<template>
  <div class="list data-sidebar-sidebar-mini">
    <div class="list-top">
      <div class="list-top-text">{{ $t("label.insuranceLedger") }}</div>
      <div class="list-top-action">
        <div class="button action" @click.prevent="exportCsv()">
          {{ $t("button.exportCsv") }}
        </div>
      </div>
    </div>
    <div class="list-filter">
      <form-filter-provider
        :doctor="doctor"
        :load-on-create="true"
        @set-doctor="(value) => (this.doctor = value)"
      />
      <form-filter-patient
        :patient="patient"
        :load-on-create="true"
        @set-patient="(value) => (this.patient = value)"
      />
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="0"
          :label="$t('label.start') + ':'"
          :original-start-date="start"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="(value) => (this.start = value)"
        ></date-time-input>
      </div>
      <div class="form-item">
        <date-time-input
          ref="inputEnd"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="1"
          :label="$t('label.end') + ':'"
          :min-date="start"
          :original-start-date="end"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="(value) => (this.end = value)"
        ></date-time-input>
      </div>
      <div class="list-filter-break"></div>
      <div class="form-item">
        <label class="item">{{ $t("label.claimType") }}:</label>
        <v-select
          :options="claimTypeOptions"
          v-model="claimType"
          :filterable="true"
        >
          <template #option="{ label }">
            <span>{{ label }}</span>
          </template>
        </v-select>
      </div>
      <div class="form-item action">
        <a class="button action" @click.prevent="loadData">
          {{ $t("button.filter") }}
        </a>
      </div>
    </div>
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="insuranceLedger"
          :sortArray="prepareHeaderArray(this.tableDefinition)"
        ></header-sort>
      </div>
      <div
        class="list-items-section data-table"
        :class="{ 'data-loading': loading }"
      >
        <!-- treatments-->
        <div class="list-items-section-wrapper">
          <template v-if="!loading">
            <div
              class="list-items-section-item"
              v-for="(item, index) in data"
              :key="'item_' + item.id"
            >
              <template v-for="(cell, cellIndex) in tableDefinition">
                <table-cell
                  v-if="
                    [
                      this.TABLE_ITEM_TYPES.TEXT,
                      this.TABLE_ITEM_TYPES.NUMBER,
                      this.TABLE_ITEM_TYPES.DATE,
                      this.TABLE_ITEM_TYPES.SELECT,
                    ].indexOf(cell.type) > -1
                  "
                  :key="'item_' + item.id + '_cell_' + cellIndex"
                  :type="cell.type"
                  :data-key="cell.key"
                  :width="cell.width"
                  :value="
                    cell.valueParser ? cell.valueParser(item) : item[cell.key]
                  "
                  :options="cell.options"
                  :placeholder="cell.placeholder"
                  :editable="cell.editable"
                  @update-cell-value="updateCellValue($event, index, item)"
                  ><template v-if="cell.slot">{{
                    cell.slot
                  }}</template></table-cell
                >
                <table-button
                  v-if="cell.type === this.TABLE_ITEM_TYPES.BUTTON"
                  :key="'item_' + item.id + '_button_' + cellIndex"
                  :label="cell.label"
                  :button-label="cell.buttonLabel"
                  :enabled="
                    typeof cell.enabled === 'function'
                      ? cell.enabled(item)
                      : cell.enabled
                  "
                  :width="cell.width"
                  @button-pressed="cell.callback(index, item)"
                />
                <table-icon-action
                  v-if="cell.type === this.TABLE_ITEM_TYPES.ICON"
                  :key="'item_' + item.id + '_icon_action_' + cellIndex"
                  :icon="cell.icon"
                  :enabled="
                    typeof cell.enabled === 'function'
                      ? cell.enabled(item)
                      : cell.enabled
                  "
                  :width="cell.width"
                  @button-pressed="cell.callback(index, item)"
                />
              </template>
            </div>
          </template>
          <div v-if="data.length === 0 && !loading">
            {{ $t("label.noResults") }}
          </div>
          <div v-if="loading" class="loading"></div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.insuranceLedger.pagination.page"
        :perPage="$store.state.insuranceLedger.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.insuranceLedger.pagination.page"
        :perPage="$store.state.insuranceLedger.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import TableCell from "@/views/private/components/table/TableCell";
import tableMixin from "@/mixins/tableMixin";
import fileMixin from "@/mixins/fileMixin";
import { CLAIM_TYPES_OPTIONS, PAGINATION } from "@/store";
import insuranceLedgerMixin from "@/views/private/components/insurance/insuranceLedgerMixin";
import TableButton from "@/views/private/components/table/TableButton";
import FormFilterProvider from "@/views/private/components/forms/FormFilterProvider";
import FormFilterPatient from "@/views/private/components/forms/FormFilterPatient";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import TableIconAction from "@/views/private/components/table/TableIconAction";
import { default as EventBus } from "@/services/eventbus";

export default {
  name: "InsuranceLedger",
  components: {
    TableIconAction,
    DateTimeInput,
    FormFilterPatient,
    FormFilterProvider,
    TableButton,
    TableCell,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  mixins: [tableMixin, fileMixin, insuranceLedgerMixin],
  data() {
    let self = this;
    return {
      data: [],
      pages: [1],
      allResults: 0,
      loading: false,
      pendingChanges: [],
      tableDefinition: [
        {
          label: this.$t("label.date"),
          key: "date",
          type: this.TABLE_ITEM_TYPES.DATE,
          editable: false,
          sortable: true,
          width: "100px",
        },
        {
          label: this.$t("label.client"),
          key: "client",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: false,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.type"),
          key: "type",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: false,
          sortable: true,
          width: "100px",
        },
        {
          label: this.$t("label.dxCode"),
          key: "dx_code",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: true,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.cptCode"),
          key: "cpt_code",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: false,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.description"),
          key: "description",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: true,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.billedAmount"),
          key: "billed_amt",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.insurancePayment"),
          key: "insurance_payment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: true,
          width: "90px",
        },
        {
          label: this.$tc("label.adjustment", 1),
          key: "adjustment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.adjustmentType"),
          key: "adjustment_type",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: true,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.patientPayment"),
          key: "patient_payment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: true,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.paymentType"),
          key: "payment_type",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: true,
          sortable: true,
          width: "200px",
        },
        {
          label: this.$t("label.claimType"),
          key: "claim_type",
          type: this.TABLE_ITEM_TYPES.SELECT,
          options: Object.values(CLAIM_TYPES_OPTIONS),
          placeholder: "Select claim type...",
          editable: true,
          sortable: true,
          width: "200px",
        },
        {
          label: this.$t("label.paymentDescription"),
          key: "payment_description",
          type: this.TABLE_ITEM_TYPES.TEXT,
          editable: true,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.patientBalance"),
          key: "patient_balance",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: false,
          width: "80px",
        },
        {
          label: this.$t("label.balanceDue"),
          key: "balance_due",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          width: "80px",
          valueParser: function (item) {
            return self.getBalanceDue(item).toFixed(2);
          },
        },
        {
          label: this.$t("label.created"),
          key: "created_at",
          type: this.TABLE_ITEM_TYPES.DATE,
          editable: false,
          sortable: true,
          width: "100px",
        },
        {
          label: this.$t("label.changes"),
          buttonLabel: this.$t("button.save"),
          key: "",
          type: this.TABLE_ITEM_TYPES.BUTTON,
          enabled: function (item) {
            return self.hasChanges(item);
          },
          callback: function (index, item) {
            return self.updateRowValue(index, item);
          },
          width: "80px",
        },
        {
          label: this.$t("label.orderDetails"),
          key: "order_id",
          type: this.TABLE_ITEM_TYPES.ICON,
          icon: "inventory-view",
          enabled: function (item) {
            return !!item.order_id;
          },
          callback: function (index, item) {
            return self.openOrderDetails(item);
          },
          width: "50px",
        },
      ],
      doctor: null,
      patient: null,
      start: null,
      end: null,
      claimType: null,
      claimTypeOptions: Object.values(CLAIM_TYPES_OPTIONS),
      cancelToken: {
        insuranceLoad: null,
        download: null,
      },
    };
  },
  watch: {
    "$store.state.insuranceLedger": {
      deep: true,
      handler: function () {
        this.loadData();
      },
    },
  },
  activated() {
    this.checkFilterParams();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    exportCsv() {
      setCancelToken(this.cancelToken, "download");

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-ledgers/export/csv",
        data: {},
        params: {
          doctor: {
            id: this.doctor ? this.doctor.id : null,
          },
          sort_by: this.$store.state.insuranceLedger.sortOrder,
        },
        responseType: "blob",
        cancelToken: this.cancelToken.download.token,
      })
        .then((response) => {
          this.downloadFile(response);
        })
        .catch(() => {});
    },
    filter() {
      this.$store.dispatch("setPagination", {
        type: "insuranceLedger",
        data: { ...PAGINATION.pagination },
      });

      this.loadData();
    },
    loadData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      setCancelToken(this.cancelToken, "insuranceLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-ledgers",
        data: {},
        params: {
          doctor: {
            id: this.doctor ? this.doctor.id : null,
          },
          patient: {
            id: this.patient ? this.patient.id : null,
          },
          date_from: this.start
            ? this.getUTCNoonFromDate(this.start).toISOString()
            : null,
          date_to: this.end
            ? this.getUTCNoonFromDate(this.end).toISOString()
            : null,
          claim_type: this.claimType ? this.claimType.value : null,
          sort_by: this.$store.state.insuranceLedger.sortOrder,
          page: this.$store.state.insuranceLedger.pagination.page,
          per_page: this.$store.state.insuranceLedger.pagination.perPage,
        },
        cancelToken: this.cancelToken.insuranceLoad.token,
      })
        .then((response) => {
          this.loading = false;
          if (response.data) {
            this.data = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateCellValue(data, index, item) {
      this.data[index][data.key] = data.value;
      this.pendingChanges.push(item.id);
    },
    updateRowValue(index, data) {
      let changeIndex = this.pendingChanges.indexOf(data.id);
      if (changeIndex > -1) {
        this.pendingChanges.splice(changeIndex, 1);
      }

      HTTP({
        method: "PUT",
        url: "/api/v1/purchases/insurance-ledgers/" + data.id,
        data: data,
      })
        .then((response) => {
          if (response.data) {
            this.data[index] = response.data;
          }
        })
        .catch(() => {});
    },
    hasChanges(item) {
      return this.pendingChanges.indexOf(item.id) > -1;
    },
    openOrderDetails(item) {
      if (!item.order_id || !item.patient) {
        return;
      }

      EventBus.$emit("open-modal", {
        type: "order-details",
        orderId: item.order_id,
        patientId: item.patient.id,
      });
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "insuranceLedger",
        data: value,
      });
    },
    checkFilterParams() {
      if (Object.keys(this.$route.query).length !== 0) {
        this.patient = null;
        this.doctor = null;
        this.start = null;
        this.end = null;
        this.claimType = null;
      }

      if (
        this.$route.query.provider_id &&
        this.$route.query.provider_id !== ""
      ) {
        this.doctor = {
          id: this.$route.query.provider_id,
          first_name: this.$route.query.provider_first_name,
          last_name: this.$route.query.provider_last_name,
        };
      }

      if (this.$route.query.patient_id && this.$route.query.patient_id !== "") {
        this.patient = {
          id: this.$route.query.patient_id,
          first_name: this.$route.query.patient_first_name,
          last_name: this.$route.query.patient_last_name,
        };
      }

      if (this.$route.query.claim_type && this.$route.query.claim_type !== "") {
        let self = this;
        let index = Object.values(CLAIM_TYPES_OPTIONS).findIndex(function (
          claimType
        ) {
          return claimType.value === self.$route.query.claim_type;
        });

        if (index > -1) {
          this.claimType = Object.values(CLAIM_TYPES_OPTIONS)[index];
        }
      }

      if (this.$route.query.date && this.$route.query.date !== "") {
        let date = this.parseISO(this.$route.query.date);
        this.start = date;
        this.end = date;
      }

      this.$router.replace({
        name: "insurance",
        params: {
          type: "insurance-ledger",
        },
      });

      this.filter();
    },
  },
};
</script>
