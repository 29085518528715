<template>
  <div class="row">
    <div class="col-lg-12 insurance">
      <insurance-sidebar />
      <KeepAlive :max="2"
        ><insurance-ledger v-if="selectedType === 'insurance-ledger'"
      /></KeepAlive>
      <KeepAlive :max="2"
        ><insurance-claims v-if="selectedType === 'insurance-claim'"
      /></KeepAlive>
      <insurance-wallet-view v-if="selectedType === 'insurance-eob'" />
    </div>
  </div>
</template>

<script>
import InsuranceSidebar from "@/views/private/components/insurance/Sidebar";
import InsuranceLedger from "@/views/private/components/insurance/InsuranceLedger";
import InsuranceWalletView from "@/views/private/components/insurance/InsuranceWalletView";
import InsuranceClaims from "@/views/private/components/insurance/InsuranceClaims";

export default {
  name: "InsuranceComponent",
  components: {
    InsuranceClaims,
    InsuranceWalletView,
    InsuranceLedger,
    InsuranceSidebar,
  },
  data() {
    return {
      types: ["insurance-ledger", "insurance-claim", "insurance-eob"],
      selectedType: null,
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        let value = val.type;
        if (typeof value === "undefined") {
          return;
        }

        if (this.types.indexOf(value) > -1) {
          this.selectedType = value;
          return;
        }

        this.selectedType = "insurance-ledger";
      },
      deep: true,
    },
  },
  created() {
    if (this.types.indexOf(this.$route.params.type) > -1) {
      this.selectedType = this.$route.params.type;
      return;
    }

    if (this.routeIn(["insuranceWalletAdd", "insuranceWalletEdit"])) {
      this.selectedType = "insurance-eob";
      return;
    }

    if (!this.$route.params.type) {
      this.$router.push({
        name: "insurance",
        params: { type: "insurance-ledger" },
      });
    }
  },
  methods: {},
};
</script>
