<template>
  <div class="form center login">
    <form ref="loginForm" @submit.prevent="login()" v-show="!loading">
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.email'),
        }"
      >
        <label class="item">{{ $t("label.email") }}: </label>
        <input v-model="form.email" type="text" ref="email" />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.password'),
        }"
      >
        <label class="item">{{ $t("label.password") }}: </label>
        <input v-model="form.password" type="password" ref="password" />
      </div>
      <router-link
        class="link"
        :to="{
          name: 'doctorsPasswordReset',
        }"
      >
        {{ $t("label.forgotPassword") }}
      </router-link>
      <div class="form-item action">
        <button class="button action" type="submit">
          {{ $t("button.login") }}
        </button>
      </div>
    </form>
    <div v-show="loading">{{ $t("message.loading") }}...</div>
  </div>
</template>

<script>
import { HTTP, HTTP_PUBLIC, logout } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import isMobile from "@/mixins/isMobile";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "DoctorsLogin",
  mixins: [isMobile],
  metaInfo() {
    return {
      title: "Doctors Login",
    };
  },
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
    "form.password": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      loading: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    if (this.$store.getters.isAuthUser) {
      this.$router.push({ name: "home" }).catch(() => {});
    } else {
      this.$refs.email.focus();
    }
  },
  methods: {
    login() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          HTTP_PUBLIC({
            method: "POST",
            url: "/api/v1/doctors/login",
            data: self.form,
          })
            .then((response) => {
              if (
                process.env.VUE_APP_DEMO_ACCOUNTS.split(",").indexOf(
                  self.form.email
                ) > -1
              ) {
                self.$store.dispatch("resetTooltips");
              }
              self.$store.dispatch("setAuth", response.data).then(() => {
                self.loadUser();
              });
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.checkCredentials"),
              });
              self.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    loadUser() {
      HTTP({
        method: "GET",
        url: "/api/v1/doctors/profile",
      })
        .then((response) => {
          this.$store.dispatch("setUser", response.data).then(() => {
            this.$store.dispatch("setUserType", "doctor");
            this.$store
              .dispatch("setFilter", {
                doctors: true,
                values: [response.data.id],
              })
              .then(() => {
                if (this.isMobile()) {
                  this.$router.push({ name: "calendarDayAgenda" });
                } else {
                  this.$router
                    .push({ name: "calendar", params: { view: "week" } })
                    .catch(() => {});
                }
              });
          });
        })
        .catch(() => {
          this.loading = false;
          logout();
        });
    },
  },
};
</script>
