<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <!-- Left column-->
            <div
              class="modal-dialog-main-section-column"
              @click="patientCreate = false"
            >
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.edit")
                          : $t("label.addNew")
                      }}
                      {{ $tc("label.appointment", 1).toLowerCase() }}
                    </div>
                    <div class="add-patient-icon-black"></div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newEvent.patient'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <v-select
                      :autoscroll="false"
                      :getOptionLabel="nameOptionLabel"
                      :options="patients"
                      :filterable="false"
                      v-model="newEvent.patient"
                      @search="searchPatients"
                      class="patient"
                    >
                      <template #list-header="{ search }">
                        <div
                          class="add-patient"
                          @click.prevent.stop="showPatientCreate(search)"
                        >
                          <div class="add-patient-icon"></div>
                          {{ $t("label.addNew") }}
                          {{ $tc("label.patient", 1).toLowerCase() }}
                        </div>
                      </template>
                      <template #list-footer>
                        <load-more
                          :message="$t('message.loading') + '...'"
                          :show-load-more="
                            patientHasMore && patients.length > 0
                          "
                          :custom-class="'vs__dropdown-option add-patient'"
                          @load-more="loadMore"
                        />
                      </template>
                    </v-select>
                  </div>

                  <div
                    class="form-item"
                    :class="{
                      error:
                        validation.hasError('newEvent.start') ||
                        validation.hasError('newEvent.end'),
                    }"
                  >
                    <date-time-input
                      label="When:"
                      ref="inputDate"
                      enable-date-calendar
                      enable-time-picker
                      input-mode
                      preserve-duration
                      show-label
                      use-date
                      use-end-time
                      use-start-time
                      :original-end-date="newEvent.end"
                      :original-start-date="newEvent.start"
                      :time-format24="this.$store.getters.userHoursFormat24"
                      @value-end-date="setEnd"
                      @value-start-date="setStart"
                    ></date-time-input>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newEvent.treatment'),
                    }"
                  >
                    <label class="item">{{ $t("label.what") }}: </label>
                    <v-select
                      label="name"
                      :options="options.treatments"
                      v-model="newEvent.treatment"
                    >
                      <template #option="{ name, id }">
                        <span>
                          <item-icon
                            type="treatment"
                            :class-name="colorClass(id, 'treatment')"
                          ></item-icon
                          >{{ name }}</span
                        >
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{ error: validation.hasError('newEvent.doctor') }"
                  >
                    <label class="item">{{ $t("label.who") }}: </label>
                    <v-select
                      :getOptionLabel="nameOptionLabel"
                      :options="options.doctors"
                      v-model="newEvent.doctor"
                    >
                      <template #option="{ first_name, last_name, id }">
                        <span>
                          <item-icon
                            type="doctor-2"
                            :class-name="colorClass(id, 'doctor')"
                          ></item-icon
                          >{{ first_name + " " + last_name }}</span
                        >
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{ error: validation.hasError('newEvent.room') }"
                  >
                    <label class="item">{{ $t("label.where") }}: </label>
                    <v-select
                      label="name"
                      :options="options.rooms"
                      v-model="newEvent.room"
                    >
                      <template #option="{ name, isAvailable, id }">
                        <span
                          ><item-icon
                            type="room"
                            :class-name="colorClass(id, 'room')"
                          ></item-icon
                          >{{ name }}</span
                        >
                        <div
                          :class="{
                            'icon-indicator': true,
                            invalid: !isAvailable,
                            'no-data': typeof isAvailable === 'undefined',
                          }"
                        ></div>
                      </template>
                      <template #selected-option="{ name, isAvailable }">
                        <span>{{ name }}</span>
                        <div
                          :class="{
                            'icon-indicator': true,
                            invalid: !isAvailable,
                            'no-data': typeof isAvailable === 'undefined',
                          }"
                        ></div>
                      </template>
                    </v-select>
                  </div>
                  <!-- Edit Notes-->
                  <div class="form-item">
                    <label class="item">{{ $tc("label.note", 2) }}: </label>
                    <textarea
                      aria-label=""
                      class="note auto-resize"
                      rows="4"
                      ref="textareaNotes"
                      v-model="newEvent.notes"
                    ></textarea>
                  </div>
                  <div class="form-item">
                    <label class="item"
                      >{{ $t("label.reasonForVisit") }}:
                    </label>
                    <textarea
                      aria-label=""
                      class="note auto-resize reason"
                      rows="4"
                      ref="textareaReason"
                      v-model="newEvent.reason_for_visit"
                    ></textarea>
                  </div>
                  <div class="form-item action">
                    <a
                      :class="{
                        'button action disable-close': true,
                        disabled: patientCreate,
                      }"
                      @click.prevent="actionHandler()"
                    >
                      {{
                        this.isModeEdit()
                          ? $t("label.update")
                          : $t("label.addNew")
                      }}
                      {{ $tc("label.appointment", 1).toLowerCase() }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Right column-->
            <div
              class="modal-dialog-main-section-column border-left"
              v-if="patientCreate"
            >
              <div
                class="modal-dialog-main-section position-relative border-bottom"
              >
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{ $t("label.addNew") }}
                      {{ $tc("label.patient", 1).toLowerCase() }}
                    </div>
                    <div class="icon-list-black"></div>
                  </div>
                </div>
              </div>
              <PatientCreate
                ref="patientCreate"
                @patient-load="searchPatients"
                @patient-select="setPatient"
                :newPatient="newPatient"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";
import SimpleVueValidator from "simple-vue3-validator";
import PatientCreate from "@/views/private/components/modals/events/PatientCreate";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import LoadMore from "@/views/shared/components/LoadMore";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "EventCreate",
  components: {
    LoadMore,
    DateTimeInput,
    PatientCreate,
    ItemIcon,
  },
  validators: {
    "newEvent.doctor": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.patient": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.treatment": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.room": function (value) {
      if (!this.isModeEdit()) {
        return;
      }
      return Validator.value(value).required();
    },
    "newEvent.start": function (value) {
      let self = this;
      return Validator.custom(function () {
        if (!Validator.isEmpty(value)) {
          if (
            self.newEvent.start &&
            !self.isBefore(self.getTodayStart(), self.newEvent.start)
          ) {
            return "invalid start time";
          }
        } else {
          return Validator.value(value).required();
        }
      });
    },
    "newEvent.end": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      options: {
        doctors: [],
        rooms: [],
        treatments: [],
      },
      patients: [],
      patientSearch: "",
      patientPage: 1,
      patientHasMore: true,
      newPatient: {
        first_name: "",
        last_name: "",
        email: "",
        birthday: "",
        gender: "",
        referral_source: "",
        passport: "empty",
        mobile_number: null,
      },
      newEvent: {
        start: null,
        end: null,
        doctor: null,
        treatment: null,
        room: null,
        patient: null,
        notes: null,
        reason_for_visit: null,
      },
      editEvent: {},
      patientCreate: false,
      loading: false,
      cancelToken: {
        roomsLoad: null,
        patientsLoad: null,
        filtersLoad: null,
      },
    };
  },
  created() {
    EventBus.$on("patient-load", () => {
      this.searchPatients();
    });
    EventBus.$on("patient-select", (params) => {
      this.newEvent.patient = params;
    });
    this.newEvent.start =
      typeof this.$store.state.modal.selectedDate !== "undefined"
        ? this.$store.state.modal.selectedDate
        : null;
  },
  mounted() {
    this.loadOptions();
    this.loadRooms();
    this.searchPatients = this.asDebounce(this.searchPatients, 1000);
    if (this.isModeEdit()) {
      this.loadEvent();
    }
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    actionHandler() {
      if (this.patientCreate) {
        return;
      }
      if (this.isModeEdit()) {
        this.updateEvent();
      } else {
        this.createEvent();
      }
    },
    isModeEdit() {
      return "eventEdit" === this.$route.name;
    },
    loadEvent() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/events/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newEvent = response.data;
            this.newEvent.start = this.handleDateTimeValue(this.newEvent.start);
            this.newEvent.end = this.handleDateTimeValue(this.newEvent.end);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadRooms() {
      let params = [];
      if (this.newEvent.start && this.newEvent.end) {
        params["start"] = this.parseToUTC(this.newEvent.start);
        params["end"] = this.parseToUTC(this.newEvent.end);
      }
      setCancelToken(this.cancelToken, "roomsLoad");
      HTTP({
        method: "GET",
        url: "/api/v1/rooms",
        data: {},
        params: params,
        cancelToken: this.cancelToken.roomsLoad.token,
      }).then((response) => {
        if (response.data) {
          this.options.rooms = response.data;

          if (this.newEvent.room) {
            let self = this;
            this.newEvent.room = this.options.rooms.find(function (item) {
              return item.id === self.newEvent.room.id;
            });
          }
        }
      });
    },
    loadOptions() {
      setCancelToken(this.cancelToken, "filtersLoad");
      let self = this;
      HTTP({
        method: "GET",
        url: "/api/v1/filters",
        cancelToken: self.cancelToken.filtersLoad.token,
      }).then((response) => {
        if (response.data) {
          self.options.doctors = response.data.length
            ? response.data[0].doctors
            : [];
          self.options.treatments = response.data.length
            ? response.data[0].treatments
            : [];
        }
        self.searchPatients();
      });
    },
    setStart(val) {
      this.newEvent.start = val;
      if (val !== null) {
        this.$validate("newEvent.start");
      }
      if (this.newEvent.end) {
        this.loadRooms();
      }
    },
    setEnd(val) {
      this.newEvent.end = val;
      this.loadRooms();
    },
    reset: function () {
      this.$refs.forms.forEach(function (form) {
        return form.reset();
      });
    },
    createEvent() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newEvent);
          HTTP({
            method: "POST",
            url: "/api/v1/events",
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.appointmentCreated"),
                });
                EventBus.$emit("event-load");
              }
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.appointmentNotCreated"),
              });
              self.loading = false;
            });
        }
      });
    },
    showDetail(event) {
      this.$router.push({
        name: "eventDetail",
        params: {
          id: event.id,
        },
      });
    },
    updateEvent() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newEvent);
          HTTP({
            method: "PUT",
            url: "/api/v1/events/" + self.$route.params.id,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.$router
                  .push({
                    name: "eventDetail",
                    params: { id: self.$route.params.id },
                  })
                  .catch(() => {});
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.appointmentUpdated"),
                });
                EventBus.$emit("event-load");
              }
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.appointmentNotUpdated"),
              });
              self.loading = false;
            });
        }
      });
    },
    searchPatients(text = "") {
      this.patientSearch = text;
      this.patientPage = 1;
      this.patientHasMore = true;
      this.patients = [];
      this.loadPatients();
    },
    loadPatients() {
      let params = {};
      if (this.patientSearch !== "") {
        params.q = this.patientSearch;
      }

      params.page = this.patientPage;
      params.per_page = 10;

      setCancelToken(this.cancelToken, "patientsLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        cancelToken: this.cancelToken.patientsLoad.token,
        params: params,
      }).then((response) => {
        if (response.data) {
          if (response.data.data.length < 10) {
            this.patientHasMore = false;
          }
          this.patients = this.patients.concat(response.data.data);
        }
      });
    },
    setPatient(val) {
      this.patientCreate = false;
      this.newEvent.patient = val;
    },
    showPatientCreate(patientFirstName) {
      this.patientCreate = true;
      this.newPatient = {
        first_name: patientFirstName,
        last_name: "",
        email: "",
        birthday: null,
        gender: null,
        referral_source: "",
        passport: "empty",
        mobile_number: null,
      };
    },
    closeModal() {
      this.$emit("close");
    },
    loadMore() {
      this.patientPage = this.patientPage + 1;
      this.loadPatients();
    },
  },
};
</script>

<style scoped></style>
