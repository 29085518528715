export default {
  methods: {
    prepareHeaderArray(dataArray) {
      return dataArray.map(function (item) {
        return {
          label: item.label,
          key: item.key,
          width: item.width,
          direction:
            typeof item.direction !== "undefined" ? item.direction : "",
          sortable:
            typeof item.sortable !== "undefined" ? item.sortable : false,
        };
      });
    },
  },
};
