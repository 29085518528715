<template>
  <div class="modal" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>
                  <div class="icon-campaign-add black"></div>
                  Add to campaign
                </div>
                <div class="icon-close-round" @click.prevent="closeModal"></div>
              </div>
            </div>
          </div>

          <div class="modal-dialog-main-section">
            <div class="modal-dialog-main-form">
              <div
                class="form-item"
                :class="{
                  error: validation.hasError('campaign'),
                }"
              >
                <label class="item">Campaigns </label>
                <v-select :options="campaigns" label="name" v-model="campaign">
                  <template #option="{ name, id }">
                    {{ name }}
                    <campaign-item-icon :id="id"></campaign-item-icon>
                  </template>
                  <template #selected-option="{ name, id }">
                    <campaign-item-icon :id="id"></campaign-item-icon>
                    {{ name }}
                  </template>
                </v-select>
              </div>
              <div class="form-item action no-padding">
                <a class="button action" @click.prevent="save()">
                  Save change
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import { default as EventBus } from "@/services/eventbus";
import CampaignItemIcon from "@/views/private/components/document/CampaignItemIcon";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "AddToCampaign",
  props: ["file"],
  components: { CampaignItemIcon },
  data() {
    return {
      campaign: null,
      campaigns: this.$store.state.documentCampaigns,
    };
  },
  created() {
    //
  },
  mounted() {
    //
  },
  validators: {
    campaign: function (value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let exist = self.file.campaigns.some(function (item) {
            return item.id === self.campaign.id;
          });

          if (exist) {
            self.closeModal();
            return;
          }

          const data = [];
          data["campaign"] = [];
          data["campaign"]["id"] = [];
          self.file.campaigns.forEach(function (item) {
            data["campaign"]["id"].push(item.id);
          });
          data["campaign"]["id"].push(self.campaign.id);

          const formData = self.generateFormData(data);

          self.loading = true;
          HTTP({
            method: "POST",
            url: "/api/v1/files/" + self.file.id,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.thumbsUp"),
                text: self.$t("notification.campaignUpdate"),
              });
              EventBus.$emit("documents-load");
              self.closeModal();
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.campaignNotUpdate"),
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
