export default {
  methods: {
    createNewEvent(selectedDate = null, splitType = null, splitId = null) {
      const date = selectedDate
        ? selectedDate
        : this.getSelectedDateTimeWithStartWorkingTime(
            this.$store.state.currentDate,
            this.$store.getters.workingStartMinutes
          );
      this.$router.push({
        name: "eventAddDetailed",
      });
      this.$store.dispatch("setModalData", {
        selectedDate: date,
        splitType: splitType,
        splitId: splitId,
      });
    },
  },
};
