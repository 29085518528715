<template>
  <div
    class="inventory-single-file"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ hover: isHovering }"
  >
    <div class="inventory-single-file-top">
      <div v-if="!file.photo" class="icon icon-file inventory"></div>
      <div
        v-else
        v-bind:style="{ backgroundImage: 'url(' + file.photo + ')' }"
        class="image"
      ></div>
    </div>
    <div class="inventory-single-file-bottom">
      <div class="inventory-single-file-bottom-sku">{{ file.sku }}</div>
      <div class="inventory-single-file-bottom-title" v-tooltip="file.name">
        {{ file.name }}
      </div>
      <div class="inventory-single-file-bottom-footer">
        <div class="inventory-single-file-bottom-footer-qty">
          {{ file.quantity }} {{ $t("label.qty") }}
        </div>
        <div class="inventory-single-file-bottom-footer-separator"></div>
        <div class="inventory-single-file-bottom-footer-price">
          {{ formatPrice(file.price) }}
        </div>
      </div>
      <div class="inventory-single-file-bottom-actions-wrapper">
        <div class="inventory-single-file-bottom-actions">
          <div
            class="inventory-single-file-bottom-actions-item qty"
            @click="qty(file)"
          ></div>
          <file-options-menu :file="file"></file-options-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as EventBus } from "@/services/eventbus";
import FileOptionsMenu from "@/views/private/components/inventory/FileOptionsMenu";
export default {
  name: "InventoryViewFile",
  components: { FileOptionsMenu },
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
    qty(file) {
      EventBus.$emit("open-modal", {
        type: "update-quantity",
        file: file,
      });
    },
  },
};
</script>
