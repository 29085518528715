<template>
  <div class="patients-schedule-agenda">
    <Agenda v-if="!loading" :events="$store.getters.eventsObjects" />
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import Agenda from "@/views/private/components/agenda/Agenda";

export default {
  name: "PatientDetailsAgenda",
  components: { Agenda },
  data: () => ({
    loading: false,
    filterParams: {},
    cancelToken: {
      load: null,
    },
  }),
  created() {
    this.loadEvents();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadEvents() {
      this.loading = true;
      this.$store.dispatch("setEvents", {
        events: [],
      });
      this.setCurrentViewParams();
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/patients/events/",
        data: {},
        cancelToken: this.cancelToken.load.token,
        params: this.filterParams,
      })
        .then((response) => {
          if (response.data) {
            this.$store.dispatch("setEvents", {
              events: response.data,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setCurrentViewParams() {
      this.filterParams.start = this.parseToUTC(
        this.newTimeZoneDate()
      ).toISOString();
      this.filterParams._sort = "start";
      this.filterParams._order = "asc";
      this.filterParams.status = [];
      this.filterParams.status.push("scheduled");
      this.filterParams.status.push("checked-in");
      this.filterParams.status.push("pending");
    },
  },
};
</script>
