export default {
  button: {
    agenda: "Agenda",
    activate: "Activate",
    advancedSearch: "Advanced search",
    addTimeOff: "Add time-off",
    addAll: "Add All",
    addPhoto: "Add photo",
    addToCheckout: "Add to Checkout",
    addNew: "Add new",
    addNewPatient: "Add New Patient",
    addNewEvent: "Add New Event",
    addNewCondition: "Add new condition",
    addItemOrFolder: "Add Item or Folder",
    addItem: "Add Item",
    addFolder: "Add Folder",
    add: "Add New",
    addSoapNotes: "Add @:label.soapNotes",
    applyFilters: "Apply Filters",
    applyChanges: "Apply Changes",
    approve: "Approve",
    back: "Back",
    cancel: "Cancel",
    checkIn: "Check-In",
    checkOut: "Check-Out",
    createAccount: "Create account",
    confirm: "Confirm",
    close: "Close",
    delete: "Delete",
    deactivate: "Deactivate",
    discard: "Discard",
    details: "Details",
    edit: "Edit",
    exportCsv: "Export .csv",
    exportPdf: "Export .pdf",
    filter: "Filter",
    fillPatientForm: "Fill patient form",
    filterNotes: "Filter Notes",
    home: "Home",
    hideDoctors: "Hide Providers",
    login: "Log in",
    loginTogether: "Login",
    logout: "Log out",
    no: "No",
    next: "Next",
    notify: "Notify",
    ok: "Ok",
    openEmailApp: "Open email app",
    payment: "Payment | Payments",
    patientForm: "Patient Form",
    previous: "Previous",
    pay: "Pay",
    policyInfo: "Policy Info",
    reset: "Reset",
    remove: "Remove",
    register: "Register",
    registerNow: "Register now",
    refund: "Refund",
    resetFilter: "Reset Filter",
    resendVerificationEmail: "Resend verification email",
    stopRecurrence: "Stop recurrence",
    save: "Save",
    saveChanges: "Save Changes",
    skip: "Skip",
    split: "Split",
    splitView: "Split View",
    showDoctors: "Show Providers",
    submit: "Submit",
    show: "Show",
    soapNotes: "SOAP Notes",
    sendResetRequest: "Send Reset Request",
    scheduleAppointment: "Schedule appointment",
    sign: "Sign",
    saveDraft: "Save Draft",
    today: "Today",
    upcomingAppointments: "Upcoming appointments",
    update: "Update",
    viewAll: "View All",
    viewDocuments: "View Documents",
    completeRegistration: "Complete Registration",
    yes: "Yes",
  },
  label: {
    action: "Action",
    admin: "Admin",
    addCreditCard: "Add credit card",
    addNew: "Add new",
    addNewDoctor: "Add new doctor",
    addNewRoom: "Add new room",
    addNewPos: "Add new POS terminal",
    addNewInsuranceEob: "Add new EOB",
    addNewTreatment: "Add new treatment",
    addNewUser: "Add new user",
    additionalInformation: "Additional Information",
    address: "Address",
    adjustment: "Adjustment | Adjustments",
    adjustmentReason: "Adjustment Reason",
    adjustmentType: "Adjustment Type",
    advancedSearch: "Advanced Search",
    activate: "Activate",
    ageAtFirstMenes: "Age at First Menses?",
    amount: "Amount",
    annualLimits: "Annual limits",
    annualVisits: "Annual visits",
    availableQty: "Available Qty.",
    averageBloodPressure: "Average blood pressure",
    allergies: "Allergies",
    all: "All",
    allFiles: "All files",
    allItems: "All Items",
    allReadyHaveAccount: "Already have an account?",
    allowableAmount: "Allowable amount",
    at: "At",
    appointment: "Appointment | Appointments",
    appointmentsNumber: "Appointments number",
    apptsNumber: "Appts. number",
    billedAmount: "Billed amount",
    balanceDue: "Balance Due",
    beginDate: "Begin date",
    balance: "Balance",
    balance030days: "Current (1-30)",
    balance3060days: "Over 30",
    balance6090days: "Over 60",
    balanceOver90days: "Over 90",
    balanceTotal: "Balance",
    billingInformationSummary: "Billing information summary",
    cardNumber: "Card Number",
    cardExpire: "Card Expire",
    cardiovascular: "Cardiovascular",
    cellPhone: "Cell phone",
    city: "City",
    claimType: "Claim Type",
    claimInfo: "Claim Info",
    client: "Client",
    clinic: "Clinic | Clinics",
    clinicData: "Clinic Data",
    checkNumber: "Check/EFT",
    changePassword: "Change password",
    changes: "Changes",
    checkout: "Checkout",
    chosenConditionTitle: "Choosen condition title",
    chiefComplaint: "Chief Complaint",
    condition: "Condition",
    conditionTemplate: "Condition Template",
    consentPages: "Consents pages",
    coveredByInsurance: "Covered by Insurance",
    coveredByPatient: "Covered by Patient",
    created: "Created",
    credit: "Credit",
    contact: "Contact",
    constitutionalGeneral: "Constitutional/General",
    code: "Code | Codes",
    color: "Color",
    copayAcupuncture: "Copay Acupuncture",
    copayEvaluation: "Copay Evaluation",
    coinsuranceAcupuncture: "Coinsurance Acupuncture",
    coinsuranceEvaluation: "Coinsurance Evaluation",
    cvv: "Cvv",
    charge: "Charge | Charges",
    cptCode: "CPT Code | CPT Codes",
    currentAuthorization: "Current authorization",
    dxCode: "DX Code | DX Codes",
    dailyDiet: "Describe your daily diet (breakfast, lunch, dinner)",
    date: "Date",
    debit: "Debit",
    describeExercise: "Describe any exercise you are currently doing",
    day: "Day",
    daysValid: "Days Valid",
    dateOfLastMenes: "Date of Last Menses?",
    deviceId: "Device ID",
    deviceName: "Device Name",
    didAnythingInitiateSymptoms: "Did anything initiate the symptoms?",
    doesAnythingMakeItWorse: "Does anything make it worse?",
    doesAnythingMakeItBetter: "Does anything make it better?",
    doctor: "Doctor | Doctors",
    duration: "Duration",
    durationOfMenes: "Duration of Menses?",
    detail: "Detail | Details",
    deductible: "Deductible",
    deductibleTotal: "Deductible Total",
    deductibleRemaining: "Deductible Remaining",
    deductibleAppliesAcupuncture: "Deductible Applies Acupuncture",
    deductibleAppliesEvaluation: "Deductible Applies Evaluation",
    dedLeft: "Ded. left",
    description: "Description",
    dateOfBirth: "Date of birth",
    dateOfService: "Date of Service",
    deactivate: "Deactivate",
    defaultTreatmentLength: "Default treatment length",
    deviceNickname: "Device nickname",
    draft: "Draft",
    discount: "Discount",
    doYouHaveMedicare: "Do you have Medicare?",
    dob: "DOB",
    dontHaveAccount: "You don’t have an account yet?",
    enterEmail: "Enter your email",
    enterPassword: "Enter password",
    enterPhoneNumber: "Enter your phone number",
    event: "Event",
    email: "Email",
    endDate: "End date",
    employedBy: "Employed by",
    enmt: "ENMT",
    edit: "Edit",
    end: "End",
    editDoctor: "Edit doctor",
    effectiveDate: "Effective date",
    editRoom: "Edit room",
    editTreatment: "Edit treatment",
    editUser: "Edit user",
    exactValue: "Exact value",
    exclusions: "Exclusions",
    editInsuranceEob: "Edit EOB",
    experienceInPast: "Experienced Acupuncture/Herbs in the past?",
    eventStatusScheduled: "Scheduled",
    eventStatusCancel: "Canceled",
    eventStatusCheckIn: "Checked in",
    eventStatusCheckOut: "Checked out",
    eventStatusPending: "Pending",
    everyday: "Everyday",
    eyes: "Eyes",
    family: "Family",
    firstName: "First name",
    folder: "Folder | Folders",
    forSubmissionTo: "For submission to",
    filters: "Filters",
    firstAppointment: "First appointment",
    financialPolicy: "Financial policy",
    firstAdded: "First added",
    foldersOnly: "Folders only",
    forgotPassword: "Forgot password?",
    fromDate: "From Date",
    guardian: "Guardian",
    gender: "Gender",
    generalClinic: "General clinic",
    groupId: "Group Id",
    gastrointestinal: "Gastrointestinal",
    gynecology: "Gynecology (Women)",
    howMuchAlcohol: "How much alcohol do you drink per week?",
    howMuchCoffeeTea: "How much coffee, tea or caffeine per week?",
    haveYouBeenGivenADiagnosis: "Have you been given a diagnosis? If so, what?",
    hourFormat: "Hour format",
    homePhone: "Home phone",
    height: "Height",
    hybrid: "Hybrid",
    hipaaPrivacy: "Hipaa Privacy Authorization Form",
    howDidYouHear: "How did you hear about us",
    in: "In",
    includeInClaim: "Include in Insurance Claim",
    ins: "Ins %",
    initialAmount: "Initial Amount",
    invoiceData: "Invoice Data",
    invoiceName: "Invoice Name",
    invoiceAddress: "Invoice Address",
    invoiceZip: "Invoice Zip",
    invoiceCity: "Invoice City",
    invoiceState: "Invoice State",
    invType: "Inv Type",
    insurance: "Insurance",
    insuredName: "Insured Name",
    insuredDob: "Insured DOB",
    insuranceLedger: "Insurance Ledger",
    insuranceClaim: "Insurance Claim | Insurance Claims",
    insuranceProvider: "Insurance Provider | Insurance Providers",
    insurancePayment: "Insurance Payment",
    insuranceEob: "Insurance EOB | Insurance EOBs",
    informedConsent: "Informed Consent for Care",
    insuranceInformation: "Insurance information",
    insuranceCompanyInformation: "Insurance company information",
    insuredsInformation: "Insured's information",
    item: "Item | Items",
    itemsFolders: "Items & Folders",
    itemsOnly: "Items only",
    insurancePhone: "Insurance phone",
    lastName: "Last name",
    lastAppointment: "Last appointment",
    lastAdded: "Last added",
    licenseNo: "License number",
    legalName: "Legal name",
    leaveVoicemail: "Leave voicemail",
    logo: "Logo",
    loadingPolicyInfo: "Loading Policy Info",
    referralSource: "Referral source",
    mobileNo: "Mobile no.",
    max: "Max",
    maxNumber: "Max number",
    mainProblems: "Main problems you want help with",
    married: "Married",
    medicinesVitaminsHerbs: "Medicines, Vitamins, Herbs",
    min: "Min",
    minutes: "Minutes",
    memberId: "Member Id",
    musculoskeletal: "Musculoskeletal",
    month: "Month",
    name: "Name",
    neuropathyPsychology: "Neuropathy/Psychology",
    nextAppointment: "Next appointment",
    newPassword: "New password",
    note: "Note | Notes",
    noResults: "No results",
    noPolicyInfo: "No Policy Info",
    noSoapNotes: "There is no SOAP notes added",
    noticeOfPrivacy: "Notice of Privacy Practices",
    noticeOfPrivacySub:
      "THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.",
    numberOfPregnancies: "Number of Pregnancies?",
    numberOfVisits: "Number of visits",
    numberOfBirths: "Number of Births?",
    number: "Number",
    numberOfDaysBetweenMenes: "Number of Days Between Menses?",
    oldPassword: "Old password",
    occupation: "Occupation",
    other: "Other",
    orderDetails: "Order details",
    or: "Or",
    otherListBelow: "Other (please list below)",
    otherDetails: "Other Details",
    patient: "Patient | Patients",
    patientId: "Patient ID",
    patientBalance: "Patient balance",
    patientResponsibility: "Patient responsibility",
    patientPayment: "Patient payment",
    patientAddress: "Patient Address",
    paymentDescription: "Payment Description",
    paymentType: "Payment Type",
    paymentOverview: "Payment overview",
    paymentSuccess: "Payment success",
    paymentFailed: "Payment failed",
    paymentMethods: "Payment methods",
    purposeOfVisit: "Purpose of visit",
    policyInfo: "Policy info",
    provider: "Provider | Providers",
    product: "Product | Products",
    preferredName: "Preferred name",
    preAuthorization: "Pre-Authorization",
    productNotFound: "Product not found",
    primaryCareDoctor: "Primary care Doctor",
    primarySecondaryInsurance: "Primary/Secondary (if Medicare) Insurance",
    pronoun: "Pronoun",
    prescriptionMedications: "Prescription Medications",
    password: "Password",
    pastMedicalHistory: "Past Medical/Family History",
    passwordConfirmation: "Password confirmation",
    price: "Price",
    procedure: "Procedure | Procedures",
    prescriptions: "Prescriptions",
    preAuthRequired: "PreAuth required",
    plan: "Plan | Plans",
    planResetMonth: "Plan Reset Month",
    posTerminal: "POS terminal",
    pleaseCheckAllThatApply: "Please check all that apply",
    phoneNo: "Phone number",
    patientEncounter: "Patient Encounter",
    postalZipCode: "Postal/ZIP code",
    posTerminals: "POS Terminals",
    preferredMeansOfContact: "Preferred means of contact",
    preview: "Preview",
    printedName: "Printed name",
    practitioner: "Practitioner",
    remaining: "Remaining",
    respiratory: "Respiratory",
    requiresGuardian: "Requires Guardian",
    qty: "Qty",
    quantity: "Quantity",
    rooms: "Rooms",
    room: "Room | Rooms",
    registrationCode: "Registration code",
    reasonForVisit: "Reason for visit",
    remove: "Remove",
    referral: "Referral",
    repeat: "Repeat",
    relationshipStatus: "Relationship status",
    relationshipToPatient: "Relationship to patient",
    restore: "Restore",
    retypeNewPassword: "Please retype new password.",
    select: "Select",
    sex: "Sex",
    sendPatientForm: "Send patient form",
    selectDay: "Select day",
    selectTime: "Select time",
    self: "Self",
    selfPay: "Self Pay",
    selfPayPrice: "Self Pay Price",
    skin: "Skin",
    slug: "Slug",
    signature: "Signature",
    signatureAuthorizes: "My signature below authorizes",
    signed: "Signed",
    success: "Success",
    startDate: "Start date",
    report: "Report | Reports",
    reinstateOn: "Reinstate on",
    reports: {
      byPatient: "By patient",
      byServiceProvider: "By service provider",
      futureEventsByDoctor: "Future appointments by provider",
      mostImportant: "Most important",
      newPatientsByDoctor: "New patients by provider",
      newPatientsListByDoctor: "New patients list by provider",
      payrollReports: "Payroll reports",
      paymentDetails: "Payment details",
      patientPayments: "Patient's payments",
      agingBalances: "Aging Balances",
      paymentDetailByServiceProvider: "Payment detail by service provider",
      providerAnalysisReports: "Provider analysis reports",
      procedureCodeUsageByProvider: "Procedure code usage by provider",
      salesByCategoryReports: "Sales by category reports",
      subtotalByInventoryType: "Subtotal by inventory type",
    },
    soldByDoctor: "Sold By Doctor",
    status: "Status",
    state: "State",
    settings: "Settings",
    soapNotes: "SOAP Notes",
    summary: "Summary",
    sortBy: "Sort by",
    start: "Start",
    streetAddress: "Street address",
    streetAddressLine2: "Street address line 2",
    subscribersName: "Subscriber's name",
    searchPatientFolders: "Patient Folders",
    systemsReview:
      "Systems Review - Check All That Apply (currently experiencing)",
    type: "Type",
    tags: "Tags",
    tax: "Tax",
    timezone: "Timezone",
    title: "Title",
    tip: "Tip",
    tobaccoUse: "Tobacco use? If yes, how often?",
    totalPrice: "Total",
    totalPriceWithDiscount: "Total with discount",
    toDate: "To Date",
    termDate: "Term date",
    totalItems: "Total Items",
    treatment: "Treatment | Treatments",
    treatments: "Treatments",
    treatmentType: "Treatment type",
    time: "Time",
    timeOff: "Time Off",
    total: "Total",
    totalToPay: "Total to pay",
    update: "Update",
    updateAdjustment: "Update Adjustment",
    updatedAdjustment: "Updated Adjustment",
    user: "User | Users",
    unit: "Unit | Units",
    until: "Until",
    uroGenital: "Uro-Genital",
    visitLimit: "Visit Limit",
    view: "View",
    yourAccount: "Your account",
    yourReason: "Your reason",
    waitList: "Waiting list",
    what: "What",
    weight: "Weight",
    who: "Who",
    whichPhoneNumber: "Which phone number to use",
    where: "Where",
    whenDidYourIssueBegin: "When did your issue begin?",
    workFrom: "Start working hours",
    workPhone: "Work phone",
    workTo: "End working hours",
    zip: "Zip",
  },
  tooltip: {
    redoSoap: "Redo this soap item",
    redo: "Redo",
    turnOnAler: "Turn on min level alert",
  },
  placeholder: {
    enterMin: "Enter min",
    enterMax: "Enter max",
    enterValue: "Enter value",
    minutes: "Minutes",
    search: "Search",
    select: "Select",
    selectCondition: "- Select a condition -",
  },
  message: {
    accessDenied: "Access denied",
    loading: "Loading",
    comingSoon: "Coming Soon",
    changesWillBeDiscarded: "Changes you made will be discarded",
    doYouWantToProceed: "Do you want to proceed?",
    editAndSelect: "Please edit event and select {type}",
    filterAllNotes: "Filter all notes",
    noMatchingOptions: "No matching options",
    noSignedSoapNote: "SOAP note not signed",
    noResultsFor: "No results for",
    noPreviousAdjustments: "No previous adjustments",
    noCurrentPolicyInfo:
      "Currently there is no policy info versions, please create one.",
    patientFormSchedule: "Schedule an appointment",
    passwordResetSent:
      "Password reset email sent, please check your mailbox for instructions.",
    patientFormScheduleText:
      "Please fill in your personal information in your account settings.",
    patientHasPlanUntil:
      "Patient has {plan} {treatment} treatment left until {date} | Patient has {plan} {treatment} treatments left until {date}",
    searchingFor: "Searching for",
    soapNotesEmpty:
      "Praesent eu dolor eu orci vehicula euismod vivamus sed\n" +
      " sollicitudin libero, vel malesuada velit.",
    userVerification: "You have been verified. You can now login.",
    viewingSoapNote: "Viewing {number} of {sum} results",
    userVerificationFailed:
      "User verification failed. Please use the url we provide you in\n" +
      " verification email",
    verificationLink:
      "The verification link has been send to your email. Please confirm your " +
      "address and continue with registration process.",
    youAreAboutToDelete: "You are about to delete a {item}",
    youAreAboutToDeactivate: "You are about to deactivate a {item}",
    youAreAboutToActivate: "You are about to activate a {item}",
  },
  notification: {
    appointmentCreated: "Appointment created",
    appointmentUpdated: "Appointment updated",
    appointmentNotCreated: "Something went wrong, appointment not created.",
    appointmentNotUpdated: "Something went wrong, appointment not updated.",
    appointmentReminder: "Appointment reminder",
    campaignUpdate: "Document campaign updated",
    campaignNotUpdate: "Something went wrong, document campaign not updated.",
    campaign: {
      initial: "Initial treatment documents",
      initialText:
        "Files in this folder will be sent out for every new patient a day before their inital treatment.",
      followUp: "Follow-up documents",
      followUpText:
        "Remind your patients before their follow-up treatments. Files in this folder will be sent out a day before the scheduled appointment.",
      reminders: "Reminder notifications",
      remindersText:
        "Files in this campaign will be sent when using appointment reminder button from the calendar.",
      invoices: "Invoicing and payments",
      invoicesText:
        "Files in this campaign will be sent out after completing each payment.",
    },
    checkVerified: "Please check if account is verified",
    checkCredentials: "Check your credentials and try again",
    checkEmail: "Check your email.",
    claimTypeRequired: "Claim Type is required",
    clinicUpdate: "Clinic updated.",
    clinicNotUpdate: "Something went wrong, clinic not updated.",
    consentSettingsUpdate: "Consent settings updated.",
    consentSettingsNotUpdate:
      "Something went wrong, consent settings not updated.",
    done: "Done",
    datesAreRequired: "Dates are required",
    documentRenamed: "{type} renamed.",
    documentNotRenamed: "Something went wrong, {type} not updated.",
    deleteSoap: "Delete SOAP Note",
    doctorDeactivated: "Doctor deactivated",
    doctorActivated: "Doctor activated",
    error: "Error",
    errorSave: "Error while saving",
    errorSaveCard: "Error while saving card, please check card data.",
    errorDelete: "Error while deleting",
    errorRefund: "Error while refunding",
    emailNotFound: "Email not found",
    fillAllFields: "Please fill all fields",
    fillAllFieldsAndCheckValidity:
      "Please fill all fields and check field validity",
    fillRequiredFields: "Please fill required fields",
    filesRepository: "Files repository",
    filesRepositoryText: "Start adding your files",
    folderCreated: "Folder created",
    folderNotCreated: "Something went wrong, folder not created.",
    info: "Info",
    inventoryInit: "Supplies inventory",
    inventoryInitText: "Start managing you inventory",
    minQuantity: "Min quantity for product is 1",
    notificationSent: "Notification sent",
    notificationNotSent: "Notification not sent",
    patientDeactivated: "Patient deactivated",
    patientActivated: "Patient activated",
    passwordSent: "Password sent",
    passwordUpdated: "Your password has been updated please login.",
    doctorPasswordUpdated: "Doctor password has been updated.",
    pleaseSelectValidStatus: "Please select valid status",
    pleaseSelectEob: "Please select EOB in order to edit adjustment",
    pleaseWaitForOtherAction: "Please wait for other action to finish",
    pleaseVerifyEmail: "Please verify email",
    passwordNotUpdated:
      "Your password has not been updated, check password and password confirmation.",
    passwordNotUpdatedExtended:
      "Your password has not been updated, check old password, new password and new password confirmation.",
    proceed: "Do you want to proceed?",
    refundItem: "Refund item",
    quantityUpdated: "Quantity updated",
    quantityNotUpdated: "Something went wrong, product quantity not updated.",
    success: "Success",
    sadFace: "😞",
    saveSoap: "Save SOAP Note",
    signSoap: "Sign SOAP Note",
    signAndFillRequired: "Please sign and fill required fields if any",
    soapCptCodesRequired: "CPT Codes are required.",
    soapDXCodesRequired:
      "DX Codes are required if claim type is other than Self Pay.",
    soapCreated: "SOAP created",
    soapDeleted: "SOAP deleted",
    soapUpdated: "SOAP updated",
    soapNotCreated: "Something went wrong, SOAP not saved.",
    soapNotDeleted: "Something went wrong, SOAP not deleted.",
    soapEmpty:
      "There is some empty SOAP note items, please check and fill or remove them.",
    somethingWentWrong: "Something went wrong, please try again.",
    shareAgenda: "Share agenda",
    shareAgendaText: "Do you want to make publicly shared link to you agenda?",
    shortcutCreated: "Shortcut created",
    shortcutNotCreated: "Something went wrong, shortcut not created.",
    successSave: "Successfully saved",
    successDelete: "Successfully deleted",
    successRefund: "Successfully refunded",
    thumbsUp: "👍",
    thisWillNotifyPatient:
      "This will notify patient about the upcoming appointment.",
    warning: "Warning",
    welcomeToKlinika: "Welcome to MyKlinika",
    welcomeStart: "Welcome, you can start using the calendar",
    onboardTooltip: {
      calendarMainNav:
        "Use the menu to navigate through appointments, files and inventory",
      calendarSearchForm: "Search the calendar by a patient or provider name",
      calendarSidebarCalendar:
        "Pick any date for a quick overview of scheduled appointments",
      calendarFocus: "Select providers to see their schedule",
      calendarSwitch: "Toggle between monthly, weekly and daily view",
      calendarAddEvent: "Click to add an appointment easily",
      filesSearch: "Search through all files",
      filesShortcuts: "Quickly navigate through your main folders",
      filesCampaigns: "Or choose a file type you want to display",
      inventorySearch: "Search inventory by name or tags",
      inventoryFolders: "Organize your inventory by creating folders",
      inventoryAddItems: "Easily add a new supply or folder",
      patientProfile: "See your account settings and upcoming appointments",
    },
  },
  sidebar: {
    calendar: "Calendar",
    documents: "Documents",
    inventory: "Inventory",
    inventoryAdvanced: "Inventory advanced search",
    insuranceLedger: "Insurance Ledger",
    patients: "Patients",
    reports: "Reports",
    settings: "Settings",
  },
  patientForm: {
    earlyDevelopment:
      "Early Development: Were there any in-utero complications before you were born? Please describe any labor and delivery, natural or C-Section, and how long breast fed",
    significantTraumas: "Significant traumas (auto accident, falls, etc)",
    signatureFirstLine:
      "- Provider to render treatment and apply for benefits paid directly to the provider",
    signatureSecondLine:
      "- The release of any medical or other information necessary to process this claim",
    signatureThirdLine:
      "- MEDICAL INSURANCE: We have contracts with BC/BS, Carefirst, Aetna and United Healthcare companies, and we will bill them as a service to you. As the responsible party, you are responsible if your insurance company declines to pay for any reason.",
  },
  clinic: {
    loginTitle: "Welcome back",
    loginText:
      "Fill in the registration data. It will take a couple of minutes.",
    patientShareAnything:
      "Please share anything that will help prepare for our meeting",
    registerTitle: "Create account",
    registerText:
      "Fill in the registration data. It will take a couple of minutes.",
    verifyEmailTitle: "Please verify your Email Address",
    verifyEmailText:
      "We sent a verification email to {email}. Please verify your email to unlock the advanced features.",
    verifyTitle: "Verify account",
    setPassword: "Set password",
    verifyText:
      "Please setup password so you can verify account and continue using app",
    forgotPasswordTitle: "Forgot password?",
    forgotPasswordText: "Please enter your email below.",
    changePasswordTitle: "Change your password",
    changePasswordText: "Please type your new password.",
  },
};
