<template>
  <div class="row">
    <div class="col-lg-12 reports">
      <sidebar
        :expanded="expanded"
        :selected="selected"
        :items="types"
      ></sidebar>
      <payment-details-view
        v-if="selected === 'payment-details'"
      ></payment-details-view>
      <patient-payments-view
        v-if="selected === 'patient-payments'"
      ></patient-payments-view>
      <aging-balances-view
        v-if="selected === 'aging-balances'"
      ></aging-balances-view>
      <subtotal-by-inventory-type-view
        v-if="selected === 'subtotal-by-inventory-type'"
      ></subtotal-by-inventory-type-view>
      <procedure-code-usage-view
        v-if="selected === 'procedure-code-usage-by-provider'"
      ></procedure-code-usage-view>
      <payment-details-by-service-provider-view
        v-if="selected === 'by-service-provider'"
      ></payment-details-by-service-provider-view>
      <future-appointments-by-provider-view
        v-if="selected === 'future-appointments-by-provider'"
      ></future-appointments-by-provider-view>
      <new-patients-list-by-provider-view
        v-if="selected === 'new-patients-list-by-provider'"
      >
      </new-patients-list-by-provider-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/reports/Sidebar";
import PaymentDetailsView from "@/views/private/components/reports/views/PaymentDetailsView";
import AgingBalancesView from "@/views/private/components/reports/views/AgingBalancesView";
import PatientPaymentsView from "@/views/private/components/reports/views/PatientPaymentsView";
import SubtotalByInventoryTypeView from "@/views/private/components/reports/views/SubtotalByInventoryTypeView";
import ProcedureCodeUsageView from "@/views/private/components/reports/views/ProcedureCodeUsageView";
import PaymentDetailsByServiceProviderView from "@/views/private/components/reports/views/PaymentDetailsByServiceProviderView";
import FutureAppointmentsByProviderView from "@/views/private/components/reports/views/FutureAppointmentsByProviderView";
import NewPatientsListByProviderView from "@/views/private/components/reports/views/NewPatientsListByProviderView";
export default {
  name: "ReportsComponent",
  components: {
    Sidebar,
    PaymentDetailsView,
    PatientPaymentsView,
    AgingBalancesView,
    ProcedureCodeUsageView,
    SubtotalByInventoryTypeView,
    PaymentDetailsByServiceProviderView,
    FutureAppointmentsByProviderView,
    NewPatientsListByProviderView,
  },
  data() {
    return {
      types: {
        most_important: {
          label: this.$t("label.reports.mostImportant"),
          items: [
            {
              label: this.$t("label.reports.paymentDetails"),
              key: "payment-details",
            },
            {
              label: this.$t("label.reports.patientPayments"),
              key: "patient-payments",
            },
            {
              label: this.$t("label.reports.agingBalances"),
              key: "aging-balances",
            },
          ],
        },
        sales_by_category_reports: {
          label: this.$t("label.reports.salesByCategoryReports"),
          items: [
            {
              label: this.$t("label.reports.subtotalByInventoryType"),
              key: "subtotal-by-inventory-type",
            },
            {
              label: this.$t("label.reports.procedureCodeUsageByProvider"),
              key: "procedure-code-usage-by-provider",
            },
          ],
        },
        payroll_reports: {
          label: this.$t("label.reports.payrollReports"),
          items: [
            {
              label: this.$t("label.reports.paymentDetailByServiceProvider"),
              key: "by-service-provider",
            },
          ],
        },
        provider_analysis_reports: {
          label: this.$t("label.reports.providerAnalysisReports"),
          items: [
            {
              label: this.$t("label.reports.futureEventsByDoctor"),
              key: "future-appointments-by-provider",
            },
            {
              label: this.$t("label.reports.newPatientsListByDoctor"),
              key: "new-patients-list-by-provider",
            },
          ],
        },
      },
      expanded: "most_important",
      selected: "by-patient",
    };
  },
  watch: {
    "$route.params.type": function (val) {
      if (!val) {
        return;
      }
      this.expanded = val.replaceAll("-", "_");
    },
    "$route.params.sub_type": function (val) {
      if (!val) {
        return;
      }
      this.selected = val;
    },
  },
  created() {
    if (typeof this.$route.params.type === "undefined") {
      this.defaultRoute();
      return;
    }
    let self = this;
    let type = this.types[this.$route.params.type.replaceAll("-", "_")]
      ? this.types[this.$route.params.type.replaceAll("-", "_")].items
      : null;
    if (
      type === null ||
      type === "undefined" ||
      type.findIndex(function (item) {
        return item.key === self.$route.params.sub_type;
      }) === -1
    ) {
      this.defaultRoute();
      return;
    }

    this.expanded = this.$route.params.type.replaceAll("-", "_");
    this.selected = this.$route.params.sub_type;
  },
  methods: {
    defaultRoute() {
      this.$router.push({
        name: "reports",
        params: {
          type: "most-important",
          sub_type: "payment-details",
        },
      });
    },
  },
};
</script>
