<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-inner-block">
        <div class="sidebar-inner-block-title">
          {{ $t("label.insurance") }}
        </div>
        <div class="sidebar-inner-block-items">
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: $route.params.type === 'insurance-ledger' }"
            @click="
              $router.push({
                name: 'insurance',
                params: { type: 'insurance-ledger' },
              })
            "
          >
            <div class="name">{{ $tc("label.insuranceLedger", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: $route.params.type === 'insurance-claim' }"
            @click="
              $router.push({
                name: 'insurance',
                params: { type: 'insurance-claim' },
              })
            "
          >
            <div class="name">{{ $tc("label.insuranceClaim", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: $route.params.type === 'insurance-eob' }"
            @click="
              $router.push({
                name: 'insurance',
                params: { type: 'insurance-eob' },
              })
            "
          >
            <div class="name">{{ $t("label.insuranceEob") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsuranceSidebar",
  methods: {},
};
</script>
