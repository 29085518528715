<template>
  <div class="row">
    <div class="col-lg-12 patients-policy-info">
      <patient-policy-info-view />
    </div>
  </div>
</template>

<script>
import PatientPolicyInfoView from "@/views/private/components/patients/PatientPolicyInfoView";
export default {
  name: "PatientPolicyInfo",
  components: {
    PatientPolicyInfoView,
  },
};
</script>
