<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <template v-if="!loading">
      <div class="list-top">
        <div class="list-top-text">
          {{ $tc("label.password", 1) }}
        </div>
        <div class="button action" @click.prevent="savePassword">
          {{ $t("button.save") }}
          <div class="icon icon-add-big"></div>
        </div>
      </div>
      <div class="list-items">
        <form @submit.prevent="">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formData.old_password'),
            }"
          >
            <label class="item">{{ $t("label.oldPassword") }}: </label>
            <password-input
              :model-value="formData.old_password"
              @update:modelValue="
                (newValue) => (formData.old_password = newValue)
              "
              prop-ref="old_password"
            />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formData.password'),
            }"
          >
            <label class="item">{{ $t("label.newPassword") }}: </label>
            <password-input
              :model-value="formData.password"
              @update:modelValue="(newValue) => (formData.password = newValue)"
              prop-ref="password"
            />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formData.password_confirmation'),
            }"
          >
            <label class="item">{{ $t("label.passwordConfirmation") }}: </label>
            <password-input
              :model-value="formData.password_confirmation"
              @update:modelValue="
                (newValue) => (formData.password_confirmation = newValue)
              "
              prop-ref="password_confirmation"
            />
          </div>
        </form>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import PasswordInput from "@/views/private/components/PasswordInput";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "DoctorPasswordView",
  validators: {
    "formData.old_password": function (value) {
      return Validator.value(value).required();
    },
    "formData.password": function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "formData.password_confirmation, formData.password": function (
      password_confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(password_confirmation)
          .required()
          .match(password);
      }
    },
  },
  components: {
    PasswordInput,
  },
  data: function () {
    return {
      formData: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      loading: false,
    };
  },
  mounted() {},
  methods: {
    savePassword() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url = "/api/v1/doctors/password/change";
          self.loading = true;
          HTTP({
            method: "POST",
            url: url,
            data: {
              current_password: self.formData.old_password,
              password: self.formData.password,
              password_confirmation: self.formData.password_confirmation,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
  },
};
</script>
