<template>
  <div class="row">
    <div class="col-lg-12 patients-form">
      <patient-consent-form-hipaa />
    </div>
  </div>
</template>

<script>
import PatientConsentFormHipaa from "@/views/shared/components/consents/PatientConsentFormHipaa";
export default {
  name: "PatientConsentHipaa",
  components: { PatientConsentFormHipaa },
};
</script>
