<template>
  <div
    v-if="soapNote.claim_type === claimTypes.CLAIM_TYPE_SELF_PAY.value"
    class="payment-tab-single-item-top-info-item"
  >
    {{ $t("label.selfPay") }}
  </div>
  <div
    v-if="soapNote.claim_type === claimTypes.CLAIM_TYPE_HYBRID.value"
    class="payment-tab-single-item-top-info-item"
  >
    {{ $t("label.hybrid") }}
  </div>
  <div
    v-if="
      [
        claimTypes.CLAIM_TYPE_MEDICAL_INSURANCE.value,
        claimTypes.CLAIM_TYPE_WORKERS_COMPENSATION.value,
        claimTypes.CLAIM_TYPE_ACCIDENT_PIP.value,
        claimTypes.CLAIM_TYPE_VETERANS.value,
      ].indexOf(soapNote.claim_type) > -1
    "
    class="payment-tab-single-item-top-info-item"
  >
    {{ $t("label.forSubmissionTo") + " " + getClaimLabel(soapNote.claim_type) }}
  </div>
</template>

<script>
import { CLAIM_TYPES_OPTIONS } from "@/store";

export default {
  name: "PaymentClaimLabel",
  props: {
    soapNote: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      claimTypes: CLAIM_TYPES_OPTIONS,
    };
  },
  methods: {
    getClaimLabel(searchItem) {
      let item = Object.values(this.claimTypes).find(function (item) {
        return item.value === searchItem;
      });

      return item ? item.label : "";
    },
  },
};
</script>
