<template>
  <div class="form patients-schedule patients-schedule-container">
    <div class="patients-schedule-inner">
      <div class="patients-schedule-inner-helper">
        <div class="patients-schedule-header">
          <div
            v-show="selectedDay !== null"
            class="patients-schedule-header-back patients-schedule-header-action"
            @click="handleBack()"
          >
            <svg
              width="15"
              height="13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.414 11.458l-4.635-5.16 4.635-4.84"
                stroke="#EB5F41"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M13.837 7.244a1 1 0 100-2v2zm-11.95 0h11.95v-2H1.887v2z"
                fill="#EB5F41"
              />
            </svg>
          </div>
          <div class="patients-schedule-header-image">
            <div class="patients-schedule-header-image">
              <template v-if="!clinicDataLoading">
                <img
                  class="image"
                  v-if="clinicData && clinicData.logo"
                  :src="clinicData.logo"
                  :alt="$store.state.user.clinic.clinic_slug + ' logo'"
                />
                <div v-else class="placeholder"></div>
              </template>
            </div>
          </div>
          <div
            class="patients-schedule-header-user patients-schedule-header-action"
            @click="handleUser()"
            ref="patientProfile"
          >
            <svg
              width="21"
              height="21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.917 17.958V16.29a3.333 3.333 0 00-3.334-3.333H6.917a3.333 3.333 0 00-3.334 3.333v1.667M10.25 9.625a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
                stroke="#EB5F41"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div class="patients-schedule-text">
          <div class="patients-schedule-text-upper">
            {{ $t("message.patientFormSchedule") }}
          </div>
          <div class="patients-schedule-text-lower">
            {{ $t("message.patientFormScheduleText") }}
          </div>
        </div>

        <div class="patients-schedule-content" :class="{ disabled: loading }">
          <!--        Part for choosing doctor/treatment and day-->
          <div v-if="selectedDay === null">
            <form class="patients-schedule-content-form">
              <div
                class="form-item"
                :class="{ error: validation.hasError('treatment') }"
              >
                <label class="item">{{ $t("label.treatmentType") }} </label>
                <v-select
                  label="name"
                  :options="treatments"
                  v-model="treatment"
                >
                  <template #option="{ name, id }">
                    <span>
                      <item-icon
                        type="treatment"
                        :class-name="colorClass(id, 'treatment')"
                      ></item-icon
                      >{{ name }}
                    </span>
                  </template>
                  <template #selected-option="{ name, id }">
                    <div>
                      <item-icon
                        type="treatment"
                        :class-name="colorClass(id, 'treatment')"
                      ></item-icon
                      >{{ name }}
                    </div>
                  </template>
                </v-select>
              </div>

              <div
                class="form-item"
                :class="{ error: validation.hasError('doctor') }"
              >
                <label class="item">{{ $t("label.practitioner") }} </label>
                <v-select
                  :getOptionLabel="nameOptionLabel"
                  :options="doctors"
                  v-model="doctor"
                >
                  <template #option="{ first_name, last_name, id }">
                    <span>
                      <item-icon
                        type="doctor-2"
                        :class-name="colorClass(id, 'doctor')"
                      ></item-icon
                      >{{ first_name + " " + last_name }}</span
                    >
                  </template>
                  <template #selected-option="{ first_name, last_name, id }">
                    <div>
                      <item-icon
                        type="doctor-2"
                        :class-name="colorClass(id, 'doctor')"
                      ></item-icon
                      >{{ first_name + " " + last_name }}
                    </div>
                  </template>
                </v-select>
              </div>
            </form>

            <div
              :class="{
                'patients-schedule-calendar': true,
                disabled: loading,
              }"
            >
              <div class="patients-schedule-calendar-actions" v-if="isMounted">
                <div
                  :class="{
                    'patients-schedule-calendar-arrow': true,
                    prev: true,
                    disabled: prevDisabled,
                  }"
                  @click.prevent="!prevDisabled && $refs.calSchedule.previous()"
                >
                  <svg
                    width="6"
                    height="12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.414 10.912L.779 5.752 5.414.913"
                      stroke="#EB5F41"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <div class="patients-schedule-calendar-actions-info">
                  <span class="label">
                    <strong>{{ $t("label.selectDay") }}</strong>
                  </span>
                  <span class="date">
                    {{ getMonthName($refs.calSchedule.view.startDate) }}
                    {{ $refs.calSchedule.view.startDate.getFullYear() }}</span
                  >
                </div>
                <div
                  :class="{
                    'patients-schedule-calendar-arrow': true,
                    next: true,
                    disabled: nextDisabled,
                  }"
                  @click.prevent="!nextDisabled && $refs.calSchedule.next()"
                >
                  <svg
                    width="7"
                    height="12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.264 10.912l4.634-5.16L1.264.913"
                      stroke="#EB5F41"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <vue-cal
                ref="calSchedule"
                active-view="month"
                class="vuecal-schedule"
                click-to-navigate
                hide-view-selector
                hideTitleBar
                :start-week-on-sunday="true"
                xsmall
                :style="'width: 310px'"
                :time="false"
                :transitions="false"
                :min-date="minimumDate ? minimumDate : new Date()"
                :max-date="maximumDate ? maximumDate : new Date()"
                :disable-days="disabledDays"
                :disable-views="['day', 'week', 'year']"
                :selected-date="selectedDay"
                @cell-click="setSelectedDay"
              >
                <template v-slot:weekday-heading="{ heading }">
                  <span class="calendar-header-label">{{
                    heading.label.substring(0, 3)
                  }}</span>
                </template>
                <template v-slot:cell-content="{ cell }">
                  <span>{{ cell.content }}</span>
                </template>
              </vue-cal>
              <div v-if="loading" class="loading"></div>
            </div>

            <!--            <div class="patients-schedule-timezone">-->
            <!--              <div class="icon icon-globe"></div>-->
            <!--              {{ $store.getters.timeZone }} ({{-->
            <!--                customFormat(timer, "hh:mm a")-->
            <!--              }})-->
            <!--            </div>-->
          </div>

          <!--        Part for selecting time-->
          <div
            v-if="selectedDay && selectedDateTime === null"
            className="patients-schedule-content-helper"
          >
            <patient-schedule-selected
              :doctor="doctor"
              :treatment="treatment"
              :selected-date-time="selectedDateTime"
              :selected-day="selectedDay"
            />
            <div class="patients-schedule-times">
              <div v-if="timesLoader" class="align-center">
                {{ $t("message.loading") }}...
              </div>

              <template v-else>
                <div class="patients-schedule-times-header">
                  <div class="margin-bottom-5">
                    <strong>{{ $t("label.selectTime") }}</strong>
                  </div>
                  <div>
                    {{ treatment.duration }}
                    {{ $t("label.minutes").toLowerCase() }}
                  </div>
                </div>
                <div class="patients-schedule-times-list">
                  <div
                    :class="{
                      'patients-schedule-times-item': true,
                      selected: index === dateTimeIndex,
                    }"
                    @click="dateTimeIndex = index"
                    v-for="(time, index) in availableHours"
                    :key="index"
                  >
                    <div class="patients-schedule-times-item-value">
                      {{ customFormat(time, "hh:mm a") }}
                    </div>
                    <div
                      class="patients-schedule-times-item-button button dark"
                      @click="selectedDateTime = time"
                      v-if="index === dateTimeIndex"
                    >
                      {{ $t("button.confirm") }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="selectedDateTime">
            <patient-schedule-selected
              :doctor="doctor"
              :treatment="treatment"
              :selected-date-time="selectedDateTime"
              :selected-day="selectedDay"
            />
            <div class="patients-schedule-notes">
              <div class="form-item">
                <label> {{ $t("clinic.patientShareAnything") }}: </label>
                <textarea
                  aria-label=""
                  class="note auto-resize"
                  @input="autoResize"
                  rows="4"
                  v-model="additionalNote"
                ></textarea>
              </div>
            </div>
            <div class="patients-schedule-action">
              <button class="button dark height" @click="addEvent">
                {{ $t("button.scheduleAppointment") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clinic-data
      @data-loaded="
        clinicData = $event;
        clinicDataLoading = false;
      "
    />
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import ClinicData from "@/views/public/pages/components/ClinicData";
import autoResize from "@/mixins/autoResize";
import axios from "axios";
import VueCal from "vue-cal";
import SimpleVueValidator from "simple-vue3-validator";
import PatientScheduleSelected from "@/views/private/components/patients/PatientScheduleSelected";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientSchedule",
  mixins: [autoResize, onboardTooltipMixin],
  components: { PatientScheduleSelected, ItemIcon, VueCal, ClinicData },
  data: function () {
    return {
      loading: true,
      clinicDataLoading: true,
      clinicData: null,
      isMounted: false,
      timer: null,
      additionalNote: "",
      selectedDay: null,
      selectedDateTime: null,
      dateTimeIndex: null,
      availableDays: [],
      availableHours: [],
      timesLoader: false,
      treatment: null,
      treatments: [],
      doctor: null,
      doctors: [],
      promises: [],
      cancelToken: { doctors: null, treatments: null, days: null, hours: null },
    };
  },
  created() {
    this.promises.push(this.loadDoctors());
    this.promises.push(this.loadTreatments());

    let scope = this;
    axios.all(this.promises).then(() => {
      scope.loading = false;
      scope.$nextTick(() => {
        scope.isMounted = true;
      });
    });

    // simple timer
    let start = Date.now();
    setInterval(function () {
      let delta = Date.now() - start;
      Math.floor(delta / 1000);
      scope.timer = new Date().toUTCString();
    }, 1000);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  watch: {
    treatment: function (val) {
      if (val && this.doctor) {
        this.loadDays();
      }
    },
    doctor: function (val) {
      if (val && this.treatment) {
        this.loadDays();
      }
    },
  },
  computed: {
    disabledDays() {
      let min = this.getMinDate(this.availableDays);
      let max = this.getMaxDate(this.availableDays);
      let days = [];

      if (!this.availableDays.length) {
        days.push(new Date().format("YYYY-MM-DD"));
      }

      while (this.isBefore(min, max)) {
        if (
          !this.availableDays.some(
            (d) => d.format("YYYY-MM-DD") === min.format("YYYY-MM-DD")
          )
        ) {
          days.push(min.format("YYYY-MM-DD"));
        }
        min = min.addDays(1);
      }

      return days;
    },
    minimumDate() {
      if (!this.availableDays.length) {
        return false;
      }

      return this.getMinDate(this.availableDays);
    },
    maximumDate() {
      if (!this.availableDays.length) {
        return false;
      }

      return this.getMaxDate(this.availableDays);
    },
    prevDisabled() {
      if (!this.minimumDate) {
        return true;
      }

      return (
        this.isMounted &&
        this.minimumDate &&
        this.$refs.calSchedule &&
        this.minimumDate.getMonth() ===
          this.$refs.calSchedule.view.startDate.getMonth()
      );
    },
    nextDisabled() {
      if (!this.maximumDate) {
        return true;
      }

      return (
        this.isMounted &&
        this.maximumDate &&
        this.$refs.calSchedule &&
        this.maximumDate.getMonth() ===
          this.$refs.calSchedule.view.startDate.getMonth()
      );
    },
  },
  validators: {
    treatment: function (value) {
      return Validator.value(value).required();
    },
    doctor: function (value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    loadDoctors() {
      setCancelToken(this.cancelToken, "doctors");
      return HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        data: {},
        cancelToken: this.cancelToken.doctors.token,
      })
        .then((response) => {
          if (response.data) {
            this.doctors = response.data;
          }
        })
        .catch(() => {});
    },
    loadTreatments() {
      setCancelToken(this.cancelToken, "treatments");
      return HTTP({
        method: "GET",
        url: "/api/v1/treatments",
        data: {},
        cancelToken: this.cancelToken.treatments.token,
      })
        .then((response) => {
          if (response.data) {
            this.treatments = response.data;
          }
        })
        .catch(() => {});
    },
    setSelectedDay(date) {
      this.$validate();
      if (this.treatment) {
        this.selectedDay = date.format("YYYY-MM-DD");
        this.loadHours();
      }
    },
    handleUser() {
      this.$router.push({
        name: "patientDetails",
        params: {
          id: this.$store.state.user.id,
        },
      });
    },
    handleBack() {
      if (this.selectedDateTime) {
        this.dateTimeIndex = null;
        this.selectedDateTime = null;
        return;
      }

      if (this.selectedDay) {
        this.dateTimeIndex = null;
        this.isMounted = false;
        this.selectedDay = null;
        let scope = this;
        scope.$nextTick(() => {
          scope.isMounted = true;
        });
        return;
      }
    },
    loadDays() {
      let scope = this;
      scope.availableDays = [];
      setCancelToken(this.cancelToken, "days");
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/available-events/days",
        params: {
          doctor: {
            id: scope.doctor.id,
          },
          treatment: {
            id: scope.treatment.id,
          },
        },
        cancelToken: scope.cancelToken.days.token,
      })
        .then((response) => {
          this.loading = false;
          if (response.data) {
            scope.availableDays = response.data.map(function (item) {
              return scope.parseFromUTC(item);
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadHours() {
      let scope = this;
      scope.availableHours = [];
      setCancelToken(this.cancelToken, "hours");
      this.timesLoader = true;
      HTTP({
        method: "GET",
        url: "/api/v1/available-events/hours",
        params: {
          doctor: {
            id: scope.doctor.id,
          },
          treatment: {
            id: scope.treatment.id,
          },
          date: scope.parseToUTC(scope.selectedDay).format("YYYY-MM-DD"),
        },
        cancelToken: scope.cancelToken.hours.token,
      })
        .then((response) => {
          if (response.data) {
            scope.availableHours = response.data.map(function (item) {
              return scope.parseFromUTC(item);
            });
          }
          this.timesLoader = false;
        })
        .catch(() => {
          this.timesLoader = false;
        });
    },
    addEvent() {
      setCancelToken(this.cancelToken, "event");
      let eventData = {
        start: this.selectedDateTime,
        online: true,
        end: this.selectedDateTime
          ? this.selectedDateTime.addMinutes(this.treatment.duration)
          : null,
        doctor: {
          id: this.doctor.id,
        },
        treatment: {
          id: this.treatment.id,
        },
        room: null,
        status: "pending",
        patient: {
          id: this.$store.state.user.id,
        },
        notes: this.additionalNote,
        reason_for_visit: null,
      };
      eventData = this.preparePostDataWithDates(eventData);
      this.loading = true;

      HTTP({
        method: "POST",
        url: "/api/v1/events",
        data: eventData,
        cancelToken: this.cancelToken.event.token,
      })
        .then((response) => {
          this.loading = false;
          if (response.data) {
            this.$notify({
              group: "notification",
              type: "success",
              title: this.$t("notification.thumbsUp"),
              text: this.$t("notification.appointmentCreated"),
            });
            this.$router.push({ name: "patientThankYou" });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.appointmentNotCreated"),
          });
        });
    },
  },
};
</script>
