<template>
  <div class="form center password">
    <form ref="passwordResetForm" @submit.prevent="submit()" v-show="!loading">
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.email'),
        }"
      >
        <label class="item">{{ $t("label.email") }}: </label>
        <input v-model="form.email" type="text" ref="email" />
      </div>
      <div class="form-item action">
        <button class="button action" type="submit">
          {{ $t("button.sendResetRequest") }}
        </button>
      </div>
    </form>
    <div v-show="loading">{{ $t("message.loading") }}...</div>
  </div>
</template>

<script>
import { HTTP_PUBLIC } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PasswordResetEmail",
  metaInfo() {
    return {
      title: "Password Reset Email",
    };
  },
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
  },
  data() {
    return {
      loading: false,
      form: {
        email: null,
      },
    };
  },
  methods: {
    submit() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          HTTP_PUBLIC({
            method: "POST",
            url: "/api/v1/password/email",
            data: self.form,
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.passwordSent"),
                text: self.$t("notification.checkEmail"),
              });
              self.$router.push({ name: "login" }).catch(() => {});
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.emailNotFound"),
              });
              self.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
