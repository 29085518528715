<template>
  <div class="modal" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>
                  <div class="icon-edit"></div>
                  Rename {{ this.type }}
                </div>
                <div class="icon-close-round" @click.prevent="closeModal"></div>
              </div>
            </div>
          </div>

          <div class="modal-dialog-main-section">
            <div class="modal-dialog-main-form">
              <div
                class="form-item"
                :class="{
                  error: validation.hasError('fileName'),
                }"
              >
                <label class="item"
                  >{{ this.type === "file" ? "File" : "Folder" }} Name:
                </label>
                <input ref="fileName" v-model="fileName" type="text" />
              </div>
              <div class="form-item action no-padding">
                <a class="button action" @click.prevent="save()"> Save </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import { default as EventBus } from "@/services/eventbus";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "FileRename",
  props: ["type", "file"],
  data() {
    return {
      fileName: this.file.name,
    };
  },
  created() {
    //
  },
  mounted() {
    this.$refs.fileName.focus();
    this.$refs.fileName.setSelectionRange(
      0,
      this.fileName.lastIndexOf(".")
        ? this.fileName.lastIndexOf(".")
        : this.fileName.length
    );
  },
  validators: {
    fileName: function (value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      let url =
        this.type === "file" ? "/api/v1/files/" : "/api/v1/files/folders/";
      let method = this.type === "file" ? "POST" : "PUT";
      url += this.file.id;
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          HTTP({
            method: method,
            url: url,
            data: {
              name: self.fileName,
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.thumbsUp"),
                text: self.$t("notification.documentRenamed", {
                  type: self.type === "file" ? "File" : "Folder",
                }),
              });
              EventBus.$emit("documents-load");
              self.closeModal();
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.documentNotRenamed", {
                  type: self.type,
                }),
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
