<template>
  <div class="modal details" id="active-modal" v-if="!loading">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>{{ $t("label.timeOff") }}</div>
              </div>
            </div>
          </div>
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item">
                  <item-icon
                    type="clock"
                    :class-name="colorClass(null)"
                  ></item-icon>
                  <div class="form-item-value">
                    {{
                      customFormat(
                        eventData.start,
                        $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                      )
                    }}
                    -
                    {{
                      customFormat(
                        eventData.end,
                        $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                      )
                    }}
                    <br />
                    <span>
                      {{ customFormat(eventData.start, "EEEE d, y") }}</span
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div
                    :class="
                      'icon-indicator  ' + statusColorClass(eventData.status)
                    "
                  ></div>
                  <div class="form-item-value">
                    <template v-if="eventData.status === 'scheduled'">
                      {{ $t("label.eventStatusScheduled") }}
                    </template>
                    <template v-if="eventData.status === 'canceled'">
                      {{ $t("label.eventStatusCancel") }}
                    </template>
                    <template v-if="eventData.status === 'checked-in'">
                      {{ $t("label.eventStatusCheckIn") }}
                    </template>
                    <template v-if="eventData.status === 'pending'">
                      {{ $t("label.eventStatusPending") }}
                    </template>
                  </div>
                </div>
                <div class="form-item">
                  <item-icon
                    type="doctor-2"
                    :class-name="
                      colorClass(
                        eventData.doctor ? eventData.doctor.id : null,
                        'doctor'
                      )
                    "
                  ></item-icon>
                  <div
                    class="form-item-value"
                    :class="{ error: !eventData.doctor }"
                  >
                    {{
                      eventData.doctor
                        ? eventData.doctor.first_name +
                          " " +
                          eventData.doctor.last_name
                        : $t("message.editAndSelect", {
                            type: $tc("label.doctor", 1).toLowerCase(),
                          })
                    }}
                  </div>
                </div>
              </div>
              <div
                class="modal-dialog-main-section"
                v-if="eventData.notes !== null"
              >
                <div
                  class="modal-dialog-info-label details"
                  v-if="eventData.notes"
                >
                  {{ $tc("label.note", 2) }}:
                  <div class="modal-dialog-info-value details">
                    {{ eventData.notes }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-item action" v-if="eventData.until === null">
            <a
              :class="{
                'button error disable-close': true,
              }"
              @click.prevent="stopRecurrence"
            >
              {{ $t("button.stopRecurrence") }}
            </a>
          </div>
          <div class="form-item action" v-else>
            <div class="form-item-value">
              <div class="color-error">
                {{ $t("label.until") }}
                {{ customFormat(eventData.until, "MMMM do, y") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading"></div>
</template>

<script>
import { HTTP } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import { default as EventBus } from "@/services/eventbus";

export default {
  name: "TimeOffDetail",
  props: ["event"],
  components: { ItemIcon },
  data() {
    return {
      loading: false,
      eventData: {},
      params: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    loadData() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/events/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.eventData = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    stopRecurrence() {
      this.loading = true;
      HTTP({
        method: "POST",
        url: "/api/v1/events/" + this.$route.params.id + "/recurrence/stop",
      })
        .then(() => {
          this.loadData();
          EventBus.$emit("event-load");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
