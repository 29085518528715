<template>
  <div
    class="inventory-single-folder"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
    :class="{ hover: isHovering }"
  >
    <div class="inventory-single-folder-top">
      <div class="icon icon-folder"></div>
    </div>
    <div class="inventory-single-folder-bottom">
      <div class="inventory-single-folder-bottom-sku">{{ folder.sku }}</div>
      <div
        class="inventory-single-folder-bottom-title"
        v-tooltip="folder.name"
        @click.prevent="setActiveFolder(folder)"
      >
        {{ folder.name }}
      </div>
      <div class="inventory-single-folder-bottom-footer">
        <div class="inventory-single-folder-bottom-footer-qty">
          {{ folder.products ? folder.products.length : 0 }}
        </div>
        <div class="inventory-single-folder-bottom-footer-separator"></div>
        <div class="inventory-single-folder-bottom-footer-price">
          {{ formatPrice(folderPrice) }}
        </div>
      </div>
      <div class="inventory-single-folder-bottom-actions-wrapper">
        <div class="inventory-single-folder-bottom-actions">
          <folder-options-menu :folder="folder"></folder-options-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FolderOptionsMenu from "@/views/private/components/inventory/FolderOptionsMenu";
export default {
  name: "InventoryViewFolder",
  components: { FolderOptionsMenu },
  props: {
    folder: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    folderPrice() {
      let price = 0;
      if (this.folder.products) {
        this.folder.products.forEach(function (item) {
          price += parseFloat(item.price);
        });
      }
      return price.toFixed(2);
    },
  },
  methods: {
    setActiveFolder: function (folder) {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventory",
        type: "folder",
        data: folder,
      });
    },
  },
};
</script>
