<template>
  <div class="calendar-main-inner-calendar-cell">
    <div class="vuecal__cell-date" :class="view.id" v-if="view.id === 'month'">
      {{ cell.content }}
    </div>
    <template v-if="view.id === 'month'">
      <div
        v-if="$store.state.focusedFilter === 'doctors'"
        class="calendar-main-inner-calendar-events"
      >
        <div
          class="calendar-main-inner-calendar-events-item"
          v-for="event in computedEvents"
          :key="'doctor_cell_' + event.id"
          :class="[
            colorClass(
              event.type !== 'time_off' ? event.doctor.id : null,
              'doctor'
            ),
            {
              'color-stripes':
                event.status === 'pending' || event.type === 'time_off',
            },
            {
              disabled: event.type === 'time_off',
            },
          ]"
          @click="showDetail(event)"
        >
          <span
            v-if="event.patient"
            :class="{
              'calendar-main-inner-calendar-events-item-name': true,
              patient: true,
              disabled: event.patient.status === 'inactive',
            }"
            >{{
              event.patient.first_name + " " + event.patient.last_name
            }}</span
          >
          <span
            class="calendar-main-inner-calendar-events-item-icon"
            v-if="event.status === 'checked-in'"
            title="checked-in"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          </span>
          <span
            class="calendar-main-inner-calendar-events-item-icon"
            v-if="event.status === 'checked-out'"
            title="checked-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4 12 14.01l-3-3" />
            </svg>
          </span>
          <span
            class="calendar-main-inner-calendar-events-item-icon"
            v-if="event.status === 'canceled'"
            title="canceled"
          >
            🚫
          </span>
          <span
            class="calendar-main-inner-calendar-events-item-icon"
            v-if="event.patient && event.patient.guardian"
            title="guardian"
          >
            🧒
          </span>
          <span
            :class="{
              'calendar-main-inner-calendar-events-item-name': true,
              disabled: event.doctor.status === 'inactive',
            }"
          >
            {{ event.doctor.first_name + " " + event.doctor.last_name }}
          </span>
          <span class="calendar-main-inner-calendar-events-item-time">
            <template v-if="isBefore(event.start, cell.startDate)">
              +{{ diffInCalDays(cell.startDate, event.start) }}
            </template>
            <template v-else>
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </span>
        </div>
      </div>
      <div
        v-else-if="$store.state.focusedFilter === 'rooms'"
        class="calendar-main-inner-calendar-events"
      >
        <div
          class="calendar-main-inner-calendar-events-item"
          v-for="event in computedEvents"
          :key="'room_cell_' + event.id"
          :class="colorClass(event.room ? event.room.id : null, 'room')"
          @click="showDetail(event)"
        >
          <span class="calendar-main-inner-calendar-events-item-name patient">{{
            event.patient.first_name + " " + event.patient.last_name
          }}</span>
          <span
            class="calendar-main-inner-calendar-events-item-name"
            v-if="event.room"
            >{{ event.room.name }}</span
          >
          <span class="calendar-main-inner-calendar-events-item-time">
            <template v-if="isBefore(event.start, cell.startDate)">
              +{{ diffInCalDays(cell.startDate, event.start) }}
            </template>
            <template v-else>
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </span>
        </div>
      </div>
      <div
        v-else-if="$store.state.focusedFilter === 'treatments'"
        class="calendar-main-inner-calendar-events"
      >
        <div
          class="calendar-main-inner-calendar-events-item"
          v-for="event in computedEvents"
          :key="'treatment_cell_' + event.id"
          :class="colorClass(event.treatment.id, 'treatment')"
          @click="showDetail(event)"
        >
          <span class="calendar-main-inner-calendar-events-item-name patient">{{
            event.patient.first_name + " " + event.patient.last_name
          }}</span>
          <span
            class="calendar-main-inner-calendar-events-item-name"
            v-if="event.treatment.name"
            >{{ event.treatment.name }}</span
          >
          <span class="calendar-main-inner-calendar-events-item-time">
            <template v-if="isBefore(event.start, cell.startDate)">
              +{{ diffInCalDays(cell.startDate, event.start) }}
            </template>
            <template v-else>
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </span>
        </div>
      </div>
      <div
        v-else-if="$store.state.focusedFilter === 'statuses'"
        class="calendar-main-inner-calendar-events"
      >
        <div
          class="calendar-main-inner-calendar-events-item"
          v-for="event in computedEvents"
          :key="'treatment_cell_' + event.id"
          :class="statusColorClass(event.status)"
          @click="showDetail(event)"
        >
          <span class="calendar-main-inner-calendar-events-item-name patient">{{
            event.patient.first_name + " " + event.patient.last_name
          }}</span>
          <span
            class="calendar-main-inner-calendar-events-item-name"
            v-if="event.status"
            >{{ event.status }}</span
          >
          <span class="calendar-main-inner-calendar-events-item-time">
            <template v-if="isBefore(event.start, cell.startDate)">
              +{{ diffInCalDays(cell.startDate, event.start) }}
            </template>
            <template v-else>
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </span>
        </div>
      </div>
      <div
        v-else-if="$store.state.focusedFilter === 'others'"
        class="calendar-main-inner-calendar-events"
      >
        <div
          class="calendar-main-inner-calendar-events-item"
          v-for="event in computedEvents"
          :key="'other_cell_' + event.id"
          :class="colorClass(event.other.id)"
          @click="showDetail(event)"
        >
          <span
            class="calendar-main-inner-calendar-events-item-name"
            v-if="event.other.name"
            >{{ event.other.name }}</span
          >
          <span class="calendar-main-inner-calendar-events-item-time">
            <template v-if="isBefore(event.start, cell.startDate)">
              +{{ diffInCalDays(cell.startDate, event.start) }}
            </template>
            <template v-else>
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const INDEXES = {
  doctors: "doctor",
  rooms: "room",
  treatments: "treatment",
  other: "other",
};

export default {
  name: "CalendarSlotMonthCell",
  props: ["cell", "view", "events"],
  data() {
    return {};
  },
  computed: {
    computedEvents: function () {
      let scope = this;
      return scope.events.filter((item) => {
        let index = INDEXES[scope.$store.state.focusedFilter];
        return item[index] !== null;
      });
    },
  },
  methods: {
    showDetail(event) {
      if (event.type === "time_off") {
        this.$router.push({
          name: "timeOffDetail",
          params: {
            id: event.id,
          },
        });
        return;
      }

      this.$router.push({
        name: "eventDetail",
        params: {
          id: event.id,
        },
      });
    },
  },
};
</script>
