<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <template v-if="!loading">
      <div class="list-top">
        <div class="list-top-text">
          {{ $tc("label.user", 1) }}
        </div>
        <div class="button action" @click.prevent="saveDoctor">
          {{ $t("button.save") }}
          <div class="icon icon-add-big"></div>
        </div>
      </div>
      <div class="list-items">
        <form @submit.prevent="">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newDoctor.first_name'),
            }"
          >
            <label class="item">{{ $t("label.firstName") }}: </label>
            <input ref="firstName" v-model="newDoctor.first_name" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newDoctor.last_name'),
            }"
          >
            <label class="item">{{ $t("label.lastName") }}: </label>
            <input ref="lastName" v-model="newDoctor.last_name" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newDoctor.email'),
            }"
          >
            <label class="item">{{ $t("label.email") }}: </label>
            <input ref="email" v-model="newDoctor.email" type="text" />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.title") }}: </label>
            <input ref="title" v-model="newDoctor.title" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newDoctor.phone_number'),
            }"
          >
            <label class="item">{{ $t("label.phoneNo") }}: </label>
            <input
              ref="phone_number"
              v-model="newDoctor.phone_number"
              type="text"
            />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.address") }} 1: </label>
            <input ref="address1" v-model="newDoctor.address_1" type="text" />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.address") }} 2: </label>
            <input ref="address2" v-model="newDoctor.address_2" type="text" />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.hourFormat") }}: </label>
            <v-select
              :options="hourFormatOptions"
              v-model="newDoctor.hour_format"
            >
            </v-select>
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.licenseNo") }}: </label>
            <input ref="licenseNo" v-model="newDoctor.license_no" type="text" />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.color") }}: </label>
            <color-picker
              :model-value="newDoctor.color"
              @update:modelValue="updateColor"
              :preset-colors="[
                '#CF5B5B',
                '#1897E3',
                '#3EAD6C',
                '#725FD0',
                '#CDAC02',
              ]"
            ></color-picker>
          </div>
        </form>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import { Sketch } from "@ckpack/vue-color";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "DoctorView",
  components: { "color-picker": Sketch },
  validators: {
    "newDoctor.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newDoctor.last_name": function (value) {
      return Validator.value(value).required();
    },
    "newDoctor.email": function (value) {
      return Validator.value(value).required();
    },
  },
  data: function () {
    return {
      newDoctor: {
        first_name: null,
        last_name: null,
        email: null,
        title: null,
        hour_format: null,
        address_1: null,
        address_2: null,
        license_no: null,
        phone_number: null,
        color: "#CF5B5B",
      },
      hourFormatOptions: ["12-hours", "24-hours"],
      loading: false,
      observer: null,
    };
  },
  mounted() {
    this.loadDoctor();
  },
  methods: {
    loadDoctor() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/doctors/" + this.$store.state.user.id,
      })
        .then((response) => {
          if (response.data) {
            this.newDoctor = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveDoctor() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url = "/api/v1/doctors/" + self.$store.state.user.id;
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newDoctor);
          HTTP({
            method: "PUT",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.injectColor(
                  "doctors",
                  response.data.id ?? null,
                  response.data.color ?? null
                );
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    updateColor(color) {
      this.newDoctor.color = color.hex;
    },
  },
};
</script>
