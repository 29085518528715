<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-inner-block">
        <div class="sidebar-inner-block-title">{{ $t("label.settings") }}</div>
        <div class="sidebar-inner-block-items">
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'clinic' }"
            @click="setType('clinic')"
          >
            <div class="name">{{ $tc("label.clinic", 1) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'providers' }"
            @click="setType('providers')"
          >
            <div class="name">{{ $tc("label.provider", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'rooms' }"
            @click="setType('rooms')"
          >
            <div class="name">{{ $tc("label.rooms", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'treatments' }"
            @click="setType('treatments')"
          >
            <div class="name">{{ $t("label.treatments") }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'plans' }"
            @click="setType('plans')"
          >
            <div class="name">{{ $tc("label.plan", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'insurance-providers' }"
            @click="setType('insurance-providers')"
          >
            <div class="name">{{ $tc("label.insuranceProvider", 2) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'charge-codes' }"
            @click="setType('charge-codes')"
          >
            <div class="name">
              {{ $tc("label.charge", 1) + " " + $tc("label.code", 2) }}
            </div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'pos-terminals' }"
            @click="setType('pos-terminals')"
          >
            <div class="name">
              {{ $t("label.posTerminals") }}
            </div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            @click="handleLogout"
          >
            <div class="name">{{ $t("button.logout") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/services/api";

export default {
  name: "SettingsSidebar",
  props: {
    selectedType: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {
    setType(type) {
      this.$router
        .push({ name: "settings", params: { type: type } })
        .catch(() => {});
    },
    handleLogout() {
      logout();
    },
  },
};
</script>
