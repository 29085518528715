<template>
  <div
    :style="
      width !== ''
        ? 'flex-basis: ' + width + '; min-width: ' + width
        : 'flex: 1;'
    "
  >
    <div
      class="button action"
      :class="{ disabled: !enabled }"
      :style="width !== '' ? 'margin: 0px 5px;' : 'flex: 1;'"
      @click.prevent.stop="$emit('button-pressed')"
    >
      {{ buttonLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TableButton",
  props: {
    buttonLabel: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
