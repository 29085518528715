<template>
  <div class="filter-menu-item">
    <div class="filter-menu-item-title">
      <span
        :class="{
          'filter-menu-item-title-label': true,
          focused: isFilterFocused,
        }"
        @click="focusFilter"
        >Rooms</span
      >
      <span class="filter-menu-item-title-select" @click="selectAll"
        >Select all</span
      >
    </div>
    <template v-for="(room, idx) in rooms">
      <template v-if="typeof room !== 'undefined'">
        <div
          :key="'room' + idx + room.id + 'parent'"
          :class="'filter-item ' + colorClass(room.id, 'room')"
        >
          <div class="filter-item-icon">
            <item-icon
              type="room"
              :class-name="colorClass(room.id, 'room')"
            ></item-icon>
          </div>
          <label :for="'room_' + room.id"
            >{{ room.name }}
            <input
              type="checkbox"
              class="filter-item-checkbox"
              :id="'room_' + room.id"
              v-bind:value="room.id"
              v-model="checkedValues"
              @change="updateFilters"
            />
            <span class="filter-item-checkbox-checkmark"></span>
          </label>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
export default {
  name: "FilterMenuRooms",
  props: ["rooms", "type"],
  components: { ItemIcon },
  data() {
    return {
      checkedValues:
        typeof this.$store.state.filter.rooms !== "undefined"
          ? this.$store.state.filter.rooms
          : [],
    };
  },
  mounted() {
    this.updateFilters = this.asDebounce(this.updateFilters, 500);
  },
  computed: {
    isFilterFocused() {
      return this.$store.state.focusedFilter === "rooms";
    },
  },
  methods: {
    updateFilters() {
      this.$store.dispatch("setFilter", {
        rooms: true,
        values: this.checkedValues,
      });
    },
    selectAll() {
      if (this.checkedValues.length === this.rooms.length) {
        this.checkedValues = [];
      } else {
        this.checkedValues = this.rooms.map(function (item) {
          return item.id;
        });
      }
      this.updateFilters();
    },
    focusFilter() {
      this.$store.dispatch("setFocusedFilter", "rooms");
    },
  },
};
</script>

<style scoped></style>
