<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") }} {{ $tc("label.insuranceEob", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="insuranceWallet"
          :sortArray="[
            {
              label: $t('label.name'),
              direction: 'asc',
              key: 'name',
            },
            {
              label: $t('label.insuranceProvider'),
              sortable: false,
              key: 'insurance_provider.name',
            },
            {
              label: $t('label.checkNumber'),
              direction: 'asc',
              key: 'check_number',
            },
            {
              label: $t('label.initialAmount'),
              key: 'initial_amount',
              width: '160px',
            },
            {
              label: $t('label.balance'),
              key: 'balance',
              width: '160px',
            },
            {
              label: $t('label.created'),
              direction: 'asc',
              key: 'created_at',
              width: '290px',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- insuranceWallets-->
        <div class="list-items-section-wrapper">
          <div
            class="list-items-section-item"
            v-for="insuranceWallet in insuranceWallets"
            :key="'insuranceWallet_' + insuranceWallet.id"
          >
            <div class="name">
              {{ insuranceWallet.name }}
            </div>
            <div class="name">
              {{
                insuranceWallet.insurance_provider
                  ? insuranceWallet.insurance_provider.name
                  : ""
              }}
            </div>
            <div class="name">
              {{ insuranceWallet.check_number }}
            </div>
            <div class="price">
              {{ insuranceWallet.initial_amount }}
            </div>
            <div class="price">
              {{ insuranceWallet.balance }}
            </div>
            <div class="created">
              {{ formatDateTime(insuranceWallet.created_at) }}
            </div>
            <insurance-wallet-options-menu
              :insuranceWallet="insuranceWallet"
            ></insurance-wallet-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.insuranceWallet.pagination.page"
        :perPage="$store.state.insuranceWallet.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.insuranceWallet.pagination.page"
        :perPage="$store.state.insuranceWallet.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, setCancelToken, cancelTokens } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import InsuranceWalletOptionsMenu from "@/views/private/components/insurance/InsuranceWalletOptionsMenu";

export default {
  name: "InsuranceWalletView",
  props: ["path"],
  components: {
    InsuranceWalletOptionsMenu,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      insuranceWallets: [],
      pages: [1],
      allResults: 0,
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadInsuranceWallets();
  },
  created() {
    EventBus.$on("insurance-wallets-load", () => {
      this.loadInsuranceWallets();
    });
  },
  unmounted() {
    EventBus.$off("insurance-wallets-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.insuranceWallet": {
      deep: true,
      handler: function () {
        this.loadInsuranceWallets();
      },
    },
  },
  methods: {
    loadInsuranceWallets() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-wallet",
        params: {
          q: this.$store.state.insuranceWallet.search,
          sort_by: this.$store.state.insuranceWallet.sortOrder,
          page: this.$store.state.insuranceWallet.pagination.page,
          per_page: this.$store.state.insuranceWallet.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.insuranceWallets = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "insuranceWallet",
        data: value,
      });
    },
    handleCreateButton() {
      this.$router.push({
        name: "insuranceWalletAdd",
      });
    },
  },
};
</script>
