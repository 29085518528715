<template>
  <doctors-agenda v-if="$store.state.focusedFilter === 'doctors'" />
  <rooms-agenda v-if="$store.state.focusedFilter === 'rooms'" />
  <treatments-agenda v-if="$store.state.focusedFilter === 'treatments'" />
  <statuses-agenda v-if="$store.state.focusedFilter === 'statuses'" />
</template>

<script>
import DoctorsAgenda from "@/views/private/components/agenda/DoctorsAgenda";
import RoomsAgenda from "@/views/private/components/agenda/RoomsAgenda";
import TreatmentsAgenda from "@/views/private/components/agenda/TreatmentsAgenda";
import StatusesAgenda from "@/views/private/components/agenda/StatusesAgenda";
export default {
  name: "AgendaComponent",
  components: {
    StatusesAgenda,
    TreatmentsAgenda,
    RoomsAgenda,
    DoctorsAgenda,
  },
};
</script>
