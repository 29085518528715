import { IMaskDirective } from "vue-imask";

export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      masks: {
        phone: {
          mask: "+{1}(000)000-0000",
          lazy: false,
        },
      },
    };
  },
  methods: {
    parsePhoneValue(event) {
      const maskRef = event.detail;
      // eslint-disable-next-line no-unused-vars
      let value = maskRef.unmaskedValue.replaceAll(/ /g, "");
      return value.length > 1 ? value : null;
    },
  },
};
