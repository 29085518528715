<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="page">
        <div v-if="!loading">
          <div>
            <form @submit.prevent="">
              <div
                class="form-item"
                :class="{ error: validation.hasError('eventData.patient') }"
              >
                <label class="item">Name: </label>
                <v-select
                  :getOptionLabel="nameOptionLabel"
                  v-model="eventData.patient"
                  @search="searchPatients"
                  :disabled="true"
                >
                </v-select>
              </div>

              <div
                class="form-item"
                :class="{
                  error:
                    validation.hasError('eventData.start') ||
                    validation.hasError('eventData.end'),
                }"
              >
                <section>
                  <date-time-range
                    ref="dateTimeRange"
                    from-now
                    :startDate="this.handleDateTimeValue(eventData.start)"
                    :endDate="this.handleDateTimeValue(eventData.end)"
                    :showLabel="true"
                    :showInput="true"
                    label="When:"
                    @start-date="setStart"
                    @end-date="setEnd"
                  ></date-time-range>
                </section>
              </div>
              <div
                class="form-item"
                :class="{ error: validation.hasError('eventData.treatment') }"
              >
                <label class="item">What: </label>
                <v-select
                  label="name"
                  :options="options[0].treatments"
                  v-model="eventData.treatment"
                ></v-select>
              </div>
              <div
                class="form-item"
                :class="{ error: validation.hasError('eventData.doctor') }"
              >
                <label class="item">Who: </label>
                <v-select
                  :getOptionLabel="nameOptionLabel"
                  :options="options[0].doctors"
                  v-model="eventData.doctor"
                >
                </v-select>
              </div>
              <div
                class="form-item"
                :class="{ error: validation.hasError('eventData.room') }"
              >
                <label class="item">Where: </label>
                <v-select
                  label="name"
                  :options="options[0].rooms"
                  v-model="eventData.room"
                ></v-select>
              </div>
              <div class="form-item">
                <a class="button action disable-close" @click="updateEvent"
                  >Update</a
                >
                <a class="button action" @click="$router.go(-1)">Back</a>
              </div>
            </form>
          </div>
          <div>
            <PatientCreate :newPatient="eventData.patient" />
          </div>
        </div>
        <div v-else class="loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import PatientCreate from "@/views/private/components/modals/events/PatientCreate";
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
export default {
  name: "EventEditDetailed",
  components: {
    DateTimeRange: () => import("@/views/private/components/DateTimeRange"),
    PatientCreate,
  },
  validators: {
    "eventData.doctor": function (value) {
      return Validator.value(value).required();
    },
    "eventData.patient": function (value) {
      return Validator.value(value).required();
    },
    "eventData.treatment": function (value) {
      return Validator.value(value).required();
    },
    "eventData.start": function (value) {
      return Validator.value(value).required();
    },
    "eventData.end": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      loading: false,
      patientCreate: false,
      eventData: {},
      params: [],
      options: [
        {
          doctors: [],
          rooms: [],
          treatments: [],
        },
      ],
      patients: [],
    };
  },
  created() {
    this.loadData();
    this.loadOptions();
  },
  methods: {
    loadOptions() {
      let self = this;
      HTTP({
        method: "GET",
        url: "/api/v1/filters",
      }).then((response) => {
        if (response.data) {
          self.options = response.data;
        }
        self.searchPatients();
      });
    },
    setStart(val) {
      this.eventData.start = val;
    },
    setEnd(val) {
      this.eventData.end = val;
    },
    reset: function () {
      this.$refs.forms.forEach(function (form) {
        return form.reset();
      });
    },
    loadData() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/events/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.eventData = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    searchPatients(text = "") {
      let params = {};
      if (text !== "") {
        params.q = text;
      }
      params._limit = 10;
      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        params: params,
      }).then((response) => {
        if (response.data) {
          this.patients = response.data.data;
        }
      });
    },
    updateEvent() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          HTTP({
            method: "PUT",
            url: "/api/v1/events/" + self.$route.params.id,
            data: self.eventData,
          }).then((response) => {
            if (response.status === 200) {
              self.$router.push({
                name: "calendar",
                params: { view: self.$store.state.currentView.type },
              });
              self.loading = false;
            }
          });
        }
      });
    },
  },
};
</script>
