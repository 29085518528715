// import dateMixin from "@/mixins/dateMixin";

export default {
  // mixins: [dateMixin],
  methods: {
    removeObjectFromArray(originalArray, removeValue, removeKey = "id") {
      let helperArray = JSON.parse(JSON.stringify(originalArray));
      let index = helperArray.findIndex(function (item) {
        return item[removeKey] === removeValue;
      });

      if (index > -1) {
        helperArray.splice(index, 1);
      }

      return helperArray;
    },
    asDebounce(func, delay) {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          debounceTimer = null;
          func.apply(context, args);
        }, delay);
      };
    },
    codeOptionLabel(option) {
      if (typeof option === "object") {
        if (
          // eslint-disable-next-line
          !option.hasOwnProperty("name") ||
          // eslint-disable-next-line
          !option.hasOwnProperty("code")
        ) {
          // eslint-disable-next-line
          return console.warn(
            `[vue-select warn]: option does not exist in object`
          );
        }
        return option["name"] + " - " + option["code"];
      }
      return option;
    },
    nameOptionLabel(option) {
      if (typeof option === "object") {
        if (
          // eslint-disable-next-line
          !option.hasOwnProperty("first_name") ||
          // eslint-disable-next-line
          !option.hasOwnProperty("last_name")
        ) {
          // eslint-disable-next-line
          return console.warn(
            `[vue-select warn]: option does not exist in object`
          );
        }
        return option["first_name"] + " " + option["last_name"];
      }
      return option;
    },
    createdAtOptionLabel(option) {
      if (typeof option === "object") {
        if (
          // eslint-disable-next-line
          !option.hasOwnProperty("created_at")
        ) {
          // eslint-disable-next-line
          return console.warn(
            `[vue-select warn]: option does not exist in object`
          );
        }
        return this.formatDateTime(option["created_at"]);
      }
      return option;
    },
    camelCase(str) {
      return str
        .replace(/-/g, " ")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index == 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
    },
    generateFormData: function (data, form, namespace) {
      let formData = form || new FormData();
      let formKey;

      if (data.length === 0 && namespace !== "undefined") {
        formData.append(namespace, []);
      }

      for (const entry in data) {
        if (typeof data[entry] === "undefined") {
          continue;
        }

        if (namespace) {
          formKey = namespace + "[" + entry + "]";
        } else {
          formKey = entry;
        }

        if (data[entry] === null) {
          data[entry] = "";
          formData.append(formKey, data[entry]);
          continue;
        }

        if (Array.isArray(data[entry])) {
          this.generateFormData(data[entry], formData, formKey);
        } else {
          formData.append(formKey, data[entry]);
        }
      }

      return formData;
    },
    getbase64EncodedImage(item) {
      return "data:image/jpeg;base64," + item.replace(/(\r\n|\n|\r)/gm, "");
    },
    routeIn(route) {
      // check if route is array of routes
      if (typeof route === "object") {
        return route.indexOf(this.$route.name) > -1;
      }

      return this.$route.name === route;
    },
    routeNotIn(route) {
      // check if route is array of routes
      if (typeof route === "object") {
        return route.indexOf(this.$route.name) === -1;
      }

      return this.$route.name !== route;
    },
    isInactiveDoctor(doctor) {
      return doctor && doctor.status === "inactive";
    },
    isInactivePatient(patient) {
      return patient && patient.status === "inactive";
    },
    formatPrice(
      value = 0,
      currency = "usd",
      fractionDigitsMin = 2,
      fractionDigitsMax = 2
    ) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigitsMin,
        maximumFractionDigits: fractionDigitsMax,
      });

      return formatter.format(value);
    },
    formatPercent(value, fractionDigitsMin = 2, fractionDigitsMax = 2) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: fractionDigitsMin,
        maximumFractionDigits: fractionDigitsMax,
      });

      return formatter.format(parseFloat(value) / 100);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber || phoneNumber.length !== 11) {
        return phoneNumber;
      }

      let formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
      formattedPhoneNumber = `+${formattedPhoneNumber.slice(
        0,
        1
      )} (${formattedPhoneNumber.slice(1, 4)}) ${formattedPhoneNumber.slice(
        4,
        7
      )}-${formattedPhoneNumber.slice(7, 11)}`;
      return formattedPhoneNumber;
    },
    formatError(error, fallback = "") {
      if (error.details && error.details.length) {
        return error.details.join("<br>");
      }

      if (error.message) {
        return error.message;
      }

      return fallback;
    },
  },
};
