<template>
  <div class="form center login">
    <div v-if="!loading">
      <div v-show="success">
        <p>{{ $t("message.userVerification") }}</p>
        <router-link to="/login">{{ $t("button.login") }}</router-link>
      </div>
      <div v-show="!success">
        <p>
          {{ $t("message.userVerificationFailed") }}
        </p>
      </div>
    </div>

    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, logout } from "@/services/api";
export default {
  name: "UserVerification",
  data: function () {
    return {
      success: false,
      loading: false,
    };
  },
  mounted() {
    let self = this;
    self.loading = true;
    HTTP({
      method: "GET",
      url: "/api/v1/email/verify/" + this.$route.params.token,
    })
      .then((response) => {
        if (response.status === 200) {
          self.success = true;
        }
        self.loading = false;
      })
      .catch(() => {
        self.loading = false;
        logout();
      });
  },
};
</script>

<style scoped></style>
