<template>
  <div class="form patients-schedule patients-schedule-container">
    <div class="patients-schedule-inner">
      <div class="patients-schedule-inner-helper">
        <h1 class="patients-schedule-header-title align-center">
          {{ $t("label.success") }}
        </h1>
        <span class="patients-schedule-header-text align-center">
          {{ $t("message.passwordResetSent") }}
        </span>

        <div class="patients-schedule-action margin-top-20">
          <router-link
            :to="{
              name: 'patientLogin',
              params: { clinic_slug: $route.params.clinic_slug },
            }"
            class="button action height"
          >
            {{ $t("button.loginTogether") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patientPasswordResetSuccess",
};
</script>
