<template>
  <div class="form center password">
    <form ref="passwordResetForm" @submit.prevent="submit()" v-show="!loading">
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.password'),
        }"
      >
        <label class="item">{{ $t("label.password") }}: </label>
        <input v-model="form.password" type="password" ref="password" />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.password'),
        }"
      >
        <label class="item">{{ $t("label.passwordConfirmation") }}: </label>
        <input
          v-model="form.password_confirmation"
          type="password"
          ref="password_confirmation"
        />
      </div>
      <div class="form-item action">
        <button class="button action" type="submit">
          {{ $t("button.reset") }}
        </button>
      </div>
    </form>
    <div v-show="loading">{{ $t("message.loading") }}...</div>
  </div>
</template>

<script>
import { HTTP_PUBLIC } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PasswordResetForm",
  metaInfo() {
    return {
      title: "Password Reset",
    };
  },
  validators: {
    "form.password": function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "form.password_confirmation, form.password": function (
      password_confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(password_confirmation)
          .required()
          .match(password);
      }
    },
  },
  data() {
    return {
      loading: false,
      form: {
        token: this.$route.params.token,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    submit() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          HTTP_PUBLIC({
            method: "POST",
            url: "/api/v1/password/reset",
            data: self.form,
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.done"),
                text: self.$t("notification.passwordUpdated"),
              });
              self.$router.push({ name: "login" }).catch(() => {});
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.passwordNotUpdated"),
              });
              self.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
