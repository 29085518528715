<template>
  <div class="inventory-main">
    <div class="inventory-main-header">
      <div class="inventory-main-header-top">
        <div class="label">
          {{
            $store.state.inventory.folder
              ? $store.state.inventory.folder.name
              : $t("label.allItems")
          }}
        </div>
        <dropdown-options
          v-if="!$store.state.inventory.folder"
          ref="addDropdown"
          class="button-action"
          :wrapperRef="'addDropdownWrapper'"
          :only-dropdown="true"
          :options="[
            { label: $t('button.addItem'), value: 'item' },
            { label: $t('button.addFolder'), value: 'folder' },
          ]"
          :selected-option="null"
          @value-change="handleAddButton"
        >
          <div
            class="button action"
            ref="addDropdownWrapper"
            @click="$refs.addDropdown.toggleOptions()"
          >
            {{ $t("button.addItemOrFolder") }}
            <div class="icon icon-add-big"></div>
          </div>
        </dropdown-options>
        <router-link v-else class="button action" to="/inventory/item/">
          {{ $t("button.addItem") }}
          <div class="icon icon-add-big"></div>
        </router-link>
      </div>
      <div class="inventory-main-header-bottom">
        <router-link
          :to="'/inventory/advanced-search'"
          class="button transparent"
        >
          {{ $t("button.advancedSearch") }}</router-link
        >
        <div class="inventory-main-header-bottom-sort-item">
          <dropdown-options
            :componentRef="'sortDropdown'"
            :label="$t('label.sortBy') + ':'"
            :options="sortOptions"
            :selected-option="$store.state.inventory.sort"
            :separator="$store.state.inventory.folder === null"
            @value-change="setSort"
          ></dropdown-options>
        </div>
        <div
          class="inventory-main-header-bottom-sort-item"
          v-if="$store.state.inventory.folder === null"
        >
          <dropdown-options
            :componentRef="'showDropdown'"
            :label="'Show:'"
            :options="showOptions"
            :selected-option="$store.state.inventoryShow"
            @value-change="setShow"
          ></dropdown-options>
        </div>
      </div>
    </div>
    <div class="inventory-main-view">
      <div class="inventory-main-view-counter">
        {{
          ($store.state.inventory.pagination.page - 1) *
            $store.state.inventory.pagination.perPage +
          (results.length ? 1 : 0)
        }}
        -
        {{
          $store.state.inventory.pagination.page *
            $store.state.inventory.pagination.perPage >
          results.length
            ? results.length
            : $store.state.inventory.pagination.page *
              $store.state.inventory.pagination.perPage
        }}
        of
        {{ this.count }}
      </div>
      <div class="inventory-main-view-items">
        <template
          v-if="
            $store.state.inventory.folder === null &&
            $store.state.inventoryShow.key !== 'items'
          "
        >
          <div class="inventory-main-view-title" v-if="folders.length">
            {{ $tc("label.folder", 2) }}
          </div>
          <inventory-view-folder
            v-for="folder in folders"
            :folder="folder"
            :key="'folder_' + folder.id"
          ></inventory-view-folder>
        </template>
        <template
          v-if="
            $store.state.inventory.folder !== null ||
            ($store.state.inventory.folder === null &&
              $store.state.inventoryShow.key !== 'folders')
          "
        >
          <div class="inventory-main-view-title" v-if="results.length">
            {{ $tc("label.item", 2) }}
          </div>
          <inventory-view-file
            v-for="file in results"
            :file="file"
            :key="'file_' + file.id"
          ></inventory-view-file>
        </template>
      </div>
    </div>
    <div class="inventory-main-footer">
      <pagination-per-page
        :page="$store.state.inventory.pagination.page"
        :perPage="$store.state.inventory.pagination.perPage"
        :allResults="results.length"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.inventory.pagination.page"
        :perPage="$store.state.inventory.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import DropdownOptions from "../DropdownOptions";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import InventoryViewFolder from "./InventoryViewFolder";
import InventoryViewFile from "./InventoryViewFile";
import { default as EventBus } from "@/services/eventbus";
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
export default {
  name: "InventoryView",
  components: {
    InventoryViewFolder,
    InventoryViewFile,
    DropdownOptions,
    PaginationPerPage,
    PaginationPage,
  },
  data() {
    return {
      sortOptions: [
        {
          key: "desc",
          label: this.$t("label.lastAdded"),
        },
        {
          key: "asc",
          label: this.$t("label.firstAdded"),
        },
      ],
      showOptions: [
        {
          key: "both",
          label: this.$t("label.itemsFolders"),
        },
        {
          key: "items",
          label: this.$t("label.itemsOnly"),
        },
        {
          key: "folders",
          label: this.$t("label.foldersOnly"),
        },
      ],
      results: [],
      folders: [],
      pages: [1],
      count: 0,
      cancelToken: {
        foldersLoad: null,
        productsLoad: null,
      },
    };
  },
  watch: {
    "$store.state.inventory.search": function () {
      this.loadFolders();
    },
    "$store.state.inventory.sort": {
      deep: true,
      handler: function () {
        this.loadFolders();
      },
    },
    "$store.state.inventory": {
      deep: true,
      handler: function () {
        this.loadData();
        if (
          this.$store.state.inventory.folder === null &&
          this.folders.length === 0
        ) {
          this.loadFolders();
        }
      },
    },
  },
  created() {
    EventBus.$on("inventory-load", () => {
      this.loadData();
    });
    EventBus.$on("inventory-folders-load", () => {
      this.loadFolders();
    });
  },
  unmounted() {
    EventBus.$off("inventory-load");
    EventBus.$off("inventory-folders-load");
    cancelTokens(this.cancelToken);
  },
  mounted() {
    this.loadData();
    this.loadFolders();
  },
  methods: {
    setSort(value) {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventory",
        type: "sort",
        data: value,
      });
    },
    handleAddButton(object) {
      if (object.value === "folder") {
        EventBus.$emit("open-modal", {
          type: "create-inventory-folder",
        });
      }
      if (object.value === "item") {
        this.$router.push({ name: "inventoryItem" });
      }
    },
    loadFolders() {
      if (
        !(
          this.$store.state.inventory.folder === null &&
          this.$store.state.inventoryShow.key !== "items"
        )
      ) {
        return;
      }

      setCancelToken(this.cancelToken, "foldersLoad");

      this.folders = [];
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/folders/",
        data: {},
        cancelToken: this.cancelToken.foldersLoad.token,
        params: {
          name: this.$store.state.inventory.search,
          with_products: 1,
          order_by: "created_at",
          order_direction: this.$store.state.inventory.sort.key,
        },
      })
        .then((response) => {
          if (response.data) {
            this.folders = response.data.data;
          }
        })
        .catch(() => {});
    },
    loadData() {
      setCancelToken(this.cancelToken, "productsLoad");

      this.results = [];
      this.count = 0;
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/products/",
        data: {},
        cancelToken: this.cancelToken.productsLoad.token,
        params: {
          folder: this.$store.state.inventory.folder
            ? { id: this.$store.state.inventory.folder.id }
            : null,
          tags: this.$store.state.inventory.tag
            ? [this.$store.state.inventory.tag]
            : null,
          term: this.$store.state.inventory.search,
          order_by: "created_at",
          order_direction: this.$store.state.inventory.sort.key,
          per_page: this.$store.state.inventory.pagination.perPage,
          page: this.$store.state.inventory.pagination.page,
        },
      })
        .then((response) => {
          if (response.data) {
            this.results = response.data.data;
            (this.count = response.data.meta.total), (this.pages = []);
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setShow(value) {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventoryShow",
        type: "",
        data: value,
      });
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", { type: "inventory", data: value });
    },
  },
};
</script>
