<template>
  <div class="patients-form-consent">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.informedConsent") }}
      </div>

      <div class="form-item patients-form-consent-text">
        By signing below, I hereby request and consent to the performance of
        acupuncture, massage therapy, nutrition/health counseling and/or reiki
        treatments and other procedures within the scope of the practice of
        these respective forms of care on me (or on the patient named below, for
        whom I am legally responsible) by a licensed practitioner in the
        practice listed above. I understand that acupuncturists, massage
        therapists and reiki providers are not primary care providers and that
        regular primary care by a licensed physician is an important choice that
        is strongly recommended.
      </div>
      <div class="form-item patients-form-consent-text">
        I understand that acupuncture is performed by the insertion of single
        use, sterile needles through the skin, at certain points on or near the
        surface of the body in an attempt to treat bodily dysfunction or
        diseases, to modify or prevent pain perception, and to normalize the
        body’s physiological functions. I understand that methods of treatment
        related to acupuncture may include, but are not limited to, acupuncture,
        moxibustion, cupping, electrical stimulation, Tui-Na (Chinese massage),
        Chinese herbal medicine, and nutritional counseling. I understand that
        the herbs may need to be prepared and the teas consumed according to the
        instructions provided orally and in writing. The herbs may be an
        unpleasant smell or taste. I will immediately notify a member of the
        clinical staff of any unanticipated or unpleasant effects associated
        with the consumption of the herbs.
      </div>
      <div class="form-item patients-form-consent-text">
        Acupuncture typically involves safe methods of treatment, however,
        certain adverse side effects may result. These unusual side effects
        could include, but are not limited to, minor bruising or bleeding,
        soreness, numbness or tingling near the needling sites that may last a
        few days, dizziness, fainting, infection, spontaneous miscarriage, nerve
        damage and organ puncture, including lung puncture (pneumothorax). Burns
        and/or scarring are a potential risk of moxibustion and cupping, or when
        treatment involves the use of heat lamps. Bruising is a common side
        effect of cupping. The herbs and nutritional supplements (which are from
        plant, animal and mineral sources) that have been recommended are
        traditionally considered safe in the practice of Chinese Medicine,
        although some may be toxic in large doses. I understand that some herbs
        may be inappropriate during pregnancy. Some possible side effects of
        taking herbs are nausea, gas, stomachache, vomiting, headache, diarrhea,
        rashes, hives, and tingling of the tongue. I will notify a clinical
        staff member who is caring for me if I am or become pregnant. I do not
        expect the clinical staff to be able to anticipate and explain all
        possible risks and complications of treatment, and I wish to rely on the
        clinical staff to exercise judgment during the course of treatment. I
        understand that results are not guaranteed and that I am free to stop
        the treatment at any time. I understand that while this document
        describes the major risks of treatment, albeit rare, there are other
        side effects that may occur.
      </div>
      <div class="form-item patients-form-consent-text">
        I understand the clinical and administrative staff may review my patient
        records and lab reports, but all my records will be kept confidential
        and will not be released without my written consent.
      </div>
      <div class="form-item patients-form-consent-text">
        By voluntarily signing below, I show that I have carefully read, or have
        had read to me, all of the above information and have been told about
        the risks and benefits of acupuncture, massage therapy, and other
        procedures. I have had an opportunity to ask questions and am fully
        aware of what I am signing. I intend this consent form to cover the
        entire course of treatment for my present condition and for any future
        condition(s) for which I seek treatment.
      </div>

      <!--      signature -->
      <div class="form-item double">
        <div class="form-item-section">
          <div class="form-item-section-title with-icon">
            {{ $t("label.signature") }}
            <div class="remove" @click.prevent.stop="clearSignature">
              <div class="icon icon-trash"></div>
            </div>
          </div>
          <div class="signature-wrapper">
            <canvas
              id="signature-pad"
              class="signature-pad"
              width="400"
              height="200"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import { HTTP_PUBLIC } from "@/services/api";
import fileMixin from "@/mixins/fileMixin";
import formMixin from "@/mixins/formMixin";

export default {
  name: "PatientConsentFormInformed",
  mixins: [fileMixin, formMixin],
  watch: {},
  data: function () {
    return {
      formValues: {
        insurance_signature_image: null,
      },
      signaturePad: null,
      canvas: null,
    };
  },
  created() {
    this.checkNextStep(this.$route.params.token);
  },
  mounted() {
    this.canvas = document.getElementById("signature-pad");
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });
  },
  methods: {
    resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    },
    clearSignature() {
      this.formValues.insurance_signature_image = null;
      this.signaturePad.clear();
      let self = this;
      this.$nextTick(() => {
        self.resizeCanvas();
      });
    },
    nextTab() {
      if (this.signaturePad.isEmpty()) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.signAndFillRequired"),
        });
        return;
      }

      this.formValues.insurance_signature_image =
        this.signaturePad.toDataURL("image/jpg");

      let formData = this.prepareConsentForm(this.formValues);

      HTTP_PUBLIC({
        method: "POST",
        url:
          "/api/v1/patient-records/" + this.$route.params.token + "/consents",
        data: {
          contract_key: "informed",
          additional_data: formData,
        },
      })
        .then(() => {
          this.checkNextStep(this.$route.params.token);
        })
        .catch();
    },
  },
};
</script>
