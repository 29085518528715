<template>
  <div
    class="modal-wrapper"
    :class="{ open: typeof this.modelValue.type !== 'undefined' }"
    @mousedown="modalClick"
  >
    <transition name="fade" mode="out-in">
      <event-detail
        v-if="typeEquals('event-detail')"
        :event="modelValue"
        @close="close"
      />
      <time-off-detail
        v-else-if="typeEquals('time-off-detail')"
        :event="modelValue"
        @close="close"
      />
      <event-create
        v-else-if="typeEquals(['event-create', 'event-edit'])"
        @close="close"
      />
      <event-create-detailed
        v-else-if="typeEquals('event-create-detailed')"
        @close="close"
        ref="event-create-detailed"
      />
      <insurance-wallet-create
        v-else-if="typeEquals(['wallet-create', 'wallet-edit'])"
        @close="close"
        ref="insurance-wallet-create"
      />
      <rename-file
        v-else-if="typeEquals('rename-file')"
        :file="this.modelValue.file"
        :type="this.modelValue.fileType"
        @close="close"
      />
      <create-folder v-else-if="typeEquals('create-folder')" @close="close" />
      <add-to-campaign
        v-else-if="typeEquals('add-to-campaign')"
        :file="this.modelValue.file"
        @close="close"
      />
      <remove-file
        v-else-if="typeEquals('remove-file')"
        :file="this.modelValue.file"
        :type="this.modelValue.fileType"
        @close="close"
      />
      <update-quantity
        v-else-if="typeEquals('update-quantity')"
        :file="this.modelValue.file"
        @close="close"
      />
      <create-inventory-folder
        v-else-if="typeEquals('create-inventory-folder')"
        @close="close"
      />
      <rename-inventory-folder
        v-else-if="typeEquals('rename-inventory-folder')"
        :folder="this.modelValue.folder"
        @close="close"
      />
      <doctor-create
        v-else-if="typeEquals(['doctor-create', 'doctor-edit'])"
        @close="close"
      />
      <doctor-change-password
        v-else-if="typeEquals(['doctor-change-password'])"
        :doctor="this.modelValue.item"
        @close="close"
      />
      <doctor-change-status
        v-else-if="typeEquals(['doctor-change-status'])"
        @close="close"
      />
      <plan-create
        v-else-if="typeEquals(['plan-create', 'plan-edit'])"
        @close="close"
      />
      <charge-create
        v-else-if="typeEquals(['charge-create', 'charge-edit'])"
        @close="close"
      />
      <room-create
        v-else-if="typeEquals(['room-create', 'room-edit'])"
        @close="close"
      />
      <pos-create
        v-else-if="typeEquals(['pos-create', 'pos-edit'])"
        @close="close"
      />
      <treatment-create
        v-else-if="typeEquals(['treatment-create', 'treatment-edit'])"
        @close="close"
      />
      <patient-create
        v-else-if="typeEquals(['patient-create', 'patient-edit'])"
        @close="close"
      />
      <patient-details
        v-else-if="typeEquals(['patient-details'])"
        @close="close"
      />
      <patient-change-status
        v-else-if="typeEquals(['patient-change-status'])"
        @close="close"
      />
      <add-payment-data
        v-else-if="typeEquals('add-payment-data')"
        :patient="this.modelValue.patient"
        :callback="this.modelValue.callback"
        @close="close"
      />
      <payment-method
        v-else-if="typeEquals('payment-method')"
        :patient="this.modelValue.patient"
        :callback="this.modelValue.callback"
        :checkoutTotal="this.modelValue.checkoutTotal"
        :mode="this.modelValue.mode"
        @close="close"
      />
      <admin-user-create
        v-else-if="typeEquals(['admin-user-create', 'admin-user-edit'])"
        @close="close"
      />
      <remove-item
        v-else-if="typeEquals('remove-item')"
        :item="this.modelValue.item"
        :type="this.modelValue.removeType"
        @close="close"
      />
      <change-wallet
        v-else-if="typeEquals('change-wallet')"
        :discardCallback="this.modelValue.discardCallback"
        @close="close"
      />
      <order-details
        v-else-if="typeEquals('order-details')"
        @close="close"
        :order-id="this.modelValue.orderId"
        :patient-id="this.modelValue.patientId"
      />
      <insurance-ledger-adjustments
        v-else-if="typeEquals('insurance-ledger-adjustments')"
        @close="close"
        :item="this.modelValue.item"
        :insurance-wallet-id="this.modelValue.insuranceWalletId"
        :callback="this.modelValue.callback"
      />
      <insurance-provider-create
        v-else-if="
          typeEquals(['insurance-provider-create', 'insurance-provider-edit'])
        "
        @close="close"
      />
    </transition>
  </div>
</template>

<script>
import AddPaymentData from "@/views/private/components/modals/patients/AddPaymentData";
import AddToCampaign from "@/views/private/components/modals/documents/AddToCampaign";
import AdminUserCreate from "@/views/private/components/modals/settings/admin/UserCreate";
import ChargeCreate from "@/views/private/components/modals/settings/ChargeCreate";
import CreateFolder from "@/views/private/components/modals/documents/CreateFolder";
import CreateInventoryFolder from "@/views/private/components/modals/inventory/CreateFolder";
import DoctorChangePassword from "@/views/private/components/modals/settings/DoctorChangePassword";
import DoctorChangeStatus from "@/views/private/components/modals/settings/DoctorChangeStatus";
import DoctorCreate from "@/views/private/components/modals/settings/DoctorCreate";
import EventBus from "@/services/eventbus";
import EventCreate from "@/views/private/components/modals/events/EventCreate";
import EventCreateDetailed from "@/views/private/components/modals/events/EventCreateDetailed";
import EventDetail from "@/views/private/components/modals/events/EventDetail";
import InsuranceWalletCreate from "@/views/private/components/modals/insurance/InsuranceWalletCreate";
import PatientChangeStatus from "@/views/private/components/modals/patients/PatientChangeStatus";
import PatientCreate from "@/views/private/components/modals/patients/PatientCreate";
import PatientDetails from "@/views/private/components/modals/patients/PatientDetails";
import PaymentMethod from "@/views/private/components/modals/payments/PaymentMethod";
import PlanCreate from "@/views/private/components/modals/settings/PlanCreate";
import PosCreate from "@/views/private/components/modals/settings/PosCreate";
import RemoveFile from "@/views/private/components/modals/documents/RemoveFile";
import RemoveItem from "@/views/private/components/modals/settings/RemoveItem";
import RenameFile from "@/views/private/components/modals/documents/RenameFile";
import RenameInventoryFolder from "@/views/private/components/modals/inventory/RenameFolder";
import RoomCreate from "@/views/private/components/modals/settings/RoomCreate";
import TimeOffDetail from "@/views/private/components/modals/events/TimeOffDetail";
import TreatmentCreate from "@/views/private/components/modals/settings/TreatmentCreate";
import UpdateQuantity from "@/views/private/components/modals/inventory/UpdateQuantity";
import ChangeWallet from "@/views/private/components/modals/insurance/ChangeWallet";
import OrderDetails from "@/views/private/components/modals/orders/OrderDetails";
import InsuranceLedgerAdjustments from "@/views/private/components/modals/insurance/UpdateAdjustment";
import InsuranceProviderCreate from "@/views/private/components/modals/settings/InsuranceProviderCreate.vue";

export default {
  name: "RootModal",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: {
    InsuranceProviderCreate,
    InsuranceLedgerAdjustments,
    OrderDetails,
    ChangeWallet,
    AddPaymentData,
    AddToCampaign,
    AdminUserCreate,
    ChargeCreate,
    CreateFolder,
    CreateInventoryFolder,
    DoctorChangePassword,
    DoctorChangeStatus,
    DoctorCreate,
    EventCreate,
    EventCreateDetailed,
    EventDetail,
    InsuranceWalletCreate,
    PatientChangeStatus,
    PatientCreate,
    PatientDetails,
    PaymentMethod,
    PlanCreate,
    PosCreate,
    RemoveFile,
    RemoveItem,
    RenameFile,
    RenameInventoryFolder,
    RoomCreate,
    TimeOffDetail,
    TreatmentCreate,
    UpdateQuantity,
  },
  data() {
    return {
      clickedAway: false,
    };
  },
  watch: {
    modelValue: function (value) {
      this.clickedAway = value.type === "event-create-detailed";
    },
  },
  mounted() {
    document.body.addEventListener("keyup", this.escapeListener, true);
  },
  unmounted() {
    document.body.removeEventListener("keyup", this.escapeListener, true);
  },
  created() {
    EventBus.$on("modal-clickaway", (value) => {
      this.clickedAway = value;
    });
  },
  beforeUnmount() {
    EventBus.$off("modal-clickaway");
  },
  methods: {
    escapeListener(event) {
      if (
        event.keyCode === 27 &&
        typeof this.modelValue.type !== "undefined" &&
        this.modelValue.type !== ""
      ) {
        if (["onboarding"].indexOf(this.modelValue.type) === -1) {
          this.close();
        }
      }
    },
    modalClick(event) {
      //return if clicked on some of next elements
      if (
        ["add-patient", "button action disable-close"].indexOf(
          event.target.className
        ) > -1
      ) {
        return;
      }

      let element = document.getElementById("active-modal");
      if (
        element &&
        event.target !== element &&
        !element.contains(event.target)
      ) {
        this.close();
      }
    },
    typeEquals(type) {
      // check if type is array
      if (typeof type === "object") {
        return type.indexOf(this.modelValue.type) > -1;
      }

      return this.modelValue.type === type;
    },
    close() {
      if (this.clickedAway) {
        this.$refs["event-create-detailed"] &&
          this.$refs["event-create-detailed"].handleDiscard();
        return;
      }
      this.clickedAway = false;
      this.$emit("update:modelValue", {});
      if (
        this.routeNotIn([
          "adminSettings",
          "adminUserAdd",
          "adminUserEdit",
          "calendar",
          "chargeAdd",
          "chargeEdit",
          "doctorAdd",
          "doctorChangeStatus",
          "doctorEdit",
          "documents",
          "inventory",
          "inventoryAdvancedSearch",
          "insurance",
          "insuranceWalletAdd",
          "insuranceWalletEdit",
          "insuranceProviderAdd",
          "insuranceProviderEdit",
          "patientAdd",
          "patientChangeStatus",
          "patientDetails",
          "patientEdit",
          "patients",
          "paymentsPatient",
          "planAdd",
          "planEdit",
          "posAdd",
          "posEdit",
          "roomAdd",
          "roomEdit",
          "settings",
          "treatmentAdd",
          "treatmentEdit",
          "insurance",
        ])
      ) {
        this.$router.push({
          name: "calendar",
          params: { view: this.$store.state.currentView.type },
        });
      }

      if (this.routeIn(["doctorAdd", "doctorEdit", "doctorChangeStatus"])) {
        this.$router.push({ name: "settings", params: { type: "providers" } });
      }

      if (this.routeIn(["patientDetails"])) {
        this.$router.push({
          name: "patientSchedule",
        });
      }

      if (this.routeIn(["planAdd", "planEdit"])) {
        this.$router.push({ name: "settings", params: { type: "plans" } });
      }

      if (this.routeIn(["chargeAdd", "chargeEdit"])) {
        this.$router.push({
          name: "settings",
          params: { type: "charge-codes" },
        });
      }

      if (this.routeIn(["patientAdd", "patientEdit", "patientChangeStatus"])) {
        this.$router.push({ name: "patients" });
      }

      if (this.routeIn(["adminUserAdd", "adminUserEdit"])) {
        this.$router.push({ name: "adminSettings", params: { type: "users" } });
      }

      if (this.routeIn(["roomAdd", "roomEdit"])) {
        this.$router.push({ name: "settings", params: { type: "rooms" } });
      }

      if (this.routeIn(["insuranceProviderAdd", "insuranceProviderEdit"])) {
        this.$router.push({
          name: "settings",
          params: { type: "insurance-providers" },
        });
      }

      if (this.routeIn(["posAdd", "posEdit"])) {
        this.$router.push({
          name: "settings",
          params: { type: "pos-terminals" },
        });
      }

      if (this.routeIn(["insuranceWalletAdd", "insuranceWalletEdit"])) {
        this.$router.push({
          name: "insurance",
          params: { type: "insurance-eob" },
        });
      }

      if (this.routeIn(["treatmentAdd", "treatmentEdit"])) {
        this.$router.push({ name: "settings", params: { type: "treatments" } });
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave {
  opacity: 0;
}
</style>
