<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.editInsuranceEob")
                          : $t("label.addNewInsuranceEob")
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newInsuranceWallet.name'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <input
                      ref="name"
                      v-model="newInsuranceWallet.name"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newInsuranceWallet.insurance_provider'
                      ),
                    }"
                  >
                    <label class="item"
                      >{{ $t("label.insuranceProvider") }}:
                    </label>
                    <v-select
                      label="name"
                      :autoscroll="false"
                      :options="insuranceProviders.options"
                      :filterable="false"
                      v-model="newInsuranceWallet.insurance_provider"
                      @search="searchInsuranceProviders"
                    >
                      <template #option="{ name }">
                        <span>
                          {{ name }}
                        </span>
                      </template>
                      <template #no-options="{ search }">
                        <vue-select-searching
                          :search="search"
                          :loading="insuranceProviders.loading"
                          :results-count="insuranceProviders.options.length"
                        />
                      </template>
                      <template #list-footer>
                        <load-more
                          :message="$t('message.loading') + '...'"
                          :show-load-more="
                            insuranceProviders.query.hasMore &&
                            insuranceProviders.options.length > 0
                          "
                          @load-more="loadMore"
                        />
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newInsuranceWallet.check_number'
                      ),
                    }"
                  >
                    <label class="item">{{ $t("label.checkNumber") }}: </label>
                    <input
                      ref="check_number"
                      v-model="newInsuranceWallet.check_number"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newInsuranceWallet.initial_amount'
                      ),
                    }"
                  >
                    <label class="item"
                      >{{ $t("label.initialAmount") }}:
                    </label>
                    <input
                      ref="initialAmount"
                      v-model="newInsuranceWallet.initial_amount"
                      type="number"
                      :readonly="
                        isModeEdit() && !newInsuranceWallet.editable
                          ? true
                          : undefined
                      "
                    />
                  </div>
                  <div class="form-item" v-if="isModeEdit()">
                    <label class="item">{{ $t("label.balance") }}: </label>
                    <input
                      ref="balance"
                      v-model="newInsuranceWallet.balance"
                      readonly
                      type="number"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newInsuranceWallet.description'
                      ),
                    }"
                  >
                    <label class="item">{{ $t("label.description") }}: </label>
                    <input
                      ref="amount"
                      v-model="newInsuranceWallet.description"
                      type="text"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="saveInsuranceWallet()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";
import LoadMore from "@/views/shared/components/LoadMore.vue";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching.vue";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "InsuranceWalletCreate",
  components: { VueSelectSearching, LoadMore },
  validators: {
    "newInsuranceWallet.name": function (value) {
      return Validator.value(value).required();
    },
    "newInsuranceWallet.insurance_provider": function (value) {
      return Validator.value(value).required();
    },
    "newInsuranceWallet.initial_amount": function (value) {
      if (this.isModeEdit()) {
        return;
      }
      return Validator.value(value).required();
    },
    "newInsuranceWallet.check_number": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      newInsuranceWallet: {
        name: null,
        initial_amount: null,
        description: null,
        check_number: null,
        insurance_provider: null,
      },
      insuranceProviders: {
        loading: false,
        options: [],
        query: {
          text: "",
          page: 1,
          per_page: 10,
          sort_by: ["name|asc"],
          hasMore: true,
        },
      },
      cancelToken: {
        insuranceProvidersLoad: null,
      },
      loading: false,
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadInsuranceWallet();
      }
      this.loadInsuranceProviders();
    },
    isModeEdit() {
      return "insuranceWalletEdit" === this.$route.name;
    },
    loadMore() {
      this.insuranceProviders.query.page =
        this.insuranceProviders.query.page + 1;
      this.loadInsuranceProviders();
    },
    searchInsuranceProviders(text = "") {
      this.insuranceProviders.query.text = text;
      this.insuranceProviders.query.page = 1;
      this.insuranceProviders.query.hasMore = true;
      this.insuranceProviders.options = [];
      this.loadInsuranceProviders();
    },
    loadInsuranceProviders() {
      let params = {};

      params.q = this.insuranceProviders.query.text;
      params.page = this.insuranceProviders.query.page;
      params.per_page = this.insuranceProviders.query.per_page;
      params.sort_by = this.insuranceProviders.query.sort_by;

      this.insuranceProviders.loading = true;

      setCancelToken(this.cancelToken, "insuranceProvidersLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-providers",
        data: {},
        cancelToken: this.cancelToken.insuranceProvidersLoad.token,
        params: params,
      })
        .then((response) => {
          this.insuranceProviders.loading = false;
          if (response.data) {
            if (response.data.data.length < 10) {
              this.insuranceProviders.query.hasMore = false;
            }
            this.insuranceProviders.options =
              this.insuranceProviders.options.concat(response.data.data);
          }
        })
        .catch(() => (this.insuranceProviders.loading = false));
    },
    loadInsuranceWallet() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-wallet/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newInsuranceWallet = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveInsuranceWallet() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/purchases/insurance-wallet/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newInsuranceWallet);

          if (self.isModeEdit() && !self.newInsuranceWallet.editable) {
            delete postData["initial_amount"];
          }

          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.loading = false;
                self.closeModal();
                EventBus.$emit("insurance-wallets-load");
              }
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
              self.loading = false;
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
