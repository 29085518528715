<template>
  <div class="payment-tab-single-item">
    <template v-if="item.object">
      <div class="payment-tab-single-item-top">
        <div class="payment-tab-single-item-top-info">
          <div class="payment-tab-single-item-top-info-item">
            {{ $t("label.name") }}:
            <span class="value">{{ item.object.name }}</span>
          </div>
          <div class="payment-tab-single-item-top-info-item">
            {{ $t("label.price") }}:
            <span class="value">{{ formatPrice(item.object.price) }}</span>
          </div>
          <div class="payment-tab-single-item-top-info-item">
            {{ $t("label.availableQty") }}:
            <span class="value">{{ item.object.quantity }}</span>
          </div>
        </div>
      </div>
      <div class="payment-tab-single-item-bottom">
        <div class="payment-tab-single-item-bottom-item">
          <div class="form-item nowrap">
            <div class="label">{{ $t("label.qty") }}:</div>
            <input
              type="number"
              min="0"
              :max="itemMutated.object.quantity"
              @change="updateQuantityHandler(itemMutated)"
              v-model="itemMutated.quantity"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="payment-tab-single-item-bottom">
        <div class="payment-tab-single-item-bottom-item">
          {{ $t("label.productNotFound") }}
        </div>
      </div>
    </template>
    <div class="payment-tab-single-item-bottom-remove">
      <div class="remove" @click.prevent="removeItem">
        <div class="icon icon-trash"></div>
        {{ $t("label.remove") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrescriptionProduct",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      itemMutated: JSON.parse(JSON.stringify(this.item)),
    };
  },
  watch: {},
  methods: {
    updateQuantityHandler(item) {
      this.$emit("update-quantity", item);
    },
    removeItem() {
      this.$emit("remove", true);
    },
  },
};
</script>
