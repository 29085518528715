<template>
  <div class="row">
    <div class="col-lg-12 patients">
      <sidebar></sidebar>
      <patients-view></patients-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/patients/Sidebar";
import PatientsView from "@/views/private/components/patients/PatientsView";
export default {
  name: "PatientIndex",
  components: {
    PatientsView,
    Sidebar,
  },
};
</script>
