<template>
  <div class="pagination-per-page">
    <div class="pagination-per-page-label" v-if="showLabel">View:</div>
    <div class="pagination-per-page-options">
      <v-select
        :options="options"
        v-model="perPageMutated"
        @update:modelValue="emitDropdownValue"
        append-to-body
        :calculate-position="withPopper"
      ></v-select>
    </div>
    <div class="pagination-per-page-counter" v-if="showCounter">
      {{ (page - 1) * perPage + (allResults ? 1 : 0) }} -
      {{ page * perPage > allResults ? allResults : page * perPage }} of
      {{ allResults }}
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "PaginationPerPage",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    options: {
      type: Array,
      default: () => {
        return [20, 30, 40];
      },
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    allResults: {
      type: Number,
      default: 0,
    },
    showCounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perPageMutated: null,
      placement: "top",
    };
  },
  mounted() {
    this.perPageMutated = this.perPage;
  },
  methods: {
    emitDropdownValue(value) {
      this.$emit("value-change", {
        page: this.page,
        perPage: value,
      });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>

<style scoped></style>
