<template>
  <div class="row">
    <div class="col-lg-12 settings">
      <sidebar-doctors :selectedType="selectedType"></sidebar-doctors>
      <doctor-view v-if="selectedType === 'user'"></doctor-view>
      <doctor-password-view
        v-if="selectedType === 'password'"
      ></doctor-password-view>
    </div>
  </div>
</template>

<script>
import SidebarDoctors from "@/views/private/components/settings/sidebar/SidebarDoctors";
import DoctorView from "@/views/private/components/settings/DoctorView";
import DoctorPasswordView from "@/views/private/components/settings/DoctorPasswordView";
export default {
  name: "SettingsDoctorsComponent",
  components: {
    DoctorView,
    DoctorPasswordView,
    SidebarDoctors,
  },
  data() {
    return {
      types: ["user", "password"],
      selectedType: null,
    };
  },
  created() {
    if (this.types.indexOf(this.$route.params.type) > -1) {
      this.selectedType = this.$route.params.type;
      return;
    }

    if (this.routeIn(["doctorsUserEdit"])) {
      this.selectedType = "user";
      return;
    }

    this.$router
      .push({ name: "settingsDoctors", params: { type: "user" } })
      .catch(() => {});
  },
  watch: {
    "$route.params.type": function (val) {
      if (!val) {
        return;
      }
      if (this.types.indexOf(val) > -1) {
        this.selectedType = val;
      } else {
        this.selectedType = "user";
      }
    },
  },
};
</script>
