<template xmlns:div="http://www.w3.org/1999/html">
  <div class="modal details" id="active-modal" v-if="!loading">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div
                :class="{
                  'form-item-title': true,
                  disabled: isInactivePatient(eventData.patient),
                }"
              >
                <div class="link-cursor" @click="showDetailsToggle">
                  <div class="icon-list-black margin-right-10"></div>
                  {{
                    typeof eventData.patient !== "undefined"
                      ? eventData.patient.first_name +
                        " " +
                        eventData.patient.last_name
                      : $tc("label.appointment", 1) +
                        " " +
                        $tc("label.detail", 1).toLowerCase()
                  }}
                </div>

                <div class="icon-wrap">
                  <div
                    class="button approve event-action"
                    @click.prevent="updateEvent('checked-in')"
                    v-if="
                      showDetails &&
                      eventData.status === 'scheduled' &&
                      this.isBefore(
                        this.parseFromUTC(eventData.start),
                        this.getTodayEnd()
                      )
                    "
                  >
                    {{ $t("button.checkIn") }}
                  </div>
                  <div
                    class="button error event-action"
                    @click.prevent="updateEvent('checked-out')"
                    v-if="showDetails && eventData.status === 'checked-in'"
                  >
                    {{ $t("button.checkOut") }}
                  </div>
                  <div
                    class="button error event-action"
                    @click.prevent="updateEvent('canceled')"
                    v-if="showDetails && eventData.status === 'scheduled'"
                  >
                    {{ $t("button.cancel") }}
                  </div>
                  <router-link
                    class="button action"
                    v-if="showDetails"
                    :to="'/patient/' + eventData.patient.id + '/payments'"
                  >
                    {{ $tc("button.payment", 1) }}
                    <div class="icon icon-add-big"></div>
                  </router-link>
                  <div
                    v-if="!showDetails"
                    class="icon-edit header"
                    @click="editEvent"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item">
                  <item-icon
                    type="clock"
                    :class-name="colorClass(null)"
                  ></item-icon>
                  <div class="form-item-value">
                    {{
                      customFormat(
                        eventData.start,
                        $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                      )
                    }}
                    -
                    {{
                      customFormat(
                        eventData.end,
                        $store.getters.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                      )
                    }}
                    <br />
                    <span>
                      {{ customFormat(eventData.start, "EEEE d, y") }}</span
                    >
                  </div>
                </div>
                <div class="form-item">
                  <div
                    :class="
                      'icon-indicator ' +
                      this.statusColorClass(eventData.status)
                    "
                  ></div>
                  <div class="form-item-value">
                    <template v-if="eventData.status === 'scheduled'">
                      {{ $t("label.eventStatusScheduled") }}
                    </template>
                    <template v-if="eventData.status === 'canceled'">
                      {{ $t("label.eventStatusCancel") }}
                    </template>
                    <template v-if="eventData.status === 'checked-in'">
                      {{ $t("label.eventStatusCheckIn") }}
                    </template>
                    <template v-if="eventData.status === 'checked-out'">
                      {{ $t("label.eventStatusCheckOut") }}
                    </template>
                    <template v-if="eventData.status === 'pending'">
                      {{ $t("label.eventStatusPending") }}
                    </template>
                    <template v-if="eventData.status === 'pending'">
                      {{ $t("label.eventStatusPending") }}
                    </template>
                  </div>
                </div>
                <div class="form-item">
                  <item-icon
                    type="doctor-2"
                    :class-name="
                      isInactiveDoctor(eventData.doctor)
                        ? 'color-gray'
                        : colorClass(
                            eventData.doctor ? eventData.doctor.id : null,
                            'doctor'
                          )
                    "
                  ></item-icon>
                  <div
                    class="form-item-value"
                    :class="{
                      error: !eventData.doctor,
                      'color-text-gray': isInactiveDoctor(eventData.doctor),
                    }"
                  >
                    {{
                      eventData.doctor
                        ? eventData.doctor.first_name +
                          " " +
                          eventData.doctor.last_name
                        : $t("message.editAndSelect", {
                            type: $tc("label.doctor", 1).toLowerCase(),
                          })
                    }}
                  </div>
                </div>
                <div class="form-item">
                  <item-icon
                    type="room"
                    :class-name="
                      colorClass(
                        eventData.room ? eventData.room.id : null,
                        'room'
                      )
                    "
                  ></item-icon>
                  <div
                    class="form-item-value"
                    :class="{ error: !eventData.room }"
                  >
                    {{
                      eventData.room
                        ? eventData.room.name
                        : $t("message.editAndSelect", {
                            type: $tc("label.room", 1).toLowerCase(),
                          })
                    }}
                  </div>
                </div>
                <div class="form-item">
                  <item-icon
                    type="treatment"
                    :class-name="
                      colorClass(
                        eventData.treatment ? eventData.treatment.id : null,
                        'treatment'
                      )
                    "
                  ></item-icon>
                  <div
                    class="form-item-value"
                    :class="{ error: !eventData.treatment }"
                  >
                    {{
                      eventData.treatment
                        ? eventData.treatment.name
                        : $t("message.editAndSelect", {
                            type: $tc("label.treatment", 1).toLowerCase(),
                          })
                    }}
                  </div>
                </div>
              </div>
              <div
                class="modal-dialog-main-section"
                v-if="
                  eventData.notes !== null ||
                  eventData.reason_for_visit !== null
                "
              >
                <div
                  class="modal-dialog-info-label details"
                  v-if="eventData.notes"
                >
                  {{ $tc("label.note", 2) }}:
                  <div class="modal-dialog-info-value details">
                    {{ eventData.notes }}
                  </div>
                </div>
                <div
                  class="modal-dialog-info-label details"
                  v-if="eventData.reason_for_visit"
                >
                  {{ $t("label.reasonForVisit") }}:
                  <div class="modal-dialog-info-value details">
                    {{ eventData.reason_for_visit }}
                  </div>
                </div>
              </div>
              <div class="modal-dialog-main-section border-top bottom">
                <div class="form-item action no-padding">
                  <div
                    @click="promptNotify"
                    class="icon"
                    v-if="eventData.status !== 'pending'"
                  >
                    <div class="icon-notify"></div>
                    {{ $t("button.notify") }}
                  </div>
                  <template v-else>
                    <button
                      class="button approve event-action"
                      :class="{
                        disabled:
                          eventData.room === null ||
                          eventData.doctor === null ||
                          eventData.treatment === null,
                      }"
                      @click.prevent="updateEvent('scheduled')"
                    >
                      {{ $t("button.approve") }}
                    </button>
                    <button
                      class="button error event-action"
                      @click.prevent="updateEvent('canceled')"
                    >
                      {{ $t("button.cancel") }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-if="showDetails && eventData.patient"
              class="modal-dialog-main-section-column border-left"
            >
              <div class="modal-dialog-main-section">
                <div class="modal-dialog-info-patient">
                  <div class="modal-dialog-info-label">
                    {{ $t("label.name") }}:
                    <span class="modal-dialog-info-value">
                      {{
                        eventData.patient.first_name +
                        " " +
                        eventData.patient.last_name
                      }}
                    </span>
                  </div>
                  <div
                    class="modal-dialog-info-label"
                    v-if="eventData.patient.guardian"
                  >
                    {{ $t("label.guardian") }}:
                    <span class="modal-dialog-info-value">
                      {{
                        eventData.patient.guardian.first_name +
                        " " +
                        eventData.patient.guardian.last_name
                      }}
                    </span>
                  </div>
                  <div
                    v-if="
                      eventData.patient.email && !eventData.patient.guardian
                    "
                    class="modal-dialog-info-label"
                  >
                    {{ $t("label.email") }}:
                    <span class="modal-dialog-info-value">
                      {{ eventData.patient.email }}
                    </span>
                  </div>
                  <div
                    v-if="eventData.patient.birthday"
                    class="modal-dialog-info-label"
                  >
                    {{ $t("label.dateOfBirth") }}:
                    <span class="modal-dialog-info-value">
                      {{
                        customFormat(eventData.patient.birthday, "MM/dd/yyyy")
                      }}
                    </span>
                  </div>
                  <div
                    v-if="eventData.patient.referral_source"
                    class="modal-dialog-info-label"
                  >
                    {{ $t("label.referralSource") }}:
                    <span class="modal-dialog-info-value">
                      {{ eventData.patient.referral_source }}
                    </span>
                  </div>
                  <div
                    v-if="eventData.patient.mobile_number"
                    class="modal-dialog-info-label"
                  >
                    {{ $t("label.mobileNo") }}:
                    <span class="modal-dialog-info-value">
                      {{ eventData.patient.mobile_number }}
                    </span>
                  </div>
                  <div class="modal-dialog-info-label">
                    <router-link
                      :to="{
                        name: 'documents',
                        params: {
                          path: '/patients/' + this.eventData.patient.id,
                        },
                      }"
                      target="_blank"
                      class="action-label"
                    >
                      {{ $t("button.viewDocuments") }}
                    </router-link>
                  </div>
                  <div
                    class="modal-dialog-info-label with-action margin-bottom-0"
                  >
                    <div>{{ $t("label.soapNotes") }}:</div>
                    <router-link
                      v-if="eventData.patient.soap_notes_count"
                      :to="'/patient/' + this.eventData.patient.id + '/soap'"
                      target="_blank"
                      class="action"
                    >
                      {{ $t("button.viewAll") }}:
                    </router-link>
                  </div>
                  <div class="modal-dialog-info-label">
                    <div
                      v-for="(soap, index) in eventData.patient
                        .soap_notes_preview"
                      :key="'soap_' + index"
                    >
                      <router-link
                        v-if="canEditSoap(soap)"
                        :to="{ name: 'soapEdit', params: { id: soap.id } }"
                        class="modal-dialog-info-value soap-note"
                      >
                        {{ customFormat(soap.created_at, "MM/dd/yyyy") }} -
                        {{ soap.doctor_first_name }}
                        {{ soap.doctor_last_name }}
                        <template v-if="soap.status === 'draft'">
                          ({{ $t("label.draft").toLowerCase() }})
                        </template>
                        <template v-if="soap.status === 'signed'">
                          ({{ $t("label.signed").toLowerCase() }})
                        </template>
                      </router-link>
                      <router-link
                        v-else
                        :to="{ name: 'soapPreview', params: { id: soap.id } }"
                        class="modal-dialog-info-value soap-note"
                        target="_blank"
                      >
                        {{ customFormat(soap.created_at, "MM/dd/yyyy") }} -
                        {{ soap.doctor_first_name }}
                        {{ soap.doctor_last_name }}
                      </router-link>
                    </div>
                  </div>
                  <div
                    v-if="canAddSoap"
                    class="modal-dialog-info-label action"
                    @click.prevent="addSoap"
                  >
                    <div class="icon icon-add-primary"></div>
                    {{ $t("button.addSoapNotes") }}:
                  </div>
                </div>
              </div>
              <div class="modal-dialog-main-section border-top bottom">
                <div class="form-item padding-bottom-0">
                  <div @click="editEvent" class="icon">
                    <div class="icon-edit"></div>
                    {{ $t("button.edit") }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-dialog-main-section-column border-left"
              v-if="showDetails && policyInfo"
            >
              <div class="modal-dialog-main-section">
                <div class="modal-dialog-info-patient">
                  <div class="modal-dialog-info-label" v-if="loadingPolicyInfo">
                    {{ $t("label.loadingPolicyInfo") }}
                  </div>
                  <div
                    class="modal-dialog-info-label"
                    v-if="!loadingPolicyInfo && policyInfo === null"
                  >
                    {{ $t("label.noPolicyInfo") }}
                  </div>
                  <template v-if="policyInfo">
                    <div class="modal-dialog-info-label">
                      {{ $t("label.copayAcupuncture") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPrice(policyInfo.copay_acupuncture) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.copayEvaluation") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPrice(policyInfo.copay_evaluation) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.coinsuranceAcupuncture") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPercent(policyInfo.coinsurance_acupuncture) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.coinsuranceEvaluation") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPercent(policyInfo.coinsurance_evaluation) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.deductibleTotal") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPrice(policyInfo.deductible_total) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.deductibleRemaining") }}:
                      <span class="modal-dialog-info-value">
                        {{ formatPrice(policyInfo.deductible_remaining) }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.visitLimit") }}:
                      <span class="modal-dialog-info-value">
                        {{ policyInfo.visit_limit }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.effectiveDate") }}:
                      <span class="modal-dialog-info-value">
                        {{
                          customFormat(
                            policyInfo.policy_effective_date,
                            "MM/dd/yyyy"
                          )
                        }}
                      </span>
                    </div>
                    <div class="modal-dialog-info-label">
                      {{ $t("label.termDate") }}:
                      <span class="modal-dialog-info-value">
                        {{
                          customFormat(
                            policyInfo.policy_term_date,
                            "MM/dd/yyyy"
                          )
                        }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading"></div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
// import { default as EventBus } from "@/services/eventbus";

export default {
  name: "EventDetail",
  props: ["event"],
  components: { ItemIcon },
  data() {
    return {
      loading: false,
      loadingPolicyInfo: false,
      policyInfo: null,
      policyInfoLoaded: false,
      eventData: {},
      params: [],
      showDetails: this.$route.name === "eventDetailPatient",
      cancelToken: { form: null },
    };
  },
  created() {
    this.loadData();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  computed: {
    canAddSoap() {
      return (
        !this.eventData.soap &&
        (this.$store.state.userType === "user" ||
          (this.isToday(this.parseToUTC(this.eventData.start)) &&
            this.$store.state.userType === "doctor"))
      );
    },
  },
  watch: {
    showDetails: function (val) {
      if (
        val &&
        this.policyInfo === null &&
        !this.loadingPolicyInfo &&
        !this.policyInfoLoaded
      ) {
        this.loadPolicyInfo();
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    canEditSoap(soap) {
      return !(soap.status === "locked" && this.$hasRole(["Doctors"]));
    },
    showDetailsToggle() {
      if (this.showDetails) {
        this.showDetails = false;
        this.$router.push({ name: "eventDetail" }).catch(() => {});
      } else {
        this.showDetails = true;
        this.$router.push({ name: "eventDetailPatient" }).catch(() => {});
      }
    },
    editEvent() {
      this.closeModal();
      this.$router.push({
        name: "eventEdit",
        params: {
          id: this.eventData.id,
        },
      });
    },
    promptNotify() {
      this.$notify({
        group: "prompt-calendar",
        duration: -1,
        closeOnClick: false,
        title: this.$t("notification.appointmentReminder"),
        text: this.$t("notification.thisWillNotifyPatient"),
        data: {
          onCancel: true,
          onConfirm: this.notify,
        },
      });
    },
    notify() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/notifications/upcoming-event/" + this.$route.params.id,
      })
        .then(() => {
          this.closeModal();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateEvent(status) {
      let data = {};
      if (status === "scheduled") {
        data = {
          doctor: {
            id: this.eventData.doctor.id,
          },
          room: {
            id: this.eventData.room.id,
          },
          treatment: {
            id: this.eventData.treatment.id,
          },
          patient: {
            id: this.eventData.patient.id,
          },
          status: status,
        };
      } else {
        data = {
          status: status,
        };
      }
      this.loading = true;
      HTTP({
        method: "PUT",
        url: "/api/v1/events/" + this.$route.params.id,
        data: data,
      })
        .then(() => {
          this.loadData(true);
          // EventBus.$emit("event-load");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadData(updateEvent = false) {
      this.loading = true;
      return HTTP({
        method: "GET",
        url: "/api/v1/events/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.eventData = response.data;
            if (updateEvent) {
              let events = JSON.parse(JSON.stringify(this.$store.state.events));
              events.splice(
                events.findIndex((e) => e.id === response.data.id),
                1,
                response.data
              );
              this.$store.dispatch("setEvents", {
                events: events,
              });
            }
          }
          this.loading = false;
          this.loadPolicyInfo();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadPolicyInfo() {
      this.loadingPolicyInfo = true;
      setCancelToken(this.cancelToken, "form");
      return HTTP({
        method: "GET",
        url:
          "/api/v1/patients/" +
          this.eventData.patient.id +
          "/patient-policy-info/latest",
        cancelToken: this.cancelToken.form.token,
      })
        .then((response) => {
          if (response.data) {
            this.policyInfo = response.data;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingPolicyInfo = false;
          this.policyInfoLoaded = true;
        });
    },
    addSoap() {
      this.$router.push({
        name: "eventSoapAdd",
        params: { id: this.eventData.id },
      });
    },
  },
};
</script>

<style scoped></style>
