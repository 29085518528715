<template>
  <div class="row">
    <div class="col-lg-12 home">
      <sidebar
        ref="sidebarWithCalendar"
        @sidebar-calendar-month-change="setMainMonth"
      />
      <main-calendar
        ref="mainCalendar"
        @main-calendar-month-change="setSidebarMonth"
      />
      <div
        @click="this.createNewEvent()"
        class="calendar-main-add-event"
        ref="calendarAddEvent"
      ></div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/calendar/Sidebar";
import MainCalendar from "@/views/private/components/calendar/MainCalendar";
import firstVisit from "@/mixins/firstVisit";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "HomeComponent",
  mixins: [firstVisit, onboardTooltipMixin],
  components: {
    Sidebar,
    MainCalendar,
  },
  data() {
    return {};
  },
  methods: {
    setMainMonth(date) {
      this.$refs.mainCalendar.setMonth(
        this.fixTimezoneOffset(this.getMonthStart(date))
      );
    },
    setSidebarMonth(date) {
      this.$refs.sidebarWithCalendar.setMonth(
        this.fixTimezoneOffset(this.getMonthStart(date))
      );
    },
  },
};
</script>
