<template>
  <div class="form-item">
    <label class="item">{{ $tc("label.provider", 1) }}: </label>
    <v-select
      :getOptionLabel="nameOptionLabel"
      :options="doctors"
      :model-value="doctor"
      :filterable="false"
      @update:modelValue="(value) => $emit('set-doctor', value)"
      @search="searchDoctors"
    >
      <template #option="{ first_name, last_name }">
        <span> {{ first_name + " " + last_name }}</span>
      </template>
      <template #no-options="{ search }">
        <vue-select-searching
          :search="search"
          :loading="doctorsLoading"
          :results-count="doctors.length"
        />
      </template>
      <template #list-footer>
        <load-more
          :message="$t('message.loading') + '...'"
          :show-load-more="hasMoreDoctors"
          :custom-class="'vs__dropdown-option'"
          @load-more="
            () => {
              doctorsSearchData.page++;
              loadDoctors();
            }
          "
        ></load-more>
      </template>
    </v-select>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import LoadMore from "@/views/shared/components/LoadMore";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching";

export default {
  name: "FormFilterProvider",
  components: { VueSelectSearching, LoadMore },
  emits: ["set-doctor"],
  props: {
    doctor: {
      type: Object,
      default() {
        return {};
      },
    },
    loadOnCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      doctors: [],
      doctorsSearchData: {
        search: "",
        page: 1,
        per_page: 10,
        sort_by: ["first_name|asc"],
      },
      hasMoreDoctors: true,
      doctorsLoading: false,
      cancelToken: {
        doctorsLoad: null,
      },
    };
  },
  created() {
    if (this.loadOnCreate) {
      this.loadDoctors();
    }
  },
  mounted() {
    this.searchDoctors = this.asDebounce(this.searchDoctors, 1000);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadDoctors() {
      if (this.doctorsLoading) {
        return;
      }

      setCancelToken(this.cancelToken, "doctorsLoad");
      this.doctorsLoading = true;

      HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        data: {},
        cancelToken: this.cancelToken.doctorsLoad.token,
        params: {
          statuses: [this.STATUSES.ACTIVE],
          q: this.doctorsSearchData.search,
          page: this.doctorsSearchData.page,
          per_page: this.doctorsSearchData.per_page,
        },
      })
        .then((response) => {
          this.doctorsLoading = false;
          if (response.data.data) {
            this.doctors = this.doctors.concat(response.data.data);

            if (response.data.meta.total <= this.doctors.length) {
              this.hasMoreDoctors = false;
            }
          }
        })
        .catch(() => {
          this.doctorsLoading = false;
        });
    },
    searchDoctors(text = "") {
      cancelTokens(this.cancelToken);
      this.doctorsSearchData.search = text;
      this.doctorsSearchData.page = 1;
      this.doctorsSearchData.per_page = 10;
      this.doctors = [];
      this.hasMoreDoctors = true;
      this.doctorsLoading = false;
      this.loadDoctors();
    },
  },
};
</script>
