<template>
  <div
    :class="{
      'documents-main-inner-files': true,
      dropping: isDropping,
    }"
    @dragover.prevent="this.$emit('update:isDropping', true)"
    @dragleave.prevent="this.$emit('update:isDropping', false)"
    @drop.stop.prevent="this.$emit('handle-file-drop', $event)"
  >
    <div class="documents-main-inner-files-header">
      <header-sort
        sortKey="documents"
        :sortArray="[
          {
            label: 'Name',
            direction: 'asc',
            key: 'name',
          },
          {
            label: 'Modified',
            direction: 'asc',
            key: 'created_at',
            width: '275px',
          },
        ]"
      ></header-sort>
    </div>
    <div class="documents-main-inner-files-section">
      <!-- level up-->
      <div
        class="documents-main-inner-files-section-item transparent"
        v-if="
          $store.state.documents.currentPath !== '/' &&
          $store.state.documents.selectedCampaign === null
        "
        @click="this.$emit('handle-folder-up-click')"
      >
        <div class="documents-main-inner-files-section-item-name">
          <div class="icon-folder up"></div>
          ...
        </div>
      </div>

      <!-- folders-->
      <div
        :class="{
          'documents-main-inner-files-section-item': true,
          active: isCurrentActive('folder', folder),
        }"
        v-for="folder in folders"
        :key="'folder_' + folder.id"
        @click="this.$emit('handle-folder-click', folder)"
      >
        <drag
          class="documents-main-inner-files-section-item-name"
          :transfer-data="{ folder: folder }"
        >
          <div
            :class="{ 'icon-folder': true, favorites: folder.shortcut }"
          ></div>
          <div class="name">{{ folder.name }}</div>
        </drag>
        <div class="documents-main-inner-files-section-item-modified">
          <div>
            {{ formatDateTime(folder.updated_at) }}
          </div>
          <folder-options-menu
            v-if="!folder.patient_folder"
            :folder="folder"
            @add-shortcut="folder['shortcut'] = 1"
            @remove-shortcut="folder['shortcut'] = 0"
          ></folder-options-menu>
        </div>
      </div>

      <!-- files-->
      <div
        :class="{
          'documents-main-inner-files-section-item': true,
          active: isCurrentActive('file', file),
        }"
        v-for="file in files"
        :key="'file_' + file.id"
        @click="this.$emit('handle-file-click', file)"
      >
        <drag
          class="documents-main-inner-files-section-item-name"
          :transfer-data="{ file: file }"
        >
          <div :class="'icon-file' + ' ' + getExtension(file.mime_type)"></div>
          <div class="name">{{ file.name }}</div>
        </drag>
        <div class="documents-main-inner-files-section-item-modified">
          <div>
            {{ formatDateTime(file.updated_at) }}
          </div>
          <file-options-menu :file="file"></file-options-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FolderOptionsMenu from "./FolderOptionsMenu";
import FileOptionsMenu from "./FileOptionsMenu";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import fileMixin from "@/mixins/fileMixin";

export default {
  name: "DocumentsStandardListView",
  props: {
    isDropping: {
      type: Boolean,
      default: false,
    },
    currentActive: {
      type: Object,
      default: () => {
        return {
          type: null,
          item: null,
        };
      },
    },
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mixins: [fileMixin],
  components: {
    FolderOptionsMenu,
    FileOptionsMenu,
    HeaderSort,
  },
  data: function () {
    return {};
  },
  methods: {
    isCurrentActive(type, item) {
      return (
        this.currentActive.type === type &&
        this.currentActive.item.id === item.id
      );
    },
  },
};
</script>
