<template>
  <div class="patients-schedule-selected">
    <div class="patients-schedule-selected-icon"></div>

    <div class="patients-schedule-selected-info">
      <div class="patients-schedule-selected-info-icon">
        <svg
          width="49"
          height="49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24.75" cy="24.44" r="24" fill="#F9E9E6" />
          <g
            clip-path="url(#clip0)"
            stroke="#EB5F41"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M29.417 19.107h-9.334c-.736 0-1.333.596-1.333 1.333v9.333c0 .736.597 1.334 1.333 1.334h9.334c.736 0 1.333-.598 1.333-1.334V20.44c0-.737-.597-1.334-1.333-1.334zM27.417 17.773v2.667M22.083 17.773v2.667M18.75 23.107h12"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path
                fill="#fff"
                transform="translate(16.75 16.44)"
                d="M0 0h16v16H0z"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="patients-schedule-selected-info-content">
        <div class="patients-schedule-selected-info-date">
          {{ this.customFormat(selectedDay, "EEEE, MMMM d, yyyy") }}
          <br />
          <template v-if="selectedDateTime">
            {{ this.customFormat(selectedDateTime, "hh:mm a") }} -
            {{
              this.customFormat(
                selectedDateTime.addMinutes(treatment.duration),
                "hh:mm a"
              )
            }}
          </template>
        </div>

        <div class="patients-schedule-selected-info-treatment">
          <span
            v-if="treatment"
            class="patients-schedule-selected-info-treatment-type"
          >
            {{ treatment.name }},
          </span>
          <span
            v-if="doctor"
            class="patients-schedule-selected-info-treatment-doctor"
          >
            {{ doctor.first_name + " " + doctor.last_name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["treatment", "doctor", "selectedDateTime", "selectedDay"],
  name: "PatientScheduleSelected",
  data: () => ({}),
  methods: {},
};
</script>
