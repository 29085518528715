<template>
  <div class="payment-tab-single">
    <div v-if="!loadingPayments">
      <div
        v-if="paymentStatus !== null"
        class="payment-tab-single-message"
        :class="{
          success: paymentStatus === 'success',
          failed: paymentStatus === 'fail',
        }"
      >
        <template v-if="paymentStatus === 'success'">{{
          $t("label.paymentSuccess")
        }}</template>
        <template v-if="paymentStatus === 'fail'">{{
          $t("label.paymentFailed")
        }}</template>
      </div>

      <div
        class="expandable-block"
        v-for="item in paymentHistory"
        :key="'history_' + item.id"
      >
        <div class="expandable-block-header" @click="setItemExpanded(item)">
          <div class="title">
            {{ $t("label.totalPrice") + ": " }} {{ formatPrice(item.total) }}
            {{ $t("label.date") }}:
            {{ customFormat(item.created_at, "MM / dd / yyyy") }}
            {{ $t("label.status") }}:
            {{ item.status }}
          </div>
          <div
            class="toggle-expand"
            :class="{ expanded: expandedId === item.id }"
          ></div>
        </div>
        <div
          class="expandable-block-body"
          :class="{ expanded: expandedId === item.id }"
        >
          <div
            class=""
            :key="'order_item_' + item.id + '_' + orderItem.id"
            v-for="orderItem in item.orderItems"
          >
            <payments-single-item-overview
              :item="orderItem"
              @load-payment-history="loadPaymentHistory()"
            ></payments-single-item-overview>
          </div>
          <div class="expandable-block-bottom">
            <p v-if="item.tip">
              <strong>Tip:</strong> {{ formatPrice(item.tip) }}
            </p>
            <p v-if="item.discount">
              <strong>Discount:</strong> {{ formatPrice(item.discount) }}
            </p>
            <p v-if="item.tax">
              <strong>Tax:</strong> {{ formatPrice(item.tax) }}
            </p>
            <h3 v-if="item.total">
              <strong>{{ $t("label.totalPrice") + ": " }} </strong>
              {{ formatPrice(item.total) }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import PaymentsSingleItemOverview from "@/views/private/components/payments/PaymentsSingleItemOverview";
export default {
  name: "PaymentsPatientOverview",
  props: {
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    paymentStatus: {
      type: String,
      default: null,
    },
  },
  components: {
    PaymentsSingleItemOverview,
  },
  data() {
    return {
      paymentHistory: [],
      loadingPayments: false,
      expandedId: null,
      cancelToken: {
        paymentHistory: null,
      },
    };
  },
  mounted() {
    this.loadPaymentHistory();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    setItemExpanded(item) {
      if (this.expandedId === item.id) {
        this.expandedId = null;
      } else {
        this.expandedId = item.id;
      }
    },
    loadPaymentHistory() {
      if (!this.patient) {
        return;
      }

      setCancelToken(this.cancelToken, "paymentHistory");
      this.loadingPayments = true;

      return HTTP({
        method: "GET",
        url: "/api/v1/purchases/patients/" + this.patient.id + "/orders",
        data: {},
        cancelToken: this.cancelToken.paymentHistory.token,
        params: {
          order_by: "created_at",
          order_direction: "desc",
        },
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.paymentHistory = response.data.data;
          }
          this.loadingPayments = false;
        })
        .catch(() => {
          this.loadingPayments = false;
        });
    },
  },
};
</script>
