<template>
  <template
    v-if="['doctors', 'treatments', 'rooms'].indexOf(splitData.class) > -1"
  >
    <item-icon
      :type="
        splitData.class === 'doctors'
          ? 'doctor'
          : splitData.class === 'treatments'
          ? 'treatment'
          : splitData.class === 'rooms'
          ? 'room'
          : ''
      "
      :class-name="
        colorClass(
          splitData.id,
          splitData.class === 'doctors'
            ? 'doctor'
            : splitData.class === 'treatments'
            ? 'treatment'
            : splitData.class === 'rooms'
            ? 'room'
            : ''
        )
      "
    ></item-icon>
    <strong
      class="no-background"
      :class="
        colorClass(
          splitData.id,
          splitData.class === 'doctors'
            ? 'doctor'
            : splitData.class === 'treatments'
            ? 'treatment'
            : splitData.class === 'rooms'
            ? 'room'
            : ''
        )
      "
      >{{ splitData.label }}</strong
    >
  </template>
  <template v-if="['statuses'].indexOf(splitData.class) > -1">
    <div :class="'icon-indicator ' + statusColorClass(splitData.label)"></div>
    <strong class="no-background" :class="statusColorClass(splitData.label)">{{
      splitData.label
    }}</strong>
  </template>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
export default {
  name: "CalendarSlotSplitView",
  components: { ItemIcon },
  props: {
    splitData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
