<template>
  <div class="row">
    <div class="col-lg-12 payment">
      <div class="payment-wrapper">
        <div class="patient-title" v-if="patient">
          {{ patient.first_name + " " + patient.last_name }}
        </div>
        <payments-patient-overview ref="patientsPayment" :patient="patient" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import PaymentsPatientOverview from "@/views/private/components/payments/PaymentsPatientOverview";
export default {
  name: "PaymentsPatientHistory",
  components: { PaymentsPatientOverview },
  data() {
    return {
      patient: null,
      loading: false,
    };
  },
  created() {
    this.loadPatient();
  },
  methods: {
    loadPatient() {
      this.loading = true;
      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
