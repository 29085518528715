<template>
  <div class="sidebar-mini">
    <div class="sidebar-mini-inner">
      <div class="sidebar-mini-items" ref="sidebarMini">
        <div
          v-if="
            activeSubmenu === null &&
            this.$hasRole(['Administrator', 'Doctors'])
          "
          class="sidebar-mini-items-item"
          :class="{
            active: this.routeIn([
              'calendar',
              'calendarDayAgenda',
              'eventEditDetailed',
              'eventEdit',
              'eventAdd',
              'eventAddDetailed',
              'eventDetail',
              'eventDetailPatient',
            ]),
          }"
          v-tooltip.right="$t('sidebar.calendar')"
          @click="goTo('calendar')"
        >
          <item-icon type="calendar" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="
            activeSubmenu === null &&
            this.$hasRole(['Administrator', 'Doctors'])
          "
          class="sidebar-mini-items-item"
          :class="{
            active: this.routeIn(['patients', 'patientForm', 'policyInfo']),
          }"
          v-tooltip.right="$t('sidebar.patients')"
          @click="goTo('patients')"
        >
          <item-icon type="doctor" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Administrator'])"
          class="sidebar-mini-items-item"
          :class="{
            active: this.routeIn(['inventory', 'inventoryAdvancedSearch']),
          }"
          v-tooltip.right="$t('sidebar.inventory')"
          @click="goTo('inventory', 'inventory')"
        >
          <item-icon type="pharmacy" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Administrator'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'documents' }"
          v-tooltip.right="$t('sidebar.documents')"
          @click="goTo('documents')"
        >
          <item-icon type="documents" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Administrator'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'insurance' }"
          v-tooltip.right="$t('sidebar.insuranceLedger')"
          @click="goTo('insurance')"
        >
          <item-icon type="insuranceLedger" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Administrator'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'reports' }"
          v-tooltip.right="$t('sidebar.reports')"
          @click="goTo('reports')"
        >
          <item-icon type="inventory-view" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Administrator'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'settings' }"
          v-tooltip.right="$t('sidebar.settings')"
          @click="goTo('settings')"
        >
          <item-icon type="filter" class-name="color-gray"></item-icon>
        </div>
        <div
          v-if="activeSubmenu === null && this.$hasRole(['Doctors'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'settingsDoctors' }"
          v-tooltip.right="$t('sidebar.settings')"
          @click="goTo('settingsDoctors')"
        >
          <item-icon type="filter" class-name="color-gray"></item-icon>
        </div>
        <template
          v-if="
            activeSubmenu === 'inventory' &&
            this.$hasRole(['Administrator', 'Doctors'])
          "
        >
          <div class="sidebar-mini-items-item" @click="goTo(null, null)">
            <div class="icon icon-arrow-left-gray"></div>
          </div>
          <div
            class="sidebar-mini-items-item"
            :class="{ active: this.$route.name === 'inventory' }"
            v-tooltip.right="$t('sidebar.inventory')"
            @click="goTo('inventory', 'inventory')"
          >
            <item-icon type="pharmacy" class-name="color-gray"></item-icon>
          </div>
          <div
            class="sidebar-mini-items-item"
            :class="{ active: this.$route.name === 'inventoryAdvancedSearch' }"
            v-tooltip.right="$t('sidebar.inventoryAdvanced')"
            @click="goTo('inventoryAdvancedSearch', 'inventory')"
          >
            <item-icon type="filter" class-name="color-gray"></item-icon>
          </div>
        </template>

        <div
          v-if="this.$hasRole(['Super Administrator'])"
          class="sidebar-mini-items-item"
          :class="{ active: this.$route.name === 'adminSettings' }"
          @click="goTo('adminSettings')"
        >
          <item-icon type="filter" class-name="color-gray"></item-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "SidebarMini",
  mixins: [onboardTooltipMixin],
  components: { ItemIcon },
  data() {
    return {
      activeSubmenu: null,
    };
  },
  created() {
    if (this.routeIn(["inventory", "inventoryAdvancedSearch"])) {
      this.activeSubmenu = "inventory";
    }
  },
  methods: {
    goTo(routeName, activeSubmenu = null) {
      this.activeSubmenu = activeSubmenu;
      if (routeName) {
        this.$router.push({ name: routeName }).catch(() => {});
      }
    },
  },
};
</script>
