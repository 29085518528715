<template>
  <div class="modal create-detailed" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <!-- Row-->
          <div class="modal-dialog-main-section-row column">
            <div class="modal-dialog-main-tabs border-bottom">
              <div
                @click="tabSelected = 'appointment'"
                :class="{
                  'modal-dialog-main-tabs-item': true,
                  'active button': tabSelected === 'appointment',
                }"
              >
                Appointment
              </div>
              <div
                @click="tabSelected = 'time_off'"
                :class="{
                  'modal-dialog-main-tabs-item': true,
                  'active button': tabSelected === 'time_off',
                }"
              >
                Time off
              </div>
            </div>

            <div class="modal-dialog-main-section-helper">
              <!-- Left column-->
              <div
                class="modal-dialog-main-section-column"
                @click="patientCreate = false"
                v-show="tabSelected === 'appointment'"
              >
                <!-- Main section-->
                <div
                  class="modal-dialog-main-section position-relative border-bottom"
                >
                  <div
                    :class="{
                      'form-item combined-input': true,
                    }"
                  >
                    <v-select
                      :class="{ treatment: true, selected: newEvent.treatment }"
                      ref="selectTreatment"
                      placeholder="treatment"
                      label="name"
                      :options="options.treatments"
                      v-model="newEvent.treatment"
                    >
                      <template #option="{ name, id }">
                        <span>
                          <item-icon
                            type="treatment"
                            :class-name="colorClass(id, 'treatment')"
                          ></item-icon
                          >{{ name }}</span
                        >
                      </template>
                    </v-select>
                    <div class="normal-font">
                      {{ $t("label.at").toLowerCase() }}
                    </div>
                    <div
                      :class="{
                        invalid: !validDateTimeInput,
                        unfinished: newEvent.start === null,
                      }"
                    >
                      <date-time-input
                        ref="inputDate"
                        separator="-"
                        use-date
                        use-start-time
                        :index="0"
                        :original-start-date="newEvent.start"
                        :time-format24="this.$store.getters.userHoursFormat24"
                        @value-start-date="setDate"
                      ></date-time-input>
                    </div>
                    <div class="normal-font">
                      {{ $t("label.in").toLowerCase() }}
                    </div>
                    <v-select
                      class="room"
                      ref="selectRoom"
                      placeholder="location"
                      label="name"
                      :options="options.rooms"
                      v-model="newEvent.room"
                    >
                      <template #option="{ name, isAvailable, id }">
                        <span>
                          <item-icon
                            type="room"
                            :class-name="colorClass(id, 'room')"
                          ></item-icon>
                          {{ name }}</span
                        >
                        <div
                          :class="{
                            'icon-indicator': true,
                            invalid: !isAvailable,
                            'no-data': typeof isAvailable === 'undefined',
                          }"
                        ></div>
                      </template>
                      <template #selected-option="{ name, isAvailable }">
                        <span>{{ name }}</span>
                        <div
                          :class="{
                            'icon-indicator': true,
                            invalid: !isAvailable,
                            'no-data': typeof isAvailable === 'undefined',
                          }"
                        ></div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- Edit Room-->
                <div class="modal-dialog-main-section border-bottom">
                  <div
                    class="form-item selectable-div"
                    :class="{
                      error: validation.hasError('newEvent.treatment'),
                    }"
                  >
                    <item-icon
                      type="treatment"
                      :class-name="
                        colorClass(
                          newEvent.treatment ? newEvent.treatment.id : null,
                          'treatment'
                        )
                      "
                    ></item-icon>
                    <v-select
                      label="name"
                      ref="selectTreatmentLower"
                      placeholder="Assign treatment"
                      :options="options.treatments"
                      v-model="newEvent.treatment"
                    >
                      <template #option="{ name, id }">
                        <span>
                          <item-icon
                            type="treatment"
                            :class-name="colorClass(id, 'treatment')"
                          ></item-icon
                          >{{ name }}</span
                        >
                      </template>
                    </v-select>
                    <div
                      class="form-item-edit"
                      @click.prevent="editTreatment()"
                    ></div>
                  </div>
                </div>
                <!-- Edit Datetime-->
                <div
                  class="modal-dialog-main-section border-bottom arrow-background"
                >
                  <div class="form-item time-div">
                    <item-icon
                      type="calendar"
                      :class-name="colorClass(null)"
                    ></item-icon>
                    <div class="form-item-time-wrapper">
                      <div
                        class="form-item-time-item"
                        :class="{
                          error: validation.hasError('newEvent.start'),
                        }"
                      >
                        <span
                          class="formatted"
                          @click.self.prevent.stop="enableDateStartEdit"
                          >{{
                            newEvent.start
                              ? customFormat(newEvent.start, "E, MMM d")
                              : ""
                          }}</span
                        >
                        <date-time-input
                          ref="inputDateStart"
                          date-first-mode
                          enable-date-calendar
                          enable-time-picker
                          use-start-time
                          :index="1"
                          :original-start-date="newEvent.start"
                          :time-format24="this.$store.getters.userHoursFormat24"
                          @value-start-date="setDateStart"
                        ></date-time-input>
                      </div>
                      <div
                        class="form-item-time-item"
                        :class="{
                          error: validation.hasError('newEvent.end'),
                        }"
                      >
                        <span
                          class="formatted"
                          @click.self.prevent.stop="enableDateEndEdit"
                          >{{
                            newEvent.start
                              ? customFormat(newEvent.start, "E, MMM d")
                              : ""
                          }}</span
                        >
                        <date-time-input
                          ref="inputDateEnd"
                          date-first-mode
                          enable-time-picker
                          from-now
                          use-end-time
                          :index="2"
                          :original-start-date="newEvent.start"
                          :original-end-date="newEvent.end"
                          :time-format24="this.$store.getters.userHoursFormat24"
                          @value-end-date="setDateEnd"
                        ></date-time-input>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Edit Treatment-->
                <div class="modal-dialog-main-section border-bottom">
                  <div
                    class="form-item selectable-div short"
                    :class="{ error: validation.hasError('newEvent.room') }"
                  >
                    <item-icon
                      type="room"
                      :class-name="
                        colorClass(
                          newEvent.room ? newEvent.room.id : null,
                          'room'
                        )
                      "
                    ></item-icon>
                    <v-select
                      label="name"
                      ref="selectRoomLower"
                      placeholder="Assign room"
                      :options="options.rooms"
                      v-model="newEvent.room"
                    >
                      <template #option="{ name, isAvailable, id }">
                        <span>
                          <item-icon
                            type="room"
                            :class-name="colorClass(id, 'room')"
                          ></item-icon
                          >{{ name }}</span
                        >
                        <div
                          :class="{
                            'icon-indicator': true,
                            invalid: !isAvailable,
                            'no-data': typeof isAvailable === 'undefined',
                          }"
                        ></div>
                      </template>
                    </v-select>
                    <div
                      class="form-item-edit"
                      @click.prevent="editRoom()"
                    ></div>
                  </div>
                </div>
                <div class="modal-dialog-main-section item-margin">
                  <!-- Edit Patient-->
                  <div
                    class="form-item selectable-div short"
                    :class="{ error: validation.hasError('newEvent.patient') }"
                  >
                    <item-icon
                      type="list"
                      :class-name="
                        colorClass(
                          newEvent.patient ? newEvent.patient.id : null
                        )
                      "
                    ></item-icon>
                    <v-select
                      ref="selectPatient"
                      placeholder="Select patient"
                      :getOptionLabel="nameOptionLabel"
                      :options="patients"
                      :filterable="false"
                      @search="searchPatients"
                      @update:modelValue="checkPatient"
                      class="patient"
                      v-model="newEvent.patient"
                    >
                      <template #list-header="{ search }">
                        <div
                          class="add-patient"
                          @click.prevent.stop="showPatientCreate(search)"
                        >
                          <div class="add-patient-icon"></div>
                          {{ $t("button.addNewPatient") }}
                        </div>
                      </template>
                      <template #list-footer>
                        <load-more
                          :message="$t('message.loading') + '...'"
                          :show-load-more="
                            patientHasMore && patients.length > 0
                          "
                          :custom-class="'vs__dropdown-option add-patient'"
                          @load-more="loadMore"
                        />
                      </template>
                    </v-select>
                    <div
                      class="form-item-edit"
                      @click.prevent="editPatient()"
                    ></div>
                  </div>
                  <!-- Edit Doctor-->
                  <div
                    class="form-item selectable-div tiny"
                    :class="{ error: validation.hasError('newEvent.doctor') }"
                  >
                    <item-icon
                      type="doctor-2"
                      :class-name="
                        colorClass(
                          newEvent.doctor ? newEvent.doctor.id : null,
                          'doctor'
                        )
                      "
                    ></item-icon>
                    <v-select
                      ref="selectDoctor"
                      :placeholder="
                        $t('placeholder.select') +
                        ' ' +
                        $tc('label.provider', 1).toLowerCase()
                      "
                      :getOptionLabel="nameOptionLabel"
                      :options="options.doctors"
                      v-model="newEvent.doctor"
                    >
                      <template #option="{ first_name, last_name, id }">
                        <span>
                          <item-icon
                            type="doctor-2"
                            :class-name="colorClass(id, 'doctor')"
                          ></item-icon
                          >{{ first_name + " " + last_name }}</span
                        >
                      </template>
                    </v-select>
                    <div
                      class="form-item-edit"
                      @click.prevent="editDoctor()"
                    ></div>
                  </div>
                  <!-- Edit Notes-->
                  <div class="form-item input-div">
                    <item-icon
                      type="notes"
                      :class-name="colorClass(null)"
                    ></item-icon>
                    <span
                      @input="
                        ($event) => (newEvent.notes = $event.target.innerText)
                      "
                      role="textbox"
                      class="notes"
                      :contenteditable="true"
                    ></span>
                  </div>
                  <!-- Reason for visit -->
                  <div class="form-item input-div no-margin">
                    <item-icon
                      type="notes"
                      :class-name="colorClass(null)"
                    ></item-icon>
                    <span
                      @input="
                        ($event) =>
                          (newEvent.reason_for_visit = $event.target.innerText)
                      "
                      role="textbox"
                      class="notes reason"
                      :contenteditable="true"
                    ></span>
                  </div>
                </div>

                <!--Plan info-->
                <div class="modal-dialog-main-section" v-if="showPlanInfo">
                  <!-- Patient Plan-->
                  <div class="form-item checkmark-div">
                    <div class="info">
                      {{
                        $tc(
                          "message.patientHasPlanUntil",
                          patientPlans[this.patientPlanIndex].events_left,
                          {
                            plan: patientPlans[this.patientPlanIndex]
                              .events_left,
                            treatment:
                              patientPlans[this.patientPlanIndex].event.name,
                            date: customFormat(
                              patientPlans[this.patientPlanIndex].valid_until,
                              "MM/dd/yyyy"
                            ),
                          }
                        )
                      }}
                    </div>
                  </div>
                </div>

                <!-- Submit-->
                <div class="modal-dialog-main-section border-top bottom">
                  <div class="form-item action">
                    <a
                      :class="{
                        'button action': true,
                        disabled: patientCreate || createEventFormDisable,
                      }"
                      @click.prevent="submit()"
                    >
                      {{ $t("button.addNew") }}
                      {{ $tc("label.appointment", 1).toLowerCase() }}
                    </a>
                  </div>
                </div>
              </div>

              <!-- Right column-->
              <div
                class="modal-dialog-main-section-column border-left"
                v-show="patientCreate && tabSelected === 'appointment'"
              >
                <div
                  class="modal-dialog-main-section position-relative border-bottom"
                >
                  <div class="form-item padding-bottom-0">
                    <div class="form-item-title">
                      <div>{{ $t("button.addNewPatient") }}</div>
                      <div class="add-patient-icon-black"></div>
                    </div>
                  </div>
                </div>
                <patient-create
                  :newPatientName="newPatientName"
                  @patient-load="searchPatients"
                  @patient-select="setPatient"
                  ref="patientCreate"
                />
              </div>
            </div>

            <!-- time off-->
            <time-off
              v-show="tabSelected === 'time_off'"
              @close="closeModal"
            ></time-off>
          </div>
        </div>
      </div>
      <!--        inner end-->
    </div>
    <div
      class="discard-changes modal-wrapper open"
      :class="{
        'discard-changes modal-wrapper': true,
        open: this.discardModalShown,
      }"
      v-if="this.discardModalShown"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-inner">
          <div>
            <p class="align-center bold font-normal">
              Discard unsaved changes?
            </p>
            <div class="flex-center margin-bottom-20">
              <div
                class="button margin-left-5 margin-right-5"
                @click="setDiscardModalShown(false)"
              >
                Cancel
              </div>
              <div
                class="button action margin-left-5 margin-right-5"
                @click="closeModal()"
              >
                Discard
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IMask from "imask";
import { IMaskDirective } from "vue-imask";
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import SimpleVueValidator from "simple-vue3-validator";
import PatientCreate from "@/views/private/components/modals/events/PatientCreate";
import TimeOff from "@/views/private/components/modals/events/TimeOff";
import { default as EventBus } from "@/services/eventbus";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import LoadMore from "@/views/shared/components/LoadMore";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "EventCreateDetailed",
  props: ["event"],
  components: {
    LoadMore,
    ItemIcon,
    PatientCreate,
    DateTimeInput,
    TimeOff,
  },
  watch: {
    "newEvent.start": function (val) {
      if (this.dateIsValid(val) && this.isAfterNow(val)) {
        // here should be added selected event lasting hours
        if (!this.newEvent.end && this.newEvent.treatment) {
          this.setEndBasedOnTreatment(val);
        } else {
          let diff = this.diffInMinutes(this.newEvent.end, this.newEvent.start);
          if (
            this.dateDifference &&
            this.dateDifference > 0 &&
            diff !== this.dateDifference
          ) {
            this.newEvent.end = this.newEvent.start.addMinutes(
              this.dateDifference
            );
          }
        }
        this.loadRooms();
      }
    },
    "newEvent.end": function (val) {
      if (
        this.dateIsValid(val) &&
        this.isAfterNow(val) &&
        this.isAfter(val, this.newEvent.start)
      ) {
        this.dateDifference = this.diffInMinutes(
          this.newEvent.end,
          this.newEvent.start
        );
        this.loadRooms();
      }
    },
    "newEvent.treatment": function (val) {
      if (val !== null && !this.newEvent.start) {
        let scope = this;
        setTimeout(function () {
          scope.$refs.inputDate.focusInput();
        }, 200);
      }

      if (this.newEvent.start && this.newEvent.start instanceof Date) {
        this.setEndBasedOnTreatment(this.newEvent.start);
      }
    },
  },
  validators: {
    "newEvent.doctor": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.patient": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.treatment": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.start": function () {
      // let self = this;
      // return Validator.custom(function() {
      // if (!Validator.isEmpty(value)) {
      //   if (
      //     self.newEvent.start
      //     // !self.isBefore(self.getTodayStart(), self.newEvent.start)
      //   ) {
      //     return "invalid start time";
      //   }
      // } else {
      //   return Validator.value(value).required();
      // }
      // });
    },
    "newEvent.end": function (value) {
      return Validator.value(value).required();
    },
  },
  computed: {
    validDateTimeInput: function () {
      return (
        this.dateIsValid(this.newEvent.start) &&
        this.dateIsValid(this.newEvent.end) &&
        this.isBefore(this.getTodayStart(), this.newEvent.start)
      );
    },
    patientPlanIndex: function () {
      let self = this;
      return this.patientPlans.findIndex(function (item) {
        return self.newEvent.treatment.id === item.event.id;
      });
    },
    showPlanInfo: function () {
      if (!this.newEvent.treatment) {
        return false;
      }

      if (this.patientPlanIndex > -1) {
        let patientPlan = this.patientPlans[this.patientPlanIndex];
        return (
          patientPlan.events_left > 0 &&
          (this.newEvent.start === null ||
            this.isBefore(
              this.newEvent.start,
              this.parseFromUTC(patientPlan.valid_until)
            ))
        );
      }

      return false;
    },
    isTimeInputAvailable: function () {
      return this.newEvent.treatment !== null;
    },
    isRoomSelectionAvailable: function () {
      return this.validDateTimeInput;
    },
  },
  data() {
    return {
      discardModalShown: false,
      mask: {
        mask: "d/m/y - hh1:mm1", // enable date mask
        // you can provide your own blocks definitions, default blocks for date mask are:
        blocks: {
          d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            minLength: 1,
            maxLength: 2,
            placeholderChar: "d",
          },
          m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            minLength: 1,
            maxLength: 2,
            placeholderChar: "m",
          },
          y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
            placeholderChar: "y",
          },
          hh1: {
            mask: IMask.MaskedRange,
            from: 8,
            to: 20,
            placeholderChar: "h",
          },
          mm1: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            placeholderChar: "m",
          },
        },
        lazy: false,
        overwrite: false,
      },
      options: {
        doctors: [],
        rooms: [],
        treatments: [],
      },
      patients: [],
      patientSearch: "",
      patientPage: 1,
      patientHasMore: true,
      patientsLoading: false,
      patientPlans: [],
      patientPlan: null,
      newEvent: {
        start: null,
        end: null,
        patient: null,
        notes: "",
        reason_for_visit: "",
        doctor: null,
        treatment: null,
        room: null,
      },
      newPatientName: "",
      date: null,
      dateDifference: null,
      appointmentCreated: false,
      indicatorValid: true,
      patientCreate: false,
      cancelToken: {
        roomsLoad: null,
        patientsLoad: null,
        patientsPlanLoad: null,
        filtersLoad: null,
      },
      tabSelected: "appointment",
      createEventFormDisable: false,
    };
  },
  created() {
    this.loadFilters();
    this.loadRooms();
    this.searchPatients();
    this.newEvent.start =
      typeof this.$store.state.modal.selectedDate !== "undefined"
        ? this.$store.state.modal.selectedDate
        : this.getSelectedDateTimeWithStartWorkingTime(
            this.$store.state.currentDate,
            this.$store.getters.workingStartMinutes
          );
  },
  mounted() {
    if (
      !(
        this.$store.state.modal.splitType === "treatments" &&
        this.$store.state.modal.splitId
      )
    ) {
      this.$refs.selectTreatment.$el.getElementsByTagName("input")[0].focus();
    }
    this.searchPatients = this.asDebounce(this.searchPatients, 1000);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    acceptFromValue(e) {
      const maskRef = e.detail;
      if (maskRef.masked.isComplete) {
        this.clinicData.working_hours_from = maskRef.value;
      } else if (maskRef.unmaskedValue === "") {
        this.clinicData.working_hours_from = "";
      }
    },
    acceptToValue(e) {
      const maskRef = e.detail;
      if (maskRef.masked.isComplete) {
        this.clinicData.working_hours_to = maskRef.value;
      } else if (maskRef.unmaskedValue === "") {
        this.clinicData.working_hours_to = "";
      }
    },
    setShow(value) {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventoryShow",
        type: "",
        data: value,
      });
    },
    setDiscardModalShown(value) {
      this.discardModalShown = value;
    },
    handleDiscard() {
      if (this.tabSelected !== "appointment") {
        this.closeModal();
      }
      if (this.$store.state.modal.splitId) {
        if (this.$store.state.modal.splitType === "rooms") {
          return !!this.newEvent.patient ||
            !!this.newEvent.doctor ||
            !!this.newEvent.treatment ||
            !!this.newEvent.end
            ? this.setDiscardModalShown(true)
            : this.closeModal();
        }
        if (this.$store.state.modal.splitType === "doctors") {
          return !!this.newEvent.patient ||
            !!this.newEvent.room ||
            !!this.newEvent.treatment ||
            !!this.newEvent.end
            ? this.setDiscardModalShown(true)
            : this.closeModal();
        }
        if (this.$store.state.modal.splitType === "treatments") {
          return !!this.newEvent.patient ||
            !!this.newEvent.doctor ||
            !!this.newEvent.room
            ? this.setDiscardModalShown(true)
            : this.closeModal();
        }
      }
      return !!this.newEvent.patient ||
        !!this.newEvent.room ||
        !!this.newEvent.doctor ||
        !!this.newEvent.treatment ||
        !!this.newEvent.end
        ? this.setDiscardModalShown(true)
        : this.closeModal();
    },
    closeModal() {
      EventBus.$emit("modal-clickaway", false);
      this.$emit("close");
    },
    editTreatment() {
      this.$refs.selectTreatmentLower.$el
        .getElementsByTagName("input")[0]
        .focus();
    },
    editRoom() {
      this.$refs.selectRoomLower.$el.getElementsByTagName("input")[0].focus();
    },
    setEndBasedOnTreatment(start) {
      this.newEvent.end =
        this.newEvent.treatment && this.newEvent.treatment.duration
          ? start.addMinutes(this.newEvent.treatment.duration)
          : this.$store.state.user.clinic &&
            this.$store.state.user.clinic.default_treatment_length
          ? start.addMinutes(
              this.$store.state.user.clinic.default_treatment_length
            )
          : start.addHours(1);
    },
    submit() {
      if (this.patientCreate) {
        return;
      }
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.createEventFormDisable = true;
          let postData = self.preparePostDataWithDates(self.newEvent);
          HTTP({
            method: "POST",
            url: "/api/v1/events",
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.appointmentCreated"),
                });
                self.appointmentCreated = true;
                EventBus.$emit("event-load");
                self.closeModal();
                self.createEventFormDisable = false;
              }
            })
            .catch(() => {
              self.createEventFormDisable = false;
            });
        }
      });
    },
    editPatient() {
      this.patientCreate = false;
      this.$refs.selectPatient.$el.getElementsByTagName("input")[0].focus();
    },
    editDoctor() {
      this.$refs.selectDoctor.$el.getElementsByTagName("input")[0].focus();
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.date = maskRef.value;
    },
    setDateStart(val) {
      this.newEvent.start = val;
      if (val !== null) {
        this.$validate("newEvent.start");
      }
    },
    setDateEnd(val) {
      this.newEvent.end = val;
    },
    setDate(val) {
      this.newEvent.start = val;
      if (!this.dateIsValid(val)) {
        return;
      }
      let self = this;

      if (!this.newEvent.room && this.newEvent.treatment) {
        setTimeout(function () {
          self.$refs.selectRoom.$el.getElementsByTagName("input")[0].focus();
        }, 200);
      }
    },
    enableDateStartEdit() {
      this.$refs.inputDateStart.activeCalendarEdit =
        !this.$refs.inputDateStart.activeCalendarEdit;
    },
    enableDateEndEdit() {
      this.$refs.inputDateEnd.setActiveEdit("end");
    },
    checkPatient(value) {
      if (!value) {
        this.patientPlans = [];
        this.patientPlan = null;
        return;
      }
      setCancelToken(this.cancelToken, "patientsPlanLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/patients/" + value.id + "/plan/active",
        data: {},
        cancelToken: this.cancelToken.patientsPlanLoad.token,
      }).then((response) => {
        if (response.data) {
          this.patientPlans = response.data;
        }
      });
    },
    searchPatients(text = "") {
      this.patientSearch = text;
      this.patientPage = 1;
      this.patientHasMore = true;
      this.patients = [];
      this.loadPatients();
    },
    loadPatients() {
      this.patientsLoading = true;

      let params = {};
      if (this.patientSearch !== "") {
        params.q = this.patientSearch;
      }

      params.page = this.patientPage;
      params.per_page = 10;
      params.statuses = ["active"];
      params.sort_by = ["updated_at|desc"];

      setCancelToken(this.cancelToken, "patientsLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        cancelToken: this.cancelToken.patientsLoad.token,
        params: params,
      })
        .then((response) => {
          this.patientsLoading = false;
          if (response.data) {
            if (response.data.data.length < 10) {
              this.patientHasMore = false;
            }
            this.patients = this.patients.concat(response.data.data);
          }
        })
        .catch(() => (this.patientsLoading = false));
    },
    showPatientCreate(patientFirstName) {
      this.patientCreate = true;
      this.newPatientName = patientFirstName;
    },
    setPatient(val) {
      this.patientCreate = false;
      this.newEvent.patient = val;
      this.checkPatient(val);
    },
    loadFilters() {
      setCancelToken(this.cancelToken, "filtersLoad");
      HTTP({
        method: "GET",
        url: "/api/v1/filters",
        cancelToken: this.cancelToken.filtersLoad.token,
      }).then((response) => {
        if (response.data) {
          this.options.doctors = response.data.length
            ? response.data[0].doctors
            : [];
          this.options.treatments = response.data.length
            ? response.data[0].treatments
            : [];

          if (this.$store.state.modal.splitId) {
            let self = this;
            if (this.$store.state.modal.splitType === "doctors") {
              this.newEvent.doctor = this.options.doctors.find(function (item) {
                return item.id === self.$store.state.modal.splitId;
              });
            }

            if (this.$store.state.modal.splitType === "treatments") {
              this.newEvent.treatment = this.options.treatments.find(function (
                item
              ) {
                return item.id === self.$store.state.modal.splitId;
              });

              if (!this.newEvent.room && this.newEvent.treatment) {
                // setTimeout(function () {
                //   self.$refs.selectRoom.$el
                //     .getElementsByTagName("input")[0]
                //     .focus();
                // }, 200);
              }
            }
          }
        }
      });
    },
    loadMore() {
      if (!this.patientsLoading) {
        this.patientPage = this.patientPage + 1;
        this.loadPatients();
      }
    },
    loadRooms() {
      let params = {};
      if (this.newEvent.start && this.newEvent.end) {
        params.start = this.parseToUTC(this.newEvent.start);
        params.end = this.parseToUTC(this.newEvent.end);
      }
      setCancelToken(this.cancelToken, "roomsLoad");
      HTTP({
        method: "GET",
        url: "/api/v1/rooms",
        data: {},
        params: params,
        cancelToken: this.cancelToken.roomsLoad.token,
      }).then((response) => {
        if (response.data) {
          this.options.rooms = response.data;

          if (this.newEvent.room) {
            let self = this;
            this.newEvent.room = this.options.rooms.find(function (item) {
              return item.id === self.newEvent.room.id;
            });
            return;
          }

          if (
            this.$store.state.modal.splitId &&
            this.$store.state.modal.splitType === "rooms"
          ) {
            let self = this;
            this.newEvent.room = this.options.rooms.find(function (item) {
              return item.id === self.$store.state.modal.splitId;
            });
          }
        }
      });
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped></style>
