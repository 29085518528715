<template>
  <div class="codes-order" v-if="soapNote.cpt_codes">
    <div
      v-for="(cpt, index) in soapNote.cpt_codes"
      class="code-order align-right"
      :key="'cpt_' + index"
    >
      {{ cpt.name }} - {{ cpt.code }} ({{
        soapNote.claim_type !== claimTypes.CLAIM_TYPE_SELF_PAY.value &&
        cpt.include_in_insurance_claim
          ? $t("label.coveredByInsurance")
          : $t("label.coveredByPatient")
      }})
      <span class="bold">
        {{
          soapNote.claim_type !== claimTypes.CLAIM_TYPE_SELF_PAY.value
            ? formatPrice(cpt.price)
            : formatPrice(cpt.self_pay_price)
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { CLAIM_TYPES_OPTIONS } from "@/store";

export default {
  name: "PaymentEventCodes",
  props: {
    soapNote: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      claimTypes: CLAIM_TYPES_OPTIONS,
    };
  },
};
</script>
