<template>
  <div class="patients-form-tab-single">
    <form @submit.prevent="">
      <!--      legal name-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.legalName") }} <span class="required">*</span>
          </div>
          <div class="form-item double no-padding">
            <div
              class="form-item"
              :class="{
                error: this.hasError('legal_first_name'),
              }"
            >
              <label class="item">{{ $t("label.firstName") }}</label>
              <input v-model="formValues.legal_first_name" type="text" />
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('legal_last_name'),
              }"
            >
              <label class="item">{{ $t("label.lastName") }}</label>
              <input v-model="formValues.legal_last_name" type="text" />
            </div>
          </div>
        </div>
      </div>

      <!--      preferred name-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.preferredName") }}
          </div>
          <div class="form-item double no-padding">
            <div class="form-item">
              <label class="item">{{ $t("label.firstName") }}</label>
              <input v-model="formValues.preferred_first_name" type="text" />
            </div>
            <div class="form-item">
              <label class="item">{{ $t("label.lastName") }}</label>
              <input v-model="formValues.preferred_last_name" type="text" />
            </div>
          </div>
        </div>
      </div>

      <!--      pronoun-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.pronoun") }}
          </div>
          <div class="form-item double no-padding">
            <div class="form-item">
              <input v-model="formValues.pronoun" type="text" />
            </div>
          </div>
        </div>
      </div>

      <!--      dob & sex-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.dob") }} <span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formValues.date_of_birth'),
            }"
          >
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              to-now
              use-date
              :original-start-date="formValues.date_of_birth"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'date_of_birth')"
            ></date-time-input>
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.sex") }} <span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('sex'),
            }"
          >
            <div
              class="input-options"
              :key="'sex_input_' + index"
              v-for="(option, index) in sexOptions"
            >
              <input
                type="radio"
                :id="'sex_' + index"
                :value="option"
                v-model="formValues.sex"
              />
              <label class="radio" :for="'sex_' + index">{{ option }}</label>
            </div>
            <div class="input-options">
              <input
                type="radio"
                :key="'sex_input_other'"
                :id="'sex_other'"
                :value="'Other'"
                v-show="sexOptions.indexOf(formValues.sex) > -1"
                v-model="formValues.sex"
              />
              <label
                class="radio"
                :for="'sex_other'"
                :key="'sex_other'"
                v-show="sexOptions.indexOf(formValues.sex) > -1"
                >{{ $t("label.other") }}</label
              >
              <input
                type="text"
                ref="sexOtherInput"
                v-model="formValues.sex"
                v-if="sexOptions.indexOf(formValues.sex) === -1"
              />
            </div>
          </div>
        </div>
      </div>

      <!--      relationship status-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.relationshipStatus") }} <span class="required">*</span>
          </div>
          <div class="form-item double no-padding">
            <div
              class="form-item"
              :class="{
                error: this.hasError('relationship_status'),
              }"
            >
              <input v-model="formValues.relationship_status" type="text" />
            </div>
          </div>
        </div>
      </div>

      <!--      address-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.address") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('street_address'),
            }"
          >
            <label class="item">{{ $t("label.streetAddress") }}</label>
            <input v-model="formValues.street_address" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('street_address_line_2'),
            }"
          >
            <label class="item">{{ $t("label.streetAddressLine2") }}</label>
            <input v-model="formValues.street_address_line_2" type="text" />
          </div>
          <div class="form-item double no-padding">
            <div
              class="form-item"
              :class="{
                error: this.hasError('city'),
              }"
            >
              <label class="item">{{ $t("label.city") }}</label>
              <input v-model="formValues.city" type="text" />
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('state'),
              }"
            >
              <label class="item">{{ $t("label.state") }}</label>
              <input v-model="formValues.state" type="text" />
            </div>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('zip'),
            }"
          >
            <label class="item">{{ $t("label.postalZipCode") }}</label>
            <input v-model="formValues.zip" type="text" />
          </div>
        </div>
      </div>

      <!--      home phone-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.homePhone") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formValues.home_phone_number'),
            }"
          >
            <input
              ref="homePhoneNumber"
              v-imask="masks.phone"
              :value="formValues.home_phone_number"
              @accept="acceptFormValue($event, 'home_phone_number')"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      cell phone-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.cellPhone") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('cell_phone_number'),
            }"
          >
            <input
              ref="cellPhoneNumber"
              v-imask="masks.phone"
              :value="formValues.cell_phone_number"
              @accept="acceptFormValue($event, 'cell_phone_number')"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      work phone-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.workPhone") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formValues.work_phone_number'),
            }"
          >
            <input
              ref="workPhoneNumber"
              v-imask="masks.phone"
              :value="formValues.work_phone_number"
              @accept="acceptFormValue($event, 'work_phone_number')"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      leave voicemail & indicate phone number-->
      <div class="form-item double no-padding">
        <div
          class="form-item-section"
          :class="{
            error: validation.hasError('formValues.ok_to_leave_voicemail'),
          }"
        >
          <div class="form-item-section-title">
            {{ $t("label.leaveVoicemail") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formValues.ok_to_leave_voicemail'),
            }"
          >
            <div
              v-for="(option, index) in leaveVoicemailOptions"
              :key="'ok_to_leave_voicemail_' + option"
              class="input-options"
            >
              <input
                type="radio"
                :id="'ok_to_leave_voicemail_' + option"
                :value="option"
                v-model="formValues.ok_to_leave_voicemail"
              />
              <label class="radio" :for="'ok_to_leave_voicemail_' + option">{{
                index
              }}</label>
            </div>
          </div>
        </div>
        <div
          class="form-item-section"
          :class="{
            error: validation.hasError(
              'formValues.indicate_which_phone_number'
            ),
          }"
        >
          <div class="form-item-section-title">
            {{ $t("label.whichPhoneNumber") }}<span class="required">*</span>
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in whichPhoneNumberOptions"
              :key="'which_phone_' + index"
              class="input-options"
            >
              <input
                type="radio"
                :id="'which_phone_' + index"
                :value="option"
                v-model="formValues.indicate_which_phone_number"
              />
              <label class="radio" :for="'which_phone_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      email-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.email") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('formValues.email'),
            }"
          >
            <input v-model="formValues.email" type="text" />
          </div>
        </div>
      </div>

      <!--      preferred means of contact-->
      <div
        class="form-item no-padding"
        :class="{
          error: validation.hasError('formValues.preferred_means_of_contract'),
        }"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.preferredMeansOfContact")
            }}<span class="required">*</span>
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in preferredContactOptions"
              :key="'preferred_means_of_contract_' + index"
              class="input-options"
            >
              <input
                type="radio"
                :id="'preferred_means_of_contract_' + index"
                :value="option"
                v-model="formValues.preferred_means_of_contract"
              />
              <label
                class="radio"
                :for="'preferred_means_of_contract_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--      married-->
      <div
        class="form-item no-padding"
        :class="{ error: validation.hasError('formValues.married') }"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.married") }}<span class="required">*</span>
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in marriedOptions"
              :key="'married_' + option"
              class="input-options"
            >
              <input
                type="radio"
                :id="'married_' + option"
                :value="option"
                v-model="formValues.married"
              />
              <label class="radio" :for="'married_' + option">{{
                index
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      occupation, employed by-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.occupation") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.occupation" type="text" />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.employedBy") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.employed_by" type="text" />
          </div>
        </div>
      </div>

      <!--      primary care doctor-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.primaryCareDoctor") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.primary_care_doctor" type="text" />
          </div>
        </div>
      </div>

      <div class="form-item-separator"></div>

      <div class="form-item-separator">
        {{ $t("label.insuranceInformation") }}
      </div>

      <!--      subscribers name-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.subscribersName") }}
          </div>
          <div class="form-item double no-padding">
            <div class="form-item">
              <label class="item">{{ $t("label.firstName") }}</label>
              <input
                v-model="formValues.insurance_subscriber_first_name"
                type="text"
              />
            </div>
            <div class="form-item">
              <label class="item">{{ $t("label.lastName") }}</label>
              <input
                v-model="formValues.insurance_subscriber_last_name"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>

      <!--      subscribers dob-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.dob") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              to-now
              use-date
              :index="1"
              :original-start-date="formValues.insurance_date_of_birth"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'insurance_date_of_birth')"
            ></date-time-input>
          </div>
        </div>
      </div>

      <!--      medicare-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.doYouHaveMedicare") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in medicareOptions"
              :key="'insurance_have_medicare_' + option"
              class="input-options"
            >
              <input
                type="radio"
                :id="'insurance_have_medicare_' + option"
                :value="option"
                v-model="formValues.insurance_have_medicare"
              />
              <label class="radio" :for="'insurance_have_medicare_' + option">{{
                index
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      primary Insurance Company & phone-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.primarySecondaryInsurance") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in insuranceCompanyOptions"
              :key="'insurance_company_' + index"
              class="input-options"
            >
              <input
                type="radio"
                :id="'insurance_company_' + index"
                :value="option"
                v-model="formValues.insurance_company"
              />
              <label class="radio" :for="'insurance_company_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.insurancePhone") }}
          </div>
          <div class="form-item">
            <input
              v-imask="masks.phone"
              :value="formValues.insurance_phone_number"
              @accept="acceptFormValue($event, 'insurance_phone_number')"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      member & group id-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.memberId") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.insurance_member_id" type="text" />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.groupId") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.insurance_group_id" type="text" />
          </div>
        </div>
      </div>

      <!--      insurance card file-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            **Please provide us with your insurance card(s) to photocopy for
            claims filing.**
          </div>
          <input
            accept="image/*"
            ref="fileInput"
            type="file"
            name="file-upload"
            multiple="false"
            @change="addInputFile"
            style="display: none"
          />
          <div
            class="inventory-item-main-right-uploader"
            :class="{ dropping: isDropping }"
            @dragover.prevent="isDropping = true"
            @dragleave.prevent="isDropping = false"
            @drop.stop.prevent="handleFileDrop"
            @click.prevent="handleUploadButton"
          >
            <div
              v-if="!formValues.insurance_card_image"
              class="inventory-item-main-right-uploader-thumb"
            >
              <div class="icon icon-thumb"></div>
            </div>
            <div
              v-if="!formValues.insurance_card_image && !insuranceCardSrc"
              class="inventory-item-main-right-uploader-action"
            >
              {{ $t("button.addPhoto") }}
            </div>
            <div
              class="inventory-item-main-right-uploader-image"
              v-if="formValues.insurance_card_image"
            >
              <img :src="insuranceCardSrc" />
              <div class="inventory-item-main-right-uploader-filename">
                {{ formValues.insurance_card_image.name }}
                <div class="remove" @click.prevent.stop="removeImage">
                  <div class="icon icon-trash"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-item no-padding"
        :class="{
          error: this.hasError('signature_authorizes'),
        }"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            <input type="checkbox" v-model="formValues.signature_authorizes" />
            {{ $t("label.signatureAuthorizes") }}<span class="required">*</span>
          </div>
        </div>
        {{ $t("patientForm.signatureFirstLine") }}<br />
        {{ $t("patientForm.signatureSecondLine") }}<br />
        {{ $t("patientForm.signatureThirdLine") }}
      </div>

      <!--      signature & date-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title with-icon">
            {{ $t("label.signature") }}
            <div class="remove" @click.prevent.stop="clearSignature">
              <div class="icon icon-trash"></div>
            </div>
          </div>
          <div
            class="signature-wrapper"
            v-show="formValues.insurance_signature_image === null"
          >
            <canvas
              id="signature-pad"
              class="signature-pad"
              width="400"
              height="200"
            ></canvas>
          </div>
          <div class="signature-wrapper" v-if="insuranceSignatureSrc !== null">
            <img :src="insuranceSignatureSrc" />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.date") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              to-now
              use-date
              :index="2"
              :original-start-date="formValues.insurance_date"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'insurance_date')"
            ></date-time-input>
          </div>
        </div>
      </div>

      <!--      representative name-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.printedName") }}
          </div>
          <div class="form-item double no-padding">
            <div class="form-item">
              <label class="item">{{ $t("label.firstName") }}</label>
              <input
                v-model="formValues.insurance_printed_first_name"
                type="text"
              />
            </div>
            <div class="form-item">
              <label class="item">{{ $t("label.lastName") }}</label>
              <input
                v-model="formValues.insurance_printed_last_name"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>

      <!--      relationship to patient-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.relationshipToPatient") }}
          </div>
          <div class="form-item">
            <input
              v-model="formValues.insurance_relationship_to_patient"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="form-item-separator"></div>

      <!--      experienced in the past-->
      <div
        class="form-item no-padding"
        :class="{
          error: this.hasError('experienced_acupuncture_herbs_in_the_past'),
        }"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.experienceInPast") }}<span class="required">*</span>
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in experienceInPastOptions"
              :key="'exp_' + option"
              class="input-options"
            >
              <input
                type="radio"
                :id="'exp_' + option"
                :value="option"
                v-model="formValues.experienced_acupuncture_herbs_in_the_past"
              />
              <label class="radio" :for="'exp_' + option">{{ index }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      height, weight-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.height") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('height'),
            }"
          >
            <input v-model="formValues.height" type="number" min="0" step="1" />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.weight") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.weight" type="number" min="0" step="1" />
          </div>
        </div>
      </div>

      <!--      blood pressure-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.averageBloodPressure") }}
          </div>
          <div class="form-item">
            <input
              v-model="formValues.average_blood_pressure_reading"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      how did you hear-->
      <div
        class="form-item no-padding"
        :class="{
          error: this.hasError('how_did_you_hear_about_us'),
        }"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.howDidYouHear") }}<span class="required">*</span>
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in howDidYouHearOptions"
              :key="'how_' + index"
              class="input-options"
            >
              <input
                type="radio"
                :id="'how_' + index"
                :value="option"
                v-model="formValues.how_did_you_hear_about_us"
              />
              <label class="radio" :for="'how_' + index">{{ option }}</label>
            </div>
            <div class="input-options">
              <input
                type="radio"
                :key="'how_input_other'"
                :id="'how_other'"
                :value="'Other'"
                v-show="
                  formValues.how_did_you_hear_about_us === null ||
                  howDidYouHearOptions.indexOf(
                    formValues.how_did_you_hear_about_us
                  ) > -1
                "
                v-model="formValues.how_did_you_hear_about_us"
              />
              <label
                class="radio"
                :for="'how_other'"
                :key="'how_other'"
                v-show="
                  formValues.how_did_you_hear_about_us === null ||
                  howDidYouHearOptions.indexOf(
                    formValues.how_did_you_hear_about_us
                  ) > -1
                "
                >{{ $t("label.other") }}</label
              >
              <input
                type="text"
                ref="howOtherInput"
                v-model="formValues.how_did_you_hear_about_us"
                v-if="
                  formValues.how_did_you_hear_about_us !== null &&
                  howDidYouHearOptions.indexOf(
                    formValues.how_did_you_hear_about_us
                  ) === -1
                "
              />
            </div>
          </div>
        </div>
      </div>

      <!--      referral-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.referral") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.referral_who" type="text" />
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
import DateTimeInput from "@/views/private/components/DateTimeInput";
const Validator = SimpleVueValidator.Validator;
import fileMixin from "@/mixins/fileMixin";
import { default as EventBus } from "@/services/eventbus";
import SignaturePad from "signature_pad";
import imaskMixin from "@/mixins/imaskMixin";

export default {
  name: "PatientFormStep1",
  components: { DateTimeInput },
  mixins: [fileMixin, imaskMixin],
  props: ["formData"],
  watch: {
    "formValues.sex": function (val) {
      if (val === "Other") {
        let self = this;
        this.$nextTick(() => {
          self.$refs.sexOtherInput.focus();
        });
      }
    },
    "formValues.how_did_you_hear_about_us": function (val) {
      if (val === "Other") {
        let self = this;
        this.$nextTick(() => {
          self.$refs.howOtherInput.focus();
        });
      }
    },
  },
  data: function () {
    return {
      formValues: {
        legal_first_name: "",
        legal_last_name: "",
        pronoun: "",
        preferred_first_name: "",
        preferred_last_name: "",
        relationship_status: "",
        date_of_birth: null,
        sex: "Male",
        street_address: "",
        street_address_line_2: "",
        city: "",
        state: "",
        zip: "",
        home_phone_number: "",
        cell_phone_number: "",
        work_phone_number: "",
        ok_to_leave_voicemail: null,
        indicate_which_phone_number: null,
        email: "",
        preferred_means_of_contract: null,
        married: null,
        occupation: "",
        employed_by: "",
        primary_care_doctor: "",
        insurance_subscriber_first_name: "",
        insurance_subscriber_last_name: "",
        insurance_date_of_birth: null,
        insurance_have_medicare: null,
        insurance_phone_number: "",
        insurance_company: "",
        insurance_member_id: "",
        insurance_group_id: "",
        insurance_card_image: null,
        signature_authorizes: false,
        insurance_signature_image: null,
        insurance_date: null,
        insurance_printed_first_name: "",
        insurance_printed_last_name: "",
        insurance_relationship_to_patient: "",
        experienced_acupuncture_herbs_in_the_past: null,
        height: null,
        weight: null,
        average_blood_pressure_reading: "",
        how_did_you_hear_about_us: null,
        referral_who: "",
      },
      isDropping: false,
      insuranceCardSrc: null,
      insuranceSignatureSrc: null,
      sexOptions: ["Male", "Female"],
      leaveVoicemailOptions: { Yes: "1", No: "0" },
      whichPhoneNumberOptions: ["Home", "Cell", "Work"],
      preferredContactOptions: ["Text Message", "Email"],
      insuranceCompanyOptions: [
        "Aetna",
        "CareFirst BlueCross BlueShield",
        "GEHA",
        "UMR",
        "United Healthcare",
      ],
      marriedOptions: { Yes: "1", No: "0" },
      experienceInPastOptions: { Yes: "1", No: "0" },
      medicareOptions: { Yes: "1", No: "0" },
      howDidYouHearOptions: [
        "Google Search",
        "Health Fair",
        "Sign",
        "Yelp",
        "Frederick Lifestyle Magazine",
        "Other publication",
        "Internet Search",
      ],
      signaturePad: null,
      canvas: null,
    };
  },
  validators: {
    "formValues.legal_first_name": function (value) {
      return Validator.value(value).required();
    },
    "formValues.legal_last_name": function (value) {
      return Validator.value(value).required();
    },
    "formValues.date_of_birth": function (value) {
      return Validator.value(value).required();
    },
    "formValues.sex": function (value) {
      return Validator.value(value).required();
    },
    "formValues.married": function (value) {
      return Validator.value(value).required();
    },
    "formValues.relationship_status": function (value) {
      return Validator.value(value).required();
    },
    "formValues.work_phone_number": function (value) {
      return Validator.value(value).length(11).required();
    },
    "formValues.home_phone_number": function (value) {
      return Validator.value(value).length(11).required();
    },
    "formValues.email": function (value) {
      return Validator.value(value).required();
    },
    "formValues.street_address": function (value) {
      return Validator.value(value).required();
    },
    "formValues.street_address_line_2": function (value) {
      return Validator.value(value).required();
    },
    "formValues.city": function (value) {
      return Validator.value(value).required();
    },
    "formValues.state": function (value) {
      return Validator.value(value).required();
    },
    "formValues.zip": function (value) {
      return Validator.value(value).required();
    },
    "formValues.cell_phone_number": function (value) {
      return Validator.value(value).length(11).required();
    },
    "formValues.preferred_means_of_contract": function (value) {
      return Validator.value(value).required();
    },
    "formValues.indicate_which_phone_number": function (value) {
      return Validator.value(value).required();
    },
    "formValues.experienced_acupuncture_herbs_in_the_past": function (value) {
      return Validator.value(value).required();
    },
    "formValues.signature_authorizes": function (value) {
      return Validator.value(value).required().in([true]);
    },
    "formValues.ok_to_leave_voicemail": function (value) {
      return Validator.value(value).required();
    },
    "formValues.how_did_you_hear_about_us": function (value) {
      return Validator.value(value).required();
    },
  },
  created() {
    EventBus.$on("upload-file", (file) => {
      let self = this;
      this.formValues.insurance_card_image = file.file;

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          self.insuranceCardSrc = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    });
    if (this.formData) {
      this.mapData();
    }
  },
  mounted() {
    this.canvas = document.getElementById("signature-pad");
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });
  },
  methods: {
    resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    },
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    setDate(value, key) {
      this.formValues[key] = value;
    },
    mapData() {
      const filtered = Object.keys(this.formData)
        .filter((key) => Object.keys(this.formValues).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.formData[key];
          return obj;
        }, {});

      this.formValues = filtered;
      this.formValues["signature_authorizes"] = true;

      if (typeof this.formValues["insurance_card_image"] === "string") {
        this.insuranceCardSrc =
          process.env.VUE_APP_API +
          "/storage/" +
          this.formValues["insurance_card_image"];
      }

      if (typeof this.formValues["insurance_signature_image"] === "string") {
        this.insuranceSignatureSrc =
          process.env.VUE_APP_API +
          "/storage/" +
          this.formValues["insurance_signature_image"];
      }
    },
    acceptFormValue(event, key) {
      const maskRef = event.detail;
      this.formValues[key] = maskRef.unmaskedValue.replaceAll(/ /g, "");
    },
    removeImage() {
      this.formValues.insurance_card_image = null;
      this.insuranceCardSrc = null;
    },
    handleUploadButton() {
      this.$refs.fileInput.click();
    },
    handleFileDrop(event) {
      if (!event.dataTransfer) {
        return;
      }
      let dt = event.dataTransfer;
      this.addDataTransfer(dt);
    },
    clearSignature() {
      this.insuranceSignatureSrc = null;
      this.formValues.insurance_signature_image = null;
      this.signaturePad.clear();
      let self = this;
      this.$nextTick(() => {
        self.resizeCanvas();
      });
    },
    nextTab() {
      let self = this;
      if (self.formValues.insurance_signature_image === null) {
        self.formValues.insurance_signature_image = self.dataURLtoBlob(
          self.signaturePad.toDataURL("image/jpg")
        );
      }
      self.$validate().then(function (success) {
        if (success) {
          self.$emit("next-tab", true);
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.fillAllFieldsAndCheckValidity"),
          });
        }
      });
    },
  },
};
</script>
