<template>
  <div class="modal" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>
                  <div class="icon-folder"></div>
                  Create folder
                </div>
                <div class="icon-close-round" @click.prevent="closeModal"></div>
              </div>
            </div>
          </div>

          <div class="modal-dialog-main-section">
            <div class="modal-dialog-main-form">
              <div
                class="form-item"
                :class="{
                  error: validation.hasError('folderName'),
                }"
              >
                <label class="item">Folder Name: </label>
                <input
                  ref="folderName"
                  v-model="folderName"
                  type="text"
                  placeholder="Name"
                />
              </div>
              <div class="form-item action no-padding">
                <a class="button action" @click.prevent="save()">
                  Create folder
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import { default as EventBus } from "@/services/eventbus";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "FolderCreate",
  data() {
    return {
      folderName: "",
    };
  },
  validators: {
    folderName: function (value) {
      return Validator.value(value).required();
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          HTTP({
            method: "POST",
            url: "/api/v1/files/folders",
            data: {
              name: self.folderName,
              path: self.$store.state.documents.currentPath,
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.thumbsUp"),
                text: self.$t("notification.folderCreated"),
              });
              EventBus.$emit("documents-load");
              self.closeModal();
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.formatError(
                  error,
                  self.$t("notification.folderNotCreated")
                ),
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
