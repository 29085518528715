import axios from "axios";
import store from "@/store";
import qs from "qs";

// variables for parallel requests
// eslint-disable-next-line no-unused-vars
let isRefreshing = false;
let failedQueue = [];

// Axios instance used for api with private route access
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "indicies" });
  },
});

// Axios instance used for api with public route access
export const HTTP_PUBLIC = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "indicies" });
  },
});

// Cancel token for canceling requests
export const CancelToken = axios.CancelToken;

// Axios instance request (before) interceptor
HTTP.interceptors.request.use(async (config) => {
  // append access token to every request
  let token = getAccessToken();
  config.headers.Authorization = "Bearer " + token;

  return config;
});

function shouldRefreshToken(error) {
  return (
    error.response &&
    !["login", "logout", "refresh"].some((route) => {
      return error.response.config.url.includes(route);
    }) &&
    (error.response.data.error.code === 1002 || error.response.status === 401)
  );
}

// Axios instance response (after) interceptor
HTTP.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (Object.getPrototypeOf(error) === axios.Cancel.prototype) {
      return new Promise(() => {});
    }

    if (shouldRefreshToken(error)) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return HTTP(error.config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      if (getRefreshToken()) {
        isRefreshing = true;
        return new Promise(function (resolve, reject) {
          getNewAccessToken()
            .then((response) => {
              store.dispatch("setAuth", response.data);
              resolve(HTTP(error.config));
              processQueue(null);
            })
            .catch((err) => {
              logout();
              failedQueue = [];
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      logout();
      return;
    }

    appendErrorMessage(error);
    return Promise.reject(error);
  }
);

// Axios instance response (after) interceptor
HTTP_PUBLIC.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    appendErrorMessage(error);
    return Promise.reject(error);
  }
);

// help functions
function getAccessToken() {
  return store.state.auth.access_token;
}
export function logout(redirect = "") {
  logoutApi().finally(() => {
    logoutClient(redirect);
  });
}

function logoutClient(redirect = "") {
  let url =
    redirect !== ""
      ? getUserTypeRoutes().clientRoute +
        "?redirect=" +
        encodeURIComponent(redirect)
      : getUserTypeRoutes().clientRoute;
  store.dispatch("setLogout").then(() => {
    window.location.href = url;
  });
}

async function logoutApi() {
  if (store.state.auth.access_token) {
    await HTTP.post(process.env.VUE_APP_API + getUserTypeRoutes().logoutUrl);
  }
}

function getUserTypeRoutes() {
  let routes;
  switch (store.state.userType) {
    case "user":
      routes = {
        clientRoute: "/login",
        logoutUrl: "/api/v1/logout",
      };
      break;
    case "doctor":
      routes = {
        clientRoute: "/doctors/login",
        logoutUrl: "/api/v1/doctors/logout",
      };
      break;
    case "patient":
      routes = {
        clientRoute:
          store.state.user && store.state.user.clinic
            ? "/" + store.state.user.clinic.slug + "/login"
            : "/login",
        logoutUrl: "/api/v1/patients/logout",
      };
      break;
    default:
      routes = {
        clientRoute: "/login",
        logoutUrl: "/api/v1/logout",
      };
      break;
  }

  return routes;
}

function getRefreshToken() {
  return !store.getters.isAuthUser ? false : store.state.auth.refresh_token;
}

// Function to cancel axios tokens object
export function cancelTokens(tokens) {
  Object.entries(tokens).forEach(function (item) {
    // this is [key, value] array
    if (item[1]) {
      item[1].cancel();
    }
  });
}

// Set token with previous cancelation
export function setCancelToken(tokens, key) {
  if (tokens[key]) {
    tokens[key].cancel();
  }

  tokens[key] = CancelToken.source();
}

function appendErrorMessage(error) {
  if (error.response && error.response.data && error.response.data.error) {
    error.message = error.response.data.error.message;
    error.details = [];
    if (error.response.data.error.details) {
      for (let item in error.response.data.error.details) {
        error.details.push(error.response.data.error.details[item][0]);
      }
    }
  }
}

function getNewAccessToken() {
  let url = "";
  switch (store.state.userType) {
    case "user":
      url = "/api/v1/login";
      break;
    case "doctor":
      url = "/api/v1/doctors/login";
      break;
    case "patient":
      url = "/api/v1/patients/login";
      break;
    default:
      url = "/api/v1/login";
      break;
  }
  return axios.post(process.env.VUE_APP_API + url + "/refresh/", {
    refresh_token: getRefreshToken(),
  });
}

function processQueue(error) {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(getAccessToken());
    }
  });

  failedQueue = [];
}
