<template>
  <div class="modal" id="active-modal">
    <div v-if="!loading" class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div v-if="orderDetails" class="modal-dialog-main-section-row">
              {{ $t("label.totalPrice") }}:
              {{ formatPrice(orderDetails.total) }}
              {{ $t("label.date") }}:
              {{ customFormat(orderDetails.created_at, "MM / dd / yyyy") }}
              {{ $t("label.status") }}:
              {{ orderDetails.status }}
            </div>
            <template v-if="orderDetails && orderDetails.payment_breakdown">
              <div
                v-for="(item, index) in orderDetails.payment_breakdown.filter(
                  (item) => {
                    return item.amount > 0;
                  }
                )"
                class="modal-dialog-main-section-row"
                :key="'order_details_payment_breakdown_' + index"
              >
                {{ item.type }} {{ formatPrice(item.amount) }}
              </div>
            </template>
            <div v-if="orderDetails" class="modal-dialog-main-section-row">
              <template
                v-for="(item, index) in orderDetails.orderItems"
                :key="'item_' + index"
              >
                <payments-single-item-overview :item="item" />
              </template>
            </div>
          </div>
          <div class="modal-dialog-main-section action">
            <a class="button action" @click.prevent="closeModal">
              {{ $t("button.close") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import PaymentsSingleItemOverview from "@/views/private/components/payments/PaymentsSingleItemOverview";

export default {
  name: "OrderDetails",
  components: { PaymentsSingleItemOverview },
  props: ["patientId", "orderId"],
  data() {
    return {
      loading: false,
      orderDetails: null,
      cancelToken: { load: null },
    };
  },
  created() {
    this.loadDetails();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadDetails() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/patients/" +
          this.patientId +
          "/orders/" +
          this.orderId,
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data.data) {
            this.orderDetails = response.data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
