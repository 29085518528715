<template>
  <div class="options-menu-wrapper">
    <div
      class="action"
      :ref="'action_' + doctor.id"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + doctor.id],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="edit">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ isInactiveDoctor(doctor) ? $t("label.view") : $t("label.edit") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        @click.prevent="changePassword"
        v-if="!isInactiveDoctor(doctor)"
      >
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.changePassword") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        v-if="!isInactiveDoctor(doctor)"
        @click.prevent="changeStatus"
      >
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.deactivate") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        v-if="isInactiveDoctor(doctor)"
        @click.prevent="changeStatus"
      >
        <item-icon type="shortcut" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.activate") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";

export default {
  name: "DoctorOptionsMenu",
  props: ["doctor"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.doctor.id,
      });
    },
    edit() {
      this.$router.push({
        name: "doctorEdit",
        params: {
          id: this.doctor.id,
        },
      });
    },
    changeStatus() {
      this.$router.push({
        name: "doctorChangeStatus",
        params: {
          id: this.doctor.id,
        },
      });
    },
    changePassword() {
      EventBus.$emit("open-modal", {
        type: "doctor-change-password",
        item: this.doctor,
      });
    },
    // remove() {
    //   EventBus.$emit("open-modal", {
    //     type: "remove-item",
    //     data: {
    //       item: this.doctor,
    //       type: "doctor",
    //       deactivate: true
    //     }
    //   });
    // },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.doctor.id) {
        this.menuOpened = false;
      }
    },
  },
};
</script>
