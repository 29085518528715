export default {
  methods: {
    scrollToLastSelected(ref) {
      this.$nextTick(() => {
        const elements = this.$refs[ref].$el.querySelectorAll(
          ".vs__dropdown-menu .vs__dropdown-option--disabled"
        );
        const last = elements[elements.length - 1];
        if (last) {
          last.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      });
    },
    optionAvailable(option, options) {
      let index = options.findIndex(function (item) {
        return item.object && item.object.key === option.key;
      });

      return index === -1;
    },
  },
};
