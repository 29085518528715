<template>
  <div class="patients-form-tab-single">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.systemsReview") }}
      </div>

      <!--      constitutional general-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.constitutionalGeneral") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in constitutionalOptions"
              :key="'systems_review_general_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_general_' + index"
                :value="option"
                v-model="formValues.systems_review_general"
              />
              <label class="radio" :for="'systems_review_general_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      eyes-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.eyes") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in eyesOptions"
              :key="'systems_review_eyes_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_eyes_' + index"
                :value="option"
                v-model="formValues.systems_review_eyes"
              />
              <label class="radio" :for="'systems_review_eyes_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      enmt-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.enmt") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in enmtOptions"
              :key="'systems_review_enmt_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_enmt_' + index"
                :value="option"
                v-model="formValues.systems_review_enmt"
              />
              <label class="radio" :for="'systems_review_enmt_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      cardiovascular-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.cardiovascular") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in cardiovascularOptions"
              :key="'systems_review_cardiovascular_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_cardiovascular_' + index"
                :value="option"
                v-model="formValues.systems_review_cardiovascular"
              />
              <label
                class="radio"
                :for="'systems_review_cardiovascular_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--      respiratory-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.respiratory") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in respiratoryOptions"
              :key="'systems_review_respiratory_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_respiratory_' + index"
                :value="option"
                v-model="formValues.systems_review_respiratory"
              />
              <label
                class="radio"
                :for="'systems_review_respiratory_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--      gastrointestinal-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.gastrointestinal") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in gastrointestinalOptions"
              :key="'systems_review_gastrointestinal_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_gastrointestinal_' + index"
                :value="option"
                v-model="formValues.systems_review_gastrointestinal"
              />
              <label
                class="radio"
                :for="'systems_review_gastrointestinal_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--      systems_review_uro_genital-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.uroGenital") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in uroGenitalOptions"
              :key="'uroGenital_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'uroGenital_' + index"
                :value="option"
                v-model="formValues.systems_review_uro_genital"
              />
              <label class="radio" :for="'uroGenital_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      gynecology-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.gynecology") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in gynecologyOptions"
              :key="'systems_review_gynecology_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_review_gynecology_' + index"
                :value="option"
                v-model="formValues.systems_review_gynecology"
              />
              <label
                class="radio"
                :for="'systems_review_gynecology_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--      number of pregnancies/births-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.numberOfPregnancies") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_number_of_pregnancies'),
            }"
          >
            <input
              v-model="formValues.systems_number_of_pregnancies"
              type="text"
            />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.numberOfBirths") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_number_of_births'),
            }"
          >
            <input v-model="formValues.systems_number_of_births" type="text" />
          </div>
        </div>
      </div>

      <!--      age of firs menes & duration of menes-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.ageAtFirstMenes") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_age_at_first_menses'),
            }"
          >
            <input
              v-model="formValues.systems_age_at_first_menses"
              type="text"
            />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.durationOfMenes") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_duration_of_menses'),
            }"
          >
            <input
              v-model="formValues.systems_duration_of_menses"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      date of last menes-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.dateOfLastMenes") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              to-now
              use-date
              :original-start-date="formValues.systems_date_of_last_menses"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="
                formValues.systems_date_of_last_menses = $event
              "
            ></date-time-input>
          </div>
        </div>
      </div>

      <!--     number of days between menes-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.numberOfDaysBetweenMenes") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_number_of_days_between_menses'),
            }"
          >
            <input
              v-model="formValues.systems_number_of_days_between_menses"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      skin-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.skin") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in skinOptions"
              :key="'systems_skin_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_skin_' + index"
                :value="option"
                v-model="formValues.systems_skin"
              />
              <label class="radio" :for="'systems_skin_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      musculoskeletal-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.musculoskeletal") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in musculoskeletalOptions"
              :key="'systems_musculoskeletal_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_musculoskeletal_' + index"
                :value="option"
                v-model="formValues.systems_musculoskeletal"
              />
              <label class="radio" :for="'systems_musculoskeletal_' + index">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--      Neuropathy/Psychology-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.neuropathyPsychology") }}
          </div>
          <div class="form-item">
            <div
              v-for="(option, index) in neuropathyPsychologyOptions"
              :key="'systems_neuropathy_psychology_' + index"
              class="input-options checkbox"
            >
              <input
                type="checkbox"
                :id="'systems_neuropathy_psychology_' + index"
                :value="option"
                v-model="formValues.systems_neuropathy_psychology"
              />
              <label
                class="radio"
                :for="'systems_neuropathy_psychology_' + index"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <!--     early development-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("patientForm.earlyDevelopment") }}
          </div>
          <div class="form-item">
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.systems_early_development"
            />
          </div>
        </div>
      </div>

      <!--     Significant traumas-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("patientForm.significantTraumas") }}:
          </div>
          <div class="form-item">
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.systems_significant_traumas"
            />
          </div>
        </div>
      </div>

      <!--      systems_allergies-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.allergies") }}:
          </div>
          <div class="form-item">
            <input v-model="formValues.systems_allergies" type="text" />
          </div>
        </div>
      </div>

      <!--      Prescription Medications-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.prescriptionMedications") }}:
          </div>
          <div class="form-item">
            <input
              v-model="formValues.systems_prescription_medications"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      Medicines, Vitamins, Herbs-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.medicinesVitaminsHerbs") }}:
          </div>
          <div class="form-item">
            <input
              v-model="formValues.systems_medicines_vitamins_herbs"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      Describe any exercise you are currently doing-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.describeExercise") }}:
          </div>
          <div class="form-item">
            <input v-model="formValues.systems_exercise" type="text" />
          </div>
        </div>
      </div>

      <!--     Describe your daily diet (breakfast, lunch, dinner)-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.dailyDiet") }}:
          </div>
          <div class="form-item">
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.systems_daily_diet"
            />
          </div>
        </div>
      </div>

      <!--      tobacco/coffee-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.tobaccoUse") }}
          </div>
          <div class="form-item">
            <input v-model="formValues.systems_tobacco" type="text" />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.howMuchCoffeeTea") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_coffee_tea_or_caffeine_per_week'),
            }"
          >
            <input
              v-model="formValues.systems_coffee_tea_or_caffeine_per_week"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      alcohol-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.howMuchAlcohol") }}
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('systems_alcohol_per_week'),
            }"
          >
            <input v-model="formValues.systems_alcohol_per_week" type="text" />
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action margin-right-15" @click="prevTab()">
          {{ $t("button.previous") }}
        </div>
        <div class="button action" @click="submit()">
          {{ $t("button.submit") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import fileMixin from "@/mixins/fileMixin";
import autoResize from "@/mixins/autoResize";
import SimpleVueValidator from "simple-vue3-validator";
import { INTEGER } from "@/mixins/constMixin";
import DateTimeInput from "@/views/private/components/DateTimeInput.vue";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientFormStep4",
  components: { DateTimeInput },
  mixins: [fileMixin, autoResize],
  props: ["formData"],
  data: function () {
    return {
      formValues: {
        systems_review_general: [],
        systems_review_eyes: [],
        systems_review_enmt: [],
        systems_review_cardiovascular: [],
        systems_review_respiratory: [],
        systems_review_gastrointestinal: [],
        systems_review_uro_genital: [],
        systems_review_gynecology: [],
        systems_number_of_pregnancies: null,
        systems_number_of_births: null,
        systems_age_at_first_menses: null,
        systems_duration_of_menses: null,
        systems_date_of_last_menses: null,
        systems_number_of_days_between_menses: null,
        systems_skin: [],
        systems_musculoskeletal: [],
        systems_neuropathy_psychology: [],
        systems_early_development: "",
        systems_significant_traumas: "",
        systems_allergies: "",
        systems_prescription_medications: "",
        systems_medicines_vitamins_herbs: "",
        systems_exercise: "",
        systems_daily_diet: "",
        systems_tobacco: "",
        systems_coffee_tea_or_caffeine_per_week: null,
        systems_alcohol_per_week: null,
      },
      constitutionalOptions: [
        "Weight Loss",
        "Weight Gain",
        "Heat Intolerance",
        "Cold Intolerance",
        "Poor Appetite",
        "Fatigue",
        "Easy Bruising",
        "Night Sweats",
        "Sweat Easily",
        "Poor Sleeping",
        "Cravings",
        "Tastes or smells",
        "Chills",
      ],
      eyesOptions: [
        "Blurry Vision",
        "Eye Pain",
        "Eye Discharge",
        "Eye Redness",
        "Glasses",
        "Dry Eyes",
        "Decrease in Vision",
        "Floaters",
      ],
      enmtOptions: [
        "Nose Bleeds",
        "Sinus Problems",
        "Swollen Lymph Nodes",
        "Sore Throat",
        "Facial Pain",
        "Grinding Teeth",
        "Phlegm",
        "Lip or Tongue Sores",
        "Hearing Loss",
        "Ringing in Ears",
        "Earaches",
        "Headaches",
      ],
      cardiovascularOptions: [
        "Chest Pain",
        "Palpitations",
        "Rapid Heart Beat",
        "Poor Circulation",
        "Swelling in Extremities",
        "High Blood Pressure",
        "Low Blood Pressure",
        "Irregular Heart Beats",
        "Cold Hands or Feet",
        "Blood Clots",
        "Fainting",
      ],
      respiratoryOptions: [
        "Shortness of Breath",
        "Chronic Cough",
        "Coughing up Blood",
        "History of Tuberculosis",
        "Bronchitis",
        "Pneumonia",
        "Asthma",
      ],
      gastrointestinalOptions: [
        "Nausea",
        "Vomitting",
        "Diarrhea",
        "Constipation",
        "Blood in Stool",
        "Bad Breath",
        "Abdominal Pain or Cramps",
        "Chronic Laxative Use",
        "Gas",
        "Belching",
        "Indigestion",
        "Hemorrhoids",
      ],
      uroGenitalOptions: [
        "Frequent UTI's",
        "Blood in Urine",
        "Painful Urination",
        "Incontinence",
        "Frequent Urination",
        "Decrease in Flow",
        "Sores on Genitals",
        "Sexual Dysfunction",
      ],
      gynecologyOptions: [
        "Heavy Period",
        "Light Period",
        "Painful Period",
        "Clots with Flow",
        "Irregular Periods",
        "Vaginal Discharge",
        "Breast Lumps",
        "PMS",
      ],
      skinOptions: [
        "Rash/Hives",
        "Itching",
        "Dandruff",
        "Ulcerations",
        "Eczema",
        "Pimples",
        "Mole Changes",
        "Nail Changes",
      ],
      musculoskeletalOptions: [
        "Muscle Aches",
        "Joint Swelling",
        "Neck Pain",
        "Back Pain",
        "Shoulder Pain",
        "Knee Pain",
        "Hip Pain",
        "Foot/Ankle Pain",
        "Hand/Wrist Pain",
        "Frequent Leg Cramps",
        "Muscle Weakness",
      ],
      neuropathyPsychologyOptions: [
        "Anxiety",
        "Depression",
        "Suicidal Thoughts",
        "Panic Attacks",
        "Seizures",
        "Concussion",
        "Bad Temper",
        "Dizziness/Vertigo",
        "Poor Memory",
        "Loss of Balance",
        "Numbness",
        "Tremors",
        "Alcohol or Drug Dependence",
        "Use of Anti-Depressants",
        "Easily Susceptible to Stress",
      ],
    };
  },
  validators: {
    "formValues.systems_number_of_pregnancies": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_number_of_births": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_age_at_first_menses": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_duration_of_menses": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_number_of_days_between_menses": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_coffee_tea_or_caffeine_per_week": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
    "formValues.systems_alcohol_per_week": function (value) {
      return Validator.value(value)
        .integer()
        .greaterThanOrEqualTo(0)
        .regex(INTEGER);
    },
  },
  created() {
    if (this.formData) {
      this.mapData();
    }
  },
  mounted() {},
  methods: {
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    mapData() {
      const filtered = Object.keys(this.formData)
        .filter((key) => Object.keys(this.formValues).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.formData[key];
          return obj;
        }, {});

      this.formValues = filtered;
    },
    prevTab() {
      this.$emit("prev-tab", true);
    },
    submit() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.$emit("submit-form");
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.fillAllFieldsAndCheckValidity"),
          });
        }
      });
    },
  },
};
</script>
