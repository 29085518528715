<template>
  <div class="row">
    <div class="col-lg-12 patients-form">
      <patient-consent-form-financial />
    </div>
  </div>
</template>

<script>
import PatientConsentFormFinancial from "@/views/shared/components/consents/PatientConsentFormFinancial";
export default {
  name: "PatientConsentFinancial",
  components: { PatientConsentFormFinancial },
};
</script>
