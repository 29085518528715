<template>
  <div class="filter-menu-item" ref="sidebarProviders">
    <div class="filter-menu-item-title">
      <span
        :class="{
          'filter-menu-item-title-label': true,
          focused: isFilterFocused,
        }"
        @click="focusFilter"
        >{{ $tc("label.provider", 2) }}</span
      >
      <span class="filter-menu-item-title-select" @click="selectAll"
        >Select all</span
      >
    </div>
    <template v-for="(doctor, idx) in doctors">
      <template v-if="typeof doctor !== 'undefined'">
        <div
          :key="'doctor' + idx + doctor.id + 'parent'"
          :class="'filter-item ' + colorClass(doctor.id, 'doctor')"
        >
          <div class="filter-item-icon">
            <item-icon
              type="doctor-2"
              :class-name="colorClass(doctor.id, 'doctor')"
            ></item-icon>
          </div>
          <label :for="'doctor_' + doctor.id"
            >{{ doctor.last_name }}, {{ doctor.first_name }}
            <input
              type="checkbox"
              class="filter-item-checkbox"
              :id="'doctor_' + doctor.id"
              v-bind:value="doctor.id"
              v-model="checkedValues"
              @change="updateFilters"
            />
            <span class="filter-item-checkbox-checkmark"></span>
          </label>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "FilterMenuDoctors",
  mixins: [onboardTooltipMixin],
  props: ["doctors", "type"],
  components: { ItemIcon },
  data() {
    return {
      checkedValues:
        typeof this.$store.state.filter.doctors !== "undefined"
          ? this.$store.state.filter.doctors
          : [],
    };
  },
  mounted() {
    this.updateFilters = this.asDebounce(this.updateFilters, 500);
  },
  computed: {
    isFilterFocused() {
      return this.$store.state.focusedFilter === "doctors";
    },
  },
  methods: {
    updateFilters() {
      this.$store.dispatch("setFilter", {
        doctors: true,
        values: this.checkedValues,
      });
    },
    selectAll() {
      if (this.checkedValues.length === this.doctors.length) {
        this.checkedValues = [];
      } else {
        this.checkedValues = this.doctors.map(function (item) {
          return item.id;
        });
      }
      this.updateFilters();
    },
    focusFilter() {
      this.$store.dispatch("setFocusedFilter", "doctors");
    },
  },
};
</script>

<style scoped></style>
