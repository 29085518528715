<template>
  <div class="row">
    <div class="col-lg-12 soap-preview">
      <div class="soap-preview-wrapper">
        <div class="soap-preview-wrapper-header">
          <div class="back" @click="back">
            <div class="icon icon-arrow-left-primary"></div>
            {{ $t("button.home") }}
          </div>
          <div
            class="calendar-main-inner-top-agenda-icon print"
            @click.prevent="printSoap()"
          ></div>
        </div>
        <!-- Soap notes-->
        <div id="soap">
          <template v-if="soapModel">
            <div
              v-for="type in soapTypes"
              :key="'type_' + type.id"
              class="soap-preview-item"
            >
              <div class="soap-preview-title">
                {{ type.name }} data ({{ type.name.substr(0, 1) }}):
              </div>
              <template
                v-for="item in filteredItems(type)"
                :key="'textarea_' + type.id + '_' + item.id + '_title'"
              >
                <span class="note title">{{ item.label_result }}</span>
                <pre
                  class="note auto-resize"
                  disabled
                  :ref="'textarea_' + type.id + '_' + item.id + '_text'"
                  v-html="getValueFromTemplate(item.template)"
                ></pre>
              </template>
            </div>
          </template>

          <!-- Dx-->
          <div class="soap-preview-item" v-if="soapModel">
            <div class="soap-preview-title">{{ $t("label.dxCode") }}:</div>
            <pre class="note auto-resize"><div
                v-for="(code, index) in soapModel.dx_codes"
                :key="'dx_prev_' + index"
            >{{ code.name }} - {{ code.code }}<br /></div></pre>
          </div>

          <!-- Cpt-->
          <div class="soap-preview-item" v-if="soapModel">
            <div class="soap-preview-title">{{ $t("label.cptCode") }}:</div>
            <pre class="note auto-resize"><div
                v-for="(code, index) in soapModel.cpt_codes"
                :key="'cpt_prev_' + index"
            >{{ code.name }} - {{ code.code }}<br /></div></pre>
          </div>

          <!-- Px-->
          <div class="soap-preview-item" v-if="soapModel">
            <div class="soap-preview-title">
              {{ $t("label.prescriptions") }}:
            </div>
            <pre class="note auto-resize"><div
                v-for="(product, index) in filteredProducts"
                :key="'product_prev_' + index"
            >{{ product.name }} - {{$t("label.quantity")}}: {{ product.quantity }}, {{$t("label.price")}}: {{ formatPrice(product.price) }}<br /></div></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import autoResize from "@/mixins/autoResize";
import soapParser from "@/mixins/soapParser";
import axios from "axios";
import printJS from "print-js";
export default {
  name: "SoapNotesPreview",
  mixins: [autoResize, soapParser],
  data() {
    return {
      soapTypes: [],
      soapModel: null,
      products: [],
      promises: [],
      cancelToken: {
        soapTypes: null,
        soapNotes: null,
        products: null,
      },
      loading: false,
    };
  },
  created() {
    this.promises.push(this.loadSoapTypes());
    this.promises.push(this.loadProducts());
  },
  mounted() {
    let scope = this;
    axios.all(this.promises).then(function () {
      scope.loadSoapNotes();
    });
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  computed: {
    filteredProducts() {
      let self = this;
      return this.soapModel.prescriptions.map(function (item) {
        let index = self.products.findIndex(function (product) {
          return product.id === item.id;
        });
        if (index > -1) {
          return {
            name: self.products[index].name,
            quantity: item.quantity,
            price: self.products[index].price,
          };
        }
      });
    },
  },
  methods: {
    printSoap() {
      printJS({
        printable: "soap",
        type: "html",
        targetStyles: ["*"],
        scanStyles: true,
        showModal: false,
      });
    },
    filteredItems(type) {
      if (this.soapModel.length === 0) {
        return [];
      }

      return this.soapModel.items.filter(function (item) {
        return item.soap_note_type.id === type.id;
      });
    },
    loadProducts() {
      setCancelToken(this.cancelToken, "products");
      return HTTP({
        method: "GET",
        url: "/api/v1/inventory/products",
        params: {},
        cancelToken: this.cancelToken.products.token,
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.products = response.data.data;
          }
        })
        .catch(() => {});
    },
    loadSoapTypes() {
      this.loading = true;
      setCancelToken(this.cancelToken, "soapTypes");
      return HTTP({
        method: "GET",
        url: "/api/v1/soap-note-types",
        cancelToken: this.cancelToken.soapTypes.token,
      })
        .then((response) => {
          this.soapTypes = response.data;
          this.setCurrentSoap(response.data[0]);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadSoapNotes() {
      this.loading = true;
      setCancelToken(this.cancelToken, "soapNotes");
      return HTTP({
        method: "GET",
        url: "/api/v1/soap-notes/" + this.$route.params.id,
        cancelToken: this.cancelToken.soapNotes.token,
      })
        .then((response) => {
          if (response.data) {
            this.soapModel = response.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({ name: "home" }).catch(() => {});
    },
  },
};
</script>
