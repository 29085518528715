<template>
  <div class="row">
    <div class="col-lg-12 soap">
      <div class="soap-wrapper">
        <template v-if="!loading">
          <div class="patient-title" v-if="eventData">
            {{
              eventData.patient.first_name + " " + eventData.patient.last_name
            }}
          </div>
          <div class="tabs">
            <!-- Soap tabs -->
            <div
              class="tabs-tab"
              :class="{
                active: soap.name === activeTab,
              }"
              v-for="soap in soapTypes"
              :key="'soap_' + soap.id"
            >
              <button
                :class="{
                  'button tab': true,
                  active: soap.name === activeTab,
                }"
                @click.prevent="setCurrentTab(soap, true)"
              >
                <div>{{ soap.name }}</div>
                <div v-if="countByType(soap)" class="counter">
                  {{ countByType(soap) }}
                </div>
              </button>
            </div>

            <!-- CptCodes tab -->
            <div
              class="tabs-tab"
              :class="{
                active: activeTab === 'insurance',
              }"
              @click.prevent="setCurrentTab('insurance')"
            >
              <button
                :class="{
                  'button tab': true,
                  active: activeTab === 'insurance',
                }"
              >
                <div>
                  {{ $t("label.claimInfo") }}
                </div>
                <div v-if="cptCodeModel.length" class="counter">
                  {{ cptCodeModel.length }}
                </div>
              </button>
            </div>
            <!-- Prescriptions tab -->
            <div
              class="tabs-tab"
              :class="{
                active: activeTab === 'prescriptions',
              }"
              @click.prevent="setCurrentTab('prescriptions')"
            >
              <button
                :class="{
                  'button tab': true,
                  active: activeTab === 'prescriptions',
                }"
              >
                <div>
                  {{ $t("label.prescriptions") }}
                </div>
                <div v-if="prescriptions.length" class="counter">
                  {{ prescriptions.length }}
                </div>
              </button>
            </div>
          </div>

          <!-- Soap items -->
          <draggable
            v-if="soapType && soapModel.length"
            class="expandable-block-wrapper"
            v-model="soapModel"
            item-key="id"
            handle=".handle"
          >
            <template #item="{ element: item, index }">
              <div
                class="expandable-block"
                :class="{
                  hidden: item.soap_note_type.id !== soapType.id,
                  invalid: !item.valid,
                }"
                @click="item.valid = true"
                :ref="item.soap_note_type.id + '_' + index"
                :key="item.soap_note_type.id + '_' + index"
              >
                <div
                  class="expandable-block-header"
                  @click="setItemExpanded(item)"
                  v-show="item.soap_note_type.id === soapType.id"
                >
                  <div class="handle"></div>
                  <div class="title">
                    {{
                      item.title ? item.title : $t("label.chosenConditionTitle")
                    }}
                  </div>
                  <div
                    v-if="item.id"
                    class="expandable-block-redo"
                    @click.stop.prevent="redo(item, index)"
                    v-tooltip="$t('tooltip.redoSoap')"
                  ></div>
                  <div
                    class="expandable-block-toggle"
                    :class="{ expanded: item.expanded }"
                  ></div>
                </div>
                <div
                  class="expandable-block-body"
                  :class="{ expanded: item.expanded }"
                >
                  <div class="soap-type-chooser" v-if="soapType">
                    <div class="label">{{ $t("label.condition") }}</div>
                    <div class="soap-type-chooser-item">
                      <v-select
                        label="title"
                        v-model="item.object"
                        :ref="'conditionChooser_' + index"
                        :options="soapType.predefined_text"
                        :placeholder="$t('placeholder.selectCondition')"
                        :selectable="
                          (option) => optionAvailable(option, soapModel)
                        "
                        @open="
                          scrollToLastSelected('conditionChooser_' + index)
                        "
                        @update:modelValue="() => setSoapItemValue(item, index)"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="label" v-if="item.title">
                    {{ $t("label.conditionTemplate") }}
                  </div>
                  <div
                    class="condition-template"
                    v-if="item.object"
                    style="white-space: pre-wrap"
                  >
                    <template v-for="(soap, soapIndex) in item.object.soap"
                      ><template v-if="soap.type === 'image-input'"
                        ><input
                          v-if="soap.value === null"
                          type="file"
                          capture="environment"
                          accept="image/*"
                          :key="getKey(soapType.id, index, soapIndex)"
                          multiple="false"
                          @change="addInputFile($event, soap)"
                        /><template v-else
                          ><div :key="getKey(soapType.id, index, soapIndex)">
                            <img :src="getImageValue(soap)" /><button
                              @click="removeImage(soap)"
                            >
                              {{ $t("button.remove") }}
                            </button>
                          </div></template
                        ></template
                      ><template v-if="soap.type === 'input'"
                        ><input
                          type="text"
                          v-model="soap.value"
                          :key="
                            getKey(soapType.id, index, soapIndex)
                          " /></template
                      ><template v-if="soap.type === 'select'"
                        ><v-select
                          :options="soap.options"
                          :multiple="soap.multiple"
                          v-model="soap.value"
                          :key="getKey(soapType.id, index, soapIndex)"
                        ></v-select></template
                      ><template v-if="soap.type === 'button-select'"
                        ><div
                          class="selectable-wrapper"
                          :key="soapIndex + '_selectable-wrapper'"
                        >
                          <div
                            v-for="(option, index) in soap.options"
                            :key="index + '_' + option.value"
                          >
                            <div
                              class="button selectable"
                              v-show="
                                option.parent === null ||
                                (soap.value &&
                                  soap.value.findIndex(function (item) {
                                    return item.group === option.parent;
                                  }) > -1)
                              "
                              :class="{
                                selected:
                                  soap.value &&
                                  soap.value.findIndex(function (item) {
                                    return item.value === option.value;
                                  }) > -1,
                                'has-parent': option.parent !== null,
                              }"
                              @click.prevent="toggleButtonSelect(soap, option)"
                            >
                              {{ option.value }}
                            </div>
                          </div>
                        </div></template
                      ><template v-if="soap.type === 'textbox'">
                        <textarea
                          aria-label=""
                          class="auto-resize"
                          @input="autoResize"
                          :key="getKey(soapType.id, index, soapIndex)"
                          :ref="getKey(soapType.id, index, soapIndex)"
                          v-model="soap.value"
                        ></textarea></template
                      ><template v-if="soap.type === 'slider'"
                        ><input
                          type="range"
                          :min="soap.min"
                          :max="soap.max"
                          :step="soap.step"
                          :key="getKey(soapType.id, index, soapIndex)"
                          v-bind:value="parseInt(soap.value)"
                          v-on:input="soap.value = $event.target.value"
                        />{{ soap.value }} ({{ $t("label.min") }}:
                        {{ soap.min }}, {{ $t("label.max") }}:
                        {{ soap.max }})</template
                      ><template v-if="soap.type === 'text'">{{
                        soap.value
                      }}</template></template
                    >
                  </div>
                  <div class="remove" @click.prevent="removeItem(item)">
                    <div class="icon icon-trash"></div>
                    {{ $t("label.remove") }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>

          <!-- CptCodes section -->
          <div
            v-if="activeTab === 'insurance'"
            class="expandable-block-wrapper"
          >
            <div class="expandable-block">
              <div class="expandable-block-header">
                <div class="title">
                  {{ $tc("label.dxCode", 2) }}
                </div>
                <div
                  v-if="redoProcedure"
                  class="expandable-block-redo"
                  @click.stop.prevent="
                    dxCodeModel = [];
                    redoProcedure = false;
                  "
                  v-tooltip="$t('tooltip.redo')"
                ></div>
              </div>
              <div class="expandable-block-body expanded">
                <v-select
                  :getOptionLabel="codeOptionLabel"
                  key="dx_code"
                  label="code"
                  class="taggable"
                  :options="dxCodes"
                  v-model="dxCodeModel"
                  multiple
                >
                </v-select>
              </div>
            </div>
            <div class="expandable-block">
              <div class="expandable-block-header">
                <div class="title">
                  {{ $t("label.insurance") }}
                </div>
                <div
                  v-if="redoClaimType"
                  class="expandable-block-redo"
                  @click.stop.prevent="
                    claimType = null;
                    cptCodes = [];
                    redoClaimType = false;
                    redoCptCode = false;
                  "
                  v-tooltip="$t('tooltip.redo')"
                ></div>
              </div>
              <div class="expandable-block-body expanded">
                <div class="soap-type-chooser">
                  <div class="label">
                    {{ $t("label.claimType") }}
                  </div>
                  <div class="soap-type-chooser-item">
                    <v-select
                      key="claim_type"
                      placeholder="Select claim type..."
                      :options="claimTypes"
                      v-model="claimType"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="soap-type-chooser">
                  <div class="label">
                    {{ $tc("label.cptCode", 2) }}
                  </div>
                  <div class="soap-type-chooser-item">
                    <v-select
                      key="cpt_code"
                      label="code"
                      placeholder="Select code..."
                      :getOptionLabel="codeOptionLabel"
                      :options="cptCodes"
                      v-model="cptCodeSelectModel"
                      @update:modelValue="cptCodeSelect"
                    >
                    </v-select>
                  </div>
                  <div v-if="cptCodeModel.length" class="codes">
                    <div
                      class="code"
                      v-for="(cpt, index) in cptCodeModel"
                      :key="'cpt_' + index"
                    >
                      <span>{{ cpt.name }} - {{ cpt.code }}</span>
                      <div
                        class="remove"
                        @click.prevent="cptCodeSelectRemove(index)"
                      >
                        <div class="icon icon-trash"></div>
                        {{ $t("label.remove") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Prescriptions section -->
          <div
            v-if="activeTab === 'prescriptions'"
            class="expandable-block-wrapper"
          >
            <div class="expandable-block">
              <div class="expandable-block-header">
                <div class="title">
                  {{ $tc("label.product", 2) }}
                </div>
              </div>
              <div class="expandable-block-body expanded">
                <v-select
                  class="margin-bottom-20"
                  key="prescriptions"
                  :options="products"
                  placeholder="Select product..."
                  label="name"
                  @update:modelValue="addPrescription"
                ></v-select>
                <div class="payment-tab-single-items">
                  <prescription-product
                    v-for="(item, index) in prescriptions"
                    :key="'prescription_' + item.id"
                    :item="item"
                    @update-quantity="updatePrescription($event, index)"
                    @remove="removePrescription(index)"
                  ></prescription-product>
                </div>
              </div>
            </div>
          </div>

          <div class="soap-type-add-condition" v-if="soapType">
            <div class="button action" @click.prevent="addNewCondition">
              {{ $t("button.addNewCondition") }}
              <div class="icon icon-add-big"></div>
            </div>
          </div>
          <div
            class="soap-empty"
            v-if="
              !(soapModel.length || cptCodeModel.length || dxCodeModel.length)
            "
          >
            <div class="soap-empty-image"></div>
            <div class="soap-empty-title">{{ $t("label.noSoapNotes") }}</div>
            <div class="soap-empty-description">
              {{ $t("message.soapNotesEmpty") }}
            </div>
          </div>
          <div
            class="soap-submit"
            v-if="soapModel.length || cptCodeModel.length || dxCodeModel.length"
          >
            <div class="button action" @click="submitHandler()">
              {{ $t("button.saveDraft") }}
            </div>
            <div
              class="button action"
              v-if="soapModel.length"
              @click="submitHandler('sign')"
            >
              {{ $t("button.sign") }}
            </div>
          </div>
        </template>
        <div v-else class="loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */

import { HTTP } from "@/services/api";
import autoResize from "@/mixins/autoResize";
import soapParser from "@/mixins/soapParser";
import fileMixin from "@/mixins/fileMixin";
import selectMixin from "@/mixins/selectMixin";
import draggable from "vuedraggable";
import axios from "axios";
import PrescriptionProduct from "@/views/private/components/soapNotes/PrescriptionProduct";
import { CLAIM_TYPES_OPTIONS } from "@/store";
export default {
  name: "SoapNotesCreate",
  mixins: [autoResize, soapParser, fileMixin, selectMixin],
  components: { draggable, PrescriptionProduct },
  data() {
    return {
      activeTab: null,
      soapTypes: [],
      soapModel: [],
      cptCodeModel: [],
      cptCodeSelectModel: null,
      dxCodeModel: [],
      prescriptions: [],
      redoCptCode: false,
      redoProcedure: false,
      redoClaimType: false,
      cptCodes: null,
      claimType: null,
      claimTypes: Object.values(CLAIM_TYPES_OPTIONS),
      dxCodes: null,
      lastSoap: [],
      action: "draft",
      eventData: null,
      promises: [],
      products: [],
      soapType: {},
      loading: false,
    };
  },
  created() {
    this.promises.push(this.loadSoapTypes());
    this.promises.push(this.loadEvent());
    this.promises.push(this.loadCptCodes());
    this.promises.push(this.loadProducts());
    this.promises.push(this.loadDxCodes());
    let storeKey = "soap_" + this.$route.params.id;
    this.soapModel =
      typeof this.$store.state.soapNotes[storeKey] !== "undefined"
        ? this.$store.state.soapNotes[storeKey]
        : [];
  },
  mounted() {
    let scope = this;
    scope.loading = true;
    axios.all(this.promises).then(function () {
      scope.loadLastSoapNote();
      scope.loading = false;
    });
  },
  watch: {
    soapModel: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal && oldVal.length !== 0) {
          let cloneVal = JSON.parse(JSON.stringify(newVal));
          cloneVal.forEach(function (item) {
            if (item.object && item.object.soap && item.object.soap.length) {
              item.object.soap.forEach(function (soapObjectItem) {
                if (soapObjectItem.type === "image-input") {
                  soapObjectItem.value = null;
                }
              });
            }
          });
          this.asDebounce(this.setStoreVal(cloneVal), 1000);
        }
        if (newVal.length === 0) {
          this.removeStoreVal();
        }
        this.$forceUpdate();
      },
    },
  },
  methods: {
    setStoreVal(val) {
      this.$store.dispatch("setSoapNote", {
        id: this.$route.params.id,
        value: val,
      });
    },
    countByType(soap) {
      return this.soapModel.filter(function (item) {
        return item.soap_note_type.id === soap.id;
      }).length;
    },
    removeStoreVal() {
      this.$store.dispatch("removeSoapNote");
    },
    cptCodeSelect(value) {
      this.cptCodeSelectModel = null;
      this.cptCodeModel.push(value);
    },
    cptCodeSelectRemove(index) {
      this.cptCodeModel.splice(index, 1);
    },
    addNewCondition() {
      let item = {
        soap_note_type: {
          id: this.soapType.id,
        },
        event_id: this.$route.params.id,
        title: null,
        text: null,
        expanded: false,
        object: null,
        valid: true,
        timestamp: Date.now(),
      };
      let self = this;
      let lastIndex = this.soapModel
        .slice()
        .reverse()
        .findIndex((item) => item.soap_note_type.id === self.soapType.id);
      let count = this.soapModel.length - 1;
      lastIndex = lastIndex >= 0 ? count - lastIndex : lastIndex;
      if (lastIndex === -1) {
        this.soapModel.push(item);
      } else {
        this.soapModel.splice(lastIndex + 1, 0, item);
      }
      this.setItemExpanded(item);
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
    removeItem(item) {
      let index = this.soapModel.findIndex(function (soap) {
        return (
          soap.soap_note_type.id === item.soap_note_type.id &&
          soap.title === item.title &&
          soap.timestamp === item.timestamp
        );
      });

      if (index > -1) {
        this.soapModel.splice(index, 1);
      }
    },
    getKey(id, index, sIndex) {
      return "soap_" + id + "_" + index + "_" + sIndex;
    },
    removeImage(soap) {
      soap.value = null;
      soap.preview = null;
    },
    getImageValue(soap) {
      if (
        typeof soap.preview !== "undefined" &&
        soap.preview &&
        soap.preview.length
      ) {
        return soap.preview;
      }
      if (typeof soap.value === "string") {
        soap["preview"] = process.env.VUE_APP_API + "/storage/" + soap.value;
      } else if (typeof soap.value === "object") {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            soap["preview"] = reader.result;
          },
          false
        );

        if (soap.value.file) {
          reader.readAsDataURL(soap.value.file);
        }
      }

      return soap.preview;
    },
    redo(item, index) {
      if (!item.expanded) {
        this.setItemExpanded(item);
      }
      item["id"] = null;
      this.setSoapItemValue(item, index);
    },
    setItemExpanded(item) {
      let wasExpanded = item.expanded;
      this.soapModel.forEach(function (soap) {
        soap.expanded = false;
      });
      item["expanded"] = !wasExpanded;
      this.fixTextareaHeight();
    },
    setCurrentTab(type, soap = false) {
      this.activeTab = soap ? type.name : type;
      this.soapType = soap ? type : null;
    },
    loadEvent() {
      return HTTP({
        method: "GET",
        url: "/api/v1/events/" + this.$route.params.id,
      })
        .then((response) => {
          this.eventData = response.data;
        })
        .catch(() => {
          //
        });
    },
    loadCptCodes() {
      return HTTP({
        method: "GET",
        url: "/api/v1/cpt-codes/",
        params: {
          sort_by: ["name|asc", "code|asc", "price|asc", "created_at|asc"],
        },
      })
        .then((response) => {
          this.cptCodes = response.data;
        })
        .catch(() => {});
    },
    loadDxCodes() {
      return HTTP({
        method: "GET",
        url: "/api/v1/dx-codes/",
      })
        .then((response) => {
          this.dxCodes = response.data;
        })
        .catch(() => {
          //
        });
    },
    loadProducts() {
      return HTTP({
        method: "GET",
        url: "/api/v1/inventory/products",
        params: {},
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.products = response.data.data;
          }
        })
        .catch(() => {});
    },
    loadLastSoapNote() {
      let storeKey = "soap_" + this.$route.params.id;
      if (typeof this.$store.state.soapNotes[storeKey] === "undefined") {
        return HTTP({
          method: "GET",
          url: "/api/v1/patients/" + this.eventData.patient.id + "/last",
        })
          .then((response) => {
            if (response.data && response.data.items) {
              this.soapModel = response.data.items;
              this.cptCodeModel = response.data.cpt_codes;
              this.dxCodeModel = response.data.dx_codes;
              this.redoClaimType = response.data.claim_type;
              this.redoCptCode = !!this.cptCodeModel.length;
              this.redoProcedure = !!this.dxCodeModel.length;
              if (response.data.claim_type) {
                this.claimType = this.claimTypes.find(function (item) {
                  return item.value === response.data.claim_type;
                });
              }

              let self = this;
              this.soapModel.forEach(function (item) {
                item.object = item.soap_note_type.predefined_text.find(
                  function (text) {
                    return text.key === item.soap_note_type_subkey;
                  }
                );
                if (!item.object) {
                  item.object = {};
                }
                item.object["soap"] = self.parseSoap(item.template);
                item["valid"] = true;
                item["expanded"] = false;
              });
            }
          })
          .catch(() => {});
      } else {
        return null;
      }
    },
    loadSoapTypes() {
      return HTTP({
        method: "GET",
        url: "/api/v1/soap-note-types",
      })
        .then((response) => {
          this.soapTypes = response.data;
          this.setCurrentTab(response.data[0], true);
        })
        .catch(() => {});
    },
    addPrescription(product) {
      if (product === null) {
        return;
      }

      let index = this.prescriptions.findIndex(function (item) {
        return item.id === product.id;
      });

      if (index === -1) {
        this.prescriptions.push({
          id: product.id,
          quantity: 1,
          object: product,
        });
      }
    },
    updatePrescription(item, index) {
      this.prescriptions[index] = item;
    },
    removePrescription(index) {
      this.prescriptions.splice(index, 1);
    },
    prepareSoap(soapModel) {
      let self = this;
      let preparedSoap = null;
      preparedSoap = soapModel.filter(function (item) {
        return item.object;
      });
      preparedSoap = preparedSoap.map(function (soap) {
        let helpArray = [];
        helpArray["title"] = soap.object.title;
        helpArray["label_result"] = soap.object.label_result;
        helpArray["soap_note_type_subkey"] = soap.object.key;
        helpArray["soap_note_type"] = [];
        helpArray["soap_note_type"]["id"] = soap.soap_note_type
          ? soap.soap_note_type.id
          : null;
        helpArray["template"] = self.prepareSoapTemplate(soap.object);
        helpArray["text"] = self.prepareSoapText(soap.object);
        helpArray["file"] = self.prepareSoapFile(soap.object, true);

        return helpArray;
      });
      return preparedSoap;
    },
    soapValid() {
      let valid = true;
      let scope = this;
      this.soapModel.forEach(function (soap) {
        if (typeof soap.object !== "undefined" && soap.object) {
          let text = scope.prepareSoapText(soap.object);
          if (text.trim() === "") {
            soap["valid"] = false;
            valid = false;
          } else {
            soap["valid"] = true;
          }
        } else {
          soap["valid"] = false;
          valid = false;
        }
      });
      valid = valid && this.soapModel.length > 0;
      return (
        valid ||
        (this.soapModel.length === 0 &&
          (this.dxCodeModel.length > 0 || this.cptCodeModel.length > 0))
      );
    },
    submitHandler(action = "draft") {
      if (action === "sign" && !this.claimType) {
        this.$notify({
          group: "notification",
          type: "error",
          title: this.$t("notification.sadFace"),
          text: this.$t("notification.claimTypeRequired"),
        });
        return;
      }

      if (action === "sign" && this.cptCodeModel.length === 0) {
        this.$notify({
          group: "notification",
          type: "error",
          title: this.$t("notification.sadFace"),
          text: this.$t("notification.soapCptCodesRequired"),
        });
        return;
      }

      if (
        action === "sign" &&
        this.claimType &&
        this.claimType.value !==
          CLAIM_TYPES_OPTIONS.CLAIM_TYPE_SELF_PAY.value &&
        this.dxCodeModel.length === 0
      ) {
        this.$notify({
          group: "notification",
          type: "error",
          title: this.$t("notification.sadFace"),
          text: this.$t("notification.soapDXCodesRequired"),
        });
        return;
      }

      if (this.soapValid() === true) {
        this.action = action;
        this.$notify({
          group: "prompt-calendar",
          duration: -1,
          closeOnClick: false,
          title:
            this.action === "sign"
              ? this.$t("notification.signSoap")
              : this.$t("notification.saveSoap"),
          text: this.$t("notification.proceed"),
          data: {
            onCancel: true,
            onConfirm: this.submit,
          },
        });
        return;
      }

      this.$notify({
        group: "notification",
        type: "error",
        title: this.$t("notification.sadFace"),
        text: this.$t("notification.soapEmpty"),
      });
    },
    submit() {
      let filteredValues = this.prepareSoap(this.soapModel);
      const data = [];
      data["cpt_code"] = [];
      data["cpt_code"]["id"] = this.cptCodeModel.map(function (item) {
        return item.id;
      });
      data["dx_code"] = [];
      data["dx_code"]["id"] = this.dxCodeModel.map(function (item) {
        return item.id;
      });
      data["prescriptions"] = [];
      this.prescriptions.forEach(function (item, index) {
        data["prescriptions"][index] = [];
        data["prescriptions"][index]["id"] = item.id;
        data["prescriptions"][index]["quantity"] = item.quantity;
      });
      data["event"] = [];
      data["event"]["id"] = this.eventData.id;
      if (this.action === "sign") {
        data["sign"] = 1;
      }
      data["doctor"] = [];
      data["doctor"]["id"] =
        this.$store.state.userType === "doctor"
          ? this.$store.state.user.id
          : this.eventData.doctor.id;
      data["doctor_first_name"] =
        this.$store.state.userType === "doctor"
          ? this.$store.state.user.first_name
          : this.eventData.doctor.first_name;

      data["doctor_last_name"] =
        this.$store.state.userType === "doctor"
          ? this.$store.state.user.last_name
          : this.eventData.doctor.last_name;

      data["claim_type"] = this.claimType ? this.claimType.value : null;

      data["items"] = filteredValues;
      const formData = this.generateFormData(data);
      let self = this;
      return HTTP({
        method: "POST",
        url: "/api/v1/soap-notes/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          self.$notify({
            group: "notification",
            type: "success",
            title: self.$t("notification.thumbsUp"),
            text: self.$t("notification.soapCreated"),
          });
          self.removeStoreVal();
          self.$router.go(-1);
        })
        .catch(() => {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.soapNotCreated"),
          });
        });
    },
  },
};
</script>
