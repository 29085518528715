<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") + " " + $tc("label.patient", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <!--          files section-->
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="patients"
          :sortArray="[
            {
              label: 'Name',
              key: 'first_name',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- patients-->
        <div class="list-items-section-wrapper">
          <div
            class="list-items-section-item"
            v-for="patient in patients"
            :key="'patient_' + patient.id"
            :class="{
              'list-items-section-item': true,
              disabled: isInactivePatient(patient),
            }"
          >
            <div class="icon icon-patient"></div>
            <div class="name">
              {{ patient.first_name + " " + patient.last_name }}
            </div>
            <patient-options-menu :patient="patient"></patient-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.patients.pagination.page"
        :perPage="$store.state.patients.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.patients.pagination.page"
        :perPage="$store.state.patients.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import fileMixin from "@/mixins/fileMixin";
import PatientOptionsMenu from "./PatientOptionsMenu";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import { default as EventBus } from "@/services/eventbus";

export default {
  name: "PatientsView",
  props: ["path"],
  mixins: [fileMixin],
  components: {
    HeaderSort,
    PatientOptionsMenu,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      files: [],
      patients: [],
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadPatients();
  },
  created() {
    EventBus.$on("patients-load", () => {
      this.loadPatients();
    });
  },
  unmounted() {
    cancelTokens(this.cancelToken);
    EventBus.$off("patients-load");
  },
  watch: {
    "$store.state.patients": {
      deep: true,
      handler: function () {
        this.loadPatients();
      },
    },
  },
  methods: {
    loadPatients() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        params: {
          q: this.$store.state.patients.search,
          sort_by: ["last_name|asc"],
          page: this.$store.state.patients.pagination.page,
          per_page: this.$store.state.patients.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            const newArray = [];
            const inactivePatients = [];
            response.data.data.forEach((item) => {
              if (item.status === "inactive") {
                return inactivePatients.push(item);
              }
              return newArray.push(item);
            });
            this.patients = newArray.concat(inactivePatients);
            // TODO refactor meta and pagination
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "patients",
        data: value,
      });
    },
    handleCreateButton() {
      this.$router.push({
        name: "patientAdd",
      });
    },
  },
};
</script>
