<template>
  <div class="payment-tab-single-item">
    <div class="payment-tab-single-item-top">
      <div class="payment-tab-single-item-top-info">
        <div class="payment-tab-single-item-top-info-item date">
          {{ $t("label.date") }}:
          <span class="value">{{
            customFormat(item.item_data.start, "MM / dd / yyyy")
          }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.item_data.doctor"
        >
          {{ $tc("label.provider", 1) }}:
          <span class="value">{{
            item.item_data.doctor.first_name +
            " " +
            item.item_data.doctor.last_name
          }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.item_data.room"
        >
          {{ $tc("label.room", 1) }}:
          <span class="value">{{ item.item_data.room.name }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.item_data.treatment"
        >
          {{ $tc("label.treatment", 1) }}:
          <span class="value">{{ item.item_data.treatment.name }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.price") }}:
          <span class="value">{{ formatPrice(item.price) }}</span>
        </div>
        <template v-if="item.item_data.soap_note">
          <payment-claim-label :soap-note="item.item_data.soap_note" />
        </template>
      </div>
      <div class="payment-tab-single-item-top-action">
        <div
          :class="{
            button: true,
            action: true,
            disabled: isInactivePatient(patient),
          }"
          @click.prevent="addToCheckout(item)"
          v-if="!checkoutMode"
        >
          {{ $t("button.addToCheckout") }}
          <div
            :class="{
              icon: true,
              'icon-add-big': true,
              disabled: isInactivePatient(patient),
            }"
            v-if="!loading"
          ></div>
          <div
            :class="{
              icon: true,
              'icon-add-big': true,
              loader: true,
              disabled: isInactivePatient(patient),
            }"
            v-else
          ></div>
        </div>
        <div v-else>{{ $tc("label.appointment", 1).toUpperCase() }}</div>
        <div
          class="payment-tab-single-item-top-info-item error"
          v-if="!item.item_data.soap_note"
        >
          {{ $t("message.noSignedSoapNote") }}
        </div>
      </div>
    </div>
    <div
      class="payment-tab-single-item-top justify-end"
      v-if="item.item_data.soap_note"
    >
      <payment-event-codes :soap-note="item.item_data.soap_note" />
    </div>
    <div class="payment-tab-single-item-bottom total">
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.totalPrice") + ": " }} {{ formatPrice(item.price) }}
      </div>
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.coveredByPatient") + ": " }}
        {{ formatPrice(totalPatient) }}
      </div>
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.coveredByInsurance") + ": " }}
        {{ formatPrice(totalInsurance) }}
      </div>
    </div>
    <div v-if="checkoutMode" class="payment-tab-single-item-bottom-remove">
      <div class="remove" @click.prevent="removeItem(item)">
        <div class="icon icon-trash" v-if="!loading"></div>
        <div class="icon icon-trash loader" v-else></div>
        {{ $t("label.remove") }}
      </div>
    </div>
  </div>
</template>

<script>
import PaymentClaimLabel from "@/views/private/components/payments/PaymentClaimLabel";
import PaymentEventCodes from "@/views/private/components/payments/PaymentEventCodes";
import { CLAIM_TYPES_OPTIONS } from "@/store";
export default {
  name: "PaymentsSingleEvent",
  components: { PaymentEventCodes, PaymentClaimLabel },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {};
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    checkoutMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      claimTypes: CLAIM_TYPES_OPTIONS,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  computed: {
    totalPatient: function () {
      let total = 0;
      let self = this;
      if (
        this.item.item_data.soap_note &&
        this.item.item_data.soap_note.cpt_codes
      ) {
        this.item.item_data.soap_note.cpt_codes.forEach(function (item) {
          if (
            self.item.item_data.soap_note.claim_type ===
            self.claimTypes.CLAIM_TYPE_SELF_PAY.value
          ) {
            total += parseFloat(item.self_pay_price);
          }

          if (
            self.item.item_data.soap_note.claim_type !==
              self.claimTypes.CLAIM_TYPE_SELF_PAY.value &&
            !item.include_in_insurance_claim
          ) {
            total += parseFloat(item.price);
          }
        });
      }
      return total;
    },
    totalInsurance: function () {
      let total = 0;
      let self = this;
      if (
        this.item.item_data.soap_note &&
        this.item.item_data.soap_note.cpt_codes
      ) {
        this.item.item_data.soap_note.cpt_codes.forEach(function (item) {
          if (
            self.item.item_data.soap_note.claim_type !==
              self.claimTypes.CLAIM_TYPE_SELF_PAY.value &&
            item.include_in_insurance_claim
          ) {
            total += parseFloat(item.price);
          }
        });
      }
      return total;
    },
  },
  methods: {
    addToCheckout(item) {
      if (this.isInactivePatient(this.patient)) {
        return;
      }
      this.loading = true;
      this.$emit("add-to-checkout", item);
    },
    removeItem(item) {
      if (this.isInactivePatient(this.patient)) {
        return;
      }
      this.loading = true;
      this.$emit("remove-from-checkout", item);
    },
  },
};
</script>
