<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") }} {{ $tc("label.user", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <!--          files section-->
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="users"
          :sortArray="[
            {
              label: 'Name',
              direction: 'asc',
              key: 'first_name',
            },
            {
              label: 'Email',
              direction: 'asc',
              key: 'email',
              width: '160px',
            },
            {
              label: 'Clinic',
              width: '120px',
            },
            {
              label: 'Role',
              width: '148px',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- users-->
        <div class="list-items-section-wrapper users">
          <div
            class="list-items-section-item"
            v-for="user in users"
            :key="'user_' + user.id"
          >
            <div class="value name">
              {{ user.first_name + " " + user.last_name }}
            </div>
            <div class="value email">
              {{ user.email }}
            </div>
            <div class="value clinic">
              {{ user.clinic ? user.clinic.name : "" }}
            </div>
            <div class="value role">
              {{ user.role ? user.role.name : "" }}
            </div>
            <user-options-menu :user="user"></user-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.users.pagination.page"
        :perPage="$store.state.users.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.users.pagination.page"
        :perPage="$store.state.users.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import UserOptionsMenu from "@/views/private/components/settings/admin/UserOptionsMenu";

export default {
  name: "UsersView",
  props: ["path"],
  components: {
    UserOptionsMenu,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      files: [],
      users: [],
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadUsers();
  },
  created() {
    EventBus.$on("users-load", () => {
      this.loadUsers();
    });
  },
  unmounted() {
    EventBus.$off("users-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.users": {
      deep: true,
      handler: function () {
        this.loadUsers();
      },
    },
  },
  methods: {
    loadUsers() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/users",
        data: {},
        params: {
          q: this.$store.state.users.search,
          sort_by: this.$store.state.users.sortOrder,
          page: this.$store.state.users.pagination.page,
          per_page: this.$store.state.users.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.users = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", { type: "users", data: value });
    },
    handleCreateButton() {
      this.$router.push({
        name: "adminUserAdd",
      });
    },
  },
};
</script>
