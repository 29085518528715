<template>
  <div class="payment-tab-single">
    <template v-if="!loading">
      <div class="payment-tab-single-item">
        <v-select
          :options="plans"
          :placeholder="
            $t('label.select') +
            ' ' +
            $tc('label.plan', 1).toLowerCase() +
            '...'
          "
          label="name"
          v-model="plan"
          @update:modelValue="selectPlan"
          :disabled="inactivePatient"
        ></v-select>
      </div>
      <div
        class="payment-tab-single-items"
        v-for="item in filteredPlans"
        :key="'payments_' + item.id"
      >
        <payments-single-plan
          :item="item"
          :patient="patient"
          @add-to-checkout="(item) => this.addToCheckout(item)"
        ></payments-single-plan>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import PaymentsSinglePlan from "@/views/private/components/payments/PaymentsSinglePlan";

export default {
  name: "PaymentTabPlans",
  components: { PaymentsSinglePlan },
  props: {
    cartItems: {
      type: Array,
      default() {
        return [];
      },
    },
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inactivePatient: {
      type: Boolean,
      default: false,
    },
    addToCheckout: {
      type: Function,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      plan: null,
      plans: [],
      plansLoaded: false,
      cancelToken: {
        plansToken: null,
      },
    };
  },
  computed: {
    filteredPlans() {
      return this.cartItems.filter(function (item) {
        return item.item_type === "Plan" && item.status === "active";
      });
    },
  },
  activated() {
    if (!this.plansLoaded) {
      this.loadPlans();
    }
  },
  deactivated() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadPlans() {
      setCancelToken(this.cancelToken, "plansToken");

      return HTTP({
        method: "GET",
        url: "/api/v1/plans",
        cancelToken: this.cancelToken.plansToken.token,
        params: {},
      })
        .then((response) => {
          if (response.data) {
            this.plans = response.data;
            this.plansLoaded = true;
          }
        })
        .catch(() => {});
    },
    selectPlan(value) {
      if (!this.patient) {
        return;
      }

      this.plan = null;
      this.$emit("loading", true);
      HTTP({
        method: "POST",
        url: "/api/v1/purchases/patients/" + this.patient.id + "/cart-items",
        data: {
          item_type: "Plan",
          status: "active",
          quantity: 1,
          price: value.price,
          item_data: value,
        },
      })
        .then(() => {
          this.$emit("loading", false);
          this.$emit("load-cart-items", false);
        })
        .catch(() => {
          this.$emit("loading", false);
        });
    },
  },
};
</script>
