<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <search mode="documents"></search>

      <!-- favorites-->
      <drop class="sidebar-inner-block" @drop="handleFolderDrop">
        <div
          class="sidebar-inner-block-title"
          @click="resetPath"
          ref="filesShortcuts"
        >
          {{ $t("label.allFiles") }}
        </div>
        <div
          class="sidebar-inner-block-items"
          v-if="favorites.length"
          ref="filesShortcuts"
        >
          <div
            @click="setPath(favorite)"
            class="sidebar-inner-block-items-item folder"
            v-for="favorite in favorites"
            :key="favorite.id"
          >
            <div class="icon"></div>
            <div class="name">{{ favorite.name }}</div>
          </div>
        </div>
      </drop>

      <!-- campaigns-->
      <div v-if="campaigns.length" class="sidebar-inner-block">
        <div class="sidebar-inner-block-title">Campaigns</div>
        <div class="sidebar-inner-block-items" ref="filesCampaigns">
          <drop
            class="sidebar-inner-block-items-item campaign"
            :class="{ dropping: campaign.dropping }"
            @click="setCampaign(campaign)"
            @dragover="campaign['dropping'] = true"
            @dragleave="campaign['dropping'] = false"
            @drop="
              (transferData) => {
                handleFileDrop(campaign, transferData);
              }
            "
            v-for="campaign in campaigns"
            :key="campaign.id"
          >
            <campaign-item-icon :id="campaign.id"></campaign-item-icon>
            <div class="name">{{ campaign.name }}</div>
          </drop>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as EventBus } from "@/services/eventbus";
import axios from "axios";
import Search from "@/views/private/components/Search";
import CampaignItemIcon from "./CampaignItemIcon";
import { HTTP, setCancelToken, cancelTokens } from "@/services/api";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "DocumentsSidebar",
  mixins: [onboardTooltipMixin],
  components: { CampaignItemIcon, Search },
  data: () => ({
    favorites: [],
    campaigns: [],
    promises: [],
    isDropping: [],
    cancelToken: {
      favoritesLoad: null,
      campaignOptionsLoad: null,
    },
  }),
  created() {
    EventBus.$on("favorites-load", () => {
      this.loadFavorites();
    });
  },
  unmounted() {
    EventBus.$off("favorites-load");
    cancelTokens(this.cancelToken);
  },
  mounted() {
    this.promises.push(this.loadFavorites());
    this.promises.push(this.loadCampaignOptions());
    axios.all(this.promises);
  },
  methods: {
    loadFavorites() {
      setCancelToken(this.cancelToken, "favoritesLoad");
      return HTTP({
        method: "GET",
        url: "/api/v1/shortcuts",
        cancelToken: this.cancelToken.favoritesLoad.token,
        params: {},
      }).then((response) => {
        if (response.data) {
          this.favorites = response.data;
        }
      });
    },
    loadCampaignOptions() {
      setCancelToken(this.cancelToken, "campaignOptionsLoad");
      return HTTP({
        method: "GET",
        url: "/api/v1/campaigns",
        cancelToken: this.cancelToken.campaignOptionsLoad.token,
        params: {},
      }).then((response) => {
        if (response.data) {
          this.campaigns = response.data;
          this.$store.dispatch("setDocumentCampaigns", this.campaigns);
        }
      });
    },
    resetPath() {
      this.$store.dispatch("setDocumentPath", "/");
    },
    setPath(favorite) {
      this.$store.dispatch("setDocumentPath", favorite.path);
    },
    setCampaign(campaign) {
      this.$store.dispatch("setSelectedCampaign", campaign);
    },
    handleFileDrop(campaign, transferData) {
      campaign["dropping"] = false;
      if (typeof transferData.file === "undefined") {
        return;
      }

      let exist = transferData.file.campaigns.some(function (item) {
        return item.id === campaign.id;
      });

      if (exist) {
        this.$notify({
          group: "notification",
          type: "success",
          title: this.$t("notification.thumbsUp"),
          text: this.$t("notification.campaignUpdate"),
        });
        return;
      }

      const data = [];
      data["campaign"] = [];
      data["campaign"]["id"] = [];
      transferData.file.campaigns.forEach(function (item) {
        data["campaign"]["id"].push(item.id);
      });
      data["campaign"]["id"].push(campaign.id);

      const formData = this.generateFormData(data);

      HTTP({
        method: "POST",
        url: "/api/v1/files/" + transferData.file.id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.campaignUpdate"),
          });
          EventBus.$emit("documents-load");
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.campaignNotUpdate"),
          });
        });
    },
    handleFolderDrop(transferData) {
      if (typeof transferData.folder === "undefined") {
        return;
      }

      if (transferData.folder.shortcut) {
        return;
      }

      HTTP({
        method: "POST",
        url: "/api/v1/shortcuts/",
        data: {
          file: {
            id: transferData.folder.id,
          },
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.shortcutCreated"),
          });
          EventBus.$emit("favorites-load");
          EventBus.$emit("documents-load");
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.shortcutNotCreated"),
          });
        });
    },
  },
};
</script>
