<template>
  <div class="modal insurance-modal" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>{{ $t("label.updateAdjustment") }}</div>
                <div class="icon-close-round" @click.prevent="closeModal"></div>
              </div>
            </div>
          </div>

          <div class="modal-dialog-main-section border-bottom">
            <div class="modal-dialog-main-form">
              <div class="form-item double">
                <div class="form-item">
                  <label class="item">{{ $t("label.adjustment") }}</label>
                  <input
                    type="number"
                    step="0.01"
                    v-model="adjustment"
                    :placeholder="$t('label.adjustment')"
                  />
                </div>
                <div class="form-item">
                  <label class="item">{{
                    $t("label.updatedAdjustment")
                  }}</label>
                  <input type="number" :value="updatedAdjustment" disabled />
                </div>
              </div>
              <div class="form-item">
                <label class="item">{{ $t("label.description") }}</label>
                <input v-model="description" type="text" />
              </div>
              <div class="form-item action">
                <a class="button action cancel" @click.prevent="closeModal">
                  {{ $t("button.cancel") }}
                </a>
                <a
                  class="button action"
                  :class="{ disabled: saving }"
                  @click.prevent="save()"
                  >{{ $t("button.save") }}</a
                >
              </div>
            </div>
          </div>
          <div class="modal-dialog-main-section">
            <div
              v-for="(item, index) in history"
              :key="'history_' + index"
              class="update-adjustment"
            >
              <div
                class="price"
                :class="{
                  positive: item.value > 0,
                  negative: item.value < 0,
                }"
              >
                {{ formatPrice(item.value) }}
              </div>
              <div class="date">
                {{ formatDateTime(item.created_at) }}
              </div>
              <div class="remove" @click.prevent="removeHistoryItem(item)">
                <div class="icon icon-trash" v-if="removing !== item.id"></div>
                <div
                  class="icon icon-trash loader"
                  v-else-if="removing === item.id"
                ></div>
                {{ $t("label.remove") }}
              </div>
            </div>
            <div
              v-if="history.length === 0 && !historyLoading"
              class="update-adjustment"
            >
              {{ $t("message.noPreviousAdjustments") }}
            </div>
            <div v-if="historyLoading" class="update-adjustment">
              {{ $t("message.loading") }}...
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";

export default {
  name: "InsuranceLedgerAdjustments",
  props: ["item", "insuranceWalletId", "callback"],
  data() {
    return {
      adjustment: 0,
      description: null,
      initialAdjustment: 0,
      cancelToken: { history: null, item: null },
      history: [],
      historyLoading: false,
      removing: null,
      saving: false,
    };
  },
  created() {
    this.initialAdjustment =
      this.item && this.item.adjustment ? parseFloat(this.item.adjustment) : 0;
    this.loadAdjustmentHistory();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  beforeUnmount() {
    if (this.callback) {
      this.callback(this.initialAdjustment);
    }
  },
  computed: {
    updatedAdjustment: function () {
      return Math.round((this.initialAdjustment + this.adjustment) * 100) / 100;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      setCancelToken(this.cancelToken, "item");

      HTTP({
        method: "POST",
        url:
          "/api/v1/purchases/insurance-ledgers/" +
          this.item.id +
          "/adjustment-history",
        cancelToken: this.cancelToken.item.token,
        data: {
          value: Math.round(this.adjustment * 100) / 100,
          insurance_wallet: {
            id: this.insuranceWalletId,
          },
          description: this.description,
        },
      })
        .then((response) => {
          this.saving = false;
          if (response.data.data) {
            this.history.unshift(response.data.data);
            this.initialAdjustment = this.initialAdjustment + this.adjustment;
            this.adjustment = 0;
          }
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.successSave"),
          });
        })
        .catch((error) => {
          this.saving = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.formatError(error, this.$t("notification.errorSave")),
          });
        });
    },
    removeHistoryItem(item) {
      if (this.removing) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.pleaseWaitForOtherAction"),
        });
        return;
      }

      this.removing = item.id;

      HTTP({
        method: "DELETE",
        url:
          "/api/v1/purchases/insurance-ledgers/" +
          this.item.id +
          "/adjustment-history/" +
          item.id,
      })
        .then(() => {
          this.removing = null;

          let index = this.history.findIndex((historyItem) => {
            return historyItem.id === item.id;
          });

          if (index > -1) {
            this.initialAdjustment = this.initialAdjustment - item.value;
            this.history.splice(index, 1);
          }
        })
        .catch((error) => {
          this.removing = null;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.formatError(error, this.$t("notification.errorDelete")),
          });
        });
    },
    loadAdjustmentHistory() {
      if (!this.item) {
        return;
      }

      this.historyLoading = true;
      setCancelToken(this.cancelToken, "history");

      HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/insurance-ledgers/" +
          this.item.id +
          "/adjustment-history",
        cancelToken: this.cancelToken.history.token,
        params: {
          sort_by: ["created_at|desc"],
        },
      })
        .then((response) => {
          this.historyLoading = false;
          if (response.data.data) {
            this.history = response.data.data;
          }
        })
        .catch(() => {
          this.historyLoading = false;
        });
    },
  },
};
</script>
