<template>
  <div
    :class="
      'calendar-main-inner-calendar-header ' + $store.state.currentView.type
    "
  >
    <template v-if="$store.state.currentView.type === 'week'">
      <span class="calendar-main-inner-calendar-header-label">{{
        heading.label.substring(0, 3)
      }}</span>
      <span class="calendar-main-inner-calendar-header-day">{{
        heading.date.format("D")
      }}</span>
    </template>
    <template v-else>
      {{ heading.label }}
    </template>
  </div>
</template>

<script>
export default {
  name: "CalendarSlotWeekdayHeading",
  props: ["heading"],
  data() {
    return {};
  },
  methods: {},
};
</script>
