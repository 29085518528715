<template>
  <div v-if="!loading">
    <filter-menu-doctors
      v-if="filters.doctors"
      :doctors="filters.doctors"
    ></filter-menu-doctors>

    <filter-menu-rooms
      v-if="filters.rooms"
      :rooms="filters.rooms"
    ></filter-menu-rooms>

    <filter-menu-treatments
      v-if="filters.treatments"
      :treatments="filters.treatments"
    ></filter-menu-treatments>

    <filter-menu-others
      v-if="filters.others"
      :others="filters.others"
    ></filter-menu-others>

    <filter-menu-statuses
      v-if="filters.statuses"
      :statuses="filters.statuses"
    ></filter-menu-statuses>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import FilterMenuDoctors from "@/views/private/components/FilterMenuDoctors";
import FilterMenuTreatments from "@/views/private/components/FilterMenuTreatments";
import FilterMenuRooms from "@/views/private/components/FilterMenuRooms";
import FilterMenuOthers from "@/views/private/components/FilterMenuOthers";
import FilterMenuStatuses from "@/views/private/components/FilterMenuStatuses";
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";

export default {
  name: "FilterMenu",
  components: {
    FilterMenuDoctors,
    FilterMenuTreatments,
    FilterMenuRooms,
    FilterMenuOthers,
    FilterMenuStatuses,
  },
  data() {
    return {
      filters: {},
      loading: false,
      cancelToken: { load: null },
    };
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  mounted() {
    this.loadFilters();
  },
  methods: {
    loadFilters() {
      this.$store.dispatch("setFilters", []);
      this.loading = true;
      setCancelToken(this.cancelToken, "load");

      HTTP({
        method: "GET",
        url: "/api/v1/filters",
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.filters = response.data[0] ? response.data[0] : [];
            this.$store.dispatch("setFilter", {
              statuses: true,
              values: ["pending", "checked-in", "scheduled", "checked-out"],
            });
            this.$store.dispatch("setFilters", this.filters);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
