<template>
  <div
    v-if="!successRegister"
    class="form patients-schedule patients-schedule-register"
  >
    <div class="patients-schedule-inner" v-show="!loading">
      <div class="patients-schedule-header">
        <div class="patients-schedule-header-image">
          <template v-if="clinicData">
            <img
              class="image"
              v-if="clinicData.logo"
              :src="clinicData.logo"
              :alt="$route.params.clinic_slug + ' logo'"
            />
            <div v-else class="placeholder"></div>
          </template>
        </div>
        <h1 class="patients-schedule-header-title">
          {{ $t("clinic.registerTitle") }}
        </h1>
        <span class="patients-schedule-header-text"
          >{{ $t("clinic.registerText") }}
        </span>
      </div>
      <form
        ref="loginForm"
        @submit.prevent="register()"
        :class="{
          'patients-schedule-content': true,
          disabled: formDisable,
        }"
      >
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.first_name'),
          }"
        >
          <label class="item"> {{ $t("label.firstName") }} </label>
          <input v-model="form.first_name" type="text" ref="firstName" />
        </div>
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.last_name'),
          }"
        >
          <label class="item">{{ $t("label.lastName") }} </label>
          <input v-model="form.last_name" type="text" ref="lastName" />
        </div>
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.email'),
          }"
        >
          <label class="item">{{ $t("label.enterEmail") }} </label>
          <input v-model="form.email" type="text" ref="email" />
        </div>
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.mobile_number'),
          }"
        >
          <label class="item">{{ $t("label.enterPhoneNumber") }} </label>
          <input
            :value="form.mobile_number"
            v-imask="masks.phone"
            @accept="form.mobile_number = parsePhoneValue($event)"
            type="text"
            ref="phone"
          />
        </div>
        <div class="patients-schedule-action">
          <button
            :class="{
              button: true,
              dark: true,
              height: true,
              disabled: formDisable,
            }"
            type="submit"
          >
            {{ $t("button.registerNow") }}
          </button>

          <span class="label">You already have an account?</span>
          <router-link
            :to="{
              name: 'patientLogin',
              params: { clinic_slug: $route.params.clinic_slug },
            }"
            class="button action height"
          >
            {{ $t("button.loginTogether") }}
          </router-link>
        </div>
      </form>
      <div v-show="loading">{{ $t("message.loading") }}...</div>
    </div>
  </div>
  <patient-verify-email
    v-if="successRegister"
    :clinic-data="clinicData"
    :email="form.email"
    :sent="true"
  />
</template>

<script>
import { HTTP_PUBLIC } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import PatientVerifyEmail from "@/views/public/pages/patient/PatientVerifyEmail.vue";
import imaskMixin from "@/mixins/imaskMixin";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientRegister",
  components: { PatientVerifyEmail },
  mixins: [imaskMixin],
  props: ["clinicData"],
  validators: {
    "form.first_name": function (value) {
      return Validator.value(value).required();
    },
    "form.last_name": function (value) {
      return Validator.value(value).required();
    },
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
    "form.mobile_number": function (value) {
      return Validator.value(value).length(11).required();
    },
  },
  data() {
    return {
      successRegister: false,
      loading: false,
      formDisable: false,
      form: {
        first_name: null,
        last_name: null,
        mobile_number: null,
        email: null,
      },
    };
  },
  mounted() {},
  methods: {
    register() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP_PUBLIC({
            method: "POST",
            url:
              "api/v1/clinics/" +
              self.$route.params.clinic_slug +
              "/patients/register",
            data: self.form,
          })
            .then(() => {
              self.successRegister = true;
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: error.message,
                text: error.details.join(" "),
              });
            });
          self.formDisable = false;
        } else {
          self.$notify({
            group: "notification",
            type: "warning",
            title: self.$t("notification.warning"),
            text: self.$t("notification.fillAllFields"),
          });
        }
      });
    },
  },
};
</script>
