<template>
  <div class="form patients-schedule patients-schedule-container">
    <div class="patients-schedule-inner" v-show="!loading">
      <div class="patients-schedule-inner-helper">
        <div
          :class="{
            'patients-schedule-header': true,
          }"
        >
          <div
            class="patients-schedule-header-back patients-schedule-header-action"
            @click="handleBack()"
          >
            <svg
              width="15"
              height="13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.414 11.458l-4.635-5.16 4.635-4.84"
                stroke="#EB5F41"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M13.837 7.244a1 1 0 100-2v2zm-11.95 0h11.95v-2H1.887v2z"
                fill="#EB5F41"
              />
            </svg>
          </div>
          <div class="patients-schedule-header-image">
            <template v-if="!clinicDataLoading">
              <img
                class="image"
                v-if="clinicData && clinicData.logo"
                :src="clinicData.logo"
                :alt="$route.params.clinic_slug + ' logo'"
              />
              <div v-else class="placeholder"></div>
            </template>
          </div>
          <div
            class="patients-schedule-header-user patients-schedule-header-action"
            @click="handleUser()"
          >
            <svg
              width="21"
              height="21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.917 17.958V16.29a3.333 3.333 0 00-3.334-3.333H6.917a3.333 3.333 0 00-3.334 3.333v1.667M10.25 9.625a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
                stroke="#EB5F41"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <h1 class="patients-schedule-header-title align-center">Thank you!</h1>
        <span class="patients-schedule-header-text align-center">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam
          dolor dolorum eaque est, exercitationem inventore iure labore
          laudantium minus molestiae quam quis!
        </span>
        <div v-show="loading">{{ $t("message.loading") }}...</div>
      </div>
    </div>
    <clinic-data
      @data-loaded="
        clinicData = $event;
        clinicDataLoading = false;
      "
    />
  </div>
</template>

<script>
import ClinicData from "@/views/public/pages/components/ClinicData";

export default {
  name: "patientThankYou",
  components: { ClinicData },
  data() {
    return {
      loading: false,
      clinicData: null,
      clinicDataLoading: true,
    };
  },
  methods: {
    handleBack() {
      return this.$router.push({ name: "patientSchedule" });
    },
    handleUser() {
      this.$router.push({
        name: "patientDetails",
        params: {
          id: this.$store.state.user.id,
        },
      });
    },
  },
};
</script>
