<template>
  <div class="row soap">
    <div class="col-lg-12 soap-patient">
      <div class="soap-patient-top">
        <div class="flex-space">
          <div class="soap-patient-top-breadcrumbs">
            <router-link :to="'/calendar/day/agenda'">
              {{ $t("button.agenda") }}
            </router-link>
            <div class="icon icon-arrow-right"></div>
            <span>
              {{ $t("button.soapNotes") }}
              <span v-if="patient">
                - {{ patient.first_name }} {{ patient.last_name }}
              </span>
            </span>
          </div>
        </div>
        <div class="soap-patient-top-filter">
          <div class="soap-patient-top-filter-header">
            <div class="title">
              <div class="icon icon-filter"></div>
              {{ $t("message.filterAllNotes") }}
            </div>
            <div
              class="toggle"
              :class="{ expanded: filterExpanded }"
              @click="filterExpanded = !filterExpanded"
            ></div>
          </div>
          <div
            class="soap-patient-top-filter-body"
            :class="{ expanded: filterExpanded }"
          >
            <div class="soap-patient-top-filter-body-item">
              <div class="label">{{ $t("label.fromDate") }}:</div>
              <div class="value">
                <template v-if="componentsShown">
                  <date-time-input
                    ref="startDate"
                    enable-date-calendar
                    input-mode
                    use-date
                    :original-start-date="fromDate"
                    :time-format24="this.$store.getters.userHoursFormat24"
                    @value-start-date="setStart"
                  ></date-time-input>
                </template>
              </div>
            </div>
            <div class="soap-patient-top-filter-body-item">
              <div class="label">{{ $t("label.toDate") }}:</div>
              <div class="value">
                <template v-if="componentsShown">
                  <date-time-input
                    ref="endDate"
                    enable-date-calendar
                    input-mode
                    use-date
                    :min-date="fromDate"
                    :original-start-date="toDate"
                    :time-format24="this.$store.getters.userHoursFormat24"
                    @value-start-date="setEnd"
                  ></date-time-input>
                </template>
              </div>
            </div>
            <div class="soap-patient-top-filter-body-item">
              <form-filter-provider
                :doctor="doctor"
                :load-on-create="true"
                @set-doctor="(value) => (this.doctor = value)"
              />
            </div>
            <div v-if="showAll" class="soap-patient-top-filter-body-item">
              <form-filter-patient
                :patient="patient"
                :load-on-create="true"
                @set-patient="(value) => (this.patient = value)"
              />
            </div>
            <div class="soap-patient-top-filter-body-actions">
              <div class="reset" @click="resetFilter">
                {{ $t("button.resetFilter") }}
              </div>
              <div
                class="button action"
                :class="{ disabled: loading }"
                @click="doFilter"
              >
                {{ $t("button.filterNotes") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 soap-patient bottom">
      <div class="soap-patient-bottom">
        <div class="message">
          {{
            $t("message.viewingSoapNote", {
              number:
                (page - 1) * perPage +
                (allResults ? 1 : 0) +
                " - " +
                (page * perPage > allResults ? allResults : page * perPage),
              sum: allResults,
            })
          }}
        </div>
        <div class="items">
          <div
            class="item"
            v-for="note in soapNotes"
            :key="'soap_' + note.id"
            @click.prevent="viewSoapNote(note)"
          >
            <div class="calendar-main-inner-agenda-item-detail">
              <div class="calendar-main-inner-agenda-item-detail-top">
                <div
                  :class="
                    'calendar-main-inner-agenda-item-icon-wrap ' +
                    colorClass(note.doctor_id, 'doctor')
                  "
                >
                  <item-icon
                    type="doctor-2"
                    :class-name="colorClass(note.doctor_id, 'doctor')"
                  ></item-icon>
                </div>
                <div
                  :class="
                    'calendar-main-inner-agenda-item-detail-top-title ' +
                    colorClass(note.doctor_id, 'doctor')
                  "
                >
                  {{ note.doctor_first_name + " " + note.doctor_last_name }}
                  <template v-if="note.status === 'draft'">
                    ({{ $t("label.draft").toLowerCase() }})
                  </template>
                  <template v-if="note.status === 'signed'">
                    ({{ $t("label.signed").toLowerCase() }})
                  </template>
                </div>
              </div>
              <div
                v-if="note.patient_id"
                class="calendar-main-inner-agenda-item-detail-bottom"
              >
                <div class="calendar-main-inner-agenda-item-detail-bottom-item">
                  {{ note.patient_first_name + " " + note.patient_last_name }}
                </div>
              </div>
              <div class="calendar-main-inner-agenda-item-detail-bottom">
                {{ customFormat(note.created_at, "MM / dd / yyyy") }}
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <div class="button action" @click="prev">
            {{ $t("button.previous") }}
          </div>
          <v-select
            :options="pages"
            v-model="page"
            @update:modelValue="changePage"
          ></v-select>
          <div class="button action" @click="next">{{ $t("button.next") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import ItemIcon from "@/views/private/components/ItemIcon";
import FormFilterProvider from "@/views/private/components/forms/FormFilterProvider.vue";
import FormFilterPatient from "@/views/private/components/forms/FormFilterPatient.vue";
export default {
  name: "SoapNotes",
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormFilterPatient,
    FormFilterProvider,
    DateTimeInput,
    ItemIcon,
  },
  data() {
    return {
      patient: null,
      soapNotes: [],
      allResults: 0,
      pages: [1],
      page: 1,
      perPage: 16,
      filterExpanded: false,
      fromDate: null,
      toDate: null,
      doctor: null,
      loading: false,
      componentsShown: true,
    };
  },
  created() {
    this.loadSoapNotes();

    if (!this.showAll) {
      this.loadPatient();
    }
  },
  methods: {
    setStart(value) {
      this.fromDate = value;
    },
    setEnd(value) {
      this.toDate = value;
    },
    loadPatient() {
      this.loading = true;
      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadSoapNotes() {
      let params = {};
      if (this.fromDate !== null) {
        params.from = this.getUTCNoonFromDate(this.fromDate).toISOString();
      }

      if (this.toDate !== null) {
        params.to = this.getUTCNoonFromDate(this.toDate).toISOString();
      }

      if (this.doctor !== null) {
        params.doctor = {};
        params.doctor.id = this.doctor.id;
      }

      if (this.showAll && this.patient) {
        params.patient = {};
        params.patient.id = this.patient.id;
      }

      params.page = this.page;
      params.per_page = this.perPage;

      this.loading = true;
      HTTP({
        method: "GET",
        url: this.showAll
          ? "/api/v1/soap-notes/"
          : "/api/v1/patients/" + this.$route.params.id + "/events/soap-notes",
        data: {},
        params: params,
      })
        .then((response) => {
          if (response.data) {
            this.soapNotes = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resetFilter() {
      this.fromDate = null;
      this.toDate = null;
      this.doctor = null;
      this.page = 1;
      if (this.showAll) {
        this.patient = null;
      }
      this.rerenderComponents();
      this.loadSoapNotes();
    },
    rerenderComponents() {
      this.componentsShown = false;
      this.$nextTick().then(() => {
        this.componentsShown = true;
      });
    },
    doFilter() {
      this.page = 1;
      this.loadSoapNotes();
    },
    prev() {
      if (this.page === 1) {
        return;
      }

      this.page = this.page - 1;
      if (this.page === 0) {
        this.page = 1;
      }
      this.loadSoapNotes();
    },
    changePage() {
      this.loadSoapNotes();
    },
    next() {
      if (this.page === Math.max(...this.pages)) {
        return;
      }
      this.page = this.page + 1;
      if (this.page > Math.max(...this.pages)) {
        this.page = Math.max(...this.pages);
      }
      this.loadSoapNotes();
    },
    viewSoapNote(note) {
      if (note.status === "locked" || this.isInactivePatient(this.patient)) {
        this.openInNewTab("soapPreview", { id: note.id });
      } else {
        this.$router.push({ name: "soapEdit", params: { id: note.id } });
      }
    },
  },
};
</script>
