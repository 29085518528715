import store from "@/store";

export default {
  hasRole(roles) {
    for (let role in roles) {
      if (
        typeof store.state.user.roles !== "undefined" &&
        store.state.user.roles.indexOf(roles[role]) > -1
      ) {
        return true;
      }
    }
    return false;
  },
};
