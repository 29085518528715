import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isToday from "date-fns/isToday";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isEqual from "date-fns/isEqual";
import max from "date-fns/max";
import min from "date-fns/min";
import parseISO from "date-fns/parseISO";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
// import { listTimeZones } from "timezone-support";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import store from "@/store";
import { differenceInYears, isDate } from "date-fns";

const dateFormat = "yyyy-MM-dd HH:mm";
const dateTimeFormat12 = "MM / dd / y, p";
const dateTimeFormat24 = "MM / dd / y, HH:mm";

// const timezones = listTimeZones();

const timezones = {
  "Pacific Time": "America/Los_Angeles",
  "Mountain Time": "America/Denver",
  "Central Time": "America/Chicago",
  "Eastern Time": "America/New_York",
};

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/*eslint no-unused-vars:0*/
const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default {
  methods: {
    getMonthName: function (date) {
      return MONTH_NAMES[date.getMonth()];
    },
    getDayName: function (date) {
      return WEEK_DAYS[date.getDay()];
    },
    isToday(date) {
      return isToday(date);
    },
    isAfter(date1, date2) {
      return isAfter(date1, date2);
    },
    diffInCalDays(date1, date2) {
      return differenceInCalendarDays(date1, date2);
    },
    diffInHours(date1, date2) {
      return differenceInHours(date1, date2);
    },
    diffInMinutes(date1, date2) {
      return differenceInMinutes(date1, date2);
    },
    diffInYears(date1, date2) {
      return differenceInYears(date1, date2);
    },
    isBefore(date1, date2) {
      return isBefore(date1, date2);
    },
    isAfterNow(date) {
      return isAfter(date, this.newTimeZoneDate());
    },
    isBeforeNow(date) {
      return isBefore(date, this.newTimeZoneDate());
    },
    getMaxDate(dateArray) {
      return max(dateArray);
    },
    getMinDate(dateArray) {
      return min(dateArray);
    },
    parseDateFormat: function (dateString, format) {
      return parse(dateString, format, new Date());
    },
    parseISO: function (dateString) {
      return parseISO(dateString);
    },
    newTimeZoneDate() {
      let date = new Date();
      return this.parseFromUTC(date);
    },
    convertToUtc(dateString) {
      return zonedTimeToUtc(dateString, "Etc/UTC");
    },
    parseFromUTC: function (date) {
      if (store.getters.timeZone === "Etc/UTC") {
        return date;
      }

      return utcToZonedTime(date, store.getters.timeZone);
    },
    parseToUTC: function (date) {
      // TODO check if need to change with convertToUtc
      return zonedTimeToUtc(date, store.getters.timeZone);
    },
    getUTCNoonFromDate: function (date) {
      let helperDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      helperDate.setHours(12, 0, 0);
      helperDate.setUTCHours(12, 0, 0);
      return helperDate;
    },
    dateIsValid: function (value) {
      return isValid(value);
    },
    customFormat: function (date, formatString) {
      if (!(date instanceof Date)) {
        date = this.parseFromUTC(new Date(date));
      }
      if (!this.dateIsValid(date)) {
        return "";
      }
      if (typeof formatString !== "undefined") {
        return format(date, formatString);
      }
      return format(date, dateFormat);
    },
    formatDateTime(date) {
      let format = store.getters.userHoursFormat24
        ? dateTimeFormat24
        : dateTimeFormat12;

      return this.customFormat(date, format);
    },
    datesEqual(date1, date2) {
      return isEqual(date1, date2);
    },
    getWeekEnd: function (date) {
      return endOfWeek(date, { weekStartsOn: 0 });
    },
    getWeekStart: function (date) {
      return startOfWeek(date, { weekStartsOn: 0 });
    },
    getTimezones() {
      return timezones;
    },
    getTodayStart() {
      return startOfToday();
    },
    getTodayEnd() {
      return endOfToday();
    },
    getMonthStart: function (date) {
      return startOfMonth(date);
    },
    fixTimezoneOffset: function (date) {
      let helperDate = new Date();
      return date.addMinutes(helperDate.getTimezoneOffset() * -1);
    },
    handleDateTimeValue: function (date, parseUtc = true) {
      if (date === null) {
        return date;
      }

      date = this.getDateFromTime(date);

      return parseUtc ? this.parseFromUTC(date) : date;
    },
    handleDateOnlyValue: function (date) {
      if (date === null) {
        return date;
      }

      let helperDate = this.parseFromUTC(date);
      helperDate.setHours(0, 0, 0);
      return helperDate;
    },
    getDateFromTime(time) {
      let date = null;
      if (
        !isValid(parseISO(time)) &&
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
      ) {
        date = parse(time, "HH:mm", new Date());
      }

      if (
        !isValid(parseISO(time)) &&
        /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(time)
      ) {
        date = parse(time, "hh:mm a", new Date());
      }

      return date ? date : time;
    },
    roundToNearestMinutes: function (date, nearestToMinutes) {
      return roundToNearestMinutes(date, { nearestTo: nearestToMinutes });
    },
    preparePostDataWithDates(data, dateOnly = []) {
      let postData = {};
      for (let key in data) {
        if (data[key] instanceof Date) {
          if (dateOnly.indexOf(key) > -1) {
            postData[key] = this.getUTCNoonFromDate(data[key]).toISOString();
            continue;
          }

          postData[key] = this.parseToUTC(data[key]).toISOString();
          continue;
        }

        postData[key] = data[key];
      }
      return postData;
    },
    getSelectedDateTimeWithStartWorkingTime(date, workingStartMinutes) {
      let resultDate = isToday(date)
        ? date
        : date.setHours(workingStartMinutes / 60, 0, 0);

      return isDate(resultDate) ? resultDate : new Date(resultDate);
    },
  },
};
