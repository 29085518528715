<template>
  <div class="calendar-main-inner-agenda" id="agenda">
    <h2 class="calendar-main-inner-agenda-title-print">
      Appointments for {{ customFormat(today, "EEE MMM dd, YYY") }}
    </h2>
    <div
      v-for="event in $store.getters.eventsObjects.filter((item) => {
        return item.type !== 'time_off';
      })"
      :key="'agenda_' + event.id"
      class="calendar-main-inner-agenda-item"
    >
      <div class="calendar-main-inner-agenda-item-icon">
        <div
          :class="
            'calendar-main-inner-agenda-item-icon-wrap ' +
            colorClass(event.treatment ? event.treatment.id : null, 'treatment')
          "
        >
          <item-icon
            type="treatment"
            :class-name="
              colorClass(
                event.treatment ? event.treatment.id : null,
                'treatment'
              )
            "
          ></item-icon>
        </div>
      </div>
      <div class="calendar-main-inner-agenda-item-detail">
        <div class="calendar-main-inner-agenda-item-detail-top">
          <div
            :class="
              'calendar-main-inner-agenda-item-detail-top-title ' +
              colorClass(
                event.treatment ? event.treatment.id : null,
                'treatment'
              )
            "
          >
            {{ event.treatment.name }}
          </div>
          <div
            v-if="['user', 'doctor'].indexOf($store.state.userType) > -1"
            class="calendar-main-inner-agenda-item-detail-top-icon"
            @click="showDetail(event)"
          ></div>
          <div class="calendar-main-inner-agenda-item-detail-top-name">
            {{ event.patient.first_name + " " + event.patient.last_name }}
          </div>
          <div class="calendar-main-inner-agenda-item-detail-top-time">
            <template v-if="['patient'].indexOf($store.state.userType) > -1">
              {{ customFormat(event.start, "EEE, MMM dd") }}
              <br />
              {{
                customFormat(
                  event.start,
                  $store.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
              -
              {{
                customFormat(
                  event.end,
                  $store.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
            <template
              v-if="['user', 'doctor'].indexOf($store.state.userType) > -1"
            >
              {{
                customFormat(
                  event.start,
                  $store.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
              -
              {{
                customFormat(
                  event.end,
                  $store.userHoursFormat24 ? "HH:mm" : "hh:mm a"
                )
              }}
            </template>
          </div>
        </div>
        <div class="calendar-main-inner-agenda-item-detail-bottom">
          <div class="calendar-main-inner-agenda-item-detail-bottom-item">
            <item-icon
              type="doctor-2"
              :class-name="
                colorClass(event.doctor ? event.doctor.id : null, 'doctor')
              "
            ></item-icon>
            {{ event.doctor.first_name + " " + event.doctor.last_name }}
          </div>
          <div
            class="calendar-main-inner-agenda-item-detail-bottom-item"
            v-if="event.room"
          >
            <item-icon
              type="room"
              :class-name="
                colorClass(event.room ? event.room.id : null, 'room')
              "
            ></item-icon>
            {{ event.room.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
import agendaMixin from "@/views/private/components/agenda/agendaMixin";
export default {
  name: "TreatmentsAgenda",
  components: { ItemIcon },
  mixins: [agendaMixin],
};
</script>
