import { HTTP } from "@/services/api";

const tinyColor = require("tinycolor2");

export default {
  methods: {
    loadColors() {
      if (!this.$store.getters.isAuthUser) {
        return;
      }

      if (this.$store.state.colorsSet) {
        return;
      }

      if (Object.keys(this.$store.state.colors).length > 0) {
        this.injectStyling(this.$store.state.colors);
        return;
      }

      if (Object.keys(this.$store.state.colors).length === 0) {
        HTTP({
          method: "GET",
          url: "/api/v1/colors",
        })
          .then((response) => {
            if (response.data) {
              this.$store.dispatch("setColors", response.data);
              this.injectStyling(response.data);
            }
            this.$store.dispatch("setColorsSet", true);
          })
          .catch();
      }
    },
    colorText(color) {
      if (!color) {
        return "";
      }

      let brightness = tinyColor(color).getBrightness();
      return brightness > 127.5 ? "dark" : "light";
    },
    colorClass: function (itemId = null, itemType = null) {
      if (itemId === null) {
        return "";
      }

      if (itemType === null) {
        return "color-" + (itemId % 5);
      }

      return "color-" + itemType + "-" + itemId;
    },
    statusColorClass: function (status = null) {
      if (status === null) {
        return "";
      }

      return "color-" + status;
    },
    injectColor: function (type, id, color) {
      if (id === null || color === null) {
        return;
      }

      const cssClass = this.createClass(type.slice(0, -1), id, color);
      this.appendStyle(cssClass);
      this.$store.dispatch("setColor", {
        type: type,
        id: id,
        color: color,
      });
    },
    injectStyling: function (colors) {
      let css = "";

      for (let type in colors) {
        if (Object.prototype.hasOwnProperty.call(colors, type)) {
          for (let id in colors[type]) {
            if (Object.prototype.hasOwnProperty.call(colors[type], id)) {
              css += this.createClass(type.slice(0, -1), id, colors[type][id]);
            }
          }
        }
      }

      this.appendStyle(css);
    },
    appendStyle: function (style) {
      document.head.appendChild(document.createElement("style")).textContent =
        style;
    },
    createClass: function (type, id, color) {
      let tinyColorHelper = tinyColor(color);
      const firstColor = color;
      const secondColor = tinyColorHelper
        .lighten((70 * ((1 - tinyColorHelper.toHsl().l) * 100)) / 100)
        .toHexString();
      const thirdColor = tinyColorHelper
        .lighten((50 * ((1 - tinyColorHelper.toHsl().l) * 100)) / 100)
        .toHexString();
      const fourthColor = tinyColorHelper
        .lighten((20 * ((1 - tinyColorHelper.toHsl().l) * 100)) / 100)
        .toHexString();

      return `
        .color-${type}-${id} {
            background-color: ${secondColor};
            color: ${firstColor};
            border-color: #fff
        }
        
        .color-${type}-${id}.inverse {
            background-color: ${firstColor}
        }
        
        .color-${type}-${id}.event>svg,
        .color-${type}-${id}.event>svg circle,
        .color-${type}-${id}.event>svg path {
            fill: ${firstColor}
        }
        
        .color-${type}-${id}.filter-item {
            background-color: unset;
            color: unset
        }
        
        .color-${type}-${id}.filter-item .filter-item-checkbox-checkmark {
            background-color: ${secondColor}
        }
        
        .color-${type}-${id}.filter-item input:checked~.filter-item-checkbox-checkmark {
            background-color: ${firstColor}
        }
        
        .color-${type}-${id}.color-stripes {
            background: repeating-linear-gradient(130deg, ${fourthColor}, ${fourthColor} 12px, ${thirdColor} 0, ${thirdColor} 24px);
            background-color: #fff
        }
        
        .color-${type}-${id}.color-stripes.disabled {
            background: repeating-linear-gradient(130deg, #faf8f7, #faf8f7 12px, rgba(224, 226, 234, .4) 0, rgba(224, 226, 234, .4) 24px);
            background-color: #fff;
            color: #a2a9ad;
            border-color: #90929a
        }
        
        .color-${type}-${id}>svg,
        .color-${type}-${id}>svg circle,
        .color-${type}-${id}>svg path {
            fill: ${firstColor}
        }
        `;
    },
  },
};
