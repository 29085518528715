<template>
  <div class="payment-tab-single">
    <template v-if="!loading">
      <div class="payment-tab-single-item">
        <v-select
          :options="products"
          placeholder="Select product..."
          label="name"
          v-model="product"
          @update:modelValue="selectProduct"
        ></v-select>
      </div>
      <div
        class="payment-tab-single-items"
        v-for="item in filteredProducts"
        :key="'product_' + item.id"
      >
        <payments-single-product
          :item="item"
          :doctors="doctors"
          :patient-id="patient ? patient.id : null"
          @add-to-checkout="(item) => this.addToCheckout(item)"
          @update-quantity="(value) => (item.quantity = value)"
          @update-doctor="(value) => (item.product_sold_by_doctor = value)"
        ></payments-single-product>
      </div>
      <div class="payment-submit" v-if="filteredProducts.length">
        <div class="button action" @click="addAllProducts">
          {{ $t("button.addAll") }}
        </div>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import PaymentsSingleProduct from "@/views/private/components/payments/PaymentsSingleProduct";
import axios from "axios";

export default {
  name: "PaymentTabProducts",
  components: { PaymentsSingleProduct },
  props: {
    cartItems: {
      type: Array,
      default() {
        return [];
      },
    },
    doctors: {
      type: Array,
      default() {
        return [];
      },
    },
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addToCheckout: {
      type: Function,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      product: null,
      products: [],
      productsLoaded: false,
      lastEvent: null,
      cancelToken: {
        productsToken: null,
        lastEventToken: null,
      },
    };
  },
  computed: {
    filteredProducts() {
      let scope = this;
      return this.cartItems.filter(function (item) {
        if (item.product_sold_by_doctor === null) {
          item.product_sold_by_doctor = scope.lastEvent
            ? scope.lastEvent.doctor
            : {
                id: null,
                first_name: scope.$t("label.generalClinic"),
                last_name: "",
              };
        }
        return item.item_type === "Product" && item.status === "active";
      });
    },
  },
  mounted() {
    this.loadLastEvent();
  },
  activated() {
    if (!this.productsLoaded) {
      this.loadInventoryProducts();
    }
  },
  deactivated() {
    cancelTokens(this.cancelToken);
  },
  watch: {
    products: function (newVal) {
      this.$emit("set-products", newVal);
    },
  },
  methods: {
    loadInventoryProducts() {
      setCancelToken(this.cancelToken, "productsToken");

      return HTTP({
        method: "GET",
        url: "/api/v1/inventory/products",
        cancelToken: this.cancelToken.productsToken.token,
        params: {},
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.products = response.data.data;
            this.productsLoaded = true;
          }
        })
        .catch(() => {});
    },
    loadLastEvent() {
      if (!this.patient) {
        return;
      }

      setCancelToken(this.cancelToken, "lastEventToken");
      HTTP({
        method: "GET",
        url: "/api/v1/events/",
        data: {},
        params: {
          patient: {
            id: [this.patient.id],
          },
          start: this.parseToUTC(this.getTodayStart()).toISOString(),
          end: this.parseToUTC(Date.now()).toISOString(),
          _sort: "start",
          _order: "asc",
        },
        cancelToken: this.cancelToken.lastEventToken.token,
      })
        .then((response) => {
          if (response.data && response.data.length) {
            this.lastEvent = response.data[0];
          }
        })
        .catch(() => {});
    },
    selectProduct(value) {
      if (!this.patient) {
        return;
      }

      this.product = null;
      this.$emit("loading", true);
      HTTP({
        method: "POST",
        url: "/api/v1/purchases/patients/" + this.patient.id + "/cart-items",
        data: {
          item_type: "Product",
          status: "active",
          quantity: 1,
          price: value.price,
          item_data: value,
        },
      })
        .then(() => {
          this.$emit("loading", false);
          this.$emit("load-cart-items", false);
        })
        .catch(() => {
          this.$emit("loading", false);
        });
    },
    addAllProducts() {
      let scope = this;
      let promises = [];
      this.filteredProducts.forEach(function (item) {
        promises.push(scope.addToCheckout(item));
      });
      this.$emit("loading", true);
      axios.all(promises).then(function () {
        scope.$emit("loading", false);
      });
    },
  },
};
</script>
