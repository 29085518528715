<template>
  <div class="inventory-sidebar">
    <search mode="inventory"></search>
    <!--    <div class="inventory-sidebar-title">{{ $tc("label.folder", 2) }}</div>-->
    <div class="expandable" ref="inventoryFolders">
      <div class="expandable-group">
        <div
          class="expandable-group-toggle"
          :class="{ active: activeFolder === null }"
          @click.prevent="setActiveFolder(null)"
        >
          <div class="icon icon-folder favorites gray"></div>
          <div class="label">{{ $t("label.allItems") }}</div>
        </div>
        <template
          v-for="(folder, index) in foldersExpanded
            ? folders
            : folders.slice(0, listItemLimit)"
          :key="'folder_' + index"
        >
          <div
            class="expandable-group-item"
            :class="{ active: activeFolder && activeFolder.id === folder.id }"
            @click.prevent="setActiveFolder(folder)"
          >
            <div class="icon icon-folder"></div>
            {{ folder.name }}
          </div>
          <div
            class="expandable-group-item expandable-group-item-showmore active"
            @click.prevent.stop="foldersExpanded = !foldersExpanded"
            v-if="index + 1 === listItemLimit && folders.length > listItemLimit"
            :key="index"
          >
            Show more
            <span
              :class="{ icon: true, opened: foldersExpanded }"
              class="icon"
            ></span>
          </div>
        </template>
      </div>
    </div>
    <div class="expandable" ref="inventoryFolders">
      <div class="expandable-group">
        <div
          class="expandable-group-toggle"
          @click.prevent="setActiveTag(null)"
        >
          <div class="label">{{ $t("label.tags") }}</div>
        </div>
        <template
          v-for="(tag, index) in tagsExpanded
            ? tags
            : tags.slice(0, listItemLimit)"
          :key="'tag_' + index"
        >
          <div
            class="expandable-group-item"
            :class="{ active: activeTag && activeTag === tag }"
            @click.prevent="setActiveTag(tag)"
          >
            {{ tag }}
          </div>
          <div
            class="expandable-group-item expandable-group-item-showmore active"
            @click.prevent.stop="tagsExpanded = !tagsExpanded"
            v-if="index + 1 === listItemLimit && tags.length > listItemLimit"
            :key="index"
          >
            Show more
            <span
              :class="{ icon: true, opened: tagsExpanded }"
              class="icon"
            ></span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/views/private/components/Search";
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import onboardTooltipMixin from "@/mixins/onboardTooltip";
export default {
  name: "InventorySidebar",
  mixins: [onboardTooltipMixin],
  components: { Search },
  props: {
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      listItemLimit: 15,
      foldersExpanded: false,
      tagsExpanded: false,
      cancelToken: { tag: null },
      tags: [],
      activeFolder: this.$store.state.inventory.folder,
      activeTag: this.$store.state.inventory.tag,
    };
  },
  created() {
    this.loadTags();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.inventory.folder": function (val) {
      this.activeFolder = val;
    },
    "$store.state.inventory.tag": function (val) {
      this.activeTag = val;
    },
    folders: function (val) {
      if (val.length < this.listItemLimit) {
        this.foldersExpanded = true;
      }
    },
  },
  methods: {
    loadTags() {
      setCancelToken(this.cancelToken, "tag");
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/tags/",
        cancelToken: this.cancelToken.tag.token,
      })
        .then((response) => {
          this.tags = response.data.data;
          if (response.data.data.length < this.listItemLimit) {
            this.tagsExpanded = true;
          }
        })
        .catch(() => {});
    },
    setActiveTag: function (tag) {
      if (this.activeTag === tag) {
        return this.$store.dispatch("setResourceStoreVal", {
          key: "inventory",
          type: "tag",
          data: null,
        });
      }
      return this.$store.dispatch("setResourceStoreVal", {
        key: "inventory",
        type: "tag",
        data: tag,
      });
    },
    setActiveFolder: function (folder) {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventory",
        type: "folder",
        data: folder,
      });
    },
  },
};
</script>
