<template>
  <div class="row">
    <div class="col-lg-12 patients">
      <patient-form-view mode="edit" />
    </div>
  </div>
</template>

<script>
import PatientFormView from "@/views/private/components/patients/PatientFormView";
export default {
  name: "PatientIndex",
  components: {
    PatientFormView,
  },
};
</script>
