<template>
  <div>
    {{ $t("message.accessDenied") }}
  </div>
</template>

<script>
export default {
  name: "ForbiddenComponent",
};
</script>
