<template>
  <div :class="mainClass">
    <label>{{ label ? label : $t("label.logo") }}</label>
    <input
      accept="image/*"
      :ref="currentRef"
      type="file"
      name="file-upload"
      multiple="false"
      @change="addInputFile"
      style="display: none"
    />
    <div
      class="uploader"
      :class="{ dropping: isDropping }"
      @dragover.prevent="isDropping = true"
      @dragleave.prevent="isDropping = false"
      @drop.stop.prevent="handleFileDrop"
      @click.prevent="handleUploadButton"
    >
      <div v-if="!image" class="uploader-thumb">
        <div class="icon icon-thumb"></div>
      </div>
      <div v-if="!image && !imageSrc" class="uploader-action">
        {{ $t("button.addPhoto") }}
      </div>
      <div class="uploader-image" v-if="image">
        <img :src="imageSrc" />
        <div class="uploader-filename">
          {{ image.name }}
          <div class="remove" @click.prevent.stop="removeImage">
            <div class="icon icon-trash"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileMixin from "@/mixins/fileMixin";
import { default as EventBus } from "@/services/eventbus";
export default {
  name: "ImageUpload",
  props: ["mainClass", "label", "image", "imageSrc", "inputRef"],
  mixins: [fileMixin],
  data() {
    return {
      isDropping: false,
    };
  },
  created() {
    EventBus.$on("upload-file", (file) => {
      let self = this;

      this.$emit("image-file", file.file);

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          self.$emit("image-src", reader.result);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    });
  },
  unmounted() {
    EventBus.$off("upload-file");
  },
  computed: {
    currentRef: function () {
      return this.inputRef ? this.inputRef : "fileInput";
    },
  },
  methods: {
    removeImage() {
      this.$emit("image-remove", null);
    },
    handleUploadButton() {
      this.$refs[this.currentRef].click();
    },
    handleFileDrop(event) {
      if (!event.dataTransfer) {
        return;
      }
      let dt = event.dataTransfer;
      this.addDataTransfer(dt);
    },
  },
};
</script>
