<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? (isInactiveDoctor(newDoctor)
                              ? $t("label.view")
                              : $t("label.edit")) +
                            " " +
                            $tc("label.provider", 1).toLowerCase()
                          : $t("label.addNew") +
                            " " +
                            $tc("label.provider", 1).toLowerCase()
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newDoctor.first_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.firstName") }}: </label>
                    <input
                      ref="firstName"
                      v-model="newDoctor.first_name"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newDoctor.last_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.lastName") }}: </label>
                    <input
                      ref="lastName"
                      v-model="newDoctor.last_name"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newDoctor.email'),
                    }"
                  >
                    <label class="item">{{ $t("label.email") }}: </label>
                    <input
                      ref="email"
                      v-model="newDoctor.email"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.title") }}: </label>
                    <input
                      ref="title"
                      v-model="newDoctor.title"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newDoctor.phone_number'),
                    }"
                  >
                    <label class="item">{{ $t("label.phoneNo") }}: </label>
                    <input
                      ref="phone_number"
                      type="text"
                      v-imask="masks.phone"
                      :value="newDoctor.phone_number"
                      :disabled="isInactiveDoctor(newDoctor)"
                      @accept="newDoctor.phone_number = parsePhoneValue($event)"
                    />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.address") }} 1: </label>
                    <input
                      ref="address1"
                      v-model="newDoctor.address_1"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.address") }} 2: </label>
                    <input
                      ref="address2"
                      v-model="newDoctor.address_2"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.hourFormat") }}: </label>
                    <v-select
                      :options="hourFormatOptions"
                      v-model="newDoctor.hour_format"
                      :disabled="isInactiveDoctor(newDoctor)"
                    >
                    </v-select>
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.licenseNo") }}: </label>
                    <input
                      ref="licenseNo"
                      v-model="newDoctor.license_no"
                      type="text"
                      :disabled="isInactiveDoctor(newDoctor)"
                    />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.color") }}: </label>
                    <color-picker
                      v-if="!isInactiveDoctor(newDoctor)"
                      :model-value="newDoctor.color"
                      @update:modelValue="updateColor"
                      :preset-colors="[
                        '#CF5B5B',
                        '#1897E3',
                        '#3EAD6C',
                        '#725FD0',
                        '#CDAC02',
                      ]"
                    ></color-picker>
                  </div>
                  <template
                    v-if="!this.isModeEdit() || !isInactiveDoctor(newDoctor)"
                  >
                    <p>Optional</p>
                    <div
                      class="form-item"
                      :class="{
                        error: validation.hasError('newDoctor.password'),
                      }"
                    >
                      <label class="item">{{ $t("label.password") }}: </label>
                      <input
                        v-model="newDoctor.password"
                        type="password"
                        ref="password"
                      />
                    </div>
                    <div
                      class="form-item"
                      :class="{
                        error: validation.hasError(
                          'newDoctor.password_confirmation'
                        ),
                      }"
                    >
                      <label class="item"
                        >{{ $t("label.passwordConfirmation") }}:
                      </label>
                      <input
                        v-model="newDoctor.password_confirmation"
                        type="password"
                        ref="password_confirmation"
                      />
                    </div>
                  </template>
                  <div class="form-item action">
                    <a
                      :class="{
                        button: true,
                        action: true,
                        'disable-close': true,
                        disabled: isInactiveDoctor(newDoctor),
                      }"
                      @click.prevent="saveDoctor()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";
import { Sketch } from "@ckpack/vue-color";
import imaskMixin from "@/mixins/imaskMixin";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "DoctorCreate",
  components: { "color-picker": Sketch },
  mixins: [imaskMixin],
  validators: {
    "newDoctor.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newDoctor.email": function (value) {
      return Validator.value(value).required().email();
    },
    "newDoctor.phone_number": function (value) {
      return Validator.value(value).length(11);
    },
    "newDoctor.last_name": function (value) {
      return Validator.value(value).required();
    },
    "newDoctor.password_confirmation, newDoctor.password": function (
      confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(confirmation).match(password);
      }
    },
  },
  data() {
    return {
      newDoctor: {
        first_name: null,
        last_name: null,
        email: null,
        title: null,
        hour_format: null,
        address_1: null,
        address_2: null,
        license_no: null,
        phone_number: null,
        color: "#CF5B5B",
        password: null,
        password_confirmation: null,
      },
      hourFormatOptions: ["12-hours", "24-hours"],
      loading: false,
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadDoctor();
      }
    },
    isModeEdit() {
      return "doctorEdit" === this.$route.name;
    },
    loadDoctor() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/doctors/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newDoctor = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveDoctor() {
      if (this.isInactiveDoctor(this.newDoctor)) {
        return;
      }
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/doctors/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newDoctor);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                EventBus.$emit("doctors-load");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.injectColor(
                  "doctors",
                  response.data.id ?? null,
                  response.data.color ?? null
                );
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    updateColor(color) {
      this.newDoctor.color = color.hex;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
