<template>
  <div class="modal remove" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div class="title">
              {{ $t("message.changesWillBeDiscarded") }}
            </div>
            <div class="sub-title">
              {{ $t("message.doYouWantToProceed") }}
            </div>
            <div class="action">
              <a class="button cancel" @click.prevent="closeModal">
                {{ $t("button.cancel") }}
              </a>
              <a class="button delete" @click.prevent="discard">
                {{ $t("button.discard") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeWallet",
  props: ["discardCallback"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
    discard() {
      this.discardCallback();
      this.closeModal();
    },
  },
};
</script>
