<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.editUser")
                          : $t("label.addNewUser")
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!--              chrome disable autofill-->
              <input style="display: none" />
              <input type="password" style="display: none" />

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newUser.first_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.firstName") }}: </label>
                    <input
                      ref="firstName"
                      v-model="newUser.first_name"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newUser.last_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.lastName") }}: </label>
                    <input
                      ref="lastName"
                      v-model="newUser.last_name"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newUser.email'),
                    }"
                  >
                    <label class="item">{{ $t("label.email") }}: </label>
                    <input ref="email" v-model="newUser.email" type="text" />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newUser.clinic'),
                    }"
                  >
                    <label class="item">{{ $tc("label.clinic", 1) }}: </label>
                    <v-select
                      :options="clinics"
                      label="name"
                      v-model="newUser.clinic"
                      class="patient"
                    >
                    </v-select>
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.hourFormat") }}: </label>
                    <v-select
                      :options="hourFormatOptions"
                      v-model="newUser.hour_format"
                    >
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newUser.password'),
                    }"
                  >
                    <label class="item">{{ $t("label.password") }}: </label>
                    <input
                      autocomplete="new-password"
                      ref="password"
                      v-model="newUser.password"
                      type="password"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newUser.password_confirmation'
                      ),
                    }"
                  >
                    <label class="item"
                      >{{ $t("label.passwordConfirmation") }}:
                    </label>
                    <input
                      ref="passwordConfirmation"
                      autocomplete="new-password"
                      v-model="newUser.password_confirmation"
                      type="password"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="saveUser()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "AdminUserCreate",
  components: {},
  validators: {
    "newUser.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newUser.email": function (value) {
      return Validator.value(value).required().email();
    },
    "newUser.clinic": function (value) {
      return Validator.value(value).required();
    },
    "newUser.password": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required().minLength(8);
      } else {
        return Validator.value(value).minLength(8);
      }
    },
    "newUser.password_confirmation, newUser.password": function (
      confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(confirmation).required().match(password);
      }
    },
  },
  data() {
    return {
      newUser: {
        first_name: null,
        last_name: null,
        email: null,
        hour_format: null,
        clinic: null,
        password: null,
        password_confirmation: null,
      },
      hourFormatOptions: ["12-hours", "24-hours"],
      clinics: [],
      loading: false,
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  created() {
    this.loadClinics();
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadUser();
      }
    },
    isModeEdit() {
      return "adminUserEdit" === this.$route.name;
    },
    loadUser() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/users/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newUser = response.data;
            this.newUser.password = "";
            this.newUser.password_confirmation = "";
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadClinics() {
      HTTP({
        method: "GET",
        url: "/api/v1/clinics/",
      })
        .then((response) => {
          if (response.data) {
            this.clinics = response.data;
          }
        })
        .catch(() => {});
    },
    saveUser() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/users/" + (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let data = [];
          if (self.isModeEdit()) {
            data["id"] = self.$route.params.id;
          }
          data["first_name"] = self.newUser.first_name;
          data["last_name"] = self.newUser.last_name;
          data["hour_format"] = self.newUser.hour_format;
          data["email"] = self.newUser.email;
          data["clinic"] = {
            id: self.newUser.clinic ? self.newUser.clinic.id : null,
          };
          data["password"] = self.newUser.password;
          data["password_confirmation"] = self.newUser.password_confirmation;
          let postData = self.preparePostDataWithDates(data);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                EventBus.$emit("users-load");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
