<template>
  <div ref="load" :class="customClass" v-if="showLoadMore && !showLoader">
    {{ message }}
  </div>
  <div ref="load" class="loading" v-if="showLoadMore && showLoader"></div>
</template>
<script>
export default {
  name: "LoadMore",
  props: {
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  emits: ["load-more"],
  data() {
    return {
      observer: null,
    };
  },
  watch: {
    showLoadMore: function (value) {
      if (value) {
        this.initObserver();
      }
    },
  },
  mounted() {
    this.initObserver();
  },
  unmounted() {
    this.observer = null;
  },
  methods: {
    async infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        await this.$emit("load-more");
      }
    },
    initObserver: function () {
      if (!this.observer) {
        this.observer = new IntersectionObserver(this.infiniteScroll, {
          threshold: [0, 1],
        });
      }
      this.$nextTick(() => {
        if (this.$refs.load) {
          this.observer.observe(this.$refs.load);
        }
      });
    },
  },
};
</script>
