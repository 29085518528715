<template>
  <div class="list">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.reports.agingBalances") }}
      </div>
      <div class="list-top-action">
        <div class="button action" @click.prevent="exportFile('csv')">
          {{ $t("button.exportCsv") }}
        </div>
        <div class="button action" @click.prevent="exportFile('pdf')">
          {{ $t("button.exportPdf") }}
        </div>
      </div>
    </div>

    <!--          files section-->
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="reports"
          :sortArray="[
            {
              label: $t('label.patientId'),
            },
            {
              label: $t('label.dateOfBirth'),
            },
            {
              label: $t('label.name'),
            },
            {
              label: $t('label.status'),
            },
            {
              label: $t('label.dateOfService'),
            },
            {
              label: $t('label.balance030days'),
            },
            {
              label: $t('label.balance3060days'),
            },
            {
              label: $t('label.balance6090days'),
            },
            {
              label: $t('label.balanceOver90days'),
            },
            {
              label: $t('label.balanceTotal'),
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- reports-->

        <div class="list-items-section-wrapper" v-show="loading">
          {{ $t("message.loading") }}...
        </div>
        <div class="list-items-section-wrapper" v-show="!loading">
          <div v-if="data.length === 0" class="list-items-section-empty">
            {{ $t("label.noResults") }}
          </div>
          <div
            class="list-items-section-item"
            v-for="(item, index) in data.items"
            :key="'item_' + index"
          >
            <div class="basic">
              {{ item.patient_id }}
            </div>
            <div class="basic">
              {{ item.patient_date_of_birth }}
            </div>
            <div class="basic">
              {{ item.patient_name }}
            </div>
            <div class="basic">
              {{ item.patient_status }}
            </div>
            <div class="basic">
              {{ item.date_of_service }}
            </div>
            <div class="basic">
              {{ item.balance_0_30_days }}
            </div>
            <div class="basic">
              {{ item.balance_30_60_days }}
            </div>
            <div class="basic">
              {{ item.balance_60_90_days }}
            </div>
            <div class="basic">
              {{ item.balance_over_90_days }}
            </div>
            <div class="basic">
              {{ item.total_balance }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import fileMixin from "@/mixins/fileMixin";

export default {
  name: "PaymentDetailsView",
  mixins: [fileMixin],
  components: {
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      data: [],
      pages: [],
      patients: [],
      patient: null,
      start: null,
      end: null,
      allResults: 0,
      cancelToken: { load: null },
      loading: false,
    };
  },
  unmounted() {
    this.$store.dispatch("resetReportsQuery");
    cancelTokens(this.cancelToken);
  },
  created() {
    this.loadData();
  },
  watch: {
    "$store.state.reports": {
      deep: true,
      handler: function (val, oldVal) {
        if (val !== oldVal && !val.reset) {
          this.loadData();
        }
      },
    },
  },
  methods: {
    loadData() {
      setCancelToken(this.cancelToken, "load");
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/reports/aging-balances",
        data: {},
        params: {
          sort_by: this.$store.state.reports.sortOrder,
          page: this.$store.state.reports.pagination.page,
          per_page: this.$store.state.reports.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.data = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportFile(type) {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/reports/aging-balances/export/" + type,
        data: {},
        params: {
          sort_by: this.$store.state.reports.sortOrder,
        },
        responseType: "blob",
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          this.downloadFile(response);
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", { type: "reports", data: value });
    },
    setStart(value) {
      this.start = value;
    },
    setEnd(value) {
      this.end = value;
    },
  },
};
</script>
