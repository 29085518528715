<template>
  <div class="options-menu-wrapper">
    <div
      class="action"
      :ref="'action_' + folder.name"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + folder.name],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="renameFolder">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Rename</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent="deleteFolder">
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Delete</div>
      </div>
      <div
        v-if="!folder.shortcut"
        class="options-menu-menu-item"
        @click.prevent="addToFavorites"
      >
        <item-icon type="shortcut" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Make shortcut</div>
      </div>
      <div
        v-else
        class="options-menu-menu-item"
        @click.prevent="removeFromFavorites"
      >
        <item-icon type="shortcut" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Remove shortcut</div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import { default as EventBus } from "@/services/eventbus";
export default {
  name: "FolderOptionsMenu",
  props: ["folder"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.folder.name,
      });
    },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.folder.name) {
        this.menuOpened = false;
      }
    },
    renameFolder() {
      EventBus.$emit("open-modal", {
        type: "rename-file",
        file: this.folder,
        fileType: "folder",
      });
    },
    deleteFolder() {
      EventBus.$emit("open-modal", {
        type: "remove-file",
        file: this.folder,
        fileType: "folder",
      });
    },
    addToFavorites() {
      HTTP({
        method: "POST",
        url: "/api/v1/shortcuts/",
        data: {
          file: {
            id: this.folder.id,
          },
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.shortcutCreated"),
          });
          EventBus.$emit("favorites-load");
          this.$emit("add-shortcut");
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.shortcutNotCreated"),
          });
        });
    },
    removeFromFavorites() {
      HTTP({
        method: "DELETE",
        url: "/api/v1/shortcuts/" + this.folder.id,
        data: {},
      })
        .then(() => {
          EventBus.$emit("favorites-load");
          this.$emit("remove-shortcut");
        })
        .catch(() => {});
    },
  },
};
</script>
