<template>
  <div class="options-menu-wrapper">
    <div class="button action" @click.prevent="goToPolicyInfo">
      {{ $t("button.policyInfo") }}
    </div>
    <div
      class="button action"
      @click.prevent="goToPatientForm"
      v-if="patient.form_id"
    >
      {{ $t("button.patientForm") }}
    </div>
    <div class="button action disabled" @click.prevent="" v-else>
      {{ $t("button.patientForm") }}
    </div>
    <div class="button action" @click.prevent="goToPayments">
      {{ $tc("button.payment", 2) }}
    </div>
    <div class="button action" @click.prevent="goToSoapNotes">
      {{ $t("button.soapNotes") }}
    </div>
    <div
      class="action"
      :ref="'action_' + patient.id"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + patient.id],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="edit">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ isInactivePatient(patient) ? $t("label.view") : $t("label.edit") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        v-if="patient.token && !isInactivePatient(patient)"
        @click.prevent="goToPatientFormPublic"
      >
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("button.fillPatientForm") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        @click.prevent="
          this.openInNewTab('documents', {
            path: '/patients/' + this.patient.id,
          })
        "
      >
        <item-icon type="documents" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("button.viewDocuments") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        @click.prevent="sendPatientForm"
        v-if="!isInactivePatient(patient)"
      >
        <item-icon type="notify" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.sendPatientForm") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        v-if="!isInactivePatient(patient)"
        @click.prevent="changeStatus"
      >
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.deactivate") }}
        </div>
      </div>
      <div
        class="options-menu-menu-item"
        v-if="isInactivePatient(patient)"
        @click.prevent="changeStatus"
      >
        <item-icon type="shortcut" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">
          {{ $t("label.activate") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";
import { HTTP } from "@/services/api";

export default {
  name: "PatientOptionsMenu",
  props: ["patient"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.patient.id,
      });
    },
    edit() {
      this.$router.push({
        name: "patientEdit",
        params: {
          id: this.patient.id,
        },
      });
    },
    changeStatus() {
      this.$router.push({
        name: "patientChangeStatus",
        params: {
          id: this.patient.id,
        },
      });
    },
    goToPatientFormPublic() {
      let routeData = this.$router.resolve({
        name: "patientForms",
        params: {
          token: this.patient.token,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goToPatientForm() {
      this.$router.push({
        name: "patientForm",
        params: {
          id: this.patient.id,
          form_id: this.patient.form_id,
        },
      });
    },
    goToPolicyInfo() {
      this.$router.push({
        name: "policyInfo",
        params: {
          id: this.patient.id,
        },
      });
    },
    goToPayments() {
      this.$router.push({
        name: "paymentsPatient",
        params: { id: this.patient.id },
      });
    },
    goToSoapNotes() {
      this.$router.push({
        name: "soapPatient",
        params: { id: this.patient.id },
      });
    },
    sendPatientForm() {
      HTTP({
        method: "GET",
        url: "/api/v1/notifications/patient-records/" + this.patient.id,
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.notificationSent"),
          });
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.error"),
            text: this.$t("notification.notificationNotSent"),
          });
        });
    },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.patient.id) {
        this.menuOpened = false;
      }
    },
  },
};
</script>
