<template>
  <div class="modal remove" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div class="icon">
              <item-icon
                v-if="isInactivePatient(patient)"
                type="revert"
                :class-name="'color-green'"
              ></item-icon>
              <item-icon
                v-else
                type="delete"
                :class-name="'color-red'"
              ></item-icon>
            </div>

            <div class="title">
              {{
                isInactivePatient(patient)
                  ? $t("message.youAreAboutToActivate", {
                      item: $tc("label.patient", 1).toLowerCase(),
                    })
                  : $t("message.youAreAboutToDeactivate", {
                      item: $tc("label.patient", 1).toLowerCase(),
                    })
              }}
            </div>
            <div class="sub-title">
              <template v-if="isInactivePatient(patient)">
                This will activate
                {{ patient.first_name + " " + patient.last_name }}. <br />Are
                you sure?
              </template>
              <template v-else>
                This will deactivate
                {{ patient.first_name + " " + patient.last_name }}. <br />Are
                you sure?
              </template>
            </div>
            <div class="action">
              <a
                :class="{
                  button: true,
                  cancel: true,
                  disabled: buttonDisabled,
                }"
                @click.prevent="closeModal"
              >
                {{ $t("button.cancel") }}
              </a>
              <a
                :class="{
                  button: true,
                  delete: !isInactivePatient(patient),
                  approve: isInactivePatient(patient),
                  small: true,
                  disabled: buttonDisabled,
                }"
                @click.prevent="changeStatus"
              >
                {{
                  isInactivePatient(patient)
                    ? $t("button.activate")
                    : $t("button.deactivate")
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import EventBus from "@/services/eventbus";

export default {
  name: "DoctorChangeStatus",
  props: ["item"],
  components: {
    ItemIcon,
  },
  data() {
    return {
      loading: false,
      buttonDisabled: false,
      patient: {
        first_name: null,
        last_name: null,
        email: null,
        birthday: null,
        gender: null,
        referral_source: null,
        passport: null,
        status: null,
        mobile_number: null,
      },
    };
  },
  mounted() {
    this.loadPatient();
  },
  methods: {
    loadPatient() {
      this.loading = true;
      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit("close");
    },
    changeStatus() {
      this.buttonDisabled = true;
      HTTP({
        method: "PUT",
        url: `/api/v1/patients/${this.patient.id}`,
        data: {
          status: this.isInactivePatient(this.patient) ? "active" : "inactive",
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.isInactivePatient(this.patient)
              ? this.$t("notification.patientActivated")
              : this.$t("notification.patientDeactivated"),
          });
          this.closeModal();
          EventBus.$emit("patients-load");
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        })
        .finally(() => {
          this.buttonDisabled = false;
        });
    },
  },
};
</script>

<style scoped></style>
