<template>
  <div class="patients-form-consent">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.financialPolicy") }}
      </div>

      <div class="form-item patients-form-consent-text">
        We are dedicated to providing you with the highest quality of
        healthcare. Beyond the practice of medicine, all healthcare providers
        are faced with the task of working with many different insurance
        companies who help coordinate your care and meet your medical financial
        responsibilities. By signing this form, you acknowledge your financial
        responsibility, including your responsibility to be aware of what your
        insurance does and does not pay for, as well as any patient
        responsibility amounts. We are happy to assist you in this process.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Retaining Credit Cards on File</span> Our
        office requires retention of an active credit or debit card on file to
        bill any balances. Health Savings and Flexible Spending cards are
        acceptable forms of payment, though we will require an additional card
        on file in case the available funds on those cards have been exhausted.
        All credit card information is encrypted and stored securely.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Self-Pay</span> Payment is due in full at
        the time of your appointment unless you have elected to pre-pay for
        services. We accept cash, check and credit card payments. There will be
        a $30 fee for a returned check.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Private Insurance</span> We currently
        accept and process for participating Aetna, Blue Cross Blue Shield, and
        United Healthcare plans that cover acupuncture. We will also process
        when Medicare is primary and one of the aforenamed insurance providers
        is secondary. If you change insurance companies or employers, or your
        policy is updated or changed, you agree to provide this office with the
        current information immediately. If benefits are determined through your
        policy but you do not wish to process through your insurance, you have
        the option to sign an insurance waiver provided by our office and pay
        the self-pay rate instead.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Copays and Co-Insurance</span> If you have
        a policy that identifies a flat copay or percentage-based co-insurance,
        we will collect the estimated patient responsibility at the time of
        service.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >Deductible Patient Responsibility and Outstanding Balances</span
        >
        Some services may not be covered by your insurance plan, may process to
        your deductible or may require an additional stated amount under patient
        responsibility. Your deductible is the amount your insurance company
        requires you to pay out of pocket prior to coverage. As a participating
        provider with your plan, all balances are due in full upon our receipt
        of Explanation of Benefits (EOB) from your insurance company, including
        deductible or additional copay, coinsurance or additional fees assigned
        to you by your insurance company. Any balances on your account from
        prior visits after insurance processing, are due at your next
        appointment or will be reconciled at the month’s end and charged to your
        credit card on file.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >Worker's Compensation and Motor Vehicle Accidents/Personal
          Injury</span
        >
        In a case where a third party may be liable for payment of your bills,
        you are responsible for your charges and we require payment in full at
        the time of service. We can provide you with a superbill insurance
        receipt upon request. We will not sign a Doctor’s Lien with your
        attorney. We are not permitted to bill your private insurance for these
        services unless subrogation has been identified in writing between your
        private insurance and your worker’s comp/personal injury insurance
        companies.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Office Records</span> A $25 fee will be
        assessed for a compilation of your medical records.
      </div>
      <div class="form-item patients-form-consent-text">
        <span class="bold underline">Missed Appointments</span> To ensure that
        the most people can receive care when needed, we require notification of
        any appointment changes 24 hours in advance of your appointment. A $50
        fee will be assessed for any missed appointments or cancellations with
        less than 24-hour notice. Any cancelled/missed appointments are best to
        reschedule within the week to maximize your benefit of care and follow
        the treatment pan your Provider has outlined for you.
      </div>
      <div class="form-item patients-form-consent-text">
        By signing this form, I have read, understand and agree to the financial
        policies of {{ clinicName }}.
      </div>

      <!--      signature -->
      <div class="form-item double">
        <div class="form-item-section">
          <div class="form-item-section-title with-icon">
            {{ $t("label.signature") }}
            <div class="remove" @click.prevent.stop="clearSignature">
              <div class="icon icon-trash"></div>
            </div>
          </div>
          <div class="signature-wrapper">
            <canvas
              id="signature-pad"
              class="signature-pad"
              width="400"
              height="200"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
import SignaturePad from "signature_pad";
import { HTTP_PUBLIC } from "@/services/api";
import fileMixin from "@/mixins/fileMixin";
import formMixin from "@/mixins/formMixin";

export default {
  name: "PatientConsentFormFinancial",
  mixins: [fileMixin, formMixin],
  watch: {},
  data: function () {
    return {
      formValues: {
        insurance_signature_image: null,
      },
      clinicName: "Klinika",
      signaturePad: null,
      canvas: null,
    };
  },
  validators: {
    "formValues.legal_first_name": function (value) {
      return Validator.value(value).required();
    },
  },
  created() {
    this.getClinicInfo();
    this.checkNextStep(this.$route.params.token);
  },
  mounted() {
    this.canvas = document.getElementById("signature-pad");
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });
  },
  methods: {
    getClinicInfo() {
      HTTP_PUBLIC({
        method: "GET",
        url: "/api/v1/patient-records/" + this.$route.params.token + "/data",
      })
        .then((response) => {
          this.clinicName = response.data.clinic_name
            ? response.data.clinic_name
            : "";
        })
        .catch(() => {});
    },
    resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    },
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    clearSignature() {
      this.formValues.insurance_signature_image = null;
      this.signaturePad.clear();
      let self = this;
      this.$nextTick(() => {
        self.resizeCanvas();
      });
    },
    nextTab() {
      if (this.signaturePad.isEmpty()) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.signAndFillRequired"),
        });
        return;
      }

      this.formValues.insurance_signature_image =
        this.signaturePad.toDataURL("image/jpg");

      let formData = this.prepareConsentForm(this.formValues);

      HTTP_PUBLIC({
        method: "POST",
        url:
          "/api/v1/patient-records/" + this.$route.params.token + "/consents",
        data: {
          contract_key: "financial_policy",
          additional_data: formData,
        },
      })
        .then(() => {
          this.checkNextStep(this.$route.params.token);
        })
        .catch();
    },
  },
};
</script>
