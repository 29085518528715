<template>
  <div class="inventory-item">
    <div class="inventory-item-header">
      <div class="inventory-item-header-top">
        <div class="back" @click="$router.go(-1)">
          <div class="icon icon-arrow-left-primary"></div>
          Back
        </div>
      </div>
      <div class="inventory-item-header-bottom">
        <div class="inventory-item-header-bottom-item">
          <div class="label">{{ $route.params.id ? "Edit" : "Add" }} Item</div>
        </div>
        <div
          class="inventory-item-header-bottom-item"
          v-if="$route.params.id && item"
        >
          <div class="inventory-single-file-bottom-actions">
            <div
              class="inventory-single-file-bottom-actions-item edit"
              @click="edit(item)"
            ></div>
            <div
              v-if="item.type === 'file'"
              class="inventory-single-file-bottom-actions-item qty"
              @click="qty(item)"
            ></div>
            <file-options-menu
              v-if="item.type === 'file'"
              :file="file"
            ></file-options-menu>
            <folder-options-menu
              v-if="item.type === 'folder'"
              :folder="file"
            ></folder-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="inventory-item-main">
      <div class="inventory-item-main-left">
        <div class="inventory-item-main-left-title">default information</div>
        <div class="form-item" v-if="$route.params.id">
          <div class="form-item-label gray margin-right-10">SKU:</div>
          <span>{{ item.sku ? item.sku : "" }}</span>
        </div>
        <div
          class="form-item"
          :class="{ error: validation.hasError('item.name') }"
        >
          <label> Item name </label>
          <input v-model="item.name" />
        </div>
        <div
          class="form-item"
          :class="{ error: validation.hasError('item.sku') }"
        >
          <label> SKU </label>
          <input v-model="item.sku" />
        </div>
        <div
          class="form-item"
          :class="{ error: validation.hasError('item.barcode') }"
        >
          <label> Barcode </label>
          <input v-model="item.barcode" />
        </div>
        <div class="form-item double">
          <div>
            <label> Quantity </label>
            <input
              v-model="item.quantity"
              class="inventory-qty"
              :class="{ error: validation.hasError('item.quantity') }"
              type="number"
              min="0"
            />
          </div>
          <div class="special-with-icons">
            <div>
              <label> Min Quantity </label>
              <input
                v-model="item.min_level"
                class="inventory-qty"
                :class="{ error: validation.hasError('item.min_level') }"
                type="number"
                min="0"
                placeholder="Min Level"
              />
            </div>
            <div
              class="icon icon-alert"
              :class="{ active: item.alert }"
              @click.prevent="item.alert = !item.alert"
              v-tooltip.right="$t('tooltip.turnOnAler')"
            ></div>
          </div>
        </div>
        <div
          class="form-item"
          :class="{ error: validation.hasError('item.price') }"
        >
          <label> Price </label>
          <input v-model="item.price" type="number" min="0" />
        </div>
        <div class="form-item">
          <label> Tags </label>
          <v-select
            v-model="item.tags"
            class="taggable"
            :options="tagOptions"
            taggable
            pushTags
            multiple
          ></v-select>
        </div>
        <div class="form-item">
          <label> Notes </label>
          <textarea
            aria-label=""
            class="note auto-resize"
            @input="autoResize"
            rows="1"
            v-model="item.notes"
          ></textarea>
        </div>
        <div class="form-item no-padding">
          <a class="button action" @click.prevent="save()"> Save </a>
        </div>
      </div>
      <div class="inventory-item-main-right">
        <div class="inventory-item-main-right-title">photos</div>
        <input
          accept="image/*"
          ref="fileInput"
          type="file"
          name="file-upload"
          multiple="false"
          @change="addInputFile"
          style="display: none"
        />
        <div
          class="inventory-item-main-right-uploader"
          :class="{ dropping: isDropping }"
          @dragover.prevent="isDropping = true"
          @dragleave.prevent="isDropping = false"
          @drop.stop.prevent="handleFileDrop"
          @click.prevent="handleUploadButton"
        >
          <div
            v-if="!item.photo"
            class="inventory-item-main-right-uploader-thumb"
          >
            <div class="icon icon-thumb"></div>
          </div>
          <div
            v-if="!item.photo && !imageSrc"
            class="inventory-item-main-right-uploader-action"
          >
            Add photo
          </div>
          <div
            class="inventory-item-main-right-uploader-image"
            v-if="item.photo"
          >
            <img :src="imageSrc" />
            <div class="inventory-item-main-right-uploader-filename">
              {{ item.photo.name }}
              <div class="remove" @click.prevent.stop="removeImage">
                <div class="icon icon-trash"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileOptionsMenu from "../document/FileOptionsMenu";
import FolderOptionsMenu from "../document/FolderOptionsMenu";
import SimpleVueValidator from "simple-vue3-validator";
import fileMixin from "@/mixins/fileMixin";
import autoResize from "@/mixins/autoResize";
import { default as EventBus } from "@/services/eventbus";
import { HTTP } from "@/services/api";

const Validator = SimpleVueValidator.Validator;
export default {
  name: "InventoryItem",
  mixins: [fileMixin, autoResize],
  components: { FileOptionsMenu, FolderOptionsMenu },
  data() {
    return {
      isDropping: false,
      tagOptions: [],
      item: {
        sku: null,
        name: null,
        quantity: null,
        barcode: null,
        alert: false,
        folder_id: this.$store.state.inventory.folder
          ? this.$store.state.inventory.folder.id
          : "",
        min_level: null,
        tags: null,
        price: null,
        notes: null,
        photo: null,
      },
      imageSrc: null,
    };
  },
  validators: {
    "item.sku": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "item.name": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "item.barcode": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "item.quantity": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "item.price": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "item.min_level": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
  },
  created() {
    EventBus.$on("upload-file", (file) => {
      let self = this;
      this.item.photo = file;

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          self.imageSrc = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    });

    this.loadTags();
    if (this.$route.params.id) {
      this.loadItem();
    }
  },
  unmounted() {
    EventBus.$off("upload-file");
  },
  methods: {
    isModeEdit() {
      return this.$route.params.id;
    },
    removeImage() {
      this.item.photo = null;
      this.imageSrc = null;
    },
    handleUploadButton() {
      this.$refs.fileInput.click();
    },
    handleFileDrop(event) {
      if (!event.dataTransfer) {
        return;
      }
      let dt = event.dataTransfer;
      this.addDataTransfer(dt);
    },
    loadItem() {
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/products/" + this.$route.params.id,
      })
        .then((response) => {
          this.item = response.data;
          this.imageSrc = this.item.photo;
          this.fixTextareaHeight();
        })
        .catch(() => {});
    },
    loadTags() {
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/tags/",
      })
        .then((response) => {
          if (response.data.data) {
            this.tagOptions = response.data.data;
          }
        })
        .catch(() => {});
    },
    save() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          const data = [];
          data["name"] = self.item.name;
          data["sku"] = self.item.sku;
          data["quantity"] = self.item.quantity;
          data["min_level"] = self.item.min_level;
          data["notes"] = self.item.notes;
          data["barcode"] = self.item.barcode;
          data["photo"] = self.item.photo ? self.item.photo.file : null;
          if (self.item.photo === null) {
            data["delete_photo"] = 1;
          }
          data["folder"] = [];
          data["folder"]["id"] = self.item.folder_id;
          data["price"] = self.item.price;
          data["tags"] = self.item.tags;
          data["alert"] = self.item.alert ? 1 : 0;

          const formData = self.generateFormData(data);

          self.loading = true;
          let url =
            "/api/v1/inventory/products/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          HTTP({
            method: "POST",
            url: url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then(() => {
              self.$router.push({ name: "inventory" });
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>
