<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-inner-block">
        <div class="sidebar-inner-block-title">{{ $t("label.settings") }}</div>
        <div class="sidebar-inner-block-items">
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'user' }"
            @click="setType('user')"
          >
            <div class="name">{{ $tc("label.user", 1) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            :class="{ active: selectedType === 'password' }"
            @click="setType('password')"
          >
            <div class="name">{{ $tc("label.password", 1) }}</div>
          </div>
          <div
            class="sidebar-inner-block-items-item settings-item"
            @click="handleLogout"
          >
            <div class="name">{{ $t("button.logout") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/services/api";

export default {
  name: "DoctorsSettingsSidebar",
  props: {
    selectedType: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {
    setType(type) {
      this.$router
        .push({ name: "settingsDoctors", params: { type: type } })
        .catch(() => {});
    },
    handleLogout() {
      logout();
    },
  },
};
</script>
