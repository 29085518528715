<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-inner-block">
        <div class="sidebar-inner-block-title">
          {{ $tc("label.report", 2) }}
        </div>
        <div class="sidebar-inner-block-items">
          <div class="expandable-group" v-for="(item, key) in items" :key="key">
            <div
              class="expandable-group-toggle"
              @click.prevent.stop="mutableExpanded = key"
            >
              <div class="label text-capitalize">{{ item.label }}</div>
              <div
                class="icon icon-arrow-right"
                :class="{ expanded: mutableExpanded === key }"
              ></div>
            </div>
            <template v-if="mutableExpanded === key">
              <div
                class="expandable-group-item"
                v-for="sub in item.items"
                :class="{
                  active: sub.key === selected,
                }"
                :key="sub.label"
                @click.prevent="setActiveReport(key, sub.key)"
              >
                {{ sub.label }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportsSidebar",
  props: {
    selected: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      default: () => {
        return {};
      },
    },
    expanded: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    mutableExpanded: null,
  }),
  created() {
    this.mutableExpanded = this.expanded;
  },
  methods: {
    setActiveReport(type, subType) {
      if (subType !== this.selected) {
        this.$router.push({
          name: "reports",
          params: {
            type: type.replaceAll("_", "-"),
            sub_type: subType,
          },
        });
      }
    },
  },
};
</script>
