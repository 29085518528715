<template>
  <div class="list data-sidebar-sidebar-mini">
    <div class="list-top">
      <div class="list-top-text">{{ $t("label.insuranceClaim") }}</div>
    </div>
    <div class="list-filter">
      <div class="form-item large">
        <label class="item">{{ $tc("label.insuranceEob", 1) }}: </label>
        <v-select
          label="name"
          :options="insuranceWallets"
          :model-value="insuranceWallet"
          :filterable="false"
          @update:modelValue="(value) => changeWallet(value)"
          @search="searchInsuranceWallets"
        >
          <template
            #option="{ name, check_number, created_at, initial_amount }"
          >
            <span>
              {{
                (name ?? "") +
                " " +
                (check_number ?? "") +
                ", " +
                customFormat(created_at, "MM/dd/yyyy") +
                " - " +
                formatPrice(initial_amount)
              }}
            </span>
          </template>
          <template #no-options="{ search }">
            <vue-select-searching
              :search="search"
              :loading="insuranceWalletLoading"
              :results-count="insuranceWallets.length"
            />
          </template>
          <template #list-footer>
            <load-more
              :message="$t('message.loading') + '...'"
              :show-load-more="hasMoreInsuranceWallets"
              :custom-class="'vs__dropdown-option'"
              @load-more="
                () => {
                  insuranceWalletSearchData.page++;
                  loadInsuranceWallets();
                }
              "
            ></load-more>
          </template>
          <template
            #selected-option="{
              name,
              check_number,
              created_at,
              initial_amount,
            }"
          >
            {{
              (name ?? "") +
              " " +
              (check_number ?? "") +
              ", " +
              customFormat(created_at, "MM/dd/yyyy") +
              " - " +
              formatPrice(initial_amount)
            }}
          </template>
        </v-select>
      </div>
      <div v-if="insuranceWallet" class="form-item">
        <label class="item">{{ $t("label.remaining") }}: </label>
        <div class="form-item-value">
          {{ formatPrice(insuranceWallet.balance) }}
        </div>
      </div>
    </div>
    <div class="list-filter">
      <form-filter-patient
        :patient="patient"
        :load-on-create="true"
        @set-patient="(value) => (this.patient = value)"
      />
      <div class="form-item">
        <label class="item">{{ $t("label.status") }}: </label>
        <v-select :options="statuses" v-model="status" />
      </div>
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="0"
          :label="$t('label.start') + ':'"
          :original-start-date="start"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setStart"
        ></date-time-input>
      </div>
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="1"
          :label="$t('label.end') + ':'"
          :min-date="start"
          :original-start-date="end"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setEnd"
        ></date-time-input>
      </div>
      <div class="form-item action">
        <a class="button action" @click.prevent="loadData">
          {{ $t("button.filter") }}
        </a>
      </div>
    </div>
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="insuranceClaim"
          :sortArray="prepareHeaderArray(this.tableDefinition)"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- treatments-->
        <div class="list-items-section-wrapper">
          <template v-if="!loading">
            <div
              :class="{
                'list-items-section-item': true,
                active: expandedId === item.id,
              }"
              v-for="(item, index) in data"
              :key="'item_' + item.id"
            >
              <div
                class="toggle-expand"
                :class="{ expanded: expandedId === item.id }"
                @click="setItemExpanded(item)"
              ></div>
              <div class="name">
                {{ item.patient.first_name + " " + item.patient.last_name }}
              </div>
              <div class="status">
                <v-select
                  :options="statuses"
                  v-model="item.status"
                  @option:selected="updateStatus(item)"
                />
              </div>
              <div class="price">
                {{ formatPrice(calculateBalance(item.items)) }}
              </div>
              <div class="created">
                {{ formatDateTime(item.date) }}
              </div>
              <div class="action">
                <div
                  :class="{
                    button: true,
                    action: true,
                    disabled:
                      typeof adjustments[index] === 'undefined' ||
                      !Object.keys(adjustments[index]).length ||
                      applyingAdjustment,
                  }"
                  @click.prevent="updateAdjustmentsValue(index)"
                >
                  {{ $t("button.saveChanges") }}
                </div>
              </div>
              <insurance-claim-items
                ref="claimItems"
                :data="item.items"
                :visible="expandedId === item.id"
                :insurance-wallet="insuranceWallet"
                @updateCellValue="
                  (payload) => {
                    this.handleCellUpdate(item.items, payload, index);
                  }
                "
              />
            </div>
          </template>
          <div v-if="data.length === 0 && !loading">
            {{ $t("label.noResults") }}
          </div>
          <div v-if="loading" class="loading"></div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.insuranceClaim.pagination.page"
        :perPage="$store.state.insuranceClaim.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.insuranceClaim.pagination.page"
        :perPage="$store.state.insuranceClaim.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import fileMixin from "@/mixins/fileMixin";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import tableMixin from "@/mixins/tableMixin";
import { INSURANCE_CLAIM_STATUSES } from "@/store";
import insuranceLedgerMixin from "@/views/private/components/insurance/insuranceLedgerMixin";
import InsuranceClaimItems from "@/views/private/components/insurance/InsuranceClaimItems";
import { default as EventBus } from "@/services/eventbus";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching";
import LoadMore from "@/views/shared/components/LoadMore";
import FormFilterPatient from "@/views/private/components/forms/FormFilterPatient";

export default {
  name: "InsuranceClaims",
  components: {
    FormFilterPatient,
    LoadMore,
    VueSelectSearching,
    InsuranceClaimItems,
    DateTimeInput,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  mixins: [tableMixin, fileMixin, insuranceLedgerMixin],
  data() {
    return {
      allResults: 0,
      insuranceWallets: [],
      adjustmentErrors: {},
      insuranceWallet: null,
      insuranceWalletLoading: false,
      applyingAdjustment: false,
      insuranceWalletSearchData: {
        search: "",
        page: 1,
        per_page: 10,
        sort_by: ["first_name|asc"],
      },
      expandedId: null,
      hasMoreInsuranceWallets: true,
      patient: null,
      loading: false,
      data: [],
      end: null,
      pages: [1],
      status: null,
      statuses: Object.values(INSURANCE_CLAIM_STATUSES),
      start: null,
      updatableKeys: ["insurance_payment", "adjustment_type", "claim_type"],
      adjustments: {},
      tableDefinition: [
        {
          label: this.$t("label.patient"),
          key: "patient",
          type: this.TABLE_ITEM_TYPES.TEXT,
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "status",
          type: this.TABLE_ITEM_TYPES.TEXT,
          sortable: true,
          width: "160px",
        },
        {
          label: this.$t("label.balanceDue"),
          key: "adjustment",
          type: this.TABLE_ITEM_TYPES.TEXT,
          sortable: false,
          width: "160px",
        },
        {
          label: this.$t("label.date"),
          key: "date",
          type: this.TABLE_ITEM_TYPES.DATE,
          sortable: true,
          width: "260px",
        },
        {
          label: this.$t("label.action"),
          type: this.TABLE_ITEM_TYPES.TEXT,
          sortable: false,
          width: "225px",
        },
      ],
      cancelToken: {
        insuranceWalletsLoad: null,
        insuranceLoad: null,
        download: null,
      },
    };
  },
  watch: {
    "$store.state.insuranceClaim": {
      deep: true,
      handler: function () {
        this.loadData();
      },
    },
  },
  created() {
    this.loadInsuranceWallets();
    this.loadData();
  },
  mounted() {
    this.searchInsuranceWallets = this.asDebounce(
      this.searchInsuranceWallets,
      1000
    );
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadData() {
      this.loading = true;
      setCancelToken(this.cancelToken, "insuranceLoad");

      let params = {
        sort_by: this.$store.state.insuranceClaim.sortOrder,
        page: this.$store.state.insuranceClaim.pagination.page,
        per_page: this.$store.state.insuranceClaim.pagination.perPage,
      };

      if (this.patient) {
        params.patient = {
          id: this.patient.id,
        };
      }
      if (this.status) {
        params.status = this.status;
      }
      if (this.start) {
        params.start = this.getUTCNoonFromDate(this.start).toISOString();
      }
      if (this.end) {
        params.end = this.getUTCNoonFromDate(this.end).toISOString();
      }

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-claims",
        data: {},
        params: params,
        cancelToken: this.cancelToken.insuranceLoad.token,
      })
        .then((response) => {
          this.loading = false;
          if (response.data) {
            this.data = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateStatus(item) {
      if (!item.status) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.pleaseSelectValidStatus"),
        });
        return;
      }

      HTTP({
        method: "PUT",
        url: "/api/v1/purchases/insurance-claims/" + item.id,
        data: {
          status: item.status,
        },
      });
    },
    loadInsuranceWallets() {
      if (this.insuranceWalletLoading) {
        return;
      }
      this.insuranceWalletLoading = true;
      setCancelToken(this.cancelToken, "insuranceWalletsLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-wallet",
        cancelToken: this.cancelToken.insuranceWalletsLoad.token,
        params: {
          q: this.insuranceWalletSearchData.search,
          page: this.insuranceWalletSearchData.page,
          per_page: this.insuranceWalletSearchData.per_page,
        },
      })
        .then((response) => {
          this.insuranceWalletLoading = false;
          if (response.data.data) {
            this.insuranceWallets = this.insuranceWallets.concat(
              response.data.data
            );

            if (response.data.meta.total <= this.insuranceWallets.length) {
              this.hasMoreInsuranceWallets = false;
            }
          }
        })
        .catch(() => {
          this.insuranceWalletLoading = false;
        });
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "insuranceClaim",
        data: value,
      });
    },
    setStart(value) {
      this.start = value;
    },
    setEnd(value) {
      this.end = value;
    },
    searchInsuranceWallets(text = "") {
      this.insuranceWalletSearchData.search = text;
      this.insuranceWalletSearchData.page = 1;
      this.insuranceWalletSearchData.per_page = 10;
      this.insuranceWallets = [];
      this.hasMoreInsuranceWallets = true;
      this.loadInsuranceWallets();
    },
    setItemExpanded(item) {
      this.expandedId = this.expandedId === item.id ? null : item.id;
    },
    async handleCellUpdate(items, payload, rowIndex) {
      items[payload.index][payload.key] = payload.val;

      if (this.updatableKeys.indexOf(payload.key) > -1) {
        if (typeof this.adjustments[rowIndex] === "undefined") {
          this.adjustments[rowIndex] = {};
        }

        if (typeof this.adjustments[rowIndex][payload.id] === "undefined") {
          this.adjustments[rowIndex][payload.id] = {};
        }

        this.adjustments[rowIndex][payload.id][payload.key] = payload.val;
      }
    },
    updateAdjustmentsValue(index) {
      let self = this;
      if (!self.insuranceWallet) {
        self.$notify({
          group: "notification",
          type: "warning",
          title: self.$t("notification.warning"),
          text: self.$t("notification.pleaseSelectEob"),
        });
        return;
      }

      if (
        typeof self.adjustmentErrors[index] !== "undefined" &&
        Object.keys(self.adjustmentErrors[index]).length
      ) {
        self.$notify({
          group: "notification",
          type: "warning",
          title: self.$t("notification.warning"),
          text: self.$t("notification.fillRequiredFields"),
        });
        return;
      }

      self.applyingAdjustment = true;
      HTTP({
        method: "PUT",
        url: "/api/v1/purchases/insurance-ledgers/adjustments",
        data: {
          adjustments: self.adjustments[index],
          wallet: {
            id: self.insuranceWallet.id,
          },
        },
      })
        .then((response) => {
          if (response.data.data) {
            self.insuranceWallet.balance = response.data.data;
          }
          delete self.adjustments[index];
          self.applyingAdjustment = false;
          self.$notify({
            group: "notification",
            type: "success",
            title: self.$t("notification.thumbsUp"),
            text: self.$t("notification.success"),
          });
        })
        .catch(() => {
          self.applyingAdjustment = false;
          self.$notify({
            group: "notification",
            type: "warning",
            title: self.$t("notification.warning"),
            text: self.$t("notification.sadFace"),
          });
        });
    },
    calculateBalance(items) {
      let balance = 0;
      let self = this;
      items.forEach(function (item) {
        let itemBalance = self.getBalanceDue(item);
        balance += itemBalance;
      });
      return Math.round(balance * 100) / 100;
    },
    changeWallet(value) {
      if (!Object.keys(this.adjustments).length) {
        this.insuranceWallet = value;
        return;
      }

      let self = this;
      EventBus.$emit("open-modal", {
        type: "change-wallet",
        discardCallback: () => {
          self.adjustments = {};
          self.insuranceWallet = value;
          self.loadData();
        },
      });
    },
    getAdjustmentErrors(index) {
      return this.adjustmentErrors[index] !== "undefined"
        ? this.adjustmentErrors[index]
        : [];
    },
  },
};
</script>
