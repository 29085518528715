import { createI18n } from "vue-i18n/dist/vue-i18n.cjs";
import { messages } from "./translations";

let locale = navigator.language.split("-")[0];

const i18n = createI18n({
  fallbackLocale: "en",
  locale: locale,
  messages: messages,
  silentFallbackWarn: true,
});

export default i18n;
