<template>
  <div class="payment-tab-single">
    <div
      class="payment-tab-single-items"
      v-for="item in filteredCheckout"
      :key="'checkout_' + item.item_type + '_' + item.id"
    >
      <template v-if="item.item_type === 'Product'">
        <payments-single-product
          :item="item"
          :doctors="doctors"
          :checkout-mode="true"
          :patient-id="patient ? patient.id : null"
          @remove-from-checkout="(item) => removeFromCheckout(item)"
          @update-quantity="(value) => (item.quantity = value)"
          @update-doctor="(value) => (item.product_sold_by_doctor = value)"
        ></payments-single-product>
      </template>
      <template v-if="item.item_type === 'Appointment'">
        <payments-single-event
          :item="item"
          :checkout-mode="true"
          @remove-from-checkout="(item) => removeFromCheckout(item)"
        ></payments-single-event>
      </template>
      <template v-if="item.item_type === 'Plan'">
        <payments-single-plan
          :item="item"
          :checkout-mode="true"
          @remove-from-checkout="(item) => removeFromCheckout(item)"
        ></payments-single-plan>
      </template>
    </div>
    <div class="payment-tab-single-summary" v-if="filteredCheckout.length">
      <div class="payment-tab-single-item">
        <div class="payment-tab-single-item-bottom">
          <div class="payment-tab-single-item-bottom-item">
            {{ $t("label.summary") }}
          </div>
          <div class="payment-tab-single-item-bottom-item">
            {{ $t("label.totalItems") }}: {{ formatPrice(orderTotal) }}
          </div>
        </div>
        <div class="payment-tab-single-item-bottom">
          <div class="payment-tab-single-item-bottom-item"></div>
          <div class="payment-tab-single-item-bottom-item special">
            {{ $t("label.discount") }}:
            <div class="helper">
              <div class="form-item">
                <input
                  v-if="discountPercent"
                  type="number"
                  min="0.0"
                  max="100.0"
                  step="1.0"
                  maxlength="4"
                  size="4"
                  v-model="discountValue"
                />
                <input
                  v-if="!discountPercent"
                  type="number"
                  min="0.0"
                  maxlength="4"
                  size="4"
                  :max="orderTotal"
                  v-model="discountValue"
                />
              </div>
              <div
                class="button no-padding"
                :class="{ action: discountPercent }"
                @click.prevent="
                  discountPercent = true;
                  discountValue = 0.0;
                "
              >
                %
              </div>
              <div
                class="button no-padding"
                :class="{ action: !discountPercent }"
                @click.prevent="
                  discountPercent = false;
                  discountValue = 0.0;
                "
              >
                $
              </div>
            </div>
          </div>
        </div>
        <div class="payment-tab-single-item-bottom">
          <div class="payment-tab-single-item-bottom-item"></div>
          <div class="payment-tab-single-item-bottom-item">
            {{ $t("label.tip") }}:
            <div class="form-item">
              <input type="number" min="0.0" size="4" v-model="orderTip" />
            </div>
          </div>
        </div>
        <div class="payment-tab-single-item-bottom">
          <div class="payment-tab-single-item-bottom-item"></div>
          <div class="payment-tab-single-item-bottom-item">
            {{ $t("label.tax") }}:
            <div class="form-item">
              <input type="number" min="0.0" size="4" v-model="orderTax" />
            </div>
          </div>
        </div>
        <div class="payment-tab-single-item-bottom">
          <div class="payment-tab-single-item-bottom-item"></div>
          <div class="payment-tab-single-item-bottom-item">
            {{ $t("label.totalPrice") + ": " }}
            {{ formatPrice(checkoutTotal) }}
          </div>
        </div>
      </div>
      <div class="payment-submit">
        <div class="button cancel" @click="cancel">
          {{ $t("button.cancel") }}
        </div>
        <div class="button action" @click="payHandler">
          {{ $t("button.pay") }}
        </div>
      </div>
    </div>
    <div v-if="loadingPay" class="loading-payment">
      <div class="image"></div>
    </div>
  </div>
</template>

<script>
import PaymentsSingleProduct from "@/views/private/components/payments/PaymentsSingleProduct";
import PaymentsSingleEvent from "@/views/private/components/payments/PaymentsSingleEvent";
import PaymentsSinglePlan from "@/views/private/components/payments/PaymentsSinglePlan";
import { default as EventBus } from "@/services/eventbus";
import { HTTP } from "@/services/api";
import { CLAIM_TYPES_OPTIONS } from "@/store";
export default {
  name: "PaymentTabCheckout",
  components: {
    PaymentsSinglePlan,
    PaymentsSingleEvent,
    PaymentsSingleProduct,
  },
  props: {
    cartItems: {
      type: Array,
      default() {
        return [];
      },
    },
    doctors: {
      type: Array,
      default() {
        return [];
      },
    },
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    removeFromCheckout: {
      type: Function,
      default() {
        return false;
      },
    },
  },
  watch: {
    orderTip: function (val) {
      if (parseFloat(val) < 0) {
        this.orderTip = 0;
      }
    },
    orderTax: function (val) {
      if (parseFloat(val) < 0) {
        this.orderTax = 0;
      }
    },
    discountValue: function (val) {
      if (parseFloat(val) < 0) {
        this.discountValue = 0;
      }
      if (this.discountPercent && parseFloat(val) > 100) {
        this.discountValue = 100;
      }
      if (!this.discountPercent && parseFloat(val) > this.orderTotal) {
        this.discountValue = this.orderTotal;
      }
    },
  },
  data() {
    return {
      orderTip: 0.0,
      orderTax: 0.0,
      discountPercent: false,
      discountValue: 0.0,
      loadingPay: false,
      claimTypes: CLAIM_TYPES_OPTIONS,
    };
  },
  computed: {
    filteredCheckout() {
      return this.cartItems.filter(function (item) {
        return item.status === "in_checkout";
      });
    },
    checkoutTotal() {
      if (this.discountValue !== 0) {
        return (
          parseFloat(this.orderTotal ? this.orderTotal : 0) -
          (this.discountPercent
            ? (this.orderTotal * this.discountValue) / 100
            : this.discountValue) +
          parseFloat(this.orderTip ? this.orderTip : 0) +
          parseFloat(this.orderTax ? this.orderTax : 0)
        );
      }

      return (
        parseFloat(this.orderTotal ? this.orderTotal : 0) +
        parseFloat(this.orderTip ? this.orderTip : 0) +
        parseFloat(this.orderTax ? this.orderTax : 0)
      );
    },
    orderTotal() {
      let self = this;
      let total = 0;
      this.filteredCheckout.forEach(function (item) {
        if (
          item.item_type === "Appointment" &&
          item.item_data.soap_note &&
          item.item_data.soap_note.claim_type !==
            self.claimTypes.CLAIM_TYPE_SELF_PAY.value
        ) {
          let eventPrice = 0;
          item.item_data.soap_note.cpt_codes.forEach(function (item) {
            if (!item.include_in_insurance_claim) {
              eventPrice += parseFloat(item.price);
            }
          });

          total += eventPrice;
          return;
        }

        total +=
          parseFloat(item.price) * (item.quantity !== null ? item.quantity : 1);
      });
      return total;
    },
  },
  methods: {
    cancel() {
      this.$emit("set-active-tab", "events");
      this.$store.dispatch("removePaymentItem", []);
    },
    payHandler() {
      EventBus.$emit("open-modal", {
        type: "payment-method",
        patient: this.patient,
        mode: "order",
        checkoutTotal: this.checkoutTotal,
        callback: this.pay,
      });
    },
    pay(paymentOptions) {
      if (!this.patient) {
        return;
      }

      this.loadingPay = true;
      HTTP({
        method: "POST",
        url: "/api/v1/purchases/patients/" + this.patient.id + "/orders/",
        data: {
          order_items: this.filteredCheckout,
          discount:
            this.discountValue !== 0
              ? this.discountPercent
                ? (this.orderTotal * this.discountValue) / 100
                : this.discountValue
              : 0,
          tip: this.orderTip,
          tax: this.orderTax,
          payment_breakdown: paymentOptions.payment_breakdown
            ? paymentOptions.payment_breakdown
            : null,
        },
      })
        .then(() => {
          this.loadingPay = false;
          this.$emit("payment-status", "success");
          this.$emit("set-active-tab", "payment_overview");
          this.$store.dispatch("removePaymentItem", []);
          this.$emit("load-cart-items", true);
        })
        .catch((error) => {
          this.loadingPay = false;
          this.$emit("payment-status", "fail");
          this.$emit("set-active-tab", "payment_overview");
          this.$emit("load-cart-items", true);
          if (error.message) {
            this.$notify({
              group: "notification",
              type: "error",
              title: this.$t("notification.sadFace"),
              text: error.message,
            });
          }
        });
    },
    removeCheckoutItem(item) {
      if (!this.patient) {
        return;
      }

      HTTP({
        method: "PUT",
        url:
          "/api/v1/purchases/patients/" +
          this.patient.id +
          "/cart-items/" +
          item.id,
        data: {
          status: "active",
        },
      })
        .then(() => {
          this.$emit("load-cart-items", true);
        })
        .catch(() => {});
    },
  },
};
</script>
