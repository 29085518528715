<template>
  <div class="patients-form-tab-single">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.pastMedicalHistory") }}
      </div>

      <!--     past medical history-->
      <div class="form-item">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.pleaseCheckAllThatApply") }}
          </div>
          <table>
            <thead>
              <tr>
                <th style="width: 30%"></th>
                <th class="with-content">{{ $t("label.self") }}</th>
                <th class="with-content">{{ $t("label.family") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="disease in diseasesOptions" :key="disease.key">
                <td>{{ disease.label }}</td>
                <td
                  class="with-input"
                  @click.stop="clickCheckbox('self', 'family', disease.key)"
                  :class="{
                    checked: self.indexOf(disease.key) > -1,
                  }"
                >
                  <input
                    type="checkbox"
                    :checked="self.indexOf(disease.key) > -1"
                  />
                </td>
                <td
                  class="with-input"
                  @click.stop="clickCheckbox('family', 'self', disease.key)"
                  :class="{
                    checked: family.indexOf(disease.key) > -1,
                  }"
                >
                  <input
                    type="checkbox"
                    :checked="family.indexOf(disease.key) > -1"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--     other-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.otherListBelow") }}
          </div>
          <div class="form-item">
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.medical_history_other"
            />
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action margin-right-15" @click="prevTab()">
          {{ $t("button.previous") }}
        </div>
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import fileMixin from "@/mixins/fileMixin";
import autoResize from "@/mixins/autoResize";

export default {
  name: "PatientFormStep3",
  mixins: [fileMixin, autoResize],
  props: ["formData"],
  data: function () {
    return {
      formValues: {
        medical_history_other: "",
      },
      self: [],
      family: [],
      diseasesOptions: [
        {
          label: "Diabetes",
          key: "medical_history_diabetes",
        },
        {
          label: "Chest Pain/Angina",
          key: "medical_history_chest_pain_angina",
        },
        {
          label: "High Blood Pressure",
          key: "medical_history_high_blood_pressure",
        },
        {
          label: "Heart Disease",
          key: "medical_history_heart_disease",
        },
        {
          label: "High Cholesterol",
          key: "medical_history_high_cholesterol",
        },
        {
          label: "Pacemaker",
          key: "medical_history_pacemaker",
        },
        {
          label: "Headaches",
          key: "medical_history_headaches",
        },
        {
          label: "Kidney Stones",
          key: "medical_history_kidney_stones",
        },
        {
          label: "Cancer",
          key: "medical_history_cancer",
        },
        {
          label: "Osteoperosis",
          key: "medical_history_osteoporosis",
        },
        {
          label: "Asthma",
          key: "medical_history_asthma",
        },
        {
          label: "Stroke",
          key: "medical_history_stroke",
        },
        {
          label: "Seizure",
          key: "medical_history_seizure",
        },
        {
          label: "HIV/AIDS",
          key: "medical_history_hiv_aids",
        },
        {
          label: "Hepatitis",
          key: "medical_history_hepatitis",
        },
        {
          label: "Stomach Ulcer",
          key: "medical_history_stomach_ulcer",
        },
        {
          label: "Liver Disease",
          key: "medical_history_liver_disease",
        },
        {
          label: "Heart Palpitations",
          key: "medical_history_heart_palpitations",
        },
        {
          label: "Arthritis",
          key: "medical_history_arthritis",
        },
        {
          label: "Heart Surgery",
          key: "medical_history_heart_surgery",
        },
        {
          label: "Blood Clots",
          key: "medical_history_blood_clots",
        },
        {
          label: "Peripheral Vascular Disease",
          key: "medical_history_peripheral_vascular_disease",
        },
        {
          label: "Tuberculosis",
          key: "medical_history_tuberculosis",
        },
        {
          label: "Depression",
          key: "medical_history_depression",
        },
        {
          label: "Congestive Heart Failure",
          key: "medical_history_congestive_heart_failure",
        },
        {
          label: "Thyroid Disease",
          key: "medical_history_thyroid_disease",
        },
      ],
    };
  },
  validators: {},
  created() {
    let self = this;
    this.diseasesOptions.forEach(function (item) {
      self.formValues[item.key] = null;
    });
    if (this.formData) {
      this.mapData();
    }
  },
  mounted() {},
  methods: {
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    clickCheckbox(sourceArrayKey, otherArrayKey, val) {
      if (this[sourceArrayKey].indexOf(val) > -1) {
        let index = this[sourceArrayKey].indexOf(val);
        this[sourceArrayKey].splice(index, 1);
        this.formValues[val] =
          this[otherArrayKey].indexOf(val) > -1 ? otherArrayKey : null;
      } else {
        this[sourceArrayKey].push(val);
        this.formValues[val] =
          this[otherArrayKey].indexOf(val) > -1 ? "both" : sourceArrayKey;
      }
    },
    mapData() {
      const filtered = Object.keys(this.formData)
        .filter((key) => Object.keys(this.formValues).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.formData[key];
          return obj;
        }, {});

      this.formValues = filtered;
      this.self = Object.keys(this.formValues).filter(
        (key) =>
          this.formValues[key] === "self" || this.formValues[key] === "both"
      );
      this.family = Object.keys(this.formValues).filter(
        (key) =>
          this.formValues[key] === "family" || this.formValues[key] === "both"
      );
    },
    prevTab() {
      this.$emit("prev-tab", true);
    },
    nextTab() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.$emit("next-tab", true);
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.fillAllFieldsAndCheckValidity"),
          });
        }
      });
    },
  },
};
</script>
