<template>
  <div class="patients-form-consent">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.hipaaPrivacy") }}
      </div>

      <div class="form-item patients-form-consent-text">
        <input
          type="checkbox"
          id="checkbox_1"
          v-model="formValues.checkbox_1.value"
        />
        <label for="checkbox_1">{{ formValues.checkbox_1.text }}</label>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold">AUTHORIZATION</span>
      </div>

      <div class="form-item patients-form-consent-text">
        I <input type="text" v-model="formValues.name" />, authorize
        {{ clinicName }} to use and disclose the protected health (First and
        Last Name) information described below to the following person(s) or
        health care professional(s):
        <input type="text" v-model="formValues.professional_name" />
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold"
          >This authorization for the release of information covers the period
          of healthcare from:</span
        >
      </div>

      <div
        class="form-item double no-padding"
        v-if="formValues.past_dates.value === false"
      >
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.fromDate") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              use-date
              :original-start-date="formValues.from_date"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'from_date')"
            ></date-time-input>
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.toDate") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              use-date
              :index="1"
              :original-start-date="formValues.to_date"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'to_date')"
            ></date-time-input>
          </div>
        </div>
      </div>

      <div
        class="form-item patients-form-consent-text"
        v-if="formValues.past_dates.value === false"
      >
        <b>{{ $t("label.or") }}</b>
      </div>

      <div class="form-item patients-form-consent-text">
        <input
          type="checkbox"
          id="past_dates"
          v-model="formValues.past_dates.value"
        />
        <label for="past_dates">{{ formValues.past_dates.text }}</label>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold">EXTENT OF AUTHORIZATION</span>
      </div>

      <div
        class="form-item patients-form-consent-text"
        v-if="formValues.authorize_2.value === false"
      >
        <input
          type="checkbox"
          id="authorize_1"
          v-model="formValues.authorize_1.value"
        />
        <label for="authorize_1">{{ formValues.authorize_1.text }}</label>
      </div>

      <div
        class="form-item patients-form-consent-text"
        v-if="formValues.authorize_2.value === false"
      >
        <b>{{ $t("label.or") }}</b>
      </div>

      <div class="form-item patients-form-consent-text">
        <input
          type="checkbox"
          id="authorize_2"
          v-model="formValues.authorize_2.value"
        />
        <label for="authorize_2">{{ formValues.authorize_2.text }}</label>
      </div>

      <div class="form-item" v-if="formValues.authorize_2.value">
        <div
          class="input-options"
          :key="'exception_input_' + index"
          v-for="(option, index) in exceptionOptions"
        >
          <input
            type="radio"
            :id="'exception_' + index"
            :value="option"
            v-model="formValues.exception"
          />
          <label class="radio" :for="'exception_' + index">{{ option }}</label>
        </div>
        <div class="input-options">
          <input
            type="radio"
            :key="'exception_input_other'"
            :id="'exception_other'"
            :value="'Other'"
            v-show="exceptionOptions.indexOf(formValues.exception) > -1"
            v-model="formValues.exception"
          />
          <label
            class="radio"
            :for="'exception_other'"
            :key="'exception_other'"
            v-show="exceptionOptions.indexOf(formValues.exception) > -1"
            >{{ $t("label.other") }}</label
          >
          <input
            type="text"
            ref="exceptionOtherInput"
            v-model="formValues.exception"
            v-if="exceptionOptions.indexOf(formValues.exception) === -1"
          />
        </div>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold">EXPIRATION</span>
      </div>

      <div class="form-item patients-form-consent-text">
        Unless sooner revoked, this authorization expires one year from the date
        this authorization is signed, or as otherwise indicated here:
      </div>

      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.fromDate") }}
          </div>
          <div class="form-item">
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              use-date
              :index="2"
              :original-start-date="formValues.expiration_date"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setDate($event, 'expiration_date')"
            ></date-time-input>
          </div>
        </div>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold">REVOCATION</span>
      </div>

      <div
        class="form-item patients-form-consent-text"
        :class="{
          error: this.hasError('revocation_1.value'),
        }"
      >
        <input
          type="checkbox"
          id="revocation_1"
          v-model="formValues.revocation_1.value"
        />
        <label for="revocation_1">{{ formValues.revocation_1.text }}</label>
        <span class="required">*</span>
      </div>

      <div
        class="form-item patients-form-consent-text"
        :class="{
          error: this.hasError('revocation_2.value'),
        }"
      >
        <input
          type="checkbox"
          id="revocation_2"
          v-model="formValues.revocation_2.value"
        />
        <label for="revocation_2">{{ formValues.revocation_2.text }}</label>
        <span class="required">*</span>
      </div>

      <div
        class="form-item patients-form-consent-text"
        :class="{
          error: this.hasError('revocation_3.value'),
        }"
      >
        <input
          type="checkbox"
          id="revocation_3"
          v-model="formValues.revocation_3.value"
        />
        <label for="revocation_3">{{ formValues.revocation_3.text }}</label>
        <span class="required">*</span>
      </div>

      <div
        class="form-item patients-form-consent-text"
        :class="{
          error: this.hasError('revocation_4.value'),
        }"
      >
        <input
          type="checkbox"
          id="revocation_4"
          v-model="formValues.revocation_4.value"
        />
        <label for="revocation_4">{{ formValues.revocation_4.text }}</label>
        <span class="required">*</span>
      </div>

      <!--      signature -->
      <div class="form-item double">
        <div class="form-item-section">
          <div class="form-item-section-title with-icon">
            {{ $t("label.signature") }}
            <div class="remove" @click.prevent.stop="clearSignature">
              <div class="icon icon-trash"></div>
            </div>
          </div>
          <div class="signature-wrapper">
            <canvas
              id="signature-pad"
              class="signature-pad"
              width="400"
              height="200"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
import SignaturePad from "signature_pad";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import { HTTP_PUBLIC } from "@/services/api";
import fileMixin from "@/mixins/fileMixin";
import formMixin from "@/mixins/formMixin";

export default {
  name: "PatientConsentFormHipaa",
  mixins: [fileMixin, formMixin],
  components: { DateTimeInput },
  watch: {
    "formValues.authorize_2": function (val) {
      if (val) {
        this.formValues.exception = this.exceptionOptions[0];
      } else {
        this.formValues.exception = null;
      }
    },
  },
  data: function () {
    return {
      clinicName: "Klinika",
      formValues: {
        checkbox_1: {
          value: false,
          text: "There are no other affiliated parties that I require to have access to my health information",
        },
        name: null,
        professional_name: null,
        authorize_1: {
          value: false,
          text:
            "I authorize the release of my complete health record (including records relating to mental healthcare, " +
            "communicable diseases, HIV or AIDS, and the treatment of alcohol or drug abuse).",
        },
        authorize_2: {
          value: false,
          text: "I authorize the release of my complete health record with the exception of the following information:",
        },
        insurance_signature_image: null,
        revocation_1: {
          value: false,
          text:
            "I understand that I have the right to revoke this authorization, in" +
            "        writing, at any time by sending written notice to " +
            this.clinicName +
            "        at 603-B West Patrick Street, Frederick, MD 21701. I" +
            "        understand that a revocation is not effective to the extent of any" +
            "        person or entity that has already acted in reliance on my authorization" +
            "        or if my authorization was acquired as a condition of obtaining" +
            "        insurance coverage and the insurer has a legal right to contest a" +
            "        claim.",
        },
        revocation_2: {
          value: false,
          text:
            "        This medical information may be used by the person(s) or health care" +
            "        professional(s) I authorize to receive this information for medical" +
            "        treatment or consultation, billing or claims payment, or other purposes" +
            "        as I may direct.",
        },
        revocation_3: {
          value: false,
          text:
            " I understand that my treatment, payment, enrollment, or eligibility for" +
            "        benefits will not be conditioned on whether I sign this authorization" +
            "        form.",
        },
        revocation_4: {
          value: null,
          text:
            "I understand that protected health information (PHI) used or disclosed" +
            "        pursuant to this authorization may be redisclosed by the recipient and" +
            "        may no longer be protected by federal or state law privacy" +
            "        regulations.",
        },
        past_dates: {
          value: false,
          text: "All past, present and future periods",
        },
        from_date: null,
        to_date: null,
        exception: null,
        expiration_date: null,
      },
      exceptionOptions: [
        "Mental Health Records",
        "Communicable diseases (including HIV and AIDS)",
        "Alcohol/drug abuse treatment",
      ],
      signaturePad: null,
      canvas: null,
    };
  },
  validators: {
    "formValues.revocation_1.value": function (value) {
      return Validator.value(value).required().in([true]);
    },
    "formValues.revocation_2.value": function (value) {
      return Validator.value(value).required().in([true]);
    },
    "formValues.revocation_3.value": function (value) {
      return Validator.value(value).required().in([true]);
    },
    "formValues.revocation_4.value": function (value) {
      return Validator.value(value).required().in([true]);
    },
  },
  created() {
    this.getClinicInfo();
    this.checkNextStep(this.$route.params.token);
  },
  mounted() {
    this.canvas = document.getElementById("signature-pad");
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });
  },
  methods: {
    getClinicInfo() {
      HTTP_PUBLIC({
        method: "GET",
        url: "/api/v1/patient-records/" + this.$route.params.token + "/data",
      })
        .then((response) => {
          this.clinicName = response.data.clinic_name
            ? response.data.clinic_name
            : "";
        })
        .catch(() => {});
    },
    resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    },
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    setDate(value, key) {
      this.formValues[key] = value;
    },
    clearSignature() {
      this.formValues.insurance_signature_image = null;
      this.signaturePad.clear();
      let self = this;
      this.$nextTick(() => {
        self.resizeCanvas();
      });
    },
    nextTab() {
      if (this.signaturePad.isEmpty()) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.signAndFillRequired"),
        });
        return;
      }

      let self = this;
      this.$validate().then(function (success) {
        if (success) {
          self.formValues.insurance_signature_image =
            self.signaturePad.toDataURL("image/jpg");

          let formData = self.prepareConsentForm(self.formValues);

          HTTP_PUBLIC({
            method: "POST",
            url:
              "/api/v1/patient-records/" +
              self.$route.params.token +
              "/consents",
            data: {
              contract_key: "hipaa",
              additional_data: formData,
            },
          })
            .then(() => {
              self.checkNextStep(self.$route.params.token);
            })
            .catch();
        } else {
          self.$notify({
            group: "notification",
            type: "warning",
            title: self.$t("notification.warning"),
            text: self.$t("notification.fillAllFields"),
          });
        }
      });
    },
  },
};
</script>
