import Login from "@/views/public/pages/Login.vue";
import Forbidden from "@/views/public/pages/Forbidden.vue";
import UserVerification from "@/views/public/pages/UserVerification.vue";
import PasswordResetForm from "@/views/public/pages/PasswordResetForm";
import PasswordResetEmail from "@/views/public/pages/PasswordResetEmail";
import DoctorsLogin from "@/views/public/pages/doctor/DoctorsLogin";
import DoctorsPasswordResetForm from "@/views/public/pages/doctor/PasswordResetForm";
import DoctorsPasswordResetEmail from "@/views/public/pages/doctor/PasswordResetEmail";
import PatientForm from "@/views/public/pages/PatientForm";
import PatientConsentInformed from "@/views/public/pages/PatientConsentInformed";
import PatientConsentFinancial from "@/views/public/pages/PatientConsentFinancial";
import PatientConsentPrivacy from "@/views/public/pages/PatientConsentPrivacy";
import PatientConsentHipaa from "@/views/public/pages/PatientConsentHipaa";
import PatientConsentThankYou from "@/views/public/pages/PatientConsentThankYou";
import PatientVerify from "@/views/public/pages/patient/PatientVerify";
import PatientResetPassword from "@/views/public/pages/patient/PatientResetPassword";
import PatientPasswordResetSuccess from "@/views/public/pages/patient/PatientPasswordResetSuccess";
import PatientForgotPassword from "@/views/public/pages/patient/PatientForgotPassword";
import PatientLogout from "@/views/public/pages/patient/PatientLogout";
import PatientPage from "@/views/public/pages/patient/PatientPage.vue";

export default [
  {
    path: "",
    name: "publicHome",
    redirect: "login",
  },
  {
    path: "login",
    name: "login",
    component: Login,
  },
  {
    path: "/doctors/login",
    name: "doctorsLogin",
    component: DoctorsLogin,
  },
  {
    path: "doctors/password/reset",
    name: "doctorsPasswordReset",
    component: DoctorsPasswordResetEmail,
  },
  {
    path: "doctors/password/reset/:token",
    name: "doctorsPasswordResetConfirm",
    component: DoctorsPasswordResetForm,
  },
  {
    path: "password/reset",
    name: "passwordReset",
    component: PasswordResetEmail,
  },
  {
    path: "password/reset/:token",
    name: "passwordResetConfirm",
    component: PasswordResetForm,
  },
  {
    path: "signup-verification/:token",
    name: "userVerification",
    component: UserVerification,
  },
  {
    path: "patient/verify/:token?",
    name: "patientVerify",
    component: PatientVerify,
    meta: {
      title: "Patient Verify",
    },
  },
  {
    path: "patient/set-password/:token?",
    name: "patientSetPassword",
    component: PatientVerify,
    meta: {
      title: "Patient Set Password",
    },
  },
  {
    path: "patient-forms/:token",
    name: "patientForms",
    component: PatientForm,
    meta: {
      title: "Patient Form",
    },
  },
  {
    path: "informed-consent/:token",
    name: "patientConsentInformed",
    component: PatientConsentInformed,
    meta: {
      title: "Informed consent",
    },
  },
  {
    path: "financial-policy/:token",
    name: "patientConsentFinancial",
    component: PatientConsentFinancial,
    meta: {
      title: "Financial policy",
    },
  },
  {
    path: "privacy-practices/:token",
    name: "patientConsentPrivacy",
    component: PatientConsentPrivacy,
    meta: {
      title: "Privacy practices",
    },
  },
  {
    path: "hipaa/:token",
    name: "patientConsentHipaa",
    component: PatientConsentHipaa,
    meta: {
      title: "HIPAA",
    },
  },
  {
    path: "thank-you",
    name: "patientConsentThankYou",
    component: PatientConsentThankYou,
    meta: {
      title: "Thank you",
    },
  },
  {
    path: "forbidden",
    name: "forbidden",
    component: Forbidden,
  },
  {
    path: "/patient/logout", //TODO remove as soon as logout flow is provided
    name: "patientLogout",
    component: PatientLogout,
  },
  {
    path: "/:clinic_slug/login",
    name: "patientLogin",
    component: PatientPage,
  },
  {
    path: "/:clinic_slug/password/reset/:token",
    name: "patientResetPassword",
    component: PatientResetPassword,
    meta: {
      title: "Patient Reset Password",
    },
  },
  {
    path: "/:clinic_slug/password-reset-success/",
    name: "patientPasswordResetSuccess",
    component: PatientPasswordResetSuccess,
    meta: {
      title: "Patient Reset Password Success",
    },
  },
  {
    path: "/:clinic_slug/forgot-password",
    name: "patientForgotPassword",
    component: PatientForgotPassword,
    meta: {
      title: "Patient Forgot Password",
    },
  },
  {
    path: "/:clinic_slug/register",
    name: "patientRegister",
    component: PatientPage,
  },
  {
    path: "*",
    redirect: "login",
  },
];
