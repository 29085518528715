<template>
  <div class="modal remove" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div class="icon">
              <item-icon type="delete" :class-name="'color-red'"></item-icon>
            </div>

            <div class="title">
              {{
                $t("message.youAreAboutToDelete", {
                  item: $tc("label." + labels[type], 1).toLowerCase(),
                })
              }}
            </div>
            <div class="sub-title">
              This will delete
              {{
                type === "doctor" || type === "user"
                  ? item.first_name + " " + item.last_name
                  : item.name
              }}
              permanentlly. <br />Are you sure?
            </div>
            <div class="action">
              <a class="button cancel" @click.prevent="closeModal">
                {{ $t("button.cancel") }}
              </a>
              <a class="button delete" @click.prevent="remove">
                {{ $t("button.delete") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";

export default {
  name: "RemoveItem",
  props: ["type", "item"],
  components: {
    ItemIcon,
  },
  data() {
    return {
      labels: {
        doctor: "provider",
        room: "room",
        clinic: "clinic",
        user: "user",
        treatment: "treatment",
        plan: "plan",
        charge: "charge",
        pos: "posTerminal",
        insuranceWallet: "insuranceWallet",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    remove() {
      let self = this;
      let url = "/api/v1";
      if (self.type === "doctor") {
        url += "/doctors/";
      }
      if (self.type === "plan") {
        url += "/plans/";
      }
      if (self.type === "charge") {
        url += "/cpt-codes/";
      }
      if (self.type === "room") {
        url += "/rooms/";
      }
      if (self.type === "pos") {
        url += "/poi-devices/";
      }
      if (self.type === "treatment") {
        url += "/treatments/";
      }
      if (self.type === "clinic") {
        url += "/clinics/";
      }
      if (self.type === "user") {
        url += "/users/";
      }
      if (self.type === "insuranceWallet") {
        url += "/purchases/insurance-wallet/";
      }
      url += self.item.id;
      HTTP({
        method: "DELETE",
        url: url,
        data: {},
      })
        .then(() => {
          self.$notify({
            group: "notification",
            type: "success",
            title: self.$t("notification.thumbsUp"),
            text: self.$t("notification.successDelete"),
          });
          if (self.type === "doctor") {
            EventBus.$emit("doctors-load");
          }
          if (self.type === "room") {
            EventBus.$emit("rooms-load");
          }
          if (self.type === "plan") {
            EventBus.$emit("plans-load");
          }
          if (self.type === "charge") {
            EventBus.$emit("charges-load");
          }
          if (self.type === "treatment") {
            EventBus.$emit("treatments-load");
          }
          if (self.type === "pos") {
            EventBus.$emit("pos-terminals-load");
          }
          if (self.type === "clinic") {
            EventBus.$emit("clinics-load");
          }
          if (self.type === "user") {
            EventBus.$emit("users-load");
          }
          if (self.type === "insuranceWallet") {
            EventBus.$emit("insurance-wallets-load");
          }
          self.closeModal();
        })
        .catch(() => {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.errorDelete"),
          });
        });
    },
  },
};
</script>
