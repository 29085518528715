<template>
  <div class="payment-tab-single-item special">
    <div class="payment-tab-single-item-top">
      <div class="payment-tab-single-item-top-info">
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.cart_item_data.item_data.name"
        >
          {{ $t("label.name") }}:
          <span class="value">{{ item.cart_item_data.item_data.name }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.cart_item_data.item_type === 'Appointment'"
        >
          {{ $t("label.date") }}:
          <span class="value">{{
            customFormat(item.cart_item_data.item_data.start, "MM / dd / yyyy")
          }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item" v-if="item.status">
          {{ $t("label.status") }}:
          <span class="value">{{ item.status }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.product_sold_by_doctor"
        >
          {{ $t("label.soldByDoctor") }}:
          <span class="value">
            {{
              item.product_sold_by_doctor.first_name +
              " " +
              item.product_sold_by_doctor.last_name
            }}</span
          >
        </div>
        <div class="payment-tab-single-item-top-info-item" v-else>
          {{ $t("label.soldByDoctor") }}:
          <span class="value">{{ $t("label.generalClinic") }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.price") }}:
          <span class="value">{{
            formatPrice(item.cart_item_data.price)
          }}</span>
        </div>
        <template v-if="item.cart_item_data.item_data.soap_note">
          <payment-claim-label
            :soap-note="item.cart_item_data.item_data.soap_note"
          />
        </template>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.cart_item_data.item_type === 'Plan'"
        >
          {{ $t("label.appointmentsNumber") }}:
          <span class="value">{{
            item.cart_item_data.item_data.appointments_number
          }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.cart_item_data.item_type === 'Plan'"
        >
          {{ $t("label.daysValid") }}:
          <span class="value">{{
            item.cart_item_data.item_data.days_valid
          }}</span>
        </div>
        <div
          class="payment-tab-single-item-top-info-item"
          v-if="item.cart_item_data.item_type === 'Product'"
        >
          {{ $t("label.qty") }}:
          <span class="value">{{ item.cart_item_data.quantity }}</span>
        </div>
      </div>
      <div class="payment-tab-single-item-top-action">
        <div v-if="item.cart_item_data.item_type === 'Product'">
          {{ $tc("label.product", 1).toUpperCase() }}
        </div>
        <div v-else-if="item.cart_item_data.item_type === 'Plan'">
          {{ $tc("label.plan", 1).toUpperCase() }}
        </div>
        <div v-else-if="item.cart_item_data.item_type === 'Appointment'">
          {{ $tc("label.appointment", 1).toUpperCase() }}
        </div>
      </div>
    </div>
    <div class="payment-tab-single-item-bottom justify-end">
      <payment-event-codes
        :soap-note="item.cart_item_data.item_data.soap_note"
      />
    </div>
    <div class="payment-tab-single-item-bottom total">
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.coveredByPatient") + ": " }}
        {{ formatPrice(totalPatient) }}
      </div>
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.coveredByInsurance") + ": " }}
        {{ formatPrice(totalInsurance) }}
      </div>
    </div>
  </div>
</template>

<script>
import { CLAIM_TYPES_OPTIONS } from "@/store";
import PaymentClaimLabel from "@/views/private/components/payments/PaymentClaimLabel";
import PaymentEventCodes from "@/views/private/components/payments/PaymentEventCodes";
export default {
  name: "PaymentsSingleItemOverview",
  components: { PaymentEventCodes, PaymentClaimLabel },
  props: {
    patient: {
      type: Object,
      default: () => {
        return {};
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      claimTypes: CLAIM_TYPES_OPTIONS,
    };
  },
  computed: {
    totalPatient: function () {
      let total = 0;
      let self = this;
      if (
        this.item.cart_item_data.item_type === "Appointment" &&
        this.item.cart_item_data.item_data.soap_note &&
        this.item.cart_item_data.item_data.soap_note.cpt_codes
      ) {
        this.item.cart_item_data.item_data.soap_note.cpt_codes.forEach(
          function (item) {
            if (
              self.item.cart_item_data.item_data.soap_note.claim_type ===
              self.claimTypes.CLAIM_TYPE_SELF_PAY.value
            ) {
              total += parseFloat(item.self_pay_price);
            }

            if (
              self.item.cart_item_data.item_data.soap_note.claim_type !==
                self.claimTypes.CLAIM_TYPE_SELF_PAY.value &&
              !item.include_in_insurance_claim
            ) {
              total += parseFloat(item.price);
            }
          }
        );
      }
      if (this.item.cart_item_data.item_type === "Product") {
        total +=
          this.item.cart_item_data.price * this.item.cart_item_data.quantity;
      }

      return total;
    },
    totalInsurance: function () {
      let total = 0;
      let self = this;
      if (
        this.item.cart_item_data.item_data.soap_note &&
        this.item.cart_item_data.item_data.soap_note.cpt_codes
      ) {
        this.item.cart_item_data.item_data.soap_note.cpt_codes.forEach(
          function (item) {
            if (
              self.item.cart_item_data.item_data.soap_note.claim_type !==
                self.claimTypes.CLAIM_TYPE_SELF_PAY.value &&
              item.include_in_insurance_claim
            ) {
              total += parseFloat(item.price);
            }
          }
        );
      }
      return total;
    },
  },
};
</script>
