<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <search mode="patients"></search>
    </div>
  </div>
</template>

<script>
import Search from "@/views/private/components/Search";

export default {
  name: "PatientsSidebar",
  components: { Search },
  data: () => ({}),
  methods: {},
};
</script>
