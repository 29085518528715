<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.edit") +
                            " " +
                            $tc("label.charge", 1).toLowerCase()
                          : $t("label.addNew") +
                            " " +
                            $tc("label.charge", 1).toLowerCase()
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newCharge.name'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <input ref="name" v-model="newCharge.name" type="text" />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newCharge.code'),
                    }"
                  >
                    <label class="item"
                      >{{
                        $tc("label.charge", 1) + " " + $tc("label.code", 1)
                      }}:
                    </label>
                    <input
                      ref="chargeCode"
                      v-model="newCharge.code"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newCharge.price'),
                    }"
                  >
                    <label class="item">{{ $t("label.price") }}: </label>
                    <input
                      ref="price"
                      v-model="newCharge.price"
                      type="number"
                      min="0.0"
                      step="1.0"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newCharge.self_pay_price'),
                    }"
                  >
                    <label class="item">{{ $t("label.selfPayPrice") }}: </label>
                    <input
                      ref="selfPayPrice"
                      v-model="newCharge.self_pay_price"
                      type="number"
                      min="0.0"
                      step="1.0"
                    />
                  </div>
                  <form-input-checkbox-toggle
                    :label="$t('label.includeInClaim')"
                    :model-value="newCharge.include_in_insurance_claim"
                    @update:modelValue="
                      (value) => (newCharge.include_in_insurance_claim = value)
                    "
                  />
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="saveCharge()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";
import FormInputCheckboxToggle from "@/views/shared/forms/FormInputCheckboxToggle";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "ChargeCreate",
  components: { FormInputCheckboxToggle },
  validators: {
    "newCharge.name": function (value) {
      return Validator.value(value).required();
    },
    "newCharge.code": function (value) {
      return Validator.value(value).required();
    },
    "newCharge.price": function (value) {
      return Validator.value(value).required().greaterThanOrEqualTo(0).float();
    },
    "newCharge.self_pay_price": function (value) {
      return Validator.value(value).required().greaterThanOrEqualTo(0).float();
    },
  },
  data() {
    return {
      newCharge: {
        name: null,
        code: null,
        price: null,
        self_pay_price: null,
        include_in_insurance_claim: true,
      },
      treatments: [],
      loading: false,
      cancelToken: { load: null },
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadCharge();
      }
    },
    isModeEdit() {
      return "chargeEdit" === this.$route.name;
    },
    loadCharge() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/cpt-codes/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newCharge = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveCharge() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/cpt-codes/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newCharge);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                EventBus.$emit("charges-load");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
