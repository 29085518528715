<template>
  <div class="patients-form-consent">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.noticeOfPrivacy") }}
        <div class="subtitle">
          {{ $t("label.noticeOfPrivacySub") }}
        </div>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline">WHO WILL FOLLOW THIS NOTICE</span>
      </div>

      <div class="form-item patients-form-consent-text">
        We care about our patient’s privacy and strive to protect the
        confidentiality of your medical information at this practice. Current
        federal legislation requires that we issue this official notice of our
        privacy practices. You have the right to the confidentiality of your
        medical information and we respect our legal obligation to keep health
        information that identifies you private. This practice (all medical
        professionals, all employees, staff and other personnel, subsidiaries
        and business associates (e.g. software program company)) are required to
        abide by the terms of the Notice Of Privacy Practices currently in
        effect, and to provide notice of its legal duties and privacy practices
        with respect to protected health information. We reserve the right to
        change this notice at any time as allowed by law. If we change the
        Notice, the new privacy practices will apply to your health information
        that we already have as well as to such information that we may generate
        in the future. If we change our Notice of Privacy Practices, we will
        post information about the change in our office, have copies available
        in our office, and post it on our Website. This Notice describes how we
        protect your health information and what rights you have regarding it.
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >TREATMENT, PAYMENT, AND HEALTH CARE OPERATIONS</span
        >
      </div>

      <div class="form-item patients-form-consent-text">
        The most common reason why we use or disclose your health information is
        for treatment, payment or health care operations. Examples of how we use
        or disclose information for treatment purposes are (if applicable):
        allergies you may have to certain materials, herbs or supplements,
        setting up an appointment for you, calling to remind you of an
        appointment, prescribing herbal supplements, developing treatment plans,
        or referring you to another doctor, practitioner or clinic for services.
        Examples of how we use or disclose your health information for payment
        purposes are: so that the treatment and services you receive from us may
        be billed and payment may be collected from you; an insurance company or
        a third party or inquiring about your health care plan(s). “Health care
        operations” mean those administrative and managerial functions that we
        must do to run our office. Examples of how we use or disclose your
        health information for health care operations are: financial or billing
        audits; internal quality assurance; personnel decisions; participation
        in managed care plans; defense of legal matters; business planning; and
        outside storage of our records.
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >APPOINTMENT REMINDERS, TREATMENT ALTERNATIVES AND HEALTH RELATED
          BENEFITS AND SERVICES.</span
        >
      </div>

      <div class="form-item patients-form-consent-text">
        We may contact you to provide appointment reminders or information about
        treatment alternatives or other health-related benefits and services
        that may be of interest to you. We may also leave voicemail messages,
        emails or text messages unless indicated otherwise.
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >INDIVIDUALS INVOLVED IN YOUR CARE OR PAYMENT FOR YOUR CARE.
        </span>
        When appropriate, we may share Health Information with a person who is
        involved in your medical care or payment for your care, such as your
        family member or close friend. Proper authorization for release of
        information to persons other than the patient or getting copies of your
        health information from another professional that you may have seen
        before us will be given by written consent from the patient.
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline">RESEARCH.</span>
      </div>

      <div class="form-item patients-form-consent-text">
        Under certain circumstances, we may use and disclose Health Information
        for research. For example, a research project may involve comparing the
        health of patients who received one treatment to those who received
        another, for the same condition. In this situation, your name will not
        be used without permission, only the results of the treatment.
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >OTHER USES OR DISCLOSURES THAT CAN BE MADE WITHOUT CONSENT OR
          AUTHORIZATION</span
        >
      </div>

      <div class="form-item patients-form-consent-text">
        In some limited situations, the law allows or requires us to use or
        disclose your health information without your permission. Not all of
        these situations will apply; some may never come up at our office at
        all. Such uses or disclosures are:
        <ul>
          <li>
            when a state or federal law mandates that certain health information
            be reported for a specific purpose;
          </li>
          <li>
            for public health purposes, such as contagious disease reporting,
            investigation or surveillance; and notices to and from the federal
            Food and Drug Administration regarding drugs or medical devices;
          </li>
          <li>
            disclosures to governmental authorities about victims of suspected
            abuse, neglect or domestic violence;
          </li>
          <li>
            uses and disclosures for health oversight activities, such as for
            the licensing of doctors; for audits by Medicare or Medicaid if
            applicable; or for investigation of possible violations of health
            care laws;
          </li>
          <li>
            disclosures for judicial and administrative proceedings, such as in
            response to subpoenas or orders of courts or administrative
            agencies;
          </li>
          <li>
            disclosures for law enforcement purposes, such as to provide
            information about someone who is or is suspected to be a victim of a
            crime; to provide information about a crime at our office; or to
            report a crime that happened somewhere else;
          </li>
          <li>
            disclosure to a medical examiner to identify a dead person or to
            determine the cause of death; or to funeral directors to aid in
            burial; or to organizations that handle organ or tissue donations;
          </li>
          <li>uses or disclosures for health-related research;</li>
          <li>
            uses and disclosures to prevent a serious threat to health or
            safety;
          </li>
          <li>
            uses or disclosures for specialized government functions, such as
            for the protection of the president or high-ranking government
            officials; for lawful national intelligence activities; for military
            purposes; or for the evaluation and health of members of the foreign
            service;
          </li>
          <li>disclosures of de-identified information;</li>
          <li>
            disclosures relating to worker’s compensation programs; disclosures
            of a “limited data set” for research, public health, or health care
            operations;
          </li>
          <li>
            incidental disclosures that are an unavoidable by-product of
            permitted uses or disclosures;
          </li>
          <li>
            disclosures to business associates (Ex: software company) who
            perform health care operations for us and who commit to respect the
            privacy of your health information
          </li>
        </ul>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline"
          >YOUR RIGHTS REGARDING YOUR HEALTH INFORMATION
        </span>
      </div>

      <div class="form-item patients-form-consent-text">
        The law gives you many rights regarding your health information. You are
        welcome to request, in writing; a restriction; amendment or adjustment
        to the use or disclosure of your information. All written requests
        should be sent to the Privacy Officer and address listed at the
        beginning of this Notice. You have the right to:
        <ul>
          <li>
            ask us to restrict our uses and disclosures for purposes of
            treatment, payment or health care operations
          </li>
          <li>
            ask us to communicate with you in a confidential way, such as
            phoning at work rather than home, by mailing health information to a
            different address, or via email to your personal email address
          </li>
          <li>
            ask to see or obtain photocopies of your health information. You
            will be able to review your information within 5 business days of
            your written request, once received and/or receive a copy of your
            health information within 15 business days of your written request,
            once received
          </li>
          <li>
            ask us to amend your health information if you think that it is
            incorrect or incomplete. If we agree, we will amend the information
            within 60 days from the date the written request was received.
          </li>
          <li>
            get a list of all disclosures that we have made within the last six
            years (or any shorter period).
          </li>
          <li>
            get additional paper copies of this Notice of Privacy Practices upon
            request.
          </li>
        </ul>
      </div>

      <div class="form-item patients-form-consent-text">
        <span class="bold underline">COMPLAINTS </span>
        If you think that we have not properly respected the privacy of your
        health information, you are free to complain to us or the U.S.
        Department of Health and Human Services, Office for Civil Rights. We
        will not retaliate against you if you make a complaint. If you want to
        complain to us, please send a written complaint to the Privacy Officer
        at the address shown at the beginning of this Notice. If you prefer, you
        can discuss your complaint in person or by phone.
      </div>

      <div
        class="form-item patients-form-consent-text"
        :class="{
          error: this.hasError('signature_authorizes.value'),
        }"
      >
        <input
          type="checkbox"
          id="signature_authorizes"
          v-model="formValues.signature_authorizes.value"
        />
        <label for="signature_authorizes">{{
          formValues.signature_authorizes.text
        }}</label>
        <span class="required">*</span>
      </div>

      <!--      signature -->
      <div class="form-item double">
        <div class="form-item-section">
          <div class="form-item-section-title with-icon">
            {{ $t("label.signature") }}
            <div class="remove" @click.prevent.stop="clearSignature">
              <div class="icon icon-trash"></div>
            </div>
          </div>
          <div class="signature-wrapper">
            <canvas
              id="signature-pad"
              class="signature-pad"
              width="400"
              height="200"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
import SignaturePad from "signature_pad";
import { HTTP_PUBLIC } from "@/services/api";
import fileMixin from "@/mixins/fileMixin";
import formMixin from "@/mixins/formMixin";

export default {
  name: "PatientConsentFormPrivacy",
  mixins: [fileMixin, formMixin],
  watch: {},
  data: function () {
    return {
      formValues: {
        insurance_signature_image: null,
        signature_authorizes: {
          value: null,
          text:
            "I have been presented with the Notice of Privacy regarding my" +
            "        individually identifiable protected health information (PHI). I consent" +
            "        to the use and disclosure of my PHI for purposes of treatment, payment" +
            "        or other health care operations. Other uses of my PHI will require an" +
            "        authorization from me for the specific intention of disclosure. Thank" +
            "        you for your continued confidence in our practice and for supporting our" +
            "        requirements.Practices explaining my rights",
        },
      },
      signaturePad: null,
      canvas: null,
    };
  },
  validators: {
    "formValues.signature_authorizes.value": function (value) {
      return Validator.value(value).required().in([true]);
    },
  },
  created() {
    this.checkNextStep(this.$route.params.token);
  },
  mounted() {
    this.canvas = document.getElementById("signature-pad");
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });
  },
  methods: {
    resizeCanvas() {
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    },
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    clearSignature() {
      this.formValues.insurance_signature_image = null;
      this.signaturePad.clear();
      let self = this;
      this.$nextTick(() => {
        self.resizeCanvas();
      });
    },
    nextTab() {
      if (this.signaturePad.isEmpty()) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.signAndFillRequired"),
        });
        return;
      }

      let self = this;
      this.$validate().then(function (success) {
        if (success) {
          self.formValues.insurance_signature_image =
            self.signaturePad.toDataURL("image/jpg");

          let formData = self.prepareConsentForm(self.formValues);

          HTTP_PUBLIC({
            method: "POST",
            url:
              "/api/v1/patient-records/" +
              self.$route.params.token +
              "/consents",
            data: {
              contract_key: "privacy_practices",
              additional_data: formData,
            },
          })
            .then(() => {
              self.checkNextStep(self.$route.params.token);
            })
            .catch();
        } else {
          self.$notify({
            group: "notification",
            type: "warning",
            title: self.$t("notification.warning"),
            text: self.$t("notification.fillAllFields"),
          });
        }
      });
    },
  },
};
</script>
