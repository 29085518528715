<template>
  <div
    v-bind="$attrs"
    :class="{
      'list-items': true,
      dropping: isDropping,
    }"
    @dragover.prevent="this.$emit('update:isDropping', true)"
    @dragleave.prevent="this.$emit('update:isDropping', false)"
    @drop.stop.prevent="this.$emit('handle-file-drop', $event)"
  >
    <div class="list-items-header">
      <header-sort
        sortKey="documents"
        :sortArray="[
          {
            label: 'Name',
            direction: 'asc',
            key: 'name',
          },
          {
            label: 'Modified',
            direction: 'asc',
            key: 'created_at',
            width: '275px',
          },
        ]"
      ></header-sort>
    </div>
    <div class="list-items-section">
      <div class="list-items-section-content">
        <!-- level up-->
        <div
          class="documents-main-inner-files-section-item transparent"
          v-if="
            $store.state.documents.currentPath !== '/' &&
            $store.state.documents.selectedCampaign === null
          "
          @click="this.$emit('handle-folder-up-click')"
        >
          <div class="documents-main-inner-files-section-item-name">
            <div class="icon-folder up"></div>
            ...
          </div>
        </div>

        <!-- folders-->
        <div
          :class="{
            'documents-main-inner-files-section-item': true,
            active: isCurrentActive('folder', folder),
          }"
          v-for="folder in folders"
          :key="'folder_' + folder.id"
          @click="this.$emit('handle-folder-click', folder)"
        >
          <drag
            class="documents-main-inner-files-section-item-name"
            :transfer-data="{ folder: folder }"
          >
            <div
              :class="{ 'icon-folder': true, favorites: folder.shortcut }"
            ></div>
            <div class="name">{{ folder.name }}</div>
          </drag>
          <div class="documents-main-inner-files-section-item-modified">
            <div>
              {{ formatDateTime(folder.updated_at) }}
            </div>
            <folder-options-menu
              v-if="!folder.patient_folder"
              :folder="folder"
              @add-shortcut="folder['shortcut'] = 1"
              @remove-shortcut="folder['shortcut'] = 0"
            ></folder-options-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="list-footer">
    <pagination-per-page
      :page="$store.state.documents.pagination.page"
      :perPage="$store.state.documents.pagination.perPage"
      :allResults="allResults"
      show-label
      :show-counter="true"
      @value-change="setPagination"
    ></pagination-per-page>
    <pagination-page
      :page="$store.state.documents.pagination.page"
      :perPage="$store.state.documents.pagination.perPage"
      :options="pages"
      show-label
      :show-controls="true"
      @value-change="setPagination"
    ></pagination-page>
  </div>
</template>

<script>
import FolderOptionsMenu from "./FolderOptionsMenu";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";

export default {
  name: "DocumentsPatientsListView",
  props: {
    isDropping: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allResults: {
      type: Number,
      default: 0,
    },
    currentActive: {
      type: Object,
      default: () => {
        return {
          type: null,
          item: null,
        };
      },
    },
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    FolderOptionsMenu,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {};
  },
  methods: {
    isCurrentActive(type, item) {
      return (
        this.currentActive.type === type &&
        this.currentActive.item.id === item.id
      );
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "documents",
        data: value,
      });
    },
  },
};
</script>
