<template>
  <div class="options-menu-wrapper">
    <div
      class="action"
      :ref="'action_' + plan.id"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + plan.id],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="edit">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">{{ $t("label.edit") }}</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent="remove">
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">{{ $t("label.remove") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";

export default {
  name: "PlanOptionsMenu",
  props: ["plan"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.plan.id,
      });
    },
    edit() {
      this.$router.push({
        name: "planEdit",
        params: {
          id: this.plan.id,
        },
      });
    },
    remove() {
      EventBus.$emit("open-modal", {
        type: "remove-item",
        item: this.plan,
        removeType: "plan",
      });
    },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.plan.id) {
        this.menuOpened = false;
      }
    },
  },
};
</script>
