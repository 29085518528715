export const TABLE_ITEM_TYPES = {
  BUTTON: "button",
  DATE: "date",
  ICON: "icon",
  NUMBER: "number",
  SELECT: "select",
  TEXT: "text",
};

export const STATUSES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
};

export const ADJUSTMENT_TYPE_OPTIONS = {
  TYPE_CONTRACTED_RATE: {
    label: "ContractedRate",
    value: "ContractedRate",
    eob: true,
  },
  TYPE_AETNA_EXAM: {
    label: "AetnaExamDenial",
    value: "AetnaExamDenial",
    eob: true,
  },
  TYPE_CHARGEBACK: {
    label: "Chargeback",
    value: "Chargeback",
    eob: true,
  },
  TYPE_COMPED: {
    label: "Comped",
    value: "Comped",
    eob: false,
  },
  TYPE_EMPLOYEE_DISCOUNT: {
    label: "EmployeeDiscount",
    value: "EmployeeDiscount",
    eob: false,
  },
  TYPE_MUE_LIMIT: {
    label: "MUE_Limit",
    value: "MUE_Limit",
    eob: true,
  },
  TYPE_WRITE_OFF_DEBT: {
    label: "WriteOff_OldDebt",
    value: "WriteOff_OldDebt",
    eob: false,
  },
  TYPE_ZERO_OUT: {
    label: "Zero_Out",
    value: "Zero_Out",
    eob: false,
  },
  TYPE_PATIENT_OVERPAID: {
    label: "Patient_Overpaid",
    value: "Patient_Overpaid",
    eob: true,
  },
};

export const US_PHONE_REGEX = /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/gm;
export const FLOAT_TWO_DECIMALS = /^(?:\d*\.\d{1,2}|\d+)$/;

export const INTEGER = /^\+?(0|[1-9]\d*)$/;

export default {
  beforeCreate() {
    this.TABLE_ITEM_TYPES = TABLE_ITEM_TYPES;
    this.ADJUSTMENT_TYPE_OPTIONS = ADJUSTMENT_TYPE_OPTIONS;
    this.STATUSES = STATUSES;
    this.ADJUSTMENT_TYPE_OPTIONS_WITH_EOB = Object.fromEntries(
      Object.entries(ADJUSTMENT_TYPE_OPTIONS).filter(
        // eslint-disable-next-line no-unused-vars
        ([key, value]) => value.eob === true
      )
    );
  },
};
