<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.reports.paymentDetailByServiceProvider") }}
      </div>
      <div class="list-top-action">
        <div class="button action" @click.prevent="exportFile('csv')">
          {{ $t("button.exportCsv") }}
        </div>
        <div class="button action" @click.prevent="exportFile('pdf')">
          {{ $t("button.exportPdf") }}
        </div>
      </div>
    </div>

    <div class="list-filter">
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="0"
          :label="$t('label.start') + ':'"
          :original-start-date="start"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setStart"
        ></date-time-input>
      </div>
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="1"
          :label="$t('label.end') + ':'"
          :min-date="start"
          :original-start-date="end"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setEnd"
        ></date-time-input>
      </div>
      <div class="form-item">
        <label class="item">{{ $tc("label.provider", 1) }}: </label>
        <v-select
          :getOptionLabel="nameOptionLabel"
          :options="doctors"
          v-model="doctor"
        >
          <template #option="{ first_name, last_name }">
            <span> {{ first_name + " " + last_name }}</span>
          </template>
        </v-select>
      </div>
      <div class="form-item action">
        <a class="button action" @click.prevent="loadData">
          {{ $t("button.filter") }}
        </a>
      </div>
    </div>

    <!--          reports section-->
    <div class="list-wrapper">
      <div class="list-items">
        <div class="list-items-header">
          <header-sort
            sortKey="reports"
            :sortArray="[
              {
                label: $t('label.date'),
              },
              {
                label: $tc('label.patient', 1),
              },
              {
                label: $t('label.description'),
              },
              {
                label: $t('label.amount'),
              },
            ]"
          ></header-sort>
        </div>
        <div class="list-items-section">
          <!-- reports-->
          <div class="list-items-section-wrapper">
            <div v-if="data.length === 0" class="list-items-section-empty">
              {{ $t("label.noResults") }}
            </div>
            <template v-else>
              <div
                class="list-items-section-item top"
                v-for="(report, index) in data"
                :key="'report_' + index"
              >
                <div class="basic">
                  {{ report.items[0].order_date }}
                </div>
                <div class="basic">
                  <div class="top">{{ report.items[0].patient }}</div>
                  <div class="sub">
                    <div
                      v-for="(item, reportIndex) in report.items"
                      :key="'date_' + index.id + '_' + reportIndex"
                    >
                      {{ item.event_date }}
                    </div>
                  </div>
                </div>
                <div class="basic">
                  <div class="top"><br /></div>
                  <div class="sub">
                    <div
                      v-for="(item, reportIndex) in report.items"
                      :key="'treatment_' + index.id + '_' + reportIndex"
                    >
                      {{ item.treatment }}
                    </div>
                  </div>
                </div>
                <div class="basic">
                  <div class="top">{{ report.sum_amount }}</div>
                  <div class="sub">
                    <div
                      v-for="(item, reportIndex) in report.items"
                      :key="'amount_' + index.id + '_' + reportIndex"
                    >
                      {{ item.amount }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import fileMixin from "@/mixins/fileMixin";

export default {
  name: "PaymentDetailsByServiceProviderView",
  mixins: [fileMixin],
  components: {
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
    DateTimeInput,
  },
  data: function () {
    return {
      data: [],
      pages: [],
      doctors: [],
      doctor: null,
      start: null,
      end: null,
      allResults: 0,
      cancelToken: { load: null },
    };
  },
  created() {
    this.loadDoctors();
  },
  unmounted() {
    this.$store.dispatch("resetReportsQuery");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.reports": {
      deep: true,
      handler: function (val, oldVal) {
        if (val !== oldVal && !val.reset) {
          this.loadData();
        }
      },
    },
  },
  methods: {
    loadData() {
      if (!this.start || !this.end || !this.doctor) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.fillAllFields"),
        });
        return;
      }
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url:
          "/api/v1/reports/payment-details-by-service-provider/" +
          this.doctor.id,
        data: {},
        params: {
          start: this.getUTCNoonFromDate(this.start).toISOString(),
          end: this.getUTCNoonFromDate(this.end).toISOString(),
          q: this.$store.state.reports.search,
          sort_by: this.$store.state.reports.sortOrder,
          page: this.$store.state.reports.pagination.page,
          per_page: this.$store.state.reports.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.data = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    exportFile(type) {
      if (!this.start || !this.end || !this.doctor) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.fillAllFields"),
        });
        return;
      }
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url:
          "/api/v1/reports/payment-details-by-service-provider/" +
          this.doctor.id +
          "/export/" +
          type,
        data: {},
        params: {
          start: this.getUTCNoonFromDate(this.start).toISOString(),
          end: this.getUTCNoonFromDate(this.end).toISOString(),
          q: this.$store.state.reports.search,
          sort_by: this.$store.state.reports.sortOrder,
        },
        responseType: "blob",
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          this.downloadFile(response);
        })
        .catch(() => {});
    },
    loadDoctors() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        data: {},
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.doctors = response.data;
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", { type: "reports", data: value });
    },
    setStart(value) {
      this.start = value;
    },
    setEnd(value) {
      this.end = value;
    },
  },
};
</script>
