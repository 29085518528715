<template>
  <div class="modal remove" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div class="icon">
              <item-icon
                v-if="isInactiveDoctor(doctor)"
                type="revert"
                :class-name="'color-green'"
              ></item-icon>
              <item-icon
                v-else
                type="delete"
                :class-name="'color-red'"
              ></item-icon>
            </div>

            <div class="title">
              {{
                isInactiveDoctor(doctor)
                  ? $t("message.youAreAboutToActivate", {
                      item: $tc("label.doctor", 1).toLowerCase(),
                    })
                  : $t("message.youAreAboutToDeactivate", {
                      item: $tc("label.doctor", 1).toLowerCase(),
                    })
              }}
            </div>
            <div class="sub-title">
              <template v-if="isInactiveDoctor(doctor)">
                This will activate
                {{ doctor.first_name + " " + doctor.last_name }}. <br />Are you
                sure?
              </template>
              <template v-else>
                This will deactivate
                {{ doctor.first_name + " " + doctor.last_name }}. <br />Are you
                sure?
              </template>
            </div>
            <div class="action">
              <a
                :class="{
                  button: true,
                  cancel: true,
                  disabled: buttonDisabled,
                }"
                @click.prevent="closeModal"
              >
                {{ $t("button.cancel") }}
              </a>
              <a
                :class="{
                  button: true,
                  delete: !isInactiveDoctor(doctor),
                  approve: isInactiveDoctor(doctor),
                  small: true,
                  disabled: buttonDisabled,
                }"
                @click.prevent="changeStatus"
              >
                {{
                  isInactiveDoctor(doctor)
                    ? $t("button.activate")
                    : $t("button.deactivate")
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import ItemIcon from "@/views/private/components/ItemIcon";
import EventBus from "@/services/eventbus";

export default {
  name: "DoctorChangeStatus",
  props: ["item"],
  components: {
    ItemIcon,
  },
  data() {
    return {
      loading: false,
      buttonDisabled: false,
      doctor: {
        first_name: null,
        last_name: null,
        deleted_at: null,
        email: null,
        title: null,
        hour_format: null,
        address_1: null,
        address_2: null,
        license_no: null,
        phone_number: null,
        status: null,
        color: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  mounted() {
    this.loadDoctor();
  },
  methods: {
    loadDoctor() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/doctors/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.doctor = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit("close");
    },
    changeStatus() {
      this.buttonDisabled = true;
      HTTP({
        method: "PUT",
        url: `/api/v1/doctors/${this.doctor.id}`,
        data: {
          status: this.isInactiveDoctor(this.doctor) ? "active" : "inactive",
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.isInactiveDoctor(this.doctor)
              ? this.$t("notification.doctorActivated")
              : this.$t("notification.doctorDeactivated"),
          });
          this.closeModal();
          EventBus.$emit("doctors-load");
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        })
        .finally(() => {
          this.buttonDisabled = false;
        });
    },
  },
};
</script>

<style scoped></style>
