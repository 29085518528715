<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.edit")
                          : $t("label.addNew")
                      }}
                      {{ $t("label.insuranceProvider") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newInsuranceProvider.name'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <input
                      ref="name"
                      v-model="newInsuranceProvider.name"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newInsuranceProvider.phone'),
                    }"
                  >
                    <label class="item">{{ $t("label.phoneNo") }}: </label>
                    <input
                      ref="phone"
                      v-imask="masks.phone"
                      :value="newInsuranceProvider.phone"
                      @accept="
                        newInsuranceProvider.phone = parsePhoneValue($event)
                      "
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newInsuranceProvider.status'),
                    }"
                  >
                    <label class="item">{{ $t("label.status") }}: </label>
                    <v-select
                      :options="statuses"
                      v-model="newInsuranceProvider.status"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="saveInsuranceProvider()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";
import imaskMixin from "@/mixins/imaskMixin";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "InsuranceProviderCreate",
  mixins: [imaskMixin],
  validators: {
    "newInsuranceProvider.name": function (value) {
      return Validator.value(value).required();
    },
    "newInsuranceProvider.phone": function (value) {
      return Validator.value(value).length(11).required();
    },
    "newInsuranceProvider.status": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      newInsuranceProvider: {
        name: null,
        phone: null,
        status: null,
      },
      statuses: Object.values(this.STATUSES),
      loading: false,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadInsuranceProvider();
      }
    },
    isModeEdit() {
      return "insuranceProviderEdit" === this.$route.name;
    },
    loadInsuranceProvider() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-providers/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newInsuranceProvider = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveInsuranceProvider() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/purchases/insurance-providers/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(
            self.newInsuranceProvider
          );
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.loading = false;
                self.closeModal();
                EventBus.$emit("insurance-providers-load");
              }
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
              self.loading = false;
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
