<template>
  <div class="insurance-claim-items" v-if="visible">
    <div class="list">
      <div class="list-items">
        <div class="list-items-header">
          <header-sort
            sortKey="insuranceLedger"
            :sortArray="prepareHeaderArray(this.tableDefinition)"
          ></header-sort>
        </div>
        <div class="list-items-section data-table">
          <!-- treatments-->
          <div class="list-items-section-wrapper">
            <div
              class="list-items-section-item"
              v-for="(item, index) in data"
              :key="'item_' + item.id"
            >
              <template v-for="(cell, cellIndex) in tableDefinition">
                <table-cell
                  v-if="
                    [
                      this.TABLE_ITEM_TYPES.TEXT,
                      this.TABLE_ITEM_TYPES.NUMBER,
                      this.TABLE_ITEM_TYPES.DATE,
                      this.TABLE_ITEM_TYPES.SELECT,
                    ].indexOf(cell.type) > -1
                  "
                  :key="'item_' + item.id + '_cell_' + cellIndex"
                  :type="cell.type"
                  :data-key="cell.key"
                  :width="cell.width"
                  :value="
                    cell.valueParser ? cell.valueParser(item) : item[cell.key]
                  "
                  :options="
                    cell.options
                      ? getOptions(
                          cell.options,
                          cell.valueParser
                            ? cell.valueParser(item)
                            : item[cell.key]
                        )
                      : undefined
                  "
                  :newOption="cell.newOption ? cell.newOption : undefined"
                  :newOptionPush="cell.newOptionPush"
                  :placeholder="cell.placeholder"
                  :editable="cell.editable && enableEdit"
                  :error="hasError(item.id, cell.key)"
                  @add-new-option="
                    (option) => {
                      cell.options.push(option);
                    }
                  "
                  @not-editable="checkForEdit"
                  @update-cell-value="updateCellValue($event, index, item.id)"
                  ><template v-if="cell.slot">{{
                    cell.slot
                  }}</template></table-cell
                >
                <table-button
                  v-if="cell.type === this.TABLE_ITEM_TYPES.BUTTON"
                  :key="'item_' + item.id + '_button_' + cellIndex"
                  :label="cell.label"
                  :button-label="cell.buttonLabel"
                  :enabled="
                    typeof cell.enabled === 'function'
                      ? cell.enabled(item)
                      : cell.enabled
                  "
                  :width="cell.width"
                  @button-pressed="cell.callback(index, item)"
                />
              </template>
              <div v-if="data.length === 0">
                {{ $t("label.noResults") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSort from "@/views/private/components/table/HeaderSort";
import TableCell from "@/views/private/components/table/TableCell";
import tableMixin from "@/mixins/tableMixin";
import fileMixin from "@/mixins/fileMixin";
import insuranceLedgerMixin from "@/views/private/components/insurance/insuranceLedgerMixin";
import { CLAIM_TYPES_OPTIONS } from "@/store";
import TableButton from "@/views/private/components/table/TableButton";
import { default as EventBus } from "@/services/eventbus";

export default {
  name: "InsuranceClaimItems",
  components: {
    TableButton,
    TableCell,
    HeaderSort,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columnErrors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    insuranceWallet: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  mixins: [tableMixin, fileMixin, insuranceLedgerMixin],
  computed: {
    enableEdit() {
      return this.insuranceWallet !== null;
    },
  },
  data() {
    let self = this;
    return {
      tableDefinition: [
        {
          label: this.$t("label.date"),
          key: "date",
          type: this.TABLE_ITEM_TYPES.DATE,
          width: "100px",
        },
        {
          label: this.$t("label.practitioner"),
          key: "doctor",
          type: this.TABLE_ITEM_TYPES.TEXT,
          width: "160px",
          valueParser: function (item) {
            return item.doctor
              ? item.doctor.first_name + " " + item.doctor.last_name
              : "";
          },
        },
        {
          label: this.$t("label.dxCode"),
          key: "dx_code",
          type: this.TABLE_ITEM_TYPES.TEXT,
          width: "160px",
        },
        {
          label: this.$t("label.cptCode"),
          key: "cpt_code",
          type: this.TABLE_ITEM_TYPES.TEXT,
          width: "160px",
        },
        {
          label: this.$t("label.billedAmount"),
          key: "billed_amt",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          width: "80px",
        },
        {
          label: this.$t("label.insurancePayment"),
          key: "insurance_payment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: true,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.adjustment"),
          key: "adjustment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.adjustment"),
          buttonLabel: this.$t("button.update"),
          key: "adjustment",
          type: this.TABLE_ITEM_TYPES.BUTTON,
          enabled: function (item) {
            return self.canMakeAdjustment(item);
          },
          callback: function (index, item) {
            return self.openAdjustmentModal(index, item);
          },
          width: "100px",
        },
        {
          label: this.$t("label.adjustmentType"),
          key: "adjustment_type",
          type: this.TABLE_ITEM_TYPES.SELECT,
          options: Object.values(this.ADJUSTMENT_TYPE_OPTIONS),
          placeholder: "Select adjustment type...",
          editable: true,
          sortable: true,
          newOption: function (option) {
            return {
              label: option,
              value: option,
            };
          },
          newOptionPush: true,
          width: "160px",
        },
        {
          label: this.$t("label.patientPayment"),
          key: "patient_payment",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          editable: false,
          sortable: true,
          width: "80px",
        },
        {
          label: this.$t("label.paymentType"),
          key: "payment_type",
          editable: false,
          type: this.TABLE_ITEM_TYPES.TEXT,
          width: "100px",
        },
        {
          label: this.$t("label.claimType"),
          key: "claim_type",
          type: this.TABLE_ITEM_TYPES.SELECT,
          options: Object.values(CLAIM_TYPES_OPTIONS),
          placeholder: "Select claim type...",
          editable: true,
          sortable: true,
          width: "200px",
        },
        {
          label: this.$t("label.balanceDue"),
          key: "balance_due",
          type: this.TABLE_ITEM_TYPES.NUMBER,
          width: "80px",
          valueParser: function (item) {
            return self.getBalanceDue(item).toFixed(2);
          },
        },
        {
          label: this.$t("label.insuranceLedger"),
          buttonLabel: this.$t("button.show"),
          key: "",
          type: this.TABLE_ITEM_TYPES.BUTTON,
          enabled: true,
          callback: function (index, item) {
            return self.showInsuranceLedger(item);
          },
          width: "100px",
        },
      ],
    };
  },
  methods: {
    updateCellValue(data, index, id) {
      this.$emit("updateCellValue", {
        index: index,
        id: id,
        key: data.key,
        val: data.value,
      });
    },
    checkForEdit(key) {
      if (key === "insurance_payment") {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.pleaseSelectEob"),
        });
      }
    },
    getOptions(options, value) {
      let index = options.findIndex(function (item) {
        return item.value === value;
      });

      if (index === -1 && value) {
        options.push({
          label: value,
          value: value,
        });
      }

      return options;
    },
    hasError(id, key) {
      return (
        this.columnErrors &&
        typeof this.columnErrors[id] !== "undefined" &&
        this.columnErrors[id].indexOf(key) > -1
      );
    },
    showInsuranceLedger(item) {
      this.$router.push({
        name: "insurance",
        params: {
          type: "insurance-ledger",
        },
        query: {
          provider_id: item.doctor ? item.doctor.id : "",
          provider_first_name: item.doctor ? item.doctor.first_name : "",
          provider_last_name: item.doctor ? item.doctor.last_name : "",
          patient_id: item.patient ? item.patient.id : "",
          patient_first_name: item.patient ? item.patient.first_name : "",
          patient_last_name: item.patient ? item.patient.last_name : "",
          claim_type: item.claim_type,
          date: item.date,
        },
      });
    },
    canMakeAdjustment(item) {
      return (
        this.enableEdit ||
        (item.adjustment_type &&
          Object.values(this.ADJUSTMENT_TYPE_OPTIONS_WITH_EOB).findIndex(
            (objectItem) => {
              return objectItem.value === item.adjustment_type;
            }
          ) === -1)
      );
    },
    openAdjustmentModal(index, item) {
      EventBus.$emit("open-modal", {
        type: "insurance-ledger-adjustments",
        item: item,
        description: item.description,
        insuranceWalletId: this.insuranceWallet
          ? this.insuranceWallet.id
          : null,
        callback: (value) => {
          this.$emit("updateCellValue", {
            index: index,
            id: item.id,
            key: "adjustment",
            val: value,
          });
        },
      });
    },
  },
};
</script>
