<template>
  <div class="form patients-schedule patients-schedule-register">
    <div class="patients-schedule-inner">
      <div class="patients-schedule-header">
        <h1 class="patients-schedule-header-title">
          {{ $t("clinic.forgotPasswordTitle") }}
        </h1>
        <span class="patients-schedule-header-text">{{
          $t("clinic.forgotPasswordText")
        }}</span>
      </div>
      <form
        @submit.prevent="reset()"
        :class="{
          'patients-schedule-content': true,
          disabled: formDisable,
        }"
      >
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.email'),
          }"
        >
          <label class="item">{{ $t("label.enterEmail") }} </label>
          <input v-model="form.email" type="text" ref="email" />
        </div>
        <div class="patients-schedule-action">
          <button
            :class="{
              button: true,
              dark: true,
              height: true,
              disabled: formDisable,
            }"
            type="submit"
          >
            {{ $t("button.submit") }}
          </button>
        </div>
      </form>

      <div class="patients-schedule-action">
        <span class="label">{{ $t("label.allReadyHaveAccount") }}</span>
        <router-link
          :to="{
            name: 'patientLogin',
            params: { clinic_slug: $route.params.clinic_slug },
          }"
          class="button action height"
        >
          {{ $t("button.loginTogether") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
import { HTTP_PUBLIC } from "@/services/api";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientForgotPassword",
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
  },
  data() {
    return {
      formDisable: false,
      form: {
        email: null,
      },
    };
  },
  mounted() {},
  methods: {
    reset() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP_PUBLIC({
            method: "POST",
            url: `/api/v1/clinics/${self.$route.params.clinic_slug}/patients/password/email`,
            data: {
              email: self.form.email,
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.done"),
                text: self.$t("notification.checkEmail"),
              });
              self.$router.push({
                name: "patientPasswordResetSuccess",
                params: { clinic_slug: self.$route.params.clinic_slug },
              });
              self.formDisable = false;
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.somethingWentWrong"),
              });
              self.formDisable = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
