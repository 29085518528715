<template>
  <div class="row">
    <div class="col-lg-12 patients-form">
      <patient-form-view mode="create" />
    </div>
  </div>
</template>

<script>
import PatientFormView from "@/views/private/components/patients/PatientFormView";
export default {
  name: "PatientForm",
  components: { PatientFormView },
};
</script>
