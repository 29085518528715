<template>
  <div class="row">
    <div class="col-lg-12 documents">
      <sidebar />
      <documents-view :path="currentPath" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/document/Sidebar";
import DocumentsView from "@/views/private/components/document/DocumentsView";
import firstVisit from "@/mixins/firstVisit";
export default {
  name: "DocumentRepository",
  mixins: [firstVisit],
  components: {
    DocumentsView,
    Sidebar,
  },
  computed: {
    currentPath: function () {
      return this.$route.params.path
        ? decodeURIComponent(this.$route.params.path.replace(/-+/g, " "))
        : "/";
    },
  },
  methods: {},
};
</script>
