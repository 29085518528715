<template>
  <div class="main">
    <sidebar-mini
      v-if="this.$hasRole(['Administrator', 'Doctors'])"
    ></sidebar-mini>
    <div class="container-fluid hide-horizontal-scroll">
      <router-view />
    </div>
    <root-modal v-model="modalData"></root-modal>
  </div>
</template>

<script>
import RootModal from "@/views/private/components/modals/RootModal.vue";
import SidebarMini from "@/views/private/components/SidebarMini";
import { default as EventBus } from "@/services/eventbus";
import { MODAL_ROUTES } from "@/router/ModalRoutes";

export default {
  name: "PrivateLayout",
  components: {
    RootModal,
    SidebarMini,
  },
  data() {
    return {
      modalData: {},
    };
  },
  watch: {
    "$route.name": function (name) {
      if (Object.keys(MODAL_ROUTES).indexOf(name) > -1) {
        this.modalData.type = MODAL_ROUTES[name];
      } else {
        this.modalData = {};
      }
    },
    "$store.state.modal": function (newVal) {
      if (Object.keys(newVal).length !== 0) {
        this.modalData = newVal;
      }
    },
  },
  created() {
    EventBus.$on("open-modal", (payload) => {
      this.modalData = payload;
    });

    if (Object.keys(this.$store.state.modal).length !== 0) {
      this.modalData = this.$store.state.modal;
    }

    if (Object.keys(MODAL_ROUTES).indexOf(this.$route.name) > -1) {
      this.modalData.type = MODAL_ROUTES[this.$route.name];
    }
  },
  beforeUnmount() {
    EventBus.$off("open-modal");
  },
};
</script>
