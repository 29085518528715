<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") }} {{ $tc("label.clinic", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <!--          files section-->
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="clinics"
          :sortArray="[
            {
              label: $t('label.name'),
              direction: 'asc',
              key: 'name',
            },
            {
              label: $t('label.status'),
              direction: 'asc',
              key: 'status',
              width: '160px',
            },
            {
              label: $t('label.email'),
              direction: 'asc',
              key: 'email',
              width: '160px',
            },
            {
              label: $t('label.timezone'),
              direction: 'asc',
              key: 'timezone',
              width: '193px',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- clinics-->
        <div class="list-items-section-wrapper">
          <div
            class="list-items-section-item"
            v-for="clinic in clinics"
            :key="'clinic_' + clinic.id"
          >
            <div class="name">
              {{ clinic.name }}
            </div>
            <div class="status">
              {{ clinic.status }}
            </div>
            <div class="email">
              {{ clinic.email }}
            </div>
            <div class="timezone">
              {{ clinic.timezone }}
            </div>
            <clinic-options-menu :clinic="clinic"></clinic-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.clinics.pagination.page"
        :perPage="$store.state.clinics.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.clinics.pagination.page"
        :perPage="$store.state.clinics.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import ClinicOptionsMenu from "@/views/private/components/settings/admin/ClinicOptionsMenu";

export default {
  name: "ClinicsView",
  props: ["path"],
  components: {
    ClinicOptionsMenu,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      files: [],
      clinics: [],
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadClinics();
  },
  created() {
    EventBus.$on("clinics-load", () => {
      this.loadClinics();
    });
  },
  unmounted() {
    EventBus.$off("clinics-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.clinics": {
      handler: function () {
        this.loadClinics();
      },
      deep: true,
    },
  },
  methods: {
    loadClinics() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/clinics",
        data: {},
        params: {
          q: this.$store.state.clinics.search,
          sort_by: this.$store.state.clinics.sortOrder,
          page: this.$store.state.clinics.pagination.page,
          per_page: this.$store.state.clinics.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.clinics = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "clinics",
        data: value,
      });
    },
    handleCreateButton() {
      this.$router.push({
        name: "adminClinic",
      });
    },
  },
};
</script>
