<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <search />
      <div class="sidebar-inner-calendar" ref="sidebarCalendar">
        <div class="sidebar-inner-calendar-top">
          <div
            class="sidebar-inner-calendar-top-prev"
            @click.prevent="previous"
          ></div>
          <div v-if="sidebarMounted" class="sidebar-inner-calendar-top-date">
            <span class="sidebar-inner-calendar-top-date-month">{{
              this.getMonthName(this.$refs.calSidebar.view.startDate)
            }}</span>
            <span class="sidebar-inner-calendar-top-date-year">{{
              this.$refs.calSidebar.view.startDate.getFullYear()
            }}</span>
          </div>
          <div
            class="sidebar-inner-calendar-top-next"
            @click.prevent="next"
          ></div>
        </div>
        <vue-cal
          ref="calSidebar"
          hide-view-selector
          hideTitleBar
          :start-week-on-sunday="true"
          :time="false"
          :transitions="false"
          click-to-navigate
          active-view="month"
          :disable-views="['day', 'week', 'year']"
          :selected-date="this.$store.state.currentDate"
          @cell-focus="setCurrentDate"
          xsmall
          class="vuecal--full-height-delete vuecal-sidebar"
        >
          <template v-slot:weekday-heading="{ heading }">
            <span class="calendar-main-inner-calendar-header-label">{{
              heading.label.substring(0, 3)
            }}</span>
          </template>
          <template v-slot:cell-content="{ cell, view }">
            <span
              :class="{
                'vuecal__cell-date': true,
                'same-week':
                  cell.startDate.getWeek() === view.selectedDate.getWeek(),
              }"
              >{{ cell.content }}</span
            >
          </template>
        </vue-cal>
      </div>
      <filter-menu />
    </div>
  </div>
</template>

<script>
import Search from "@/views/private/components/Search";
import FilterMenu from "@/views/private/components/FilterMenu";
import VueCal from "vue-cal";
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "SidebarComponent",
  mixins: [onboardTooltipMixin],
  components: { FilterMenu, Search, VueCal },
  data: () => ({
    sidebarMounted: false,
  }),
  watch: {
    "$store.state.currentDate": function (value) {
      this.$refs.calSidebar.switchView("month", value);
    },
  },
  mounted() {
    this.sidebarMounted = true;
  },
  methods: {
    setCurrentDate(e) {
      this.$store.dispatch("setCurrentDate", this.fixTimezoneOffset(e));
    },
    previous() {
      this.$refs.calSidebar.previous();
      this.$emit(
        "sidebar-calendar-month-change",
        this.$refs.calSidebar.view.startDate
      );
    },
    next() {
      this.$refs.calSidebar.next();
      this.$emit(
        "sidebar-calendar-month-change",
        this.$refs.calSidebar.view.startDate
      );
    },
    setMonth(date) {
      this.$refs.calSidebar.switchView("month", date);
    },
  },
};
</script>

<style scoped></style>
