<template>
  <!--    Doctors slot-->
  <div
    v-if="
      (event.type === 'regular' || event.type === null) &&
      $store.state.focusedFilter === 'doctors'
    "
    @click="showDetail(event)"
    :class="[
      colorClass(event.doctor.id, 'doctor'),
      'event-wrapper',
      { 'color-stripes': event.status === 'pending' },
    ]"
  >
    <div class="event-upper">
      <div class="event-name">
        <div class="event-name-left">
          <div class="event-name-left-upper">
            <div
              :class="{
                'event-title': true,
                disabled: event.doctor.status === 'inactive',
              }"
            >
              {{ event.doctor.first_name + " " + event.doctor.last_name }}
            </div>
            <div class="event-time">
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
                )
              }}
            </div>
          </div>
        </div>
        <div class="event-name-right">
          <span
            class="icon"
            v-if="event.status === 'checked-in'"
            title="checked-in"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          </span>
          <span
            class="icon"
            v-if="event.status === 'checked-out'"
            title="checked-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4 12 14.01l-3-3" />
            </svg>
          </span>
          <span
            class="icon"
            v-if="event.status === 'canceled'"
            title="canceled"
          >
            🚫
          </span>
          <span
            class="icon"
            v-if="event.patient && event.patient.guardian"
            title="guardian"
          >
            🧒
          </span>
        </div>
      </div>
    </div>
    <div class="event-lower">
      <div
        class="event-patient"
        :class="[
          {
            inverse: true,
            disabled: event.patient.status === 'inactive',
          },
          colorClass(event.doctor.id, 'doctor'),
          colorText(event.doctor ? event.doctor.color : null),
        ]"
      >
        {{ event.patient.first_name + " " + event.patient.last_name }}
      </div>
      <div class="event-additional">
        <div class="event-title">
          <item-icon
            type="treatment"
            :tooltip="event.treatment.name"
            :class-name="[
              colorClass(
                event.treatment ? event.treatment.id : null,
                'treatment'
              ),
              'event',
            ]"
          ></item-icon
          >{{ event.treatment.name }}
        </div>
        <div v-if="event.room" class="event-title">
          <item-icon
            type="room"
            :tooltip="event.room.name"
            :class-name="[
              colorClass(event.room ? event.room.id : null, 'room'),
              'event',
            ]"
          ></item-icon
          >{{ event.room.name }}
        </div>
      </div>
    </div>
  </div>

  <!--    Doctors slot time off-->
  <div
    v-else-if="
      event.type === 'time_off' && $store.state.focusedFilter === 'doctors'
    "
    @click="showDetail(event)"
    class="event-wrapper color-stripes disabled"
  >
    <div class="event-upper">
      <div class="event-name">
        <div class="event-name-left">
          <div class="event-name-left-upper">
            <div
              :class="{
                'event-title': true,
                disabled: event.doctor.status === 'inactive',
              }"
            >
              <item-icon
                type="doctor-2"
                :tooltip="`${event.doctor.first_name} ${event.doctor.last_name}`"
                :class-name="
                  colorClass(event.doctor ? event.doctor.id : null, 'doctor')
                "
              ></item-icon
              >{{ event.doctor.first_name + " " + event.doctor.last_name }}
            </div>
          </div>
        </div>
        <div class="event-name-right">
          <span
            class="icon"
            v-if="event.status === 'checked-in'"
            title="checked-in"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          </span>
          <span
            class="icon"
            v-if="event.status === 'checked-out'"
            title="checked-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <path d="M22 4 12 14.01l-3-3" />
            </svg>
          </span>
          <span
            class="icon"
            v-if="event.status === 'canceled'"
            title="canceled"
          >
            🚫
          </span>
          <span
            class="icon"
            v-if="event.patient && event.patient.guardian"
            title="guardian"
          >
            🧒
          </span>
        </div>
      </div>
    </div>
    <div class="event-lower"></div>
    <div class="event-time">
      <div class="event-additional">
        <div class="event-title">
          {{
            customFormat(
              event.start,
              $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
            )
          }}
          -
          {{
            customFormat(
              event.end,
              $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
            )
          }}
        </div>
      </div>
    </div>
  </div>

  <!--    Rooms slot-->
  <div
    v-else-if="
      (event.type === 'regular' || event.type === null) &&
      $store.state.focusedFilter === 'rooms'
    "
    @click="showDetail(event)"
    :class="
      'event-wrapper ' + colorClass(event.room ? event.room.id : null, 'room')
    "
  >
    <div class="event-upper">
      <div class="event-name">
        <div class="event-name-left">
          <div class="event-name-left-upper">
            <div class="event-title" v-if="event.room.name">
              {{ event.room.name }}
            </div>
            <div class="event-time">
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event-lower">
      <div
        class="event-patient"
        :class="[
          {
            inverse: true,
            disabled: event.patient.status === 'inactive',
          },
          colorClass(event.room ? event.room.id : null, 'room'),
          colorText(event.room ? event.room.color : null),
        ]"
      >
        {{ event.patient.first_name + " " + event.patient.last_name }}
      </div>
      <div class="event-additional">
        <div class="event-title" v-if="event.treatment">
          <item-icon
            type="treatment"
            :tooltip="event.treatment.name"
            :class-name="
              colorClass(
                event.treatment ? event.treatment.id : null,
                'treatment'
              )
            "
          ></item-icon
          >{{ event.treatment.name }}
        </div>
        <div class="event-title">
          <item-icon
            type="doctor-2"
            :tooltip="`${event.doctor.first_name} ${event.doctor.last_name}`"
            :class-name="
              colorClass(event.doctor ? event.doctor.id : null, 'doctor')
            "
          ></item-icon
          >{{ event.doctor.first_name + " " + event.doctor.last_name }}
        </div>
      </div>
    </div>
  </div>

  <!--    Treatments slot-->
  <div
    v-else-if="
      (event.type === 'regular' || event.type === null) &&
      $store.state.focusedFilter === 'treatments'
    "
    @click="showDetail(event)"
    :class="'event-wrapper ' + colorClass(event.treatment.id, 'treatment')"
  >
    <div class="event-upper">
      <div class="event-name">
        <div class="event-name-left">
          <div class="event-name-left-upper">
            <div class="event-title" v-if="event.treatment">
              {{ event.treatment.name }}
            </div>
            <div class="event-time">
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event-lower">
      <div
        class="event-patient"
        :class="[
          {
            inverse: true,
            disabled: event.patient.status === 'inactive',
          },
          colorClass(event.treatment ? event.treatment.id : null, 'treatment'),
          colorText(event.treatment ? event.treatment.color : null),
        ]"
      >
        {{ event.patient.first_name + " " + event.patient.last_name }}
      </div>
      <div class="event-additional">
        <div class="event-title" v-if="event.room">
          <item-icon
            type="room"
            :tooltip="event.room.name"
            :class-name="colorClass(event.room ? event.room.id : null, 'room')"
          ></item-icon
          >{{ event.room.name }}
        </div>
        <div class="event-title">
          <item-icon
            type="doctor-2"
            :tooltip="`${event.doctor.first_name} ${event.doctor.last_name}`"
            :class-name="
              colorClass(event.doctor ? event.doctor.id : null, 'doctor')
            "
          ></item-icon
          >{{ event.doctor.first_name + " " + event.doctor.last_name }}
        </div>
      </div>
    </div>
  </div>

  <!--    Statuses slot-->
  <div
    v-else-if="
      (event.type === 'regular' || event.type === null) &&
      $store.state.focusedFilter === 'statuses'
    "
    @click="showDetail(event)"
    :class="'event-wrapper ' + statusColorClass(event.status)"
  >
    <div class="event-upper">
      <div class="event-name">
        <div class="event-name-left">
          <div class="event-name-left-upper">
            <div class="event-title" v-if="event.status">
              {{ event.status }}
            </div>
            <div class="event-time">
              {{
                customFormat(
                  event.start,
                  $store.getters.userHoursFormat24 ? "HH:mm" : "h:mm a"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event-lower">
      <div
        class="event-patient"
        :class="[
          {
            inverse: true,
            disabled: event.patient.status === 'inactive',
          },
          statusColorClass(event.status),
        ]"
      >
        {{ event.patient.first_name + " " + event.patient.last_name }}
      </div>
      <div class="event-additional">
        <div class="event-title">
          <item-icon
            v-if="event.treatment"
            type="treatment"
            :tooltip="event.treatment.name"
            :class-name="[
              colorClass(
                event.treatment ? event.treatment.id : null,
                'treatment'
              ),
              'event',
            ]"
          ></item-icon
          >{{ event.treatment.name }}
        </div>
        <div class="event-title" v-if="event.room">
          <item-icon
            type="room"
            :tooltip="event.room.name"
            :class-name="colorClass(event.room ? event.room.id : null, 'room')"
          ></item-icon
          >{{ event.room.name }}
        </div>
        <div class="event-title">
          <item-icon
            type="doctor-2"
            :tooltip="`${event.doctor.first_name} ${event.doctor.last_name}`"
            :class-name="
              colorClass(event.doctor ? event.doctor.id : null, 'doctor')
            "
          ></item-icon
          >{{ event.doctor.first_name + " " + event.doctor.last_name }}
        </div>
      </div>
    </div>
  </div>

  <!--    Others slot-->
  <div
    v-else-if="
      (event.type === 'regular' || event.type === null) &&
      $store.state.focusedFilter === 'others'
    "
    @click="showDetail(event)"
    :class="'event-wrapper ' + colorClass(event.other.id)"
  >
    <div class="event-title" v-html="event.title"></div>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
export default {
  name: "CalendarSlotEvent",
  props: ["event", "cell"],
  components: { ItemIcon },
  data() {
    return {};
  },
  methods: {
    showDetail(event) {
      if (event.type === "time_off") {
        this.$router.push({
          name: "timeOffDetail",
          params: {
            id: event.id,
          },
        });
        return;
      }

      this.$router.push({
        name: "eventDetail",
        params: {
          id: event.id,
        },
      });
    },
  },
};
</script>
