<template>
  <div>
    <form
      @submit.prevent="handlePasswordReset()"
      :class="{
        disabled: formDisable,
      }"
    >
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.current_password'),
        }"
      >
        <label class="item">{{ $t("label.oldPassword") }} </label>
        <password-input
          :model-value="form.current_password"
          @update:modelValue="(newValue) => (form.current_password = newValue)"
          prop-ref="current_password"
        />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.password'),
        }"
      >
        <label class="item">{{ $t("label.newPassword") }} </label>
        <password-input
          :model-value="form.password"
          @update:modelValue="(newValue) => (form.password = newValue)"
          prop-ref="password"
        />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('form.password_confirmation'),
        }"
      >
        <label class="item">{{ $t("label.passwordConfirmation") }} </label>
        <password-input
          :model-value="form.password_confirmation"
          @update:modelValue="
            (newValue) => (form.password_confirmation = newValue)
          "
          prop-ref="password_confirmation"
        />
      </div>
      <div class="form-item action">
        <button
          :class="{
            button: true,
            action: true,
            disabled: formDisable,
          }"
          type="submit"
        >
          {{ $t("button.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import PasswordInput from "@/views/private/components/PasswordInput";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientPasswordChange",
  mixins: [formMixin],
  components: {
    PasswordInput,
  },
  data: function () {
    return {
      form: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
      oldPasswordVisible: false,
      passwordVisible: false,
      passwordConfirmationVisible: false,
      formDisable: false,
      loading: false,
    };
  },
  validators: {
    "form.current_password": function (value) {
      return Validator.value(value).required();
    },
    "form.password": function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "form.password_confirmation, form.password": function (
      password_confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(password_confirmation)
          .required()
          .match(password);
      }
    },
  },
  methods: {
    handlePasswordReset() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP({
            method: "POST",
            url: "/api/v1/patients/password/change",
            data: {
              current_password: self.form.current_password,
              password: self.form.password,
              password_confirmation: self.form.password_confirmation,
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.done"),
                text: self.$t("notification.passwordUpdated"),
              });
              self.accordionSelectedType = "details";
              self.formDisable = false;
              self.$emit("accordionSelectedType", "details");
              self.form.current_password = "";
              self.form.password = "";
              self.form.password_confirmation = "";
              self.validation.reset();
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.passwordNotUpdatedExtended"),
              });
              self.formDisable = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
