import { createApp } from "vue";
import App from "@/App.vue";
import store from "@/store";
import i18n from "@/services/localization/i18n";
import router from "@/router";
import auth from "@/services/auth";
import dateMixin from "@/mixins/dateMixin";
import utilMixin from "@/mixins/utilMixin";
import colorMixin from "@/mixins/colorMixin";
import constMixin from "@/mixins/constMixin";
import calendarMixin from "@/mixins/calendarMixin";
import openInNewTab from "@/mixins/openInNewTab";
import closable from "@/directives/closable";
import Rollbar from "rollbar";
import vSelect from "vue-select";
import { VTooltip } from "floating-vue";
import SimpleVueValidator from "simple-vue3-validator";
import Notifications from "@kyvg/vue3-notification";
import VueGtag from "vue-gtag";
import "vue-select/dist/vue-select.css";
import "@/assets/sass/style.scss";
import "floating-vue/dist/style.css";
import OpenIndicator from "@/views/private/components/vendor/vue-select/VueSelectOpenIndicator";
import Drag from "@/views/private/components/vendor/drag-drop/Drag";
import Drop from "@/views/private/components/vendor/drag-drop/Drop";

const app = createApp(App)
  .use(SimpleVueValidator, { mode: "conservative" })
  .use(store)
  .use(router)
  .use(i18n)
  .use(Notifications)
  .use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
      enabled: process.env.VUE_APP_ENV === "production",
    },
    router
  )
  .mixin(constMixin)
  .mixin(dateMixin)
  .mixin(utilMixin)
  .mixin(colorMixin)
  .mixin(calendarMixin)
  .mixin(openInNewTab)
  .directive("closable", closable)
  .directive("tooltip", VTooltip)
  .component("drag", Drag)
  .component("drop", Drop)
  .component("v-select", vSelect);

vSelect.props.components.default = () => ({ OpenIndicator });

app.config.globalProperties.$hasRole = function (roles) {
  return auth.hasRole(roles);
};

if (process.env.VUE_APP_ENV !== "development") {
  app.config.globalProperties.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.VUE_APP_ENV,
    },
  });

  app.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };
}

app.mount("#klinika-app");
