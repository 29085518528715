export default {
  methods: {
    onboardTooltipShow() {
      if (
        this.$route.name === "calendar" &&
        this.$store.state.firstVisit.indexOf("calendar") !== -1
      ) {
        if (
          this.$store.state.onboardingTooltipShown.indexOf("sidebarMini") === -1
        ) {
          this.onboardTooltip(
            "sidebarMini",
            this.$t("notification.onboardTooltip.calendarMainNav"),
            "center",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "calendarSearchForm"
          ) === -1
        ) {
          this.onboardTooltip(
            "calendarSearchForm",
            this.$t("notification.onboardTooltip.calendarSearchForm"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "sidebarCalendar"
          ) === -1
        ) {
          this.onboardTooltip(
            "sidebarCalendar",
            this.$t("notification.onboardTooltip.calendarSidebarCalendar"),
            "center",
            "right",
            "center"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "sidebarProviders"
          ) === -1
        ) {
          this.onboardTooltip(
            "sidebarProviders",
            this.$t("notification.onboardTooltip.calendarFocus"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf("calendarSwitch") ===
          -1
        ) {
          this.onboardTooltip(
            "calendarSwitch",
            this.$t("notification.onboardTooltip.calendarSwitch"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "calendarAddEvent"
          ) === -1
        ) {
          this.onboardTooltip(
            "calendarAddEvent",
            this.$t("notification.onboardTooltip.calendarAddEvent"),
            "bottom",
            "left",
            "bottom"
          );
        }
      }

      if (
        this.$route.name === "documents" &&
        this.$store.state.firstVisit.indexOf("documents") !== -1
      ) {
        if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "documentsSearchForm"
          ) === -1
        ) {
          this.onboardTooltip(
            "documentsSearchForm",
            this.$t("notification.onboardTooltip.filesSearch"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf("filesShortcuts") ===
          -1
        ) {
          this.onboardTooltip(
            "filesShortcuts",
            this.$t("notification.onboardTooltip.filesShortcuts"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf("filesCampaigns") ===
          -1
        ) {
          this.onboardTooltip(
            "filesCampaigns",
            this.$t("notification.onboardTooltip.filesCampaigns"),
            "top",
            "right",
            "top"
          );
        }
      }

      if (
        this.$route.name === "inventory" &&
        this.$store.state.firstVisit.indexOf("inventory") !== -1
      ) {
        if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "inventorySearchForm"
          ) === -1
        ) {
          this.onboardTooltip(
            "inventorySearchForm",
            this.$t("notification.onboardTooltip.inventorySearch"),
            "top",
            "right",
            "top"
          );
        } else if (
          this.$store.state.onboardingTooltipShown.indexOf(
            "inventoryFolders"
          ) === -1
        ) {
          this.onboardTooltip(
            "inventoryFolders",
            this.$t("notification.onboardTooltip.inventoryFolders"),
            "top",
            "right",
            "top"
          );
        }
      }

      if (this.$route.name === "patientSchedule") {
        if (
          this.$store.state.onboardingTooltipShown.indexOf("patientProfile") ===
          -1
        ) {
          this.onboardTooltip(
            "patientProfile",
            this.$t("notification.onboardTooltip.patientProfile"),
            "top",
            "left",
            "top"
          );
        }
      }
    },
    onboardTooltip(ref, text, arrowPosition, popupPosition, verticalPosition) {
      if (typeof this.$refs[ref] === "undefined") {
        return;
      }
      if (this.$store.state.onboardingTooltipShown.indexOf(ref) === -1) {
        let self = this;
        setTimeout(function () {
          if (self.$refs[ref]) {
            self.$notify({
              group: "onboarding-tooltip",
              duration: -1,
              closeOnClick: false,
              text: text,
              data: {
                arrowVertical: arrowPosition,
                height: self.$refs[ref].clientHeight,
                left: self.$refs[ref].getBoundingClientRect().right,
                popupPosition: popupPosition,
                ref: ref,
                top: self.$refs[ref].getBoundingClientRect().top,
                verticalPosition: verticalPosition,
                width: self.$refs[ref].clientWidth,
              },
            });
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.onboardTooltipShow();
  },
  watch: {
    "$store.state.firstVisit": function (val) {
      if (val) {
        this.onboardTooltipShow();
      }
    },
    "$store.state.onboardingTooltipShown": function (val) {
      if (val) {
        this.onboardTooltipShow();
      }
    },
    "$route.name": function (to, from) {
      if (to.name !== from.name) {
        this.$notify({ group: "onboarding-tooltip", clean: true });
      }
    },
  },
};
