<template>
  <div id="klinika-app">
    <router-view />
    <notifications-wrapper />
  </div>
</template>

<script>
import "vue-cal/dist/vuecal.css";
import NotificationsWrapper from "@/views/private/components/NotificationsWrapper";

export default {
  name: "App",
  components: { NotificationsWrapper },
  metaInfo() {
    return {
      titleTemplate: "%s / " + this.title,
    };
  },
  created() {
    this.loadColors();
  },
  watch: {
    "$store.state.auth": function (val) {
      if (Object.keys(val).length !== 0) {
        this.loadColors();
      }
    },
  },
  data() {
    return {
      title: "Klinika",
    };
  },
};
</script>
