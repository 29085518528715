<template>
  <div class="sidebar inventory-sidebar-filter">
    <div class="sidebar-inner inventory-sidebar-filter-inner">
      <div class="sidebar-inner-block inventory-sidebar-filter-block">
        <div class="sidebar-inner-block-title">
          {{ $t("label.filters") }}
        </div>
        <div class="sidebar-inner-block-items inventory-sidebar-filter-items">
          <!--      Items-->
          <search mode="inventoryAdvanced"></search>
          <div class="inventory-sidebar-filter-item">
            <div
              class="inventory-sidebar-filter-item-title"
              @click.prevent.stop="toggleExpanded('folder')"
            >
              <div
                class="icon icon-arrow-right"
                :class="{ expanded: expandedMenus.indexOf('folder') > -1 }"
              ></div>
              <div class="label">{{ $tc("label.folder", 2) }}</div>
            </div>
            <div
              class="inventory-sidebar-filter-item-values"
              v-show="expandedMenus.indexOf('folder') > -1"
            >
              <div class="inventory-sidebar-folder-group">
                <div class="inventory-sidebar-folder-group-toggle">
                  <label
                    class="label"
                    :for="'folders'"
                    @click="toggleAllFolders"
                  >
                    <input :id="'folders'" type="checkbox" />
                    <span class="checkbox-checkmark"></span>
                    {{ $t("label.all") }}
                    {{ $tc("label.item", 2).toLowerCase() }}
                  </label>
                  <div
                    class="icon icon-arrow-right"
                    :class="{
                      expanded: expandedMenus.indexOf('folderAll') > -1,
                    }"
                    @click.prevent.stop="toggleExpanded('folderAll')"
                  ></div>
                </div>
                <div v-show="expandedMenus.indexOf('folderAll') > -1">
                  <label
                    class="inventory-sidebar-folder-group-item"
                    v-for="(folder, index) in folders"
                    :for="'folder_' + index"
                    :key="'folder_' + index"
                    @click="toggleFolder($event, folder)"
                  >
                    <input :id="'folder_' + index" type="checkbox" />
                    <span class="checkbox-checkmark"></span>
                    {{ folder.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="inventory-sidebar-filter-item">
            <div
              class="inventory-sidebar-filter-item-title"
              @click.prevent.stop="toggleExpanded('quantity')"
            >
              <div
                class="icon icon-arrow-right"
                :class="{ expanded: expandedMenus.indexOf('quantity') > -1 }"
              ></div>
              <div class="label">{{ $t("label.quantity") }}</div>
            </div>
            <div
              class="inventory-sidebar-filter-item-values"
              v-show="expandedMenus.indexOf('quantity') > -1"
            >
              <template v-if="!quantityExact">
                <div class="form-item double">
                  <div>
                    <input
                      class="item"
                      type="number"
                      min="0"
                      v-model="quantityMin"
                      :placeholder="$t('placeholder.enterMin')"
                    />
                  </div>
                  <div>
                    <input
                      class="item"
                      type="number"
                      min="0"
                      v-model="quantityMax"
                      :placeholder="$t('placeholder.enterMax')"
                    />
                  </div>
                </div>
              </template>
              <template v-else
                ><div class="form-item">
                  <input
                    class="item"
                    type="number"
                    min="0"
                    v-model="quantity"
                    :placeholder="$t('placeholder.enterValue')"
                  /></div
              ></template>
              <div>
                <div class="toggle">
                  <label class="toggle-switch-wrapper">
                    <input
                      id="toggleValue"
                      v-model="quantityExact"
                      type="checkbox"
                    />
                    <span class="toggle-switch"></span>
                  </label>
                  <div class="toggle-label">{{ $t("label.exactValue") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="inventory-sidebar-filter-item">
            <div
              class="inventory-sidebar-filter-item-title"
              :class="{ expanded: expandedMenus.indexOf('price') > -1 }"
              @click.prevent.stop="toggleExpanded('price')"
            >
              <div
                class="icon icon-arrow-right"
                :class="{ expanded: expandedMenus.indexOf('price') > -1 }"
              ></div>
              <div class="label">{{ $t("label.price") }}</div>
            </div>
            <div
              class="inventory-sidebar-filter-item-values"
              v-show="expandedMenus.indexOf('price') > -1"
            >
              <template v-if="!priceExact">
                <div class="form-item double">
                  <div>
                    <input
                      class="item"
                      type="number"
                      min="0"
                      v-model="priceMin"
                      :placeholder="$t('placeholder.enterMin')"
                    />
                  </div>
                  <div>
                    <input
                      class="item"
                      type="number"
                      min="0"
                      v-model="priceMax"
                      :placeholder="$t('placeholder.enterMax')"
                    />
                  </div>
                </div>
              </template>
              <template v-else
                ><div class="form-item">
                  <input
                    class="item"
                    type="number"
                    min="0"
                    v-model="price"
                    :placeholder="$t('placeholder.enterValue')"
                  /></div
              ></template>
              <div>
                <div class="toggle">
                  <label class="toggle-switch-wrapper">
                    <input
                      id="toggleValue2"
                      v-model="priceExact"
                      type="checkbox"
                    />
                    <span class="toggle-switch"></span>
                  </label>
                  <div class="toggle-label">{{ $t("label.exactValue") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="inventory-sidebar-filter-item">
            <div
              class="inventory-sidebar-filter-item-title"
              @click.prevent.stop="toggleExpanded('tags')"
            >
              <div
                class="icon icon-arrow-right"
                :class="{ expanded: expandedMenus.indexOf('tags') > -1 }"
              ></div>
              <div class="label">{{ $t("label.tags") }}</div>
            </div>
            <div
              class="inventory-sidebar-filter-item-values"
              v-show="expandedMenus.indexOf('tags') > -1"
            >
              <v-select
                v-model="tags"
                class="taggable"
                :options="tagOptions"
                taggable
                multiple
              ></v-select>
            </div>
          </div>
        </div>
        <div class="inventory-sidebar-filter-submit">
          <div class="button action" @click.prevent.stop="submitFilter">
            {{ $t("button.applyFilters") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import Search from "@/views/private/components/Search";

export default {
  name: "InventorySidebarFilter",
  components: { Search },
  props: {
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      foldersExpanded: null,
      selectedFolders: [],
      quantityExact: false,
      quantity: null,
      quantityMin: null,
      quantityMax: null,
      priceExact: false,
      price: null,
      priceMin: null,
      priceMax: null,
      tags: [],
      tagOptions: [],
      cancelToken: {
        loadTags: null,
      },
      expandedMenus: ["folder"],
    };
  },
  created() {
    this.loadTags();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    toggleFolder(event, folder) {
      document.getElementById("folders").checked = false;
      if (event.target.checked) {
        if (this.selectedFolders.indexOf(folder.id) === -1) {
          this.selectedFolders.push(folder.id);
        }
      } else {
        let index = this.selectedFolders.indexOf(folder.id);
        if (index > -1) {
          this.selectedFolders.splice(index, 1);
        }
      }
    },
    toggleAllFolders() {
      document.getElementById("folders").checked =
        !document.getElementById("folders").checked;
      if (document.getElementById("folders").checked) {
        this.selectedFolders = this.folders.map(function (item, index) {
          document.getElementById("folder_" + index).checked = true;
          return item.id;
        });
      } else {
        this.folders.forEach(function (item, index) {
          document.getElementById("folder_" + index).checked = false;
          return item;
        });
        this.selectedFolders = [];
      }
    },
    toggleExpanded(item) {
      let index = this.expandedMenus.indexOf(item);
      if (index === -1) {
        this.expandedMenus.push(item);
      } else {
        this.expandedMenus.splice(index, 1);
      }
    },
    loadTags() {
      setCancelToken(this.cancelToken, "loadTags");
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/tags/",
        cancelToken: this.cancelToken.loadTags.token,
      })
        .then((response) => {
          if (response.data.data) {
            this.tagOptions = response.data.data;
          }
        })
        .catch(() => {});
    },
    submitFilter() {
      this.$store.dispatch("setResourceStoreVal", {
        key: "inventoryAdvanced",
        type: "query",
        data: {
          price: this.priceExact ? this.price : null,
          price_min: !this.priceExact ? this.priceMin : null,
          price_max: !this.priceExact ? this.priceMax : null,
          quantity: this.quantityExact ? this.quantity : null,
          quantity_min: !this.quantityExact ? this.quantityMin : null,
          quantity_max: !this.quantityExact ? this.quantityMax : null,
          folders:
            this.selectedFolders.length &&
            !document.getElementById("folders").checked
              ? this.selectedFolders
              : null,
          tags: this.tags.length ? this.tags : null,
        },
      });
    },
  },
};
</script>
