<template>
  <div class="password-input">
    <input
      :value="modelValue"
      :type="visible ? 'text' : 'password'"
      :ref="propRef"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <span
      :class="{
        icon: true,
        active: visible,
      }"
      @click="visible = !visible"
    >
      <svg
        width="23"
        height="16"
        fill="none"
        viewBox="0 0 23 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25.83c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill="#D5D9E9"
        />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  props: ["modelValue", "propRef"],
  emits: ["update:modelValue"],
  data: function () {
    return {
      visible: false,
    };
  },
};
</script>
