<template>
  <div class="row">
    <div class="col-lg-12 patients-form">
      <patient-consent-form-informed />
    </div>
  </div>
</template>

<script>
import PatientConsentFormInformed from "@/views/shared/components/consents/PatientConsentFormInformed";
export default {
  name: "PatientConsentInformed",
  components: { PatientConsentFormInformed },
};
</script>
