<template>
  <div class="modal-dialog-main-section-column extended-width" v-if="!loading">
    <div class="modal-dialog-main-form">
      <div class="form-item" :class="{ error: validation.hasError('date') }">
        <date-time-input
          label="When:"
          ref="inputDate"
          enable-date-calendar
          enable-time-picker
          from-now
          input-mode
          show-label
          use-date
          :index="0"
          :original-start-date="newEvent.start"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setStart"
        ></date-time-input>
      </div>

      <!-- doctor -->
      <div
        class="form-item"
        :class="{ error: validation.hasError('newEvent.doctor') }"
        v-if="this.$hasRole(['Administrator'])"
      >
        <label class="item">{{ $t("label.who") }}: </label>
        <v-select
          :getOptionLabel="nameOptionLabel"
          :options="options.doctors"
          v-model="newEvent.doctor"
        >
          <template #option="{ first_name, last_name, id }">
            <span>
              <item-icon
                type="doctor-2"
                :class-name="colorClass(id, 'doctor')"
              ></item-icon
              >{{ first_name + " " + last_name }}</span
            >
          </template>
        </v-select>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newEvent.start'),
            }"
          >
            <label class="label">{{ $t("label.start") }}:</label>
            <date-time-input
              ref="workFrom"
              input-mode
              use-start-time
              :index="1"
              :original-start-date="newEvent.start"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-start-date="setStart"
            ></date-time-input>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('newEvent.end'),
            }"
          >
            <label class="label">{{ $t("label.end") }}:</label>
            <date-time-input
              ref="workTo"
              input-mode
              use-end-time
              :index="2"
              :original-start-date="newEvent.start"
              :original-end-date="newEvent.end"
              :time-format24="this.$store.getters.userHoursFormat24"
              @value-end-date="setEnd"
            ></date-time-input>
          </div>
        </div>
      </div>

      <div class="modal-dialog-main-form-item-action">
        <label class="checkbox">
          <input type="checkbox" @click.stop="toggleRepeats = !toggleRepeats" />
          <span>Repeats</span>
        </label>

        <v-select
          :class="{ 'time-format': true, disabled: !toggleRepeats }"
          v-model="newEvent.recurrence"
          placeholder="Select period:"
          label="name"
          :options="[
            {
              name: 'Daily',
              value: 'DAILY',
            },
            {
              name: 'Weekly',
              value: 'WEEKLY',
            },
          ]"
          :searchable="false"
        >
        </v-select>
      </div>

      <div class="form-item">
        <label class="item">{{ $t("label.yourReason") }}: </label>
        <textarea
          aria-label=""
          class="note auto-resize reason"
          rows="4"
          ref="textareaReason"
          v-model="newEvent.notes"
        ></textarea>
      </div>
      <div class="form-item flex-end margin-top-30">
        <a
          :class="{
            'button action dark disable-close': true,
          }"
          @click.prevent="submit"
        >
          {{ $t("button.addTimeOff") }}
        </a>
      </div>
    </div>
  </div>
  <div v-else class="loading"></div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import IMask from "imask";
import ItemIcon from "@/views/private/components/ItemIcon";
import SimpleVueValidator from "simple-vue3-validator";
import DateTimeInput from "@/views/private/components/DateTimeInput";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "TimeOff",
  props: ["event"],
  components: { ItemIcon, DateTimeInput },
  watch: {},
  validators: {
    date: function (value) {
      return Validator.value(value).required();
    },
    "newEvent.doctor": function (value) {
      return Validator.value(value).required();
    },
    "newEvent.start": function (value) {
      let self = this;
      return Validator.custom(function () {
        if (!Validator.isEmpty(value)) {
          if (self.newEvent.start && !self.isAfterNow(self.newEvent.start)) {
            return "invalid start time";
          }
        } else {
          return Validator.value(value).required();
        }
      });
    },
    "newEvent.end": function (value) {
      let self = this;
      return Validator.custom(function () {
        if (!Validator.isEmpty(value)) {
          if (
            self.newEvent.start &&
            self.newEvent.end &&
            !self.isBefore(self.newEvent.start, self.newEvent.end)
          ) {
            return "invalid end time";
          }
        } else {
          return Validator.value(value).required();
        }
      });
    },
  },
  data() {
    return {
      mask: {
        mask: "hh:mm", // enable date mask
        // you can provide your own blocks definitions, default blocks for date mask are:
        blocks: {
          hh: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
            placeholderChar: "h",
          },
          mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            placeholderChar: "m",
          },
        },
        lazy: false,
        overwrite: false,
      },
      options: {
        doctors: [],
      },
      newEvent: {
        type: "time_off",
        start: null,
        end: null,
        patient: null,
        notes: "",
        reason_for_visit: "",
        doctor: null,
        treatment: null,
        recurrence: null,
        room: null,
      },
      loading: false,
      date: null,
      cancelToken: {
        doctorsLoad: null,
      },
      toggleRepeats: false,
    };
  },
  created() {
    this.loadDoctors();
    this.newEvent.start =
      typeof this.$store.state.modal.selectedDate !== "undefined"
        ? this.$store.state.modal.selectedDate
        : this.getSelectedDateTimeWithStartWorkingTime(
            this.$store.state.currentDate,
            this.$store.getters.workingStartMinutes
          );
  },
  mounted() {},
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    setStart(value) {
      this.date = value;
      this.newEvent.start = value;
    },
    setEnd(value) {
      this.newEvent.end = value;
    },
    submit() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          if (self.newEvent.recurrence) {
            self.newEvent.recurrence = self.newEvent.recurrence.value;
          }
          let postData = self.preparePostDataWithDates(self.newEvent);
          HTTP({
            method: "POST",
            url: "/api/v1/events",
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.appointmentCreated"),
                });
                self.appointmentCreated = true;
                self.loading = false;
                EventBus.$emit("event-load");
                self.$emit("close");
              }
            })
            .catch(() => {
              self.loading = false;
            });
        }
      });
    },
    loadDoctors() {
      setCancelToken(this.cancelToken, "doctorsLoad");
      HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        cancelToken: this.cancelToken.doctorsLoad.token,
      }).then((response) => {
        if (response.data) {
          this.options.doctors = response.data;
        }
      });
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped></style>
