<template>
  <div class="pagination">
    <div class="pagination-label" v-if="showLabel">Go to page:</div>
    <div class="pagination-options">
      <v-select
        :options="options"
        v-model="pageMutated"
        append-to-body
        :calculate-position="withPopper"
      ></v-select>
    </div>
    <div class="pagination-controls">
      <div class="icon icon-arrow-left" @click.prevent="prev"></div>
      <div class="icon icon-arrow-right" @click.prevent="next"></div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "PaginationPage",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    options: {
      type: Array,
      default: () => {
        return [1];
      },
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showControls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placement: "top",
    };
  },
  computed: {
    pageMutated: {
      get() {
        return this.page;
      },
      set(value) {
        this.emitDropdownValue(value);
      },
    },
  },
  methods: {
    closeOptions() {
      this.dropdownOpen = false;
    },
    prev() {
      this.pageMutated = this.pageMutated - 1;
      if (this.pageMutated === 0) {
        this.pageMutated = 1;
      }
    },
    next() {
      this.pageMutated = this.pageMutated + 1;
      if (this.pageMutated > Math.max(...this.options)) {
        this.pageMutated = Math.max(...this.options);
      }
    },
    emitDropdownValue(value) {
      this.$emit("value-change", {
        page: value,
        perPage: this.perPage,
      });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>

<style scoped></style>
