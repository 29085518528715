<template>
  <div class="row">
    <div class="col-lg-12 payment">
      <div class="payment-wrapper">
        <div
          class="patient-title with-icon link-cursor"
          v-if="patient"
          @click="editPatient()"
        >
          <item-icon type="doctor"></item-icon>
          {{ patient.first_name + " " + patient.last_name }}
          <template v-if="patientBalance !== null">
            {{ formatPrice(patientBalance.balance) }}
          </template>
        </div>
        <div class="tabs">
          <template v-for="tab in tabs" :key="'tab_' + tab.key">
            <div
              v-show="tabsLoaded"
              class="tabs-tab"
              :class="{
                active: tab.key === activeTab,
                disabled: disabledTabs.indexOf(tab.key) > -1,
              }"
            >
              <button
                :class="{
                  'button tab': true,
                  active: tab.key === activeTab,
                }"
                :disabled="disabledTabs.indexOf(tab.key) > -1"
                @click.prevent="setActiveTab(tab.key)"
              >
                <div
                  v-if="
                    tab.key === 'events' ||
                    tab.key === 'products' ||
                    tab.key === 'plans'
                  "
                >
                  {{ $tc("label." + tab.label, 2) }}
                </div>
                <div v-else>{{ $t("label." + tab.label) }}</div>
                <div v-if="tab.key === 'checkout'" class="counter">
                  {{ checkoutItems ? checkoutItems.length : 0 }}
                </div>
              </button>
            </div>
          </template>
        </div>

        <payment-tab-events
          v-if="activeTab === 'events'"
          :add-to-checkout="addToCheckout"
          :cart-items="cartItems"
          :inactive-patient="this.isInactivePatient(this.patient)"
          :loading="loadingCartItems"
          :patient="patient"
          @loading="(value) => (this.loadingCartItems = value)"
        />

        <KeepAlive>
          <payment-tab-plans
            v-if="activeTab === 'plans'"
            :add-to-checkout="addToCheckout"
            :cart-items="cartItems"
            :inactive-patient="this.isInactivePatient(this.patient)"
            :loading="loadingCartItems"
            :patient="patient"
            @loading="(value) => (this.loadingCartItems = value)"
            @load-cart-items="loadCartItems()"
          />
        </KeepAlive>

        <KeepAlive>
          <payment-tab-products
            v-if="activeTab === 'products'"
            :add-to-checkout="addToCheckout"
            :cart-items="cartItems"
            :doctors="doctors"
            :loading="loadingCartItems"
            :patient="patient"
            @loading="(value) => (this.loadingCartItems = value)"
            @load-cart-items="loadCartItems()"
            @set-products="(products) => (this.products = products)"
          />
        </KeepAlive>

        <payment-tab-checkout
          v-if="activeTab === 'checkout'"
          :remove-from-checkout="removeCartItem"
          :cart-items="cartItems"
          :doctors="doctors"
          :patient="patient"
          @set-active-tab="(value) => (this.activeTab = value)"
          @payment-status="(value) => (this.paymentStatus = value)"
          @load-cart-items="loadCartItems()"
        />

        <KeepAlive>
          <payment-tab-overview
            v-if="activeTab === 'payment_overview'"
            :payment-status="paymentStatus"
            :patient="patient"
            ref="patientsPayment"
            @payment-status="(value) => (this.paymentStatus = value)"
            @load-patient-balance="loadPatientBalance()"
          />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import axios from "axios";
import ItemIcon from "../components/ItemIcon";
import PaymentTabEvents from "@/views/private/components/payments/tabs/PaymentTabEvents";
import PaymentTabPlans from "@/views/private/components/payments/tabs/PaymentTabPlans";
import PaymentTabProducts from "@/views/private/components/payments/tabs/PaymentTabProducts";
import PaymentTabCheckout from "@/views/private/components/payments/tabs/PaymentTabCheckout";
import PaymentTabOverview from "@/views/private/components/payments/tabs/PaymentTabOverview";

export default {
  name: "PaymentsPatient",
  components: {
    PaymentTabOverview,
    PaymentTabCheckout,
    PaymentTabProducts,
    PaymentTabPlans,
    PaymentTabEvents,
    ItemIcon,
  },
  data() {
    return {
      promises: [],
      activeTab: "events",
      disabledTabs: [],
      tabs: [
        {
          key: "events",
          label: "appointment",
        },
        {
          key: "products",
          label: "product",
        },
        {
          key: "plans",
          label: "plan",
        },
        {
          key: "checkout",
          label: "checkout",
        },
        {
          key: "payment_overview",
          label: "paymentOverview",
        },
      ],
      tabsLoaded: false,
      products: [],
      doctors: [],
      cartItems: [],
      patient: null,
      patientBalance: null,
      cancelToken: {
        cartItemsToken: null,
        doctorsToken: null,
        initCartItemsToken: null,
        patientToken: null,
        patientBalanceToken: null,
      },
      paymentStatus: null,
      loadingCartItems: false,
      expandedId: null,
    };
  },
  created() {
    this.promises.push(this.initializeCartItems());
    this.promises.push(this.loadPatient());
    this.promises.push(this.loadPatientBalance());
    this.promises.push(this.loadDoctors());
  },
  mounted() {
    let scope = this;
    axios.all(this.promises).then(function () {
      scope.loadCartItems();
    });
  },
  unmounted() {
    this.$store.dispatch("removePaymentItem", []);
    cancelTokens(this.cancelToken);
  },
  computed: {
    checkoutItems() {
      return this.cartItems.filter(function (item) {
        return item.status === "in_checkout";
      });
    },
  },
  methods: {
    filterTabs(tabs, patient) {
      let newTabs = [...tabs];
      if (patient && this.isInactivePatient(patient)) {
        newTabs = newTabs.filter(
          (item) => !["products", "checkout"].includes(item.key)
        );
      }
      this.tabsLoaded = true;
      return newTabs;
    },
    setActiveTab(key) {
      if (this.disabledTabs.indexOf(key) > -1) {
        return;
      }

      this.activeTab = key;

      if (key === "payment_overview" && this.paymentStatus !== null) {
        this.disabledTabs.indexOf("events") === -1
          ? this.disabledTabs.push("events")
          : null;

        this.disabledTabs.indexOf("products") === -1
          ? this.disabledTabs.push("products")
          : null;
      }
    },
    loadPatient() {
      setCancelToken(this.cancelToken, "patientToken");

      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
        cancelToken: this.cancelToken.patientToken.token,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
            this.tabs = this.filterTabs(this.tabs, response.data);
          }
        })
        .catch(() => {});
    },
    loadPatientBalance() {
      setCancelToken(this.cancelToken, "patientBalanceToken");

      return HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/patients/" +
          this.$route.params.id +
          "/patient-balance",
        cancelToken: this.cancelToken.patientBalanceToken.token,
      })
        .then((response) => {
          if (response.data.data) {
            this.patientBalance = response.data.data;
          }
        })
        .catch(() => {});
    },
    loadDoctors() {
      setCancelToken(this.cancelToken, "doctorsToken");

      HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        data: {},
        cancelToken: this.cancelToken.doctorsToken.token,
      })
        .then((response) => {
          if (response.data) {
            this.doctors = response.data;
            this.doctors.unshift({
              id: null,
              first_name: this.$t("label.generalClinic"),
              last_name: "",
            });
          }
        })
        .catch(() => {});
    },
    initializeCartItems() {
      setCancelToken(this.cancelToken, "initCartItemsToken");
      this.loadingCartItems = true;

      return HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/patients/" +
          this.$route.params.id +
          "/cart-items/initialize",
        cancelToken: this.cancelToken.initCartItemsToken.token,
        params: {},
      })
        .then(() => {
          this.loadingCartItems = false;
        })
        .catch(() => {
          this.loadingCartItems = false;
        });
    },
    loadCartItems() {
      setCancelToken(this.cancelToken, "cartItemsToken");

      this.loadingCartItems = true;
      return HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/patients/" +
          this.$route.params.id +
          "/cart-items/",
        data: {},
        cancelToken: this.cancelToken.cartItemsToken.token,
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.cartItems = response.data.data;
          }
          this.loadingCartItems = false;
        })
        .catch(() => {
          this.loadingCartItems = false;
        });
    },
    addToCheckout(item) {
      if (this.isInactivePatient(this.patient)) {
        return;
      }
      return HTTP({
        method: "PUT",
        url:
          "/api/v1/purchases/patients/" +
          this.$route.params.id +
          "/cart-items/" +
          item.id,
        data: {
          status: "in_checkout",
        },
      })
        .then(() => {
          if (item.item_type === "Appointment") {
            this.addPrescriptionProducts(item);
          } else {
            this.loadCartItems();
          }
        })
        .catch(() => {});
    },
    addPrescriptionProducts(cartItem) {
      let promises = [];
      let scope = this;

      if (
        cartItem.item_data &&
        cartItem.item_data.soap_note &&
        cartItem.item_data.soap_note.prescriptions
      ) {
        cartItem.item_data.soap_note.prescriptions.forEach(function (item) {
          let index = scope.products.findIndex(function (product) {
            return product.id === item.id;
          });

          if (index > -1) {
            promises.push(
              scope.addPrescriptionProduct(
                scope.products[index],
                item.quantity,
                cartItem.item_data.doctor_id,
                cartItem.item_data.soap_note
              )
            );
          }
        });
      }
      axios.all(promises).then(function () {
        scope.loadCartItems();
      });
    },
    addPrescriptionProduct(product, quantity, doctor_id, soapNote) {
      return HTTP({
        method: "POST",
        url:
          "/api/v1/purchases/patients/" + this.$route.params.id + "/cart-items",
        data: {
          item_type: "Product",
          status: "in_checkout",
          quantity: quantity,
          price: product.price,
          item_data: Object.assign(product, {
            soap_note: soapNote,
          }),
          product_sold_by_doctor: {
            id: doctor_id,
          },
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    removeCartItem(item) {
      HTTP({
        method: "DELETE",
        async: false,
        url:
          "/api/v1/purchases/patients/" +
          this.$route.params.id +
          "/cart-items/" +
          item.id,
      })
        .then(() => {
          this.loadCartItems();
        })
        .catch(() => {});
    },
    editPatient() {
      if (this.patient.form_id) {
        this.$router.push({
          name: "patientForm",
          params: {
            id: this.patient.id,
            form_id: this.patient.form_id,
          },
        });
      }
    },
  },
};
</script>
