<template>
  <div
    class="form patients-schedule patients-schedule-register"
    v-if="this.show || this.$route.name === 'patientSetPassword'"
  >
    <div class="patients-schedule-inner">
      <div class="patients-schedule-header">
        <h1 class="patients-schedule-header-title">
          {{
            this.$route.name === "patientSetPassword"
              ? $t("clinic.setPassword")
              : $t("clinic.verifyTitle")
          }}
        </h1>
        <span class="patients-schedule-header-text">{{
          $t("clinic.verifyText")
        }}</span>
      </div>
      <form
        @submit.prevent="setPassword()"
        :class="{
          'patients-schedule-content': true,
          disabled: formDisable,
        }"
      >
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.password'),
          }"
        >
          <label class="item">{{ $t("label.enterPassword") }} </label>
          <div class="password-input">
            <input
              v-model="form.password"
              :type="passwordVisible ? 'text' : 'password'"
              ref="password"
            />
            <span
              :class="{
                icon: true,
                active: passwordVisible,
              }"
              @click="passwordVisible = !passwordVisible"
            >
              <svg
                width="23"
                height="16"
                fill="none"
                viewBox="0 0 23 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25.83c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  fill="#D5D9E9"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.password_confirmation'),
          }"
        >
          <label class="item">{{ $t("label.passwordConfirmation") }} </label>
          <div class="password-input">
            <input
              v-model="form.password_confirmation"
              :type="passwordConfirmationVisible ? 'text' : 'password'"
              ref="password"
            />
            <span
              :class="{
                icon: true,
                active: passwordConfirmationVisible,
              }"
              @click="
                passwordConfirmationVisible = !passwordConfirmationVisible
              "
            >
              <svg
                width="23"
                height="16"
                fill="none"
                viewBox="0 0 23 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25.83c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  fill="#D5D9E9"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="patients-schedule-action">
          <button
            :class="{
              button: true,
              dark: true,
              height: true,
              disabled: formDisable,
            }"
            type="submit"
          >
            {{ $t("button.completeRegistration") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
import { HTTP, HTTP_PUBLIC } from "@/services/api";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientVerify",
  validators: {
    "form.password": function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "form.password_confirmation, form.password": function (
      password_confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(password_confirmation)
          .required()
          .match(password);
      }
    },
  },
  data() {
    return {
      formDisable: false,
      show: false,
      passwordVisible: false,
      passwordConfirmationVisible: false,
      form: {
        password: null,
        password_confirmation: null,
      },
    };
  },
  mounted() {
    this.$route.name !== "patientSetPassword" && this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      HTTP_PUBLIC({
        method: "GET",
        url: `/api/v1/email/verify/${this.$route.params.token}`,
      })
        .then(() => {
          this.show = true;
        })
        .catch((error) => {
          this.$notify({
            duration: -1,
            group: "notification",
            closeOnClick: false,
            type: "error",
            title: this.$t("notification.error"),
            text: this.formatError(error, this.$t("notification.error")),
          });
        });
    },
    setPassword() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP_PUBLIC({
            method: "POST",
            url: "/api/v1/patients/password",
            data: {
              password: self.form.password,
              password_confirmation: self.form.password_confirmation,
              token: self.$route.params.token,
            },
          })
            .then((response) => {
              if (response.data) {
                self.$store.dispatch("setAuth", response.data).then(() => {
                  self.loadUser();
                });
              }
              self.formDisable = false;
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.checkCredentials"),
              });
              self.formDisable = false;
            });
        } else {
          return false;
        }
      });
    },
    // login(data) {
    //   let self = this;
    //   self.$validate().then(function(success) {
    //     if (success) {
    //       self.formDisable = true;
    //       HTTP_PUBLIC({
    //         method: "POST",
    //         url: `/api/v1/clinics/${self.$route.params.clinic_slug}/patients/login`,
    //         data: data
    //       })
    //         .then(response => {
    //           self.$store.dispatch("setAuth", response.data).then(() => {
    //             self.loadUser();
    //           });
    //         })
    //         .catch(() => {
    //           self.$notify({
    //             group: "notification",
    //             type: "error",
    //             title: self.$t("notification.error"),
    //             text: self.$t("notification.checkCredentials")
    //           });
    //           self.formDisable = false;
    //         });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    loadUser() {
      HTTP({
        method: "GET",
        url: "/api/v1/patients/profile",
      })
        .then((response) => {
          this.$store.dispatch("setUser", response.data).then(() => {
            this.$store.dispatch("setUserType", "patient");
            this.$router.push({ name: "patientSchedule" });
          });
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.error"),
            text: this.$t("notification.checkVerified"),
          });
          this.loading = false;
          this.formDisable = false;
        });
    },
  },
};
</script>
