<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>{{ $t("label.changePassword") }}</div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <form
                    @submit.prevent="handlePasswordChange()"
                    :class="{
                      disabled: formDisable,
                    }"
                  >
                    <div
                      class="form-item"
                      :class="{
                        error: validation.hasError('form.password'),
                      }"
                    >
                      <label class="item">{{ $t("label.newPassword") }} </label>
                      <password-input
                        :model-value="form.password"
                        @update:modelValue="
                          (newValue) => (form.password = newValue)
                        "
                        prop-ref="password"
                      />
                    </div>
                    <div
                      class="form-item"
                      :class="{
                        error: validation.hasError(
                          'form.password_confirmation'
                        ),
                      }"
                    >
                      <label class="item"
                        >{{ $t("label.passwordConfirmation") }}
                      </label>
                      <password-input
                        :model-value="form.password_confirmation"
                        @update:modelValue="
                          (newValue) => (form.password_confirmation = newValue)
                        "
                        prop-ref="password_confirmation"
                      />
                    </div>
                    <div class="form-item action">
                      <button
                        :class="{
                          button: true,
                          action: true,
                          disabled: formDisable,
                        }"
                        type="submit"
                      >
                        {{ $t("button.submit") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import PasswordInput from "@/views/private/components/PasswordInput";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "DoctorChangePassword",
  props: ["doctor"],
  components: {
    PasswordInput,
  },
  validators: {
    "form.password": function (value) {
      return Validator.value(value).required().minLength(8);
    },
    "form.password_confirmation, form.password": function (
      password_confirmation,
      password
    ) {
      if (password && password.length) {
        return Validator.value(password_confirmation)
          .required()
          .match(password);
      }
    },
  },
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
      },
      passwordVisible: false,
      passwordConfirmationVisible: false,
      formDisable: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    handlePasswordChange() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP({
            method: "POST",
            url: `/api/v1/doctors/${self.doctor.id}/change-password`,
            data: {
              password: self.form.password,
              password_confirmation: self.form.password_confirmation,
            },
          })
            .then(() => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.done"),
                text: self.$t("notification.doctorPasswordUpdated"),
              });
              self.formDisable = false;
              self.form.password = "";
              self.form.password_confirmation = "";
              self.validation.reset();
              self.closeModal();
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.passwordNotUpdatedExtended"),
              });
              self.formDisable = false;
              self.closeModal();
            });
        } else {
          return false;
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
