<template>
  <!-- Events tab start-->
  <div class="payment-tab-single">
    <template v-if="!loading">
      <div
        class="payment-tab-single-items"
        v-for="item in filteredEvents"
        :key="'event_' + item.id"
        :class="{
          featured:
            customFormat(item.item_data.start, 'MM/dd/yyyy') ===
            customFormat(currentDate, 'MM/dd/yyyy'),
        }"
      >
        <payments-single-event
          :item="item"
          :patient="patient"
          @add-to-checkout="(item) => this.addToCheckout(item)"
        ></payments-single-event>
      </div>
      <div class="payment-submit" v-if="filteredEvents.length">
        <div
          :class="{
            button: true,
            action: true,
            disabled: this.inactivePatient,
          }"
          @click="addAllEvents"
        >
          {{ $t("button.addAll") }}
        </div>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
  <!-- Events tab end-->
</template>

<script>
import axios from "axios";
import PaymentsSingleEvent from "@/views/private/components/payments/PaymentsSingleEvent";

export default {
  name: "PaymentTabEvents",
  components: { PaymentsSingleEvent },
  props: {
    cartItems: {
      type: Array,
      default() {
        return [];
      },
    },
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inactivePatient: {
      type: Boolean,
      default: false,
    },
    addToCheckout: {
      type: Function,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  computed: {
    filteredEvents() {
      return this.cartItems.filter(function (item) {
        return item.item_type === "Appointment" && item.status === "active";
      });
    },
  },
  methods: {
    addAllEvents() {
      if (this.inactivePatient) {
        return;
      }
      let scope = this;
      let promises = [];
      this.filteredEvents.forEach(function (item) {
        promises.push(scope.addToCheckout(item));
      });
      this.$emit("loading", true);
      axios.all(promises).then(function () {
        scope.$emit("loading", false);
      });
    },
  },
};
</script>
