<template>
  <span :class="className ? className : ''" v-tooltip="tooltip">
    <svg
      v-if="type === 'doctor'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="a">
          <rect
            width="8"
            height="8"
            transform="translate(26 413)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-26 -413)" clip-path="url(#a)">
        <g transform="translate(25.429 412.429)">
          <path
            d="M7.714,7.774a4.289,4.289,0,0,0-.928-2.7,2.827,2.827,0,0,0-4.428,0,4.288,4.288,0,0,0-.928,2.7,7.656,7.656,0,0,0,3.142.8A6.992,6.992,0,0,0,7.714,7.774Z"
          />
          <circle
            cx="1.571"
            cy="1.571"
            r="1.571"
            transform="translate(3 0.571)"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'doctor-2'"
      xmlns="http://www.w3.org/2000/svg"
      height="8"
      width="8"
      viewBox="0 0 512 512"
      xml:space="preserve"
    >
      <path
        d="M507.84 185.563 388.01 47.984c-.197-.227-.404-.432-.608-.645-.079-.082-.153-.168-.235-.249-3.473-3.487-7.829-5.003-12.097-4.912H136.92c-4.265-.088-8.616 1.428-12.087 4.91-.083.082-.159.171-.24.255-.202.211-.407.415-.602.639L4.16 185.563A16.916 16.916 0 0 0 0 196.674v213.83c0 9.342 7.574 16.916 16.916 16.916h68.02c9.342 0 16.916-7.574 16.916-16.916V240.762l17.976-21.286v97.621h86.066v69.149h-86.066v66.665c0 9.342 7.574 16.916 16.916 16.916h238.507c9.342 0 16.916-7.574 16.916-16.916v-66.665h-86.066v-69.149h86.066v-97.621l17.976 21.286v169.743c0 9.342 7.574 16.916 16.916 16.916h68.022c9.342 0 16.916-7.574 16.916-16.916V196.674a16.897 16.897 0 0 0-4.157-11.111zM358.337 80.147 256 207.509 153.662 80.147V59.095h53.504L256 207.509l48.833-148.415h53.504v21.053z"
      />
    </svg>
    <svg
      v-if="type === 'room'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="b">
          <rect
            width="8"
            height="8"
            transform="translate(26 413)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-26 -413)" clip-path="url(#b)">
        <g transform="translate(26 413)">
          <path
            d="M4,0A2,2,0,0,0,2,2v.167a.5.5,0,0,0,1,0V2A1,1,0,0,1,5,2c0,.405-.4.735-1.219,1.009-.1.034-.237.069-.383.107C2.775,3.277,2,3.478,2,4V7.333A.667.667,0,0,0,2.667,8H5.333A.667.667,0,0,0,6,7.333V2A2,2,0,0,0,4,0Z"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'treatment'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="c">
          <rect
            width="8"
            height="8"
            transform="translate(26 413)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-26 -413)" clip-path="url(#c)">
        <path
          d="M5.392,0S6.1,1.73,3.07,2.889c-1.647.632-2.91,2.248-1.4,4.746.125-1.117.662-3.578,2.966-4.14,0,0-.022.008-.062.027-.354.165-2.1,1.144-2.272,4.406,1.1.155,3.688.259,4.454-1.985C7.767,2.98,5.392,0,5.392,0Z"
          transform="translate(26 413)"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'notes'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="d">
          <rect
            width="16"
            height="16"
            transform="translate(492 533)"
            fill="#fff"
            stroke="#6f5bcf"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-492 -533)" clip-path="url(#d)">
        <path
          d="M14.542.655a.516.516,0,0,0,0,1.032.426.426,0,0,1,.426.425V13.886a.426.426,0,0,1-.426.425.516.516,0,0,0,0,1.032A1.46,1.46,0,0,0,16,13.886V2.113A1.46,1.46,0,0,0,14.542.655Zm-2.392,0a.516.516,0,0,0,0,1.032.426.426,0,0,1,.426.425V13.886a.426.426,0,0,1-.426.425.516.516,0,0,0,0,1.032,1.459,1.459,0,0,0,1.458-1.458V2.113A1.459,1.459,0,0,0,12.15.655Zm-.934,1.458V13.886a1.459,1.459,0,0,1-1.458,1.458H4.928a.516.516,0,0,1-.516-.517c0-.461,0-1.378,0-3.247a.647.647,0,0,0-.646-.646c-1.869,0-2.786,0-3.247,0A.516.516,0,0,1,0,10.417v-8.3A1.458,1.458,0,0,1,1.458.655h8.3a1.459,1.459,0,0,1,1.458,1.458Zm-7.3,9.831v2.884a.516.516,0,0,1-.881.365C-.08,12.077.051,12.257.01,12.043a.516.516,0,0,1,.506-.615H3.4a.516.516,0,0,1,.516.516Z"
          transform="translate(492 533)"
          fill="#cfd1d7"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'list'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="e">
          <rect
            width="16"
            height="16"
            transform="translate(492 430)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-492 -430)" clip-path="url(#e)">
        <path
          d="M13.6,1.6H10.252a2.385,2.385,0,0,0-4.5,0H2.4A1.6,1.6,0,0,0,.8,3.2V14.4A1.6,1.6,0,0,0,2.4,16H13.6a1.6,1.6,0,0,0,1.6-1.6V3.2A1.6,1.6,0,0,0,13.6,1.6ZM8,1.6a.8.8,0,1,1-.8.8A.8.8,0,0,1,8,1.6ZM9.6,12.8H4V11.2H9.6ZM12,9.6H4V8h8Zm0-3.2H4V4.8h8Z"
          transform="translate(492 430)"
          fill="#cfd1d7"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'calendar'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="f">
          <rect
            width="16"
            height="16"
            transform="translate(492 291)"
            fill="#fff"
            stroke="#b2b5bf"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-492 -291)" clip-path="url(#f)">
        <g transform="translate(492 291)">
          <path
            d="M2.5,10H4.167a.5.5,0,0,0,.5-.5V7.833a.5.5,0,0,0-.5-.5H2.5a.5.5,0,0,0-.5.5V9.5A.5.5,0,0,0,2.5,10Zm0,0"
            fill="#cfd1d7"
          />
          <path
            d="M2.5,14H4.167a.5.5,0,0,0,.5-.5V11.833a.5.5,0,0,0-.5-.5H2.5a.5.5,0,0,0-.5.5V13.5A.5.5,0,0,0,2.5,14Zm0,0"
            fill="#cfd1d7"
          />
          <path
            d="M7.167,10H8.833a.5.5,0,0,0,.5-.5V7.833a.5.5,0,0,0-.5-.5H7.167a.5.5,0,0,0-.5.5V9.5A.5.5,0,0,0,7.167,10Zm0,0"
            fill="#cfd1d7"
          />
          <path
            d="M7.167,14H8.833a.5.5,0,0,0,.5-.5V11.833a.5.5,0,0,0-.5-.5H7.167a.5.5,0,0,0-.5.5V13.5A.5.5,0,0,0,7.167,14Zm0,0"
            fill="#cfd1d7"
          />
          <path
            d="M11.833,10H13.5a.5.5,0,0,0,.5-.5V7.833a.5.5,0,0,0-.5-.5H11.833a.5.5,0,0,0-.5.5V9.5A.5.5,0,0,0,11.833,10Zm0,0"
            fill="#cfd1d7"
          />
          <path
            d="M14,2h-.667V.667A.667.667,0,0,0,12.667,0H12a.667.667,0,0,0-.667.667V2H4.667V.667A.667.667,0,0,0,4,0H3.333a.667.667,0,0,0-.667.667V2H2A2,2,0,0,0,0,4V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V4A2,2,0,0,0,14,2Zm.667,12a.668.668,0,0,1-.667.667H2A.668.668,0,0,1,1.333,14V6.693H14.667Zm0,0"
            fill="#cfd1d7"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'clock'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="g">
          <rect
            id="Rectangle_79"
            data-name="Rectangle 79"
            width="16"
            height="16"
            transform="translate(552 224)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_22"
        data-name="Mask Group 22"
        transform="translate(-552 -224)"
        clip-path="url(#g)"
      >
        <g id="clock" transform="translate(552 224)">
          <path
            id="Path_28"
            data-name="Path 28"
            d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.594A6.594,6.594,0,1,1,14.594,8,6.6,6.6,0,0,1,8,14.594Z"
            fill="#cfd1d7"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M8,2.344A5.656,5.656,0,1,0,13.656,8,5.663,5.663,0,0,0,8,2.344Zm.469,5.85L5.326,11.337l-.663-.663L7.531,7.806V3.75h.938Z"
            fill="#cfd1d7"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'edit'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <defs>
        <clipPath id="h">
          <rect
            id="Rectangle_80"
            data-name="Rectangle 80"
            width="10"
            height="10"
            transform="translate(670 609)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_24"
        data-name="Mask Group 24"
        transform="translate(-670 -609)"
        clip-path="url(#h)"
      >
        <g id="pencil" transform="translate(670 609)">
          <path
            id="Path_32"
            data-name="Path 32"
            d="M6.176,1.675l-5.5,5.5a.219.219,0,0,0-.057.1L.006,9.725a.217.217,0,0,0,.21.269.215.215,0,0,0,.052-.007l2.448-.61a.216.216,0,0,0,.1-.057l5.5-5.5Zm0,0"
            fill="#cfd1d7"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M9.683.924,9.07.312a1.109,1.109,0,0,0-1.532,0l-.75.75L8.933,3.206l.75-.75a1.083,1.083,0,0,0,0-1.532Zm0,0"
            fill="#cfd1d7"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'delete'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="i">
          <rect
            width="8"
            height="8"
            transform="translate(888 320)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-888 -320)" clip-path="url(#i)">
        <g transform="translate(888 320)">
          <path
            d="M6.625,1H5.25V.75A.75.75,0,0,0,4.5,0h-1a.75.75,0,0,0-.75.75V1H1.375a.625.625,0,0,0-.625.625v.5a.25.25,0,0,0,.25.25H7a.25.25,0,0,0,.25-.25v-.5A.625.625,0,0,0,6.625,1ZM3.25.75A.25.25,0,0,1,3.5.5h1a.25.25,0,0,1,.25.25V1H3.25Z"
            fill="#8b9297"
          />
          <path
            d="M1.224,2.875a.078.078,0,0,0-.078.082l.206,4.329A.749.749,0,0,0,2.1,8H5.9a.749.749,0,0,0,.749-.714l.206-4.329a.078.078,0,0,0-.078-.082ZM5,3.5a.25.25,0,0,1,.5,0V6.75a.25.25,0,0,1-.5,0Zm-1.25,0a.25.25,0,0,1,.5,0V6.75a.25.25,0,0,1-.5,0ZM2.5,3.5a.25.25,0,0,1,.5,0V6.75a.25.25,0,0,1-.5,0Z"
            fill="#8b9297"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'shortcut'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="j">
          <rect
            width="8"
            height="8"
            transform="translate(888 366)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-888 -366)" clip-path="url(#j)">
        <path
          d="M8,3.653,4.675.008V2.183H3.969A3.968,3.968,0,0,0,0,6.151V7.3L.314,6.96A5.634,5.634,0,0,1,4.475,5.124h.2V7.3Zm0,0"
          transform="translate(888 366.344)"
          fill="#8b9297"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'insuranceLedger'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330 330"
      xml:space="preserve"
    >
      <path
        d="M308.625 70.576a14.996 14.996 0 0 0-13.216-2.41c-8.328 2.43-16.824 3.662-25.251 3.662-24.195 0-48.153-7.088-65.733-19.445C188.902 41.471 180 27.846 180 15c0-8.284-6.717-15-15-15-8.285 0-15 6.716-15 15 0 12.846-8.903 26.471-24.426 37.383-17.58 12.357-41.538 19.445-65.732 19.445-8.428 0-16.924-1.232-25.251-3.662a15 15 0 0 0-19.202 14.437c.203 79.771 35.262 142.536 56.142 172.85 14.604 21.201 30.958 39.488 47.296 52.888C130.874 318.222 148.664 330 165 330c16.334 0 34.125-11.778 46.172-21.659 16.338-13.399 32.693-31.686 47.296-52.888 20.881-30.314 55.94-93.079 56.143-172.85a15.002 15.002 0 0 0-5.986-12.027zm-200.58 154.609c0-31.458 25.5-56.956 56.955-56.956-20.221 0-36.615-16.393-36.615-36.614C128.385 111.392 144.779 95 165 95c20.221 0 36.615 16.392 36.615 36.614 0 20.222-16.395 36.614-36.615 36.614 31.455 0 56.955 25.498 56.955 56.956h-113.91z"
      />
    </svg>
    <svg
      v-if="type === 'campaign'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="k">
          <rect
            width="8"
            height="8"
            transform="translate(888 343)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-888 -343)" clip-path="url(#k)">
        <g transform="translate(888 343)">
          <path
            d="M5.833,3.667A2.167,2.167,0,1,0,8,5.833,2.169,2.169,0,0,0,5.833,3.667Zm.833,2.5h-.5v.5a.333.333,0,0,1-.667,0v-.5H5A.333.333,0,0,1,5,5.5h.5V5a.333.333,0,0,1,.667,0v.5h.5a.333.333,0,0,1,0,.667Z"
            fill="#d7dde1"
          />
          <path
            d="M6.333,0H1A1,1,0,0,0,1,2H6.333a1,1,0,0,0,0-2ZM1,1.333A.333.333,0,1,1,1.333,1,.333.333,0,0,1,1,1.333Zm1.333,0A.333.333,0,1,1,2.667,1,.333.333,0,0,1,2.333,1.333Z"
            fill="#d7dde1"
          />
          <path
            d="M6.333,2.667H1a1,1,0,0,0,0,2H3.253A2.826,2.826,0,0,1,7.3,3.41a.991.991,0,0,0-.963-.743ZM1,4a.333.333,0,1,1,.333-.333A.334.334,0,0,1,1,4ZM2.333,4a.333.333,0,1,1,.333-.333A.334.334,0,0,1,2.333,4Z"
            fill="#d7dde1"
          />
          <path
            d="M3,5.833a2.763,2.763,0,0,1,.047-.5H1a1,1,0,1,0,0,2H3.43A2.807,2.807,0,0,1,3,5.833ZM1,6.667a.333.333,0,1,1,.333-.333A.334.334,0,0,1,1,6.667Zm1.333,0a.333.333,0,1,1,.333-.333A.334.334,0,0,1,2.333,6.667Z"
            fill="#d7dde1"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'documents'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="l">
          <rect
            width="18"
            height="18"
            transform="translate(282 174)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-282 -174)" clip-path="url(#l)">
        <g transform="translate(282 174.844)">
          <path
            d="M15,2.438H7.125S6.059.75,5.438.75H2.625A1.125,1.125,0,0,0,1.5,1.875V14.813a1.125,1.125,0,0,0,1.125,1.125H15a1.125,1.125,0,0,0,1.125-1.125V3.563A1.125,1.125,0,0,0,15,2.438Z"
            transform="translate(0.188 -0.75)"
            fill="#fff"
          />
          <path
            d="M1.5,3.75H16.125v4.5H1.5Z"
            transform="translate(0.188 -0.375)"
            fill="#e4e7e7"
          />
          <path
            d="M1.125,4.75h15.75A1.125,1.125,0,0,1,18,5.875l-1.125,9.562a1.125,1.125,0,0,1-1.125,1.125H2.25a1.125,1.125,0,0,1-1.125-1.125L0,5.875A1.125,1.125,0,0,1,1.125,4.75Z"
            transform="translate(0 -0.25)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'inventory-view'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="m">
          <rect
            width="18"
            height="18"
            transform="translate(21 132)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-21 -132)" clip-path="url(#m)">
        <g transform="translate(21 132)">
          <path
            d="M3,5.063A3.567,3.567,0,0,1,6.563,1.5h6.85A2.058,2.058,0,0,0,11.438,0H2.813A2.064,2.064,0,0,0,.75,2.063V13.688A2.064,2.064,0,0,0,2.813,15.75H3Z"
            fill="#fff"
          />
          <path
            d="M15.188,3H6.563A2.064,2.064,0,0,0,4.5,5.063V15.938A2.064,2.064,0,0,0,6.563,18h8.625a2.064,2.064,0,0,0,2.063-2.062V5.063A2.064,2.064,0,0,0,15.188,3Zm-1.5,12.75H8.063a.563.563,0,0,1,0-1.125h5.625a.563.563,0,0,1,0,1.125Zm0-3H8.063a.563.563,0,0,1,0-1.125h5.625a.563.563,0,0,1,0,1.125Zm0-2.625H8.063A.563.563,0,0,1,8.063,9h5.625a.563.563,0,0,1,0,1.125Zm0-3H8.063A.563.563,0,0,1,8.063,6h5.625a.563.563,0,0,1,0,1.125Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'filter'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502.664 502.664"
      xml:space="preserve"
    >
      <g fill="#010002">
        <path
          d="M52.137 144.664h398.369c28.797 0 52.158-25.756 52.158-57.594 0-31.86-23.361-57.659-52.158-57.659H52.137C23.404 29.412 0 55.21 0 87.07c0 31.839 23.404 57.594 52.137 57.594zm19.327-93.056c19.586 0 35.484 15.876 35.484 35.462s-15.876 35.462-35.484 35.462c-19.565 0-35.462-15.876-35.462-35.462 0-19.586 15.897-35.462 35.462-35.462zM450.506 193.716H52.137C23.404 193.716 0 219.537 0 251.375s23.404 57.594 52.137 57.594h398.369c28.797 0 52.158-25.756 52.158-57.594s-23.361-57.659-52.158-57.659zm-126.534 93.121c-19.586 0-35.462-15.876-35.462-35.462 0-19.586 15.855-35.462 35.462-35.462s35.484 15.876 35.484 35.462c-.022 19.586-15.898 35.462-35.484 35.462zM450.506 357.999H52.137C23.404 357.999 0 383.82 0 415.658c0 31.817 23.404 57.637 52.137 57.594h398.369c28.797 0 52.158-25.777 52.158-57.594s-23.361-57.659-52.158-57.659zm-235.51 93.122c-19.586 0-35.462-15.876-35.462-35.484s15.855-35.462 35.462-35.462 35.462 15.855 35.462 35.462-15.876 35.484-35.462 35.484z"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'file'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="o">
          <rect
            width="18"
            height="18"
            transform="translate(282 321)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-282 -321)" clip-path="url(#o)">
        <g transform="translate(283.114 321)">
          <path
            d="M2.114,0h9.469L16.6,5V16.717a1.114,1.114,0,0,1-1.114,1.114H2.114A1.114,1.114,0,0,1,1,16.717V1.114A1.115,1.115,0,0,1,2.114,0Z"
            transform="translate(-1)"
            fill="#e4e7e7"
          />
          <path
            d="M15.5,5.014H11.614A1.115,1.115,0,0,1,10.5,3.9V.01Z"
            transform="translate(0.087 0.001)"
            fill="#c2c5c7"
          />
          <path
            d="M3,6.958V8.072H14.145V6.958ZM3,10.3H14.145V9.187H3ZM3,12.53H14.145V11.416H3Zm0,2.229H14.145V13.645H3ZM9.129,4.729H3V5.843H9.13V4.729Zm0-2.229H3V3.614H9.13V2.5Z"
            transform="translate(-0.771 0.286)"
            fill="#ccd0d2"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'notify'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <defs>
        <clipPath id="p">
          <rect
            width="10"
            height="10"
            transform="translate(670 609)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-670 -609)" clip-path="url(#p)">
        <path
          d="M10,0,0,5.625,3.2,6.809,8.125,2.188,4.376,7.246h0V10L6.167,7.909l2.27.841Z"
          transform="translate(670 609)"
        />
      </g>
    </svg>
    <svg
      id="Capa_1"
      v-if="type === 'pharmacy'"
      viewBox="0 0 295.284 295.284"
      xml:space="preserve"
    >
      <g>
        <path
          d="M287.229,47.118c10.739-10.739,10.739-28.151,0-38.891c-10.738-10.738-28.15-10.74-38.891,0l-100.15,100.149h77.782
        L287.229,47.118z"
        />
        <path
          d="M281.167,135.778H56.851c-0.085,8.477,0.492,16.845,1.72,25c3.612,24.004,12.817,46.166,27.074,63.856
        c4.332,5.375,3.486,13.244-1.889,17.576c-5.371,4.329-13.242,3.489-17.576-1.889c-17.774-22.054-28.962-49.768-32.815-79.544
        c-1.062-8.202-1.562-16.561-1.495-25H12.5c-6.903,0-12.5,5.597-12.5,12.5c0,57.693,23.964,111.543,64.102,144.047
        c2.226,1.803,5.002,2.786,7.866,2.786h149.73c2.864,0,5.641-0.983,7.866-2.786c40.139-32.504,64.103-86.354,64.103-144.047
        C293.667,141.374,288.07,135.778,281.167,135.778z"
        />
      </g>
    </svg>
    <svg
      v-if="type === 'check'"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m7 1.5-4.2 5-1.8-2"
        stroke="#000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      v-if="type === 'revert'"
      width="8"
      height="8"
      viewBox="0 0 8 8"
    >
      <defs>
        <clipPath id="revert_1">
          <rect
            width="8"
            height="8"
            transform="translate(888 366)"
            fill="#fff"
            stroke="#707070"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(-888 -366)" clip-path="url(#revert_1)">
        <path
          d="M8,3.653,4.675.008V2.183H3.969A3.968,3.968,0,0,0,0,6.151V7.3L.314,6.96A5.634,5.634,0,0,1,4.475,5.124h.2V7.3Zm0,0"
          transform="translate(888 366.344)"
          fill="#8b9297"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "ItemIcon",
  props: ["type", "className", "tooltip"],
};
</script>
