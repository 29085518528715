// defined modal routes as key val pairs route => modal type
export const MODAL_ROUTES = {
  adminUserAdd: "admin-user-create",
  adminUserEdit: "admin-user-edit",
  chargeAdd: "charge-create",
  chargeEdit: "charge-edit",
  doctorAdd: "doctor-create",
  doctorChangeStatus: "doctor-change-status",
  doctorEdit: "doctor-edit",
  eventAdd: "event-create",
  eventAddDetailed: "event-create-detailed",
  eventDetail: "event-detail",
  eventDetailPatient: "event-detail",
  eventEdit: "event-edit",
  insuranceProviderAdd: "insurance-provider-create",
  insuranceProviderEdit: "insurance-provider-edit",
  insuranceWalletAdd: "wallet-create",
  insuranceWalletEdit: "wallet-edit",
  patientAdd: "patient-create",
  patientChangeStatus: "patient-change-status",
  patientDetails: "patient-details",
  patientEdit: "patient-edit",
  planAdd: "plan-create",
  planEdit: "plan-edit",
  posAdd: "pos-create",
  roomAdd: "room-create",
  roomEdit: "room-edit",
  timeOffDetail: "time-off-detail",
  treatmentAdd: "treatment-create",
  treatmentEdit: "treatment-edit",
};
