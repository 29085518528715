<template>
  <div class="payment-tab-single-item">
    <div class="payment-tab-single-item-top">
      <div class="payment-tab-single-item-top-info">
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.name") }}:
          <span class="value">{{ item.item_data.name }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.price") }}:
          <span class="value">{{ formatPrice(item.item_data.price) }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.appointmentsNumber") }}:
          <span class="value">{{ item.item_data.appointments_number }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.daysValid") }}:
          <span class="value">{{ item.item_data.days_valid }}</span>
        </div>
      </div>
      <div class="payment-tab-single-item-top-action">
        <div
          :class="{
            button: true,
            action: true,
            disabled: isInactivePatient(patient),
          }"
          @click.prevent="addToCheckout(item)"
          v-if="!checkoutMode"
        >
          {{ $t("button.addToCheckout") }}
          <div
            :class="{
              icon: true,
              'icon-add-big': true,
              disabled: isInactivePatient(patient),
            }"
            v-if="!loading"
          ></div>
          <div
            :class="{
              icon: true,
              'icon-add-big': true,
              loader: true,
              disabled: isInactivePatient(patient),
            }"
            v-else
          ></div>
        </div>
        <div v-else>{{ $tc("label.plan", 1).toUpperCase() }}</div>
      </div>
    </div>
    <div class="payment-tab-single-item-bottom">
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.totalPrice") + ": " }} {{ formatPrice(item.price) }}
      </div>
    </div>
    <div v-if="checkoutMode" class="payment-tab-single-item-bottom-remove">
      <div class="remove" @click.prevent="removeItem(item)">
        <div class="icon icon-trash" v-if="!loading"></div>
        <div class="icon icon-trash loader" v-else></div>
        {{ $t("label.remove") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentsSinglePlan",
  props: {
    patient: {
      type: Object,
      default: () => {
        return {};
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    checkoutMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  watch: {},
  methods: {
    addToCheckout(item) {
      if (this.isInactivePatient(this.patient)) {
        return;
      }
      this.loading = true;
      this.$emit("add-to-checkout", item);
    },
    removeItem(item) {
      if (this.isInactivePatient(this.patient)) {
        return;
      }
      this.loading = true;
      this.$emit("remove-from-checkout", item);
    },
  },
};
</script>
