<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") }} {{ $tc("label.insuranceProvider", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="insuranceProviders"
          :sortArray="[
            {
              label: $t('label.name'),
              key: 'name',
            },
            {
              label: $t('label.phoneNo'),
              key: 'phone',
              width: '160px',
            },
            {
              label: $t('label.status'),
              key: 'status',
              width: '160px',
            },
            {
              label: $t('label.created'),
              key: 'created_at',
              width: '290px',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- insuranceProviders-->
        <div class="list-items-section-wrapper">
          <div
            v-for="insuranceProvider in insuranceProviders"
            :key="'insuranceProvider_' + insuranceProvider.id"
            :class="{
              'list-items-section-item': true,
            }"
          >
            <div class="name">
              {{ insuranceProvider.name }}
            </div>
            <div class="phone">
              {{ formatPhoneNumber(insuranceProvider.phone) }}
            </div>
            <div class="status">
              {{ insuranceProvider.status }}
            </div>
            <div class="created">
              {{ formatDateTime(insuranceProvider.created_at) }}
            </div>
            <insuranceProvider-options-menu
              :insuranceProvider="insuranceProvider"
            ></insuranceProvider-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.insuranceProviders.pagination.page"
        :perPage="$store.state.insuranceProviders.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.insuranceProviders.pagination.page"
        :perPage="$store.state.insuranceProviders.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import InsuranceProviderOptionsMenu from "@/views/private/components/settings/InsuranceProviderOptionsMenu";

export default {
  name: "InsuranceProvidersView",
  props: ["path"],
  components: {
    InsuranceProviderOptionsMenu,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      insuranceProviders: [],
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadInsuranceProviders();
  },
  created() {
    EventBus.$on("insurance-providers-load", () => {
      this.loadInsuranceProviders();
    });
  },
  unmounted() {
    EventBus.$off("insurance-providers-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.insuranceProviders": {
      deep: true,
      handler: function () {
        this.loadInsuranceProviders();
      },
    },
  },
  methods: {
    loadInsuranceProviders() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-providers",
        data: {},
        params: {
          q: this.$store.state.insuranceProviders.search,
          sort_by: ["created_at|desc"],
          page: this.$store.state.insuranceProviders.pagination.page,
          per_page: this.$store.state.insuranceProviders.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.insuranceProviders = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "insuranceProviders",
        data: value,
      });
    },
    handleCreateButton() {
      this.$router.push({
        name: "insuranceProviderAdd",
      });
    },
  },
};
</script>
