<template>
  <div class="row">
    <div class="col-lg-12 inventory">
      <!--      <inventory-sidebar-mini></inventory-sidebar-mini>-->
      <template v-if="this.$route.name === 'inventory'">
        <inventory-sidebar :folders="folders"></inventory-sidebar>
      </template>
      <template v-if="this.$route.name === 'inventoryAdvancedSearch'">
        <inventory-sidebar-filter :folders="folders"></inventory-sidebar-filter>
      </template>
      <template v-if="this.$route.name === 'inventory'">
        <inventory-view></inventory-view>
      </template>
      <template v-if="this.$route.name === 'inventoryAdvancedSearch'">
        <inventory-filter-view></inventory-filter-view>
      </template>
      <template v-if="this.$route.name === 'inventoryItem'">
        <inventory-item></inventory-item>
      </template>
    </div>
  </div>
</template>

<script>
import InventorySidebar from "@/views/private/components/inventory/Sidebar";
import InventorySidebarFilter from "@/views/private/components/inventory/SidebarFilter";
import InventoryView from "@/views/private/components/inventory/InventoryView";
import InventoryFilterView from "@/views/private/components/inventory/InventoryFilterView";
import InventoryItem from "@/views/private/components/inventory/InventoryItem";
import { HTTP, setCancelToken, cancelTokens } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import firstVisit from "@/mixins/firstVisit";

export default {
  name: "InventoryComponent",
  mixins: [firstVisit],
  components: {
    InventoryItem,
    InventoryFilterView,
    InventoryView,
    InventorySidebarFilter,
    InventorySidebar,
  },
  data() {
    return {
      folders: [],
      cancelToken: { load: null },
      token: null,
    };
  },
  created() {
    this.loadFolders();
    EventBus.$on("inventory-folders-load", () => {
      this.loadFolders();
    });
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadFolders() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/folders/",
        data: {},
        cancelToken: this.cancelToken.load.token,
        params: {
          with_products: 0,
        },
      })
        .then((response) => {
          if (response.data) {
            this.folders = response.data.data;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
