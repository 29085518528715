<template>
  <div>
    <notifications
      position="bottom right"
      class="notification"
      group="notification"
    >
      <template #body="props">
        <div class="notification-single" :class="props.item.type">
          <div class="notification-single-icon"></div>
          <div class="notification-single-body">
            <div class="notification-single-body-title">
              {{ props.item.title }}
            </div>
            <div
              class="notification-single-body-text"
              v-html="props.item.text"
            ></div>
          </div>
          <div class="notification-single-close" @click="props.close"></div>
        </div>
      </template>
    </notifications>
    <notifications
      class="prompt"
      group="prompt-calendar"
      :closeOnClick="false"
      ref="promptCalendar"
    >
      <template #body="props">
        <div class="notification-prompt">
          <div
            class="notification-prompt-close"
            @click="
              notificationEvents(
                props.close,
                props.item.data.onConfirmTooltip,
                props.item.data.key
              )
            "
          ></div>
          <div class="notification-prompt-calendar-image"></div>
          <div class="notification-prompt-calendar-body">
            <div class="notification-prompt-calendar-body-title">
              {{ props.item.title }}
            </div>
            <div class="notification-prompt-calendar-body-text">
              {{ props.item.text }}
            </div>
          </div>
          <div class="notification-prompt-actions">
            <div
              v-if="props.item.data.onCancel"
              class="button action cancel"
              @click.prevent="cancel(props.close, props.item.data.onCancel)"
            >
              {{ $t("button.no") }}
            </div>
            <div
              v-if="props.item.data.onConfirm"
              class="button action"
              @click.prevent="confirm(props.close, props.item.data.onConfirm)"
            >
              {{ $t("button.yes") }}
            </div>
            <div
              v-if="props.item.data.confirm"
              class="button action"
              @click.prevent="
                notificationEvents(
                  props.close,
                  props.item.data.onConfirmTooltip,
                  props.item.data.key
                )
              "
            >
              {{ $t("button.ok") }}
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <notifications
      class="notification notification-onboarding"
      group="onboarding-tooltip"
      ref="notificationOnboard"
      :closeOnClick="false"
      :ignoreDuplicates="true"
      :max="1"
    >
      <template #body="props">
        <div class="notification-prompt notification-onboarding-inner">
          <span class="dot"></span>
          {{ props.item.text }}

          <a
            class="button"
            @click="onboardTooltipClose(props.close, currentRef)"
            >ok</a
          >
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  name: "NotificationsWrapper",
  data() {
    return {
      currentRef: "",
    };
  },
  watch: {
    "$route.name": function (to, from) {
      if (to !== from) {
        this.$store.dispatch("setOnboardingTooltipShown", this.currentRef);
      }
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.notificationOnboard.list;
      },
      (val) => {
        if (typeof val === "object" && val.length) {
          this.setStyle("notificationOnboard", val);
          this.currentRef = val[0].data.ref;
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    notificationEvents(closeCallback, onConfirmCallback, onConfirmValue) {
      if (onConfirmCallback) {
        onConfirmCallback(onConfirmValue);
      }
      closeCallback();
    },
    onboardTooltipClose(closeCallback, ref) {
      this.$store.dispatch("setOnboardingTooltipShown", ref);
      closeCallback();
    },
    setStyle(ref, value) {
      let verticalPopupPosition;
      let verticalPopupPositionTransform = "transform:translateY(0);";
      if (value[0].data.verticalPosition === "center") {
        verticalPopupPosition =
          "top: " + (value[0].data.top + value[0].data.height / 2);
        verticalPopupPositionTransform = "transform:translateY(-50%);";
      } else if (value[0].data.verticalPosition === "top") {
        verticalPopupPosition = "top: " + (value[0].data.top - 7);
      } else {
        verticalPopupPosition =
          "bottom: " +
          (window.innerHeight - value[0].data.top - value[0].data.height);
      }

      let horizontalPopupPosition =
        value[0].data.popupPosition == "left" ? value[0].data.width + 230 : 0;

      this.$refs[ref].$el.setAttribute(
        "style",
        verticalPopupPosition +
          "px; left:" +
          (value[0].data.left + 10 - horizontalPopupPosition) +
          "px;" +
          verticalPopupPositionTransform
      );

      this.$refs[ref].$el.setAttribute(
        "class",
        "vue-notification-group notification notification-onboarding " +
          value[0].data.popupPosition +
          " " +
          value[0].data.arrowVertical
      );
    },
    cancel(closeCallback, cancelCallback) {
      if (cancelCallback !== true) {
        cancelCallback();
      }
      closeCallback();
    },
    confirm(closeCallback, confirmCallback) {
      if (confirmCallback !== true) {
        confirmCallback();
      }
      closeCallback();
    },
  },
};
</script>

<style scoped></style>
