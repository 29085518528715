<template>
  <div class="patients-form-tab-single">
    <form @submit.prevent="">
      <div class="form-item-separator">
        {{ $t("label.chiefComplaint") }}
      </div>

      <!--     main problems-->
      <div class="form-item no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.mainProblems") }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('chief_complaint_main_problems'),
            }"
          >
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.chief_complaint_main_problems"
            />
          </div>
        </div>
      </div>

      <!--      when did issue begin & initiate symptoms-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.whenDidYourIssueBegin")
            }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('chief_complaint_when_did_issue_begin'),
            }"
          >
            <input
              v-model="formValues.chief_complaint_when_did_issue_begin"
              type="text"
            />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.didAnythingInitiateSymptoms")
            }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError(
                'chief_complaint_anything_initiate_symptoms'
              ),
            }"
          >
            <input
              v-model="formValues.chief_complaint_anything_initiate_symptoms"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      does anything make it better/worse-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.doesAnythingMakeItBetter")
            }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('chief_complaint_anything_makes_it_better'),
            }"
          >
            <v-select
              v-model="formValues.chief_complaint_anything_makes_it_better"
              appendToBody
              :options="doesAnythingMakeItBetterOptions"
            />
          </div>
        </div>
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.haveYouBeenGivenADiagnosis")
            }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('chief_complaint_given_diagnosis'),
            }"
          >
            <input
              v-model="formValues.chief_complaint_given_diagnosis"
              type="text"
            />
          </div>
        </div>
      </div>

      <!--      have you given diagnosis-->
      <div class="form-item double no-padding">
        <div class="form-item-section">
          <div class="form-item-section-title">
            {{ $t("label.doesAnythingMakeItWorse")
            }}<span class="required">*</span>
          </div>
          <div
            class="form-item"
            :class="{
              error: this.hasError('chief_complaint_anything_makes_it_worse'),
            }"
          >
            <textarea
              class="note auto-resize"
              @input="autoResize"
              v-model="formValues.chief_complaint_anything_makes_it_worse"
            />
          </div>
        </div>
      </div>

      <div class="form-item right">
        <div class="button action margin-right-15" @click="prevTab()">
          {{ $t("button.previous") }}
        </div>
        <div class="button action" @click="nextTab()">
          {{ $t("button.next") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
import fileMixin from "@/mixins/fileMixin";
import autoResize from "@/mixins/autoResize";

export default {
  name: "PatientFormStep2",
  props: ["formData"],
  mixins: [fileMixin, autoResize],
  data: function () {
    return {
      formValues: {
        chief_complaint_main_problems: "",
        chief_complaint_when_did_issue_begin: "",
        chief_complaint_anything_initiate_symptoms: "",
        chief_complaint_anything_makes_it_better: "",
        chief_complaint_anything_makes_it_worse: "",
        chief_complaint_given_diagnosis: "",
      },
      doesAnythingMakeItBetterOptions: [
        "Heat",
        "Cold",
        "Massage",
        "Rest",
        "Fatigue",
        "Other",
      ],
    };
  },
  validators: {
    "formValues.chief_complaint_main_problems": function (value) {
      return Validator.value(value).required();
    },
    "formValues.chief_complaint_anything_initiate_symptoms": function (value) {
      return Validator.value(value).required();
    },
    "formValues.chief_complaint_when_did_issue_begin": function (value) {
      return Validator.value(value).required();
    },
    "formValues.chief_complaint_anything_makes_it_better": function (value) {
      return Validator.value(value).required();
    },
    "formValues.chief_complaint_anything_makes_it_worse": function (value) {
      return Validator.value(value).required();
    },
    "formValues.chief_complaint_given_diagnosis": function (value) {
      return Validator.value(value).required();
    },
  },
  created() {
    if (this.formData) {
      this.mapData();
    }
  },
  mounted() {},
  methods: {
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    mapData() {
      const filtered = Object.keys(this.formData)
        .filter((key) => Object.keys(this.formValues).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.formData[key];
          return obj;
        }, {});

      this.formValues = filtered;
    },
    prevTab() {
      this.$emit("prev-tab", true);
    },
    nextTab() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.$emit("next-tab", true);
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.fillAllFieldsAndCheckValidity"),
          });
        }
      });
    },
  },
};
</script>
