<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <template v-if="!loading">
      <div class="list-top">
        <div class="list-top-text">
          {{ $t("label.clinicData") }}
        </div>
        <div class="button action" @click.prevent="updateClinic">
          {{ $t("button.save") }}
          <div class="icon icon-add-big"></div>
        </div>
      </div>
      <div class="list-items">
        <form @submit.prevent="">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.name'),
            }"
          >
            <label class="item">{{ $t("label.name") }}</label>
            <input ref="name" v-model="clinicData.name" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.slug'),
            }"
          >
            <label class="item">{{ $t("label.slug") }}</label>
            <input ref="name" v-model="clinicData.slug" type="text" />
          </div>

          <div class="form-item">
            <image-upload
              :main-class="'uploader-form'"
              :label="$t('label.logo')"
              :image="clinicData.logo"
              :image-src="logoSrc"
              @image-file="clinicData.logo = $event"
              @image-src="logoSrc = $event"
              @image-remove="
                clinicData.logo = null;
                logoSrc = null;
              "
            />
          </div>

          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.status'),
            }"
          >
            <label class="item">{{ $t("label.status") }}</label>
            <v-select
              :options="statuses"
              v-model="clinicData.status"
            ></v-select>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.email'),
            }"
          >
            <label class="item">{{ $t("label.email") }}</label>
            <input ref="email" v-model="clinicData.email" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.default_treatment_length'),
            }"
          >
            <label class="item">{{ $t("label.defaultTreatmentLength") }}</label>
            <input
              ref="email"
              v-model="clinicData.default_treatment_length"
              type="number"
              min="0"
              :placeholder="$t('placeholder.minutes')"
            />
          </div>
          <div class="form-item double no-padding">
            <div
              class="form-item"
              :class="{
                error: validation.hasError('clinicData.working_hours_from'),
              }"
            >
              <label class="item">{{ $t("label.workFrom") }}</label>
              <date-time-input
                ref="workFrom"
                input-mode
                use-start-time
                :index="0"
                :original-start-date="clinicData.working_hours_from"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="acceptFromValue"
              ></date-time-input>
            </div>
            <div
              class="form-item"
              :class="{
                error: validation.hasError('clinicData.working_hours_to'),
              }"
            >
              <label class="item">{{ $t("label.workTo") }}</label>
              <date-time-input
                ref="workTo"
                input-mode
                use-start-time
                :index="1"
                :original-start-date="clinicData.working_hours_to"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="acceptToValue"
              ></date-time-input>
            </div>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('clinicData.timezone'),
            }"
          >
            <label class="item">{{ $t("label.timezone") }}</label>
            <v-select
              :options="Object.keys(timezones)"
              v-model="clinicData.timezone"
            ></v-select>
          </div>
          <div class="form-item">
            <label class="item separator">{{ $t("label.invoiceData") }}</label>
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.invoiceName") }}</label>
            <input
              ref="invoicName"
              v-model="clinicData.invoicing_name"
              type="text"
            />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.invoiceAddress") }}</label>
            <input
              ref="invoiceAddress"
              v-model="clinicData.invoicing_address"
              type="text"
            />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.invoiceZip") }}</label>
            <input
              ref="invoiceZip"
              v-model="clinicData.invoicing_zip"
              type="text"
            />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.invoiceCity") }}</label>
            <input
              ref="invoiceCity"
              v-model="clinicData.invoicing_city"
              type="text"
            />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.invoiceState") }}</label>
            <input
              ref="invoiceState"
              v-model="clinicData.invoicing_state"
              type="text"
            />
          </div>

          <!--          Consents-->
          <div class="form-item">
            <label class="item separator">{{ $t("label.consentPages") }}</label>
          </div>
          <div
            class="form-item"
            v-for="(item, index) in consentSettings"
            :key="item.field_name"
          >
            <input
              type="checkbox"
              :id="item.field_name"
              @change="updateConsent(item.id, consentSettings[index].value)"
              v-model="consentSettings[index].value"
            />
            <label :for="item.field_name" class="checkbox">{{
              consentKeys[item.field_name]
            }}</label>
          </div>
        </form>
      </div>
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import ImageUpload from "@/views/private/components/settings/admin/ImageUpload";
import DateTimeInput from "@/views/private/components/DateTimeInput";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "ClinicView",
  components: { ImageUpload, DateTimeInput },
  data: function () {
    return {
      loading: false,
      cancelToken: { data: null, settings: null },
      timezones: this.getTimezones(),
      statuses: ["active", "disabled", "locked"],
      consentKeys: {
        informed: "Informed",
        financial_policy: "Financial policy",
        privacy_practices: "Privacy practices",
        hipaa: "HIPAA",
      },
      consentSettings: [],
      clinicSettings: {},
      logoSrc: null,
      clinicData: {
        id: null,
        status: null,
        name: "",
        slug: "",
        logo: null,
        default_treatment_length: "",
        timezone: "",
        email: "",
        working_hours_from: null,
        working_hours_to: null,
        invoicing_name: "",
        invoicing_address: "",
        invoicing_zip: "",
        invoicing_city: "",
        invoicing_state: "",
      },
    };
  },
  mounted() {
    this.loadClinicSettings();
    this.loadClinicData();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  watch: {
    clinicSettings: {
      handler: function (val) {
        if (val.length) {
          this.consentSettings = val.reduce((filtered, item) => {
            if (Object.keys(this.consentKeys).indexOf(item.field_name) > -1) {
              filtered.push({
                field_name: item.field_name,
                id: item.id,
                value: item.value === "1",
              });
            }
            return filtered;
          }, []);
        }
      },
      deep: true,
    },
  },
  validators: {
    "clinicData.name": function (value) {
      return Validator.value(value).required();
    },
    "clinicData.slug": function (value) {
      return Validator.value(value).required();
    },
    "clinicData.working_hours_from": function (value) {
      return Validator.value(value).required();
    },
    "clinicData.working_hours_to": function (value) {
      return Validator.value(value).required();
    },
    "clinicData.status": function (value) {
      return Validator.value(value).required();
    },
    "clinicData.default_treatment_length": function (value) {
      return Validator.value(value).integer().greaterThan(0);
    },
    "clinicData.email": function (value) {
      return Validator.value(value).email();
    },
  },
  methods: {
    loadClinicSettings() {
      setCancelToken(this.cancelToken, "settings");
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/settings",
        cancelToken: this.cancelToken.settings.token,
      })
        .then((response) => {
          this.clinicSettings = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadClinicData() {
      setCancelToken(this.cancelToken, "data");
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/clinics/" + this.$store.state.user.clinic.id,
        cancelToken: this.cancelToken.data.token,
      })
        .then((response) => {
          if (response.data) {
            this.clinicData = response.data;
            this.clinicData.timezone = Object.keys(this.timezones).find(
              (tz) => this.timezones[tz] === response.data.timezone
            );
            this.clinicData.working_hours_from = this.handleDateTimeValue(
              this.clinicData.working_hours_from
            );
            this.clinicData.working_hours_to = this.handleDateTimeValue(
              this.clinicData.working_hours_to
            );
            let scope = this;
            scope.logoSrc = scope.clinicData.logo;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acceptFromValue(val) {
      this.clinicData.working_hours_from = val;
    },
    acceptToValue(val) {
      this.clinicData.working_hours_to = val;
    },
    updateConsent(id, value) {
      HTTP({
        method: "PUT",
        url: "/api/v1/settings/" + id,
        data: {
          value: value ? "1" : "0",
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.consentSettingsUpdate"),
          });
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.consentSettingsNotUpdate"),
          });
        });
    },
    updateClinic() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;

          const data = [];
          data["id"] = self.$store.state.user.clinic.id;
          data["status"] = self.clinicData.status;
          data["name"] = self.clinicData.name;
          data["slug"] = self.clinicData.slug;
          if (typeof self.clinicData.logo !== "string") {
            data["logo"] = self.clinicData.logo;
          }
          data["default_treatment_length"] =
            self.clinicData.default_treatment_length;
          data["email"] = self.clinicData.email;
          data["working_hours_from"] = self.customFormat(
            self.clinicData.working_hours_from,
            "HH:mm"
          );
          data["working_hours_to"] = self.customFormat(
            self.clinicData.working_hours_to,
            "HH:mm"
          );
          data["timezone"] = self.timezones[self.clinicData.timezone];
          data["invoicing_name"] = self.clinicData.invoicing_name;
          data["invoicing_address"] = self.clinicData.invoicing_address;
          data["invoicing_zip"] = self.clinicData.invoicing_zip;
          data["invoicing_city"] = self.clinicData.invoicing_city;
          data["invoicing_state"] = self.clinicData.invoicing_state;

          const formData = self.generateFormData(data);

          self.clinicData.id = self.$store.state.user.clinic.id;
          HTTP({
            method: "POST",
            url: "/api/v1/clinics/" + self.$store.state.user.clinic.id,
            data: formData,
          })
            .then((response) => {
              self.$notify({
                group: "notification",
                type: "success",
                title: self.$t("notification.thumbsUp"),
                text: self.$t("notification.clinicUpdate"),
              });
              if (response.data) {
                self.clinicData = response.data;
                self.clinicData.timezone = Object.keys(self.timezones).find(
                  (tz) => self.timezones[tz] === response.data.timezone
                );
                self.$store.dispatch("setUserClinic", self.clinicData);
                self.clinicData.working_hours_from = self.handleDateTimeValue(
                  response.data.working_hours_from
                );
                self.clinicData.working_hours_to = self.handleDateTimeValue(
                  response.data.working_hours_to
                );
              }
            })
            .catch(() => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.clinicNotUpdate"),
              });
            })
            .finally(() => {
              self.loading = false;
            });
        }
      });
    },
  },
};
</script>
