import ItemIcon from "@/views/private/components/ItemIcon";

export default {
  components: { ItemIcon },
  data() {
    return {
      today: new Date(),
    };
  },
  methods: {
    showDetail(event) {
      this.$router.push({
        name: "eventDetail",
        params: {
          id: event.id,
        },
      });
    },
  },
};
