<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? (isInactivePatient(newPatient)
                              ? $t("label.view")
                              : $t("label.edit")) +
                            " " +
                            $tc("label.patient", 1).toLowerCase()
                          : $t("label.addNew") +
                            " " +
                            $tc("label.patient", 1).toLowerCase()
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.first_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.firstName") }}: </label>
                    <input
                      ref="firstName"
                      v-model="newPatient.first_name"
                      type="text"
                      :disabled="isInactivePatient(newPatient)"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.last_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.lastName") }}: </label>
                    <input
                      v-model="newPatient.last_name"
                      type="text"
                      :disabled="isInactivePatient(newPatient)"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.birthday'),
                    }"
                  >
                    <date-time-input
                      ref="inputDate"
                      enable-date-calendar
                      input-mode
                      show-label
                      to-now
                      use-date
                      :index="1"
                      :disabled="isInactivePatient(newPatient)"
                      :label="$t('label.dateOfBirth') + ':'"
                      :original-start-date="newPatient.birthday"
                      :time-format24="this.$store.getters.userHoursFormat24"
                      @value-start-date="setStart"
                    ></date-time-input>
                  </div>
                  <form-input-checkbox-toggle
                    :label="$t('label.requiresGuardian')"
                    :model-value="minorPatient"
                    @update:modelValue="
                      (value) => {
                        this.minorPatient = value;
                      }
                    "
                  />
                  <div
                    v-if="!minorPatient"
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.email'),
                    }"
                  >
                    <label class="item">{{ $t("label.email") }}: </label>
                    <input
                      v-model="newPatient.email"
                      type="text"
                      :disabled="isInactivePatient(newPatient)"
                    />
                  </div>
                  <div
                    v-if="minorPatient"
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.guardian'),
                    }"
                  >
                    <label class="item">{{ $t("label.guardian") }}: </label>
                    <v-select
                      placeholder="Select Guardian"
                      :getOptionLabel="nameOptionLabel"
                      :options="guardians"
                      :filterable="false"
                      @search="searchGuardians"
                      v-model="newPatient.guardian"
                    >
                      <template #selected-option="{ first_name, last_name }">
                        {{ first_name + " " + last_name }}
                      </template>
                      <template #no-options="{ search }">
                        <vue-select-searching
                          :search="search"
                          :loading="guardian.loading"
                          :results-count="guardians.length"
                        />
                      </template>
                      <template #list-footer>
                        <load-more
                          :custom-class="'vs__dropdown-option'"
                          :message="$t('message.loading') + '...'"
                          :show-load-more="guardian.hasMore"
                          @load-more="loadMore"
                        />
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.gender'),
                    }"
                  >
                    <label class="item">{{ $t("label.gender") }}: </label>
                    <v-select
                      :options="genderList"
                      v-model="newPatient.gender"
                      :disabled="isInactivePatient(newPatient)"
                    ></v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.referral_source'),
                    }"
                  >
                    <label class="item"
                      >{{ $t("label.referralSource") }}:
                    </label>
                    <input
                      v-model="newPatient.referral_source"
                      type="text"
                      :disabled="isInactivePatient(newPatient)"
                    />
                  </div>
                  <div
                    v-if="!minorPatient"
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPatient.mobile_number'),
                    }"
                  >
                    <label class="item">{{ $t("label.mobileNo") }}: </label>
                    <input
                      :value="newPatient.mobile_number"
                      v-imask="masks.phone"
                      @accept="
                        newPatient.mobile_number = parsePhoneValue($event)
                      "
                      :disabled="isInactivePatient(newPatient)"
                      type="text"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      :class="{
                        button: true,
                        action: true,
                        'disable-close': true,
                        disabled: isInactivePatient(newPatient),
                      }"
                      @click.prevent="savePatient()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import SimpleVueValidator from "simple-vue3-validator";
import FormInputCheckboxToggle from "@/views/shared/forms/FormInputCheckboxToggle";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching";
import LoadMore from "@/views/shared/components/LoadMore";
import imaskMixin from "@/mixins/imaskMixin";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientCreate",
  mixins: [imaskMixin],
  components: {
    LoadMore,
    VueSelectSearching,
    FormInputCheckboxToggle,
    DateTimeInput,
  },
  validators: {
    "newPatient.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.last_name": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.email, minorPatient": function (value, minor) {
      if (minor) {
        return;
      }

      return Validator.value(value).required().email();
    },
    "newPatient.guardian, minorPatient": function (value, minor) {
      if (!minor) {
        return;
      }

      return Validator.value(value).required();
    },
    "newPatient.birthday": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.gender": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.mobile_number, minorPatient": function (value, minor) {
      if (minor) {
        return;
      }

      return Validator.value(value).length(11).required();
    },
  },
  data() {
    return {
      guardians: [],
      guardian: {
        search: "",
        page: 0,
        hasMore: true,
        loading: false,
      },
      minorPatient: false,
      newPatient: {
        first_name: null,
        last_name: null,
        email: null,
        guardian: null,
        mobile_number: null,
        referral_source: null,
        gender: "",
        birthday: null,
        passport: "empty",
      },
      genderList: ["male", "female", "other"],
      loading: false,
      observer: null,
      cancelToken: {
        guardiansLoad: null,
      },
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
    minorPatient: function (val) {
      if (val) {
        this.newPatient.email = null;
        if (this.guardian.hasMore) {
          this.loadMore();
        }
        return;
      }

      if (!this.patientIsMinor) {
        this.newPatient.guardian = null;
      }

      if (!val) {
        this.checkMinor();
      }
    },
    "newPatient.birthday": function () {
      this.checkMinor();
    },
  },
  mounted() {
    this.entrypoint();
    this.searchGuardians = this.asDebounce(this.searchGuardians, 1000);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  computed: {
    patientIsMinor: function () {
      return (
        this.dateIsValid(this.newPatient.birthday) &&
        this.diffInYears(new Date(), this.newPatient.birthday) < 18
      );
    },
  },
  methods: {
    checkMinor() {
      if (this.patientIsMinor) {
        this.$nextTick(() => {
          this.minorPatient = true;
        });
      }
    },
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadPatient();
      }
    },
    isModeEdit() {
      return "patientEdit" === this.$route.name;
    },
    loadPatient() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newPatient = response.data;
            this.newPatient.birthday = this.handleDateOnlyValue(
              this.newPatient.birthday
            );
            this.minorPatient = !!this.newPatient.guardian.id;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    savePatient() {
      if (this.isInactivePatient(this.newPatient)) {
        return;
      }
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/patients/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newPatient, [
            "birthday",
          ]);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if ([200, 201].indexOf(response.status) > -1) {
                self.loading = false;
                self.closeModal();
                EventBus.$emit("patients-load");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    setStart(value) {
      this.newPatient.birthday = value;
    },
    closeModal() {
      this.$emit("close");
    },
    searchGuardians(text = "") {
      this.guardian.search = text;
      this.guardian.page = 1;
      this.guardian.hasMore = true;
      this.guardians = [];
      this.loadGuardians();
    },
    loadMore() {
      if (!this.guardian.loading) {
        this.guardian.page = this.guardian.page + 1;
        this.loadGuardians();
      }
    },
    loadGuardians() {
      this.guardian.loading = true;

      let params = {};
      params.page = this.guardian.page;
      params.per_page = 10;
      params.statuses = ["active"];
      params.only_guardians = 1;

      if (this.guardian.search !== "") {
        params.q = this.guardian.search;
      }

      setCancelToken(this.cancelToken, "guardiansLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        cancelToken: this.cancelToken.guardiansLoad.token,
        params: params,
      })
        .then((response) => {
          if (response.data) {
            if (response.data.data.length < 10) {
              this.guardian.hasMore = false;
            }
            this.guardians = this.guardians.concat(response.data.data);
          }
          this.guardian.loading = false;
        })
        .catch(() => (this.guardian.loading = false));
    },
  },
};
</script>

<style scoped></style>
