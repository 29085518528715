<template>
  <div class="filter-menu-item">
    <div class="filter-menu-item-title">
      <span
        :class="{
          'filter-menu-item-title-label': true,
          focused: isFilterFocused,
        }"
        @click="focusFilter"
        >Statuses</span
      >
    </div>
    <template v-for="(status, index) in statuses">
      <template v-if="typeof status !== 'undefined'">
        <div
          :key="'status' + index + 'parent'"
          :class="'filter-item ' + this.statusColorClass(status)"
        >
          <div
            :class="{
              'filter-item-icon': true,
              'icon-indicator': true,
            }"
          ></div>
          <label :for="'status_' + index"
            >{{ status }}
            <input
              type="checkbox"
              class="filter-item-checkbox"
              :id="'status_' + index"
              v-bind:value="status"
              v-model="checkedValues"
              @change="updateFilters"
            />
            <span class="filter-item-checkbox-checkmark"></span>
          </label>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "FilterMenuStatuses",
  props: ["statuses", "type"],
  data() {
    return {
      checkedValues:
        typeof this.$store.state.filter.statuses !== "undefined"
          ? this.$store.state.filter.statuses
          : [],
    };
  },
  mounted() {
    this.updateFilters = this.asDebounce(this.updateFilters, 500);
  },
  computed: {
    isFilterFocused() {
      return this.$store.state.focusedFilter === "statuses";
    },
  },
  methods: {
    updateFilters() {
      this.$store.dispatch("setFilter", {
        statuses: true,
        values: this.checkedValues,
      });
    },
    selectAll() {
      if (this.checkedValues.length === this.statuses.length) {
        this.checkedValues = [];
      } else {
        this.checkedValues = this.statuses.map(function (item) {
          return item.id;
        });
      }
      this.updateFilters();
    },
    focusFilter() {
      this.$store.dispatch("setFocusedFilter", "statuses");
    },
  },
};
</script>

<style scoped></style>
