import Settings from "@/views/private/pages/admin/Settings";
import ClinicView from "@/views/private/components/settings/admin/ClinicView";

export default [
  {
    path: "",
    name: "adminHome",
    redirect: { name: "adminSettings" },
    meta: {
      role: ["Super Administrator"],
    },
  },
  {
    path: "clinic/:id?",
    name: "adminClinic",
    component: ClinicView,
    meta: {
      role: ["Super Administrator"],
    },
  },
  {
    path: "user/add",
    name: "adminUserAdd",
    component: Settings,
    meta: {
      role: ["Super Administrator"],
    },
  },
  {
    path: "user/:id/edit",
    name: "adminUserEdit",
    component: Settings,
    meta: {
      role: ["Super Administrator"],
    },
  },
  {
    path: "settings/:type?",
    name: "adminSettings",
    component: Settings,
    meta: {
      role: ["Super Administrator"],
    },
  },
];
