<template>
  <div class="list">
    <template v-if="!loading">
      <div class="list-top">
        <div class="steps" v-if="mode === 'edit' && patient">
          <div
            @click="setCurrentTab('step1')"
            :class="{
              'steps-item': true,
              active: activeTab === Object.keys(tabs)[0],
            }"
          >
            1/4
          </div>
          <div
            @click="setCurrentTab('step2')"
            :class="{
              'steps-item': true,
              active: activeTab === Object.keys(tabs)[1],
            }"
          >
            2/4
          </div>
          <div
            @click="setCurrentTab('step3')"
            :class="{
              'steps-item': true,
              active: activeTab === Object.keys(tabs)[2],
            }"
          >
            3/4
          </div>
          <div
            @click="setCurrentTab('step4')"
            :class="{
              'steps-item': true,
              active: activeTab === Object.keys(tabs)[3],
            }"
          >
            4/4
          </div>
        </div>
        <template v-else>
          <div
            class="progress bar"
            :style="
              'width: ' +
              ((Object.keys(tabs).indexOf(activeTab) + 1) /
                Object.keys(tabs).length) *
                100 +
              '%'
            "
          >
            {{
              ((Object.keys(tabs).indexOf(activeTab) + 1) /
                Object.keys(tabs).length) *
              100
            }}
            %
          </div>
          <div class="progress"></div>
        </template>
      </div>

      <div class="list-wrapper" v-if="this.mode === 'create' || this.formData">
        <patient-form-step1
          ref="step1"
          :form-data="this.formData"
          v-show="activeTab === Object.keys(tabs)[0]"
          @next-tab="setCurrentTab('step2')"
        ></patient-form-step1>
        <patient-form-step2
          ref="step2"
          :form-data="this.formData"
          v-show="activeTab === Object.keys(tabs)[1]"
          @next-tab="setCurrentTab('step3')"
          @prev-tab="setCurrentTab('step1')"
        ></patient-form-step2>
        <patient-form-step3
          ref="step3"
          :form-data="this.formData"
          v-show="activeTab === Object.keys(tabs)[2]"
          @next-tab="setCurrentTab('step4')"
          @prev-tab="setCurrentTab('step2')"
        ></patient-form-step3>
        <patient-form-step4
          ref="step4"
          :form-data="this.formData"
          v-show="activeTab === Object.keys(tabs)[3]"
          @prev-tab="setCurrentTab('step3')"
          @submit-form="submitForm"
        ></patient-form-step4>
      </div>
      <!-- tab contents-->
    </template>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import PatientFormStep1 from "@/views/shared/components/PatientFormStep1";
import PatientFormStep2 from "@/views/shared/components/PatientFormStep2";
import PatientFormStep3 from "@/views/shared/components/PatientFormStep3";
import PatientFormStep4 from "@/views/shared/components/PatientFormStep4";
import formMixin from "@/mixins/formMixin";
import {
  HTTP,
  HTTP_PUBLIC,
  setCancelToken,
  cancelTokens,
} from "@/services/api";
import axios from "axios";

export default {
  name: "PatientFormView",
  props: ["mode"],
  mixins: [formMixin],
  components: {
    PatientFormStep1,
    PatientFormStep2,
    PatientFormStep3,
    PatientFormStep4,
  },
  data: function () {
    return {
      activeTab: "step1",
      tabs: {
        step1: {
          label: "Step 1",
        },
        step2: {
          label: "Step 2",
        },
        step3: {
          label: "Step 3",
        },
        step4: {
          label: "Step 4",
        },
      },
      patient: null,
      formData: null,
      loading: false,
      submitting: false,
      cancelToken: { patient: null, form: null },
      promises: [],
    };
  },
  created() {},
  mounted() {
    if (this.mode === "edit") {
      this.promises.push(this.loadPatientData());
      this.promises.push(this.loadPatientFormData());
      axios.all(this.promises);
    }
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    setCurrentTab(tab) {
      this.activeTab = tab;
    },
    loadPatientData() {
      setCancelToken(this.cancelToken, "patient");
      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
        cancelToken: this.cancelToken.patient.token,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
            this.patient.birthday = this.handleDateOnlyValue(
              this.patient.birthday
            );
          }
        })
        .catch(() => {});
    },
    loadPatientFormData() {
      setCancelToken(this.cancelToken, "form");
      return HTTP({
        method: "GET",
        url:
          "/api/v1/patients/" +
          this.$route.params.id +
          "/patient-records/" +
          this.$route.params.form_id,
        cancelToken: this.cancelToken.form.token,
      })
        .then((response) => {
          if (response.data) {
            this.formData = response.data;
            this.formData.date_of_birth = this.handleDateOnlyValue(
              this.formData.date_of_birth
            );
            this.formData.effective_date = this.handleDateOnlyValue(
              this.formData.effective_date
            );
            this.formData.term_date = this.handleDateOnlyValue(
              this.formData.term_date
            );
            this.formData.reinstate_on = this.handleDateOnlyValue(
              this.formData.reinstate_on
            );
            this.formData.end_date = this.handleDateOnlyValue(
              this.formData.end_date
            );
            this.formData.start_date = this.handleDateOnlyValue(
              this.formData.start_date
            );
            this.formData.systems_date_of_last_menses =
              this.handleDateOnlyValue(
                this.formData.systems_date_of_last_menses
              );
          }
        })
        .catch(() => {});
    },
    submitForm() {
      let submitData = {};
      for (let tabsKey in this.tabs) {
        Object.assign(submitData, this.$refs[tabsKey].formValues);
      }
      submitData["insurance_date_of_birth"] = submitData[
        "insurance_date_of_birth"
      ]
        ? this.getUTCNoonFromDate(
            submitData["insurance_date_of_birth"]
          ).toISOString()
        : null;
      submitData["date_of_birth"] = submitData["date_of_birth"]
        ? this.getUTCNoonFromDate(submitData["date_of_birth"]).toISOString()
        : null;
      submitData["insurance_date"] = submitData["insurance_date"]
        ? this.getUTCNoonFromDate(submitData["insurance_date"]).toISOString()
        : null;
      submitData["systems_date_of_last_menses"] = submitData[
        "systems_date_of_last_menses"
      ]
        ? this.getUTCNoonFromDate(
            submitData["systems_date_of_last_menses"]
          ).toISOString()
        : null;

      const formData = this.generateFormData(submitData);
      this.submitting = true;

      if (this.mode === "create") {
        formData.append("token", this.$route.params.token);
        this.createPatientRecord(formData);
      }

      if (this.mode === "edit") {
        if (typeof submitData["insurance_card_image"] === "string") {
          formData.delete("insurance_card_image");
        }
        if (typeof submitData["insurance_signature_image"] === "string") {
          formData.delete("insurance_signature_image");
        }

        this.updatePatientRecord(formData);
      }
    },
    createPatientRecord(formData) {
      HTTP_PUBLIC({
        method: "POST",
        url: `/api/v1/patient-records/${this.$route.params.token}/initialize/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.submitting = false;
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.success"),
          });

          this.checkNextStep(this.$route.params.token);
        })
        .catch(() => {
          this.submitting = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        });
    },
    updatePatientRecord(formData) {
      HTTP({
        method: "POST",
        url:
          "/api/v1/patients/" +
          this.$route.params.id +
          "/patient-records/" +
          this.$route.params.form_id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.submitting = false;
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.success"),
          });
          this.$router.push({ name: "patients" });
        })
        .catch(() => {
          this.submitting = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        });
    },
  },
};
</script>
