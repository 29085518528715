<template>
  <div class="payment-tab-single-item">
    <div class="payment-tab-single-item-top">
      <div class="payment-tab-single-item-top-info">
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.name") }}:
          <span class="value">{{ item.item_data.name }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.price") }}:
          <span class="value">{{ formatPrice(item.item_data.price) }}</span>
        </div>
        <div class="payment-tab-single-item-top-info-item">
          {{ $t("label.availableQty") }}:
          <span class="value">{{ item.item_data.quantity }}</span>
        </div>
      </div>
      <div class="payment-tab-single-item-top-action">
        <div
          class="button action"
          @click.prevent="addToCheckout(item)"
          v-if="!checkoutMode"
        >
          {{ $t("button.addToCheckout") }}
          <div class="icon icon-add-big" v-if="!loading"></div>
          <div class="icon icon-add-big loader" v-else></div>
        </div>
        <div v-else>{{ $tc("label.product", 1).toUpperCase() }}</div>
      </div>
    </div>
    <div class="payment-tab-single-item-top">
      <div class="payment-tab-single-item-top-action">
        <div class="form-item">
          <label class="item">{{ $tc("label.provider", 1) }}: </label>
          <v-select
            :getOptionLabel="nameOptionLabel"
            :options="doctors"
            :model-value="item.product_sold_by_doctor"
            @option:selected="(value) => this.updateDoctor(value)"
          >
            <template #option="{ first_name, last_name }">
              <span> {{ first_name + " " + last_name }}</span>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="payment-tab-single-item-bottom">
      <div class="payment-tab-single-item-bottom-item">
        <div class="form-item nowrap">
          <div class="label">{{ $t("label.qty") }}:</div>
          <input
            type="number"
            min="0"
            :max="item.item_data.quantity"
            :value="item.quantity"
            @change="($event) => this.updateQuantity($event.target.value)"
          />
        </div>
      </div>
      <div class="payment-tab-single-item-bottom-item">
        {{ $t("label.totalPrice") + ": " }}
        {{ formatPrice(item.quantity * item.price) }}
      </div>
    </div>
    <div v-if="checkoutMode" class="payment-tab-single-item-bottom-remove">
      <div class="remove" @click.prevent="removeItem(item)">
        <div class="icon icon-trash" v-if="!loading"></div>
        <div class="icon icon-trash loader" v-else></div>
        {{ $t("label.remove") }}
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";

export default {
  name: "PaymentsSingleProduct",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    patientId: {
      type: [String, Number],
      default: null,
    },
    doctors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkoutMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  mounted() {
    this.updateQuantity = this.asDebounce(this.updateQuantity, 1000);
  },
  methods: {
    addToCheckout(item) {
      if (item.quantity < 1) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.minQuantity"),
        });
      } else {
        this.loading = true;
        this.$emit("add-to-checkout", item);
      }
    },
    removeItem(item) {
      this.loading = true;
      this.$emit("remove-from-checkout", item);
    },
    updateQuantity(value) {
      if (value < 0) {
        value = 0;
      }

      if (value > this.item.item_data.quantity) {
        value = this.item.item_data.quantity;
      }

      this.$emit("update-quantity", value);
      this.$nextTick(() => {
        this.updateQuantityApi();
      });
    },
    updateQuantityApi() {
      return HTTP({
        method: "PUT",
        url:
          "/api/v1/purchases/patients/" +
          this.patientId +
          "/cart-items/" +
          this.item.id,
        data: {
          quantity: parseInt(this.item.quantity),
          product_sold_by_doctor: {
            id: this.item.product_sold_by_doctor.id,
          },
        },
      }).catch(() => {});
    },
    updateDoctor(value) {
      this.$emit("update-doctor", value);
      this.$nextTick(() => {
        this.updateDoctorApi();
      });
    },
    updateDoctorApi() {
      return HTTP({
        method: "PUT",
        url:
          "/api/v1/purchases/patients/" +
          this.patientId +
          "/cart-items/" +
          this.item.id,
        data: {
          product_sold_by_doctor: {
            id: this.item.product_sold_by_doctor.id,
          },
        },
      }).catch(() => {});
    },
  },
};
</script>
