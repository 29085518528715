<template>
  <div class="options-menu-wrapper">
    <div
      class="action"
      :ref="'action_' + file.sku"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + file.sku],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="edit">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Edit</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent="deleteFile">
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Delete</div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemIcon from "@/views/private/components/ItemIcon";
import { default as EventBus } from "@/services/eventbus";
import { HTTP } from "@/services/api";
export default {
  name: "FileOptionsMenu",
  props: ["file"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.file.sku,
      });
    },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.file.sku) {
        this.menuOpened = false;
      }
    },
    edit() {
      this.$router.push({
        name: "inventoryItem",
        params: { id: this.file.id },
      });
    },
    deleteFile() {
      HTTP({
        method: "DELETE",
        url: "/api/v1/inventory/products/" + this.file.id,
      })
        .then(() => {
          EventBus.$emit("inventory-load");
          this.$emit("remove-shortcut");
        })
        .catch(() => {});
    },
  },
};
</script>
