<template>
  <patient-login
    v-if="$route.name === 'patientLogin'"
    :clinic-data="clinicData"
  />
  <patient-register
    v-if="$route.name === 'patientRegister'"
    :clinic-data="clinicData"
  />
</template>

<script>
import {
  HTTP_PUBLIC,
  setCancelToken,
  cancelTokens,
  logout,
} from "@/services/api";
import PatientLogin from "@/views/public/pages/patient/PatientLogin.vue";
import PatientRegister from "@/views/public/pages/patient/PatientRegister.vue";
export default {
  name: "PatientPage",
  components: { PatientRegister, PatientLogin },
  data: function () {
    return {
      clinicData: null,
      cancelToken: { clinic: null },
    };
  },
  created() {
    this.loadClinicData();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadClinicData() {
      setCancelToken(this.cancelToken, "clinic");
      return HTTP_PUBLIC({
        method: "GET",
        url:
          "/api/v1/clinic/" +
          (this.$route.params.clinic_slug
            ? this.$route.params.clinic_slug
            : this.$store.state.user.clinic.slug
            ? this.$store.state.user.clinic.slug
            : ""),
        params: {},
        cancelToken: this.cancelToken.clinic.token,
      })
        .then((response) => {
          if (response.data) {
            this.clinicData = response.data;
          }
          if (response.status === 404) {
            logout();
            this.$router.push("/").catch(() => {});
          }
        })
        .catch(() => {
          logout();
          this.$router.push("/").catch(() => {});
        });
    },
  },
};
</script>
