module.exports = {
  ALLOWED_MIME_TYPES: [
    "image/*",
    "video/*",
    "application/pdf",
    "application/zip",
    "text/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.apple.pages",
    "application/vnd.apple.numbers",
    "application/vnd.apple.keynote",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
  ],
  USE_ALLOWED_MIME_TYPES:
    process.env.VUE_APP_DOCUMENTS_LIMIT_MIME_TYPES === "true",
  MAX_FILESIZE_MB: 10,
  USE_MAX_FILE_SIZE_LIMIT:
    process.env.VUE_APP_DOCUMENTS_LIMIT_FILE_SIZE === "true",
};
