<template>
  <div class="modal create patients-modal-details" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="modal-dialog-main-tabs">
                  <div
                    @click="tabSelected = 'details'"
                    :class="{
                      'modal-dialog-main-tabs-item': true,
                      'active button': tabSelected === 'details',
                    }"
                  >
                    {{ $t("label.yourAccount") }}
                  </div>
                  <div
                    @click="tabSelected = 'agenda'"
                    :class="{
                      'modal-dialog-main-tabs-item': true,
                      helper: true,
                      'active button': tabSelected === 'agenda',
                    }"
                  >
                    {{ $t("button.upcomingAppointments") }}
                  </div>
                  <div
                    v-if="tabSelected === 'agenda'"
                    class="calendar-main-inner-top-agenda-icon print"
                    @click.prevent="printAgenda()"
                  ></div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <patient-details-profile v-if="tabSelected === 'details'" />
                  <patient-details-agenda v-if="tabSelected === 'agenda'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientDetailsProfile from "@/views/private/components/patients/PatientDetailsProfile";
import PatientDetailsAgenda from "@/views/private/components/patients/PatientDetailsAgenda";
import printJS from "print-js";

export default {
  name: "PatientDetails",
  components: { PatientDetailsAgenda, PatientDetailsProfile },
  data() {
    return {
      tabSelected: "details",
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    printAgenda() {
      printJS({
        style:
          "#agenda { overflow: hidden!important; width: 100% !important; height: auto!important; } .calendar-main-inner-agenda-item { height: 190px !important; } .calendar-main-inner-agenda-item-detail-bottom { margin-top: 10px !important; } .calendar-main-inner-agenda-item-detail-top { height: auto !important; margin-bottom: 10px !important; }.calendar-main-inner-agenda-item-detail-top-name { display: block!important; margin-top: 10px !important; } .calendar-main-inner-agenda-item-detail-top-title { margin-bottom-10px !important; width: 100% !important; } .calendar-main-inner-agenda-item-detail-top-time { line-height: 1.5 !important; margin-bottom: 15px !important; }",
        printable: "agenda",
        type: "html",
        targetStyles: ["*"],
        scanStyles: true,
        showModal: false,
      });
    },
  },
};
</script>

<style scoped></style>
