<template>
  <div :class="'color-campaign-' + decode(id)">
    <template v-if="clickable">
      <div v-if="selected" class="selected"></div>
      <div class="hover">
        <div v-if="selected" class="remove" @click="removeFromFavorites"></div>
        <div v-else class="add" @click="addToFavorites"></div>
      </div>
    </template>
  </div>
</template>
<script>
var Optimus = require("optimus-js");

var optimus = new Optimus(
  process.env.VUE_APP_OPTIMUS_PRIME,
  process.env.VUE_APP_OPTIMUS_INVERSE,
  process.env.VUE_APP_OPTIMUS_RANDOM
);
export default {
  name: "CampaignItemIcon",
  props: ["id", "clickable", "selected"],
  methods: {
    addToFavorites() {
      this.$emit("add-to-favorites");
    },
    removeFromFavorites() {
      this.$emit("remove-from-favorites");
    },
    decode(id) {
      return optimus.decode(id) % 18;
    },
  },
};
</script>
