<template>
  <div class="inventory-main">
    <div class="inventory-main-header">
      <div class="inventory-main-header-top">
        <div class="label">
          {{ $t("label.advancedSearch") }}
        </div>
      </div>
    </div>
    <div class="inventory-main-view">
      <div class="inventory-main-view-items">
        <inventory-view-file
          v-for="item in results"
          :file="item"
          :key="'item_' + item.id"
        ></inventory-view-file>
      </div>
    </div>
    <div class="inventory-main-footer">
      <pagination-per-page
        :page="$store.state.inventoryAdvanced.pagination.page"
        :perPage="$store.state.inventoryAdvanced.pagination.perPage"
        :allResults="results.length"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.inventoryAdvanced.pagination.page"
        :perPage="$store.state.inventoryAdvanced.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import InventoryViewFile from "@/views/private/components/inventory/InventoryViewFile";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
export default {
  name: "InventoryFilterView",
  components: { InventoryViewFile, PaginationPerPage, PaginationPage },
  data() {
    return {
      results: [],
      pages: [],
      cancelToken: {
        load: null,
      },
    };
  },
  created() {
    this.loadData();
    EventBus.$on("inventory-load", () => {
      this.loadData();
    });
  },
  unmounted() {
    EventBus.$off("inventory-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.inventoryAdvanced": {
      deep: true,
      handler: function () {
        this.loadData();
      },
    },
  },
  methods: {
    loadData() {
      setCancelToken(this.cancelToken, "load");
      this.results = [];
      this.count = 0;
      HTTP({
        method: "GET",
        url: "/api/v1/inventory/products/",
        data: {},
        cancelToken: this.cancelToken.load.token,
        params: {
          folder: {
            id:
              this.$store.state.inventoryAdvanced.query &&
              this.$store.state.inventoryAdvanced.query.folders
                ? this.$store.state.inventoryAdvanced.query.folders
                : null,
          },
          price_exact:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.price
              ? this.$store.state.inventoryAdvanced.query.price
              : null,
          price_min:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.price_min
              ? this.$store.state.inventoryAdvanced.query.price_min
              : null,
          price_max:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.price_max
              ? this.$store.state.inventoryAdvanced.query.price_max
              : null,
          quantity_exact:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.quantity
              ? this.$store.state.inventoryAdvanced.query.quantity
              : null,
          quantity_min:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.quantity_min
              ? this.$store.state.inventoryAdvanced.query.quantity_min
              : null,
          quantity_max:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.quantity_max
              ? this.$store.state.inventoryAdvanced.query.quantity_max
              : null,
          tags:
            this.$store.state.inventoryAdvanced.query &&
            this.$store.state.inventoryAdvanced.query.tags
              ? this.$store.state.inventoryAdvanced.query.tags
              : null,
          name: this.$store.state.inventoryAdvanced.search,
          per_page: this.$store.state.inventoryAdvanced.pagination.perPage,
          page: this.$store.state.inventoryAdvanced.pagination.page,
        },
      })
        .then((response) => {
          if (response.data) {
            this.results = response.data.data;
            this.count = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "inventoryAdvanced",
        data: value,
      });
    },
  },
};
</script>
