<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{ $t("label.addNewPos") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError(
                        'newPosTerminal.device_nickname'
                      ),
                    }"
                  >
                    <label class="item">{{ $t("label.deviceName") }}: </label>
                    <input
                      ref="deviceName"
                      v-model="newPosTerminal.device_nickname"
                      type="text"
                    />
                  </div>
                  <template v-if="isModeEdit()">
                    <div class="form-item">
                      <label class="item">{{ $t("label.deviceId") }}: </label>
                      <input
                        disabled
                        ref="deviceId"
                        v-model="newPosTerminal.poi_device_id"
                        type="text"
                      />
                    </div>
                  </template>
                  <template v-if="!isModeEdit()">
                    <div
                      class="form-item"
                      :class="{
                        error: validation.hasError(
                          'newPosTerminal.registration_code'
                        ),
                      }"
                    >
                      <label class="item">{{ $t("label.deviceId") }}: </label>
                      <input
                        ref="deviceId"
                        v-model="newPosTerminal.registration_code"
                        type="text"
                      />
                    </div>
                  </template>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="savePosTerminal()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PosCreate",
  components: {},
  validators: {
    "newPosTerminal.registration_code": function (value) {
      if (!this.isModeEdit()) {
        return Validator.value(value).required();
      }
    },
    "newPosTerminal.device_nickname": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      newPosTerminal: {
        device_nickname: null,
        registration_code: null,
        poi_device_id: null,
      },
      loading: false,
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadPosTerminal();
      }
    },
    isModeEdit() {
      return "posEdit" === this.$route.name;
    },
    loadPosTerminal() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/poi-devices/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newPosTerminal = response.data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    savePosTerminal() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/poi-devices/" +
            (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newPosTerminal);
          HTTP({
            method: "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.loading = false;
                self.closeModal();
                EventBus.$emit("pos-terminals-load");
              }
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
              self.loading = false;
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
