<template>
  <div class="row">
    <div class="col-lg-12 settings">
      <sidebar :selectedType="selectedType"></sidebar>
      <clinics-view v-if="selectedType === 'clinics'"></clinics-view>
      <users-view v-if="selectedType === 'users'"></users-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/settings/admin/Sidebar";
import ClinicsView from "@/views/private/components/settings/admin/ClinicsView";
import UsersView from "@/views/private/components/settings/admin/UsersView";
export default {
  name: "AdminSettings",
  components: {
    ClinicsView,
    UsersView,
    Sidebar,
  },
  data() {
    return {
      types: ["clinics", "users"],
      selectedType: null,
    };
  },
  created() {
    if (this.types.indexOf(this.$route.params.type) > -1) {
      this.selectedType = this.$route.params.type;
      return;
    }

    if (this.routeIn(["adminClinicAdd", "adminClinicEdit"])) {
      this.selectedType = "clinics";
      return;
    }

    if (this.routeIn(["adminUserAdd", "adminUserEdit"])) {
      this.selectedType = "users";
      return;
    }

    this.$router.push({ name: "adminSettings", params: { type: "clinics" } });
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        let value = val.type;
        if (!value) {
          return;
        }
        if (this.types.indexOf(value) > -1) {
          this.selectedType = value;
        } else {
          this.selectedType = "clinic";
        }
      },
      deep: true,
    },
  },
};
</script>
