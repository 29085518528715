<template>
  <form
    class="search-form"
    @submit.prevent=""
    @click="
      onboardTooltip(mode + 'SearchForm', 'Search form', 'top', 'right', 'top')
    "
    :ref="this.mode + 'SearchForm'"
  >
    <div
      class="search-form-input"
      :class="{
        'search-focused': searchFocus,
        'mobile-hide': selectMode,
        'with-toggle': mode === 'documents',
      }"
    >
      <div class="search-form-input-search icon-search"></div>
      <input
        type="text"
        ref="search"
        :placeholder="$t('placeholder.search') + '...'"
        v-model="searchValue"
        @keyup="setSearch"
      />
      <div
        class="search-form-input-clear icon-clear"
        v-show="searchValue"
        @click="clearSearch()"
      ></div>
    </div>
    <div v-if="this.mode === 'documents'">
      <div class="toggle">
        <label class="toggle-switch-wrapper">
          <input id="toggleValue" v-model="patientDocuments" type="checkbox" />
          <span class="toggle-switch"></span>
        </label>
        <label class="toggle-label" for="toggleValue">{{
          $t("label.searchPatientFolders")
        }}</label>
      </div>
    </div>
  </form>
</template>

<script>
import onboardTooltipMixin from "@/mixins/onboardTooltip";

export default {
  name: "SearchComponent",
  mixins: [onboardTooltipMixin],
  props: {
    mode: {
      type: String,
      default: "calendar", // possible values: calendar, inventory, inventoryAdvanced, documents, clinics, users, patients..
    },
  },
  data() {
    return {
      searchFocus: false,
      selectMode: false,
      searchValue: "",
    };
  },
  created() {
    this.initSearch();
  },
  mounted() {
    this.setSearch = this.asDebounce(this.setSearch, 1000);
  },
  computed: {
    patientDocuments: {
      get() {
        return this.$store.state.documents.patientDocuments;
      },
      set(value) {
        this.$store.dispatch("setPatientDocuments", value);
      },
    },
  },
  watch: {
    mode: function () {
      this.initSearch();
    },
  },
  methods: {
    initSearch() {
      if (this.mode === "calendar") {
        this.searchValue = this.$store.state.filter.search
          ? this.$store.state.filter.search
          : "";
      } else {
        this.searchValue = this.$store.state[this.mode]
          ? this.$store.state[this.mode].search
          : "";
      }
    },
    clearSearch() {
      this.searchValue = "";
      this.setSearch();
      this.$refs.search.focus();
    },
    setSearch() {
      if (this.mode === "calendar") {
        this.$store.dispatch("setFilter", {
          search: true,
          values: this.searchValue,
        });
      } else {
        this.$store.dispatch("setSearch", {
          type: this.mode,
          data: this.searchValue,
        });
      }
    },
  },
};
</script>

<style scoped></style>
