<template>
  <div class="patients-schedule-details" v-if="!loading">
    <div class="patients-schedule-details-accordion">
      <div
        :class="{
          'patients-schedule-details-accordion-item': true,
          active: accordionSelectedType === 'details',
        }"
      >
        <h3
          class="patients-schedule-details-accordion-item-title"
          @click="
            accordionSelectedType =
              accordionSelectedType === 'details' ? '' : 'details'
          "
        >
          {{ $tc("label.detail", 2) }}
        </h3>
        <div class="patients-schedule-details-accordion-item-content">
          <div
            class="form-item"
            :class="{
              error: validation.hasError('patient.first_name'),
            }"
          >
            <label class="item">{{ $t("label.firstName") }}: </label>
            <input ref="firstName" v-model="patient.first_name" type="text" />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('patient.last_name'),
            }"
          >
            <label class="item">{{ $t("label.lastName") }}: </label>
            <input v-model="patient.last_name" type="text" />
          </div>
          <div class="form-item">
            <label class="item">{{ $t("label.email") }}: </label>
            <input v-model="patient.email" type="text" readonly />
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('patient.birthday'),
            }"
          >
            <date-time-input
              ref="inputDate"
              enable-date-calendar
              input-mode
              show-label
              to-now
              use-date
              :index="0"
              :label="$t('label.dateOfBirth') + ':'"
              :original-start-date="patient.birthday"
              @value-start-date="setStart"
            ></date-time-input>
          </div>
          <div
            class="form-item"
            :class="{
              error: validation.hasError('patient.gender'),
            }"
          >
            <label class="item">{{ $t("label.gender") }}: </label>
            <v-select :options="genderList" v-model="patient.gender"></v-select>
          </div>
          <!--    <div-->
          <!--      class="form-item"-->
          <!--      :class="{-->
          <!--        error: validation.hasError('patient.referral_source')-->
          <!--      }"-->
          <!--    >-->
          <!--      <label class="item">{{ $t("label.referralSource") }}: </label>-->
          <!--      <input v-model="patient.referral_source" type="text" />-->
          <!--    </div>-->
          <div
            class="form-item"
            :class="{
              error: validation.hasError('patient.mobile_number'),
            }"
          >
            <label class="item">{{ $t("label.mobileNo") }}: </label>
            <input v-model="patient.mobile_number" type="text" />
          </div>
          <div class="form-item action">
            <a
              class="button action disable-close"
              @click.prevent="savePatient()"
            >
              {{ $t("button.save") }}
            </a>
          </div>
        </div>
      </div>
      <div
        :class="{
          'patients-schedule-details-accordion-item': true,
          active: accordionSelectedType === 'resetPassword',
        }"
      >
        <h3
          class="patients-schedule-details-accordion-item-title"
          @click="
            accordionSelectedType =
              accordionSelectedType === 'resetPassword' ? '' : 'resetPassword'
          "
        >
          {{ $t("label.changePassword") }}
        </h3>
        <div class="patients-schedule-details-accordion-item-content">
          <patient-password-change
            @accordionSelectedType="changeAccordionSelectedTypeOnSuccess"
          />
        </div>
      </div>
      <div class="form-item action margin-top-30">
        <button
          @click="handleLogout()"
          :class="{
            button: true,
            dark: true,
            height: true,
          }"
          type="submit"
        >
          {{ $t("button.logout") }}
        </button>
      </div>
    </div>
  </div>
  <div v-else class="loading"></div>
</template>

<script>
import PatientPasswordChange from "@/views/private/components/patients/PatientPasswordChange";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import SimpleVueValidator from "simple-vue3-validator";
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import { logout } from "@/services/api";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientDetailsProfile",
  components: { DateTimeInput, PatientPasswordChange },
  data: () => ({
    patient: {
      first_name: null,
      formDisable: false,
      last_name: null,
      mobile_number: null,
      // referral_source: null,
      gender: "",
      birthday: null,
      passport: "empty",
    },
    passwordResetForm: {
      current_password: null,
      password: null,
      password_confirmation: null,
    },
    oldPasswordVisible: false,
    passwordVisible: false,
    passwordConfirmationVisible: false,
    formDisable: false,
    genderList: ["male", "female", "other"],
    cancelToken: {
      load: null,
    },
    accordionSelectedType: null,
    loading: false,
  }),
  validators: {
    "patient.first_name": function (value) {
      return Validator.value(value).required();
    },
    "patient.last_name": function (value) {
      return Validator.value(value).required();
    },
    "patient.birthday": function (value) {
      return Validator.value(value).required();
    },
    "patient.gender": function (value) {
      return Validator.value(value).required();
    },
    "patient.mobile_number": function (value) {
      return Validator.value(value).required();
    },
  },
  created() {
    this.loadPatient();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadPatient() {
      this.loading = true;
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/patients/profile",
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data.data) {
            this.patient = response.data.data;
            this.patient.birthday = this.handleDateOnlyValue(
              this.patient.birthday
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    savePatient() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url = "/api/v1/patients/profile";
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.patient, [
            "birthday",
          ]);
          HTTP({
            method: "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    setStart(value) {
      this.patient.birthday = value;
    },
    handleLogout() {
      logout();
    },
    changeAccordionSelectedTypeOnSuccess(value) {
      this.accordionSelectedType = value;
    },
  },
};
</script>
