export default {
  methods: {
    openInNewTab(name, params) {
      let routeData = this.$router.resolve({
        name: name,
        params: params,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
