<template>
  <div></div>
</template>

<script>
import { logout } from "@/services/api";

export default {
  name: "PatientLogout", //TODO remove as soon as logout flow is provided
  data() {
    return {};
  },
  created() {
    logout();
  },
};
</script>
