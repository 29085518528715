export const REF_KEYS = {
  CALENDAR_EVENT_WRAPPER: "_calEventWrapper",
  CALENDAR_EVENT: "_calEvent",
  DATE_MONTH_INPUT: "_monthInput",
  DATE_DAY_INPUT: "_dayInput",
  DATE_YEAR_INPUT: "_yearInput",
  DATE_ORIGIN: "_date",
  START_DATE_HOUR_INPUT: "_startHourInput",
  START_DATE_MINUTE_INPUT: "_startMinuteInput",
  START_DATE_AM_PM_INPUT: "_startAmPmInput",
  START_ORIGIN: "_start",
  END_DATE_HOUR_INPUT: "_endHourInput",
  END_DATE_MINUTE_INPUT: "_endMinuteInput",
  END_DATE_AM_PM_INPUT: "_endAmPmInput",
  END_ORIGIN: "_end",
};

export default {
  methods: {
    updateMaskValue(key, value) {
      if (!this.$refs[key]) {
        return;
      }

      this.$refs[key].value = value;
      this.$refs[key].maskRef.updateValue();
    },
    checkForKeyPress(e) {
      if (e.keyCode === 8) {
        return this.asDebounce(this.backFunction(e), 400);
      }
      if (e.keyCode === 37) {
        return this.asDebounce(this.leftFunction(e), 400);
      }
      if (e.keyCode === 39) {
        return this.asDebounce(this.rightFunction(e), 400);
      }
    },
    backFunction(e) {
      if (e.target.maskRef && e.target.maskRef.masked.unmaskedValue === "") {
        let previousRef = e.target.dataset.previousRef;
        if (this.$refs[previousRef]) {
          e.preventDefault();
          this.$refs[previousRef].focus();
        }
      }
    },
    leftFunction(e) {
      if (e.target.maskRef && e.target.maskRef.cursorPos === 0) {
        let previousRef = e.target.dataset.previousRef;
        if (
          this.$refs[previousRef] &&
          this.$refs[previousRef].dataset.originRef ===
            e.target.dataset.originRef
        ) {
          e.preventDefault();
          this.$refs[previousRef].focus();
        }
      }
    },
    rightFunction(e) {
      if (
        e.target.maskRef &&
        parseInt(e.target.maskRef.cursorPos) ===
          parseInt(e.target.dataset.length)
      ) {
        let nextRef = e.target.dataset.nextRef;
        if (
          this.$refs[nextRef] &&
          this.$refs[nextRef].dataset.originRef === e.target.dataset.originRef
        ) {
          e.preventDefault();
          this.$refs[nextRef].focus();
        }
      }
    },
  },
};
