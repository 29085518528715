<template>
  <template v-if="loading && search"
    >{{ $t("message.searchingFor") }} "{{ search }}"</template
  >
  <template v-if="!loading && search && !resultsCount"
    >{{ $t("message.noResultsFor") }} "{{ search }}"</template
  >
  <template v-if="!search"><div></div></template>
</template>
<script>
export default {
  name: "VueSelectSearching",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    resultsCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
