<template>
  <div></div>
</template>

<script>
import {
  HTTP_PUBLIC,
  setCancelToken,
  cancelTokens,
  logout,
} from "@/services/api";
export default {
  name: "ClinicData",
  data: function () {
    return {
      cancelToken: { clinic: null },
    };
  },
  created() {
    setCancelToken(this.cancelToken, "clinic");
    return HTTP_PUBLIC({
      method: "GET",
      url:
        "/api/v1/clinic/" +
        (this.$route.params.clinic_slug
          ? this.$route.params.clinic_slug
          : this.$store.state.user.clinic.slug
          ? this.$store.state.user.clinic.slug
          : ""),
      params: {},
      cancelToken: this.cancelToken.clinic.token,
    })
      .then((response) => {
        if (response.data) {
          this.$emit("data-loaded", response.data);
        }
        if (response.status === 404) {
          logout();
          this.$router.push("/").catch(() => {});
        }
      })
      .catch(() => {
        logout();
        this.$router.push("/").catch(() => {});
      });
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
};
</script>
