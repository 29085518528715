<template>
  <div class="form patients-schedule patients-schedule-register">
    <div class="patients-schedule-inner">
      <div
        :class="{
          'patients-schedule-header': true,
          big: true,
        }"
      >
        <div class="patients-schedule-header-image">
          <template v-if="clinicData">
            <img
              class="image"
              v-if="clinicData.logo"
              :src="clinicData.logo"
              :alt="$route.params.clinic_slug + ' logo'"
            />
            <div v-else class="placeholder"></div>
          </template>
        </div>
        <h1 class="patients-schedule-header-title">
          {{ $t("clinic.verifyEmailTitle") }}
        </h1>
        <span v-if="sent || resend" class="patients-schedule-header-text"
          >{{ $t("clinic.verifyEmailText", { email: this.email }) }}
        </span>
      </div>
      <div class="patients-schedule-action bottom">
        <button
          class="button dark height"
          :class="{ disabled: loading }"
          @click="resendNotification"
        >
          {{ $t("button.resendVerificationEmail") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP_PUBLIC } from "@/services/api";

export default {
  name: "PatientVerifyEmail",
  props: ["clinicData", "email", "sent"],
  data() {
    return {
      loading: false,
      resend: false,
    };
  },
  methods: {
    resendNotification() {
      let self = this;
      self.loading = true;
      HTTP_PUBLIC({
        method: "POST",
        url: `/api/v1/clinics/${self.clinicData.slug}/patients/verification/email`,
        data: {
          email: self.email,
        },
      })
        .then(() => {
          self.loading = false;
          self.resend = true;
        })
        .catch(() => {
          self.loading = false;

          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.error"),
            text: self.$t("notification.notificationNotSent"),
          });
        });
    },
  },
};
</script>
