<template>
  <div class="calendar-main">
    <div class="calendar-main-inner">
      <div class="row">
        <div
          :class="{
            'col-lg-12': !showAgenda,
            'col-lg-8': showAgenda,
          }"
        >
          <div class="calendar-main-inner-top">
            <div
              :class="
                'calendar-main-inner-top-switch ' +
                $store.state.currentView.type
              "
              ref="calendarSwitch"
              @click.prevent="switchView()"
              v-tooltip.right="this.nextViewType"
            ></div>
            <a
              class="button calendar-main-inner-top-today"
              @click.prevent="today()"
            >
              {{ $t("button.today") }}
            </a>
            <div
              class="calendar-main-inner-top-prev"
              @click.prevent="previous"
            ></div>
            <div
              class="calendar-main-inner-top-next"
              @click.prevent="next"
            ></div>
            <div class="calendar-main-inner-top-text">
              {{ formattedTitle }}
            </div>
            <div class="calendar-main-inner-top-actions">
              <a
                v-if="showSplitButton"
                class="button calendar-main-inner-top-split"
                :class="{ active: this.splitView }"
                @click.prevent="toggleSplit()"
              >
                {{ $t("button.splitView") }}
              </a>
              <a
                v-if="showAgendaButton"
                class="button calendar-main-inner-top-agenda"
                @click.prevent="toggleAgenda()"
              >
                <div class="calendar-main-inner-top-agenda-icon agenda"></div>
                {{ $t("button.agenda") }}
              </a>
            </div>
          </div>
          <div
            :class="
              'calendar-main-inner-calendar ' + $store.state.currentView.type
            "
          >
            <vue-cal
              ref="cal"
              id="vuecal"
              hideTitleBar
              hideViewSelector
              :sticky-split-labels="true"
              :watch-real-time="true"
              :start-week-on-sunday="true"
              :transitions="false"
              :dblclick-to-navigate="false"
              :active-view="startView"
              :time-from="$store.getters.workingStartMinutes"
              :time-to="$store.getters.workingEndMinutes"
              :time-cell-height="timeCellHeight"
              :snap-to-time="15"
              :events="$store.getters.eventsObjects"
              :disable-views="['years', 'year']"
              :selected-date="$store.state.currentDate"
              :twelve-hour="!$store.getters.userHoursFormat24"
              :timeFormat="
                $store.getters.userHoursFormat24 ? 'HH:mm' : 'h {am}'
              "
              :split-days="splitData"
              :min-split-width="200"
              @view-change="viewChange"
              @cell-focus="setCurrentDate"
              @cell-click="handleDoubleClick"
              @ready="scrollToCurrentTime"
              class="vuecal--full-height-delete vuecal-main"
            >
              <!-- Split view header-->
              <template v-slot:split-label="{ split }">
                <calendar-slot-split-view :split-data="split" />
              </template>

              <!-- Custom heading -->
              <template v-slot:weekday-heading="{ heading }">
                <calendar-slot-weekday-heading
                  :heading="heading"
                ></calendar-slot-weekday-heading>
              </template>

              <!-- Custom events -->
              <template v-slot:event="{ event }">
                <calendar-slot-event :event="event"></calendar-slot-event>
              </template>

              <!-- Custom cells -->
              <template v-slot:cell-content="{ cell, view, events }">
                <div
                  class="calendar-main-inner-month-cell"
                  @click="handleMonthViewClick(cell, $event)"
                >
                  <calendar-slot-month-cell
                    :cell="cell"
                    :view="view"
                    :events="events"
                  ></calendar-slot-month-cell>
                </div>
              </template>
            </vue-cal>
          </div>
        </div>
        <div v-if="showAgenda" class="col-lg-4 sidebar-agenda">
          <div class="calendar-main-inner-top padding-left-0">
            <a
              class="button no-border calendar-main-inner-top-agenda margin-left-0 primary"
              @click.prevent="toggleAgenda()"
            >
              <div class="calendar-main-inner-top-agenda-icon agenda"></div>
              {{ $t("button.agenda") }}
            </a>
            <div
              class="calendar-main-inner-top-agenda-icon print"
              @click.prevent="printAgenda()"
            ></div>
            <!--            <div-->
            <!--              class="calendar-main-inner-top-agenda-icon share"-->
            <!--              @click.prevent="shareAgenda()"-->
            <!--            ></div>-->
          </div>
          <agenda />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import printJS from "print-js";
import onboardTooltipMixin from "@/mixins/onboardTooltip";
import CalendarSlotWeekdayHeading from "@/views/private/components/calendar/CalendarSlotWeekdayHeading";
import CalendarSlotEvent from "@/views/private/components/calendar/CalendarSlotEvent";
import Agenda from "@/views/private/components/agenda/Agenda";
import CalendarSlotSplitView from "@/views/private/components/calendar/CalendarSlotSplitView";
import CalendarSlotMonthCell from "@/views/private/components/calendar/CalendarSlotMonthCell";

export default {
  name: "MainCalendar",
  mixins: [onboardTooltipMixin],
  components: {
    CalendarSlotMonthCell,
    CalendarSlotSplitView,
    Agenda,
    VueCal,
    CalendarSlotWeekdayHeading,
    CalendarSlotEvent,
  },
  data: function () {
    return {
      views: ["day", "month", "week"],
      startView: this.$store.state.currentView.type,
      timeCellHeight: 240,
      agendaView: this.$route.name === "calendarDayAgenda",
      splitView: false,
      formattedTitle: "",
      latestMonth: 1,
      loading: false,
      filterParams: {},
      cancelToken: { load: null },
      dblClickCount: 0,
      dblClickTimer: null,
      nextViewType: null,
    };
  },
  watch: {
    "$store.state.currentDate": function (value) {
      this.$refs.cal.switchView(this.$store.state.currentView.type, value);
    },
    "$store.state.filter": {
      deep: true,
      handler: function () {
        this.setDoctorParams();
        this.setRoomParams();
        this.setTreatmentParams();
        this.setStatusesParams();
        this.setSearchParams();
        this.loadEvents();
      },
    },
  },
  computed: {
    workingTimeDiff() {
      return (
        this.$store.getters.workingEndMinutes -
        this.$store.getters.workingStartMinutes
      );
    },
    showAgenda() {
      return (
        ["day"].indexOf(this.$store.state.currentView.type) > -1 &&
        this.agendaView
      );
    },
    showAgendaButton() {
      return (
        ["day"].indexOf(this.$store.state.currentView.type) > -1 &&
        this.$store.getters.eventsObjects.length &&
        !this.agendaView
      );
    },
    splitData() {
      return this.splitView &&
        ["day"].indexOf(this.$store.state.currentView.type) > -1
        ? this.getSplitData(this.$store.state.focusedFilter)
        : [];
    },
    showSplitButton() {
      return ["day"].indexOf(this.$store.state.currentView.type) > -1;
    },
    hoursFrom() {
      return this.$store.getters.workingStartMinutes / 60;
    },
  },
  mounted() {
    this.latestMonth = this.$refs.cal.view.startDate.getMonth();
    this.setNextViewTypeWithCapitalization();
    this.viewChange();
    this.setDoctorParams();
    this.setRoomParams();
    this.setTreatmentParams();
    this.setStatusesParams();
    this.setSearchParams();
    this.loadEvents();
  },
  created() {
    if (this.$route.name === "calendarDayAgenda") {
      this.$store.dispatch("setCurrentView", {
        type: "day",
        start_date: this.$store.state.currentView.start_date,
        end_date: this.$store.state.currentView.end_date,
      });
    }
    EventBus.$on("event-load", () => {
      this.loadEvents();
    });
  },
  unmounted() {
    EventBus.$off("event-load");
    cancelTokens(this.cancelToken);
  },
  methods: {
    switchView() {
      let nextView =
        this.views[
          (this.views.indexOf(this.$store.state.currentView.type) + 1) %
            this.views.length
        ];
      this.$refs.cal.switchView(nextView, this.$store.state.currentDate);
      this.viewChange();
      if (nextView !== this.$route.params.view) {
        this.$router.push({ name: "calendar", params: { view: nextView } });
      }
      if (this.$refs.cal.view.startDate.getMonth() !== this.latestMonth) {
        this.$emit("main-calendar-month-change", this.$refs.cal.view.startDate);
      }
      this.setNextViewTypeWithCapitalization();
    },
    toggleSplit() {
      this.splitView = !this.splitView;
    },
    toggleAgenda() {
      if (this.agendaView) {
        this.agendaView = false;
        this.$router
          .push({ name: "calendar", params: { view: "day" } })
          .catch(() => {});
      } else {
        this.agendaView = true;
        this.$router.push({ name: "calendarDayAgenda" }).catch(() => {});
      }
    },
    printAgenda() {
      printJS({
        style:
          "#agenda { overflow: hidden!important; width: 100% !important; height: auto!important; } .calendar-main-inner-agenda-title-print { display: block !important; } .calendar-main-inner-agenda-item { height: 170px !important; } .calendar-main-inner-agenda-item-detail-bottom { margin-top: 10px !important; } .calendar-main-inner-agenda-item-detail-top { height: auto !important; margin-bottom: 10px !important; }.calendar-main-inner-agenda-item-detail-top-name { display: block!important; } .calendar-main-inner-agenda-item-detail-top-title { margin-bottom-10px !important; width: 100% !important; } .calendar-main-inner-agenda-item-detail-top-time { line-height: 1.5 !important; margin-bottom: 10px !important; }",
        printable: "agenda",
        type: "html",
        targetStyles: ["*"],
        scanStyles: true,
        showModal: false,
      });
    },
    getSplitData(type) {
      let self = this;
      return this.$store.state.filters[type]
        .filter(function (item) {
          return item &&
            self.$store.state.filter[type] &&
            self.$store.state.filter[type].length
            ? item.id
              ? self.$store.state.filter[type].indexOf(item.id) > -1
              : item
              ? self.$store.state.filter[type].indexOf(item) > -1
              : true
            : true;
        })
        .map(function (item) {
          return {
            id: item.id ?? item,
            class: type,
            label: (() => {
              switch (type) {
                case "doctors":
                  return item.first_name + " " + item.last_name;
                case "treatments":
                case "rooms":
                  return item.name;
                case "statuses":
                  return item;
              }
            })(),
          };
        });
    },
    today() {
      let today = this.newTimeZoneDate();
      this.$refs.cal.switchView(this.$store.state.currentView.type, today);
      this.$store.dispatch("setCurrentDate", this.fixTimezoneOffset(today));
      this.scrollToCurrentTime();
    },
    previous() {
      this.latestMonth = this.$refs.cal.view.startDate.getMonth();
      this.$refs.cal.previous();
      if (this.$refs.cal.view.startDate.getMonth() !== this.latestMonth) {
        this.$emit("main-calendar-month-change", this.$refs.cal.view.startDate);
      }
    },
    next() {
      this.latestMonth = this.$refs.cal.view.startDate.getMonth();
      this.$refs.cal.next();
      if (this.$refs.cal.view.startDate.getMonth() !== this.latestMonth) {
        this.$emit("main-calendar-month-change", this.$refs.cal.view.startDate);
      }
    },
    handleMonthViewClick(cell, event) {
      if (this.$store.state.currentView.type === "month") {
        this.dblClickCount++;
        if (this.dblClickCount === 1) {
          this.$store.dispatch(
            "setCurrentDate",
            this.fixTimezoneOffset(cell.startDate)
          );
          let self = this;
          this.dblClickTimer = setTimeout(function () {
            self.dblClickCount = 0;
          }, 500);
        } else {
          let ratio = event.offsetY / event.target.clientHeight;
          let eventMinutes =
            this.$store.getters.workingStartMinutes +
            parseInt(ratio * this.workingTimeDiff);
          let eventDate = cell.startDate.addMinutes(eventMinutes);
          this.createNewEvent(this.roundToNearestMinutes(eventDate, 15));
          clearTimeout(this.dblClickTimer);
          this.dblClickCount = 0;
        }
      }
    },
    handleDoubleClick(eventObject) {
      if (
        this.$store.state.currentView.type === "week" &&
        typeof eventObject.split === "object"
      ) {
        return;
      }

      if (this.$store.state.currentView.type !== "month") {
        this.createNewEvent(
          this.roundToNearestMinutes(
            this.splitView ? eventObject.date : eventObject,
            15
          ),
          this.$store.state.focusedFilter,
          this.splitView ? eventObject.split : null
        );
      }
    },
    scrollToCurrentTime() {
      this.formatCustomTitle();
      const calendar = document.querySelector("#vuecal .vuecal__bg");
      const hours =
        this.$refs.cal.now.getHours() -
        this.hoursFrom +
        this.$refs.cal.now.getMinutes() / 60;
      calendar.scrollTo({
        top: hours * this.$refs.cal.timeCellHeight,
        behavior: "smooth",
      });
    },
    viewChange() {
      this.$store.dispatch("setCurrentView", {
        type: this.$refs.cal.view.id,
        start_date:
          this.$store.state.currentView.type === "month"
            ? this.$refs.cal.view.firstCellDate
            : this.$refs.cal.view.startDate,
        end_date:
          this.$store.state.currentView.type === "month"
            ? this.$refs.cal.view.lastCellDate
            : this.$refs.cal.view.endDate,
      });
      this.formatCustomTitle();
      this.loadEvents();
    },
    formatCustomTitle() {
      if (this.$store.state.currentView.type === "week") {
        this.formattedTitle =
          this.getMonthName(this.$refs.cal.view.startDate) +
          " " +
          this.$refs.cal.view.startDate.format("D") +
          " - " +
          this.getMonthName(this.$refs.cal.view.endDate) +
          " " +
          this.$refs.cal.view.endDate.format("D") +
          ", " +
          this.$refs.cal.view.startDate.getFullYear();
      }
      if (this.$store.state.currentView.type === "month") {
        this.formattedTitle =
          this.getMonthName(this.$refs.cal.view.startDate) +
          ", " +
          this.$refs.cal.view.startDate.getFullYear();
      }
      if (this.$store.state.currentView.type === "day") {
        this.formattedTitle =
          this.getDayName(this.$refs.cal.view.startDate) +
          ", " +
          this.getMonthName(this.$refs.cal.view.startDate) +
          " " +
          this.$refs.cal.view.startDate.format("D") +
          ", " +
          this.$refs.cal.view.startDate.getFullYear();
      }
    },
    loadEvents() {
      this.loading = true;
      this.$store.dispatch("setEvents", {
        events: [],
      });
      this.setCurrentViewParams();
      this.setNotesParams();
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/events/",
        data: {},
        cancelToken: this.cancelToken.load.token,
        params: this.filterParams,
      })
        .then((response) => {
          if (response.data) {
            this.$store.dispatch("setEvents", {
              events: response.data,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setMonth(date) {
      this.$refs.cal.switchView("month", date);
      this.viewChange();
      if ("month" !== this.$route.params.view) {
        this.$router.push({ name: "calendar", params: { view: "month" } });
      }
    },
    setNotesParams() {
      if (
        this.$store.state.currentView.type === "week" ||
        this.$store.state.currentView.type === "day"
      ) {
        this.filterParams["include_notes"] = true;
      } else {
        delete this.filterParams["include_notes"];
      }
    },
    setCurrentDate(e) {
      if (this.$store.state.currentView.type !== "month") {
        this.$store.dispatch("setCurrentDate", this.fixTimezoneOffset(e));
      }
    },
    setRoomParams() {
      this.filterParams["room"] = {};
      this.filterParams["room"].id = this.$store.state.filter.rooms;
    },
    setDoctorParams() {
      this.filterParams["doctor"] = {};
      this.filterParams["doctor"].id = this.$store.state.filter.doctors;
    },
    setTreatmentParams() {
      this.filterParams["treatment"] = {};
      this.filterParams["treatment"].id = this.$store.state.filter.treatments;
    },
    setStatusesParams() {
      this.filterParams["status"] = {};
      this.filterParams["status"] = this.$store.state.filter.statuses;
    },
    setSearchParams() {
      this.filterParams["q"] = this.$store.state.filter.search;
    },
    setCurrentViewParams() {
      if (
        this.$store.state.currentView.start_date === null ||
        this.$store.state.currentView.end_date === null
      ) {
        return;
      }
      this.filterParams.start = this.parseToUTC(
        this.$store.state.currentView.start_date
      ).toISOString();
      this.filterParams.end = this.parseToUTC(
        this.$store.state.currentView.end_date
      ).toISOString();
      this.filterParams._sort = "start";
      this.filterParams._order = "asc";
    },
    setNextViewTypeWithCapitalization() {
      let nextType =
        this.views[
          (this.views.indexOf(this.$store.state.currentView.type) + 1) %
            this.views.length
        ];
      this.nextViewType = nextType.charAt(0).toUpperCase() + nextType.slice(1);
    },
  },
};
</script>

<style scoped></style>
