<template>
  <div class="row">
    <div class="col-lg-12 patients-form-consent-thank-you">
      <h3>Thank you</h3>
      <div>
        We appriciate your time, thank you for taking the time to fill out these
        forms
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientConsentThankYou",
  components: {},
};
</script>
