<template>
  <div class="documents-main-inner-preview">
    <div v-if="!currentActive.item" class="documents-main-inner-preview-thumb">
      Select a file to see more details
    </div>
    <div v-else class="documents-main-inner-preview-thumb">
      <template v-if="currentActive.type === 'folder'">
        {{ currentActive.item.name }}
      </template>
      <template v-else>
        <div class="preview" @click="openFile">
          <template v-if="typeof currentActive.item.preview !== 'undefined'">
            <img :src="getbase64EncodedImage(currentActive.item.preview)" />
          </template>
        </div>
        <div class="filename">
          {{ currentActive.item.name }}
        </div>
        <div class="filesize">
          {{ getFileSize(currentActive.item.size) }}
        </div>
      </template>
    </div>
    <div
      v-if="currentActive.item && !currentActive.item.patient_folder"
      class="documents-main-inner-preview-actions"
    >
      <div class="action" @click="renameAction">
        <div class="icon icon-edit"></div>
        Rename
      </div>
      <div
        v-if="currentActive.type === 'file'"
        class="action"
        @click="printFile(currentActive.item)"
      >
        <div class="icon icon-print"></div>
        Print
      </div>
    </div>
    <div
      class="documents-main-inner-preview-actions campaigns"
      v-if="
        currentActive.type === 'file' &&
        !currentActive.item.patient_folder &&
        !currentActive.item.patient_file
      "
    >
      <div
        class="campaign"
        v-for="campaign in $store.state.documentCampaigns"
        :key="campaign.id"
      >
        <campaign-item-icon
          :id="campaign.id"
          :clickable="true"
          :selected="isCampaignSelected(campaign.id)"
          @add-to-favorites="addToFavorites(campaign)"
          @remove-from-favorites="removeFromFavorites(campaign)"
        ></campaign-item-icon>
      </div>
    </div>
    <div
      class="documents-main-inner-preview-actions"
      v-if="this.$route.params.path !== '/patients'"
    >
      <div class="action" @click.prevent="handleUploadButton">
        <div class="icon icon-arrow-up"></div>
        Upload new
      </div>
      <div class="action" @click.prevent="createFolder">
        <div class="icon icon-folder black"></div>
        Create folder
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP, setCancelToken } from "@/services/api";
import printJS from "print-js";
import CampaignItemIcon from "./CampaignItemIcon";
import { default as EventBus } from "@/services/eventbus";
import fileMixin from "@/mixins/fileMixin";

export default {
  name: "DocumentsPreview",
  props: ["currentActive"],
  mixins: [fileMixin],
  components: {
    CampaignItemIcon,
  },
  data() {
    return {
      cancelToken: { preview: null },
    };
  },
  watch: {
    currentActive: function (val) {
      if (val.type) {
        this.loadPreview();
      }
    },
  },
  methods: {
    renameAction() {
      EventBus.$emit("open-modal", {
        type: "rename-file",
        file: this.currentActive.item,
        fileType: this.currentActive.type,
      });
    },
    createFolder() {
      EventBus.$emit("open-modal", {
        type: "create-folder",
      });
    },
    isCampaignSelected(campaignId) {
      return this.currentActive.item.campaigns.some(function (item) {
        return item.id === campaignId;
      });
    },
    addToFavorites(campaign) {
      this.$emit("add-to-favorites", campaign);
    },
    removeFromFavorites(campaign) {
      this.$emit("remove-from-favorites", campaign);
    },
    handleUploadButton() {
      this.$emit("handle-upload");
    },
    loadPreview() {
      if (
        this.currentActive.item.preview ||
        this.currentActive.type === "folder"
      ) {
        return;
      }
      setCancelToken(this.cancelToken, "preview");
      let self = this;
      HTTP({
        method: "GET",
        url: "/api/v1/files/preview/" + self.currentActive.item.id,
        cancelToken: self.cancelToken.preview.token,
      })
        .then(function (response) {
          if (response.data.file !== "" && self.currentActive.item) {
            self.currentActive.item["preview"] = response.data.file;
          }
        })
        .catch(function () {});
    },
    openFile() {
      let file = this.currentActive.item;

      if (!file) {
        return;
      }

      let extension = this.getPrintExtension(file.mime_type);
      if (["pdf", "image", "base64"].indexOf(extension) > -1) {
        HTTP({
          method: "GET",
          url: "/api/v1/files/download/" + file.id,
          responseType: "blob",
        }).then(function (response) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: file.mime_type })
          );
          window.open(url);
        });
      }
    },
    printFile(file) {
      let extension = this.getPrintExtension(file.mime_type);

      HTTP({
        method: "GET",
        url: "/api/v1/files/download/" + file.id,
        responseType: "blob",
      })
        .then(function (response) {
          const headerval = response.headers["content-disposition"];
          let filename = headerval
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");

          const url = window.URL.createObjectURL(new Blob([response.data]));

          if (["pdf", "image", "html", "base64"].indexOf(extension) > -1) {
            printJS({
              printable: url,
              type: extension,
              showModal: false,
            });
          } else {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(function () {});
    },
  },
};
</script>
