<template>
  <div
    class="documents-main-inner-upload"
    v-if="filesQueue.length && uploaderOpen"
  >
    <div
      :class="{
        'documents-main-inner-upload-header': true,
        expand: uploadListOpen,
      }"
    >
      <div class="documents-main-inner-upload-header-status">
        <div class="documents-main-inner-upload-header-status-label">
          {{ getUploaderStatusLabel }}
          <span class="status"> {{ getUploaderStatusPercent }} </span>
        </div>
        <div class="documents-main-inner-upload-header-status-actions">
          <div
            :class="{ expand: true, expanded: uploadListOpen }"
            @click.prevent="toggleExpand"
          ></div>
          <div class="close" @click.prevent="close"></div>
        </div>
      </div>
      <div
        :class="{
          'documents-main-inner-upload-header-progress': true,
          expand: uploadListOpen,
        }"
        :style="'width:' + getUploaderStatusPercent"
      ></div>
    </div>
    <div
      :class="{
        'documents-main-inner-upload-list': true,
        expand: uploadListOpen,
      }"
    >
      <div
        class="documents-main-inner-upload-list-item"
        v-for="(file, index) in filesQueue"
        :key="file.id"
      >
        <div
          :class="{
            'documents-main-inner-upload-list-item-action': true,
            error: file.error !== '',
          }"
        >
          <div class="documents-main-inner-upload-list-item-action-file">
            <div
              :class="'icon-file' + ' ' + getExtension(file.mime_type)"
            ></div>
            <div class="name">{{ file.name }}</div>
          </div>
          <div class="documents-main-inner-upload-list-item-action-status">
            <template v-if="file.error !== ''">
              <div class="failed">Failed</div>
              <div class="remove" @click.prevent="removeFromQueue(index)"></div>
            </template>
            <template v-if="file.success">
              <div class="size">{{ getFileSize(file.size) }}</div>
              <div class="remove" @click.prevent="removeFromQueue(index)"></div>
            </template>
            <template v-if="file.active">
              <div
                class="progress bar"
                :style="'width: ' + file.progress + '%'"
              ></div>
              <div class="progress"></div>
            </template>
          </div>
        </div>
        <div
          class="documents-main-inner-upload-list-item-retry"
          v-if="file.error !== ''"
          @click.prevent="startUpload(index)"
        >
          Retry
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileMixin from "@/mixins/fileMixin";
export default {
  name: "DocumentsUploaderQueue",
  props: ["filesQueue"],
  mixins: [fileMixin],
  data() {
    return {
      uploadListOpen: false,
      uploaderOpen: false,
    };
  },
  computed: {
    getUploaderStatusLabel() {
      let active = this.filesQueue.filter(function (item) {
        return item.active;
      });
      if (active.length) {
        return (
          "Uploading " +
          active.length +
          (active.length > 1 ? " files" : " file")
        );
      } else {
        let uploaded = this.filesQueue.filter(function (item) {
          return item.success;
        });
        return (
          "Uploaded " +
          uploaded.length +
          (uploaded.length > 1 ? " files" : " file")
        );
      }
    },
    getUploaderStatusPercent() {
      let activeTotal = 0;
      let activeSize = 0;
      let active = this.filesQueue.filter(function (item) {
        if (item.active) {
          activeTotal += (item.progress / 100) * item.size;
          activeSize += item.size;
        }
        return item.active;
      });
      if (active.length) {
        return Math.floor((activeTotal / activeSize) * 100) + "%";
      } else if (this.filesQueue.length) {
        let failed = this.filesQueue.filter(function (item) {
          return !item.success;
        });

        return failed.length === this.filesQueue.length ? "0%" : "100%";
      } else {
        return "0%";
      }
    },
  },
  methods: {
    open() {
      this.uploaderOpen = true;
    },
    close() {
      this.uploaderOpen = false;
    },
    toggleExpand() {
      this.uploadListOpen = !this.uploadListOpen;
    },
    removeFromQueue(index) {
      this.$emit("remove-from-queue", index);
    },
    startUpload(index) {
      this.$emit("start-upload", index);
    },
  },
};
</script>
