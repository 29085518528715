export default {
  data() {
    return {
      notificationKey: "",
      notificationTitle: "",
      notificationText: "",
      notifications: {
        calendar: {
          title: this.$t("notification.welcomeToKlinika"),
          text: this.$t("notification.welcomeToKlinika"),
        },
        documents: {
          title: this.$t("notification.filesRepository"),
          text: this.$t("notification.filesRepositoryText"),
        },
        inventory: {
          title: this.$t("notification.inventoryInit"),
          text: this.$t("notification.inventoryInitText"),
        },
        campaign: {
          initial: {
            title: this.$t("notification.campaign.initial"),
            text: this.$t("notification.campaign.initialText"),
          },
          followUp: {
            title: this.$t("notification.campaign.followUp"),
            text: this.$t("notification.campaign.followUpText"),
          },
          reminders: {
            title: this.$t("notification.campaign.reminders"),
            text: this.$t("notification.campaign.remindersText"),
          },
          invoices: {
            title: this.$t("notification.campaign.invoices"),
            text: this.$t("notification.campaign.invoicesText"),
          },
        },
      },
    };
  },
  watch: {
    "$store.state.documents.selectedCampaign": function (val) {
      if (val) {
        if (this.checkCampaignNotification()) {
          this.notificationKey = this.camelCase(
            this.$store.state.documents.selectedCampaign.name
          );
          this.notificationTitle =
            this.notifications.campaign[this.notificationKey].title;
          this.notificationText =
            this.notifications.campaign[this.notificationKey].text;
          this.notify(
            this.notificationKey,
            this.notificationTitle,
            this.notificationText
          );
        }
      }
    },
  },
  methods: {
    checkRouteNotification() {
      return (
        typeof this.$route.meta.firstVisitTooltip !== "undefined" &&
        this.$store.state.firstVisit.indexOf(this.$route.name) === -1
      );
    },
    checkCampaignNotification() {
      return (
        this.$store.state.firstVisit.indexOf(
          this.camelCase(this.$store.state.documents.selectedCampaign.name)
        ) === -1
      );
    },
    notify(key, title, text) {
      let self = this;
      setTimeout(function () {
        self.$notify({
          group: "prompt-calendar",
          duration: -1,
          closeOnClick: false,
          title: title,
          text: text,
          data: {
            confirm: true,
            onConfirmTooltip: self.logFirstVisit,
            key: key,
          },
        });
      }, 500);
      // this.$store.dispatch("addFirstVisit", key);
    },
    logFirstVisit(key) {
      this.$store.dispatch("addFirstVisit", key);
    },
  },
  mounted() {
    if (this.checkRouteNotification()) {
      this.notificationKey = this.$route.name;
      this.notificationTitle = this.notifications[this.$route.name].title;
      this.notificationText = this.notifications[this.$route.name].text;
      this.notify(
        this.notificationKey,
        this.notificationTitle,
        this.notificationText
      );
    }
  },
};
