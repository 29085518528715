import Logout from "@/views/private/pages/Logout";
import Home from "@/views/private/pages/Home";
import EventEditDetailed from "@/views/private/pages/EventEditDetailed";
import DocumentRepository from "@/views/private/pages/DocumentRepository";
import SoapNotesCreate from "@/views/private/pages/SoapNotesCreate";
import SoapNotesEdit from "@/views/private/pages/SoapNotesEdit";
import SoapNotesPreview from "@/views/private/pages/SoapNotesPreview";
import SoapNotes from "@/views/private/pages/SoapNotes.vue";
import PaymentsPatient from "@/views/private/pages/PaymentsPatient";
import PaymentsPatientHistory from "@/views/private/pages/PaymentsPatientHistory";
import Settings from "@/views/private/pages/settings/Settings";
import SettingsDoctors from "@/views/private/pages/settings/SettingsDoctors";
import Reports from "@/views/private/pages/Reports";
import Inventory from "@/views/private/pages/Inventory";
import Insurance from "@/views/private/pages/Insurance";
import PatientIndex from "@/views/private/pages/PatientIndex";
import PatientForm from "@/views/private/pages/PatientForm";
import PolicyInfo from "@/views/private/pages/PatientPolicyInfo";
import PatientSchedule from "@/views/private/pages/patient/PatientSchedule";
import PatientThankYou from "@/views/private/pages/patient/PatientThankYou";

export default [
  {
    path: "",
    name: "home",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Home",
    },
  },
  {
    path: "calendar/:view?",
    name: "calendar",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Calendar",
      firstVisitTooltip: true,
    },
  },
  {
    path: "calendar/day/agenda",
    name: "calendarDayAgenda",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Agenda",
    },
  },
  {
    path: "documents/:path?",
    name: "documents",
    component: DocumentRepository,
    meta: {
      role: ["Administrator"],
      title: "Documents",
      firstVisitTooltip: true,
    },
  },
  {
    path: "patients",
    name: "patients",
    component: PatientIndex,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Patients",
    },
  },
  {
    path: "inventory/",
    name: "inventory",
    component: Inventory,
    meta: {
      role: ["Administrator"],
      title: "Inventory",
      firstVisitTooltip: true,
    },
  },
  {
    path: "inventory/advanced-search",
    name: "inventoryAdvancedSearch",
    component: Inventory,
    meta: {
      role: ["Administrator"],
      title: "Inventory",
    },
  },
  {
    path: "inventory/item/:id?",
    name: "inventoryItem",
    component: Inventory,
    meta: {
      role: ["Administrator"],
      title: "Inventory",
    },
  },
  {
    path: "insurance-provider/add",
    name: "insuranceProviderAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Insurance Provider Add",
    },
  },
  {
    path: "insurance-provider/:id/edit",
    name: "insuranceProviderEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Insurance Provider Edit",
    },
  },
  {
    path: "insurance-eob/add",
    name: "insuranceWalletAdd",
    component: Insurance,
    meta: {
      role: ["Administrator"],
      title: "Insurance Wallet Add",
    },
  },
  {
    path: "insurance-eob/:id/edit",
    name: "insuranceWalletEdit",
    component: Insurance,
    meta: {
      role: ["Administrator"],
      title: "Insurance Wallet Edit",
    },
  },
  {
    path: "insurance/:type?",
    name: "insurance",
    component: Insurance,
    meta: {
      role: ["Administrator"],
      title: "Insurance",
    },
  },
  {
    path: "event/add",
    name: "eventAdd",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "event/:id/edit/detailed",
    name: "eventEditDetailed",
    component: EventEditDetailed,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "event/:id/edit",
    name: "eventEdit",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "event/:id/soap/add",
    name: "eventSoapAdd",
    component: SoapNotesCreate,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Event SOAP",
    },
  },
  {
    path: "event/add/detailed",
    name: "eventAddDetailed",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "event/:id/detail/patient",
    name: "eventDetailPatient",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Event Patient",
    },
  },
  {
    path: "event/:id/detail",
    name: "eventDetail",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "event/time-off/:id/detail",
    name: "timeOffDetail",
    component: Home,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Appointment",
    },
  },
  {
    path: "patient/:id/soap",
    name: "soapPatient",
    component: SoapNotes,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Patient SOAP",
    },
  },
  {
    path: "patient/:id/payments",
    name: "paymentsPatient",
    component: PaymentsPatient,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Payment",
    },
  },
  {
    path: "patient/:id/form/:form_id",
    name: "patientForm",
    component: PatientForm,
    meta: {
      title: "Patient Form",
    },
  },
  {
    path: "patient/:id/policy-info/",
    name: "policyInfo",
    component: PolicyInfo,
    meta: {
      title: "Policy Info",
    },
  },
  {
    path: "patient/:id/payments/history",
    name: "paymentsPatientHistory",
    component: PaymentsPatientHistory,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Payment History",
    },
  },
  {
    path: "soap/:id/",
    name: "soapPreview",
    component: SoapNotesPreview,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "SOAP",
    },
  },
  {
    path: "soap/:id/edit",
    name: "soapEdit",
    component: SoapNotesEdit,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "SOAP",
    },
  },
  {
    path: "soap-notes",
    name: "soapNotes",
    component: SoapNotes,
    props: { showAll: true },
    meta: {
      role: ["Administrator", "Doctors"],
      title: "SOAP Notes",
    },
  },
  {
    path: "reports/:type?/:sub_type?",
    name: "reports",
    component: Reports,
    meta: {
      role: ["Administrator"],
      title: "Reports",
    },
  },
  {
    path: "settings/:type?",
    name: "settings",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Settings",
    },
  },
  {
    path: "settings/doctors/:type?",
    name: "settingsDoctors",
    component: SettingsDoctors,
    meta: {
      role: ["Doctors"],
      title: "Settings",
    },
  },
  {
    path: "plan/add",
    name: "planAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Plan Add",
    },
  },
  {
    path: "plan/:id/edit",
    name: "planEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Plan Edit",
    },
  },
  {
    path: "charge-code/add",
    name: "chargeAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Charge Code Add",
    },
  },
  {
    path: "charge-code/:id/edit",
    name: "chargeEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Charge Code Edit",
    },
  },
  {
    path: "provider/add",
    name: "doctorAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Provider Add",
    },
  },
  {
    path: "provider/:id/edit",
    name: "doctorEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Provider Edit",
    },
  },
  {
    path: "provider/:id/status",
    name: "doctorChangeStatus",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Provider Status",
    },
  },
  {
    path: "room/add",
    name: "roomAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Room Add",
    },
  },
  {
    path: "room/:id/edit",
    name: "roomEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Room Edit",
    },
  },
  {
    path: "pos/:id/edit",
    name: "posEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "POS Edit",
    },
  },
  {
    path: "pos/add",
    name: "posAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "POS Add",
    },
  },
  {
    path: "treatment/add",
    name: "treatmentAdd",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Treatment Add",
    },
  },
  {
    path: "treatment/:id/edit",
    name: "treatmentEdit",
    component: Settings,
    meta: {
      role: ["Administrator"],
      title: "Treatment Edit",
    },
  },
  {
    path: "logout",
    name: "logout",
    component: Logout,
    meta: {
      role: ["Super Administrator", "Administrator", "Doctors"],
      title: "Logout",
    },
  },
  {
    path: "patient/add",
    name: "patientAdd",
    component: PatientIndex,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Patient Add",
    },
  },
  {
    path: "patient/:id/edit",
    name: "patientEdit",
    component: PatientIndex,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Patient Edit",
    },
  },
  {
    path: "patient/:id/status",
    name: "patientChangeStatus",
    component: PatientIndex,
    meta: {
      role: ["Administrator", "Doctors"],
      title: "Patient Status",
    },
  },
  {
    path: "patient/schedule",
    name: "patientSchedule",
    component: PatientSchedule,
    meta: {
      role: ["Patient"],
      title: "Patient Schedule Appointment",
      firstVisitTooltip: true,
    },
  },
  {
    path: "patient/:id/details",
    name: "patientDetails",
    component: PatientSchedule,
    meta: {
      role: ["Patient"],
      title: "Patient Details",
    },
  },
  {
    path: "patient/thankyou",
    name: "patientThankYou",
    component: PatientThankYou,
    meta: {
      role: ["Patient"],
      title: "Patient Thank You",
    },
  },
];
