<template>
  <div class="modal remove" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section">
            <div class="icon">
              <item-icon type="delete" :class-name="'color-red'"></item-icon>
            </div>

            <div class="title">You are about to delete a {{ type }}</div>
            <div class="sub-title">
              This will delete your
              {{ type === "file" ? "file" : "folder and its contents" }}
              permanentlly. <br />Are you sure?
            </div>
            <div class="action">
              <a class="button cancel" @click.prevent="closeModal"> Cancel </a>
              <a class="button delete" @click.prevent="remove"> Delete </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";

export default {
  name: "RemoveFile",
  props: ["type", "file"],
  components: {
    ItemIcon,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    remove() {
      let self = this;
      let url = "/api/v1/files/" + this.file.id;
      if (this.file.type === 2) {
        url = "/api/v1/files/folders/" + this.file.id;
      }
      HTTP({
        method: "DELETE",
        url: url,
        data: {},
      })
        .then(() => {
          self.$notify({
            group: "notification",
            type: "success",
            title: self.$t("notification.thumbsUp"),
            text: self.$t("notification.successDelete"),
          });
          EventBus.$emit("documents-load");
          if (this.file.type === 2) {
            EventBus.$emit("favorites-load");
          }
          self.closeModal();
        })
        .catch(() => {
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.errorDelete"),
          });
        });
    },
  },
};
</script>
