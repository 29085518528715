<template>
  <div class="modal" id="active-modal">
    <div class="modal-dialog">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section border-bottom">
            <div class="form-item padding-bottom-0">
              <div class="form-item-title">
                <div>Change quantity</div>
                <div class="icon-close-round" @click.prevent="closeModal"></div>
              </div>
            </div>
          </div>

          <div class="modal-dialog-main-section">
            <div class="modal-dialog-main-form">
              <div class="form-item">
                <div class="button-double">
                  <div class="button-double-left" @click.prevent="increase">
                    <div class="icon icon-increase"></div>
                    Increase
                  </div>
                  <div class="button-double-separator"></div>
                  <div class="button-double-right" @click.prevent="decrease">
                    <div class="icon icon-decrease"></div>
                    Decrease
                  </div>
                </div>
              </div>
              <div class="form-item double">
                <div class="form-item">
                  <label class="item">Amount</label>
                  <input
                    type="number"
                    v-model="increaseQuantity"
                    placeholder="+ Enter amount"
                    min="0"
                  />
                </div>
                <div class="form-item">
                  <label class="item">Updated quantity</label>
                  <input type="number" :value="updatedQuantity" disabled />
                </div>
              </div>
              <div class="form-item action">
                <a class="button action disabled" @click.prevent="closeModal()">
                  Cancel
                </a>
                <a class="button action" @click.prevent="save()"> Save </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
const Validator = SimpleVueValidator.Validator;
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";

export default {
  name: "UpdateQuantity",
  props: ["file"],
  data() {
    return {
      increaseQuantity: null,
      quantity: null,
    };
  },
  created() {
    //
  },
  mounted() {
    this.quantity = this.file.quantity;
  },
  validators: {
    fileName: function (value) {
      return Validator.value(value).required();
    },
  },
  computed: {
    updatedQuantity: function () {
      return (
        (this.quantity ? parseInt(this.quantity) : 0) +
        (this.increaseQuantity ? parseInt(this.increaseQuantity) : 0)
      );
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    increase() {
      this.quantity++;
    },
    decrease() {
      this.quantity--;
      if (this.quantity < 0) {
        this.quantity = 0;
      }
    },
    save() {
      const data = [];
      data["quantity"] =
        this.quantity +
        (this.increaseQuantity ? parseInt(this.increaseQuantity) : 0);

      const formData = this.generateFormData(data);
      HTTP({
        method: "POST",
        url: "/api/v1/inventory/products/" + this.file.id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          EventBus.$emit("inventory-load");
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.quantityUpdated"),
          });
          this.closeModal();
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.quantityNotUpdated"),
          });
        });
    },
  },
};
</script>
