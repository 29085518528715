<template>
  <div
    v-if="!emailVerify"
    class="form patients-schedule patients-schedule-register"
  >
    <div class="patients-schedule-inner">
      <div class="patients-schedule-header">
        <div class="patients-schedule-header-image">
          <template v-if="clinicData">
            <img
              class="image"
              v-if="clinicData.logo"
              :src="clinicData.logo"
              :alt="$route.params.clinic_slug + ' logo'"
            />
            <div v-else class="placeholder"></div>
          </template>
        </div>
        <h1 class="patients-schedule-header-title">
          {{ $t("clinic.loginTitle") }}
        </h1>
        <span class="patients-schedule-header-text">{{
          $t("clinic.loginText")
        }}</span>
      </div>
      <form
        ref="loginForm"
        @submit.prevent="login()"
        :class="{
          'patients-schedule-content': true,
          disabled: formDisable,
        }"
      >
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.email'),
          }"
        >
          <label class="item">{{ $t("label.enterEmail") }} </label>
          <input v-model="form.email" type="text" ref="email" />
        </div>
        <div
          class="form-item"
          :class="{
            error: validation.hasError('form.password'),
          }"
        >
          <label class="item">{{ $t("label.enterPassword") }} </label>
          <div class="password-input">
            <input
              v-model="form.password"
              :type="passwordVisible ? 'text' : 'password'"
              ref="password"
            />
            <span
              :class="{
                icon: true,
                active: passwordVisible,
              }"
              @click="passwordVisible = !passwordVisible"
            >
              <svg
                width="23"
                height="16"
                fill="none"
                viewBox="0 0 23 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25.83c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                  fill="#D5D9E9"
                />
              </svg>
            </span>
          </div>
        </div>
        <router-link
          class="link"
          :to="{
            name: 'patientForgotPassword',
            params: { clinic_slug: $route.params.clinic_slug },
          }"
        >
          {{ $t("label.forgotPassword") }}
        </router-link>
        <div class="patients-schedule-action">
          <button
            :class="{
              button: true,
              dark: true,
              height: true,
              disabled: formDisable,
            }"
            type="submit"
          >
            {{ $t("button.loginTogether") }}
          </button>

          <span class="label">{{ $t("label.dontHaveAccount") }}</span>
          <router-link
            :to="{
              name: 'patientRegister',
              params: { clinic_slug: $route.params.clinic_slug },
            }"
            class="button action height"
          >
            {{ $t("button.createAccount") }}
          </router-link>
        </div>
      </form>
      <div v-show="loading">{{ $t("message.loading") }}...</div>
    </div>
  </div>
  <patient-verify-email
    v-if="emailVerify"
    :email="form.email"
    :clinic-data="clinicData"
    :sent="false"
  />
</template>

<script>
import SimpleVueValidator from "simple-vue3-validator";
import { HTTP, HTTP_PUBLIC } from "@/services/api";
import PatientVerifyEmail from "@/views/public/pages/patient/PatientVerifyEmail.vue";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientLogin",
  components: { PatientVerifyEmail },
  props: ["clinicData"],
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
    "form.password": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      formDisable: false,
      passwordVisible: false,
      loading: false,
      emailVerify: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {},
  methods: {
    login() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.formDisable = true;
          HTTP_PUBLIC({
            method: "POST",
            url: `/api/v1/clinics/${self.$route.params.clinic_slug}/patients/login`,
            data: self.form,
          })
            .then((response) => {
              self.formDisable = false;
              self.$store.dispatch("setAuth", response.data).then(() => {
                self.loadUser();
              });
            })
            .catch((error) => {
              self.formDisable = false;
              if (error.response && error.response.status === 403) {
                self.$notify({
                  group: "notification",
                  type: "error",
                  title: self.$t("notification.error"),
                  text: self.$t("notification.pleaseVerifyEmail"),
                });
                self.emailVerify = true;
                return;
              }

              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.error"),
                text: self.$t("notification.checkCredentials"),
              });
            });
        } else {
          return false;
        }
      });
    },
    loadUser() {
      HTTP({
        method: "GET",
        url: "/api/v1/patients/profile",
      })
        .then((response) => {
          this.$store.dispatch("setUser", response.data).then(() => {
            this.$store.dispatch("setUserType", "patient");
            this.$router.push({ name: "patientSchedule" });
          });
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.error"),
            text: this.$t("notification.checkVerified"),
          });
          this.loading = false;
          this.formDisable = false;
        });
    },
  },
};
</script>
