<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{ $t("label.addCreditCard") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPayment.first_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.firstName") }}:</label>
                    <input
                      ref="firstName"
                      v-model="newPayment.first_name"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPayment.last_name'),
                    }"
                  >
                    <label class="item">{{ $t("label.lastName") }}</label>
                    <input v-model="newPayment.last_name" type="text" />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPayment.ccnumber'),
                    }"
                  >
                    <label class="item">{{ $t("label.cardNumber") }}</label>
                    <input
                      :value="newPayment.ccnumber"
                      v-imask="masks.maskCard"
                      ref="cardValue"
                      @accept="acceptCardValue"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPayment.ccexp'),
                    }"
                  >
                    <label class="item">{{ $t("label.cardExpire") }}</label>
                    <input
                      :value="newPayment.ccexp"
                      ref="expireValue"
                      v-imask="masks.maskExpire"
                      @accept="acceptExpireValue"
                      type="text"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="updateData()"
                    >
                      {{ $t("button.submit") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import IMask from "imask";
import { IMaskDirective } from "vue-imask";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "AddPaymentData",
  props: ["patient", "callback"],
  validators: {
    "newPayment.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newPayment.last_name": function (value) {
      return Validator.value(value).required();
    },
    "newPayment.ccnumber": function (value) {
      let scope = this;
      return Validator.custom(function () {
        if (value === "") {
          return "empty";
        }

        if (
          scope.$refs["cardValue"] &&
          !scope.$refs["cardValue"].maskRef.masked.isComplete
        ) {
          return "not complete";
        }
      });
    },
    "newPayment.ccexp": function (value) {
      let scope = this;
      return Validator.custom(function () {
        if (value === "") {
          return "empty";
        }

        if (
          scope.$refs["expireValue"] &&
          !scope.$refs["expireValue"].maskRef.masked.isComplete
        ) {
          return "not complete";
        }
      });
    },
  },
  data() {
    return {
      newPayment: {
        first_name: null,
        last_name: null,
        ccnumber: null,
        ccexp: null,
      },
      loading: false,
      observer: null,
      masks: {
        maskCard: {
          mask: "dddd dddd dddd dddd",
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 9,
              placeholderChar: "-",
            },
          },
          lazy: false,
          overwrite: false,
        },
        maskExpire: {
          mask: "block1 / block2",
          blocks: {
            block1: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              placeholderChar: "M",
            },
            block2: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 99,
              placeholderChar: "Y",
            },
          },
          lazy: false,
          overwrite: false,
        },
      },
    };
  },
  methods: {
    updateData() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newPayment);
          HTTP({
            method: "POST",
            url:
              "/api/v1/purchases/patients/" +
              self.patient.id +
              "/payments/info/add/",
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                if (self.callback) {
                  self.callback();
                }
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    acceptCardValue(e) {
      const maskRef = e.detail;
      this.newPayment.ccnumber = maskRef.value.replaceAll(/ /g, "");
    },
    acceptExpireValue(e) {
      const maskRef = e.detail;
      this.newPayment.ccexp = maskRef.value
        .replace(/ /g, "")
        .replace(/\//g, "");
    },
    closeModal() {
      this.$emit("close");
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped></style>
