/* eslint-disable no-useless-escape */
export default {
  data() {
    return {};
  },
  methods: {
    parseSingleSoapValue(soap) {
      let valueString = "";
      if (soap.value) {
        if (
          typeof soap.value === "object" &&
          ["image-input", "button-select"].indexOf(soap.type) === -1
        ) {
          valueString += soap.value.join(", ");
          valueString += " ";
        } else if (soap.type === "image-input") {
          valueString +=
            "<img src='" +
            process.env.VUE_APP_API +
            "/storage/" +
            soap.value +
            "'/>";
        } else if (soap.type === "button-select") {
          let groupValue = "";
          soap.value.forEach(function (item) {
            let value = "";
            if (item.parent) {
              value = "<i>" + item.value + "</i>";
            } else {
              value = item.value;
            }

            groupValue += value + (item.group ? ": " : ", ");
          });

          valueString += groupValue.slice(0, -2);
        } else if (soap.type === "slider") {
          valueString += soap.value;
          valueString += " (min: " + soap.min + " max: " + soap.max + ")";
          valueString += " ";
        } else {
          valueString += soap.value;
          valueString += " ";
        }
      }

      return valueString;
    },
    parseSingleSoapTemplate(soap) {
      let valueString = "";
      if (soap.type === "text") {
        return soap.value;
      }
      if (soap.type === "image-input") {
        return soap.unparsed;
      }
      if (soap.value) {
        valueString += " value=";
        if (typeof soap.value === "object") {
          soap.value.forEach(function (value) {
            if (
              typeof value === "object" &&
              ["button-select"].indexOf(soap.type) > -1
            ) {
              let preparedValue = value.value.replaceAll(/(\[|\])/g, "");
              valueString += '"' + preparedValue;
              if (value.group) {
                valueString += "|group:" + value.group;
              }
              if (value.parent) {
                valueString += "|parent:" + value.parent;
              }
              valueString += '", ';
            } else {
              let preparedValue = value.replaceAll(/(\[|\])/g, "");
              valueString += '"' + preparedValue + '", ';
            }
          });
          valueString = valueString.slice(0, -2);
        } else {
          let preparedValue = soap.value.replaceAll(/(\[|\])/g, "");
          valueString += '"' + preparedValue + '"';
        }
      }
      return valueString !== ""
        ? this.makeTemplateFromObject(soap, valueString)
        : soap.unparsed;
    },
    makeTemplateFromObject(soap, value) {
      let templateString = "[" + soap.type + " ";
      templateString += value;
      if (soap.min) {
        templateString += ' min="' + soap.min + '"';
      }
      if (soap.max) {
        templateString += ' max="' + soap.max + '"';
      }
      if (soap.step) {
        templateString += ' step="' + soap.step + '"';
      }
      if (soap.multiple) {
        templateString += ' multiple="true"';
      }
      if (soap.options) {
        let optionsString = " options=";
        if (typeof soap.options === "object") {
          soap.options.forEach(function (value) {
            if (
              typeof value === "object" &&
              ["button-select"].indexOf(soap.type) > -1
            ) {
              let group = value.group ? "|group:" + value.group : null;
              let parent = value.parent ? "|parent:" + value.parent : null;
              optionsString +=
                '"' +
                value.value +
                (group ? group : "") +
                (parent ? parent : "") +
                '", ';
            } else {
              optionsString += '"' + value + '", ';
            }
          });
          optionsString = optionsString.slice(0, -2);
        } else {
          optionsString += '"' + soap.value + '"';
        }
        templateString += optionsString;
      }

      templateString += "]";

      return templateString;
    },
    prepareSoapFile(soapValues, createMode = false) {
      let files = [];
      soapValues.soap.forEach(function (soap) {
        if (
          ["image-input"].indexOf(soap.type) > -1 &&
          ((soap.value && typeof soap.value === "object") ||
            (soap.value === null && !createMode))
        ) {
          files[soap.name] = soap.value ? soap.value.file : null;
        }
      });
      return files;
    },
    prepareSoapTemplate(templateValues) {
      let valueString = "";
      let self = this;
      templateValues.soap.forEach(function (soap) {
        valueString += self.parseSingleSoapTemplate(soap);
      });

      return valueString;
    },
    prepareSoapText(templateValues) {
      let valueString = "";
      let self = this;
      templateValues.soap.forEach(function (soap) {
        valueString += self.parseSingleSoapValue(soap);
      });

      return valueString;
    },
    toggleButtonSelect(soap, value) {
      if (!soap.value) {
        soap.value = [];
      }

      let index =
        typeof value === "object" && ["button-select"].indexOf(soap.type) > -1
          ? soap.value.findIndex(function (item) {
              return item.value === value.value;
            })
          : soap.value.indexOf(value);

      if (index === -1) {
        if (value.parent) {
          let parentIndex = soap.value.findIndex(function (item) {
            return item.group === value.parent;
          });

          if (parentIndex !== -1) {
            soap.value.splice(parentIndex + 1, 0, value);
          } else {
            soap.value.push(value);
          }
        } else {
          soap.value.push(value);
        }
      } else {
        soap.value.splice(index, 1);
        if (
          soap.value &&
          value.group !== null &&
          ["button-select"].indexOf(soap.type) > -1
        ) {
          soap.value = soap.value.filter(function (item) {
            return item.parent === null || item.parent !== value.group;
          });
        }
      }
    },
    getValueFromTemplate(template) {
      let soap = this.parseSoap(template);
      let value = "";
      let self = this;
      soap.forEach(function (item) {
        value += self.parseSingleSoapValue(item);
      });

      return value;
    },
    parseSoap: function (unparsed) {
      let soap = [];
      let componentsRegExp = /([^\[]*)(\[[^\]]*\]*)([^\[]*)/gm;

      let componentTypeRegExp = /\[([^( |\])]*)/;
      let componentOptionsRegExp =
        /.*options=((.*?(?=multiple=|value=)|[^\]]*)){1}/;
      let componentValueRegExp =
        /.*value=((.*?(?=multiple=|options=|step=|min=|max=)|[^\]]*)){1}/;
      let componentMultipleRegExp =
        /.*multiple=((.*?(?=value=|options=)|[^\]]*)){1}/;
      let componentMinRegExp =
        /.*min="((.*?(?=multiple=|options=|step=|value=|max=)|[^\]"]*)){1}"/;
      let componentMaxRegExp =
        /.*max="((.*?(?=multiple=|options=|step=|min=|value=)|[^\]"]*)){1}"/;
      let componentNameRegExp =
        /.*name="((.*?(?=multiple=|options=|step=|min=|value=)|[^\]"]*)){1}"/;
      let componentStepRegExp =
        /.*step="((.*?(?=multiple=|options=|value=|min=|max=)|[^\]"]*)){1}"/;

      let components = unparsed.matchAll(componentsRegExp);
      for (let component of components) {
        let textBefore = component[1];
        let componentText = component[2];
        let textAfter = component[3];

        let type = componentText.match(componentTypeRegExp);
        let options = this.parseOptions(
          componentText.match(componentOptionsRegExp),
          type ? type[1] : null
        );
        let multiple = this.parseMultiple(
          componentText.match(componentMultipleRegExp)
        );

        let value = this.parseValue(
          componentText.match(componentValueRegExp),
          multiple || type ? type[1] === "button-select" : false,
          type ? type[1] : null
        );
        let min = componentText.match(componentMinRegExp);
        let max = componentText.match(componentMaxRegExp);
        let name = componentText.match(componentNameRegExp);
        let step = componentText.match(componentStepRegExp);
        if (textBefore) {
          soap.push({
            unparsed: textBefore,
            type: "text",
            options: null,
            value: textBefore,
            multiple: null,
          });
        }
        soap.push({
          unparsed: componentText,
          type: type ? type[1] : null,
          options: options ? options : null,
          value: value ? value : null,
          multiple: multiple ? multiple : null,
          min: min ? min[1] : null,
          max: max ? max[1] : null,
          name: name ? name[1] : null,
          step: step ? step[1] : null,
        });
        if (textAfter) {
          soap.push({
            unparsed: textAfter,
            type: "text",
            options: null,
            value: textAfter,
            multiple: null,
          });
        }
      }

      return soap;
    },
    parseMultiple(value) {
      if (!value) {
        return;
      }
      value = value[1].trim();
      if (value === '"true"') {
        return true;
      }
      return false;
    },
    parseValue(value, multiple = false, type) {
      if (multiple) {
        return this.parseOptions(value, type);
      }
      return value ? value[1].trim().replace(/(^\"|\"$)/g, "") : "";
    },
    parseOptions(options, type) {
      if (!options) {
        return;
      }
      options = options[1].trim();
      let returnArray = [];
      // eslint-disable-next-line
      let optionsRegExp = /(\"([^\"]*)\")/gm;
      if (options === "") {
        return null;
      }
      // eslint-disable-next-line
      let parsedOptions = options.matchAll(optionsRegExp);
      for (let parsedOption of parsedOptions) {
        if (["button-select"].indexOf(type) > -1) {
          let split = parsedOption[2].split("|");
          let object = {
            value: split[0],
            group: this.getAdditionalValue(split, "group"),
            parent: this.getAdditionalValue(split, "parent"),
          };
          returnArray.push(object);
        } else {
          returnArray.push(parsedOption[2]);
        }
      }
      return returnArray;
    },
    getAdditionalValue(splitArray, type) {
      if (typeof splitArray[1] === "undefined") {
        return null;
      }

      let groupSplit = splitArray[1].split(":");
      if (groupSplit[0] === type) {
        return typeof groupSplit[1] !== "undefined" ? groupSplit[1] : null;
      }

      return null;
    },
    setSoapItemValue(item, index) {
      if (item.object) {
        item["title"] = item.object.title;
        item["label_result"] = item.object.label_result;
        item.object["soap"] = this.parseSoap(item.object.template);

        this.fixTextareaHeight();
      }
      if (this.$refs[item.soap_note_type.id + "_" + index]) {
        let scope = this;
        setTimeout(function () {
          if (
            scope.$refs[item.soap_note_type.id + "_" + index]
              .getElementsByClassName("condition-template")[0]
              .getElementsByTagName("input")[0]
          ) {
            scope.$refs[item.soap_note_type.id + "_" + index]
              .getElementsByClassName("condition-template")[0]
              .getElementsByTagName("input")[0]
              .focus();
          } else if (
            scope.$refs[item.soap_note_type.id + "_" + index]
              .getElementsByClassName("condition-template")[0]
              .getElementsByTagName("textarea")[0]
          ) {
            scope.$refs[item.soap_note_type.id + "_" + index]
              .getElementsByClassName("condition-template")[0]
              .getElementsByTagName("textarea")[0]
              .focus();
          }
        }, 500);
      }
    },
  },
};
