<template>
  <div class="options-menu-wrapper">
    <div
      class="action"
      :ref="'action_' + file.name"
      @click.stop="toggleMenu"
    ></div>
    <div class="options-menu-menu-arrow" v-if="menuOpened"></div>
    <div
      class="options-menu-menu"
      v-if="menuOpened"
      v-closable="{
        exclude: ['action_' + file.name],
        handler: 'closeMenu',
      }"
    >
      <div class="options-menu-menu-item" @click.prevent="renameFile">
        <item-icon type="edit" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Rename</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent="deleteFile">
        <item-icon type="delete" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Delete</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent="addToCampaign">
        <item-icon type="campaign" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Add to campaign</div>
      </div>
      <div class="options-menu-menu-item" @click.prevent.stop="downloadFile">
        <item-icon type="campaign" :class-name="'color-gray'"></item-icon>
        <div class="options-menu-menu-item-label">Download</div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemIcon from "@/views/private/components/ItemIcon";
import { default as EventBus } from "@/services/eventbus";
import { HTTP } from "@/services/api";

export default {
  name: "FileOptionsMenu",
  props: ["file"],
  components: { ItemIcon },
  data() {
    return {
      menuOpened: false,
    };
  },
  created() {
    EventBus.$on("close-options-menu", (params) => {
      this.closeMenu(params.ref);
    });
  },
  unmounted() {
    EventBus.$off("close-options-menu");
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      EventBus.$emit("close-options-menu", {
        ref: "action_" + this.file.name,
      });
    },
    closeMenu(ref = null) {
      if (ref === null) {
        this.menuOpened = false;
      }

      if (ref && ref !== "action_" + this.file.name) {
        this.menuOpened = false;
      }
    },
    downloadFile() {
      this.closeMenu();
      HTTP({
        method: "GET",
        url: "/api/v1/files/download/" + this.file.id,
        responseType: "blob",
      })
        .then(function (response) {
          const headerval = response.headers["content-disposition"];
          let filename = headerval
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(function () {});
    },
    renameFile() {
      EventBus.$emit("open-modal", {
        type: "rename-file",
        file: this.file,
        fileType: "file",
      });
    },
    deleteFile() {
      EventBus.$emit("open-modal", {
        type: "remove-file",
        file: this.file,
        fileType: "file",
      });
    },
    addToCampaign() {
      EventBus.$emit("open-modal", {
        type: "add-to-campaign",
        file: this.file,
      });
    },
  },
};
</script>
