<template>
  <div class="row">
    <div class="col-lg-12 settings">
      <sidebar :selectedType="selectedType"></sidebar>
      <doctors-view v-if="selectedType === 'providers'"></doctors-view>
      <plans-view v-if="selectedType === 'plans'"></plans-view>
      <charges-view v-if="selectedType === 'charge-codes'"></charges-view>
      <rooms-view v-if="selectedType === 'rooms'"></rooms-view>
      <treatments-view v-if="selectedType === 'treatments'"></treatments-view>
      <insurance-providers-view
        v-if="selectedType === 'insurance-providers'"
      ></insurance-providers-view>
      <clinic-view v-if="selectedType === 'clinic'"></clinic-view>
      <pos-view v-if="selectedType === 'pos-terminals'"></pos-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/private/components/settings/sidebar/Sidebar";
import DoctorsView from "@/views/private/components/settings/DoctorsView";
import PlansView from "@/views/private/components/settings/PlansView";
import ChargesView from "@/views/private/components/settings/ChargesView";
import RoomsView from "@/views/private/components/settings/RoomsView";
import TreatmentsView from "@/views/private/components/settings/TreatmentsView";
import ClinicView from "@/views/private/components/settings/ClinicView";
import PosView from "@/views/private/components/settings/PosView";
import InsuranceProvidersView from "@/views/private/components/settings/InsuranceProvidersView.vue";
export default {
  name: "SettingsComponent",
  components: {
    InsuranceProvidersView,
    ClinicView,
    TreatmentsView,
    PlansView,
    ChargesView,
    DoctorsView,
    RoomsView,
    PosView,
    Sidebar,
  },
  data() {
    return {
      types: [
        "charge-codes",
        "clinic",
        "insurance-providers",
        "plans",
        "pos-terminals",
        "providers",
        "rooms",
        "treatments",
      ],
      selectedType: null,
    };
  },
  created() {
    if (this.types.indexOf(this.$route.params.type) > -1) {
      this.selectedType = this.$route.params.type;
      return;
    }

    if (this.routeIn(["doctorAdd", "doctorEdit", "doctorChangeStatus"])) {
      this.selectedType = "providers";
      return;
    }

    if (this.routeIn(["planAdd", "planEdit"])) {
      this.selectedType = "plans";
      return;
    }

    if (this.routeIn(["chargeAdd", "chargeEdit"])) {
      this.selectedType = "charge-codes";
      return;
    }

    if (this.routeIn(["treatmentAdd", "treatmentEdit"])) {
      this.selectedType = "treatments";
      return;
    }

    if (this.routeIn(["roomAdd", "roomEdit"])) {
      this.selectedType = "rooms";
      return;
    }

    if (this.routeIn(["posAdd", "roomEdit"])) {
      this.selectedType = "pos-terminals";
      return;
    }

    if (this.routeIn(["insuranceProviderAdd", "insuranceProviderEdit"])) {
      this.selectedType = "insurance-providers";
      return;
    }

    this.$router
      .push({ name: "settings", params: { type: "clinic" } })
      .catch(() => {});
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        let value = val.type;
        if (typeof value === "undefined") {
          return;
        }

        if (this.types.indexOf(value) > -1) {
          this.selectedType = value;
          return;
        }

        this.selectedType = "clinic";
      },
      deep: true,
    },
  },
};
</script>
