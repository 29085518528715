<template>
  <div
    :class="{
      'form-item': true,
      error: hasError,
    }"
  >
    <label v-if="label" class="item inline">{{ label }}</label>
    <slot name="label"></slot>
    <div class="toggle">
      <label class="toggle-switch-wrapper">
        <input
          id="toggleValue"
          :checked="modelValue"
          @change="
            ($event) => $emit('update:modelValue', !!$event.target.checked)
          "
          type="checkbox"
        />
        <span class="toggle-switch"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInputCheckboxToggle",
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
