export default {
  data() {
    return {};
  },
  methods: {
    getBalanceDue(item) {
      let adjustment = item.adjustment ? parseFloat(item.adjustment) : 0;
      let billed_amount = item.billed_amt ? parseFloat(item.billed_amt) : 0;
      let insurance_payment = item.insurance_payment
        ? parseFloat(item.insurance_payment)
        : 0;
      let patient_payment = item.patient_payment
        ? parseFloat(item.patient_payment)
        : 0;

      let balance_due =
        billed_amount - insurance_payment - patient_payment - adjustment;
      return Math.round(balance_due * 100) / 100;
    },
  },
};
