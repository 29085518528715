<template>
  <div>Logout</div>
</template>

<script>
import { logout } from "@/services/api";

export default {
  name: "LogoutComponent",
  created() {
    this.handleLogout();
  },
  methods: {
    handleLogout() {
      logout();
    },
  },
};
</script>

<style scoped></style>
