<template>
  <div :class="{ 'datetime-input-mode': inputMode, error: timeError || error }">
    <label v-if="showLabel" class="item">{{ label }}</label>
    <div
      :class="{
        'datetime-input-wrapper': true,
        'input-mode': inputMode,
        'calendar-disabled': !enableDateCalendar,
      }"
      :ref="index + refKeys.CALENDAR_EVENT_WRAPPER"
    >
      <div>
        <template v-if="useDate">
          <input
            v-imask="masks.monthMask"
            :class="{
              'date-time-input': true,
              short: true,
              expanded:
                currentDateValues.month === '' ||
                currentDateValues.month.indexOf('m') > -1,
            }"
            :disabled="disabled"
            :data-length="2"
            :data-next-ref="index + refKeys.DATE_DAY_INPUT"
            :data-origin-ref="index + refKeys.DATE_ORIGIN"
            :ref="index + refKeys.DATE_MONTH_INPUT"
            @accept="
              acceptDateValue($event, 'month', index + refKeys.DATE_DAY_INPUT)
            "
            @click="activeCalendarEdit = true"
            @keydown="checkForKeyPress"
          />
          <div class="separator">/</div>
          <input
            class="short date-time-input"
            v-imask="masks.dayMask"
            :disabled="disabled"
            :data-length="2"
            :data-previous-ref="index + refKeys.DATE_MONTH_INPUT"
            :data-next-ref="index + refKeys.DATE_YEAR_INPUT"
            :data-origin-ref="index + refKeys.DATE_ORIGIN"
            :ref="index + refKeys.DATE_DAY_INPUT"
            @accept="
              acceptDateValue($event, 'day', index + refKeys.DATE_YEAR_INPUT)
            "
            @click="activeCalendarEdit = true"
            @keydown="checkForKeyPress"
          />
          <div class="separator">/</div>
          <input
            class="wide date-time-input"
            v-imask="masks.yearMask"
            :disabled="disabled"
            :data-length="4"
            :data-previous-ref="index + refKeys.DATE_DAY_INPUT"
            :data-origin-ref="index + refKeys.DATE_ORIGIN"
            :ref="index + refKeys.DATE_YEAR_INPUT"
            @accept="
              acceptDateValue(
                $event,
                'year',
                index + refKeys.START_DATE_HOUR_INPUT
              )
            "
            @complete="dateComplete"
            @click="activeCalendarEdit = true"
            @keydown="checkForKeyPress"
          />
        </template>
        <div
          class="datetime-picker"
          v-closable="{
            exclude: [index + refKeys.CALENDAR_EVENT_WRAPPER],
            handler: 'closePicker',
          }"
          v-if="enableDateCalendar"
          v-show="enableDateCalendar && activeCalendarEdit"
        >
          <div class="datetime-inner-calendar">
            <div class="datetime-inner-calendar-top">
              <div
                class="datetime-inner-calendar-top-date"
                v-if="
                  typeof this.$refs[this.index + refKeys.CALENDAR_EVENT] !==
                  'undefined'
                "
              >
                <span class="datetime-inner-calendar-top-date-month"
                  >{{
                    this.getMonthName(
                      this.$refs[this.index + refKeys.CALENDAR_EVENT].view
                        .startDate
                    )
                  }}
                  {{
                    this.$refs[
                      this.index + refKeys.CALENDAR_EVENT
                    ].view.startDate.getFullYear()
                  }}</span
                >
              </div>
              <div class="datetime-inner-calendar-top-arrows">
                <div
                  class="datetime-inner-calendar-top-prev"
                  @click.prevent="previous"
                ></div>
                <div
                  class="datetime-inner-calendar-top-next"
                  @click.prevent="next"
                ></div>
              </div>
            </div>
            <vue-cal
              active-view="month"
              class="vuecal--full-height-delete vuecal-event"
              click-to-navigate
              hide-view-selector
              hideTitleBar
              xsmall
              :start-week-on-sunday="true"
              :transitions="false"
              :disable-views="['day', 'week', 'year']"
              :min-date="
                fromNow
                  ? this.newTimeZoneDate()
                  : minDate
                  ? this.parseFromUTC(minDate)
                  : null
              "
              :max-date="toNow ? this.newTimeZoneDate() : null"
              :ref="index + refKeys.CALENDAR_EVENT"
              :selected-date="currentDate"
              :time="false"
              @cell-click="setDate"
            >
              <template v-slot:cell-content="{ cell, view }">
                <span
                  :class="{
                    'vuecal__cell-date': true,
                    'same-week':
                      cell.startDate.getWeek() === view.selectedDate.getWeek(),
                  }"
                  >{{ cell.content }}</span
                >
              </template>
            </vue-cal>
          </div>
        </div>
      </div>

      <template v-if="useStartTime && useDate">
        <div class="separator middle">{{ this.separator }}</div>
      </template>
      <div v-if="useStartTime">
        <input
          class="short date-time-input"
          v-imask="masks.hoursMask"
          :disabled="disabled"
          :data-length="2"
          :data-previous-ref="index + refKeys.DATE_YEAR_INPUT"
          :data-next-ref="index + refKeys.START_DATE_MINUTE_INPUT"
          :data-origin-ref="index + refKeys.START_ORIGIN"
          :ref="index + refKeys.START_DATE_HOUR_INPUT"
          @accept="
            acceptStartTimeValue(
              $event,
              'h',
              index + refKeys.START_DATE_MINUTE_INPUT
            )
          "
          @complete="focusInput($event)"
          @focusin.prevent.stop="setActiveEdit('start')"
          @keydown="checkForKeyPress"
        />
        <div class="separator">:</div>
        <input
          v-imask="masks.minutesMask"
          :class="{
            'date-time-input': true,
            short: true,
            expanded:
              startTimeValues.m === '' || startTimeValues.m.indexOf('m') > -1,
          }"
          :disabled="disabled"
          :data-previous-ref="index + refKeys.START_DATE_HOUR_INPUT"
          :data-next-ref="
            index +
            (showAmPm
              ? refKeys.START_DATE_AM_PM_INPUT
              : refKeys.END_DATE_HOUR_INPUT)
          "
          :data-origin-ref="index + refKeys.START_ORIGIN"
          :data-length="2"
          :ref="index + refKeys.START_DATE_MINUTE_INPUT"
          @accept="
            acceptStartTimeValue(
              $event,
              'm',
              index +
                (showAmPm
                  ? refKeys.START_DATE_AM_PM_INPUT
                  : refKeys.END_DATE_HOUR_INPUT)
            )
          "
          @complete="showAmPm ? focusInput($event) : startComplete"
          @focusin.prevent.stop="setActiveEdit('start')"
          @keydown="checkForKeyPress"
        />
        <template v-if="showAmPm">
          <input
            :class="{
              'date-time-input': true,
              short: true,
              expanded:
                startTimeValues.m === '' || startTimeValues.m.indexOf('m') > -1,
            }"
            v-imask="masks.timeFormatMask"
            :data-previous-ref="index + refKeys.START_DATE_MINUTE_INPUT"
            :data-length="2"
            :data-origin-ref="index + refKeys.START_ORIGIN"
            :ref="index + refKeys.START_DATE_AM_PM_INPUT"
            @accept="
              acceptStartTimeValue(
                $event,
                'timeFormat',
                index + refKeys.END_DATE_HOUR_INPUT
              )
            "
            @complete="startComplete"
            @focusin.prevent.stop="setActiveEdit('start')"
            @keydown="checkForKeyPress"
            :disabled="disabled"
          />
        </template>
        <div
          class="datetime-inner-dropdown"
          v-closable="{
            exclude: [index + refKeys.CALENDAR_EVENT_WRAPPER],
            handler: 'closeTimePicker',
          }"
          v-show="
            activeEdit === 'start' &&
            enableTimePicker &&
            !activeCalendarEdit &&
            startTimeFilteredOptions.length
          "
        >
          <div
            v-for="(time, ind) in startTimeFilteredOptions"
            :class="{
              'datetime-inner-dropdown-option': true,
              active: datesEqual(time, startDate),
            }"
            :key="index + '_start_time_' + ind"
            @click.prevent.stop="setStartDate(time)"
          >
            {{
              timeFormat24
                ? customFormat(time, "HH:mm")
                : customFormat(time, "h:mm a")
            }}
          </div>
        </div>
      </div>
      <template v-if="useEndTime && useStartTime">
        <div class="separator middle">-</div>
      </template>
      <div v-if="useEndTime">
        <input
          class="short date-time-input"
          v-imask="masks.hoursMask"
          :data-previous-ref="
            index +
            (showAmPm
              ? refKeys.START_DATE_AM_PM_INPUT
              : refKeys.START_DATE_MINUTE_INPUT)
          "
          :data-length="2"
          :data-next-ref="index + refKeys.END_DATE_MINUTE_INPUT"
          :data-origin-ref="index + refKeys.END_ORIGIN"
          :ref="index + refKeys.END_DATE_HOUR_INPUT"
          @accept="
            acceptEndTimeValue(
              $event,
              'h',
              index + refKeys.END_DATE_MINUTE_INPUT
            )
          "
          @focusin.prevent.stop="setActiveEdit('end')"
          @keydown="checkForKeyPress"
          :disabled="disabled"
        />
        <div class="separator">:</div>
        <input
          v-imask="masks.minutesMask"
          :class="{
            'date-time-input': true,
            short: true,
            expanded:
              endTimeValues.m === '' || endTimeValues.m.indexOf('m') > -1,
          }"
          :data-previous-ref="index + refKeys.END_DATE_HOUR_INPUT"
          :data-next-ref="
            index + (showAmPm ? refKeys.END_DATE_AM_PM_INPUT : '')
          "
          :data-length="2"
          :data-origin-ref="index + refKeys.END_ORIGIN"
          :ref="index + refKeys.END_DATE_MINUTE_INPUT"
          @accept="
            acceptEndTimeValue(
              $event,
              'm',
              index + (showAmPm ? refKeys.END_DATE_AM_PM_INPUT : '')
            )
          "
          @complete="endComplete"
          @focusin.prevent.stop="setActiveEdit('end')"
          @keydown="checkForKeyPress"
          :disabled="disabled"
        />
        <template v-if="showAmPm">
          <input
            :class="{
              'date-time-input': true,
              short: true,
              expanded:
                endTimeValues.m === '' || endTimeValues.m.indexOf('m') > -1,
            }"
            v-imask="masks.timeFormatMask"
            :data-previous-ref="index + refKeys.END_DATE_MINUTE_INPUT"
            :data-length="2"
            :data-origin-ref="index + refKeys.END_ORIGIN"
            :ref="index + refKeys.END_DATE_AM_PM_INPUT"
            @accept="acceptEndTimeValue($event, 'timeFormat')"
            @complete="endComplete"
            @focusin.prevent.stop="setActiveEdit('end')"
            @keydown="checkForKeyPress"
            :disabled="disabled"
          />
        </template>
        <div
          class="datetime-inner-dropdown"
          v-closable="{
            exclude: [index + refKeys.CALENDAR_EVENT_WRAPPER],
            handler: 'closeTimePicker',
          }"
          v-show="
            activeEdit === 'end' &&
            enableTimePicker &&
            !activeCalendarEdit &&
            endTimeFilteredOptions.length
          "
        >
          <div
            v-for="(time, ind) in endTimeFilteredOptions"
            :class="{
              'datetime-inner-dropdown-option': true,
              active: datesEqual(time, endDate),
            }"
            :key="index + '_end_time_' + ind"
            @click.prevent.stop="setEndDate(time)"
          >
            {{ customFormat(time, timeFormat24 ? "HH:mm" : "h:mm a") }}
            ({{ formatDuration(time) }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import IMask from "imask";
import { IMaskDirective } from "vue-imask";
import clinicConfig from "@/config/clinic";
import dateTimeInputMixin, { REF_KEYS } from "@/mixins/dateTimeInputMixin";

export default {
  name: "DateTimeInput",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    error: {
      default: false,
      type: Boolean,
    },
    originalDate: {
      type: Date,
      default: null,
    },
    originalStartDate: {
      type: Date,
      default: null,
    },
    originalEndDate: {
      type: Date,
      default: null,
    },
    dateFirstMode: {
      type: Boolean,
      default: false,
    },
    timeFormat24: {
      type: Boolean,
      default: false,
    },
    useDate: {
      type: Boolean,
      default: false,
    },
    useStartTime: {
      default: false,
      type: Boolean,
    },
    useEndTime: {
      default: false,
      type: Boolean,
    },
    separator: {
      default: "|",
      type: String,
    },
    eventLength: {
      type: Number,
      default: null,
    },
    enableTimePicker: {
      default: false,
      type: Boolean,
    },
    enableDateCalendar: {
      default: false,
      type: Boolean,
    },
    minDate: {
      type: Date,
      default: null,
    },
    fromNow: {
      type: Boolean,
      default: false,
    },
    toNow: {
      type: Boolean,
      default: false,
    },
    preserveDuration: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { VueCal },
  mixins: [dateTimeInputMixin],
  emits: ["value-start-date", "value-end-date"],
  data() {
    return {
      refKeys: REF_KEYS,
      currentDateValues: {
        year: "",
        day: "",
        month: "",
      },
      startTimeValues: {
        h: "",
        m: "",
        timeFormat: "",
      },
      endTimeValues: {
        h: "",
        m: "",
        timeFormat: "",
      },
      emitChanges: false,
      currentDate: null,
      startDate: null,
      endDate: null,
      dateDifference: null,
      activeCalendarEdit: false,
      startTimeOptions: [],
      endTimeOptions: [],
      activeEdit: null,
      timeFormatValue: null,
      masks: {
        dayMask: {
          mask: "d",
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              placeholderChar: "d",
            },
          },
          lazy: false,
          overwrite: false,
        },
        monthMask: {
          mask: "m",
          blocks: {
            m: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              placeholderChar: "m",
            },
          },
          lazy: false,
          overwrite: false,
        },
        yearMask: {
          mask: "y",
          blocks: {
            y: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 2100,
              placeholderChar: "y",
            },
          },
          lazy: false,
          overwrite: false,
        },
        hoursMask: {
          mask: "h",
          blocks: {
            h: {
              mask: IMask.MaskedRange,
              from: this.timeFormat24 ? 0 : 1,
              to: this.timeFormat24 ? 23 : 12,
              placeholderChar: "h",
            },
          },
          lazy: false,
          overwrite: false,
        },
        minutesMask: {
          mask: "m",
          blocks: {
            m: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 59,
              placeholderChar: "m",
            },
          },
          lazy: false,
          overwrite: false,
        },
        timeFormatMask: {
          mask: "A",
          blocks: {
            A: {
              mask: IMask.MaskedEnum,
              enum: ["AM", "am", "PM", "pm", "aM", "Am", "pM", "Pm"],
            },
          },
          lazy: false,
          overwrite: false,
        },
      },
    };
  },
  directives: {
    imask: IMaskDirective,
  },
  computed: {
    showAmPm: function () {
      return !this.timeFormat24;
    },
    timeError: function () {
      if (this.useStartTime && this.startDate && this.toNow) {
        return this.endDate && this.useEndTime
          ? this.isAfter(this.startDate, this.endDate) ||
              this.isAfterNow(this.startDate)
          : this.isAfterNow(this.startDate);
      }

      if (this.useStartTime && this.startDate && this.fromNow) {
        return this.endDate && this.useEndTime
          ? this.isAfter(this.startDate, this.endDate) ||
              !this.isAfterNow(this.startDate)
          : !this.isAfterNow(this.startDate);
      }

      if (
        !this.useStartTime &&
        this.startDate &&
        this.endDate &&
        this.useEndTime
      ) {
        return this.isAfter(this.startDate, this.endDate);
      }

      if (this.useDate && this.currentDate && this.toNow) {
        return this.isAfterNow(this.currentDate);
      }

      if (this.useDate && this.currentDate && this.fromNow) {
        return (
          !this.isAfterNow(this.currentDate) && !this.isToday(this.currentDate)
        );
      }

      return false;
    },
    startTimeFilteredOptions: function () {
      let self = this;
      let hourNum = self.startTimeValues.h
        ? self.startTimeValues.h.match(/\d+/g)
          ? self.startTimeValues.h.match(/\d+/g).join("")
          : null
        : null;
      let minuteNum = self.startTimeValues.m
        ? self.startTimeValues.m.match(/\d+/g)
          ? self.startTimeValues.m.match(/\d+/g).join("")
          : null
        : null;
      if (
        hourNum &&
        hourNum.length === 2 &&
        minuteNum &&
        minuteNum.length === 2
      ) {
        return self.startTimeOptions;
      }

      let filteredOptions = this.startTimeOptions.filter(function (item) {
        let hourDate = self.customFormat(item, self.timeFormat24 ? "HH" : "hh");
        let minuteDate = self.customFormat(item, "mm");
        return minuteNum
          ? hourDate.indexOf(hourNum) === 0 &&
              minuteDate.indexOf(minuteNum) === 0
          : hourDate.indexOf(hourNum) === 0;
      });

      return filteredOptions.length ? filteredOptions : this.startTimeOptions;
    },
    endTimeFilteredOptions: function () {
      let self = this;
      let hourNum = self.endTimeValues.h
        ? self.endTimeValues.h.match(/\d+/g)
          ? self.endTimeValues.h.match(/\d+/g).join("")
          : null
        : null;
      let minuteNum = self.endTimeValues.m
        ? self.endTimeValues.m.match(/\d+/g)
          ? self.endTimeValues.m.match(/\d+/g).join("")
          : null
        : null;

      if (
        hourNum &&
        hourNum.length === 2 &&
        minuteNum &&
        minuteNum.length === 2
      ) {
        return self.endTimeOptions;
      }
      let filteredOptions = this.endTimeOptions.filter(function (item) {
        let hourDate = self.customFormat(item, self.timeFormat24 ? "HH" : "hh");
        let minuteDate = self.customFormat(item, "mm");
        return minuteNum
          ? hourDate.indexOf(hourNum) === 0 &&
              minuteDate.indexOf(minuteNum) === 0
          : hourDate.indexOf(hourNum) === 0;
      });

      return filteredOptions.length ? filteredOptions : this.endTimeOptions;
    },
    workingEndMinutes: function () {
      let workingEndMinutesNormalised =
        this.$store.getters.workingEndMinutes +
        clinicConfig.EVENT_CREATION_OFFSET_ALLOW;

      if (workingEndMinutesNormalised >= 24 * 60) {
        // can't be 1400 because 00:00 hours
        workingEndMinutesNormalised = 24 * 60 - 1;
      }

      return workingEndMinutesNormalised;
    },
  },
  mounted() {
    this.initPropDates();
  },
  watch: {
    originalStartDate: function (newVal) {
      if (newVal !== null && !this.datesEqual(newVal, this.startDate)) {
        let currentDateHelper = new Date(newVal.valueOf());
        currentDateHelper.setHours(0, 0, 0);
        this.currentDate = currentDateHelper;
        this.currentDateValues = {
          day: this.customFormat(this.currentDate, "dd"),
          month: this.customFormat(this.currentDate, "MM"),
          year: this.customFormat(this.currentDate, "yyyy"),
        };

        if (this.useDate) {
          this.updateMaskValue(
            this.index + this.refKeys.DATE_DAY_INPUT,
            this.customFormat(this.currentDate, "dd")
          );
          this.updateMaskValue(
            this.index + this.refKeys.DATE_MONTH_INPUT,
            this.customFormat(this.currentDate, "MM")
          );
          this.updateMaskValue(
            this.index + this.refKeys.DATE_YEAR_INPUT,
            this.customFormat(this.currentDate, "yyyy")
          );
        }

        this.setStartDate(newVal, true);
      }
      if (this.enableTimePicker && this.useStartTime) {
        this.initStartTimeOptions();
      }
    },
    originalEndDate: function (newVal) {
      if (newVal !== null && !this.datesEqual(newVal, this.endDate)) {
        this.setEndDate(newVal, true);
      }
    },
    startDate: function (date) {
      if (!this.dateIsValid(date) && this.emitChanges) {
        return this.$emit("value-start-date", null);
      }

      if (
        (this.toNow && this.isAfterNow(date)) ||
        (this.fromNow && !this.isAfterNow(date))
      ) {
        return;
      }

      if (this.emitChanges) {
        this.$emit("value-start-date", date);
      }
    },
    endDate: function (date) {
      if (!this.dateIsValid(date) && this.emitChanges) {
        return this.$emit("value-end-date", null);
      }

      if (this.isBefore(date, this.startDate)) {
        return;
      }

      if (this.emitChanges) {
        this.$emit("value-end-date", date);
      }
    },
  },
  methods: {
    initPropDates() {
      if (this.originalStartDate) {
        this.setDate(this.originalStartDate, true);
        this.setStartDate(this.originalStartDate, true);
      }

      if (this.originalEndDate) {
        this.setEndDate(this.originalEndDate, true);
      }
    },
    reInitStartEndDates() {
      let start = this.useStartTime
        ? this.parseDateTime(this.startTimeValues)
        : this.currentDate;

      if (
        this.dateIsValid(start) &&
        (this.useStartTime || (!this.useStartTime && this.useDate))
      ) {
        this.setStartDate(start);
      }

      let end = this.parseDateTime(this.endTimeValues);

      if (this.dateIsValid(end) && this.useEndTime) {
        this.setEndDate(end);
      }
    },
    setActiveEdit(type) {
      if (this.dateFirstMode && this.useStartTime && !this.currentDate) {
        this.activeCalendarEdit = true;
      } else {
        this.activeCalendarEdit = false;
        this.activeEdit = type;
      }
    },
    focusInput(ref = null) {
      if (
        !ref &&
        typeof this.$refs[this.index + this.refKeys.DATE_MONTH_INPUT] !==
          "undefined"
      ) {
        this.$refs[this.index + this.refKeys.DATE_MONTH_INPUT].focus();
      }

      if (typeof this.$refs[ref] !== "undefined") {
        this.$refs[ref].focus();
      }
    },
    acceptDateValue(e, key, nextRef = null) {
      const maskRef = e.detail;
      this.currentDateValues[key] = maskRef.value;
      this.checkCurrentDateValues();
      if (
        maskRef.masked.isComplete &&
        nextRef &&
        this.$refs[nextRef] &&
        !this.$refs[nextRef].maskRef.masked.isComplete
      ) {
        this.focusInput(nextRef);
        return;
      }

      if (
        maskRef.masked.typedValue === "mm" &&
        maskRef.masked.unmaskedValue === ""
      ) {
        this.startDate = null;
        this.closePicker();
      }

      if (maskRef.masked.isComplete) {
        e.target.blur();
      }
    },
    acceptStartTimeValue(e, key, nextRef = null) {
      const maskRef = e.detail;
      this.startTimeValues[key] = maskRef.value;
      this.checkStartTimeValues();

      if (key === "timeFormat") {
        e.detail.value = e.detail.value.toUpperCase();
      }

      if (maskRef.masked.isComplete && nextRef && !this.preserveDuration) {
        this.focusInput(nextRef);
      }
    },
    acceptEndTimeValue(e, key, nextRef = null) {
      const maskRef = e.detail;
      this.endTimeValues[key] = maskRef.value;
      this.checkEndTimeValues();

      if (key === "timeFormat") {
        e.detail.value = e.detail.value.toUpperCase();
      }

      if (maskRef.masked.isComplete && nextRef) {
        this.focusInput(nextRef);
      }
    },
    dateComplete() {
      if (this.useStartTime && this.enableTimePicker) {
        this.initStartTimeOptions();
      }
      this.closePicker();
    },
    startComplete() {
      if (this.useEndTime && this.enableTimePicker) {
        this.initEndTimeOptions();
      }
    },
    endComplete() {
      this.closeTimePicker();
    },
    previous() {
      this.currentView = "month";
      this.$refs[this.index + this.refKeys.CALENDAR_EVENT].previous();
    },
    next() {
      this.currentView = "month";
      this.$refs[this.index + this.refKeys.CALENDAR_EVENT].next();
    },
    closePicker() {
      this.activeCalendarEdit = false;
    },
    closeTimePicker() {
      this.activeEdit = null;
    },
    parseDate(date) {
      if (!date) {
        return null;
      }
      return this.parseDateFormat(
        date.month + "/" + date.day + "/" + date.year,
        "MM/dd/yyyy"
      );
    },
    parseDateTime(time) {
      if (!time) {
        return null;
      }
      let dateTimeString =
        this.currentDateValues.month +
        "/" +
        this.currentDateValues.day +
        "/" +
        this.currentDateValues.year +
        " " +
        time.h +
        ":" +
        time.m;

      if (!this.timeFormat24) {
        dateTimeString += " " + time.timeFormat;
      }

      return this.parseDateFormat(
        dateTimeString,
        this.timeFormat24 ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy hh:mm a"
      );
    },
    checkCurrentDateValues() {
      let date = this.parseDate(this.currentDateValues);

      if (this.dateIsValid(date)) {
        this.currentDate = date;
        this.reInitStartEndDates();
        if (this.enableTimePicker && this.useStartTime) {
          this.initStartTimeOptions();
        }
      }
    },
    checkStartTimeValues() {
      if (!this.useDate && this.currentDateValues.year === "") {
        let now = this.newTimeZoneDate();
        this.currentDateValues.day = this.customFormat(now, "dd");
        this.currentDateValues.month = this.customFormat(now, "MM");
        this.currentDateValues.year = this.customFormat(now, "yyyy");
      }

      let date = this.parseDateTime(this.startTimeValues);
      if (this.dateIsValid(date)) {
        this.emitChanges = true;
        this.startDate = date;
        this.activeEdit = null;

        if (this.enableTimePicker && this.useEndTime) {
          if (this.preserveDuration && this.endDate) {
            let diff = this.diffInMinutes(this.endDate, this.startDate);
            if (
              this.dateDifference &&
              this.dateDifference > 0 &&
              diff !== this.dateDifference
            ) {
              this.setEndDate(this.startDate.addMinutes(this.dateDifference));
            }
          }
        }
      }
    },
    checkEndTimeValues() {
      if (!this.useDate && this.currentDateValues.year === "") {
        let now = this.newTimeZoneDate();
        this.currentDateValues.day = this.customFormat(now, "dd");
        this.currentDateValues.month = this.customFormat(now, "MM");
        this.currentDateValues.year = this.customFormat(now, "yyyy");
      }

      let date = this.parseDateTime(this.endTimeValues);

      if (this.dateIsValid(date)) {
        this.emitChanges = true;
        this.endDate = date;
        this.activeEdit = null;

        if (this.startDate) {
          this.dateDifference = this.diffInMinutes(
            this.endDate,
            this.startDate
          );
        }
      }
    },
    setDate(date, init = false) {
      let dateHelper = new Date(date.valueOf());
      dateHelper.setHours(0, 0, 0);
      this.currentDate = dateHelper;
      this.emitChanges = !init;
      if (this.useDate) {
        this.updateMaskValue(
          this.index + this.refKeys.DATE_DAY_INPUT,
          this.customFormat(this.currentDate, "dd")
        );
        this.updateMaskValue(
          this.index + this.refKeys.DATE_MONTH_INPUT,
          this.customFormat(this.currentDate, "MM")
        );
        this.updateMaskValue(
          this.index + this.refKeys.DATE_YEAR_INPUT,
          this.customFormat(this.currentDate, "yyyy")
        );
      }

      this.currentDateValues = {
        day: this.customFormat(this.currentDate, "dd"),
        month: this.customFormat(this.currentDate, "MM"),
        year: this.customFormat(this.currentDate, "yyyy"),
      };

      this.checkCurrentDateValues();

      if (!init) {
        this.activeCalendarEdit = false;
      }

      if (!init && this.dateFirstMode && !this.startDate) {
        this.$refs[this.index + this.refKeys.START_DATE_HOUR_INPUT].focus();
      }

      this.dateComplete();
    },
    setStartDate(date, init = false) {
      this.emitChanges = !init;
      this.startDate = date;

      if (this.useStartTime) {
        this.updateMaskValue(
          this.index + this.refKeys.START_DATE_HOUR_INPUT,
          this.customFormat(this.startDate, this.timeFormat24 ? "HH" : "hh")
        );
        this.updateMaskValue(
          this.index + this.refKeys.START_DATE_MINUTE_INPUT,
          this.customFormat(this.startDate, "mm")
        );

        if (!this.timeFormat24) {
          this.updateMaskValue(
            this.index + this.refKeys.START_DATE_AM_PM_INPUT,
            this.customFormat(this.startDate, "a")
          );
        }
      }

      this.startTimeValues = {
        h: this.customFormat(this.startDate, this.timeFormat24 ? "HH" : "hh"),
        m: this.customFormat(this.startDate, "mm"),
        timeFormat: !this.timeFormat24
          ? this.customFormat(this.startDate, "a")
          : null,
      };

      if (!init) {
        this.activeEdit = null;
      }

      this.startComplete();
    },
    setEndDate(date, init = false) {
      this.emitChanges = !init;
      this.endDate = date;
      if (this.useEndTime) {
        this.updateMaskValue(
          this.index + this.refKeys.END_DATE_HOUR_INPUT,
          this.customFormat(this.endDate, this.timeFormat24 ? "HH" : "hh")
        );
        this.updateMaskValue(
          this.index + this.refKeys.END_DATE_MINUTE_INPUT,
          this.customFormat(this.endDate, "mm")
        );

        if (!this.timeFormat24) {
          this.updateMaskValue(
            this.index + this.refKeys.END_DATE_AM_PM_INPUT,
            this.customFormat(this.endDate, "a")
          );
        }
      }

      this.endTimeValues = {
        h: this.customFormat(this.endDate, this.timeFormat24 ? "HH" : "hh"),
        m: this.customFormat(this.endDate, "mm"),
        timeFormat: !this.timeFormat24
          ? this.customFormat(this.endDate, "a")
          : null,
      };

      if (!init) {
        this.activeEdit = null;
      }
    },
    initStartTimeOptions(date = null) {
      this.startTimeOptions = [];
      let currentDay =
        date !== null
          ? date
          : this.currentDate
          ? this.currentDate
          : this.newTimeZoneDate();

      // should be set from config
      // clinic should have start working hours default 08:00
      if (currentDay) {
        if (this.isToday(currentDay)) {
          let now = this.newTimeZoneDate();
          let minutes = now.getMinutes();
          let nextQuarter = (Math.floor(minutes / 15) + 1) * 15;
          currentDay.setHours(
            nextQuarter >= 60 ? now.getHours() + 1 : now.getHours(),
            nextQuarter >= 60 ? 0 : nextQuarter,
            0
          );
        } else {
          currentDay.setHours(
            (this.$store.getters.workingStartMinutes -
              clinicConfig.EVENT_CREATION_OFFSET_ALLOW) /
              60,
            (this.$store.getters.workingStartMinutes -
              clinicConfig.EVENT_CREATION_OFFSET_ALLOW) %
              60,
            0
          );
        }
      }

      // also clinic should have end working hours default 23:00
      while (
        currentDay.getHours() * 60 + currentDay.getMinutes() <=
        this.workingEndMinutes
      ) {
        this.startTimeOptions.push(currentDay);
        let day = currentDay.getDay();
        currentDay = currentDay.addMinutes(
          this.eventLength ? this.eventLength : 15
        );
        let nextDay = currentDay.getDay();

        if (nextDay > day) {
          break;
        }
      }
    },
    initEndTimeOptions() {
      this.endTimeOptions = [];
      let currentDay = this.startDate ? this.startDate : this.newTimeZoneDate();
      if (this.startDate === null) {
        return;
      }

      currentDay = this.roundToNearestMinutes(
        currentDay,
        this.eventLength ? this.eventLength : 15
      );

      currentDay = currentDay.addMinutes(
        this.eventLength ? this.eventLength : 15
      );

      while (
        currentDay.getHours() * 60 + currentDay.getMinutes() <=
        this.workingEndMinutes
      ) {
        if (
          currentDay.getHours() * 60 + currentDay.getMinutes() ===
          this.workingEndMinutes
        ) {
          this.endTimeOptions.push(currentDay);
          break;
        }

        this.endTimeOptions.push(currentDay);
        let day = currentDay.getDay();
        currentDay = currentDay.addMinutes(
          this.eventLength ? this.eventLength : 15
        );
        let nextDay = currentDay.getDay();

        if (nextDay > day) {
          break;
        }
      }
    },
    formatDuration(date) {
      let durationString = "";
      let diffInHours = this.diffInHours(date, this.startDate);
      let diffInMinutes = this.diffInMinutes(date, this.startDate) % 60;

      if (diffInHours > 0) {
        durationString += diffInHours + " h";
      }

      if (diffInMinutes > 0) {
        durationString +=
          (durationString.length ? " " : "") + diffInMinutes + " min";
      }

      return durationString;
    },
  },
};
</script>
