import { HTTP_PUBLIC } from "@/services/api";
import dateMixin from "@/mixins/dateMixin";

export default {
  data() {
    return {
      forms: [
        {
          key: "informed",
          routeName: "patientConsentInformed",
        },
        {
          key: "financial_policy",
          routeName: "patientConsentFinancial",
        },
        {
          key: "privacy_practices",
          routeName: "patientConsentPrivacy",
        },
        {
          key: "hipaa",
          routeName: "patientConsentHipaa",
        },
      ],
    };
  },
  methods: {
    checkNextStep(token) {
      HTTP_PUBLIC({
        method: "GET",
        url: "/api/v1/patient-records/" + token + "/consents",
      })
        .then((response) => {
          if (response.data) {
            this.navigateToForm(response.data.data, token);
          }
        })
        .catch(() => {});
    },
    navigateToForm(formKey, token) {
      let index = this.forms.findIndex((item) => {
        return item.key === formKey;
      });

      if (index !== -1) {
        if (this.$route.name === this.forms[index].routeName) {
          return;
        }

        this.$router.push({
          name: this.forms[index].routeName,
          params: { token: token },
        });
        return;
      }

      this.$router.push({
        name: "patientConsentThankYou",
      });
    },
    prepareConsentForm(formValues) {
      let formData = {};
      Object.entries(formValues).forEach(function ([key, value]) {
        if (value instanceof Date) {
          formData[key] = dateMixin.methods
            .getUTCNoonFromDate(value)
            .toISOString();
        } else if (value instanceof Object) {
          if (value.value) {
            formData[key] = value.text ? value.text : value;
          }
        } else {
          formData[key] = value;
        }
      });
      return JSON.stringify(formData);
    },
  },
};
