<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.reports.newPatientsListByDoctor") }}
      </div>
      <div class="list-top-action">
        <div class="button action" @click.prevent="exportFile('csv')">
          {{ $t("button.exportCsv") }}
        </div>
        <div class="button action" @click.prevent="exportFile('pdf')">
          {{ $t("button.exportPdf") }}
        </div>
      </div>
    </div>

    <div class="list-filter">
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="0"
          :label="$t('label.start') + ':'"
          :original-start-date="start"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setStart"
        ></date-time-input>
      </div>
      <div class="form-item">
        <date-time-input
          ref="inputStart"
          enable-date-calendar
          input-mode
          show-label
          use-date
          :index="1"
          :label="$t('label.end') + ':'"
          :min-date="start"
          :original-start-date="end"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setEnd"
        ></date-time-input>
      </div>
      <div class="form-item action">
        <a class="button action" @click.prevent="loadData">
          {{ $t("button.filter") }}
        </a>
      </div>
    </div>

    <!--          reports section-->
    <div class="list-wrapper">
      <div
        class="list-items"
        v-for="(report, index) in data"
        :key="'report_' + index"
      >
        <div class="list-items-title">{{ index }}</div>
        <div class="list-items-header">
          <header-sort
            sortKey="reports"
            :sortArray="[
              {
                label: $tc('label.patient', 1),
              },
              {
                label: $t('label.firstAppointment'),
              },
              {
                label: $t('label.lastAppointment'),
              },
              {
                label: $t('label.nextAppointment'),
              },
            ]"
          ></header-sort>
        </div>
        <div class="list-items-section">
          <!-- reports-->
          <div class="list-items-section-wrapper">
            <div v-if="report.length === 0" class="list-items-section-empty">
              {{ $t("label.noResults") }}
            </div>
            <div
              class="list-items-section-item"
              v-for="(item, reportIndex) in report.patients"
              :key="'item_' + index.id + '_' + reportIndex"
            >
              <div class="basic">
                {{ item.patient }}
              </div>
              <div class="basic">
                {{ item.first_appt }}
              </div>
              <div class="basic">
                {{ item.last_appt }}
              </div>
              <div class="basic">
                {{ item.next_appt }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.length === 0" class="list-items-section-empty">
        {{ $t("label.noResults") }}
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.reports.pagination.page"
        :perPage="$store.state.reports.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import fileMixin from "@/mixins/fileMixin";

export default {
  name: "NewPatientsListByProviderView",
  mixins: [fileMixin],
  components: {
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
    DateTimeInput,
  },
  data: function () {
    return {
      data: [],
      pages: [],
      start: null,
      end: null,
      allResults: 0,
      cancelToken: { load: null },
    };
  },
  unmounted() {
    this.$store.dispatch("resetReportsQuery");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.reports": {
      deep: true,
      handler: function (val, oldVal) {
        if (val !== oldVal && !val.reset) {
          this.loadData();
        }
      },
    },
  },
  methods: {
    loadData() {
      if (!this.start || !this.end) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.fillAllFields"),
        });
        return;
      }
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/reports/new-patients-by-doctor/",
        data: {},
        params: {
          start: this.getUTCNoonFromDate(this.start).toISOString(),
          end: this.getUTCNoonFromDate(this.end).toISOString(),
          q: this.$store.state.reports.search,
          sort_by: this.$store.state.reports.sortOrder,
          page: this.$store.state.reports.pagination.page,
          per_page: this.$store.state.reports.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.data = response.data.data;
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    exportFile(type) {
      if (!this.start || !this.end) {
        this.$notify({
          group: "notification",
          type: "warning",
          title: this.$t("notification.warning"),
          text: this.$t("notification.fillAllFields"),
        });
        return;
      }
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/reports/new-patients-by-doctor/export/" + type,
        data: {},
        params: {
          start: this.getUTCNoonFromDate(this.start).toISOString(),
          end: this.getUTCNoonFromDate(this.end).toISOString(),
          q: this.$store.state.reports.search,
          sort_by: this.$store.state.reports.sortOrder,
        },
        responseType: "blob",
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          this.downloadFile(response);
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", { type: "reports", data: value });
    },
    setStart(value) {
      this.start = value;
    },
    setEnd(value) {
      this.end = value;
    },
  },
};
</script>
