<template>
  <div class="header-sort">
    <div
      class="header-sort-item"
      @click="sort(sortItem)"
      v-for="(sortItem, index) in mutableSortArray"
      :key="sortKey + '_' + (sortItem.key ? sortItem.key : index)"
      :style="
        sortItem.width
          ? 'flex-basis: ' + sortItem.width + '; min-width: ' + sortItem.width
          : 'flex: 1;'
      "
    >
      <div class="header-sort-item-title">{{ sortItem.label }}</div>
      <div
        v-if="sortItem.sortable && sortItem.key && sortItem.direction"
        :class="{
          arrow: true,
          up: sortItem.direction === 'desc',
          down: sortItem.direction === 'asc',
        }"
      ></div>
      <div
        v-else-if="sortItem.sortable && sortItem.key"
        class="arrow down disabled"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSort",
  props: {
    sortKey: {
      type: String,
      default: "",
    },
    sortArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      mutableSortArray: [],
      sortValues: [],
    };
  },
  created() {
    let scope = this;
    this.mutableSortArray = this.sortArray;
    this.mutableSortArray.forEach(function (item) {
      if (item.key && item.direction && item.direction !== "") {
        scope.sortValues.push(item.key + "|" + item.direction);
      }
    });
    if (this.sortValues.length) {
      this.$store.dispatch("setHeaderSortOrder", {
        key: this.sortKey,
        value: this.sortValues,
      });
    }
  },
  methods: {
    sort(sortItem) {
      if (!sortItem.sortable) {
        return;
      }

      if (sortItem.direction) {
        let oldEntry = sortItem.key + "|" + sortItem.direction;
        let oldEntryIndex = this.sortValues.indexOf(oldEntry);
        if (oldEntryIndex !== -1) {
          this.sortValues.splice(oldEntryIndex, 1);
        }
      }

      sortItem["direction"] =
        typeof sortItem.direction === "undefined" || sortItem.direction === ""
          ? "asc"
          : sortItem.direction === "asc"
          ? "desc"
          : null;

      if (sortItem.direction === null) {
        delete sortItem.direction;
      }

      if (sortItem.direction) {
        let newEntry = sortItem.key + "|" + sortItem.direction;
        this.sortValues.unshift(newEntry);
      }

      this.$store.dispatch("setHeaderSortOrder", {
        key: this.sortKey,
        value: this.sortValues,
      });
    },
  },
};
</script>

<style scoped></style>
