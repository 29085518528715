export default {
  methods: {
    autoResize(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
    fixTextareaHeight() {
      this.$nextTick(() => {
        let elements = document.getElementsByClassName("auto-resize");
        elements.forEach(function (item) {
          item.setAttribute("style", "height: auto");
          item.setAttribute("style", "height: " + item.scrollHeight + "px");
        });
      });
    },
  },
  mounted() {},
};
