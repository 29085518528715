<template>
  <div>
    <form @submit.prevent="">
      <div
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.first_name'),
        }"
      >
        <label class="item">{{ $t("label.firstName") }}: </label>
        <input ref="firstName" v-model="newPatient.first_name" type="text" />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.last_name'),
        }"
      >
        <label class="item">{{ $t("label.lastName") }}: </label>
        <input v-model="newPatient.last_name" type="text" />
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.birthday'),
        }"
      >
        <date-time-input
          ref="inputDate"
          enable-date-calendar
          input-mode
          show-label
          to-now
          use-date
          :index="0"
          :label="$t('label.dateOfBirth') + ':'"
          :original-start-date="newPatient.birthday"
          :time-format24="this.$store.getters.userHoursFormat24"
          @value-start-date="setStart"
        ></date-time-input>
      </div>
      <form-input-checkbox-toggle
        :label="$t('label.requiresGuardian')"
        :model-value="minorPatient"
        @update:modelValue="(value) => (this.minorPatient = value)"
      />
      <div
        v-if="!minorPatient"
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.email'),
        }"
      >
        <label class="item">{{ $t("label.email") }}: </label>
        <input v-model="newPatient.email" type="text" />
      </div>
      <div
        v-if="minorPatient"
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.guardian'),
        }"
      >
        <label class="item">{{ $t("label.guardian") }}: </label>
        <v-select
          placeholder="Select Guardian"
          :getOptionLabel="nameOptionLabel"
          :options="guardians"
          :filterable="false"
          @search="searchGuardians"
          v-model="newPatient.guardian"
        >
          <template #no-options="{ search }">
            <vue-select-searching
              :search="search"
              :loading="guardian.loading"
              :results-count="guardians.length"
            />
          </template>
          <template #list-footer>
            <load-more
              :message="$t('message.loading') + '...'"
              :show-load-more="guardian.hasMore"
              :custom-class="'vs__dropdown-option'"
              @load-more="loadMore"
            />
          </template>
        </v-select>
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.gender'),
        }"
      >
        <label class="item">{{ $t("label.gender") }}: </label>
        <v-select :options="genderList" v-model="newPatient.gender"></v-select>
      </div>
      <div
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.referral_source'),
        }"
      >
        <label class="item">{{ $t("label.referralSource") }}: </label>
        <input v-model="newPatient.referral_source" type="text" />
      </div>
      <div
        v-if="!minorPatient"
        class="form-item"
        :class="{
          error: validation.hasError('newPatient.mobile_number'),
        }"
      >
        <label class="item">{{ $t("label.mobileNo") }}: </label>
        <input
          :value="newPatient.mobile_number"
          v-imask="masks.phone"
          @accept="newPatient.mobile_number = parsePhoneValue($event)"
          type="text"
        />
      </div>
      <div class="form-item action">
        <a
          class="button action"
          :class="{ disabled: storing }"
          @click.prevent="storeNewPatient()"
        >
          {{ $t("label.addNew") }} {{ $tc("label.patient", 1).toLowerCase() }}
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import { cancelTokens, HTTP, setCancelToken } from "@/services/api";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import SimpleVueValidator from "simple-vue3-validator";
import FormInputCheckboxToggle from "@/views/shared/forms/FormInputCheckboxToggle";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching";
import LoadMore from "@/views/shared/components/LoadMore";
import imaskMixin from "@/mixins/imaskMixin";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientCreate",
  props: ["newPatientName"],
  mixins: [imaskMixin],
  components: {
    LoadMore,
    VueSelectSearching,
    FormInputCheckboxToggle,
    DateTimeInput,
  },
  validators: {
    "newPatient.first_name": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.last_name": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.email, minorPatient": function (value, minor) {
      if (minor) {
        return;
      }

      return Validator.value(value).required().email();
    },
    "newPatient.guardian, minorPatient": function (value, minor) {
      if (!minor) {
        return;
      }

      return Validator.value(value).required();
    },
    "newPatient.birthday": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.gender": function (value) {
      return Validator.value(value).required();
    },
    "newPatient.mobile_number, minorPatient": function (value, minor) {
      if (minor) {
        return;
      }

      return Validator.value(value).length(11).required();
    },
  },
  data() {
    return {
      genderList: ["male", "female", "other"],
      guardians: [],
      storing: false,
      guardian: {
        search: "",
        page: 0,
        hasMore: true,
        loading: false,
      },
      minorPatient: false,
      newPatient: {
        first_name: "",
        last_name: "",
        birthday: null,
        guardian: null,
        email: "",
        gender: "",
        referral_source: "",
        passport: "empty",
        mobile_number: null,
      },
      cancelToken: {
        guardiansLoad: null,
      },
    };
  },
  mounted() {
    this.$refs.firstName.focus();
    this.searchGuardians = this.asDebounce(this.searchGuardians, 1000);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  watch: {
    newPatientName: function (val) {
      this.newPatient.first_name = val;
    },
    minorPatient: function (val) {
      if (val) {
        this.newPatient.email = null;
        return this.loadMore();
      }

      if (!this.patientIsMinor) {
        this.newPatient.guardian = null;
      }

      if (!val) {
        this.checkMinor();
      }
    },
    "newPatient.birthday": function () {
      this.checkMinor();
    },
  },
  computed: {
    patientIsMinor: function () {
      return (
        this.dateIsValid(this.newPatient.birthday) &&
        this.diffInYears(new Date(), this.newPatient.birthday) < 18
      );
    },
  },
  methods: {
    checkMinor() {
      if (this.patientIsMinor) {
        this.$nextTick(() => {
          this.minorPatient = true;
        });
      }
    },
    storeNewPatient() {
      let self = this;
      self.$validate().then(function (success) {
        let data = self.preparePostDataWithDates(self.newPatient, ["birthday"]);
        if (success) {
          self.storing = true;
          HTTP({
            method: "POST",
            url: "/api/v1/patients",
            data: data,
          })
            .then((response) => {
              if ([200, 201].indexOf(response.status) > -1 && response.data) {
                self.$emit("patient-load");
                self.$emit("patient-select", response.data);
              }
            })
            .finally(() => {
              self.storing = false;
            });
        }
      });
    },
    setStart(value) {
      this.newPatient.birthday = value;
    },
    searchGuardians(text = "") {
      this.guardian.search = text;
      this.guardian.page = 1;
      this.guardian.hasMore = true;
      this.guardians = [];
      this.loadGuardians();
    },
    loadMore() {
      if (!this.guardian.loading) {
        this.guardian.page = this.guardian.page + 1;
        this.loadGuardians();
      }
    },
    loadGuardians() {
      this.guardian.loading = true;

      let params = {};
      params.page = this.guardian.page;
      params.per_page = 10;
      params.statuses = ["active"];
      params.only_guardians = 1;

      if (this.guardian.search !== "") {
        params.q = this.guardian.search;
      }

      setCancelToken(this.cancelToken, "guardiansLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/patients",
        data: {},
        cancelToken: this.cancelToken.guardiansLoad.token,
        params: params,
      })
        .then((response) => {
          if (response.data) {
            if (response.data.data.length < 10) {
              this.guardian.hasMore = false;
            }
            this.guardians = this.guardians.concat(response.data.data);
          }
          this.guardian.loading = false;
        })
        .catch(() => (this.guardian.loading = false));
    },
  },
};
</script>

<style scoped></style>
