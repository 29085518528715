<template>
  <div class="payment-tab-single">
    <div
      v-if="paymentStatus !== null"
      class="payment-tab-single-message"
      :class="{
        success: paymentStatus === 'success',
        failed: paymentStatus === 'fail',
      }"
    >
      <template v-if="paymentStatus === 'success'">{{
        $t("label.paymentSuccess")
      }}</template>
      <template v-if="paymentStatus === 'fail'">{{
        $t("label.paymentFailed")
      }}</template>
    </div>
    <div class="button action margin-bottom-20" @click="payHandler">
      {{ $t("button.pay") }}
    </div>
    <div class="payment-tab-single-list">
      <div
        class="expandable-block"
        v-for="item in paymentHistory"
        :key="'history_' + item.id"
      >
        <div class="expandable-block-header" @click="setItemExpanded(item)">
          <div class="title">
            {{ $t("label.amount") + ": " }} {{ formatPrice(item.amount) }}
            {{ $t("label.date") }}:
            {{ customFormat(item.created_at, "MM / dd / yyyy") }}
            {{ $t("label.paymentType") }}:
            {{ item.payment_type }}
          </div>
          <div
            class="toggle-expand"
            :class="{ expanded: expandedId === item.id }"
          ></div>
        </div>

        <div
          class="expandable-block-body"
          :class="{ expanded: expandedId === item.id }"
        >
          <template v-if="item.order">
            <div
              class=""
              :key="'order_item_' + item.id + '_' + orderItem.id"
              v-for="orderItem in item.order.orderItems"
            >
              <payments-single-item-overview
                v-if="
                  orderItem.cart_item_data &&
                  orderItem.cart_item_data.item_type &&
                  orderItem.cart_item_data.item_type !== 'PatientBalance'
                "
                :item="orderItem"
              ></payments-single-item-overview>
              <template v-else>
                {{ $t("label.patientPayment") }}
              </template>
            </div>
          </template>
        </div>
      </div>
      <load-more
        :show-load-more="hasMore"
        :show-loader="true"
        @load-more="loadMore"
      />
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import PaymentsSingleItemOverview from "@/views/private/components/payments/PaymentsSingleItemOverview";
import { default as EventBus } from "@/services/eventbus";
import LoadMore from "@/views/shared/components/LoadMore";
export default {
  name: "PaymentTabOverview",
  props: {
    patient: {
      type: Object,
      default() {
        return null;
      },
    },
    paymentStatus: {
      type: String,
      default: null,
    },
  },
  components: {
    LoadMore,
    PaymentsSingleItemOverview,
  },
  data() {
    return {
      paymentHistory: [],
      loadingPayments: false,
      expandedId: null,
      hasMore: true,
      pagination: {
        page: 1,
        perPage: 10,
      },
      cancelToken: {
        paymentHistory: null,
      },
    };
  },
  activated() {
    if (this.paymentStatus || this.paymentHistory.length === 0) {
      this.resetPaymentHistory();
      this.loadPaymentHistory();
    }
  },
  deactivated() {
    this.$emit("payment-status", null);
    cancelTokens(this.cancelToken);
  },
  methods: {
    resetPaymentHistory() {
      this.paymentHistory = [];
      this.hasMore = true;
      this.pagination = {
        page: 1,
        perPage: 10,
      };
    },
    setItemExpanded(item) {
      if (this.expandedId === item.id) {
        this.expandedId = null;
      } else {
        this.expandedId = item.id;
      }
    },
    loadMore() {
      if (!this.loadingPayments) {
        this.pagination.page =
          this.hasMore && this.paymentHistory.length === 0
            ? 1
            : this.pagination.page + 1;
        this.loadPaymentHistory();
      }
    },
    loadPaymentHistory() {
      if (!this.patient || this.loadingPayments || !this.hasMore) {
        return;
      }

      setCancelToken(this.cancelToken, "paymentHistory");
      this.loadingPayments = true;

      return HTTP({
        method: "GET",
        url: "/api/v1/purchases/patients/" + this.patient.id + "/payment-logs",
        data: {},
        cancelToken: this.cancelToken.paymentHistory.token,
        params: {
          order_by: "created_at",
          order_direction: "desc",
          page: this.pagination.page,
          per_page: this.pagination.perPage,
        },
      })
        .then((response) => {
          if (response.data && response.data.data) {
            this.paymentHistory = this.paymentHistory.concat(
              response.data.data
            );
          }
          if (response.data.meta.total <= this.paymentHistory.length) {
            this.hasMore = false;
          }

          this.loadingPayments = false;
        })
        .catch(() => {
          this.loadingPayments = false;
        });
    },
    payHandler() {
      EventBus.$emit("open-modal", {
        type: "payment-method",
        patient: this.patient,
        mode: "patient",
        checkoutTotal: this.checkoutTotal,
        callback: this.pay,
      });
    },
    pay(paymentOptions) {
      if (!this.patient) {
        return;
      }
      return HTTP({
        method: "POST",
        url:
          "/api/v1/purchases/patients/" + this.patient.id + "/patient-balance",
        data: {
          order_items: paymentOptions.payment_breakdown
            ? [
                {
                  price: this.calculatePrice(paymentOptions.payment_breakdown),
                  quantity: 1,
                  item_type: "PatientBalance",
                },
              ]
            : [],
          payment_breakdown: paymentOptions.payment_breakdown
            ? paymentOptions.payment_breakdown
            : null,
        },
      })
        .then(() => {
          let self = this;
          self.$notify({
            group: "notification",
            type: "success",
            title: self.$t("notification.thumbsUp"),
            text: self.$t("notification.successSave"),
          });
          self.$emit("load-patient-balance");
          self.paymentHistory = [];
          self.pagination = {
            page: 1,
            perPage: 10,
          };
          self.hasMore = true;
        })
        .catch((error) => {
          let self = this;
          if (error.message) {
            self.$notify({
              group: "notification",
              type: "error",
              title: self.$t("notification.sadFace"),
              text: error.message,
            });
          }
        });
    },
    calculatePrice(paymentBreakdown) {
      let price = 0;

      paymentBreakdown.forEach((item) => {
        price += parseFloat(item.amount);
      });

      return price;
    },
  },
};
</script>
