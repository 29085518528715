<template>
  <div class="list data-sidebar-sidebar-mini">
    <!--          top section-->
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.all") }} {{ $tc("label.provider", 2) }}
      </div>
      <div class="button action" @click.prevent="handleCreateButton">
        {{ $t("button.addNew") }}
        <div class="icon icon-add-big"></div>
      </div>
    </div>

    <!--          files section-->
    <div class="list-items">
      <div class="list-items-header">
        <header-sort
          sortKey="doctors"
          :sortArray="[
            {
              label: 'Name',
              key: 'first_name',
            },
            {
              label: 'Created',
              key: 'created_at',
              width: '290px',
            },
          ]"
        ></header-sort>
      </div>
      <div class="list-items-section">
        <!-- doctors-->
        <div class="list-items-section-wrapper">
          <div
            v-for="doctor in doctors"
            :key="'doctor_' + doctor.id"
            :class="{
              'list-items-section-item': true,
              disabled: isInactiveDoctor(doctor),
            }"
          >
            <div class="icon-doctor">
              <item-icon
                type="doctor-2"
                :class-name="colorClass(doctor.id, 'doctor')"
              ></item-icon>
            </div>
            <div class="name">
              {{ doctor.first_name + " " + doctor.last_name }}
            </div>
            <div class="created">
              {{ formatDateTime(doctor.created_at) }}
            </div>
            <doctor-options-menu :doctor="doctor"></doctor-options-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="list-footer">
      <pagination-per-page
        :page="$store.state.doctors.pagination.page"
        :perPage="$store.state.doctors.pagination.perPage"
        :allResults="allResults"
        show-label
        :show-counter="true"
        @value-change="setPagination"
      ></pagination-per-page>
      <pagination-page
        :page="$store.state.doctors.pagination.page"
        :perPage="$store.state.doctors.pagination.perPage"
        :options="pages"
        show-label
        :show-controls="true"
        @value-change="setPagination"
      ></pagination-page>
    </div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import PaginationPerPage from "@/views/private/components/table/PaginationPerPage";
import PaginationPage from "@/views/private/components/table/PaginationPage";
import HeaderSort from "@/views/private/components/table/HeaderSort";
import ItemIcon from "@/views/private/components/ItemIcon";
import DoctorOptionsMenu from "@/views/private/components/settings/DoctorOptionsMenu";

export default {
  name: "DoctorsView",
  props: ["path"],
  components: {
    DoctorOptionsMenu,
    ItemIcon,
    HeaderSort,
    PaginationPerPage,
    PaginationPage,
  },
  data: function () {
    return {
      files: [],
      doctors: [],
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.loadDoctors();
  },
  created() {
    EventBus.$on("doctors-load", () => {
      this.loadDoctors();
    });
  },
  unmounted() {
    EventBus.$off("doctors-load");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.doctors": {
      deep: true,
      handler: function () {
        this.loadDoctors();
      },
    },
  },
  methods: {
    loadDoctors() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/doctors",
        data: {},
        params: {
          q: this.$store.state.doctors.search,
          sort_by: ["last_name|asc"],
          page: this.$store.state.doctors.pagination.page,
          per_page: this.$store.state.doctors.pagination.perPage,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            const newArray = [];
            const inactiveDoctors = [];
            response.data.data.forEach((item) => {
              if (item.status === "inactive") {
                return inactiveDoctors.push(item);
              }
              return newArray.push(item);
            });
            this.doctors = newArray.concat(inactiveDoctors);
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    setPagination(value) {
      this.$store.dispatch("setPagination", {
        type: "doctors",
        data: value,
      });
    },
    handleCreateButton() {
      this.$router.push({
        name: "doctorAdd",
      });
    },
  },
};
</script>
