<template>
  <div
    :class="{
      action: true,
      disabled: !enabled,
    }"
    :style="
      width !== ''
        ? 'flex-basis: ' + width + '; min-width: ' + width
        : 'flex: 1;'
    "
  >
    <div class="margin-auto" style="width: fit-content">
      <item-icon
        v-if="icon !== ''"
        :type="icon"
        :class-name="{ 'color-gray': !enabled, 'color-primary': enabled }"
        @click.prevent.stop="$emit('button-pressed')"
      />
    </div>
  </div>
</template>

<script>
import ItemIcon from "@/views/private/components/ItemIcon";
export default {
  name: "TableIconAction",
  components: { ItemIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
