import Vuex from "vuex";
import dateMixin from "@/mixins/dateMixin";
import clinicConfig from "@/config/clinic";

export const INSURANCE_CLAIM_STATUSES = {
  STATUS_NOT_READY: "Not ready",
  STATUS_READY_TO_SUBMIT: "Ready to Submit",
  STATUS_SUBMITTED: "Submitted",
  STATUS_PROCESSED_PAID: "Processed Paid",
  STATUS_PROCESSED_DENIED: "Processed Denied",
};

export const CLAIM_TYPES_OPTIONS = {
  CLAIM_TYPE_SELF_PAY: {
    label: "Self Pay",
    value: "self-pay",
  },
  CLAIM_TYPE_MEDICAL_INSURANCE: {
    label: "Medical insurance",
    value: "medical-insurance",
  },
  CLAIM_TYPE_WORKERS_COMPENSATION: {
    label: "Workers compensation",
    value: "workers-compensation",
  },
  CLAIM_TYPE_ACCIDENT_PIP: {
    label: "Accident/PIP",
    value: "accident-pip",
  },
  CLAIM_TYPE_VETERANS: {
    label: "Veterans",
    value: "veterans",
  },
  CLAIM_TYPE_HYBRID: {
    label: "Hybrid (billable & self-pay)",
    value: "hybrid",
  },
};

export const PAGINATION = {
  search: "",
  sortOrder: [],
  pagination: {
    page: 1,
    perPage: 20,
  },
};

export default new Vuex.Store({
  state: {
    auth: window.localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : {},
    user: window.localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
    userType: window.localStorage.getItem("userType")
      ? JSON.parse(localStorage.getItem("userType"))
      : "user",
    filter: window.localStorage.getItem("filter")
      ? JSON.parse(localStorage.getItem("filter"))
      : {},
    colors: window.localStorage.getItem("colors")
      ? JSON.parse(localStorage.getItem("colors"))
      : {},
    colorsSet: false,
    filters: [],
    focusedFilter: "doctors",
    events: [],
    soapNotes: window.localStorage.getItem("soapNotes")
      ? JSON.parse(localStorage.getItem("soapNotes"))
      : {},
    documentCampaigns: [],
    firstVisit: window.localStorage.getItem("firstVisit")
      ? JSON.parse(localStorage.getItem("firstVisit"))
      : [],
    modal: {},
    inventory: {
      search: "",
      folder: null,
      tag: null,
      sort: {
        key: "desc",
        label: "Last added",
      },
      pagination: {
        page: 1,
        perPage: 20,
      },
    },
    inventoryShow: {
      key: "both",
      label: "Items & Folders",
    },
    inventoryAdvanced: { ...PAGINATION, query: null },
    doctors: { ...PAGINATION },
    insuranceProviders: { ...PAGINATION },
    plans: { ...PAGINATION },
    charges: { ...PAGINATION },
    reports: { ...PAGINATION, reset: false },
    clinics: { ...PAGINATION },
    users: { ...PAGINATION },
    rooms: { ...PAGINATION },
    posTerminals: { ...PAGINATION },
    treatments: { ...PAGINATION },
    patients: { ...PAGINATION },
    insuranceLedger: { ...PAGINATION },
    insuranceClaim: { ...PAGINATION },
    insuranceWallet: { ...PAGINATION },
    documents: {
      ...PAGINATION,
      selectedCampaign: null,
      patientDocuments: false,
      currentPath: "/",
    },
    payment: {
      items: [],
    },
    timeZone: null,
    currentView: window.localStorage.getItem("currentView")
      ? JSON.parse(window.localStorage.getItem("currentView"))
      : {
          type: "day",
          start_date: dateMixin.methods.getWeekStart(new Date()),
          end_date: dateMixin.methods.getWeekEnd(new Date()),
        },
    currentDate: new Date(),
    onboardingTooltipShown: window.localStorage.getItem(
      "onboardingTooltipShown"
    )
      ? JSON.parse(window.localStorage.getItem("onboardingTooltipShown"))
      : [],
  },
  getters: {
    isAuthUser: (state) => {
      return (
        state.auth.constructor === Object &&
        Object.keys(state.auth).length !== 0 &&
        state.auth.access_token
      );
    },
    userHoursFormat24: (state) => {
      return state.user.hour_format === "24-hours";
    },
    workingStartMinutes: (state) => {
      let startTime =
        state.user.clinic && state.user.clinic.working_hours_from
          ? state.user.clinic.working_hours_from
          : clinicConfig.DEFAULT_WORKING_START_TIME;

      let dayStart = dateMixin.methods.newTimeZoneDate();
      dayStart.setHours(0, 0, 0, 0);
      let date = dateMixin.methods.getDateFromTime(startTime);
      return dateMixin.methods.diffInMinutes(date, dayStart);
    },
    workingEndMinutes: (state) => {
      let endTime =
        state.user.clinic && state.user.clinic.working_hours_to
          ? state.user.clinic.working_hours_to
          : clinicConfig.DEFAULT_WORKING_END_TIME;

      let dayStart = dateMixin.methods.newTimeZoneDate();
      dayStart.setHours(0, 0, 0, 0);
      let date = dateMixin.methods.getDateFromTime(endTime);
      return dateMixin.methods.diffInMinutes(date, dayStart);
    },
    timeZone: (state) => {
      let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return state.timeZone === null
        ? browserTimezone
          ? browserTimezone
          : "Etc/UTC"
        : state.timeZone;
    },
    clinicId: (state) => {
      return typeof state.user.clinic === "undefined" ||
        state.user.clinic === null
        ? ""
        : state.user.clinic.id;
    },
    eventsObjects: (state) => {
      return state.events
        .filter(function (item) {
          return item.type !== "time_off" || state.focusedFilter === "doctors";
        })
        .map(function (item) {
          return {
            id: typeof item.id !== "undefined" ? item.id : null,
            type: typeof item.type !== "undefined" ? item.type : null,
            start:
              typeof item.start !== "undefined"
                ? dateMixin.methods.parseFromUTC(item.start)
                : null,
            end:
              typeof item.end !== "undefined"
                ? dateMixin.methods.parseFromUTC(item.end)
                : null,
            patient: typeof item.patient !== "undefined" ? item.patient : null,
            doctor: typeof item.doctor !== "undefined" ? item.doctor : null,
            room:
              typeof item.room !== "undefined" && item.room ? item.room : null,
            treatment:
              typeof item.treatment !== "undefined" ? item.treatment : null,
            status: typeof item.status !== "undefined" ? item.status : null,
            split: (() => {
              switch (state.focusedFilter) {
                case "doctors":
                  return item.doctor !== "undefined" && item.doctor
                    ? item.doctor.id
                    : null;
                case "rooms":
                  return item.room !== "undefined" && item.room
                    ? item.room.id
                    : null;
                case "treatments":
                  return item.treatment !== "undefined" && item.treatment
                    ? item.treatment.id
                    : null;
                case "statuses":
                  return item.status !== "undefined" ? item.status : null;
                default:
                  return null;
              }
            })(),
            details: typeof item.details !== "undefined" ? item.details : null,
            notes: typeof item.notes !== "undefined" ? item.notes : null,
            title: typeof item.title !== "undefined" ? item.title : null,
          };
        });
    },
  },
  mutations: {
    ADD_PAYMENT_ITEM(state, payload) {
      let index = state.payment.items.findIndex(function (item) {
        return item.id === payload.id;
      });
      if (index === -1) {
        state.payment.items.push(payload);
      } else {
        state.payment.items.splice(index, 1);
        state.payment.items.splice(index, 0, payload);
      }
    },
    ADD_FIRST_VISIT(state, payload) {
      if (state.firstVisit.indexOf(payload) === -1) {
        state.firstVisit.push(payload);
        window.localStorage.setItem(
          "firstVisit",
          JSON.stringify(state.firstVisit)
        );
      }
    },
    REMOVE_SOAP_NOTE(state) {
      state.soapNotes = {};
      window.localStorage.setItem("soapNotes", JSON.stringify(state.soapNotes));
    },
    REMOVE_PAYMENT_ITEM(state, payload) {
      if (typeof payload === "object" && payload.length === 0) {
        state.payment.items = [];
      }
      state.payment.items.splice(payload, 1);
    },
    RESET_TOOLTIPS(state) {
      state.onboardingTooltipShown = [];
      window.localStorage.setItem(
        "onboardingTooltipShown",
        JSON.stringify(state.onboardingTooltipShown)
      );
      state.firstVisit = [];
      window.localStorage.setItem(
        "firstVisit",
        JSON.stringify(state.firstVisit)
      );
    },
    RESET_REPORTS_QUERY(state) {
      state.reports = {
        search: "",
        sortOrder: [],
        pagination: {
          page: 1,
          perPage: 20,
        },
        reset: true,
      };
    },
    SET_AUTH(state, payload) {
      state.auth = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
      window.localStorage.setItem("user", JSON.stringify(payload));
    },
    SET_USER_TYPE(state, payload) {
      state.userType = payload;
      window.localStorage.setItem("userType", JSON.stringify(payload));
    },
    SET_USER_CLINIC(state, payload) {
      state.user.clinic = payload;
      window.localStorage.setItem("user", JSON.stringify(state.user));
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
      window.localStorage.setItem("filter", JSON.stringify(payload));
    },
    SET_FOCUSED_FILTER(state, payload) {
      state.focusedFilter = payload;
    },
    SET_EVENTS(state, payload) {
      state.events = payload;
      window.localStorage.setItem("events", JSON.stringify(payload));
    },
    SET_FILTERS(state, payload) {
      state.filters = payload;
    },
    SET_COLORS(state, payload) {
      state.colors = payload;
      window.localStorage.setItem("colors", JSON.stringify(payload));
    },
    SET_COLOR(state, payload) {
      if (typeof state.colors[payload.type] !== "undefined") {
        state.colors[payload.type][payload.id] = payload.color;
        window.localStorage.setItem("colors", JSON.stringify(state.colors));
      }
    },
    SET_COLORS_SET(state, payload) {
      state.colorsSet = payload;
    },
    SET_CURRENT_DATE(state, payload) {
      state.currentDate = payload;
    },
    SET_CURRENT_VIEW(state, payload) {
      state.currentView = payload;
      window.localStorage.setItem("currentView", JSON.stringify(payload));
    },
    SET_SEARCH(state, payload) {
      if (payload.type && typeof payload.data === "string") {
        if (typeof state[payload.type].pagination !== "undefined") {
          state[payload.type].pagination = {
            page: 1,
            perPage: 20,
          };
        }
        state[payload.type].search = payload.data;
      }

      if (payload.type === "documents" && !state.documents.patientDocuments) {
        state.documents.selectedCampaign = null;
        state.documents.currentPath = "/";
      }
    },
    SET_RESOURCE_STORE_VAL(state, payload) {
      if (
        typeof payload.key !== "undefined" &&
        typeof payload.type !== "undefined" &&
        typeof payload.data !== "undefined"
      ) {
        if (payload.type !== "") {
          state[payload.key][payload.type] = payload.data;
        } else {
          state[payload.key] = payload.data;
        }
      }
    },
    SET_PAGINATION(state, payload) {
      if (payload.type && payload.data) {
        state[payload.type].pagination = payload.data;
      }

      if (payload.type === "reports") {
        state.reports.reset = false;
      }
    },
    SET_DOCUMENT_CAMPAIGNS(state, payload) {
      state.documentCampaigns = payload;
    },
    SET_SELECTED_CAMPAIGN(state, payload) {
      state.documents.search = "";
      state.documents.selectedCampaign = payload;
    },
    SET_HEADER_SORT_ORDER(state, payload) {
      state[payload.key].sortOrder = payload.value;
    },
    SET_SOAP_NOTE(state, payload) {
      let storeKey = "soap_" + payload.id;
      state.soapNotes = {};
      state.soapNotes[storeKey] = payload.value;
      window.localStorage.setItem("soapNotes", JSON.stringify(state.soapNotes));
    },
    SET_DOCUMENT_PATH(state, payload) {
      state.documents.search = "";
      state.documents.selectedCampaign = null;
      state.documents.patientDocuments = false;
      state.documents.currentPath = payload;
    },
    SET_PATIENT_DOCUMENTS(state, payload) {
      state.documents.currentPath = "/patients";
      state.documents.patientDocuments = payload;
    },
    SET_DOCUMENT_PATH_UP(state) {
      if (state.documents.currentPath !== "/") {
        let path = state.documents.currentPath.split("/");
        delete path[path.length - 1];
        let newPath = path.join("/");
        state.documents.selectedCampaign = null;
        state.documents.patientDocuments = false;
        state.documents.currentPath =
          newPath !== "/" ? newPath.substring(0, newPath.length - 1) : "/";
      }
    },
    SET_ONBOARDING_TOOLTIP_SHOWN(state, payload) {
      if (state.onboardingTooltipShown.indexOf(payload) === -1) {
        state.onboardingTooltipShown.push(payload);
        window.localStorage.setItem(
          "onboardingTooltipShown",
          JSON.stringify(state.onboardingTooltipShown)
        );
      }
    },
    SET_MODAL_DATA(state, payload) {
      state.modal = payload;
    },
  },
  actions: {
    addFirstVisit(context, payload) {
      context.commit("ADD_FIRST_VISIT", payload);
    },
    addPaymentItem(context, payload) {
      context.commit("ADD_PAYMENT_ITEM", payload);
    },
    resetFilter(context) {
      context.commit("SET_FILTER", {});
    },
    resetTooltips(context) {
      context.commit("RESET_TOOLTIPS");
    },
    resetReportsQuery(context) {
      context.commit("RESET_REPORTS_QUERY");
    },
    removeSoapNote(context) {
      context.commit("REMOVE_SOAP_NOTE");
    },
    removePaymentItem(context, payload) {
      context.commit("REMOVE_PAYMENT_ITEM", payload);
    },
    setUserType(context, payload) {
      context.commit("SET_USER_TYPE", payload);
    },
    setOnboardingTooltipShown(context, payload) {
      context.commit("SET_ONBOARDING_TOOLTIP_SHOWN", payload);
    },
    setPagination(context, payload) {
      context.commit("SET_PAGINATION", payload);
    },
    setResourceStoreVal(context, payload) {
      context.commit("SET_RESOURCE_STORE_VAL", payload);
    },
    setSearch(context, payload) {
      context.commit("SET_SEARCH", payload);
    },
    setSelectedCampaign(context, payload) {
      context.commit("SET_SELECTED_CAMPAIGN", payload);
    },
    setDocumentPath(context, payload) {
      context.commit("SET_DOCUMENT_PATH", payload);
    },
    setPatientDocuments(context, payload) {
      context.commit("SET_PATIENT_DOCUMENTS", payload);
    },
    setDocumentPathUp(context) {
      context.commit("SET_DOCUMENT_PATH_UP");
    },
    setHeaderSortOrder(context, payload) {
      context.commit("SET_HEADER_SORT_ORDER", payload);
    },
    setSoapNote(context, payload) {
      context.commit("SET_SOAP_NOTE", payload);
    },
    setColors(context, payload) {
      context.commit("SET_COLORS", payload);
    },
    setColor(context, payload) {
      context.commit("SET_COLOR", payload);
    },
    setColorsSet(context, payload) {
      context.commit("SET_COLORS_SET", payload);
    },
    setFilters(context, payload) {
      context.commit("SET_FILTERS", payload);
    },
    setFilter(context, payload) {
      if (typeof payload.doctors !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.doctors;
        } else {
          this.state.filter.doctors = payload.values;
        }
      }
      if (typeof payload.rooms !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.rooms;
        } else {
          this.state.filter.rooms = payload.values;
        }
      }
      if (typeof payload.posTerminals !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.posTerminals;
        } else {
          this.state.filter.posTerminals = payload.values;
        }
      }
      if (typeof payload.treatments !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.treatments;
        } else {
          this.state.filter.treatments = payload.values;
        }
      }
      if (typeof payload.others !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.others;
        } else {
          this.state.filter.others = payload.values;
        }
      }
      if (typeof payload.statuses !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.statuses;
        } else {
          this.state.filter.statuses = payload.values;
        }
      }
      if (typeof payload.search !== "undefined") {
        if (payload.values.length === 0) {
          delete this.state.filter.search;
        } else {
          this.state.filter.search = payload.values;
        }
      }
      let newFilter = JSON.parse(JSON.stringify(this.state.filter));
      context.commit("SET_FILTER", newFilter);
    },
    setFocusedFilter(context, payload) {
      context.commit("SET_FOCUSED_FILTER", payload);
    },
    setEvents(context, payload) {
      if (typeof payload.events !== "undefined") {
        this.state.events = JSON.parse(JSON.stringify(payload.events));
      }
      context.commit("SET_EVENTS", this.state.events);
    },
    setDocumentCampaigns(context, payload) {
      context.commit("SET_DOCUMENT_CAMPAIGNS", payload);
    },
    setAuth(context, payload) {
      if (
        typeof payload.expires_at !== "undefined" &&
        payload.expires_at !== null
      ) {
        payload.expires_at = payload.expires_in + Math.round(Date.now() / 1000);
      }
      context.commit("SET_AUTH", payload);
      window.localStorage.setItem("auth", JSON.stringify(payload));
    },
    setUser(context, payload) {
      context.commit("SET_USER", payload);
    },
    setUserClinic(context, payload) {
      context.commit("SET_USER_CLINIC", payload);
    },
    setLogout() {
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("auth");
      window.localStorage.removeItem("colors");
      window.localStorage.removeItem("events");
      window.localStorage.removeItem("filters");
      window.localStorage.removeItem("currentView");
    },
    setCurrentDate(context, payload) {
      context.commit("SET_CURRENT_DATE", payload);
    },
    setCurrentView(context, payload) {
      context.commit("SET_CURRENT_VIEW", payload);
    },
    setModalData(context, payload) {
      context.commit("SET_MODAL_DATA", payload);
    },
  },
  modules: {},
});
