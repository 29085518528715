<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.editRoom")
                          : $t("label.addNewRoom")
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newRoom.name'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <input ref="name" v-model="newRoom.name" type="text" />
                  </div>
                  <div class="form-item">
                    <label class="item">{{ $t("label.color") }}: </label>
                    <color-picker
                      :model-value="newRoom.color"
                      @update:modelValue="updateColor"
                      :preset-colors="[
                        '#CF5B5B',
                        '#1897E3',
                        '#3EAD6C',
                        '#725FD0',
                        '#CDAC02',
                      ]"
                    ></color-picker>
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="saveRoom()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import SimpleVueValidator from "simple-vue3-validator";
import { Sketch } from "@ckpack/vue-color";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "RoomCreate",
  components: { "color-picker": Sketch },
  validators: {
    "newRoom.name": function (value) {
      return Validator.value(value).required();
    },
  },
  data() {
    return {
      newRoom: {
        name: null,
        color: "#CF5B5B",
      },
      loading: false,
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  mounted() {
    this.entrypoint();
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadRoom();
      }
    },
    isModeEdit() {
      return "roomEdit" === this.$route.name;
    },
    loadRoom() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/rooms/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newRoom = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveRoom() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/rooms/" + (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newRoom);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
                self.loading = false;
                self.closeModal();
                EventBus.$emit("rooms-load");
                self.injectColor(
                  "rooms",
                  response.data.id ?? null,
                  response.data.color ?? null
                );
              }
            })
            .catch((error) => {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
              self.loading = false;
            });
        }
      });
    },
    updateColor(color) {
      this.newRoom.color = color.hex;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
