<template>
  <div class="row">
    <div class="col-lg-12 patients-form">
      <patient-consent-form-privacy />
    </div>
  </div>
</template>

<script>
import PatientConsentFormPrivacy from "@/views/shared/components/consents/PatientConsentFormPrivacy";
export default {
  name: "PatientConsentPrivacy",
  components: { PatientConsentFormPrivacy },
};
</script>
