<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog payment-modal" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{ $t("label.paymentType") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div class="payment-modal-group">
                    <h4>Credit Card</h4>
                    <div class="form-item">
                      <label class="item">{{ $t("label.amount") }}:</label>
                      <input
                        ref="ccAmount"
                        type="number"
                        :max="this.checkoutTotal"
                        min="0"
                        name="ccOption"
                        v-model="amount.cc"
                      />
                    </div>
                    <div class="payment-modal-group-inner margin-top-20">
                      <div
                        class="payment-modal-group-inner-item"
                        v-if="amount.cc > 0"
                      >
                        <div
                          class="form-item padding-bottom-0"
                          :class="{
                            error: validation.hasError('ccMethod'),
                          }"
                        >
                          <div class="input-options radio">
                            <input
                              type="radio"
                              id="cc_card"
                              value="cc_card"
                              name="ccOption"
                              v-model="ccMethod"
                            />
                            <label class="radio" for="cc_card"> cc form </label>
                          </div>
                        </div>
                        <div
                          class="form-item padding-bottom-0"
                          :class="{
                            error: validation.hasError('ccMethod'),
                          }"
                        >
                          <div class="input-options radio">
                            <input
                              type="radio"
                              id="cc_pos_terminal"
                              value="cc_pos_terminal"
                              name="ccOption"
                              v-model="ccMethod"
                            />
                            <label class="radio" for="cc_pos_terminal">
                              POS terminal
                            </label>
                          </div>
                        </div>
                        <div
                          class="form-item padding-bottom-0"
                          :class="{
                            error: validation.hasError('ccMethod'),
                          }"
                        >
                          <div class="input-options radio">
                            <input
                              type="radio"
                              id="cc_customer_vault"
                              value="cc_customer_vault"
                              name="ccOption"
                              :disabled="
                                !patient.payment_data ||
                                (customerVault &&
                                  customerVault.customer_vault &&
                                  customerVault.customer_vault.length === 0)
                              "
                              v-model="ccMethod"
                            />
                            <label class="radio" for="cc_customer_vault">
                              read from customer vault
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="payment-modal-group-inner-item">
                        <div
                          class="modal-dialog-main-form card-form"
                          v-if="this.ccMethod === 'cc_card'"
                        >
                          <div
                            class="form-item"
                            :class="{
                              error: validation.hasError(
                                'newPayment.first_name'
                              ),
                            }"
                          >
                            <label class="item"
                              >{{ $t("label.firstName") }}:</label
                            >
                            <input
                              ref="firstName"
                              v-model="newPayment.first_name"
                              type="text"
                            />
                          </div>
                          <div
                            class="form-item"
                            :class="{
                              error: validation.hasError(
                                'newPayment.last_name'
                              ),
                            }"
                          >
                            <label class="item">{{
                              $t("label.lastName")
                            }}</label>
                            <input v-model="newPayment.last_name" type="text" />
                          </div>
                          <div
                            class="form-item"
                            :class="{
                              error: validation.hasError('newPayment.ccnumber'),
                            }"
                          >
                            <label class="item">{{
                              $t("label.cardNumber")
                            }}</label>
                            <input
                              :value="newPayment.ccnumber"
                              v-imask="masks.maskCard"
                              ref="cardValue"
                              @accept="acceptCardValue"
                              type="text"
                            />
                          </div>
                          <div
                            class="form-item"
                            :class="{
                              error: validation.hasError('newPayment.ccexp'),
                            }"
                          >
                            <label class="item">{{
                              $t("label.cardExpire")
                            }}</label>
                            <input
                              :value="newPayment.ccexp"
                              ref="expireValue"
                              v-imask="masks.maskExpire"
                              @accept="acceptExpireValue"
                              type="text"
                            />
                          </div>
                        </div>

                        <div
                          class="modal-dialog-main-form card-form"
                          v-if="this.ccMethod === 'cc_pos_terminal'"
                        >
                          <div
                            class="form-item"
                            :class="{
                              error: validation.hasError('ccMethodObject'),
                            }"
                          >
                            <label class="item"
                              >{{ $t("label.posTerminal") }}:</label
                            >
                            <v-select
                              style="min-width: 190px"
                              label="device_nickname"
                              ref="posTerminal"
                              placeholder="Select POS terminal"
                              :options="posTerminals"
                              v-model="ccMethodObject"
                            >
                            </v-select>
                          </div>
                        </div>

                        <div
                          class="form-item margin-top-5"
                          v-if="
                            customerVault && ccMethod === 'cc_customer_vault'
                          "
                        >
                          <div class="modal-dialog-main-form card-form">
                            <template
                              v-if="!!customerVault.customer_vault.customer"
                            >
                              {{
                                customerVault.customer_vault.customer.cc_type
                              }}
                              <br />
                            </template>
                            <template
                              v-if="!!customerVault.customer_vault.customer"
                            >
                              {{
                                customerVault.customer_vault.customer.cc_number
                              }}
                              <br />
                            </template>
                            <template
                              v-if="!!customerVault.customer_vault.customer"
                            >
                              {{
                                customerVault.customer_vault.customer.cc_exp.slice(
                                  0,
                                  2
                                )
                              }}
                              /
                              {{
                                customerVault.customer_vault.customer.cc_exp.slice(
                                  -2
                                )
                              }}
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="payment-modal-group">
                    <h4>Cash</h4>
                    <div class="form-item">
                      <label class="item">{{ $t("label.amount") }}:</label>
                      <input
                        ref="cashAmount"
                        type="number"
                        :max="this.checkoutTotal"
                        min="0"
                        v-model="amount.cash"
                      />
                    </div>
                  </div>
                  <div class="payment-modal-group">
                    <h4>Check</h4>
                    <div class="form-item">
                      <label class="item">{{ $t("label.amount") }}:</label>
                      <input
                        ref="checkAmount"
                        type="number"
                        :max="this.checkoutTotal"
                        min="0"
                        v-model="amount.check"
                      />
                    </div>
                  </div>
                  <div class="payment-modal-group">
                    <h4>External Payment Provider</h4>
                    <div class="form-item">
                      <label class="item">{{ $t("label.amount") }}:</label>
                      <input
                        ref="eppAmount"
                        type="number"
                        :max="this.checkoutTotal"
                        min="0"
                        v-model="amount.epp"
                      />
                    </div>
                  </div>
                  <template v-if="mode === 'order'">
                    <p class="payment-modal-group-message">
                      {{ $t("label.total") }}
                      {{ formatPrice(this.checkoutTotal) }}
                    </p>
                  </template>
                  <p class="payment-modal-group-message">
                    {{ $t("label.totalToPay") }}
                    {{ formatPrice(this.totalToPay) }}
                  </p>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="pay()"
                    >
                      {{ $t("button.submit") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/api";
import SimpleVueValidator from "simple-vue3-validator";
import IMask from "imask";
import { IMaskDirective } from "vue-imask";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PaymentMethod",
  props: ["patient", "callback", "checkoutTotal", "mode"],
  data() {
    return {
      customerVault: null,
      ccMethod: null,
      ccMethodObject: null,
      newPayment: {
        first_name: null,
        last_name: null,
        ccnumber: null,
        ccexp: null,
      },
      posTerminals: [],
      loading: false,
      observer: null,
      masks: {
        maskCard: {
          mask: "dddd dddd dddd dddd",
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 9,
              placeholderChar: "-",
            },
          },
          lazy: false,
          overwrite: false,
        },
        maskExpire: {
          mask: "block1 / block2",
          blocks: {
            block1: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              placeholderChar: "M",
            },
            block2: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 99,
              placeholderChar: "Y",
            },
          },
          lazy: false,
          overwrite: false,
        },
      },
      amount: {
        cc: 0,
        cash: 0,
        check: 0,
        epp: 0,
      },
    };
  },
  validators: {
    ccMethod: function (value) {
      if (parseFloat(this.amount.cc) > 0) {
        return Validator.value(value).required();
      }
    },
    ccMethodObject: function (value) {
      if (
        parseFloat(this.amount.cc) > 0 &&
        this.ccMethod === "cc_pos_terminal"
      ) {
        return Validator.value(value).required();
      }
    },
    "newPayment.first_name": function (value) {
      if (this.ccMethod === "cc_card") {
        return Validator.value(value).required();
      }
    },
    "newPayment.last_name": function (value) {
      if (this.ccMethod === "cc_card") {
        return Validator.value(value).required();
      }
    },
    "newPayment.ccnumber": function (value) {
      if (this.ccMethod === "cc_card") {
        let scope = this;
        return Validator.custom(function () {
          if (value === "") {
            return "empty";
          }
          if (
            scope.$refs["cardValue"] &&
            !scope.$refs["cardValue"].maskRef.masked.isComplete
          ) {
            return "not complete";
          }
        });
      }
    },
    "newPayment.ccexp": function (value) {
      if (this.ccMethod === "cc_card") {
        let scope = this;
        return Validator.custom(function () {
          if (value === "") {
            return "empty";
          }
          if (
            scope.$refs["expireValue"] &&
            !scope.$refs["expireValue"].maskRef.masked.isComplete
          ) {
            return "not complete";
          }
        });
      }
    },
  },
  mounted() {
    if (this.patient.payment_data) {
      this.loadCustomerVault();
    }
    this.loadPosTerminals();
    this.$validate();
  },
  computed: {
    totalToPay: function () {
      return (
        (isNaN(parseFloat(this.amount.cc)) ? 0 : parseFloat(this.amount.cc)) +
        (isNaN(parseFloat(this.amount.cash))
          ? 0
          : parseFloat(this.amount.cash)) +
        (isNaN(parseFloat(this.amount.check))
          ? 0
          : parseFloat(this.amount.check)) +
        (isNaN(parseFloat(this.amount.epp)) ? 0 : parseFloat(this.amount.epp))
      );
    },
  },
  methods: {
    loadPosTerminals() {
      HTTP({
        method: "GET",
        url: "/api/v1/poi-devices",
        data: {},
      })
        .then((response) => {
          if (response.data) {
            this.posTerminals = response.data.data;
          }
        })
        .catch(() => {});
    },
    loadCustomerVault() {
      let self = this;
      HTTP({
        method: "GET",
        url:
          "/api/v1/purchases/patients/" + self.patient.id + "/payments/info/",
      })
        .then((response) => {
          if (response.data) {
            self.customerVault = response.data;
          }
        })
        .catch(() => {});
    },
    pay() {
      let self = this;

      if (self.ccMethod !== "cc_card") {
        self.completePay();
        return;
      }

      self.$validate().then(function (success) {
        if (success) {
          let cardAdded = self.addCard();

          cardAdded.then((success) => {
            if (success) {
              self.completePay();
            }
          });
        }
      });
    },
    async addCard() {
      let self = this;
      let successCardAdd = false;

      self.loading = true;
      let postData = self.preparePostDataWithDates({
        ...self.newPayment,
        zip: self.patient.zip,
      });
      await HTTP({
        method: "POST",
        url:
          "/api/v1/purchases/patients/" +
          self.patient.id +
          "/payments/info/add/",
        data: postData,
      })
        .then((response) => {
          if (response.status === 200) {
            successCardAdd = response.data.data;
            self.loading = false;

            if (!successCardAdd) {
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                text: self.$t("notification.errorSaveCard"),
              });
              return;
            }

            self.$notify({
              group: "notification",
              type: "success",
              title: self.$t("notification.thumbsUp"),
              text: self.$t("notification.successSave"),
            });
          }
        })
        .catch(() => {
          self.loading = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: self.$t("notification.sadFace"),
            text: self.$t("notification.errorSave"),
          });
        });

      return successCardAdd;
    },
    completePay() {
      let self = this;
      self.$validate().then(function (success) {
        if (success && self.mode === "order") {
          self.closeModal();
        }

        if (success && self.callback) {
          if (self.mode === "patient") {
            self.loading = true;
          }

          self
            .callback({
              payment_breakdown: [
                {
                  type: "cc",
                  method: self.ccMethod,
                  method_id:
                    self.ccMethod === "cc_pos_terminal"
                      ? self.ccMethodObject.poi_device_id
                      : "",
                  amount: Number(self.amount.cc),
                },
                {
                  type: "cash",
                  amount: Number(self.amount.cash),
                },
                {
                  type: "check",
                  amount: Number(self.amount.check),
                },
                {
                  type: "epp",
                  amount: Number(self.amount.epp),
                },
              ],
            })
            .catch(() => {})
            .finally(() => {
              if (self.mode === "patient") {
                self.loading = false;
                self.closeModal();
              }
            });
        }
      });
    },
    acceptCardValue(e) {
      const maskRef = e.detail;
      this.newPayment.ccnumber = maskRef.value.replaceAll(/ /g, "");
    },
    acceptExpireValue(e) {
      const maskRef = e.detail;
      this.newPayment.ccexp = maskRef.value
        .replace(/ /g, "")
        .replace(/\//g, "");
    },
    closeModal() {
      this.$emit("close");
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style scoped></style>
