<template>
  <div class="list data-sidebar-mini">
    <div class="list-top">
      <div class="list-top-text">
        {{ $t("label.policyInfo") }}
        <template v-if="patient">
          - {{ patient.first_name + " " + patient.last_name }}
        </template>
      </div>
      <div class="list-top-action">
        <div class="button action" @click.prevent="submitForm()">
          {{ $t("button.save") }}
        </div>
      </div>
    </div>

    <div class="list-filter">
      <div
        v-if="patientPolicyInfoList.length === 0 && !loading && !loadingPolicy"
        class="form-item"
      >
        {{ $t("message.noCurrentPolicyInfo") }}
      </div>
      <div v-else class="form-item">
        <label class="item">{{ $t("label.policyInfo") }}:</label>
        <v-select
          ref="selectPolicyInfo"
          placeholder="Select policy infos"
          :getOptionLabel="createdAtOptionLabel"
          :options="patientPolicyInfoList"
          @update:modelValue="loadPatientPolicyInfo"
          v-model="currentPolicyInfo"
        >
          <template #list-footer>
            <load-more
              :message="$t('message.loading') + '...'"
              :showLoadMore="hasMore && patientPolicyInfoList.length > 0"
              :custom-class="'vs__dropdown-option'"
              @load-more="loadMore"
            ></load-more>
          </template>
        </v-select>
      </div>
      <div class="form-item action" v-if="currentPolicyInfo">
        <a class="button action" @click.prevent="deleteCurrentItem">
          {{ $t("button.delete") }}
        </a>
      </div>
    </div>

    <div class="list-wrapper double" v-if="!loadingPolicy">
      <div class="list-items">
        <div class="form-item-separator">
          {{ $t("label.billingInformationSummary") }}
        </div>
        <!--      legal name-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.legalName") }} <span class="required">*</span>
            </div>
            <div class="form-item double no-padding">
              <div
                class="form-item"
                :class="{
                  error: this.hasError('legal_first_name'),
                }"
              >
                <label class="item">{{ $t("label.firstName") }}</label>
                <input v-model="formValues.legal_first_name" type="text" />
              </div>
              <div
                class="form-item"
                :class="{
                  error: this.hasError('legal_last_name'),
                }"
              >
                <label class="item">{{ $t("label.lastName") }}</label>
                <input v-model="formValues.legal_last_name" type="text" />
              </div>
            </div>
          </div>
        </div>

        <!--      dob -->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.dob") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: validation.hasError('formValues.date_of_birth'),
              }"
            >
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                to-now
                use-date
                :index="0"
                :original-start-date="formValues.date_of_birth"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'date_of_birth')"
              ></date-time-input>
            </div>
          </div>
        </div>

        <!--     insurance provider-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.insuranceProvider") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('insurance_provider.id'),
              }"
            >
              <v-select
                label="name"
                :autoscroll="false"
                :options="insuranceProviders.options"
                :filterable="false"
                :reduce="(item) => item.id"
                v-model="formValues.insurance_provider.id"
                @search="searchInsuranceProviders"
              >
                <template #option="{ name }">
                  <span>
                    {{ name }}
                  </span>
                </template>
                <template #no-options="{ search }">
                  <vue-select-searching
                    :search="search"
                    :loading="insuranceProviders.loading"
                    :results-count="insuranceProviders.options.length"
                  />
                </template>
                <template #list-footer>
                  <load-more
                    :message="$t('message.loading') + '...'"
                    :show-load-more="
                      insuranceProviders.query.hasMore &&
                      insuranceProviders.options.length > 0
                    "
                    @load-more="loadMoreProviders"
                  />
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <!--      member & group id-->
        <div class="form-item double no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.memberId") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('insurance_member_id'),
              }"
            >
              <input v-model="formValues.insurance_member_id" type="text" />
            </div>
          </div>
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.groupId") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('insurance_group_id'),
              }"
            >
              <input v-model="formValues.insurance_group_id" type="text" />
            </div>
          </div>
        </div>

        <!--      plan reset month-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.planResetMonth") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: hasError('plan_reset_month'),
              }"
            >
              <input
                v-model="formValues.plan_reset_month"
                type="number"
                min="1"
                max="12"
                step="1"
              />
            </div>
          </div>
        </div>

        <!--      pre-auth required-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <form-input-checkbox-toggle
              :model-value="formValues.pre_auth_required"
              :has-error="hasError('pre_auth_required')"
              @update:modelValue="
                (value) => (formValues.pre_auth_required = value)
              "
            >
              <template v-slot:label>
                <div class="form-item-section-title">
                  {{ $t("label.preAuthRequired") }}
                </div>
              </template>
            </form-input-checkbox-toggle>
          </div>
        </div>

        <!--      copay-->
        <div class="form-item double no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.copayAcupuncture") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('copay_acupuncture'),
              }"
            >
              <input
                v-model="formValues.copay_acupuncture"
                placeholder="$"
                type="number"
                min="0"
                step="1.0"
              />
            </div>
          </div>
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.copayEvaluation") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('copay_evaluation'),
              }"
            >
              <input
                v-model="formValues.copay_evaluation"
                placeholder="$"
                type="number"
                min="0"
                step="1.0"
              />
            </div>
          </div>
        </div>

        <!--      coinsurance-->
        <div class="form-item double no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.coinsuranceAcupuncture") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('coinsurance_acupuncture'),
              }"
            >
              <input
                v-model="formValues.coinsurance_acupuncture"
                placeholder="%"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </div>
          </div>
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.coinsuranceEvaluation") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('coinsurance_evaluation'),
              }"
            >
              <input
                v-model="formValues.coinsurance_evaluation"
                placeholder="%"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </div>
          </div>
        </div>

        <!--      deductible acupuncture-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <form-input-checkbox-toggle
              :model-value="formValues.deductible_applies_acupuncture"
              :has-error="hasError('deductible_applies_acupuncture')"
              @update:modelValue="
                (value) => (formValues.deductible_applies_acupuncture = value)
              "
            >
              <template v-slot:label>
                <div class="form-item-section-title">
                  {{ $t("label.deductibleAppliesAcupuncture") }}
                </div>
              </template>
            </form-input-checkbox-toggle>
          </div>
        </div>

        <!--      deductible evaluation-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <form-input-checkbox-toggle
              :model-value="formValues.deductible_applies_evaluation"
              :has-error="hasError('deductible_applies_evaluation')"
              @update:modelValue="
                (value) => (formValues.deductible_applies_evaluation = value)
              "
            >
              <template v-slot:label>
                <div class="form-item-section-title">
                  {{ $t("label.deductibleAppliesEvaluation") }}
                </div>
              </template>
            </form-input-checkbox-toggle>
          </div>
        </div>

        <!--      deductible-->
        <div class="form-item double no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.deductibleTotal") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('deductible_total'),
              }"
            >
              <input
                v-model="formValues.deductible_total"
                placeholder="$"
                type="number"
                min="0"
                step="1.0"
              />
            </div>
          </div>
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.deductibleRemaining") }}
              <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('deductible_remaining'),
              }"
            >
              <input
                v-model="formValues.deductible_remaining"
                placeholder="$"
                type="number"
                min="0"
                step="1.0"
              />
            </div>
          </div>
        </div>

        <!--      visits limit-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.visitLimit") }} <span class="required">*</span>
            </div>
            <div class="form-item double no-padding">
              <div
                class="form-item"
                :class="{
                  error: this.hasError('visit_limit'),
                }"
              >
                <input
                  v-model="formValues.visit_limit"
                  type="number"
                  min="0"
                  step="1"
                />
              </div>
            </div>
          </div>
        </div>

        <!--      exclusions-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.exclusions") }}
            </div>
            <div class="form-item">
              <textarea
                class="note auto-resize"
                @input="autoResize"
                v-model="formValues.exclusions"
              />
            </div>
          </div>
        </div>

        <!--      other details-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.otherDetails") }}
            </div>
            <div class="form-item">
              <textarea
                class="note auto-resize"
                @input="autoResize"
                v-model="formValues.other_details"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="list-items">
        <div class="form-item-separator">
          {{ $t("label.additionalInformation") }}
        </div>

        <!--          insured name-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.insuredName") }}
            </div>
            <div class="form-item">
              <input type="text" v-model="formValues.insured_name" />
            </div>
          </div>
        </div>

        <!--          insured dob -->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.insuredDob") }}
            </div>
            <div class="form-item">
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                to-now
                use-date
                :index="0"
                :original-start-date="formValues.insured_dob"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'insured_dob')"
              ></date-time-input>
            </div>
          </div>
        </div>

        <!--          insurance relationship to patient-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.relationshipToPatient") }}
            </div>
            <div class="form-item">
              <input
                type="text"
                v-model="formValues.insurance_relationship_to_patient"
              />
            </div>
          </div>
        </div>

        <!--          patient address-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.patientAddress") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('patient_address'),
              }"
            >
              <input type="text" v-model="formValues.patient_address" />
            </div>
          </div>
        </div>

        <!--          policy effective/policy term date-->
        <div class="form-item no-padding double">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.effectiveDate") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('policy_effective_date'),
              }"
            >
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                use-date
                :index="2"
                :original-start-date="formValues.policy_effective_date"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'policy_effective_date')"
              ></date-time-input>
            </div>
          </div>
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.termDate") }}
            </div>
            <div class="form-item">
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                use-date
                :index="2"
                :original-start-date="formValues.policy_term_date"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'policy_term_date')"
              ></date-time-input>
            </div>
          </div>
        </div>

        <div class="form-item-separator">
          {{ $t("label.preAuthorization") }}
        </div>

        <!--          current auth-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.patientAddress") }}
            </div>
            <div class="form-item">
              <input
                type="text"
                maxlength="255"
                v-model="formValues.current_auth"
              />
            </div>
          </div>
        </div>

        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.status") }} <span class="required">*</span>
            </div>
            <div
              class="form-item"
              :class="{
                error: this.hasError('status'),
              }"
            >
              <input type="text" maxlength="255" v-model="formValues.status" />
            </div>
          </div>
        </div>

        <!--          start/end date-->
        <div class="form-item no-padding double">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.startDate") }}
            </div>
            <div class="form-item">
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                use-date
                :index="4"
                :original-start-date="formValues.start_date"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'start_date')"
              ></date-time-input>
            </div>
          </div>

          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.endDate") }}
            </div>
            <div class="form-item">
              <date-time-input
                ref="inputDate"
                enable-date-calendar
                input-mode
                show-label
                use-date
                :index="5"
                :original-start-date="formValues.end_date"
                :time-format24="this.$store.getters.userHoursFormat24"
                @value-start-date="setDate($event, 'end_date')"
              ></date-time-input>
            </div>
          </div>
        </div>

        <!--      visit max-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $t("label.numberOfVisits") }}
            </div>
            <div class="form-item">
              <input
                v-model="formValues.visits_approved"
                type="number"
                min="0"
              />
            </div>
          </div>
        </div>

        <!--      notes-->
        <div class="form-item no-padding">
          <div class="form-item-section">
            <div class="form-item-section-title">
              {{ $tc("label.note", 2) }}
            </div>
            <div class="form-item">
              <textarea
                class="note auto-resize"
                @input="autoResize"
                v-model="formValues.notes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, setCancelToken, cancelTokens } from "@/services/api";
import DateTimeInput from "@/views/private/components/DateTimeInput";
import axios from "axios";
import autoResize from "@/mixins/autoResize";
import SimpleVueValidator from "simple-vue3-validator";
import LoadMore from "@/views/shared/components/LoadMore";
import VueSelectSearching from "@/views/private/components/vendor/vue-select/VueSelectSearching.vue";
import FormInputCheckboxToggle from "@/views/shared/forms/FormInputCheckboxToggle.vue";
import { FLOAT_TWO_DECIMALS } from "@/mixins/constMixin";
const Validator = SimpleVueValidator.Validator;

export default {
  name: "PatientPolicyInfoView",
  props: ["mode"],
  components: {
    FormInputCheckboxToggle,
    VueSelectSearching,
    LoadMore,
    DateTimeInput,
  },
  mixins: [autoResize],
  data: function () {
    return {
      patient: null,
      formData: null,
      patientPolicyInfoList: [],
      currentPolicyInfo: null,
      insuranceProviders: {
        loading: false,
        options: [],
        query: {
          text: "",
          page: 1,
          per_page: 10,
          sort_by: ["name|asc"],
          hasMore: true,
        },
      },
      originalFormValues: {
        legal_first_name: "",
        legal_last_name: "",
        date_of_birth: null,
        insurance_provider: {
          id: null,
        },
        insurance_member_id: "",
        insurance_group_id: "",
        plan_reset_month: null,
        pre_auth_required: false,
        coinsurance_acupuncture: null,
        coinsurance_evaluation: null,
        copay_acupuncture: null,
        copay_evaluation: null,
        deductible_applies_acupuncture: false,
        deductible_applies_evaluation: false,
        deductible_total: null,
        deductible_remaining: null,
        visit_limit: 0,
        exclusions: "",
        other_details: "",
        insured_name: "",
        insured_dob: null,
        insurance_relationship_to_patient: "",
        patient_address: "",
        policy_effective_date: null,
        policy_term_date: null,
        current_auth: "",
        status: "",
        start_date: null,
        end_date: null,
        visits_approved: null,
        notes: "",
      },
      formValues: {},
      loading: false,
      loadingPolicy: false,
      submitting: false,
      cancelToken: {
        patient: null,
        form: null,
        item: null,
        insuranceProvidersLoad: null,
      },
      promises: [],
      hasMore: true,
      searchData: {
        page: 1,
        per_page: 10,
        sort_by: ["created_at|desc"],
      },
    };
  },
  validators: {
    "formValues.legal_first_name": function (value) {
      return Validator.value(value).required();
    },
    "formValues.legal_last_name": function (value) {
      return Validator.value(value).required();
    },
    "formValues.date_of_birth": function (value) {
      return Validator.value(value).required();
    },
    "formValues.insurance_provider.id": function (value) {
      return Validator.value(value).required();
    },
    "formValues.insurance_member_id": function (value) {
      return Validator.value(value).required();
    },
    "formValues.insurance_group_id": function (value) {
      return Validator.value(value).required();
    },
    "formValues.plan_reset_month": function (value) {
      return Validator.value(value).integer().between(1, 12).required();
    },
    "formValues.pre_auth_required": function (value) {
      return Validator.value(value).required();
    },
    "formValues.coinsurance_acupuncture": function (value) {
      return Validator.value(value).integer().between(0, 100).required();
    },
    "formValues.coinsurance_evaluation": function (value) {
      return Validator.value(value).integer().between(0, 100).required();
    },
    "formValues.copay_acupuncture": function (value) {
      return Validator.value(value)
        .float()
        .greaterThanOrEqualTo(0)
        .regex(FLOAT_TWO_DECIMALS)
        .required();
    },
    "formValues.copay_evaluation": function (value) {
      return Validator.value(value)
        .float()
        .greaterThanOrEqualTo(0)
        .regex(FLOAT_TWO_DECIMALS)
        .required();
    },
    "formValues.deductible_applies_acupuncture": function (value) {
      return Validator.value(value).required();
    },
    "formValues.deductible_applies_evaluation": function (value) {
      return Validator.value(value).required();
    },
    "formValues.deductible_total": function (value) {
      return Validator.value(value)
        .float()
        .greaterThanOrEqualTo(0)
        .regex(FLOAT_TWO_DECIMALS)
        .required();
    },
    "formValues.deductible_remaining": function (value) {
      return Validator.value(value)
        .float()
        .greaterThanOrEqualTo(0)
        .regex(FLOAT_TWO_DECIMALS)
        .required();
    },
    "formValues.visit_limit": function (value) {
      return Validator.value(value).digit().greaterThanOrEqualTo(0).required();
    },
    "formValues.insured_name": function (value) {
      return Validator.value(value).maxLength(255);
    },
    "formValues.insurance_relationship_to_patient": function (value) {
      return Validator.value(value).maxLength(255);
    },
    "formValues.patient_address": function (value) {
      return Validator.value(value).maxLength(255).required();
    },
    "formValues.policy_effective_date": function (value) {
      return Validator.value(value).required();
    },
    "formValues.current_auth": function (value) {
      return Validator.value(value).maxLength(255);
    },
    "formValues.status": function (value) {
      return Validator.value(value).maxLength(255).required();
    },
  },
  created() {
    this.reInitFormValues();
    this.loadInsuranceProviders();
  },
  mounted() {
    this.promises.push(this.loadPatientData());
    this.promises.push(this.loadPatientPolicyInfoList());
    axios.all(this.promises);
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    loadPatientData() {
      setCancelToken(this.cancelToken, "patient");
      return HTTP({
        method: "GET",
        url: "/api/v1/patients/" + this.$route.params.id,
        cancelToken: this.cancelToken.patient.token,
      })
        .then((response) => {
          if (response.data) {
            this.patient = response.data;
          }
        })
        .catch(() => {});
    },
    loadMoreProviders() {
      this.insuranceProviders.query.page =
        this.insuranceProviders.query.page + 1;
      this.loadInsuranceProviders();
    },
    searchInsuranceProviders(text = "") {
      this.insuranceProviders.query.text = text;
      this.insuranceProviders.query.page = 1;
      this.insuranceProviders.query.hasMore = true;
      this.insuranceProviders.options = [];
      this.loadInsuranceProviders();
    },
    loadInsuranceProviders() {
      let params = {};

      params.q = this.insuranceProviders.query.text;
      params.page = this.insuranceProviders.query.page;
      params.per_page = this.insuranceProviders.query.per_page;
      params.sort_by = this.insuranceProviders.query.sort_by;

      this.insuranceProviders.loading = true;

      setCancelToken(this.cancelToken, "insuranceProvidersLoad");

      HTTP({
        method: "GET",
        url: "/api/v1/purchases/insurance-providers",
        data: {},
        cancelToken: this.cancelToken.insuranceProvidersLoad.token,
        params: params,
      })
        .then((response) => {
          this.insuranceProviders.loading = false;
          if (response.data) {
            if (response.data.data.length < 10) {
              this.insuranceProviders.query.hasMore = false;
            }
            this.insuranceProviders.options =
              this.insuranceProviders.options.concat(response.data.data);
          }
        })
        .catch(() => (this.insuranceProviders.loading = false));
    },
    loadPatientPolicyInfoList() {
      this.loading = true;
      setCancelToken(this.cancelToken, "form");
      return HTTP({
        method: "GET",
        url:
          "/api/v1/patients/" +
          this.$route.params.id +
          "/patient-policy-info/list",
        cancelToken: this.cancelToken.form.token,
        params: this.searchData,
      })
        .then((response) => {
          if (response.data.data) {
            this.patientPolicyInfoList = this.patientPolicyInfoList.concat(
              response.data.data
            );

            if (response.data.meta.total <= this.patientPolicyInfoList.length) {
              this.hasMore = false;
            }

            if (
              response.data.meta.current_page === 1 &&
              this.patientPolicyInfoList.length
            ) {
              this.loadPatientPolicyInfo(this.patientPolicyInfoList[0]);
            }
          }
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    loadPatientPolicyInfo(value) {
      if (!value) {
        return;
      }

      this.loadingPolicy = true;
      this.currentPolicyInfo = value;
      setCancelToken(this.cancelToken, "item");
      return HTTP({
        method: "GET",
        url:
          "/api/v1/patients/" +
          this.$route.params.id +
          "/patient-policy-info/" +
          this.currentPolicyInfo.id,
        cancelToken: this.cancelToken.item.token,
      })
        .then((response) => {
          if (response.data) {
            this.setItemValues(response.data);
          }
        })
        .catch(() => {})
        .finally(() => (this.loadingPolicy = false));
    },
    setDate(value, key) {
      this.formValues[key] = value;
    },
    acceptFormValue(event, key) {
      const maskRef = event.detail;
      this.formValues[key] = maskRef.value.replaceAll(/ /g, "");
    },
    hasError(key) {
      return this.validation.hasError("formValues." + key);
    },
    submitForm() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let data = self.preparePostDataWithDates(self.formValues, [
            "date_of_birth",
            "insured_dob",
            "policy_term_date",
            "policy_effective_date",
            "start_date",
            "end_date",
          ]);
          self.submitting = true;
          self.saveData(data);
        } else {
          self.$notify({
            group: "notification",
            type: "warning",
            title: self.$t("notification.warning"),
            text: self.$t("notification.fillRequiredFields"),
          });
        }
      });
    },
    saveData(formValues) {
      this.loadingPolicy = true;
      HTTP({
        method: "POST",
        url:
          "/api/v1/patients/" + this.$route.params.id + "/patient-policy-info/",
        data: formValues,
      })
        .then((response) => {
          this.submitting = false;
          this.loadingPolicy = false;
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.success"),
          });
          if (response.data) {
            this.patientPolicyInfoList.unshift(response.data);
            this.currentPolicyInfo = response.data;
            this.setItemValues(response.data);
          }
        })
        .catch(() => {
          this.submitting = false;
          this.loadingPolicy = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        });
    },
    deleteCurrentItem() {
      this.loadingPolicy = true;
      HTTP({
        method: "DELETE",
        url:
          "/api/v1/patients/" +
          this.$route.params.id +
          "/patient-policy-info/" +
          this.currentPolicyInfo.id,
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.success"),
          });
          this.patientPolicyInfoList = this.removeObjectFromArray(
            this.patientPolicyInfoList,
            this.currentPolicyInfo.id,
            "id"
          );
          if (!this.patientPolicyInfoList.length) {
            this.currentPolicyInfo = null;
            this.reInitFormValues();
            return;
          }
          this.loadPatientPolicyInfo(this.patientPolicyInfoList[0]);
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.error"),
          });
        })
        .finally(() => (this.loadingPolicy = false));
    },
    loadMore() {
      if (!this.loading) {
        this.searchData.page = this.searchData.page + 1;
        this.loadPatientPolicyInfoList();
      }
    },
    setItemValues(data) {
      this.formValues = data;
      this.formValues.date_of_birth = this.handleDateOnlyValue(
        this.formValues.date_of_birth
      );
      this.formValues.insured_dob = this.handleDateOnlyValue(
        this.formValues.insured_dob
      );
      this.formValues.policy_term_date = this.handleDateOnlyValue(
        this.formValues.policy_term_date
      );
      this.formValues.policy_effective_date = this.handleDateOnlyValue(
        this.formValues.policy_effective_date
      );
      this.formValues.start_date = this.handleDateOnlyValue(
        this.formValues.start_date
      );
      this.formValues.end_date = this.handleDateOnlyValue(
        this.formValues.end_date
      );
    },
    reInitFormValues() {
      this.formValues = JSON.parse(JSON.stringify(this.originalFormValues));
    },
  },
};
</script>
