<template>
  <div class="documents-main">
    <div class="documents-main-inner">
      <div class="row">
        <div
          :class="{
            'col-lg-8': true,
            'with-pagination': $store.state.documents.patientDocuments,
          }"
        >
          <!--          top section-->
          <div :class="{ list: $store.state.documents.patientDocuments }">
            <div
              :class="{
                'documents-main-inner-top': true,
              }"
            >
              <div class="documents-main-inner-top-text">All Files</div>
              <a
                class="button no-border documents-main-inner-top-upload"
                @click.prevent="handleUploadButton"
              >
                Upload file
                <div class="documents-main-inner-top-upload-icon upload"></div>
              </a>
              <input
                v-if="documentsConfig.USE_ALLOWED_MIME_TYPES"
                :accept="documentsConfig.ALLOWED_MIME_TYPES.join(',')"
                ref="fileInput"
                type="file"
                name="file-upload"
                multiple="multiple"
                @change="addInputFile"
                style="display: none"
              />
              <input
                v-else
                ref="fileInput"
                type="file"
                name="file-upload"
                multiple="multiple"
                @change="addInputFile"
                style="display: none"
              />
            </div>

            <documents-standard-list-view
              v-if="!$store.state.documents.patientDocuments"
              v-model="isDropping"
              :currentActive="currentActive"
              :files="files"
              :folders="folders"
              @handle-file-click="handleFileClick"
              @handle-file-drop="handleFileDrop"
              @handle-folder-click="handleFolderClick"
              @handle-folder-up-click="handleFolderUpClick"
            />
            <documents-patients-list-view
              v-if="$store.state.documents.patientDocuments"
              v-model="isDropping"
              :all-results="allResults"
              :currentActive="currentActive"
              :folders="folders"
              :pages="pages"
              @handle-file-drop="handleFileDrop"
              @handle-folder-click="handleFolderClick"
              @handle-folder-up-click="handleFolderUpClick"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <documents-preview
            :currentActive="currentActive"
            @add-to-favorites="addToFavorites"
            @remove-from-favorites="removeFromFavorites"
            @handle-upload="handleUploadButton"
          ></documents-preview>
        </div>
      </div>

      <documents-uploader-queue
        :filesQueue="this.uploader.filesQueue"
        ref="documentsUploaderQueue"
        @start-upload="startUpload"
        @remove-from-queue="removeFromQueue"
      ></documents-uploader-queue>
    </div>
  </div>
</template>

<script>
import {
  HTTP,
  CancelToken,
  cancelTokens,
  setCancelToken,
} from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import fileMixin from "@/mixins/fileMixin";
import config from "@/config/documents.js";
import DocumentsUploaderQueue from "./DocumentsUploaderQueue";
import DocumentsPreview from "./DocumentsPreview";
import DocumentsStandardListView from "@/views/private/components/document/DocumentsStandardListView";
import DocumentsPatientsListView from "@/views/private/components/document/DocumentsPatientsListView";

export default {
  name: "DocumentsView",
  props: ["path"],
  mixins: [fileMixin],
  components: {
    DocumentsStandardListView,
    DocumentsPatientsListView,
    DocumentsPreview,
    DocumentsUploaderQueue,
  },
  data: function () {
    return {
      documentsConfig: config,
      files: [],
      folders: [],
      isDropping: false,
      dblClickTimer: false,
      dblClickCount: 0,
      pages: [1],
      allResults: 0,
      currentActive: {
        type: null,
        item: null,
      },
      uploader: {
        filesQueue: [],
        maxFiles: 10,
      },
      cancelToken: { load: null },
    };
  },
  mounted() {
    this.checkDocumentsLoad();
  },
  created() {
    EventBus.$on("upload-file", (file) => {
      this.uploadFile(file);
    });
    EventBus.$on("documents-load", () => {
      this.loadFiles();
    });
    if (this.path !== this.$store.state.documents.currentPath) {
      this.$store.dispatch("setDocumentPath", this.path);
    }
  },
  unmounted() {
    EventBus.$off("documents-load");
    EventBus.$off("upload-file");
    cancelTokens(this.cancelToken);
  },
  watch: {
    "$store.state.documents": {
      deep: true,
      handler: function () {
        this.checkDocumentsLoad();
      },
    },
    "$store.state.documents.currentPath": function (newPath, oldPath) {
      if (
        newPath === "/patients" &&
        !this.$store.state.documents.patientDocuments
      ) {
        this.$store.dispatch("setPatientDocuments", true);
      }

      if (oldPath !== newPath && typeof newPath !== "undefined") {
        this.$router
          .push(
            "/documents/" +
              (newPath !== "/"
                ? encodeURIComponent(newPath.replace(/\s+/g, "-"))
                : "")
          )
          .catch(() => {});
      }
    },
    path: function (newVal) {
      if (newVal !== this.$store.state.documents.currentPath) {
        this.$store.dispatch("setDocumentPath", newVal);
      }
    },
  },
  methods: {
    loadPatientDocuments() {
      this.clearFiles();
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/files/folders/patients",
        data: {},
        params: {
          search: this.$store.state.documents.search,
          page: this.$store.state.documents.pagination.page,
          per_page: this.$store.state.documents.pagination.perPage,
          sort_by: ["last_name|asc"],
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.folders = response.data.data;
            this.files = [];
            this.allResults = response.data.meta.total;
            this.pages = [];
            for (let i = 1; i <= response.data.meta.total_pages; i++) {
              this.pages.push(i);
            }
          }
        })
        .catch(() => {});
    },
    loadFiles() {
      this.clearFiles();
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/files",
        data: {},
        params: {
          path: this.$store.state.documents.currentPath,
          campaign: {
            id: this.$store.state.documents.selectedCampaign
              ? this.$store.state.documents.selectedCampaign.id
              : null,
          },
          search: this.$store.state.documents.search,
          sort_by: this.$store.state.documents.sortOrder,
        },
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.folders = response.data.filter(function (item) {
              return item.type === 2 && item.path !== "/";
            });

            this.files = response.data.filter(function (item) {
              return item.type === 1;
            });
          }
        })
        .catch(() => {});
    },
    handleUploadButton() {
      this.$refs.fileInput.click();
    },
    handleFileDrop(event) {
      if (!event.dataTransfer) {
        return;
      }
      let dt = event.dataTransfer;
      this.addDataTransfer(dt);
    },
    handleFolderUpClick() {
      this.$store.dispatch("setDocumentPathUp");
    },
    handleFolderClick(folder) {
      this.dblClickCount++;
      this.setCurrentActive("folder", folder);
      if (this.dblClickCount === 1) {
        let self = this;
        this.dblClickTimer = setTimeout(function () {
          self.dblClickCount = 0;
        }, 500);
      } else {
        clearTimeout(this.dblClickTimer);
        this.$store.dispatch("setDocumentPath", folder.path);
        this.dblClickCount = 0;
      }
    },
    handleFileClick(file) {
      this.setCurrentActive("file", file);
    },
    uploadFile(file) {
      this.uploader.filesQueue.push(file);
      this.startUpload(this.uploader.filesQueue.length - 1);
    },
    startUpload(index) {
      if (typeof this.uploader.filesQueue[index] === "undefined") {
        return;
      }

      if (
        this.uploader.filesQueue[index].active ||
        this.uploader.filesQueue[index].success
      ) {
        return;
      }

      let data = [];

      data["fileData"] = this.uploader.filesQueue[index].file;
      data["name"] = this.uploader.filesQueue[index].file.name;
      data["path"] = this.$store.state.documents.currentPath;

      const formData = this.generateFormData(data);

      this.uploader.filesQueue[index].active = true;
      this.uploader.filesQueue[index].error = "";
      this.uploader.filesQueue[index].success = false;
      this.uploader.filesQueue[index].token = CancelToken.source();

      if (this.$refs.documentsUploaderQueue) {
        this.$refs.documentsUploaderQueue.open();
      }

      let self = this;
      HTTP({
        method: "POST",
        url: "/api/v1/files",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: self.uploader.filesQueue[index].token.token,
        onUploadProgress: function (progressEvent) {
          self.uploader.filesQueue[index].progress = Math.round(
            // eslint-disable-next-line
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then(function () {
          self.loadFiles();
          self.uploader.filesQueue[index].active = false;
          self.uploader.filesQueue[index].success = true;
        })
        .catch(function (e) {
          self.uploader.filesQueue[index].error = e;
          self.uploader.filesQueue[index].active = false;
          self.uploader.filesQueue[index].false = true;
        });
    },
    removeFromQueue(index) {
      if (typeof this.uploader.filesQueue[index] === "undefined") {
        return;
      }

      if (
        this.uploader.filesQueue[index].active &&
        this.uploader.filesQueue[index].cancelToken
      ) {
        this.uploader.filesQueue[index].cancelToken.cancel();
      }

      this.uploader.filesQueue.splice(index, 1);
    },
    clearFiles() {
      this.files = [];
      this.folders = [];
      this.currentActive = {
        type: null,
        item: null,
      };
    },
    addToFavorites(campaign) {
      let exist = this.currentActive.item.campaigns.some(function (item) {
        return item.id === campaign.id;
      });

      if (!exist) {
        this.currentActive.item.campaigns.push(campaign);
        this.updateFavorites();
      }
    },
    removeFromFavorites(campaign) {
      this.currentActive.item.campaigns =
        this.currentActive.item.campaigns.filter(function (item) {
          return item.id !== campaign.id;
        });
      this.updateFavorites();
    },
    updateFavorites() {
      const data = [];
      data["campaign"] = [];
      data["campaign"]["id"] = [];
      this.currentActive.item.campaigns.forEach(function (item) {
        data["campaign"]["id"].push(item.id);
      });
      const formData = this.generateFormData(data);

      HTTP({
        method: "POST",
        url: "/api/v1/files/" + this.currentActive.item.id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("notification.thumbsUp"),
            text: this.$t("notification.campaignUpdate"),
          });
        })
        .catch(() => {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("notification.sadFace"),
            text: this.$t("notification.campaignNotUpdate"),
          });
        });
    },
    setCurrentActive(type, item) {
      if (
        this.currentActive.type === type &&
        this.currentActive.item.id === item.id
      ) {
        this.currentActive = {
          type: null,
          item: null,
        };
        return;
      }

      this.currentActive = {
        type: type,
        item: item,
      };
    },
    checkDocumentsLoad() {
      if (this.$store.state.documents.patientDocuments) {
        this.loadPatientDocuments();
      } else {
        this.loadFiles();
      }
    },
  },
};
</script>
