<template>
  <div
    class="table-cell"
    :class="{ editable: editable, 'not-editable': !editable }"
    :style="
      width !== ''
        ? 'flex-basis: ' + width + '; min-width: ' + width
        : 'flex: 1;'
    "
    :ref="'cell_' + id"
    @keyup="checkForKeyPress"
  >
    <slot></slot>
    <template v-if="type === 'text' || type === 'number'">
      <div
        :class="{
          placeholder: true,
          error: error,
        }"
        v-show="editActive === false"
        @click.prevent="activateEdit"
        style="min-height: 16px"
      >
        {{ value }}
      </div>
      <div v-if="editable && editActive">
        <input
          ref="textInput"
          :class="{ error: error }"
          :type="type"
          v-model="mutatedValue"
          v-closable="{
            exclude: ['cell_' + id],
            handler: 'closeCell',
          }"
        />
      </div>
    </template>
    <template v-if="type === 'date'">
      <div>
        {{ formatDateTime(value) }}
      </div>
    </template>
    <template v-if="type === 'select'">
      <v-select
        :disabled="!editable"
        :placeholder="placeholder"
        :options="options"
        :class="{ error: error }"
        v-model="mutatedValue"
        :reduce="(item) => item.value"
        appendToBody
        @open="activateEdit"
        @option:selected="closeCell"
      >
        <template v-if="newOptionPush && newOption" #no-options="{ search }">
          <a @click.prevent.stop="addNewOption(search)">
            {{ $t("button.addNew") }}
          </a>
        </template>
      </v-select>
    </template>
  </div>
</template>

<script>
import { TABLE_ITEM_TYPES } from "@/mixins/constMixin";

export default {
  name: "TableCell",
  props: {
    type: {
      type: String,
      default: "",
      validator: function (value) {
        return Object.values(TABLE_ITEM_TYPES).includes(value);
      },
    },
    dataKey: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    newOption: {
      type: Function,
      default() {
        return false;
      },
    },
    newOptionPush: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      id: null,
      mutatedValue: null,
      editActive: false,
    };
  },
  created() {
    this.mutatedValue = this.value;
    this.id = this.$.vnode.key;
  },
  watch: {
    value: function (newVal) {
      if (newVal !== this.mutatedValue) {
        this.mutatedValue = newVal;
      }
    },
  },
  methods: {
    checkForKeyPress(e) {
      if ([13, 27].indexOf(e.keyCode) > -1) {
        this.closeCell();
      }
    },
    activateEdit() {
      if (!this.editable) {
        this.$emit("not-editable", this.dataKey);
        return;
      }
      this.editActive = true;

      if (this.type !== "select") {
        let self = this;
        this.$nextTick(() => {
          self.$refs.textInput.focus();
        });
      }
    },
    closeCell() {
      if (
        (this.editActive || this.type === "select") &&
        this.value !== this.mutatedValue
      ) {
        this.$emit("update-cell-value", {
          key: this.dataKey,
          value: this.mutatedValue,
        });
      }

      this.editActive = false;
    },
    addNewOption(value) {
      let option = this.newOption(value);
      this.$emit("add-new-option", option);
      this.mutatedValue = option.value;
      this.$emit("update-cell-value", {
        key: this.dataKey,
        value: option.value,
      });
      this.editActive = false;
    },
  },
};
</script>

<style scoped></style>
