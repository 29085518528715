import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import auth from "@/services/auth";

import Error404 from "@/views/Error404";

import PublicLayout from "@/views/public/Layout";
import PrivateLayout from "@/views/private/Layout";

import PublicRoutes from "@/router/PublicRoutes";
import PrivateRoutes from "@/router/PrivateRoutes";
import PrivateAdminRoutes from "@/router/PrivateAdminRoutes";
import { logout } from "@/services/api";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/admin",
      component: PrivateLayout,
      children: PrivateAdminRoutes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      component: PrivateLayout,
      children: PrivateRoutes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      component: PublicLayout,
      children: PublicRoutes,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "error404",
      component: Error404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);

  if (requiresAuth) {
    if (!store.getters.isAuthUser) {
      if (to.fullPath !== "/") {
        logout(to.fullPath);
        return;
      }

      logout();
      return;
    }

    // root path route
    if (to.fullPath === "/") {
      if (auth.hasRole(["Administrator", "Doctors"])) {
        return next({
          name: "calendar",
          params: { view: store.state.currentView.type },
        });
      }

      if (auth.hasRole(["Patient"])) {
        return next({
          name: "patientSchedule",
        });
      }

      if (auth.hasRole(["Super Administrator"])) {
        return next({
          name: "adminSettings",
          params: { type: "clinics" },
        });
      }
    }

    // calendar view routing
    if (to.name === "calendar") {
      if (
        to.params.view &&
        ["day", "week", "month"].indexOf(to.params.view) > -1 &&
        store.state.currentView.type !== to.params.view
      ) {
        store.dispatch("setCurrentView", {
          type: to.params.view,
          start_date: store.state.currentView.start_date,
          end_date: store.state.currentView.end_date,
        });
      } else if (store.state.currentView.type !== to.params.view) {
        return next("/calendar/" + store.state.currentView.type);
      }
    }

    // switch to day view
    if (to.name === "calendarDayAgenda") {
      store.dispatch("setCurrentView", {
        type: "day",
        start_date: store.state.currentView.start_date,
        end_date: store.state.currentView.end_date,
      });
    }

    // Check if current user has role for accessing route
    // eslint-disable-next-line no-prototype-builtins
    if (!to.matched.some((record) => record.meta.hasOwnProperty("role"))) {
      to.query.redirect ? next(to.query.redirect) : next();
      return;
    }

    if (!to.matched.some((record) => auth.hasRole(record.meta.role))) {
      next({ name: "forbidden" });
      return;
    }
  }

  next();
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " | " + process.env.VUE_APP_NAME
      : process.env.VUE_APP_NAME;
  });
});

export default router;
