<template>
  <div class="modal create" id="active-modal">
    <div class="modal-dialog" v-if="!loading">
      <div class="modal-dialog-inner">
        <div class="modal-dialog-main">
          <div class="modal-dialog-main-section-row">
            <div class="modal-dialog-main-section-column">
              <div class="modal-dialog-main-section border-bottom">
                <div class="form-item padding-bottom-0">
                  <div class="form-item-title">
                    <div>
                      {{
                        this.isModeEdit()
                          ? $t("label.edit") +
                            " " +
                            $tc("label.plan", 1).toLowerCase()
                          : $t("label.addNew") +
                            " " +
                            $tc("label.plan", 1).toLowerCase()
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-dialog-main-section">
                <div class="modal-dialog-main-form">
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPlan.name'),
                    }"
                  >
                    <label class="item">{{ $t("label.name") }}: </label>
                    <input ref="name" v-model="newPlan.name" type="text" />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPlan.treatment'),
                    }"
                  >
                    <label class="item"
                      >{{ $tc("label.treatment", 1) }}:
                    </label>
                    <v-select
                      label="name"
                      :options="treatments"
                      v-model="newPlan.treatment"
                    >
                      <template #option="{ name, id }">
                        <span>
                          <item-icon
                            type="treatment"
                            :class-name="colorClass(id, 'treatment')"
                          ></item-icon
                          >{{ name }}</span
                        >
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPlan.price'),
                    }"
                  >
                    <label class="item">{{ $t("label.price") }}: </label>
                    <input
                      ref="price"
                      v-model="newPlan.price"
                      type="number"
                      min="0.0"
                      step="1.0"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPlan.appointments_number'),
                    }"
                  >
                    <label class="item"
                      >{{ $t("label.appointmentsNumber") }}:
                    </label>
                    <input
                      ref="appointmentsNumber"
                      v-model="newPlan.appointments_number"
                      type="number"
                      min="0"
                      step="1"
                    />
                  </div>
                  <div
                    class="form-item"
                    :class="{
                      error: validation.hasError('newPlan.days_valid'),
                    }"
                  >
                    <label class="item">{{ $t("label.daysValid") }}: </label>
                    <input
                      ref="daysValid"
                      v-model="newPlan.days_valid"
                      type="number"
                      min="0"
                      step="1"
                    />
                  </div>
                  <div class="form-item action">
                    <a
                      class="button action disable-close"
                      @click.prevent="savePlan()"
                    >
                      {{ $t("button.save") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import { HTTP, cancelTokens, setCancelToken } from "@/services/api";
import { default as EventBus } from "@/services/eventbus";
import ItemIcon from "@/views/private/components/ItemIcon";
import SimpleVueValidator from "simple-vue3-validator";

const Validator = SimpleVueValidator.Validator;

export default {
  name: "PlanCreate",
  components: { ItemIcon },
  validators: {
    "newPlan.name": function (value) {
      return Validator.value(value).required();
    },
    "newPlan.treatment": function (value) {
      return Validator.value(value).required();
    },
    "newPlan.price": function (value) {
      return Validator.value(value).required().greaterThanOrEqualTo(0);
    },
    "newPlan.appointments_number": function (value) {
      return Validator.value(value).required().greaterThanOrEqualTo(0);
    },
    "newPlan.days_valid": function (value) {
      return Validator.value(value).required().greaterThanOrEqualTo(0);
    },
  },
  data() {
    return {
      newPlan: {
        name: null,
        price: null,
        treatment: null,
        appointments_number: null,
        days_valid: null,
      },
      treatments: [],
      hourFormatOptions: ["12-hours", "24-hours"],
      loading: false,
      cancelToken: { load: null },
      observer: null,
    };
  },
  watch: {
    "$route.name": function () {
      this.entrypoint();
    },
  },
  created() {
    this.loadTreatments();
  },
  mounted() {
    this.entrypoint();
  },
  unmounted() {
    cancelTokens(this.cancelToken);
  },
  methods: {
    entrypoint() {
      if (this.isModeEdit()) {
        this.loadPlan();
      }
    },
    isModeEdit() {
      return "planEdit" === this.$route.name;
    },
    loadPlan() {
      this.loading = true;
      HTTP({
        method: "GET",
        url: "/api/v1/plans/" + this.$route.params.id,
      })
        .then((response) => {
          if (response.data) {
            this.newPlan = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadTreatments() {
      setCancelToken(this.cancelToken, "load");
      HTTP({
        method: "GET",
        url: "/api/v1/treatments",
        data: {},
        cancelToken: this.cancelToken.load.token,
      })
        .then((response) => {
          if (response.data) {
            this.treatments = response.data;
          }
        })
        .catch(() => {});
    },
    savePlan() {
      let self = this;
      self.$validate().then(function (success) {
        if (success) {
          let url =
            "/api/v1/plans/" + (self.isModeEdit() ? self.$route.params.id : "");
          self.loading = true;
          let postData = self.preparePostDataWithDates(self.newPlan);
          HTTP({
            method: self.isModeEdit() ? "PUT" : "POST",
            url: url,
            data: postData,
          })
            .then((response) => {
              if (response.status === 200) {
                self.loading = false;
                self.closeModal();
                EventBus.$emit("plans-load");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: self.$t("notification.thumbsUp"),
                  text: self.$t("notification.successSave"),
                });
              }
            })
            .catch((error) => {
              self.loading = false;
              self.$notify({
                group: "notification",
                type: "error",
                title: self.$t("notification.sadFace"),
                duration: 5000,
                text: self.formatError(
                  error,
                  self.$t("notification.errorSave")
                ),
              });
            });
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
