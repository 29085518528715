<template>
  <div class="dropdown" :class="{ separator: separator }" :ref="componentRef">
    <div class="dropdown-label">
      {{ label }}
    </div>
    <div class="dropdown-selected">
      <template v-if="!onlyDropdown">
        {{ selectedOption ? selectedOption.label : "" }}
        <div
          class="icon icon-arrow-right"
          :class="{ expanded: dropdownOpen }"
          @click.prevent="dropdownOpen = !dropdownOpen"
        ></div>
      </template>
      <template v-else><slot></slot></template>
      <div class="dropdown-options" :class="{ expanded: dropdownOpen }">
        <div
          class="dropdown-options-item"
          v-for="(option, index) in options"
          :key="'dropdown_option_' + index"
          @click.prevent="emitDropdownValue(option)"
          v-closable="{
            exclude: [componentRef, wrapperRef],
            handler: 'closeOptions',
          }"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownOptions",
  props: {
    label: {
      type: String,
      default: "",
    },
    componentRef: {
      type: String,
      default: "",
    },
    wrapperRef: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    onlyDropdown: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    toggleOptions() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeOptions() {
      this.dropdownOpen = false;
    },
    emitDropdownValue(value) {
      this.$emit("value-change", value);
      this.closeOptions();
    },
  },
};
</script>

<style scoped></style>
