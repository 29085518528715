const CONTENT_TYPES = {
  "text/html": "html",
  "text/css": "css",
  "text/xml": "xml",
  "image/gif": "gif",
  "image/jpeg": "jpeg",
  "application/x-javascript": "js",
  "application/atom+xml": "atom",
  "application/rss+xml": "rss",

  "text/mathml": "mml",
  "text/plain": "txt",
  "text/vnd.sun.j2me.app-descriptor": "jad",
  "text/vnd.wap.wml": "wml",
  "text/x-component": "htc",

  "image/png": "png",
  "image/tiff": "tif",
  "image/vnd.wap.wbmp": "wbmp",
  "image/x-icon": "ico",
  "image/x-jng": "jng",
  "image/x-ms-bmp": "bmp",
  "image/svg+xml": "svg",
  "image/webp": "webp",

  "application/java-archive": "jar war ear",
  "application/json": "json",
  "application/mac-binhex40": "hqx",
  "application/msword": "doc",
  "application/pdf": "pdf",
  "application/postscript": "ps eps ai",
  "application/rtf": "rtf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.wap.wmlc": "wmlc",
  "application/vnd.google-earth.kml+xml": "kml",
  "application/vnd.google-earth.kmz": "kmz",
  "application/x-7z-compressed": "7z",
  "application/x-cocoa": "cco",
  "application/x-java-archive-diff": "jardiff",
  "application/x-java-jnlp-file": "jnlp",
  "application/x-makeself": "run",
  "application/x-perl": "pl pm",
  "application/x-pilot": "prc pdb",
  "application/x-rar-compressed": "rar",
  "application/x-redhat-package-manager": "rpm",
  "application/x-sea": "sea",
  "application/x-shockwave-flash": "swf",
  "application/x-stuffit": "sit",
  "application/x-tcl": "tcl tk",
  "application/x-x509-ca-cert": "der pem crt",
  "application/x-xpinstall": "xpi",
  "application/xhtml+xml": "xhtml",
  "application/zip": "zip",

  "audio/midi": "mid",
  "audio/mpeg": "mp3",
  "audio/ogg": "ogg",
  "audio/x-realaudio": "ra",

  "video/3gpp": "3gp",
  "video/mpeg": "mpg",
  "video/quicktime": "mov",
  "video/x-flv": "flv",
  "video/x-mng": "mng",
  "video/x-ms-asf": "asf",
  "video/x-ms-wmv": "wmv",
  "video/x-msvideo": "avi",
  "video/mp4 ": "m4v mp4",
};
import config from "@/config/documents.js";
import { default as EventBus } from "@/services/eventbus";
export default {
  methods: {
    getExtension(mimeType) {
      if (typeof CONTENT_TYPES[mimeType] !== "undefined") {
        return CONTENT_TYPES[mimeType];
      }
      return "";
    },
    getPrintExtension(mimeType) {
      if (mimeType.indexOf("image") === 0) {
        return "image";
      }

      return this.getExtension(mimeType);
    },
    getFileSize(fileSizeInBytes) {
      let i = -1;
      let byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    addInputFile(event, dataObject = null) {
      let el = event.target;
      let files = [];
      if (el.files) {
        for (let i = 0; i < el.files.length; i++) {
          let file = el.files[i];
          files.push({
            size: file.size,
            name: file.webkitRelativePath || file.relativePath || file.name,
            type: file.type,
            file,
          });
        }

        return this.addFiles(files, dataObject);
      }
    },
    addDataTransfer(dataTransfer) {
      let files = [];
      if (dataTransfer.files) {
        for (let i = 0; i < dataTransfer.files.length; i++) {
          let file = dataTransfer.files[i];
          files.push({
            size: file.size,
            name: file.webkitRelativePath || file.relativePath || file.name,
            type: file.type,
            file,
          });
        }

        return this.addFiles(files);
      }
    },
    addFiles(_files, dataObject = null) {
      let files = _files;
      let isArray = files instanceof Array;

      if (!isArray) {
        files = [files];
      }

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file instanceof Blob) {
          file = {
            file,
            size: file.size,
            name:
              file.webkitRelativePath ||
              file.relativePath ||
              file.name ||
              "unknown",
            type: file.type,
          };
        }
        let fileObject = false;
        if (file.fileObject === false) {
          fileObject = false;
        } else if (file.fileObject) {
          fileObject = true;
        } else if (
          typeof Element !== "undefined" &&
          file.el instanceof Element
        ) {
          fileObject = true;
        } else if (typeof Blob !== "undefined" && file.file instanceof Blob) {
          fileObject = true;
        }
        if (fileObject) {
          file = {
            fileObject: true,
            size: -1,
            active: false,
            error: "",
            success: false,
            ...file,
            response: {},
            progress: "0.00",
            speed: 0,
            cancelToken: null,
          };

          file.data = {
            ...this.data,
            ...(file.data ? file.data : {}),
          };

          file.headers = {
            ...this.headers,
            ...(file.headers ? file.headers : {}),
          };
        }

        // id
        if (!file.id) {
          file.id = Math.random().toString(36).substr(2);
        }

        if (
          config.USE_MAX_FILE_SIZE_LIMIT &&
          file.size > 1024 * 1024 * config.MAX_FILESIZE_MB
        ) {
          alert(
            "Maximum file size for file is " +
              config.MAX_FILESIZE_MB +
              "MB" +
              " file " +
              file.name +
              " won't be uploaded"
          );
          continue;
        }

        if (config.USE_ALLOWED_MIME_TYPES) {
          let inType = config.ALLOWED_MIME_TYPES.some(function (item) {
            return (
              item === file.type ||
              (item.indexOf("*") > -1 &&
                file.type.indexOf(item.substring(0, item.length - 1)) > -1)
            );
          });

          if (!inType) {
            alert(
              "File " +
                file.name +
                " is not in allowed filetype and won't be uploaded"
            );
            continue;
          }
        }

        if (dataObject) {
          dataObject.value = file;
        } else {
          EventBus.$emit("upload-file", file);
        }
      }
    },
    downloadFile(response) {
      const headerval = response.headers["content-disposition"];
      let filename = headerval
        .split(";")[1]
        .split("=")[1]
        .replace('"', "")
        .replace('"', "");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
  },
};
