<template>
  <div class="main" :key="$route.path">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicLayout",
};
</script>
